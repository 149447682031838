import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { ShiftService, IShift } from '../services/shift.service';
import { baseRole } from '../shared/baseRole';
import { UserService, IUser } from '../services/user.service';
import { PositionService, IPosition } from '../services/position.service';
import { TimeOffService } from '../services/timeOff.service';
import {
  EmployeePositionService,
  IEmployeePosition,
} from '../services/employeePosition.service';
import { RateType, moment, dateToBeginningOfPayroll } from '../shared/api';
import {
  createShiftBreakTime,
  IShiftBreak,
  shiftBreakValidation,
} from 'source/shared/helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'modal-add-shift',
  templateUrl: 'modalAddShift.html',
})
export class ModalAddShiftComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;

  @Input() startDate: Date;
  @Input() endDate: Date;
  //@Input() statMap: any;
  //@Input() dayList: any[];
  @Input() dayList: any[];
  @Input() stats: any[];
  @Input() timeOffs: any[];

  public days: { _selected: boolean; date: Date }[];
  public startTime: Date;
  public endTime: Date;
  public positionId: number;
  public positions: IPosition[];
  public singleSelected;
  public users: any[];
  public _allUsers: any[];
  public unassigned = 0;
  public selectedDays: any[];
  public numbers: number[];
  public firstBreak: IShiftBreak;
  public secondBreak: IShiftBreak;
  public thirdBreak: IShiftBreak;
  public breakTimeOffset: number;
  public errors: string[] = null;

  constructor(
    public router: Router,
    public accountService: AccountService,
    public shiftService: ShiftService,
    public positionService: PositionService,
    protected userService: UserService,
    protected timeOffService: TimeOffService,
    protected employeePositionService: EmployeePositionService
  ) {
    super(accountService);

    this.numbers = Array(13)
      .fill(0)
      .map((x, i) => i * 5);
  }

  ngOnInit() {}

  show() {
    this.selectedDays = [];
    this.startTime = new Date(this.startDate);
    this.startTime.setHours(10);
    this.startTime.setMinutes(0);
    this.startTime.setSeconds(0);
    this.startTime.setMilliseconds(0);
    this.endTime = new Date(this.startTime.getTime() + 8 * 60 * 60 * 1000);
    let date = new Date(this.startDate);
    this.days = [];
    for (let i = 0; i < 7; i++, date.setDate(date.getDate() + 1)) {
      this.days.push({ _selected: false, date: new Date(date) });
    }

    this.firstBreak = {
      toggle: false,
      time: new Date(this.startTime.getTime()),
      length: 10,
    };
    this.secondBreak = {
      toggle: false,
      time: new Date(this.startTime.getTime()),
      length: 10,
    };
    this.thirdBreak = {
      toggle: false,
      time: new Date(this.startTime.getTime()),
      length: 10,
    };

    this.positionService.list().subscribe(data => {
      this.positions = data;
      this.positionId = this.positions[0].id;
      // So why endDate, endDate? Because if you add a user in the interim,
      // they won't get listed otherwise. Same with the employee_position..
      this.userService
        .listWithPositions(this.endDate, this.endDate, { hide: false })
        .subscribe(data => {
          this._allUsers = data;
          this.filterUsersByPosition();
          this.calcUserStats();
          this.calcConflicts();
          this.modal.show();
        });
    });
  }

  calcUserStats() {
    if (!this.dayList || !this._allUsers) return;
    let userMap: any = {};

    for (let u of this._allUsers) {
      userMap[u.id] = u;

      //u.conflicts = [];
      u.minutes = 0;
      u.cost = 0;
      u.shift_count = 0;
      u.shifts = [];
      u.timeOffs = [];
    }
    let shiftMap: any = {};
    for (let d of this.dayList) {
      for (let s of d._shifts) {
        let shift = s.shift;
        if (!(shift.user_id in userMap)) continue; // Not in current user list
        if (shift.id in shiftMap) continue; // Seen this one already
        shiftMap[shift.id] = true;
        userMap[shift.user_id].minutes += shift.minutes + shift.ot_minutes;
        userMap[shift.user_id].shift_count++;
        userMap[shift.user_id].shifts.push(shift);
        if (shift.is_salary) continue;
        userMap[shift.user_id].cost += (shift.rate * shift.minutes) / 60;
        userMap[shift.user_id].cost +=
          (shift.rate * 1.5 * shift.ot_minutes) / 60;
      }
    }
    for (let t of this.timeOffs) {
      if (!(t.user_id in userMap)) continue;
      userMap[t.user_id].timeOffs.push(t);
    }
  }

  calcConflicts() {
    if (!this.users || !this.timeOffs) return;
    let payrollStart = dateToBeginningOfPayroll(
      this.startDate,
      this.getLocation()
    );

    let selectedDays = this.days.filter(e => {
      return e._selected;
    });
    let timeArray = [];
    for (let s of selectedDays) {
      timeArray.push(this.createShift(s.date, null));
    }
    let shiftMap: any = {};
    for (let u of this.users) {
      u.weekMinutes = [0, 0];
      if (!u.shifts) continue;
      u.conflicts = [];
      u.notifications = [];
      for (let s of u.shifts) {
        let shiftRange = moment.range(s.start, s.end);
        let weekKey = Math.floor(
          moment(s.start).diff(payrollStart, 'weeks', true)
        );
        if (!(s.id in shiftMap)) {
          u.weekMinutes[weekKey] += moment(s.end).diff(s.start, 'minutes');
          shiftMap[s.id] = true;
        }
        for (let t of timeArray) {
          let tRange = moment.range(t.start, t.end);
          if (shiftRange.overlaps(tRange)) {
            u.conflicts.push({
              start: s.start,
              end: s.end,
              name: 'Overlap: ' + s.position_name,
            });
          } else if (moment(s.start).isSame(t.start, 'day')) {
            u.notifications.push({
              start: s.start,
              end: s.end,
              name: 'Working: ' + s.position_name,
            });
          }
        }
      }

      if (u._selected) {
        // Add on the new minutes;
        for (let t of timeArray) {
          let weekKey = Math.floor(
            moment(t.start).diff(payrollStart, 'weeks', true)
          );
          if (weekKey > 0)
            u.weekMinutes[weekKey] += moment(t.end).diff(t.start, 'minutes');
        }
      }

      for (let t of u.timeOffs) {
        let timeRange = moment.range(t.start_date, t.end_date);
        for (let e of timeArray) {
          let tRange = moment.range(e.start, e.end);
          if (timeRange.overlaps(tRange)) {
            u.conflicts.push({
              start: t.start_date,
              end: t.end_date,
              name: 'Time Off',
            });
            break; // show this only once, not once for each day
          }
        }
      }
    }

    for (let u of this.users) {
      if (u.is_salary) continue;
      if (u.weekMinutes[0] > u.max_hours * 60) {
        u.conflicts.push({
          minutes: u.weekMinutes[0] - u.max_hours * 60,
          name: 'Overtime',
        });
      } else if (u.weekMinutes[1] > u.max_hours * 60) {
        u.conflicts.push({
          minutes: u.weekMinutes[1] - u.max_hours * 60,
          name: 'Overtime',
        });
      }
    }
  }

  filterUsersByPosition() {
    this.users = this._allUsers.filter(e => {
      if (!e.positions) return false;
      for (let p of e.positions) {
        if (p.id == this.positionId) return true;
      }
      return false;
    });
    this.unassigned = this.users.length == 0 ? 1 : 0;
  }

  changeSelected() {
    let tmp = this.days.filter(e => {
      return e._selected;
    });
    this.selectedDays = [];
    tmp.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });
    for (let t of tmp) {
      this.selectedDays.push(t.date.getDay());
    }
    this.calcUserStats();
    this.calcConflicts();
  }

  cancel() {
    this.modal.hide();
  }

  private createShift(date: Date, user_id: number, fixDate = false): IShift {
    this.breakTimeOffset = 0;
    let data: IShift = {
      start: new Date(date),
      end: new Date(date),
      position_id: this.positionId,
      user_id: user_id,
    };

    data.start.setSeconds(0);
    data.end.setSeconds(0);
    data.start.setMilliseconds(0);
    data.end.setMilliseconds(0);
    data.start.setHours(this.startTime.getHours());
    data.start.setMinutes(this.startTime.getMinutes());
    data.end.setHours(this.endTime.getHours());
    data.end.setMinutes(this.endTime.getMinutes());

    // Is end time before the start time? It's in the next day.
    if (fixDate && data.end.getTime() <= data.start.getTime()) {
      data.end.setDate(data.end.getDate() + 1);
      this.breakTimeOffset = 1;
    }

    if (this.firstBreak.toggle) {
      data.first_break_time = createShiftBreakTime(
        date,
        this.firstBreak.time,
        this.breakTimeOffset
      );
      data.first_break_length = this.firstBreak.length;
    }
    if (this.secondBreak.toggle) {
      data.second_break_time = createShiftBreakTime(
        date,
        this.secondBreak.time,
        this.breakTimeOffset
      );
      data.second_break_length = this.secondBreak.length;
    }
    if (this.thirdBreak.toggle) {
      data.third_break_time = createShiftBreakTime(
        date,
        this.thirdBreak.time,
        this.breakTimeOffset
      );
      data.third_break_length = this.thirdBreak.length;
    }

    return data;
  }

  save() {
    this.errors = [];
    let shifts: IShift[] = [];
    let selectedUsers = this.users.filter(e => {
      return e._selected;
    });
    let selectedDays = this.days.filter(e => {
      return e._selected;
    });

    if (!this.unassigned && selectedUsers.length == 0) {
      this.errors.push('You must select a placeholder or staff for this shift');
    }
    if (selectedDays.length == 0) {
      this.errors.push('You must select one or more days for this shift');
    }

    for (let d of selectedDays) {
      for (let u of selectedUsers) {
        shifts.push(this.createShift(d.date, u.id, true));
      }
      for (let i = 0; i < this.unassigned; i++) {
        shifts.push(this.createShift(d.date, null, true));
      }
    }

    this.errors.push(...shiftBreakValidation(shifts));

    // Filter duplicate errors from array
    this.errors = [...new Set(this.errors)];

    const cleanErrors = this.errors.filter(function (item) {
      return item != null;
    });

    if (cleanErrors.length > 0) return;
    this.shiftService.bulkUpdate(shifts).subscribe(data => {
      this.modal.hide();
      localStorage.setItem('scrollPosition', window.scrollY.toString());
      this.router.navigate(['/schedule']);
    });
  }
}
