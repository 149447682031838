<div class="row">
  <div class="col-md-12 d-none d-md-block" style="min-height: 100px">
    &nbsp;
  </div>
</div>

<div class="row justify-content-center" *ngIf="data">
  <div class="col-md-4 col-10 text-center card">
    <div class="card-body">
      <div style="width: 100%" class="my-4">
        <span class="cdi-l-logo cdi-biggest mx-auto"></span>
      </div>
      <h4>Please register with Cheddrsuite</h4>
      <form (ngSubmit)="verify()" ngNativeValidate>
        <label>{{data.firstname}} {{data.lastname}}</label>
        <label style="text-transform: none">{{data.username}}</label>
        <local-error [error]="error.token"></local-error>
        <input
          class="form-control"
          type="password"
          placeholder="Password"
          name="password"
          [(ngModel)]="data.password"
          (ngModelChange)="checkPassword()"
          required />
        <input
          class="form-control"
          type="password"
          placeholder="Confirm Password"
          name="cpassword"
          debounce
          [delay]="500"
          (result)="matchPassword()"
          [(ngModel)]="data.cpassword"
          required />
        <local-error [error]="error.matchPassword"></local-error>
        <local-error [error]="warning"></local-error>
        <local-error-array [errors]="suggestions"></local-error-array>
        <!--	<input class="form-control" type="text" bsDatepicker placeholder="Date Of Birth" name="dob" [(ngModel)]="data.dob" required>-->
        <p>
          <input type="checkbox" name="tnc" value="1" [(ngModel)]="tnc" /> I
          agree to the
          <a target="_blank" href="https://www.cheddrsuite.com/terms"
            >Terms &amp; Conditions</a
          >
          and
          <a target="_blank" href="https://www.cheddrsuite.com/terms"
            >Privacy Policy</a
          >
        </p>
        <button
          class="btn btn-primary"
          [disabled]="!(tnc && data.password && data.cpassword)">
          Continue
        </button>
        <local-error class="mt-2" [error]="error.global"></local-error>
      </form>
    </div>
  </div>
</div>
