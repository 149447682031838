import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { LocationService, ILocation } from '../services/location.service';
import {
  PermissionLocationService,
  IPermissionLocation,
  Permission,
  PermissionCategory,
  PermissionNames,
} from '../services/permissionLocation.service';
import { baseRole } from '../shared/baseRole';
import { UserType } from '../shared/api';

@Component({
  templateUrl: './permissions.html',
})
export class PermissionsComponent extends baseRole implements OnInit {
  public loc: ILocation;
  public perms: any[];
  public UserType = UserType;
  public PermissionCategory = PermissionCategory;
  public PermissionCategoryKeys = Object.keys(PermissionCategory);
  public PermissionNames = PermissionNames;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    protected permissionLocationService: PermissionLocationService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.accountService.getLocation()) {
      this.router.navigate(['/settings/account']);
      return;
    }
    this.locationService
      .get(this.accountService.getLocation().id)
      .subscribe(data => {
        this.loc = data;
      });
    this.permissionLocationService
      .list({ location_id: this.accountService.getLocation().id })
      .subscribe(data => {
        this.permsToArray(data);
      });
  }

  editLoc() {
    this.loc._edit = {
      id: this.loc.id,
      level_1: this.loc.level_1,
      level_2: this.loc.level_2,
      level_3: this.loc.level_3,
    };
  }
  saveLoc() {
    this.locationService.update(this.loc._edit).subscribe(data => {
      this.loc.level_1 = this.loc._edit.level_1;
      this.loc.level_2 = this.loc._edit.level_2;
      this.loc.level_3 = this.loc._edit.level_3;
      delete this.loc._edit;
    });
  }
  cancelLocEdit() {
    delete this.loc._edit;
  }

  toggleEdit(obj) {
    this.permissionLocationService
      .update({
        id: obj.id,
        permission_id: obj.permission_id,
        user_type: obj.user_type,
        value: obj.value,
      })
      .subscribe(() => {
        if (obj.user_type == this.getLocation().user_type) {
          this.getLocation().permissions[obj.permission_id] = obj.value;
        }
      });
  }

  reset() {
    this.permissionLocationService.reset(this.loc.id).subscribe(data => {
      let loc = this.getLocation();
      for (let d of data) {
        if (d.user_type == loc.user_type) {
          loc.permissions[d.permission_id] = d.value;
        }
      }
      this.permsToArray(data);
    });
  }

  permsToArray(data) {
    this.perms = [];
    for (let i = 0; i <= Object.keys(Permission).filter(Number).length; i++) {
      this.perms[i] = [];
    }
    for (let d of data) {
      this.perms[d.permission_id][d.user_type] = d;
    }
  }
}
