import { PaymentService } from 'source/services/payment.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { UserService } from '../services/user.service';
import { LocationService } from '../services/location.service';

import {
  PackageType,
  getSignInUrl,
  getPasswordResetUrl,
  UserType,
  LocationStatus,
} from '../shared/api';

@Component({
  templateUrl: './home.html',
})
export class HomeComponent implements OnInit {
  public searchUser: string;
  public searchLocation: string;
  public dataLoc: any[];
  public sort = ['name', 'id'];
  public dataUser: any[];
  public PackageType = PackageType;
  public UserType = UserType;
  public LocationStatus = LocationStatus;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected locationService: LocationService,
    protected accountService: AccountService,
    protected paymentService: PaymentService
  ) {}

  ngOnInit() {}

  findUser() {
    this.dataLoc = null;
    this.dataUser = null;

    this.sort = ['lastname', 'id'];
    if (!this.searchUser) return;
    this.userService.search({ search: this.searchUser }).subscribe(data => {
      for (let d of data) {
        if (d.password_token) {
          d.passwordUrl = getPasswordResetUrl(d.password_token, d.email);
          console.log(d.passwordUrl);
        }
      }
      this.dataUser = data;
    });
  }

  newestUser() {
    this.dataLoc = null;
    this.dataUser = null;

    this.sort = ['-created_at', 'id'];
    this.userService.search({ newest: true }).subscribe(data => {
      this.dataUser = data;
    });
  }

  findLocation() {
    this.dataLoc = null;
    this.dataUser = null;
    this.sort = ['name'];

    if (!this.searchLocation) return;
    this.locationService
      .search({ search: this.searchLocation })
      .subscribe(data => {
        this.dataLoc = data;
      });
  }

  newestLocation() {
    this.dataLoc = null;
    this.dataUser = null;

    this.sort = ['-created_at', 'id'];
    this.locationService.search({ newest: true }).subscribe(data => {
      this.dataLoc = data;
    });
  }

  signinAs(user) {
    this.userService.getAdminToken(user.id).subscribe(token => {
      user.token = getSignInUrl(token);
      setTimeout(() => {
        user.token = null;
      }, 5000);
    });
  }
}
