import { IInventoryReport } from './../../../api/source/models/database/types';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, SelectAddAll } from '../services/account.service';
import { InventoryCountService } from '../services/inventoryCount.service';
import { baseRole } from '../shared/baseRole';
import { ExpenseType, moment } from '../shared/api';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
@Component({
  templateUrl: './inventoryCount.html',
})
export class InventoryCountComponent extends baseRole implements OnInit {
  public loc: any;
  public now: Date;
  public totalNum = 0;
  public totalPrice = 0;
  public expenseAccounts: IExpenseAccount[];
  // public filterInventoryData: IInventoryReport[];
  // public filterValue: number;
  public filterKey = 'inventory_sku_filter_key';
  public filterData: {
    expense_account_id: string;
  };
  public _inventoryData: IInventoryReport[];
  public inventoryData: IInventoryReport[];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected inventoryCountService: InventoryCountService,
    protected accountService: AccountService,
    protected expenseAccountService: ExpenseAccountService
  ) {
    super(accountService);
  }
  getDefaultFilter() {
    return {
      expense_account_id: '',
    };
  }
  ngOnInit() {
    this.loc = this.accountService.getLocation();
    this.now = new Date();
    this.filterData = this.accountService.getTempState(
      this.filterKey,
      this.getDefaultFilter()
    );

    this.inventoryCountService.reportQuantity().subscribe(data => {
      for (let d of data) {
        this.totalNum += d.quantity;
        this.totalPrice += d.total;
      }
      this.inventoryData = data;
      this._inventoryData = data;
    });
    this.expenseAccountService
      .list({ expense_type: ExpenseType.COGS })
      .subscribe(data => {
        this.expenseAccounts = SelectAddAll(data, 'All COG Categories');
      });
  }

  filename() {
    return 'inventory_quantity_' + moment().format('Y_M_D') + '.csv';
  }

  filter() {
    this.totalNum = 0;
    this.totalPrice = 0;
    this.accountService.setTempState(this.filterKey, this.filterData);

    if (this.filterData.expense_account_id) {
      this.inventoryData = this._inventoryData.filter(
        e => e.expense_account_id === Number(this.filterData.expense_account_id)
      );
    } else {
      this.inventoryData = this._inventoryData;
    }
    for (let d of this.inventoryData) {
      this.totalNum += d.quantity;
      this.totalPrice += d.total;
    }
  }
}
