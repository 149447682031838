<div class="row p-container nav-shift" *ngIf="data">
  <div class="col-12">
    <form class="card" ngNativeValidate (submit)="save()">
      <div class="card-body">
        <div class="form-group">
          <label>Notification Type</label>
          <select
            name="notification_type"
            class="form-control"
            required
            [(ngModel)]="data.notification_type"
            (ngModelChange)="typeChange()">
            <option *ngFor="let t of NotificationTypeKeys" [value]="t">
              {{NotificationType[t]}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>To</label>
          <input
            class="form-control"
            type="text"
            name="to"
            [(ngModel)]="data.to"
            required
            maxlength="255" />
        </div>
        <div class="form-group">
          <label>Active</label>
          <input-yes-no
            name="active"
            [(ngModel)]="data.is_active"></input-yes-no>
        </div>
        <div class="form-group">
          <label>Subject</label>
          <input
            class="form-control"
            type="text"
            name="subject"
            [(ngModel)]="data.subject"
            required
            maxlength="255" />
          <local-error [error]="error.subject"></local-error>
        </div>
        <div class="form-group">
          <label for="cell">Text Message</label>
          <textarea
            class="form-control"
            name="text_message"
            [(ngModel)]="data.email_text"></textarea>
          <local-error [error]="error.email_text"></local-error>
        </div>
        <div class="form-group">
          <label for="cell">HTML Message</label>
          <textarea
            class="form-control"
            name="html_message"
            [(ngModel)]="data.email_html"></textarea>
          <local-error [error]="error.email_html"></local-error>
        </div>
        <div class="form-group">
          <label for="cell">SMS Message</label>
          <textarea
            class="form-control"
            name="sms_message"
            [(ngModel)]="data.sms_text"></textarea>
          <local-error [error]="error.sms_text"></local-error>
        </div>
        <div class="form-group">
          <label for="cell">Attachment Name</label>
          <input
            class="form-control"
            name="attachment_name"
            [(ngModel)]="data.attachment_name" />
          <local-error [error]="error.attachment_name"></local-error>
        </div>
        <cancel-button class="float-right" routerLink=".."></cancel-button>
        <save-button class="float-right"></save-button>
      </div>
    </form>
  </div>
</div>

<div class="row p-container d-flex" *ngIf="data && data.id">
  <div class="col-md-6 col-12">
    <form (submit)="sendTest()" ngNativeValidate>
      <textarea
        name="testdata"
        [(ngModel)]="testDataString"
        required
        class="form-control"
        style="min-height: 300px"></textarea>
      <local-error [error]="testError"></local-error>
      <button class="btn btn-primary float-right" *ngIf="!testSend">
        Test Send
      </button>
    </form>
    <div class="alert alert-success" *ngIf="testSend">{{testSend}}</div>
  </div>
  <div class="col-md-3 col-6">
    <h6>Pre Defined</h6>
    <code><pre>{{ predefined | json }}</pre></code>
  </div>
  <div class="col-md-3 col-6">
    <h6>Also available</h6>
    <code>
      <pre>{{ NotificationTypeDataHints[data.notification_type] | json }}</pre>
    </code>
  </div>
</div>
