<modal-doc-stats #modalDocStats></modal-doc-stats>
<modal-doc-sign
  [perms]="perms"
  (onComplete)="done()"
  #modalDocSign></modal-doc-sign>
<modal-view-file #modalViewFile></modal-view-file>
<doc-quiz-view
  [perms]="perms"
  (onClose)="done()"
  #modalQuizView></doc-quiz-view>
<div class="text-center" *ngIf="doc">
  <div class="doc-preview mb-2" (click)="!viewOnly && modalViewFile.show(doc)">
    <h5>{{doc.name}}</h5>
    <img
      class="img-fluid rounded"
      *ngIf="isVideo()"
      [src]="'https://i1.ytimg.com/vi/'+youTubeUrlToId()+'/mqdefault.jpg'" />
    <img
      class="img-fluid rounded"
      *ngIf="isImage()"
      [src]="getBaseUrl()+'doc/image/thumb/'+doc.id" />
    <img
      class="img-fluid rounded"
      *ngIf="isEmpty()"
      src="/images/default/default-doc.svg" />
    <img
      class="img-fluid"
      *ngIf="isPdf()"
      src="/images/default/default-pdf.svg" />
  </div>
  <div *ngIf="canEdit" style="width: 100%">
    <delete-modal
      class="float-right"
      (onDelete)="deleteDoc(doc)"
      [name]="doc.name"
      [deleteObject]="doc">
      <span class="pointer cdi-s-trash pointer"></span>
    </delete-modal>
    <span
      class="pointer cdi-s-edit float-left"
      [routerLink]="['/docs',doc.doc_group_id,doc.id]"></span>
  </div>

  <div *ngIf="viewOnly && doc.signature">
    {{(doc.signature_id?'SIGNED':'UNSIGNED')}}
  </div>
  <div *ngIf="viewOnly && doc.quizReady">
    QUIZ {{(doc.quiz_passed_id?'PASSED':'UNTAKEN')}}
  </div>
  <button
    type="button"
    class="btn btn-block"
    *ngIf="!viewOnly && doc.signature && !doc.signature_id"
    (click)="modalDocSign.show(doc)">
    SIGN
  </button>

  <button
    type="button"
    class="btn btn-block bg-success text-white"
    *ngIf="!viewOnly && doc.signature && doc.signature_id">
    SIGNED
  </button>
  <button
    type="button"
    class="btn btn-block"
    [class.bg-success]="doc.quiz_passed_id"
    [class.text-white]="doc.quiz_passed_id"
    *ngIf="!viewOnly && doc.quiz && doc.quizReady"
    (click)="modalQuizView.show(doc)">
    <span *ngIf="!doc.quiz_passed_id">TAKE QUIZ</span>
    <span *ngIf="doc.quiz_passed_id">PASSED QUIZ</span>
  </button>
  <button
    type="button"
    class="btn btn-block"
    *ngIf="canEdit && doc.quiz"
    routerLink="{{'./'+doc.id+'/quiz'}}">
    EDIT QUIZ
  </button>
  <button
    type="button"
    class="btn btn-block"
    *ngIf="canEdit && (doc.quiz || doc.signature)"
    (click)="modalDocStats.show(doc)">
    DOC STATS
  </button>
</div>
