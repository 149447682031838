import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { IBlackOut, BlackOutService } from '../services/blackOut.service';
import { ILocation, LocationService } from '../services/location.service';
import { ScheduleSortOrder, DaysOfWeek } from '../shared/api';

@Component({
  templateUrl: './schedule.html',
})
export class ScheduleComponent implements OnInit {
  public loc: ILocation;
  public blackOuts: IBlackOut[];
  public blackOutStart: Date;
  public blackOutEnd: Date;
  public ScheduleSortOrder = ScheduleSortOrder;
  public DaysOfWeek = DaysOfWeek;
  public DaysOfWeekKeys = Object.keys(DaysOfWeek).filter(e => {
    return e.toString().length < 3;
  });

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected blackOutService: BlackOutService,
    protected locationService: LocationService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    if (!this.accountService.getLocation()) {
      this.router.navigate(['/settings/account']);
      return;
    }
    this.blackOutService.listCurrent().subscribe(data => {
      this.blackOuts = data;
    });
    this.locationService
      .get(this.accountService.getLocation().id)
      .subscribe(data => {
        this.loc = data;
      });
  }

  saveLocationField(fieldName) {
    let updateObj: any = {
      id: this.loc.id,
    };

    updateObj[fieldName] = (<any>this.loc)[fieldName];
    this.locationService.update(updateObj).subscribe(data => {});
  }

  saveMinMax() {
    let updateObj: any = {
      id: this.loc.id,
      min_labor: this.loc.min_labor,
      max_labor: this.loc.max_labor,
    };

    this.locationService.update(updateObj).subscribe(data => {});
  }

  saveBlackout() {
    if (this.blackOutStart) {
      //user can hit save with no data, db error
      let b = {
        start: this.blackOutStart,
        end: this.blackOutEnd,
      };
      this.blackOutService.update(b).subscribe(result => {
        this.blackOuts.push(result);
        this.blackOutStart = null;
        this.blackOutEnd = null;
      });
    }
  }

  deleteBlackout(obj) {
    this.blackOutService.delete(obj.id).subscribe(data => {
      let idx = this.blackOuts.indexOf(obj);
      this.blackOuts.splice(idx, 1);
    });
  }
}
