import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ISubNavItem } from '../shared/api';

@Component({
  templateUrl: './admin.html',
})
export class AdminComponent implements OnInit {
  public menu: ISubNavItem[] = [
    {
      label: 'Main',
      routerLink: '/admin',
      exact: true,
    },
    {
      label: 'Reports',
      routerLink: '/admin/billing',
    },
    // {
    //   label: 'Billing Plans',
    //   routerLink: '/admin/billingPlan',
    // },
    {
      label: 'Notifications',
      routerLink: '/admin/notification',
    },
    {
      label: 'Test Stuff',
      routerLink: '/admin/test',
    },
  ];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    // Turn on the test stuff
    if (
      this.accountService.getUser().email != 'robert.kugler@cheddrsuite.com'
    ) {
      this.menu = this.menu.filter(e => {
        return e.routerLink.indexOf('/admin/test') < 0;
      });
    }
  }
}
