import { PaymentService } from './../services/payment.service';
import { IApiLocation } from './../shared/types';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService, IUser } from '../services/account.service';
import { BillingPlanService } from '../services/billingPlan.service';
import {
  LocationService,
  ILocation,
  LocationStatus,
} from '../services/location.service';
import { MediaService } from '../services/media.service';
import { IntegrationService } from '../services/integration.service';
import {
  UserCreditCardService,
  IUserCreditCard,
} from '../services/userCreditCard.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TimezoneService } from '../services/timezone.service';
import { loadStripe } from '@stripe/stripe-js';
import {
  Countries,
  PackageType,
  PackageTypeNames,
  Provinces,
  States,
  SalesIntegrationType,
  SalesIntegrationFeatures,
  BillingPlanType,
  isIntegrationEnabled,
  isIntegrationActive,
  moment,
} from '../shared/api';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Component({
  templateUrl: './account.list.html',
})
export class AccountListComponent extends baseRole implements OnInit {
  public SalesIntegrationType = SalesIntegrationType;
  public PackageType = PackageType;
  public PackageTypeNames = PackageTypeNames;
  public BillingPlanType = BillingPlanType;
  public SalesIntegrationFeatures = SalesIntegrationFeatures;
  public locations: ILocation[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    protected accountService: AccountService,
    protected paymentService: PaymentService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.locationService.list().subscribe(data => {
      this.locations = data;
    });
  }

  createStripePortalSession(locationID: number) {
    this.paymentService
      .createStripePortalSession('settings/account', locationID)
      .subscribe((data: any) => {
        window.location.href = data;
      });
  }
}

@Component({
  templateUrl: './account.edit.html',
})
export class AccountEditComponent extends baseRole implements OnInit {
  public locId: number;
  public loc: any;
  public States = States;
  public Provinces = Provinces;
  public Countries = Countries;
  public StateKeys = Object.keys(States);
  public ProvinceKeys = Object.keys(Provinces);
  public CountryKeys = Object.keys(Countries);
  public timezones = [];
  public SalesIntegrationType = SalesIntegrationType;
  public saveError: string;
  public saveMessage: string;
  public fileData;
  public now: Date;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    protected timezoneService: TimezoneService,
    protected mediaService: MediaService,
    protected accountService: AccountService,
    protected integrationService: IntegrationService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.now = new Date();
    this.timezoneService.list().subscribe(data => {
      this.timezones = data;
    });
    this.route.params.subscribe(params => {
      this.locId = params['id'] || this.getLocationId();
      /*this.route.query.subscribe((query)=> {
			});*/

      this.load();
    });
  }

  load() {
    this.locationService.get(this.locId).subscribe(data => {
      this.loc = data;
    });
  }

  handleFileChange(files) {
    if (!files || files.length == 0) this.fileData = null;
    else this.fileData = files[0];
  }

  saveLoc() {
    this.saveMessage = 'Saving now...';
    let updateObj = {
      id: this.loc.id,
      name: this.loc.name,
      address_1: this.loc.address_1,
      address_2: this.loc.address_2,
      city: this.loc.city,
      state: this.loc.state,
      country: this.loc.country,
      zip: this.loc.zip,
      timezone_id: this.loc.timezone_id,
    };

    let p = [];
    p.push(this.locationService.update(updateObj));
    if (this.fileData) {
      p.push(
        this.mediaService.upload(
          this.fileData,
          'location',
          this.getLocationId()
        )
      );
    }

    forkJoin(p).subscribe(data => {
      this.saveMessage = 'Done';
      if (this.fileData) {
        this.loc.has_image = <string>data[1];
      }
      setTimeout(() => {
        this.saveMessage = null;
      }, 3000);
    });
  }
}

@Component({
  templateUrl: './account.integration.html',
})
export class AccountEditIntegrationComponent
  extends baseRole
  implements OnInit
{
  public locId: number;
  public loc: any;
  public isEnabled = false;
  public squareUrl: string;
  public lightspeedUrl: string;
  public currentIntegrationType: number;
  public SalesIntegrationType = SalesIntegrationType;
  public SalesIntegrationFeatures = SalesIntegrationFeatures;
  public SalesIntegrationKeys = SalesIntegrationFeatures.filter(e => {
    return e != null && e.id != SalesIntegrationType.Toast;
  }).map(e => {
    return { id: e.id, name: e.name };
  });
  public PackageType = PackageType;
  public saveError: string;
  public error: boolean = null;
  public saveMessage: string;
  public packageMessage: string;
  public getAllLocations: IApiLocation[];

  public step = 1;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    protected integrationService: IntegrationService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.locId = params['id'] || this.getLocationId();
      this.load();
    });
    this.integrationService.squareUrl().subscribe(data => {
      this.squareUrl = data;
    });
    this.integrationService.lightspeedUrl().subscribe(data => {
      this.lightspeedUrl = data;
    });
  }

  isIntegrationEnabled(): boolean {
    if (!this.loc) return false;
    return isIntegrationEnabled(this.loc);
  }

  load() {
    // Set the location with the current location id.
    this.accountService.setLocation(this.locId, false).subscribe(() => {});

    // Get the location data.
    this.locationService.get(this.locId).subscribe(data => {
      this.loc = data;
      this.loc.currentIntegrationType = this.loc.sales_integration_type;
      if (!this.loc.sales_integration_type)
        this.loc.sales_integration_type = SalesIntegrationType.Clover;

      this.isEnabled = isIntegrationEnabled(this.loc);

      if (this.isEnabled) {
        if (this.loc.sales_integration_type === SalesIntegrationType.Square) {
          this.integrationService.squareLocations().subscribe(data => {
            this.loc.square_locations = data;
          });
        }

        if (!this.loc.sales_integration_merchant_id) {
          // Failed.
        } else if (!this.loc.sales_integration_location_id) {
          if (
            this.loc.sales_integration_type === SalesIntegrationType.Lightspeed
          ) {
            this.integrationService.lightspeedLocations().subscribe(data => {
              //console.log('Square Locations');
              //console.log(data);
              if (data && data.length > 0) {
                this.locationService
                  .update({
                    id: this.locId,
                    sales_integration_location_id: data[0].id,
                  })
                  .subscribe(() => {
                    this.loc.sales_integration_location_id = data[0].id;
                    //console.log('Updating to first one');
                  });
              }
            });
          }
        }
      }
    });
  }
  integrationDone(result: boolean) {
    console.log('Got Done: ' + result);
    if (result) {
      this.step = 3;
    } else {
      this.step = 1;
    }
  }

  saveIntegrationType() {
    this.error = false;

    // Get square location name with location id
    if (this.loc.sales_integration_type === SalesIntegrationType.Square) {
      // Get all locations
      this.getAllLocations = this.accountService.getLocations();

      // Check if sales integration location id is empty
      if (this.loc.sales_integration_location_id === '') {
        this.error = true;
        this.saveError = 'Please select a location';
        return;
      } else if (
        // Check if sales integration location id is not in the list of locations
        this.getAllLocations.filter(
          location =>
            location.sales_integration_location_id ===
            this.loc.sales_integration_location_id
        ).length > 0
      ) {
        this.error = true;
        this.saveError =
          'This location is already in use. Please select a different location.';
        return;
      }
      if (this.error) return;

      this.saveError = null;
      // Set sales integration location name
      for (let square_locations of this.loc.square_locations) {
        if (square_locations.id === this.loc.sales_integration_location_id) {
          this.loc.sales_integration_location_name = square_locations.name;
        }
      }
      this.locationService
        .update({
          id: this.loc.id,
          sales_integration_location_id: this.loc.sales_integration_location_id,
          sales_integration_location_name:
            this.loc.sales_integration_location_name,
        })
        .subscribe(data => {
          this.step = 2;
        });
    } else {
      // Save other integration type
      this.saveError = null;
      this.integrationService
        .setSalesIntegrationType({
          location_id: this.loc.id,
          sales_integration_type: this.loc.sales_integration_type,
        })
        .subscribe(data => {
          this.step = 2;
        });
    }
  }
}

@Component({
  templateUrl: './account.package.html',
})
export class AccountEditPackageComponent extends baseRole implements OnInit {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {
    super(accountService);
  }
  ngOnInit() {}
}
