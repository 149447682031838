<tabset #tabs>
  <tab heading="Reviews" (selectTab)="changeTab(true)"></tab>
  <tab heading="Write Ups" (selectTab)="changeTab(false)"></tab>
</tabset>

<div class="row">
  <div class="col-12">
    <div class="card" *ngIf="data && data.length==0">
      <div class="card-body">None Found</div>
    </div>

    <div class="card" *ngFor="let r of data | orderBy:'-created_at'">
      <div class="card-body">
        <label>{{r.created_at | date:'MMMM d, y, h:mm a'}}</label>
        <strong>By:</strong>&nbsp; {{ r.firstname }} {{ r.lastname }}
        <p class="px-3 pt-2">{{r.body}}</p>
        <button
          class="btn btn-primary"
          *ngIf="!isReview &&  !r.signature_id"
          type="button"
          (click)="showSig(r)">
          Sign
        </button>
        <img
          class="signature-display"
          *ngIf="r.signature_id"
          [src]="baseUrl+'signature/'+r.signature_id+'/view'" />
      </div>
    </div>
  </div>
</div>

<writeup-sign #writeupSign (onClose)="doWriteupSign()"></writeup-sign>
