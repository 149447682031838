<div
  bsModal
  #modalDocSign="bs-modal"
  [config]="{backdrop: 'static', ignoreBackdropClick: true, keyboard: false }"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Sign This Writeup"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" *ngIf="writeup">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Sign This Writeup
          <button
            *ngIf="showClose"
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancelSig()">
            <span aria-hidden="true">&times;</span>
          </button>
          <br />
        </div>

        <div *ngIf="!showClose">
          <label>{{writeup.created_at | date:'MMMM d, y, h:mm a'}}</label>
          <strong>By:</strong>&nbsp; {{ writeup.firstname }} {{ writeup.lastname
          }}
          <p class="px-3 pt-2">{{writeup.body}}</p>
        </div>
        <h4 class="text-center">
          By filling out the form below, you are legally signing your name to
          this writeup. By signing you are acknowledging that you have read this
          writeup and understand it's contents.
          <a
            href="https://my.uniformlaws.org/committees/community-home?communitykey=2c04b76c-2b7d-4399-977e-d5876ba7e034&tab=groupdetails"
            target="_blank"
            ><u>Tell me more about the Electronic Signatures Act.</u></a
          >
        </h4>

        <div class="modal-body" *ngIf="writeup && sig">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12"></div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <label>Type your Name</label>
                  <input
                    [(ngModel)]="sig.name"
                    type="text"
                    name="name"
                    class="form-control"
                    required />
                  <input-toggle name="checked" [(ngModel)]="checked">
                    I have read this Writeup
                  </input-toggle>
                </div>
                <div class="col-md-6 col-12">
                  <label
                    >Draw your Signature
                    <u
                      class="float-right"
                      (click)="hasDrawn = false;signature.clear()">
                      Clear</u
                    >
                  </label>
                  <signature
                    #signature
                    (hasDrawn)="hasDrawn = true"></signature>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                *ngIf="showClose"
                class="float-right"
                (click)="cancelSig()"></cancel-button>
              <save-button
                *ngIf="checked && hasDrawn && sig.name && sig.name.length>0"
                class="float-right"
                (click)="saveSig()"></save-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
