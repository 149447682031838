<div *ngIf="user && !newLocation">
  <div class="card">
    <div class="card-body">
      <img
        class="rounded float-left mb-2 mr-3"
        [src]="getUserProfileImage(
          user.has_image,
          user.firstname,
          user.lastname,
          user.color
        ) | safeUrl"
        height="80px"
        width="80px" />
      <h5 style="min-height: 80px" class="mb-2">
        Welcome, {{ user.firstname }} {{ user.lastname }}
      </h5>
      <br />

      <alert type="warning" *ngIf="!loc && !isAdmin()">
        <strong>You are not currently part of any business location. </strong
        ><br />
        <div class="pb-4">
          Would you like to create a new location?
          <button
            class="btn btn-primary float-right"
            type="button"
            (click)="newLocation=true">
            Add Location
          </button>
        </div>
      </alert>

      <alert type="success" *ngIf="isAdmin()" routerLink="/admin">
        <button type="button" class="btn btn-primary">Go</button>
        Jump to admin?
      </alert>

      <div *ngIf="!jailed">
        There are no items requiring your attention today. Please explore and
        take full advantage of all of the CheddrSuite features available to you.
      </div>

      <div *ngIf="loc && jailed">
        The items listed below are required before you can fully log into
        CheddrSuite.
        <hr />

        <alert type="danger" *ngIf="loc.jail_no_package">
          <button
            type="button"
            class="btn btn-primary"
            (click)="selectPackage()">
            Go
          </button>
          Please select a package to continue.
        </alert>

        <div *ngIf="!loc.payment_status">
          <alert
            type="danger"
            *ngIf="loc.billing_plan_type!=BillingPlanType.Monthly">
            This location has failed payment. Please contact Cheddrsuite to
            continue.</alert
          >

          <alert
            type="danger"
            *ngIf="loc.billing_plan_type==BillingPlanType.Monthly && inRole([ UserType.Manager, UserType.Owner])">
            <button
              type="button"
              class="btn btn-primary"
              (click)="selectPackage()">
              Go
            </button>
            This location has failed payment.
          </alert>

          <alert
            type="danger"
            *ngIf="loc.billing_plan_type==BillingPlanType.Monthly && !inRole([ UserType.Manager, UserType.Owner])">
            This location has failed payment. Please contact your manager.
          </alert>
        </div>

        <alert
          type="danger"
          *ngIf="user.jail_bad_password"
          routerLink="/profile/profile">
          <button type="button" class="btn btn-primary">Go</button>
          Please change your password.
        </alert>

        <alert
          type="warning"
          *ngIf="loc && loc.jail_profile"
          routerLink="/profile/profile">
          <button type="button" class="btn btn-primary">
            Step 1, Complete your profile
          </button>
          Congratulations, your account is setup. Please click the Complete your
          profile button to continue.
        </alert>

        <alert
          type="danger"
          *ngIf="loc && loc.jail_writeup"
          routerLink="/profile/reviews">
          <button type="button" class="btn btn-primary">Go</button>
          You must sign off on a writeup.
        </alert>

        <alert
          type="danger"
          *ngIf="loc && loc.jail_doc"
          routerLink="/profile/docs">
          <button type="button" class="btn btn-primary">Go</button>
          There is a document or a quiz that requires your attention.
        </alert>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="newLocation">
  <div class="card-body">
    <location-add (onComplete)="save()"></location-add>
  </div>
</div>

<div
  class="card"
  *ngIf="!newLocation && !jailed && inRole([UserType.Owner,UserType.Manager])">
  <div class="card-body">
    <h5 class="mb-1">Getting started with Cheddrsuite is easy!</h5>
    <p class="ml-2">
      Follow these simple steps to take full advantage of CheddrSuite. Click the
      question mark in the top right of the page to access our help at any time.
    </p>
    <ul class="mt-3 ml-2">
      <li>
        <strong>Step 1. Add employees</strong>
        <ol class="ml-5">
          <li>Adding employees is easy! Navigate to the Staff section.</li>
          <li>Click the “Add Staff” button at the top of the page.</li>
          <li>
            Fill out the employee’s first and last name, email address,
            position, and pay rate.
          </li>
          <li>The employee will receive an email and create an account.</li>
        </ol>
        <div class="mt-4">
          <button
            routerLink="/users/user/add"
            type="button"
            class="btn btn-primary mx-2">
            Start now
          </button>
          <a
            href="https://support.cheddrsuite.com/support/solutions/articles/24000044441-step-2-on-board-employees"
            class="btn btn-secondary"
            target="_blank"
            >Get help</a
          >
        </div>
        <hr class="mt-4" />
      </li>

      <li>
        <strong>Step 2. Create a Schedule</strong><br />
        <p class="ml-2">
          Once you have added your employees to the system, set up a schedule.
        </p>
        <ol class="ml-5">
          <li>Navigate to the Schedule section</li>
          <li>Select the Calendar Week you want to schedule for</li>
          <li>Select “Add Shift” from the top left of the page</li>
          <li>
            Select the position, days, hours and employees you want to schedule
          </li>
          <li>
            Click the “Publish Schedule” button – notifications will be sent to
            all employees
          </li>
        </ol>
        <div class="mt-4">
          <button
            routerLink="/schedule"
            type="button"
            class="btn btn-primary mx-2">
            Start now
          </button>
          <a
            href="https://support.cheddrsuite.com/support/solutions/articles/24000044442-step-3-create-an-employee-schedule"
            class="btn btn-secondary"
            target="_blank">
            Get help
          </a>
        </div>
        <hr class="mt-4" />
      </li>
      <li *ngIf="loc">
        <strong>Step 3. Integrate your POS</strong><br />
        <p class="ml-2">Cheddr works better with daily sales data!</p>
        <ol class="ml-5">
          <li>Go to Settings &gt; Account</li>
          <li>
            Select "Edit Integration" on the location you wish to connect with
            your POS.
          </li>
          <li>Select your POS type from the dropdown menu.</li>
          <li>Click “Change Provider” or “Set API Keys”</li>
          <li>Follow Instructions</li>
        </ol>
        <div class="mt-4">
          <button
            [routerLink]="'/settings/account/'+getLocationId()+'/integration'"
            type="button"
            class="btn btn-primary mx-2"
            [disabled]="loc.package_type!=3">
            Start now
          </button>
          <a
            href="https://support.cheddrsuite.com/support/solutions/articles/24000044448-step-4-integrate-pos-with-cheddrsuite"
            class="btn btn-secondary"
            target="_blank">
            Get help
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>
