<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Edit Shift
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <global-error *ngFor="let error of errors" [error]="error"></global-error>
      <div class="modal-body" *ngIf="data">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body" *ngIf="positions">
                <label>Choose a Position</label>
                <select
                  [(ngModel)]="data.position_id"
                  name="modal_edit_position_id"
                  class="form-control d-inline-block"
                  (ngModelChange)="filterUsersByPosition()">
                  <option
                    *ngFor="let p of positions | orderBy: 'name'"
                    [value]="p.id">
                    {{p.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-body">
                <!-- make sure this starts on the first day of the work week as in settings-->
                <label>Choose a Day </label>
                <select
                  [(ngModel)]="startIndex"
                  class="form-control d-inline-block"
                  name="date"
                  (ngModelChange)="updateIndex()">
                  <option *ngFor="let d of days;let i = index" [value]="i">
                    {{ d|date: 'EE MMM d'}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-body">
                <label>Start Time</label>
                <timepicker [(ngModel)]="data.start" name="start"></timepicker>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-body">
                <label>End Time</label>
                <timepicker [(ngModel)]="data.end" name="end"></timepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="card my-1">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label>Break</label>

                  <div class="custom-control custom-switch custom-switch-lg">
                    <input
                      [(ngModel)]="firstBreak.toggle"
                      type="checkbox"
                      class="custom-control-input"
                      id="firstBreakEditSwitch" />
                    <label
                      class="custom-control-label"
                      for="firstBreakEditSwitch">
                    </label>
                  </div>
                </div>

                <div *ngIf="firstBreak.toggle" class="row">
                  <div class="col-xl-8 col-lg-12 col-sm-8 col-12">
                    <timepicker
                      [(ngModel)]="data.first_break_time"
                      name="firstBreak"
                      minuteStep="15"></timepicker>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-sm-4 col-12">
                    <select
                      class="form-control"
                      style="max-width: 7rem"
                      [(ngModel)]="data.first_break_length">
                      <option *ngFor="let number of numbers" [ngValue]="number">
                        <span *ngIf="number == 0">None</span>
                        <span *ngIf="number != 0"> {{number}}min</span>
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="card my-1">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label>Break</label>
                  <div class="custom-control custom-switch custom-switch-lg">
                    <input
                      [(ngModel)]="secondBreak.toggle"
                      type="checkbox"
                      class="custom-control-input"
                      id="secondBreakEditSwitch" />
                    <label
                      class="custom-control-label"
                      for="secondBreakEditSwitch">
                    </label>
                  </div>
                </div>
                <div *ngIf="secondBreak.toggle" class="row">
                  <div class="col-xl-8 col-lg-12 col-sm-8 col-12">
                    <timepicker
                      [(ngModel)]="data.second_break_time"
                      name="secondBreak"
                      minuteStep="15"></timepicker>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-sm-4 col-12">
                    <select
                      class="form-control"
                      style="max-width: 7rem"
                      [(ngModel)]="data.second_break_length">
                      <option *ngFor="let number of numbers" [ngValue]="number">
                        <span *ngIf="number == 0">None</span>
                        <span *ngIf="number != 0"> {{number}}min</span>
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="card my-1">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <label>Break</label>
                  <div class="custom-control custom-switch custom-switch-lg">
                    <input
                      [(ngModel)]="thirdBreak.toggle"
                      type="checkbox"
                      class="custom-control-input"
                      id="thirdBreakEditSwitch" />
                    <label
                      class="custom-control-label"
                      for="thirdBreakEditSwitch">
                    </label>
                  </div>
                </div>
                <div *ngIf="thirdBreak.toggle" class="row">
                  <div class="col-xl-8 col-lg-12 col-sm-8 col-12">
                    <timepicker
                      [(ngModel)]="data.third_break_time"
                      name="thirdBreak"
                      minuteStep="15"></timepicker>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-sm-4 col-12">
                    <select
                      class="form-control"
                      style="max-width: 7rem"
                      [(ngModel)]="data.third_break_length">
                      <option *ngFor="let number of numbers" [ngValue]="number">
                        <span *ngIf="number == 0">None</span>
                        <span *ngIf="number != 0"> {{number}}min</span>
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <label>Select Employee - <small>weekly labor stats</small> </label>
            <table class="table table-bordered table-sm inline-block">
              <thead class="thead-light">
                <tr>
                  <th class="align-middle text-center">Name</th>
                  <th class="align-middle text-center">Hours</th>
                  <th class="align-middle text-center">Shifts</th>
                  <th class="align-middle text-center">Cost</th>
                  <th class="align-middle text-center d-none d-sm-table-cell">
                    Preferences <small>Click</small>
                  </th>
                  <th class="align-middle text-center">Scheduled</th>
                  <!--<th class="align-middle text-center d-none d-sm-table-cell">All Prefs</th>-->
                </tr>
              </thead>
              <tbody *ngIf="users">
                <ng-container *ngFor="let u of users | orderBy:'lastname'">
                  <tr class="align-middle text-center">
                    <td class="btn-group-toggle">
                      <label class="btn btn-radio" [class.active]="u._selected">
                        <input
                          type="radio"
                          name="selected"
                          (click)="selectUser(u.id)" />
                        <strong>{{u.firstname}} {{u.lastname}}</strong
                        ><br /><small><phone [phone]="u.phone"></phone></small>
                      </label>
                    </td>
                    <td class="align-middle">
                      {{u.minutes | bliMinutes}}<br />
                      <small class="text-dark"
                        >Max {{u.max_hours}}/week {{u.ot_hours}}/day</small
                      >
                    </td>
                    <td class="align-middle text-center">
                      {{u.shifts.length}}
                    </td>
                    <td class="align-middle">{{u.cost | currency: 'USD' }}</td>
                    <td
                      class="align-middle d-none d-sm-table-cell pointer"
                      (click)="u._viewPref=!u._viewPref">
                      <work-pref-graph
                        [prefs]="u.pref_dna"
                        [day]="startIndex"
                        style="height: 2rem"></work-pref-graph>
                    </td>
                    <td class="align-middle">
                      <div *ngFor="let c of u.conflicts" class="text-danger">
                        {{ c.name }}
                        <span *ngIf="c.start">
                          {{ c.start | date: 'MMM d h:mm a'}} - {{ c.end | date:
                          'MMM d h:mm a'}} <br />
                        </span>
                        <span *ngIf="c.minutes">
                          with {{c.minutes |bliMinutes}} scheduled in pay week.
                        </span>
                      </div>
                      <span *ngFor="let c of u.notifications" class="text-dark">
                        {{ c.name}}
                        <span *ngIf="c.start">
                          {{ c.start | date: 'MMM d h:mm a'}} - {{ c.end | date:
                          'MMM d h:mm a'}} <br />
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="u._viewPref" (click)="u._viewPref=false">
                    <td colspan="6" class="pointer">
                      <shift-preferences
                        [(ngModel)]="u.pref_dna"
                        [disabled]="true"></shift-preferences>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
              <tbody>
                <tr class="align-middle text-center">
                  <td class="btn-group-toggle">
                    <label
                      class="btn btn-radio"
                      [class.active]="unassigned._selected">
                      <input
                        type="radio"
                        name="selected"
                        (click)="selectUser(null)" />
                      <strong>Unassigned</strong>
                    </label>
                  </td>
                  <td class="align-middle" colspan="5"></td>
                </tr>
              </tbody>
            </table>

            <p class="font-weight-bold">Shift History</p>
            <div *ngFor="let h of shiftHistory">
              {{h.position_name}}: {{h.firstname}} {{h.lastname}} {{ h.start
              |date: 'MMM d h:mm a' }}-{{ h.end | date: 'h:mm a' }}
              <small>
                - Set {{h.created_at |date: 'MMM d h:mm a'}} by
                {{h.edit_firstname}} {{h.edit_lastname}}
              </small>
              <strong *ngIf="h.trade_type"
                >Trade - {{TradeTypeNames[h.trade_type]}}</strong
              >
            </div>

            <div class="row mt-3">
              <div class="col-sm-12">
                <cancel-button
                  class="float-right"
                  (click)="cancel()"></cancel-button>
                <delete-modal
                  class="float-right"
                  [deleteObject]="data"
                  name="This Shift"
                  (onDelete)="deleteShift()">
                  <button class="btn btn-danger mx-2">Delete Shift</button>
                </delete-modal>
                <save-button class="float-right" (click)="save()"></save-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
