import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser, IApiUser } from '../services/account.service';
import { TimeoutService } from '../services/timeout.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { baseRole } from '../shared/baseRole';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './login.html',
})
export class LoginComponent extends baseRole implements OnInit {
  public username: string;
  public password: string;
  public timeclock: boolean;
  public stickLogin: boolean; // Do login even if in timeclock mode?
  public stay: boolean = false; // Stay Logged in?
  public locId: number;
  public destLocId: number;
  public locs: any[];
  public error: any = {};
  public user: IApiUser;
  public redirect: string;
  protected readonly maintenance = environment.maintenance;
  @ViewChild('timeclockWarningModal', { static: true })
  public modal: ModalDirective;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected timeoutService: TimeoutService,
    protected accountService: AccountService,
    private gtmService: GoogleTagManagerService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Log into your CheddrSuite account to manage and oversee your business operations.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content: 'CheddrSuite, Login, Business Management, Operations',
      },
      {
        name: 'robots',
        content: 'noindex, nofollow',
      },
    ]);
    this.title.setTitle('CheddrSuite | Login');
  }

  ngOnInit() {
    // So one of these params causes the timeclock to appear
    // the other forces login even if there is timeclockCookie.
    this.route.queryParams.subscribe(qParams => {
      this.redirect = qParams['redirect'];
      this.destLocId = qParams['location_id'];
      this.route.params.subscribe(params => {
        if (this.accountService.getUser()) {
          this.go();
          return;
        }
        this.timeclock = params['timeclock'];
        this.stickLogin = params['login'];
        let cookie = this.accountService.getCookie('timeclock');
        //console.log('Cookie: '+cookie);
        if (cookie && cookie.length > 0 && !this.stickLogin) {
          this.router.navigate(['/timeclock']);
        }
        let tokenLogin = params['token'];
        if (tokenLogin) {
          this.accountService
            .login(this.username, this.password)
            .subscribe(user => {
              if (user) {
                this.router.navigate(['/profile/']);
              }
            });
        }
      });
    });
  }

  go() {
    if (this.destLocId) {
      this.accountService.setLocation(this.destLocId).subscribe(
        () => {
          if (this.accountService.checkJail()) {
            this.router.navigate(['/profile']);
          } else if (this.redirect) {
            this.router.navigate([this.redirect]);
          } else this.router.navigate(['/']);
        },
        err => {
          this.accountService
            .setLocation(this.user.locations[0].id)
            .subscribe(() => {
              if (this.accountService.checkJail()) {
                this.router.navigate(['/profile']);
              } else {
                this.router.navigate(['/']);
              }
            });
        }
      );
    } else {
      if (this.redirect) {
        this.router.navigate([this.redirect]);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  setLocation() {
    this.accountService.setLocation(this.user.location_index).subscribe(() => {
      if (this.accountService.checkJail()) {
        this.router.navigate(['/profile']);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  login() {
    if (environment.maintenance) {
      console.log('Maintenance mode is on.');
      if (
        this.username != environment.supportEmail &&
        this.username != environment.devEmail &&
        this.username != environment.demoEmail
      ) {
        this.setError(
          'global',
          'Unable to login at the moment. Please try again later.'
        );
        return;
      }
    }
    if (!this.username || this.username.length == 0) {
      return this.setError('username', 'Please type in a username.');
    }
    if (!this.password || this.password.length == 0) {
      return this.setError('password', 'Please type in a password.');
    }
    this.error = {};

    if (!this.timeclock) {
      // Normal user login
      this.accountService.login(this.username, this.password).subscribe(
        user => {
          if (!user) {
            this.setError('global', 'Invalid username or password.');
          } else {
            this.user = user;
            this.timeoutService.setNeedsTimeout(!this.stay);
            this.timeoutService.resetTimeout();
            const gtmTag = {
              event: 'Login',
              lead_id: this.accountService.getUser().id,
            };
            this.gtmService.pushTag(gtmTag);
            if (this.accountService.isAdmin()) {
              this.router.navigate(['/admin']);
            } else if (this.accountService.getLocations().length == 0) {
              this.router.navigate(['/profile']);
            } else if (this.user.locations.length == 1) {
              this.destLocId = this.user.locations[0].id;
              //this.accountService.setLocation(this.user.locations[0].id).subscribe(()=> {});
              this.go();
            } else {
              this.go();
            }
          }
        },
        err => {
          this.setError('global', 'Invalid username or password.');
        }
      );
    } else {
      this.accountService
        .checkLogin(this.username, this.password)
        .subscribe(result => {
          //console.log(result);
          if (result && result.length > 0) {
            this.locs = result;
            this.locId = this.locs[0].id;
            this.modal.show();
          } else {
            this.setError('global', 'Invalid username or password.');
          }
        });
    }
  }

  cancelTimeclock() {
    this.locs = null;
    this.username = null;
    this.password = null;
    this.modal.hide();
  }

  authorizeTimeclock() {
    this.accountService
      .timeclockDevice(this.username, this.password, this.locId)
      .subscribe(token => {
        if (token) {
          //this.accountService.setCookie('timeclock',token,1000);
          this.router.navigate(['/timeclock']);
        }
      });
  }

  setError(field: string, error: string): void {
    this.error[field] = error;
    setTimeout(() => {
      this.error = {};
    }, 5000);
  }
}
