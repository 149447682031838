<div class="row" *ngIf="users && users.length>0">
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body">
        <label> Docs and quizzes needing action</label>
        <div class="row">
          <div
            class="col text-center"
            style="min-width: 90px; max-width: 110px"
            *ngIf="totalDocs>0">
            <div
              class="schedule-request-button"
              style="border: 0"
              routerLink="/users/user">
              <div class="card-img pointer pt-2">
                <icons name="FileText"></icons>
                <p class="card-text m-0">Docs</p>
                <div class="card-img-overlay" style="top: -10%; left: 45%">
                  <span class="notification-badge">{{totalDocs}}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col text-center"
            style="min-width: 90px; max-width: 110px"
            *ngIf="totalQuiz>0">
            <div
              class="schedule-request-button"
              style="border: 0"
              routerLink="/users/user">
              <div class="card-img pointer pt-2">
                <icons name="FileText"></icons>
                <p class="card-text m-0">Quizzes</p>
                <div class="card-img-overlay" style="top: -10%; left: 45%">
                  <span class="notification-badge">{{totalQuiz}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div bsModal #docAllList="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="View Quiz" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">Unsigned Docs &amp; Untaken Quizzes
          <button type="button" class="close" aria-label="Close" (click)="docAllList.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

			<div class="modal-body" *ngIf="users">
				<div class='card' *ngFor="let u of users | orderBy:'lastname'">
					<div class='card-body'>
					<label class='bg-white'>{{u.firstname}} {{u.lastname}} - {{ u.docs.length }} Needing Completion</label>
						<div class="row">
							<div class='col-md-2 col-4 my-2' *ngFor="let d of u.docs | orderBy:'id'">
										<doc-view style='max-width: 70%;' [viewOnly]="true" [doc]="d"></doc-view>
							</div>
						</div>
					</div>
				</div>
				<div class='row mt-3'>
					<div class='col-sm-12'>
					<done-button class='float-right' (click)="docAllList.hide()"></done-button>
					</div>
				</div>
			</div>

    </div>
  </div>
</div>-->
