import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
//import { ShiftService, IShift } from '../services/shift.service';
import { PositionService, IPosition } from '../services/position.service';
import { LocationService, ILocation } from '../services/location.service';

import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-filter-shift',
  templateUrl: 'modalFilterShift.html',
})
export class ModalFilterShiftComponent extends baseRole implements OnInit {
  public positions: IPosition[];
  public _positions: IPosition[];
  public loc: ILocation;
  //private position_ids:any = {};

  public fohButton: boolean = null;
  public permission_level: boolean[] = [];

  @ViewChild('modal')
  public modal: ModalDirective;

  //@Input() users;
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();

  public filter: any = {};

  constructor(
    public accountService: AccountService,
    public positionService: PositionService,
    public locationService: LocationService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.filter.position_ids = {};
  }

  show() {
    this.load();
  }

  load() {
    this.positionService.list().subscribe(data => {
      this._positions = data;
      this.houseUpdate();
      if (Object.keys(this.filter.position_ids).length > 0) {
        for (let p of this.positions) {
          p._selected = this.filter.position_ids[p.id];
        }
      }
    });
    this.locationService
      .get(this.accountService.getLocationId())
      .subscribe(data => {
        this.loc = data;
      });
    this.modal.show();
  }

  cancel() {
    this.modal.hide();
  }

  houseUpdate() {
    this.positions = this._positions.filter(e => {
      e._selected = false;
      if (this.fohButton === null) return true;
      e._selected = true;
      if (this.fohButton === true && e.position_type == 1) return true;
      if (this.fohButton === false && e.position_type == 2) return true;
      return false;
    });
  }
  //no filtering done here, send filters to home component
  save() {
    this.filter.position_ids = {};
    this.filter.positionFilter = this.fohButton !== null;
    let selectedPos = this.positions.filter(e => {
      return e._selected;
    });

    for (let p of selectedPos) {
      this.filter.position_ids[p.id] = true;
      this.filter.positionFilter = true;
    }

    this.filter.permission_level = this.permission_level;
    this.filter.permissionFilter =
      this.permission_level[1] ||
      this.permission_level[2] ||
      this.permission_level[3];
    if (this.filter.permissionFilter && this.filter.permission_level[3]) {
      this.filter.permission_level[4] = true; // Add owners in for managers
    }
    this.filter.isFiltered =
      this.filter.positionFilter ||
      this.filter.permissionFilter ||
      this.fohButton !== null;

    /*
		this.filter.filteredUsers = this.users.filter((e)=> {
			if(!this.isFiltered) return true;

			if(permissionFilter) {
					if(!this.permission_level[e.user_type]) return false;
			}

				if(this.fohButton !== null) {
				if(this.fohButton && !e.foh) return false; //user has FOH/BOH from home
				else if(!this.fohButton && !e.boh) return false;
			}

			if(positionFilter) {
				for(let p of e.positions) {
					if(this.filter.position_ids[p.id]) return true;
				}
				return false;
			}
			return true;
		});
	*/

    //		console.log(this.filter);
    this.filterChange.emit(this.filter);

    this.modal.hide();
  }

  clearHouses() {
    this.fohButton = null;
  }

  noFilter() {
    this.fohButton = null;
    this.permission_level = [];
    this.filter.position_ids = {};
    this.houseUpdate();
    for (let p of this.positions) {
      p._selected = false;
    }
    this.filter.isFiltered = false;
  }

  clearFilter() {
    this.noFilter();
    this.save();
  }
}
