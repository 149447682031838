<div class="card" *ngIf="loc">
  <div class="card-body">
    <div class="title text-center">Location Information</div>

    <form ngNativeValidate (submit)="saveLoc()">
      <div class="row">
        <div class="col-md-3 col-12">
          <label>Picture or logo <small>(Not required)</small> </label>
          <img
            class="rounded mb-1"
            [src]="getLocationImage(loc.has_image)"
            height="82px"
            width="82px" />
          <input
            name="profile"
            type="file"
            class="form-control-file mb-3"
            id="formControlFile"
            (change)="handleFileChange($event.target.files)"
            accept="image/*" />
        </div>
        <div class="col-md-9 col-12">
          <div class="row">
            <div class="col-12">
              <input
                [(ngModel)]="loc.name"
                name="loc_name"
                maxlength="50"
                type="text"
                class="form-control"
                required />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <input
                [(ngModel)]="loc.address_1"
                name="loc_address_1"
                type="text"
                class="form-control"
                required
                placeholder="Address Line 1" />
            </div>
            <div class="col-md-6 col-12">
              <input
                [(ngModel)]="loc.address_2"
                name="loc_address_2"
                type="text"
                class="form-control"
                placeholder="Address Line 2" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-12">
              <input
                [(ngModel)]="loc.city"
                name="loc_city"
                type="text"
                class="form-control"
                required
                placeholder="City" />
            </div>
            <div class="col-md-4 col-12">
              <select
                [(ngModel)]="loc.country"
                name="loc_country"
                class="form-control"
                required>
                <option *ngFor="let c of CountryKeys" [value]="c">
                  {{Countries[c]}}
                </option>
              </select>
            </div>
            <div class="col-md-4 col-12">
              <select
                *ngIf="loc.country=='US' || loc.country==''"
                [(ngModel)]="loc.state"
                name="loc_state"
                class="form-control"
                required>
                <option *ngFor="let s of StateKeys" [value]="s">
                  {{States[s]}}
                </option>
              </select>
              <select
                *ngIf="loc.country=='CA'"
                [(ngModel)]="loc.state"
                name="loc_state"
                class="form-control"
                required>
                <option *ngFor="let p of ProvinceKeys" [value]="p">
                  {{Provinces[p]}}
                </option>
              </select>
            </div>
            <div class="col-md-4 col-12">
              <input
                *ngIf="loc.country=='US' || loc.country==''"
                [(ngModel)]="loc.zip"
                name="loc_zip"
                type="text"
                class="form-control"
                pattern="[0-9]{5}"
                maxlength="5"
                required
                placeholder="Zip Code" />
              <input
                *ngIf="loc.country=='CA'"
                [(ngModel)]="loc.zip"
                name="loc_zip"
                type="text"
                class="form-control"
                maxlength="7"
                required
                placeholder="Postal Code" />
            </div>
            <div class="col-md-4 col-12">
              <select
                [(ngModel)]="loc.timezone_id"
                name="loc_timezone_id"
                type="text"
                class="form-control"
                required>
                <option *ngFor="let t of timezones" [value]="t.id">
                  {{t.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="saveMessage">
        <div class="col-12">
          <alert type="success">
            <strong>{{saveMessage}}</strong>
          </alert>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <cancel-button
            class="float-right"
            routerLink="/settings/account"></cancel-button>
          <save-button
            class="float-right"
            [disabled]="saveMessage"></save-button>
        </div>
      </div>
    </form>
  </div>
</div>
