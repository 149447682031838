<div class="row">
  <div class="col-8">
    <label>Icon size helpers</label>
    <span class="cdi-l-send-message cdi-biggest"></span>
    <span class="cdi-l-send-message cdi-bigger"></span>
    <span class="cdi-l-send-message"></span>
    <span class="cdi-l-send-message cdi-smaller"></span>
    <span class="cdi-l-send-message cdi-smallest"></span>
  </div>
  <div class="col-4">
    <label>State Classes</label>
    <span class="cdi-m-check"></span>
    <span class="cdi-m-check nostate"></span>
    <span class="cdi-m-check active nostate"></span>
    <span class="cdi-m-check active"></span>
  </div>
</div>
<table class="nav-padding table table-responsive">
  <tbody>
    <tr>
      <td
        *ngFor="let iList of iconList"
        style="vertical-align: top; padding-left: 30px">
        <table>
          <tbody>
            <tr *ngFor="let icon of iList">
              <td>{{icon}}</td>
              <td *ngFor="let s of sizes">
                <div class="cdi-{{s}}-{{icon}} cdi-border"></div>
              </td>
              <td *ngFor="let s of sizes">
                <div class="cdi-{{s}}-{{icon}} cdi-border cdi-bg-dark"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
