import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from '../services/account.service';
import { EmployeePositionService } from '../services/employeePosition.service';
import { LogService } from '../services/log.service';
import { TimeClockService, ITimeClock } from '../services/timeClock.service';
import { UserService, IUser } from '../services/user.service';
import { ShiftService } from '../services/shift.service';
import { baseRole } from '../shared/baseRole';
import { DateMenuType, UserAddAll, RateType } from '../shared/api';

@Component({
  templateUrl: './timecards.html',
})
export class TimecardsComponent extends baseRole implements OnInit, OnDestroy {
  public timeclock: any = {};
  public timeClocks: any[];
  public salaryUserNoClockIn: any[];

  public positions: any[];
  public users: any[];
  public date: Date;
  // Shift Data
  //public shifts: any[];
  //public totalScheduledHours = 0;
  //public regHours = 0;
  //public otHours = 0;
  //public totalHours = 0;
  public totalSales = 0;
  //public netPay =0;
  //public estPayrollCosts =0;
  public estGross = 0;
  public loc;
  public stats;
  public sub: any;
  public timeclockSub: any;
  //public userClockedInMap:any = {};
  public userShiftMap: any;
  public RateType = RateType;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected timeClockService: TimeClockService,
    protected shiftService: ShiftService,
    protected userService: UserService,
    protected logService: LogService,
    protected employeePositionService: EmployeePositionService,
    public accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.EditTimeCard)) {
      this.router.navigate(['/']);
      return;
    }

    this.loc = this.accountService.getLocation();

    this.sub = this.accountService
      .getCurrentDateUpdateObservable(DateMenuType.Daily)
      .subscribe(date => {
        if (!date) return;
        //		console.log('Date: '+date);
        this.date = date;

        this.userService
          .listWithPositions(this.date, this.date)
          .subscribe(data => {
            this.users = UserAddAll(data);
          });

        if (!this.timeclockSub) {
          this.timeclockSub = this.timeClockService
            .getUpdateObservable()
            .subscribe(() => {
              this.load();
            });
        } else {
          this.load();
        }
      });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.timeclockSub) this.timeclockSub.unsubscribe();
  }

  /*resetTotals() {
		this.totalScheduledHours = 0;
		this.regHours = 0;
		this.otHours = 0;
	//	this.totalHours = 0; summing reg+OT in UI
		this.netPay = 0;
		this.estPayrollCosts = 0;
		this.estGross= 0;
	}*/

  initTimeclock() {
    let start = new Date(this.date.getTime());
    start.setHours(10);
    let end = new Date(this.date.getTime());
    end.setHours(16);
    this.timeclock = {
      user_id: '',
      start: start,
      end: end,
    };
    if (this.getLocation().has_tip_reporting) {
      this.timeclock.tips = 0;
    }
  }

  load() {
    //this.resetTotals();
    this.initTimeclock();
    this.logService
      .getReport({ start: this.date, end: this.date, include_user_data: true })
      .subscribe(dataList => {
        this.stats = dataList[0];
        //		console.log(this.stats);
        this.estGross =
          (this.stats.foh_cost + this.stats.boh_cost) *
          (1 + this.loc.payroll_goal / 100);

        this.totalSales = this.stats.service_time[0].sales;
        this.timeClocks = this.stats.users.filter(e => {
          return e.timeclocks.length > 0;
        });
        this.salaryUserNoClockIn = this.stats.users.filter(e => {
          return e.timeclocks.length == 0 && e.salary;
        });
        // This is wrong.
        //this.totalSales = data.sales;
      });
    let endOfDate = new Date(this.date);
    endOfDate.setDate(endOfDate.getDate() + 1);
    endOfDate.setSeconds(endOfDate.getSeconds() - 1);
    this.shiftService
      .list({ startOn: this.date, endOn: endOfDate })
      .subscribe(data => {
        this.userShiftMap = {};
        for (let s of data) {
          if (!s.user_id) continue;
          this.userShiftMap[s.user_id] = s;
        }
      });
  }

  deleteTimeclock(t) {
    this.timeClockService.delete(t.id).subscribe(() => {});
  }

  changeStaff(userId) {
    if (!userId) {
      this.timeclock.user_id = '';
      this.positions = null;
      return;
    }

    this.timeclock.user_id = userId;
    //		console.log('updating');
    this.employeePositionService
      .list({ active: true, user_id: this.timeclock.user_id })
      .subscribe(data => {
        //		console.log(data);
        this.positions = data;
        let primaries = this.positions.filter(e => {
          return e.is_primary;
        });
        if (primaries && primaries.length == 1) {
          this.timeclock.employee_position_id = primaries[0].id;
        } else if (this.positions.length > 0) {
          this.timeclock.employee_position_id = this.positions[0].id;
        }
      });
  }

  saveTimeclock(clock) {
    let t = clock._edit || clock;
    if (t.end.getTime() < t.start.getTime()) {
      t.end.setDate(t.end.getDate() + 1);
    }

    this.timeClockService.update(t).subscribe(() => {
      if (!t.id) this.initTimeclock();
    });
  }
}
