import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  ChecklistItemService,
  IChecklistItem,
  IChecklist,
} from '../services/checklistItem.service';
import { baseRole } from '../shared/baseRole';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'modal-edit-checklist',
  templateUrl: 'modalEditChecklist.html',
})
export class ModalEditChecklistComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  @ViewChild('modal')
  public modal: ModalDirective;
  public checklist: IChecklist;
  public checklists: IChecklist[];
  public data: IChecklistItem[];
  public newItem: any;
  public sub;

  constructor(
    public accountService: AccountService,
    protected dragulaService: DragulaService,
    public checklistItemService: ChecklistItemService
  ) {
    super(accountService);
    this.resetNewItem();
  }

  ngOnInit() {
    this.setupDragNDrop();
  }
  resetNewItem() {
    this.newItem = {
      days: [true, true, true, true, true, true, true],
    };
  }
  show(data, allChecklists) {
    this.checklist = data;
    this.checklists = allChecklists;
    this.checklistItemService
      .list({ checklist_id: data.id })
      .subscribe(data => {
        //		console.log(data);
        this.data = data;
        this.modal.show();
      });
  }

  editObj(obj) {
    obj._edit = Object.assign({}, obj);
  }

  cancelEdit(obj) {
    obj = Object.assign(obj, obj._edit);
    delete obj._edit;
  }

  deleteObj(obj) {
    this.checklistItemService.delete(obj.id).subscribe(data => {
      let idx = this.data.indexOf(obj);
      this.data.splice(idx, 1);
    });
  }

  saveObj(obj) {
    if (!obj.checklist_id) {
      obj.checklist_id = this.checklist.id;
      obj.order_by = this.data.length;
    }
    this.checklistItemService.update(obj).subscribe(data => {
      /*console.log(data);
		console.log(obj);*/
      if (!obj.id) {
        obj.id = data.id;
        this.data.push(obj);
        this.resetNewItem();
      } else {
        delete obj._edit;
        //Object.assign(obj,data);
      }
    });
  }
  cancel() {
    this.modal.hide();
  }

  // DRAG N DROP
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    this.dragulaService.destroy('checklist_items');
  }

  setupDragNDrop() {
    this.dragulaService.createGroup('checklist_items', {
      direction: 'vertical',
    });

    if (!this.sub) {
      this.sub = this.dragulaService
        .drop('checklist_items')
        .subscribe(value => {
          //console.log(value);
          const myId = +value.el.getAttribute('data-id');

          //			console.log(this.data);
          if (!this.data) {
            console.error('this.data is null');
            console.error(this);
            return;
          }
          let i = 0;
          for (i = 0; i < this.data.length; i++) {
            if (this.data[i].id == myId) break;
          }
          if (i == this.data.length) return; // No idea what happened..

          let me = this.data[i];

          if (value.sibling) {
            // Was inserted before someone...
            var targetId = +value.sibling.getAttribute('data-id');
            let j = 0;
            for (j = 0; j < this.data.length; j++) {
              if (this.data[j].id == targetId) break;
            }
            if (j == this.data.length) return; // No idea what happened..
            if (i < j) {
              this.data.splice(j, 0, me);
              this.data.splice(i, 1);
            } else {
              this.data.splice(i, 1);
              this.data.splice(j, 0, me);
            }
          } else {
            this.data.splice(i, 1);
            this.data.push(me);
          }

          var update = [];
          for (i = 0; i < this.data.length; i++) {
            this.data[i].order_by = i;
            update.push({ id: this.data[i].id, order_by: i });
          }

          this.checklistItemService.reOrder(update).subscribe(data => {});
        });
    }
  }
}
