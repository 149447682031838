<div class="row">
  <div class="col-md-6 col-12">
    <div class="card">
      <form (submit)="findUser()" class="card-body">
        <label>Find User</label>
        <input
          type="text"
          name="user"
          [(ngModel)]="searchUser"
          class="form-control override-invalid"
          required />
        <button type="button" class="btn btn-primary" (click)="newestUser()">
          Newest
        </button>
        <button type="submit" class="btn float-right btn-primary">Find</button>
      </form>
    </div>
  </div>

  <div class="col-md-6 col-12">
    <div class="card">
      <form (submit)="findLocation()" class="card-body">
        <label>Find Location</label>
        <input
          type="text"
          name="location"
          [(ngModel)]="searchLocation"
          class="form-control override-invalid"
          required />
        <button
          type="button"
          class="btn btn-primary"
          (click)="newestLocation()">
          Newest
        </button>
        <button type="submit" class="btn float-right btn-primary">Find</button>
      </form>
    </div>
  </div>
</div>

<div class="row row-table row-header" *ngIf="dataLoc">
  <div class="col-12">Locations</div>
</div>

<div class="row-table" *ngIf="dataLoc">
  <div
    class="row p-2 card"
    style="background-color: #e5e7eb"
    *ngFor="let l of dataLoc | orderBy: sort">
    <div class="row col-12 justify-content-between">
      <div class="col-md-10" style="font-size: 1.2rem; font-weight: 700">
        {{ l.name}}
        <small>({{ l.id }}) Created: {{ l.created_at | bliDate }}</small>
      </div>
      <div class="col-md-2">
        <label class="pointer mb-0" [routerLink]="['/admin/location',l.id]">
          <icons class="float-right" name="Settings"></icons>
        </label>
      </div>
    </div>
    <div class="col-12">
      <button
        class="btn btn-primary mb-2 mr-2"
        type="button"
        [routerLink]="['/admin/integration/sales',l.id]">
        Integration Report
      </button>
      <button
        class="btn btn-primary mb-2 mr-2"
        type="button"
        [routerLink]="['/admin/location',l.id,'import']">
        Imports
      </button>
    </div>
    <div class="row col-12">
      <div class="col-md-2 col-12">Condition: {{LocationStatus[l.status]}}</div>
      <div class="col-md-2 col-12">
        Billing Plan: {{PackageType[l.package_type]}}
      </div>
      <div class="col-md-2 col-12">Free Until: {{ l.free_until }}</div>
      <div class="col-md-2 col-12">
        Payment Status: {{ l.payment_status?'Okay':'Failed'}}
      </div>
    </div>

    <div class="col-12">
      <div class="row py-1 justify-content-between" *ngFor="let u of l.user">
        <div class="col-md-6 col-12">
          Owner: {{ u.firstname }} {{u.lastname}} ({{u.id}}) - {{u.email}}
        </div>
        <div class="col-md-6 col-12">
          <div *ngIf="!u.token">
            <button
              class="btn btn-secondary float-right w-25"
              type="button"
              (click)="signinAs(u)">
              Get Signin Link
            </button>
          </div>
          <div *ngIf="u.token">
            <a
              *ngIf="u.token"
              class="btn btn-primary float-right w-25"
              [href]="u.token | safeUrl">
              Open in incognito
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="dataUser">
  <div class="card-body">
    <div class="row row-table row-header">
      <div class="col-12">Staff</div>
    </div>
    <div class="row font-weight-bold">
      <div class="col-2">Name</div>
      <div class="col-2">Created</div>
      <div class="col-2">Email</div>
      <div class="col-6">
        <span class="float-right w-25">Actions</span>
      </div>
    </div>
    <div class="row-table row-striped">
      <div class="row py-1" *ngFor="let u of dataUser | orderBy: sort">
        <div class="col-2">
          <strong>{{ u.firstname}} {{u.lastname }}</strong>
        </div>
        <div class="col-2">
          <div><small>{{ u.created_at | bliDate }}</small></div>
        </div>
        <div class="col-2">{{ u.email}}</div>
        <div class="col-6">
          <div *ngIf="!u.token">
            <button
              class="btn btn-secondary float-right w-25"
              type="button"
              (click)="signinAs(u)">
              Get Signin Link
            </button>
          </div>
          <div *ngIf="u.token">
            <a
              *ngIf="u.token"
              class="btn btn-primary float-right w-25"
              [href]="u.token | safeUrl">
              Open in incognito
            </a>
          </div>
        </div>
        <div class="col-12">
          <div *ngIf="u.location && u.location.length>0">
            <span *ngFor="let l of u.location; let isLast = last">
              <a [routerLink]="['/admin/location',l.id]"> {{l.name}}</a>
              {{UserType[l.user_type]}}
              <small>{{l.created_at |bliDate}}</small>
              <span *ngIf="!isLast">,</span>
            </span>
          </div>
        </div>
        <div class="col-12">
          <div *ngIf="u.passwordUrl">
            <span>
              Password reset until: {{ u.password_token_expire | date: 'short'
              }}
            </span>
            <span>{{u.passwordUrl}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
