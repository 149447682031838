<freemium *ngIf="isFreemium()"></freemium>
<add-comment #msgComment (onAdd)="addLogComment($event)"></add-comment>
<div
  class="row text-white nav-padding"
  *ngIf="isAllowed(Permission.ViewSales) && !isFreemium()">
  <div class="col-12">
    <ul class="list-group" *ngIf="sales">
      <li class="list-group-item blue-bg">
        <div class="d-flex flex-row">
          <h4 class="pt-1 m-0">SALES</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ sales.service_time[0].sales | currency: 'USD' }}
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item grey-dark-bg">
        <div class="d-flex flex-row">
          <h4 class="pt-1 m-0">LABOR</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ payrollCost*(sales.foh_cost+sales.boh_cost) | currency: 'USD'
              }}
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item grey-bg">
        <div class="d-flex flex-row">
          <h4 class="pt-1 m-0">COGS</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">{{ sales.cogs_cost | currency: 'USD' }}</h3>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="row" *ngIf="!isFreemium()">
  <div
    class="col-md-6 col-xs-12 pr-0"
    *ngIf="isAllowed(Permission.ViewSales) && getLocation().has_finances">
    <div class="card">
      <div class="card-body" *ngIf="sales && lastYearSales">
        <h3>
          SALES: {{ sales.service_time[0].sales | currency: 'USD' }}
          <h6 style="display: inline" *ngIf="!(salesPercent==null)">
            &nbsp;&nbsp;{{ salesPercent | number: '1.2-2' }}%
          </h6>
        </h3>
        <h4>
          LAST YEAR SALES: {{ lastYearSales.service_time[0].sales | currency:
          'USD' }}
        </h4>
      </div>
      <div *ngIf="lastYearSales && sales">
        <chart-summary
          [reportData]="{ lastYear: lastYearSales, thisYear: sales}"></chart-summary>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-xs-12 pr-0">
    <div class="row">
      <div class="col-12">
        <checklist-list [date]="date"></checklist-list>
      </div>
    </div>

    <div
      class="col-md-6 col-xs-12"
      *ngIf="noteCount==0 && comments && comments.length==0">
      <div class="card">
        <div class="card-body text-center">No Log Notes For The Day</div>
      </div>
    </div>

    <div class="col-12" *ngIf="noteCount>0">
      <div class="card">
        <div class="card-body">
          <ng-container *ngFor="let t of LogNoteTypeKeys">
            <div *ngIf="logNotes[t] && logNotes[t].length>0">
              <label>{{LogNoteNames[t]}}</label>
              <div *ngFor="let n of logNotes[t] | orderBy:'id'" class="mb-2">
                <!--		<div style='height: 45px'>
									<img class="rounded float-left" style='margin-right: 1.0rem;' src="/images/test/brian.jpg" height="40px">-->
                {{ n.firstname }} {{ n.lastname }} at {{ n.created_at |
                date:'short'}} - {{ServiceTimeType[n.service_time_id]}} log
                <span *ngIf="n.images[0]"> + File </span>
                <div class="text-black mx-2">{{n.note}}</div>
              </div>
            </div>
          </ng-container>

          <div
            class="d-flex justify-content-end"
            *ngIf="isAllowed(this.Permission.LogShiftData)"
            (click)="msgComment.show()">
            <span class="cdi-m-send-message pointer"></span>
            <span class="align-self-center" style="font-size: 12px"
              >Add Comment</span
            >
          </div>
          <div class="d-inline mt-3" *ngIf="comments && comments.length>=0">
            <div
              class="body-bg border my-1"
              style="font-size: 12px"
              *ngFor="let c of comments">
              &nbsp;{{ c.firstname }} {{ c.lastname }} at {{ c.created_at |
              date:'short'}}
              <span
                *ngIf="isAllowed(this.Permission.LogShiftData)"
                class="cdi-s-cancel float-right pointer"
                (click)="deleteCommentObj = c;deleteComment.show()"></span>
              <div class="text-black mx-2">{{ c.response }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="noteCount==0 && comments && comments.length>0">
      <div class="card">
        <div class="card-body">
          <label>Log Comments</label>
          <div
            *ngFor="let c of comments"
            class="body-bg border my-1"
            style="font-size: 12px">
            &nbsp;{{ c.firstname }} {{ c.lastname }} at {{ c.created_at |
            date:'short'}}
            <span
              class="cdi-s-cancel float-right pointer"
              (click)="deleteCommentObj = c;deleteComment.show()"></span>
            <div class="text-black mx-2">{{ c.response }}</div>
          </div>
        </div>
      </div>
    </div>
    <delete-modal
      #deleteComment
      (onDelete)="deleteLogComment()"
      name="this comment"></delete-modal>
  </div>
</div>
