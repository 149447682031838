import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardJail } from '../app/auth-guard';
import { HomeComponent } from './home.component';
import { ScheduleComponent } from './schedule.component';

const appRoutes: Routes = [
  {
    path: 'schedule',
    canActivate: [AuthGuard, AuthGuardJail],
    component: ScheduleComponent,
    children: [{ path: '', component: HomeComponent }],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
