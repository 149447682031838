import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  ServiceTimeService,
  IServiceTime,
} from '../services/serviceTime.service';
import { LogService, ILogReport } from '../services/log.service';
import {
  CustomLogFieldService,
  ICustomLogField,
} from '../services/customLogField.service';
import {
  SelectAddAll,
  ServiceTimeType,
  moment,
  IChartSales,
} from '../shared/api';
import {
  addLogReport,
  zeroLogReports,
  dataToChart,
} from '../shared/widgets/widget.component';
import { baseRole } from '../shared/baseRole';

/* Match week day doesn't work on month/ quarter or year. */
@Component({
  templateUrl: './sales.html',
})
export class SalesComponent extends baseRole implements OnInit {
  public ServiceTimeType = ServiceTimeType;
  public ServiceTimeTypeKeys = Object.keys(ServiceTimeType).filter(Number);
  public chartData: IChartSales;
  public mData: ILogReport[];
  public salesReportData: ILogReport[];
  public reportData: {
    date: string;
    value: number;
    service_time_type: number;
  }[];
  public loc;
  public isDaily = false;
  public serviceTimes: IServiceTime[];
  public customFields: ICustomLogField[];
  public mainSalesReport = false;
  public total = 0;
  public lyTotal = 0;
  public groupByLabor = ['day', 'week', 'month', 'year'];

  public search: any = {
    start: Date,
    end: Date,
    search_field: 'net_sales',
    group_by: 'month',
    compare_last_year: false,
    match_week_day: false,
    match_week_day_disabled: false,
    match_week_day_available: false,
    service_time_id: 0,
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected logService: LogService,
    protected customLogFieldService: CustomLogFieldService,
    protected serviceTimeService: ServiceTimeService,
    protected accountService: AccountService
  ) {
    super(accountService);

    this.search.start = new Date();
    this.search.start.setMonth(0);
    this.search.start.setDate(1);
    this.search.end = new Date();
    this.zeroDate(this.search.start);
    this.zeroDate(this.search.end);
  }

  zeroDate(d: Date) {
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
  }

  ngOnInit() {
    if (!this.isFreemium() && !this.isAllowed(this.Permission.ViewSales)) {
      this.router.navigate(['/reports/payroll']);
      return;
    }
    this.loc = this.accountService.getLocation();
    this.isDaily = this.loc.is_service_time_daily;
    this.serviceTimeService.list().subscribe(data => {
      this.serviceTimes = data;
      this.search.service_time_id = null;
      if (!this.isFreemium()) {
        this.load();
      }
    });
    this.customLogFieldService.list().subscribe(data => {
      this.customFields = data;
    });
  }

  updateGroupBy() {
    if (this.search.group_by == 'day') {
      this.search.match_week_day_disabled = false;
      this.search.match_week_day_available = true;
      this.search.match_week_day = true;
    } else if (
      this.search.group_by == 'month' ||
      this.search.group_by == 'quarter' ||
      this.search.group_by == 'year' ||
      this.search.group_by == 'week'
    ) {
      this.search.match_week_day_disabled = false;
      this.search.match_week_day_available = false;
      this.search.match_week_day = false;
    } else {
      this.search.match_week_day_disabled = true;
      this.search.match_week_day_available = true;
      this.search.match_week_day = true;
    }
  }

  changeServiceTime(val) {
    this.search.service_time_type = val;
    if (!val) this.search.service_time_id = null;
    else {
      let match = this.serviceTimes.filter(e => {
        return e.service_time_type == val;
      });
      if (match.length == 1) {
        this.search.service_time_id = match[0].id;
      } else {
        this.search.service_time_id = null;
      } // Dunno what happened here.
    }
  }

  load() {
    this.chartData = null;
    let start = moment(this.search.start);
    let end = moment(this.search.end);
    if (this.search.compare_last_year) {
      start.add(-1, 'years');
    }

    if (
      this.search.search_field == 'net_sales' &&
      this.groupByLabor.indexOf(this.search.group_by) >= 0 &&
      !this.search.compare_last_year
    ) {
      this.logService
        .getReport({
          start: start,
          end: end,
          service_time_id: this.search.service_time_id,
          search_field: this.search.search_field,
        })
        .subscribe(data => {
          this.salesReportData = data;
          this.laborRecalc();
        });
    } else {
      this.logService
        .getSalesReport({
          start: start,
          end: end,
          service_time_id: this.search.service_time_id,
          search_field: this.search.search_field,
        })
        .subscribe(data => {
          this.reportData = data;
          this.recalc();
        });
    }
  }

  resetChartData(): {
    start: any;
    binStart: any;
    end: any;
    length: number;
    inc: number;
    offset: string;
    keyFunc: (any) => number;
    selectFunc: (any) => boolean;
  } {
    this.chartData = {
      popupFormat: 'MMM YYYY',
      titleBar: true,
      tickInterval: 7 * 86400 * 1000,
      data: [],
    };
    //		console.log('Search');
    //		console.log(this.search);
    let start = moment(this.search.start);
    let binStart = moment(this.search.start);
    let end = moment(this.search.end);
    let length: number;
    let inc: number;
    let offset = 'days';
    let keyFunc;
    let lastDateFunc;
    let selectFunc = date => {
      return true;
    };

    if (this.search.compare_last_year) {
      binStart.add(-1, 'years');
    }

    //	console.log('Start '+start.toDate().toString());
    //console.log('End '+end.toDate().toString());
    switch (this.search.group_by) {
      case 'day':
        this.chartData.tickInterval = 86400 * 1000;
        length = moment.duration(end.diff(binStart)).asDays() + 1;
        inc = 1;
        offset = 'days';
        keyFunc = date => {
          return Math.round(date.diff(binStart, 'days'));
        };
        //selectFunc = (date)=> { return moment(date).isSameOrAfter(start); };
        break;
      case 'week':
        this.chartData.tickInterval = 7 * 86400 * 1000;
        this.chartData.popupFormat = 'D MMM';
        binStart.startOf('week');
        length = moment.duration(end.diff(binStart)).asWeeks() + 1;
        inc = 1;
        offset = 'weeks';
        keyFunc = date => {
          return Math.round(date.diff(binStart, 'week'));
        };
        //selectFunc = (date)=> { return moment(date).isSameOrAfter(start); };
        break;
      case 'total_week':
      case 'average_week':
        this.chartData.tickInterval = 86400 * 1000;
        this.chartData.labelFormat = 'ddd';
        this.chartData.popupFormat = 'ddd';
        binStart.startOf('week');
        length = 14;
        inc = 1;
        offset = 'days';
        keyFunc = date => {
          if (date.isBefore(start)) {
            return date.day();
          } else {
            return date.day() + 7;
          }
        };
        //selectFunc = (date)=> { return moment(date).isSameOrAfter(start); };
        break;
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
        this.chartData.tickInterval = 7 * 86400 * 1000;
        length = moment.duration(end.diff(binStart)).asWeeks() + 1;
        inc = 1;
        offset = 'weeks';
        keyFunc = date => {
          return Math.round(date.diff(binStart, 'week'));
        };
        selectFunc = date => {
          return moment(date).day() == +this.search.group_by;
        };
        break;
      case 'month':
        this.chartData.tickInterval = 31 * 86400 * 1000;
        //			console.log(end.diff(start));
        //		console.log(moment.duration(end.diff(start)));
        binStart.startOf('month');
        length = moment.duration(end.diff(binStart)).asMonths();
        inc = 1;
        offset = 'months';
        keyFunc = date => {
          return Math.round(date.diff(binStart, 'months'));
        };
        //selectFunc = (date)=> { return moment(date).isSameOrAfter(start); };
        break;
      case 'quarter':
        this.chartData.tickInterval = 92 * 86400 * 1000;
        binStart.startOf('quarter');
        length = moment.duration(end.diff(binStart)).asMonths() / 3;
        inc = 1;
        offset = 'quarters';
        keyFunc = date => {
          return Math.round(date.diff(binStart, 'quarters'));
        };
        //selectFunc = (date)=> { return moment(date).isSameOrAfter(start); };
        break;
      case 'year':
        this.chartData.tickInterval = 365 * 86400 * 1000;
        binStart.startOf('year');
        length = moment.duration(end.diff(binStart)).asYears();
        inc = 1;
        offset = 'years';
        keyFunc = date => {
          return Math.round(date.diff(binStart, 'years'));
        };
        //selectFunc = (date)=> { return moment(date).isSameOrAfter(start); };
        break;
    }
    return {
      start: start,
      binStart: binStart,
      end: end,
      length: Math.ceil(length),
      inc: inc,
      offset: offset,
      keyFunc: keyFunc,
      selectFunc: selectFunc,
    };
  }

  findLastYearDate(date): any {
    return moment(date).add(-1, 'years');
  }

  lastYearOffset(date): any {
    let newDate = moment(date); // Now
    let tmpDate = moment(date).add(-1, 'years'); // A Year ago..
    if (this.search.match_week_day) {
      // If we need to match, move it.
      let offset = tmpDate.day() - newDate.day();
      if (offset > 0) offset -= 7;
      //console.log(newDate.toDate()+" : "+tmpDate.toDate()+" -> "+offset);
      newDate.add(offset, 'days');
    }
    return newDate;
  }

  recalc() {
    let info = this.resetChartData();
    let avgBin: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    //console.log(info);
    let dateMap: any = {};
    let lastDateMap: any = {};
    this.mainSalesReport = false;

    this.total = 0;
    this.lyTotal = 0;

    if (
      this.search.group_by == 'total_week' ||
      this.search.group_by == 'average_week'
    ) {
      let i = 0;
      let s: any;
      for (s = moment(info.binStart); i < 7; i++, s.add('1', 'days')) {
        dateMap[i] = {
          date: moment(s),
          lastYearKey: info.keyFunc(this.findLastYearDate(s)),
          service_time: [0, 0, 0, 0],
        };
        lastDateMap[i] = {
          date: moment(s),
          service_time: [0, 0, 0, 0],
        };
      }
      for (s = moment(info.start); i < 14; i++, s.add('1', 'days')) {
        dateMap[i] = {
          date: moment(s),
          lastYearKey: info.keyFunc(this.findLastYearDate(s)),
          service_time: [0, 0, 0, 0],
        };
        lastDateMap[i] = {
          date: moment(s),
          service_time: [0, 0, 0, 0],
        };
      }
    } else {
      for (
        let i = 0, s = moment(info.binStart);
        i < info.length;
        i++, s.add(info.inc, info.offset)
      ) {
        dateMap[i] = {
          date: moment(s),
          lastYearKey: info.keyFunc(this.findLastYearDate(s)),
          service_time: [0, 0, 0, 0],
        };
        lastDateMap[i] = {
          date: moment(s),
          service_time: [0, 0, 0, 0],
        };
      }
    }
    // Need to probably filter by start/end date
    // Load it into the day map.
    for (let d of this.reportData) {
      let key = info.keyFunc(moment(d.date));

      if (this.search.compare_last_year) {
        let lyKey = info.keyFunc(this.lastYearOffset(d.date));
        //console.log(d.date.toString()+' '+lyKey+' '+d.value);
        if (lyKey in lastDateMap) {
          lastDateMap[lyKey].service_time[0] += d.value;
        }
      }
      if (!info.selectFunc(d.date)) continue;

      if (this.search.group_by == 'average_week') {
        avgBin[key]++;
      }

      if (this.isDaily || this.search.compare_last_year) {
        dateMap[key].service_time[0] += d.value;
      } else {
        // Probably need to do all the service times.
        dateMap[key].service_time[d.service_time_type] += d.value;
      }
    }

    // Fix the averaging..
    if (this.search.group_by == 'average_week') {
      for (let key in dateMap) {
        let d = dateMap[key];
        let cnt = avgBin[d.date.day()];
        if (cnt > 0 && (this.isDaily || this.search.compare_last_year)) {
          dateMap[key].service_time[0] /= cnt;
        } else if (cnt > 0) {
          dateMap[key].service_time[0] /= cnt;
          dateMap[key].service_time[1] /= cnt;
          dateMap[key].service_time[2] /= cnt;
          dateMap[key].service_time[3] /= cnt;
        }
      }
    }

    /*console.log('DateMap followed by LastDateMap');
		console.log(dateMap);
		console.log(lastDateMap);*/

    if (this.search.compare_last_year) {
      this.chartData.data = [
        {
          name: 'This Period',
          type: 'spline',
          lineWidth: 2,
          data: [],
        },
        {
          name: 'Same Period Last Year',
          type: 'spline',
          lineWidth: 2,
          color: '#1E90FF',
          data: [],
        },
      ];
    } else {
      if (this.isDaily) {
        this.chartData.data[0] = {
          name: ServiceTimeType[ServiceTimeType.Daily],
          type: 'spline',
          data: [],
        };
      } else {
        this.chartData.data[0] = {
          name: ServiceTimeType[ServiceTimeType.Breakfast],
          type: 'areaspline',
          data: [],
        };
        this.chartData.data[1] = {
          name: ServiceTimeType[ServiceTimeType.Lunch],
          type: 'areaspline',
          data: [],
        };
        this.chartData.data[2] = {
          name: ServiceTimeType[ServiceTimeType.Dinner],
          type: 'areaspline',
          data: [],
        };
      }
    }

    /*console.log('Date Map');
		console.log(dateMap);*/
    this.total = 0;
    this.lyTotal = 0;
    let now = moment();
    let keyMatch = 0;
    for (let key in dateMap) {
      //console.log(key);
      let d = dateMap[key];
      //console.log('Date for key: '+d.date.toDate());
      if (
        this.search.group_by == 'total_week' ||
        this.search.group_by == 'average_week'
      ) {
        if (+key < 7) continue;
      } else {
        if (d.date.isBefore(info.start) || now.isBefore(d.date)) continue;
      }
      //console.log('Going: '+d.date.toDate());
      let t = d.date.unix() * 1000;
      // If search here.
      d.service_time.map(value => {
        this.total += value;
      });
      keyMatch++;
      if (this.search.compare_last_year) {
        //console.log(d);
        //console.log(d.date.toDate()+" "+lastDateMap[d.lastYearKey].date.toDate());
        //console.log(d.service_time[0]);
        this.chartData.data[0].data.push([t, d.service_time[0]]);
        if (d.lastYearKey >= 0) {
          // Didn't find it?
          this.lyTotal += lastDateMap[d.lastYearKey].service_time[0];

          this.chartData.data[1].data.push([
            t,
            lastDateMap[d.lastYearKey].service_time[0],
          ]);
        } else {
          this.chartData.data[1].data.push([t, 0]);
          //console.log('Missed key: '+d.lastYearKey);
        }
      } else if (this.isDaily) {
        this.chartData.data[0].data.push([t, d.service_time[0]]);
      } else {
        this.chartData.data[0].data.push([
          t,
          d.service_time[ServiceTimeType.Breakfast],
        ]);
        this.chartData.data[1].data.push([
          t,
          d.service_time[ServiceTimeType.Lunch],
        ]);
        this.chartData.data[2].data.push([
          t,
          d.service_time[ServiceTimeType.Dinner],
        ]);
      }
    }
    if (keyMatch < 3) {
      for (let d of this.chartData.data) {
        d.type = 'column';
      }
    }
    //console.log(this.chartData);
  }

  laborRecalc() {
    let info = this.resetChartData();
    this.mainSalesReport = true;

    this.mData = new Array(Math.ceil(info.length));
    zeroLogReports(this.mData, info.start, info.inc, info.offset, this.isDaily);

    let start = moment(this.search.start);
    let end = moment(this.search.end);

    this.total = 0;
    for (let d of this.salesReportData) {
      if (start.isSameOrBefore(d.d)) {
        this.total += d.service_time[0].sales;
        let length = moment.duration(d.d.diff(start));

        let k: number;
        // So we need round here, because daylight savings time throws stuff off.
        switch (this.search.group_by) {
          case 'day':
            k = Math.floor(length.asDays());
            break;
          case 'week':
            k = Math.floor(length.asWeeks());
            break;
          case '0':
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
            if (+this.search.group_by != d.d.day()) continue;
            k = Math.floor(length.asWeeks());
            break;
          case 'month':
            k = Math.floor(length.asMonths());
            break;
          case 'quarter':
            k = Math.floor(length.asMonths() / 3);
            break;
          case 'year':
            k = Math.floor(length.asYears());
            break;
        }
        //				console.log(d.d.toDate().toString()+" => " + k);
        addLogReport(this.mData[k], d);
      }
    }

    dataToChart(this.chartData, this.mData, this.isDaily);
  }
}
