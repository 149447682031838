import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService, IUser } from '../services/account.service';
import {
  MessageService,
  IMessage,
  MessagePageSize,
} from '../services/message.service';
import { MessageRecipientService } from '../services/messageRecipient.service';
import { IPaginator } from '../shared/api';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './inbox.html',
})
export class InboxComponent
  extends baseRole
  implements OnInit, OnDestroy, IPaginator
{
  public sub;
  public msgType: string;
  public messages: any[];
  public selectAll: boolean;
  public searchObj: any;
  public numMessages: number;
  public numPages = 0;
  public currentPage = 0;
  public maxPerPage = MessagePageSize;
  public currentOffset = 0;
  public openId: number;
  @ViewChild('modalReply') mReply;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected messageService: MessageService,
    protected messageRecipientService: MessageRecipientService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Messages');
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.openId = params['id'];
      }
    });
    this.route.data.subscribe(data => {
      this.searchObj = {
        archived: false,
        wall: false,
      };
      this.msgType = data['type'] || 'inbox';
      //console.log('Msg Type: '+this.msgType);
      switch (this.msgType) {
        case 'archive':
          this.searchObj.archived = true;
          delete this.searchObj.wall;
          break;
        case 'wall':
          this.searchObj.wall = true;
          break;
      }
      this.sub = this.messageService.getUpdateObservable().subscribe(data => {
        this.load();
      });
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  load() {
    this.messageService.countReceived(this.searchObj).subscribe(data => {
      this.numMessages = data;
      this.numPages = this.numMessages / this.maxPerPage;
      this.searchObj._limit = this.maxPerPage;
      this.searchObj._offset = this.maxPerPage * this.currentPage;
      this.messageService.listReceived(this.searchObj).subscribe(data => {
        this.messages = data;
        for (let m of this.messages) {
          for (let r of m.recipients) {
            if (r.user_id == this.getUserId()) {
              m.read = r.read;
              break;
            }
          }
          if (m.id == this.openId) {
            this.mReply.show(m);
          }
        }
        //console.log(this.messages);
      });
    });
  }

  archiveSelected() {
    for (let message of this.messages) {
      if (message._selected) this.archiveMessage(message);
    }
  }

  archiveMessage(obj) {
    let matches = obj.recipients.filter(e => {
      return e.user_id == this.getUserId();
    });
    // I have no idea what to do if it doesn't match..
    if (matches.length > 0) {
      for (let m of matches) {
        let updateObj: any = {
          id: m.id,
          archived: true,
        };
        this.messageRecipientService.update(updateObj).subscribe(data => {});
      }
      let idx = this.messages.indexOf(obj);
      this.messages.splice(idx, 1);
    } else {
      console.log('Unable to find myself as a recipient');
    }
  }

  changeSelectAll() {
    for (let m of this.messages) {
      m._selected = this.selectAll;
    }
  }

  pageChanged(event: any) {
    this.currentPage = event.page - 1; // This 1 indexed not 0
    this.load();
  }
}
