<!-- Note there is a separate set for breakfast/lunch/dinner
Is there any logical order to the stats? -->

<tabset *ngIf="_allServices && _allServices.length>1">
  <tab heading="Breakfast" (selectTab)="changeService(0)"></tab>
  <tab heading="Lunch" (selectTab)="changeService(1)"></tab>
  <tab heading="Dinner" (selectTab)="changeService(2)"></tab>
</tabset>

<div class="card" *ngIf="data">
  <div class="card-body">
    <label>Stock Stats</label>
    <div class="row" style="font-size: 10px">
      <div class="flex-200 p-1">
        <input-toggle [disabled]="true" [ngModel]="true"
          >Sales (required)</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle [(ngModel)]="data.tax" (ngModelChange)="saveField('tax')">
          Tax
        </input-toggle>
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.foh_labor_hours"
          (ngModelChange)="saveField('foh_labor_hours')">
          <span *ngIf="getLocation().has_houses">FOH</span> Labor Hours
        </input-toggle>
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.foh_labor_cost"
          (ngModelChange)="saveField('foh_labor_cost')">
          <span *ngIf="getLocation().has_houses">FOH</span> Labor Cost
        </input-toggle>
      </div>
      <div class="flex-200 p-1" *ngIf="getLocation().has_houses">
        <input-toggle
          [(ngModel)]="data.boh_labor_hours"
          (ngModelChange)="saveField('boh_labor_hours')">
          BOH Labor Hours</input-toggle
        >
      </div>
      <div class="flex-200 p-1" *ngIf="getLocation().has_houses">
        <input-toggle
          [(ngModel)]="data.boh_labor_cost"
          (ngModelChange)="saveField('boh_labor_cost')">
          BOH Labor Cost</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.guest_count"
          (ngModelChange)="saveField('guest_count')">
          Guest Count</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.comps"
          (ngModelChange)="saveField('comps')">
          Comps/House Account</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.discounts"
          (ngModelChange)="saveField('discounts')"
          >Discounts</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.employee_discounts"
          (ngModelChange)="saveField('employee_discounts')"
          >Employee Discounts</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.cc_batch_no"
          (ngModelChange)="saveField('cc_batch_no')">
          Credit Card Batch #
        </input-toggle>
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.pay_outs"
          (ngModelChange)="saveField('pay_outs')">
          Payouts</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.cash"
          (ngModelChange)="saveField('cash')"
          >Cash Collected</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.expected_cash"
          (ngModelChange)="saveField('expected_cash')">
          Expected Cash Drop</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.gift_redeemed"
          (ngModelChange)="saveField('gift_redeemed')">
          Gift Cards Redeemed</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.gift_sold"
          (ngModelChange)="saveField('gift_sold')">
          Gift Cards Sold</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.cc_tips_collected"
          (ngModelChange)="saveField('cc_tips_collected')">
          CC Tips Collected</input-toggle
        >
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.actual_bank"
          (ngModelChange)="saveField('actual_bank')"
          >Actual Bank Deposit</input-toggle
        >
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="data">
  <div class="card-body">
    <label>Notes</label>
    <div class="row" style="font-size: 10px">
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.notes_general"
          (ngModelChange)="saveField('notes_general')">
          General Notes
        </input-toggle>
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.notes_employee"
          (ngModelChange)="saveField('notes_employee')">
          Employee Issues
        </input-toggle>
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.notes_repair"
          (ngModelChange)="saveField('notes_repair')">
          Repair &amp; Maintenance
        </input-toggle>
      </div>
      <div class="flex-200 p-1">
        <input-toggle
          [(ngModel)]="data.notes_feedback"
          (ngModelChange)="saveField('notes_feedback')">
          Feedback, Complaints &amp; Customer Issues
        </input-toggle>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <label>Custom Fields</label>
    <form ngNativeValidate (submit)="saveCustom(custom)">
      <div class="input-group body-bg mb-3">
        <input
          [(ngModel)]="custom.name"
          type="text"
          maxlength="50"
          class="form-control override-invalid"
          name="new_name"
          required
          placeholder="Add a custom field" />
        <div class="input-group-append">
          <button
            class="btn bg-white btn-icon"
            style="
              padding: 0px;
              border-radius: 0%;
              height: calc(1.5em + 0.75rem + 2px);
            "
            type="submit">
            <span class="cdi-s-add"> </span>
          </button>
        </div>
      </div>
    </form>

    <ng-container *ngFor="let c of customFields">
      <div class="row" *ngIf="!c._edit">
        <div class="col-9">{{c.name}}</div>
        <div class="col-3">
          <span
            class="cdi-s-trash float-right pointer"
            (click)="deleteCustom(c)"></span>
          <span
            class="cdi-s-edit float-right pointer"
            (click)="editCustom(c)"></span>
        </div>
      </div>
      <div class="row" *ngIf="c._edit">
        <div class="input-group">
          <input
            type="text"
            maxlength="50"
            name="name"
            class="form-control"
            [(ngModel)]="c.name"
            name="name"
            required />
          <div class="input-group-append">
            <span class="cdi-s-save pointer" (click)="saveCustom(c)"> </span>
            <span class="cdi-s-cancel pointer" (click)="cancelCustom(c)"></span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="c.days[0]" [disabled]="!c._edit"
            ><small>Sun</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="c.days[1]" [disabled]="!c._edit"
            ><small>Mon</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="c.days[2]" [disabled]="!c._edit"
            ><small>Tue</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="c.days[3]" [disabled]="!c._edit"
            ><small>Wed</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="c.days[4]" [disabled]="!c._edit"
            ><small>Thu</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="c.days[5]" [disabled]="!c._edit"
            ><small>Fri</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="c.days[6]" [disabled]="!c._edit"
            ><small>Sat</small></input-toggle
          >
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="card" *ngIf="false">
  <div class="card-body">
    <label>Integrations</label>
    <div class="row mb-3">
      <div class="col-md-3">
        <h3><b>No Import</b></h3>
      </div>
      <div class="col-md-9 btn-group btn-group-toggle">
        <label
          class="btn btn-radio"
          [class.active]="apiLoc.sales_integration_type==SalesIntegrationType.None">
          <input
            type="radio"
            name="options"
            [value]="SalesIntegrationType.None"
            [(ngModel)]="apiLoc.sales_integration_type"
            (ngModelChange)="updateImport()" />Do not import sales data
        </label>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <img src="/images/vendor/square.svg" />
      </div>
      <div class="col-md-9 btn-group btn-group-toggle">
        <label
          class="btn btn-radio"
          [class.active]="apiLoc.sales_integration_type==SalesIntegrationType.Square">
          <input
            type="radio"
            name="options"
            [value]="SalesIntegrationType.Square"
            [(ngModel)]="apiLoc.sales_integration_type"
            (ngModelChange)="updateImport()" />Auto import sales data from
          Square
        </label>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <img src="/images/vendor/lavu.svg" />
      </div>
      <div class="col-md-9 btn-group btn-group-toggle">
        <label
          class="btn btn-radio"
          [class.active]="apiLoc.sales_integration_type==SalesIntegrationType.Lavu">
          <input
            type="radio"
            name="options"
            [value]="SalesIntegrationType.Lavu"
            [(ngModel)]="apiLoc.sales_integration_type"
            (ngModelChange)="updateImport()" />Auto import sales data from POS
          Lavu
        </label>
        <button
          type="button"
          *ngIf="apiLoc.sales_integration_type==SalesIntegrationType.Lavu && !salesIntegrationChanged"
          class="btn btn-secondary"
          (click)="salesIntegrationChanged=true">
          Change Keys
        </button>
      </div>

      <div
        class="col-md-3"
        *ngIf="salesIntegrationChanged && apiLoc.sales_integration_type==SalesIntegrationType.Lavu"></div>
      <div
        class="col-md-9"
        *ngIf="salesIntegrationChanged && apiLoc.sales_integration_type==SalesIntegrationType.Lavu">
        <form
          ngNativeValidate
          class="row"
          (submit)="saveLavu()"
          *ngIf="salesIntegrationChanged && apiLoc.sales_integration_type==SalesIntegrationType.Lavu">
          <div class="col-md-3">
            <label>Lavu Api Dataname</label>
          </div>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              name="lavu_api_dataname"
              required
              [(ngModel)]="dataLoc.lavu_api_dataname"
              required
              placeholder="Lavu API Dataname" />
          </div>
          <div class="col-md-3">
            <label>Lavu API Key</label>
          </div>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              name="lavu_api_key"
              required
              [(ngModel)]="dataLoc.lavu_api_key"
              required
              placeholder="Lavu API Key" />
          </div>
          <div class="col-md-3">
            <label>Lavu Api Token</label>
          </div>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              name="lavu_api_token"
              required
              [(ngModel)]="dataLoc.lavu_api_token"
              required
              placeholder="Lavu API Token" />
          </div>

          <div class="col-12">
            <alert type="success" *ngIf="saveMessage">
              <strong>Save</strong> - {{saveMessage}}
            </alert>
            <local-error [error]="saveError"></local-error>
            <cancel-button
              *ngIf="!saveMessage"
              class="float-right"
              (click)="salesIntegrationChanged=false"></cancel-button>
            <save-button *ngIf="!saveMessage" class="float-right"></save-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="card"
  *ngIf="serviceTimePercents && !getLocation().is_service_time_daily && _allServices">
  <div class="card-body">
    <div class="row">
      <div class="col d-flex justify-content-between mb-4">
        <label>Service Times</label>
        <save-button *ngIf="serviceModified" (click)="saveServiceTimes()">
        </save-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3" *ngIf="serviceTimePercents">
        <progressbar
          class="serviceTimeProgress"
          [value]="serviceTimePercents"></progressbar>
      </div>
    </div>

    <div class="d-flex flex-wrap flex-row justify-content-around">
      <div>
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="service-time-label"> Breakfast starts </label>
          </div>
          <timepicker
            [(ngModel)]="_allServices[0].start"
            name="breakfast"
            required
            (ngModelChange)="serviceTimeUpdate(true)"></timepicker>
        </div>
      </div>
      <div>
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="service-time-label"> Lunch starts </label>
          </div>
          <timepicker
            [(ngModel)]="_allServices[1].start"
            name="lunch"
            required
            (ngModelChange)="serviceTimeUpdate(true)"></timepicker>
        </div>
      </div>
      <div>
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="service-time-label"> Dinner starts </label>
          </div>
          <timepicker
            [(ngModel)]="_allServices[2].start"
            name="dinner"
            required
            (ngModelChange)="serviceTimeUpdate(true)"></timepicker>
        </div>
      </div>
    </div>
    For accounting purposes the service times must cover all 24 hours even
    though your restaurant may be closed for some hours.
  </div>
</div>
