<div *ngIf="loc">
  <div class="title text-center">Add Staff</div>
  <form ngNativeValidate (submit)="save()">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 col-12">
                <label>First Name</label>
                <input
                  [(ngModel)]="data.firstname"
                  name="first_name"
                  type="text"
                  maxlength="50"
                  class="form-control form-control-lg"
                  placeholder="First Name"
                  required />
              </div>
              <div class="col-md-4 col-12">
                <label>Last Name</label>
                <input
                  [(ngModel)]="data.lastname"
                  name="last_name"
                  type="text"
                  maxlength="50"
                  class="form-control form-control-lg"
                  placeholder="Last Name"
                  required />
              </div>
              <div class="col-md-4 col-12" *ngIf="!complex">
                <label> Email</label>
                <input
                  [(ngModel)]="data.email"
                  name="email"
                  type="email"
                  class="form-control form-control-lg"
                  debounce
                  (result)="checkEmail()"
                  placeholder="Unique Email"
                  required />
                <local-error [error]="emailError"></local-error>
              </div>
              <div class="col-md-4 col-12" *ngIf="complex">
                <label>PIN </label>
                <input
                  [(ngModel)]="data.pin"
                  name="pin"
                  type="number"
                  class="form-control form-control-lg"
                  disabled />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xs-12" *ngIf="complex">
        <div class="card">
          <div class="card-body">
            <label> Email </label>
            <input
              [(ngModel)]="data.email"
              name="email"
              type="email"
              class="form-control"
              placeholder="Email"
              required />
            <local-error [error]="emailError"></local-error>
            <small
              >Email must be unique on a location.<br />Users without an email
              will have limited access to CheddrSuite. These users can log in to
              use timecards using their PIN, but only on activated
              devices.</small
            >
            <label>Cell</label>
            <input
              [(ngModel)]="data.cell"
              name="cell"
              maxlength="15"
              type="text"
              class="form-control"
              phone />
            <input
              class="mr-2 pt-2"
              [(ngModel)]="data.confirmPhone"
              type="checkbox"
              name="confirmPhone"
              required />
            <small
              >By opting in, you consent to receive automated messages and
              acknowledge that text and data rates may apply. To end the
              service, reply with "STOP" The terms and privacy policy can be
              found on
              <a href="https://cheddrsuite.com/terms" target="_blank"
                >cheddrsuite.com/terms</a
              >.</small
            >
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xs-12" *ngIf="complex">
        <div class="card">
          <div class="card-body">
            <label> Address </label>
            <input
              [(ngModel)]="data.address"
              name="address"
              type="text"
              class="form-control"
              placeholder="Address" />
            <label>City</label>
            <input
              [(ngModel)]="data.city"
              name="city"
              type="text"
              class="form-control"
              placeholder="City" />
            <label>State</label>
            <select
              [(ngModel)]="data.state"
              name="state"
              class="form-control"
              required>
              <option *ngFor="let state of StateKeys" [value]="state">
                {{States[state]}}
              </option>
            </select>
            <label>ZIP </label>
            <input
              [(ngModel)]="data.zip"
              name="zip"
              type="text"
              maxlength="10"
              class="form-control"
              placeholder="ZIP" />
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xs-12" *ngIf="complex">
        <div class="card">
          <div class="card-body">
            <label>Date of Hire </label>
            <input
              type="text"
              class="form-control input-calendar"
              bsDatepicker
              [(ngModel)]="data.date_of_hire"
              name="date_of_hire" />
            <label>Frequency of Review</label>
            <select
              [(ngModel)]="data.freq_of_review"
              class="form-control"
              name="freq_of_review">
              <option value="0">None</option>
              <option value="1">Monthly</option>
              <option value="2">3 Months</option>
              <option value="3">6 Months</option>
              <option value="4">Yearly</option>
            </select>

            <label class="mt-3">
              Schedule Color
              <input-color
                [(ngModel)]="data.color"
                class="float-right"
                name="schedule_color"></input-color>
            </label>
            <label>Max Week Hours for Schedule Warning</label>
            <input
              [(ngModel)]="data.max_hours"
              name="max_hours"
              type="number"
              step="0.25"
              min="1"
              class="form-control" />
            <label>Max Day Hours before Overtime pay</label>
            <input
              [(ngModel)]="data.ot_hours"
              name="ot_hours"
              type="number"
              min="1"
              step="0.25"
              class="form-control" />
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="complex">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <label> Employee Notes</label>
            <textarea
              [(ngModel)]="data.notes"
              name="notes"
              class="form-control"
              rows="2"
              placeholder="Employee Notes"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="loc && complex">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <label> Permissions Type for Employee</label>
            <div class="row">
              <div class="col">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="data.user_type==UserType.Manager">
                    <input
                      type="radio"
                      name="options"
                      [value]="UserType.Manager"
                      [(ngModel)]="data.user_type" />
                    {{ loc.level_3 }}
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="data.user_type==UserType.ShiftLead">
                    <input
                      type="radio"
                      name="options"
                      [value]="UserType.ShiftLead"
                      [(ngModel)]="data.user_type" />{{loc.level_2}}
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="data.user_type==UserType.Regular">
                    <input
                      type="radio"
                      name="options"
                      [value]="UserType.Regular"
                      [(ngModel)]="data.user_type" />{{loc.level_1}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="complex">
      <div class="card-body row-striped">
        <label> Positions</label>
        <ng-container *ngFor="let p of myPositions">
          <div class="row row-table" *ngIf="!p._edit">
            <div class="col-5">
              <b>{{p.name}}</b>
              <span *ngIf="p.is_primary">&nbsp;- Primary Position</span>
            </div>
            <div class="col">
              <span *ngIf="p.rate_type == RateType.Salary || !isSalary">
                {{ p.rate | currency: 'USD' }} {{ RateType[p.rate_type] }}
              </span>
              <span *ngIf="p.rate_type != RateType.Salary && isSalary">
                Salaried
              </span>
            </div>
            <div class="col d-flex justify-content-end">
              <span class="cdi-s-edit pointer" (click)="editPosition(p)">
              </span>
              <span
                class="cdi-s-trash pointer"
                *ngIf="!p.is_primary"
                (click)="deletePosition(p)">
              </span>
              <span class="cdi-s-blank" *ngIf="p.is_primary"> </span>
            </div>
          </div>

          <form *ngIf="p._edit">
            <div class="row d-flex">
              <div class="col-md-5">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> {{p.name}}</span>
                  </div>
                  <input-toggle
                    name="is_primary"
                    [(ngModel)]="p.is_primary"
                    [disabled]="p._edit.is_primary">
                    Primary Position</input-toggle
                  >
                </div>
              </div>
              <div class="col">
                <div class="input-group mb-3">
                  <div
                    class="input-group-prepend"
                    *ngIf="!isSalary || p.rate_type == RateType.Salary">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    *ngIf="!isSalary || p._edit.rate_type == RateType.Salary"
                    [(ngModel)]="p.rate"
                    type="number"
                    name="rate"
                    required
                    class="form-control" />
                  <select
                    *ngIf="!isSalary || p._edit.rate_type == RateType.Salary"
                    class="form-control d-inline-block"
                    [(ngModel)]="p.rate_type"
                    name="rate_type">
                    <option [value]="RateType.Salary">Salary (Yearly)</option>
                    <option [value]="RateType.Hourly">Hourly</option>
                  </select>
                  <div class="input-group-append">
                    <span
                      class="cdi-s-save float-right pointer"
                      (click)="savePosition(p)">
                    </span>
                    <span
                      class="cdi-s-cancel float-right pointer"
                      (click)="cancelPosition(p)"></span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>

    <div
      class="card"
      *ngIf="availablePositions && availablePositions.length!=0">
      <div class="card-body">
        <form ngNativeValidate *ngIf="newPosition">
          <div class="row d-flex">
            <div class="col-md-5">
              <div class="input-group mb-3">
                <select
                  [ngModel]="newPosition.position_id ||''"
                  (ngModelChange)="newPosition.position_id =$event"
                  class="form-control-select override-invalid"
                  name="position_id"
                  required>
                  <option *ngFor="let p of availablePositions" [value]="p.id">
                    {{p.name}}
                  </option>
                </select>
                <div class="input-group-append py-1">
                  <input-toggle
                    name="is_primary"
                    [(ngModel)]="newPosition.is_primary"
                    [disabled]="myPositions && myPositions.length==0">
                    Primary Position</input-toggle
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="input-group mb-3" *ngIf="!isSalary">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="newPosition.rate"
                  type="number"
                  step="0.01"
                  name="rate"
                  required
                  class="form-control form-control-lg override-invalid"
                  placeholder="Pay Rate" />
                <select
                  class="form-control-select"
                  [(ngModel)]="newPosition.rate_type"
                  name="rate_type"
                  required>
                  <option [value]="RateType.Salary">Salary (Yearly)</option>
                  <option [value]="RateType.Hourly">Hourly</option>
                </select>
                <div class="input-group-append" *ngIf="complex">
                  <span class="cdi-s-add pointer" (click)="addPosition()">
                  </span>
                </div>
              </div>
              <div *ngIf="isSalary && complex" class="mt-2">
                <span class="cdi-s-add pointer" (click)="addPosition()"> </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <global-error [error]="newPositionError"></global-error>

    <!--	<div class='card'>
			<div class='card-body'>
				<label>Locations</label>
				<div class="row">
					<div class="col-md-3 col-xs-12" *ngFor="let l of data.locations">
						<input-toggle [name]="'loc_'+l.id" [(ngModel)]="l._selected">{{l.name}}</input-toggle>
					</div>
				</div>
			</div>
		</div>  -->

    <div class="card" *ngIf="complex">
      <div class="card-body">
        <div class="row">
          <label> Wait to send this user their login details?</label>
          <div class="col-md-3 col-xs-12">
            <input-toggle [(ngModel)]="data.no_email" name="wait_to_send"
              >Yes, wait to send</input-toggle
            >
          </div>
        </div>
        <p style="font-size: 12px" class="mb-1">
          This allows you to get the Cheddr account fully loaded with details
          prior to sending employees their logins. When you're ready to invite
          your staff, select Send Emails to Unverified Staff.
        </p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <button
          type="button"
          class="btn btn-secondary"
          [disabled]="emailError"
          *ngIf="!complex"
          (click)="complex=true">
          Add Full
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          [disabled]="emailError"
          *ngIf="complex"
          (click)="complex=false">
          Add Fast
        </button>
        <cancel-button class="float-right" routerLink="/users"></cancel-button>
        <button
          class="btn btn-primary float-right mx-2"
          [disabled]="emailError"
          (click)="addType=1">
          Add &amp; New
        </button>
        <button
          class="btn btn-primary float-right"
          [disabled]="emailError"
          (click)="addType=0">
          Add &amp; Continue
        </button>
      </div>
    </div>
  </form>
</div>
