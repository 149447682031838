import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import { SalesGoalService, ISalesGoal } from '../services/salesGoal.service';
import { LogService } from '../services/log.service';
import { baseRole } from '../shared/baseRole';
import { DaysOfWeek, DaysOfWeekKeys, moment } from '../shared/api';

function round2(data: number): number {
  return Math.round(data * 100 + 0.0001) / 100;
}

@Component({
  selector: 'modal-adjust-sales',
  templateUrl: 'modalAdjustSales.html',
})
export class ModalAdjustSalesComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  @ViewChild('adjustModal')
  public adjustModal: ModalDirective;

  @ViewChild('reviewModal')
  public reviewModal: ModalDirective;
  //	public loc: IApiLocation;
  public LY: number[] = []; //[7] is the weekly number
  public LW: number[] = [];
  public estimated: number;
  public percent: number;
  public weekTotal: number;
  //public days: Date[] = [];
  //public DaysOfWeek = DaysOfWeek;
  //public dayArray: number[];
  public lastYear: number;
  public lastWeek: number;
  public startIndex = 0;
  @Input() startDate;
  @Input() endDate;

  public stats;
  public goals: ISalesGoal[];

  constructor(
    public accountService: AccountService,
    public salesGoalService: SalesGoalService,
    public logService: LogService
  ) {
    super(accountService);
  }

  ngOnInit() {}
  ngOnDestroy() {
    document.body.className = ''; // Blow the background back out.
  }

  load() {
    this.salesGoalService
      .list({ start: this.startDate, end: this.endDate })
      .subscribe(data => {
        this.goals = data;
        this.logService.sumBreakdown(this.startDate).subscribe(data => {
          this.stats = data;
          this.estimated = this.stats.lastYear / 52;
          if (this.goals.length > 0) {
            this.estimated = 0;
            for (let g of this.goals) {
              this.estimated += g.goal;
            }
          } else {
            this.goals = [];
            let d = moment(this.startDate);
            for (let i = 0; i < 7; i++) {
              this.goals.push({
                location_id: this.getLocationId(),
                date: d.toDate(),
                goal: round2(this.estimated / 7),
              });
              d.add(1, 'days');
            }
          }
          this.weekTotal = 0;
          for (let g of this.goals) {
            this.weekTotal += g.goal;
          }
          this.weekTotal = round2(this.weekTotal);

          let lw = moment(this.startDate).add('-1', 'weeks');
          let ly = moment(this.startDate).add('-1', 'years');
          let lwTotal = 0;
          let lyTotal = 0;
          for (let i = 0; i < 7; i++) {
            let lyKey = ly.format('YYYY-MM-DD');
            let lwKey = lw.format('YYYY-MM-DD');
            this.LY[i] =
              lyKey in this.stats.data ? this.stats.data[lyKey].sales : 0;
            this.LW[i] =
              lwKey in this.stats.data ? this.stats.data[lwKey].sales : 0;
            lyTotal += this.LY[i];
            lwTotal += this.LW[i];
            lw = lw.add(1, 'days');
            ly = ly.add(1, 'days');
          }
          this.LY[7] = lyTotal;
          this.LW[7] = lwTotal;
          //console.log('Sales Stats');
          //console.log(this.stats);
        });
      });

    //this.percent = this.amt[7] / this.LY[7] *100;

    //this.adjustModal.show();
  }

  showAdjust() {
    this.load();
    this.cancelReview();
    this.adjustModal.show();
  }

  showReview() {
    //		console.log('Showing Review');
    this.load();
    this.cancelAdjust();
    this.reviewModal.show();
  }

  cancelAdjust() {
    this.adjustModal.hide();
    // This is to fix a problem with the modal sliding closed before the other one is.
    document.body.className = '';
  }

  cancelReview() {
    this.reviewModal.hide();
  }

  saveAdjust() {
    this.salesGoalService.bulkUpdate(this.goals).subscribe(() => {
      this.adjustModal.hide();
    });
  }

  saveReview() {
    //		console.log('Saving goals');
    //		console.log(this.goals);
    this.salesGoalService.bulkUpdate(this.goals).subscribe(() => {
      this.reviewModal.hide();
    });
  }

  sum() {
    this.weekTotal = 0;
    for (let g of this.goals) {
      this.weekTotal += g.goal;
      g.goal = round2(g.goal);
    }
    this.weekTotal = round2(this.weekTotal);

    this.percent = (this.weekTotal / (this.stats.lastYear / 52)) * 100;
  }

  upOnePC(
    day //[7] is the weekly number
  ) {
    if (day == 7) {
      for (let g of this.goals) {
        this.upOnePC(g);
      }
    } else {
      day.goal += day.goal / 100;
    }

    this.sum();
  }

  downOnePC(
    day // gonna need an arg here
  ) {
    if (day == 7) {
      for (let g of this.goals) {
        this.downOnePC(g);
      }
    } else {
      day.goal -= day.goal / 100;
    }

    this.sum();
  }

  amtChange() {
    this.sum();
  }
}
