import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { LocationService, ILocation } from '../services/location.service';
import {
  IntegrationUserService,
  IIntegrationUser,
} from '../services/integrationUser.service';
import { baseRole } from '../shared/baseRole';
import { UserType } from '../shared/api';

@Component({
  templateUrl: './integration.html',
})
export class IntegrationComponent extends baseRole implements OnInit {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.accountService.getLocation()) {
      this.router.navigate(['/settings/account']);
      return;
    }
  }
}
