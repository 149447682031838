import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardJail } from '../app/auth-guard';
import { IntegrationUserComponent } from './integrationUser.component';
import { ManageComponent } from './manage.component';
import { PositionsComponent } from './positions.component';
import { TimeOffComponent } from './timeOff.component';
import { TradeComponent } from './trade.component';
import { UsersComponent } from './users.component';
import { UserAddComponent } from './userAdd.component';
import { UserEditComponent } from './userEdit.component';

const appRoutes: Routes = [
  {
    path: 'users',
    canActivate: [AuthGuard, AuthGuardJail],
    component: UsersComponent,
    children: [
      { path: '', component: ManageComponent },
      { path: 'user/add', component: UserAddComponent },
      { path: 'user/:id', component: UserEditComponent },
      { path: 'trade', component: TradeComponent },
      { path: 'timeoff', component: TimeOffComponent },
      { path: 'positions', component: PositionsComponent },
      { path: 'integration', component: IntegrationUserComponent },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
