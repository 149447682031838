import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AccountService, IApiUser } from '../services/account.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  PurchaseOrderService,
  IPurchaseOrder,
  PurchaseOrderStatus,
} from '../services/purchaseOrder.service';
import { SkuService } from '../services/sku.service';
import { ContactService } from '../services/contact.service';
import { ExpenseAccountService } from '../services/expenseAccount.service';
import { SalesGoalService } from '../services/salesGoal.service';
import { baseRole } from '../shared/baseRole';
import {
  SelectAddAll,
  DateMenuType,
  ExpenseType,
  getEndOfWeek,
} from '../shared/api';

const dateField = [
  '_sunday',
  '_monday',
  '_tuesday',
  '_wednesday',
  '_thursday',
  '_friday',
  '_saturday',
];

@Component({
  templateUrl: 'purchaseOrderGenerate.html',
})
export class PurchaseOrderGenerateComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public startDate: Date;
  public poDate: Date;
  public inventoryDate;
  public contacts: any[];
  public _allSkus: any[];
  public skus: any[];
  public expenseAccountId = '';
  public contactId = '';
  public accounts: any[];
  public total = 0;
  public budget = 0;
  public pendingPrice = 0;
  public pendingPercent = 0;
  public approvedPrice = 0;
  public approvedPercent = 0;
  public sub;

  public currentContact;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected skuService: SkuService,
    protected contactService: ContactService,
    protected salesGoalService: SalesGoalService,
    protected expenseAccountService: ExpenseAccountService,
    protected poService: PurchaseOrderService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.EditPO)) {
      this.router.navigate(['/inventory']);
      return;
    }
    this.inventoryDate = new Date();
    this.poDate = new Date();

    this.contactService.list().subscribe(data => {
      this.contacts = SelectAddAll(data, 'All Vendors', 'company');
    });
    this.expenseAccountService
      .list({ expense_type: ExpenseType.COGS })
      .subscribe(data => {
        this.accounts = SelectAddAll(data, 'All Categories');
      });
    this.loadInventoryInfo();

    let loc = this.accountService.getLocation();

    this.sub = this.accountService
      .getCurrentDateUpdateObservable(DateMenuType.Weekly)
      .subscribe(date => {
        if (!date) return;
        this.startDate = date;
        let endDate = getEndOfWeek(this.startDate);
        this.salesGoalService
          .list({ start: this.startDate, end: endDate })
          .subscribe(goals => {
            this.budget = 0;
            for (let g of goals) {
              this.budget += g.goal;
            }
            this.poService
              .sumByType(this.startDate, endDate)
              .subscribe(prices => {
                for (let p of prices) {
                  if (p.status == PurchaseOrderStatus.Approved) {
                    this.approvedPrice = p.price;
                    if (this.budget) {
                      this.approvedPercent =
                        (this.budget - this.approvedPrice) / this.budget;
                    }
                  }
                  if (p.status == PurchaseOrderStatus.Pending) {
                    this.pendingPrice = p.price;
                    if (this.budget) {
                      this.pendingPercent = this.pendingPrice / this.budget;
                    }
                  }
                }
              });
          });
      });
  }

  clearSuggested() {
    for (let s of this._allSkus) {
      s.qty = 0;
    }
  }

  loadInventoryInfo() {
    this.skuService.listForGenPO(this.inventoryDate).subscribe(data => {
      this._allSkus = data;
      let dKey = dateField[this.inventoryDate.getDay()];
      for (let d of this._allSkus) {
        d.qty = 0;
        if (d.par_type == 'std') {
          if (d.subdivided) {
            //buying units and par units different
            if (d.par_qty >= d.count)
              //12 bottles of beer in a case
              d.qty = d.reorder_qty; //par based on bottles, the inner unit
          } else {
            if (d.par_qty >= d.count) {
              d.qty = d.reorder_qty;
            }
          }
          d.parqty = d.par_qty; // simple here, not so for daily
        } else if (d.par_type == 'daily') {
          d.parqty = (<any>d)['par_qty' + dKey];
          if ((<any>d)['par_qty' + dKey] > d.count) {
            d.qty = (<any>d)['reorder_qty' + dKey];
          }
        }
      }
      this.filter();
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  save() {
    let skus = this.skus.filter(e => {
      return e.qty && e.qty > 0;
    });
    if (skus.length == 0) {
      this.router.navigate(['/inventory/orders/purchase']);
    } else {
      this.poService
        .createMany({
          location_id: this.getLocationId(),
          date: this.poDate,
          skus: skus,
        })
        .subscribe(data => {
          this.router.navigate(['/inventory/orders/purchase']);
        });
    }
  }

  cancel() {
    this.router.navigate(['/inventory/orders/purchase']);
  }

  recalc() {
    this.total = 0;
    for (let s of this.skus) {
      if (s.qty) {
        this.total += s.qty * s.price;
      }
    }
  }

  filter() {
    if (this.contactId) {
      this.currentContact = this.contacts.filter(e => {
        return e.id == this.contactId;
      })[0];
    } else {
      this.currentContact = null;
    }

    if (!this.expenseAccountId && !this.contactId) {
      this.skus = this._allSkus;
      this.recalc();
      return;
    }
    this.skus = this._allSkus.filter(e => {
      if (
        this.expenseAccountId &&
        e.expense_account_id != this.expenseAccountId
      )
        return false;
      if (this.contactId && e.contact_id != this.contactId) return false;
      return true;
    });
    this.recalc();
  }
}
