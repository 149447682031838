import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  LocationService,
  ILocation,
  IApiLocation,
} from '../services/location.service';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
import {
  EmployeePositionService,
  RateType,
} from '../services/employeePosition.service';
import { FixedCostService, IFixedCost } from '../services/fixedCost.service';
import {
  ExpenseType,
  FixedCostType,
  FixedCostPeriod,
  LocationLaborType,
} from '../shared/api';

@Component({
  templateUrl: './cost.html',
})
export class CostComponent implements OnInit, OnDestroy {
  public fixedCosts: IFixedCost[];
  public expenseAccounts: IExpenseAccount[];
  public newExpense: any = { expense_type: ExpenseType.COGS };
  public loc: IApiLocation;
  public salaryUsers: any[];
  public ExpenseType = ExpenseType;
  public LocationLaborType = LocationLaborType;
  public FixedCostPeriod = FixedCostPeriod;
  public FixedCostPeriodKeys = Object.keys(FixedCostPeriod).filter(Number);
  public FixedCostType = FixedCostType;

  public fixedCostSub;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected fixedCostService: FixedCostService,
    protected expenseAccountService: ExpenseAccountService,
    protected employeePositionService: EmployeePositionService,
    protected locationService: LocationService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    if (!this.accountService.getLocation()) {
      this.router.navigate(['/settings/account']);
      return;
    }
    this.loc = this.accountService.getLocation();
    //		console.log(this.loc);

    this.fixedCostSub = this.fixedCostService
      .getUpdateObservable()
      .subscribe(data => {
        this.fixedCostService.list().subscribe(data => {
          this.fixedCosts = data;
        });
      });

    this.expenseAccountService.list().subscribe(data => {
      this.expenseAccounts = data;
      /*	if(!this.loc.has_houses) { // may have converted from houses to no houses
				for(let e of this.expenseAccounts){ // done in DB now
					if(e.expense_type == ExpenseType.BOH) e.expense_type=ExpenseType.FOH;
				}
			} */
    });

    this.employeePositionService
      .list({ rate_type: RateType.Salary })
      .subscribe(data => {
        this.salaryUsers = data.filter(e => {
          return e.active;
        });
      });
  }

  ngOnDestroy() {
    if (this.fixedCostSub) this.fixedCostSub.unsubscribe();
  }

  deleteFixedCost(obj) {
    this.fixedCostService.delete(obj.id).subscribe(data => {
      let idx = this.fixedCosts.indexOf(obj);
      this.fixedCosts.splice(idx, 1);
    });
  }

  saveLocationField(fieldName) {
    let updateObj: any = {
      id: this.loc.id,
    };

    updateObj[fieldName] = (<any>this.loc)[fieldName];
    this.locationService.update(updateObj).subscribe(data => {});
  }

  editExpense(obj) {
    obj._edit = Object.assign({}, obj);
  }

  deleteExpense(obj) {
    this.expenseAccountService.delete(obj.id).subscribe(data => {
      let idx = this.expenseAccounts.indexOf(obj);
      this.expenseAccounts.splice(idx, 1);
    });
  }

  cancelExpense(obj) {
    delete obj._edit;
  }

  saveExpense(obj) {
    this.expenseAccountService.update(obj._edit || obj).subscribe(data => {
      if (!obj.id) {
        obj.id = data.id;
        obj._can_delete = true;
        this.expenseAccounts.push(obj);
        this.newExpense = { expense_type: ExpenseType.COGS };
      } else {
        Object.assign(obj, obj._edit);
        delete obj._edit;
      }
    });
  }
}
