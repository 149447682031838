<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" *ngIf="!isPdf()">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          <span *ngIf="isImage()">View File</span>
          <span *ngIf="isVideo()">View Video</span>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div *ngIf="isImage()">
          <img [src]="getDocData()" class="img-fluid" />
        </div>
        <div *ngIf="isVideo()" class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            [src]="'https://www.youtube.com/embed/'+youTubeUrlToId()+'?rel=0'|safeUrl"
            frameborder="0"
            allowfullscreen></iframe>
        </div>

        <div class="row my-2" *ngIf="!isPdf()">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-primary float-right"
              (click)="cancel()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-dialog modal-lg" style="height: 100%" *ngIf="isPdf()">
    <div class="modal-content" style="height: 95%">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          View File
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" style="height: 90%">
        <div *ngIf="isImage()">
          <img [src]="getDocData()" class="img-fluid" />
        </div>
        <div *ngIf="isVideo()" class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            [src]="'https://www.youtube.com/embed/'+youTubeUrlToId()+'?rel=0'|safeUrl"
            frameborder="0"
            allowfullscreen></iframe>
        </div>
        <div *ngIf="isPdf()" style="height: 100%" class="w-100">
          <object
            [data]="getDocData()|safeUrl"
            type="application/pdf"
            class="w-100 h-100">
            <embed
              [src]="getDocData()|safeUrl"
              type="application/pdf"
              class="w-100 h-100" />
          </object>
        </div>

        <div class="row my-2" *ngIf="!isPdf()">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-primary float-right"
              (click)="cancel()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
