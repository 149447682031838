import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, forkJoin, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IIdentifiable, IResult, getBaseUrl } from '../shared/api';
import { AccountService } from './account.service';

export class BaseService<T extends IIdentifiable> {
  protected _blockObservable = false;
  public replaySubject: ReplaySubject<any> = <ReplaySubject<any>>(
    new ReplaySubject(1)
  );
  protected baseUrl: string;
  protected defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  protected defaultOptions = {
    withCredentials: true,
    headers: this.defaultHeaders,
  };

  constructor(
    protected accountService: AccountService,
    protected http: HttpClient,
    extraUrlPath: string
  ) {
    this.baseUrl = getBaseUrl() + extraUrlPath + '/';
    this.initMessage();
  }

  private initMessage() {
    this.replaySubject.next(true);
  }

  _blockUpdate(val: boolean) {
    this._blockObservable = val;
  }
  notifyUpdate(data: any = true) {
    if (!this._blockObservable) {
      this.replaySubject.next(data);
    }
  }
  getUpdateObservable(): Observable<any> {
    return this.replaySubject.asObservable();
  }

  public static handleError(error: Response) {
    //console.log('In Error Handler');
    //console.log(JSON.stringify(error,null,2));
    if (error.status == 422) {
      if ((<any>error).error) {
        return throwError((<any>error).error);
      }
      return throwError(error.json());
    } else if (error.status == 403) {
      return throwError({
        __status: 0,
        __message: 'You are not authorized to access this object.',
      } as IResult);
    } else if (error.status == 401) {
      console.log('Error status 401');
      console.log(error);
      AccountService.logoutRedirect();
    } else if (error.status == 404) {
      // Might want to do something different here. We'll see.
      return throwError({
        __status: 0,
        __message: 'Server is offline?',
      } as IResult);
    } else {
      return throwError({
        __status: 0,
        __message: 'Server error: ' + error.status,
      } as IResult);
    }
  }

  public getErrorMessages(error: IResult, unused = null) {
    return BaseService.getErrorMessages(error);
  }

  public static getErrorMessages(error: IResult): any {
    let errorArray: any = {};
    if (error.__message == 'Validation Error') {
      return error.__properties;
    } else {
      errorArray.global = 'Something went wrong';
    }
    return errorArray;
  }

  preProcess(data: any): any {
    return data;
  }

  postProcess(): (data: any) => any {
    return data => {
      return data;
    };
  }

  list(filter?: any): Observable<T[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }

    if (!filter || !this.accountService.isAdmin() || !filter.location_id) {
      queryParameters = userFilter.updateQuery(queryParameters);
    }

    return this.http
      .get(this.baseUrl, { params: queryParameters })
      .pipe(map(this.postProcess()), catchError(BaseService.handleError));
  }

  // This can be used on some objects..
  search(searchString: string | Object): Observable<any[]> {
    let data: any = {};
    let userFilter = this.accountService.getUserFilter();
    data = userFilter.updateObject(data);

    if (searchString != null && typeof searchString == 'string') {
      data.searchString = searchString;
    } else if (searchString) {
      for (let k in <Object>searchString) {
        if (searchString[k]) {
          data[k] = searchString[k];
        }
      }
    }

    return this.http
      .post<any[]>(this.baseUrl + 'search', data, this.defaultOptions)
      .pipe(map(this.postProcess()));
  }

  count(filter: any): Observable<number> {
    return this.http.post<number>(
      this.baseUrl + 'count',
      filter,
      this.defaultOptions
    );
  }

  get(id: string | number): Observable<T> {
    return this.http.get<T>(this.baseUrl + id).pipe(map(this.postProcess()));
  }

  delete(id: string | number): Observable<boolean> {
    return this.http
      .delete<boolean>(this.baseUrl + id, this.defaultOptions)
      .pipe(
        map(data => {
          this.notifyUpdate();
          return data;
        })
      );
  }

  bulkUpdate(data: any[]): Observable<T[]> {
    let oList: Observable<any>[] = [];
    this._blockUpdate(true);
    if (!data || data.length == 0) return of([]);
    for (let d of data) {
      oList.push(this.update(d));
    }
    return forkJoin(oList).pipe(
      map(data => {
        this._blockUpdate(false);
        this.notifyUpdate();
        return data;
      })
      // Todo Catch the error.
    );
  }

  bulkDelete(data: number[]): Observable<number> {
    if (!data || data.length == 0) return of(0);
    let oList: Observable<any>[] = [];
    for (let d of data) {
      oList.push(
        this.http.delete<boolean>(this.baseUrl + d, this.defaultOptions)
      );
    }
    return forkJoin(oList).pipe(
      map(data => {
        this.notifyUpdate();
        return data.length;
      })
    );
  }

  reOrder(data: { id: number; order_by?: number }[]): Observable<number> {
    if (data.length == 0) {
      return of(0);
    }
    return this.http
      .put<number>(this.baseUrl + 'reOrder', data, this.defaultOptions)
      .pipe(
        map(data => {
          this.notifyUpdate();
          return data;
        })
      );
  }

  update(data: any): Observable<T> {
    let id = data.id;
    let guid = data.guid;

    data = this.preProcess(data);
    if (id || guid) {
      return this.http
        .put<T>(this.baseUrl + (id || guid), data, this.defaultOptions)
        .pipe(
          map(data => {
            this.notifyUpdate();
            return data;
          }),
          catchError(BaseService.handleError)
        );
    } else {
      let userFilter = this.accountService.getUserFilter();
      data = userFilter.updateObject(data);
      return this.http.post<T>(this.baseUrl, data, this.defaultOptions).pipe(
        map(data => {
          this.notifyUpdate();
          return data;
        }),
        catchError(BaseService.handleError)
      );
    }
  }

  fixDate(obj: any, field: string) {
    fixDate(obj, field);
  }

  dateToLocal(obj: any, field: string) {
    dateToLocal(obj, field);
  }

  formatDateOnly(obj: any, field: string) {
    if (!(field in obj)) return;
    fixDate(obj, field);
    obj[field] = formatDateOnly(obj[field]);
  }

  fixTime(obj: any, field: string) {
    fixTime(obj, field);
  }
}

export function fixDate(obj: any, field: string): void {
  if (!obj) return null;
  if (field in obj && obj[field]) {
    if (typeof obj[field] == 'string') {
      if (obj[field].length < 14) {
        let parts = obj[field].split('-');
        obj[field] = new Date(parts[0], parts[1] - 1, parts[2]);
      } else {
        obj[field] = new Date(obj[field]);
      }
    } else if ('toDate' in obj[field]) {
      // This should fix moment dates..
      obj[field] = new Date(obj[field].toDate());
    }
  }
}

export function fixShortDate(obj: any, field: string): void {
  if (!obj) return null;
  if (field in obj && obj[field]) {
    if (typeof obj[field] == 'string') {
      let parts = obj[field].replace('T', '-').split('-');
      obj[field] = new Date(parts[0], parts[1] - 1, parts[2]);
    }
  }
}

export function dateToLocal(obj: any, field: string): void {
  fixDate(obj, field);
  const date = obj[field];
  if (!date) return;
  date.toJSON = function () {
    let newDate = new Date(this.getTime());
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate.toJSON().split('T')[0];
  };
}

export function formatDateOnly(date: Date): string {
  if (!date) return null;
  let newDate = new Date(date.getTime());
  newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
  return newDate.toJSON().split('T')[0];
}

export function fixTime(obj: any, field: string): void {
  if (field in obj && typeof obj[field] == 'string') {
    let d = new Date();
    let fields = obj[field].split(':');
    d.setHours(fields[0]);
    d.setMinutes(fields[1]);
    d.setSeconds(fields[2]);
    obj[field] = d;
  }
}

export function timeToString(obj: any, field: string): void {
  if (field in obj && typeof obj[field] != 'string') {
    obj[field] =
      obj[field].getHours() +
      ':' +
      obj[field].getMinutes() +
      ':' +
      obj[field].getSeconds();
  }
}

export { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
