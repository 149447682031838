import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { IconsRegistry } from './icons.service';

@Component({
  selector: 'icons',
  template: ` <ng-content></ng-content> `,
  styles: [':host::ng-deep svg{width: 18px; height: 18px}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconsComponent {
  private svgIcon: SVGElement;

  @Input() set color(color: string) {
    if (this.svgIcon) {
      this.svgIcon.setAttribute('stroke', color);
    }
  }
  @Input()
  set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.iconRegistry.getIcon(iconName);
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
  }

  constructor(
    private element: ElementRef,
    private iconRegistry: IconsRegistry,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    const svgElement =
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path');

    return svgElement;
  }
}
