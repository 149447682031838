import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import {
  NgModel,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { IDoc, DocService } from '../services/doc.service';
import { AccountService } from '../services/account.service';
import { SignatureService, ISignature } from '../services/signature.service';

@Component({
  selector: 'modal-doc-sign',
  templateUrl: 'modalDocSign.html',
})
export class ModalDocSignComponent {
  public data: any;
  public doc: any;
  public checked = false;
  public hasDrawn = false;
  @ViewChild('signature') signature;
  @ViewChild('modalDocSign')
  public modal;

  @Input() perms;
  @Output() onComplete = new EventEmitter<boolean>();

  constructor(
    public accountService: AccountService,
    public signatureService: SignatureService
  ) {}

  show(doc: IDoc) {
    this.doc = doc;
    this.data = {
      perms: this.perms,
      doc_id: doc.id,
      user_id: this.perms
        ? this.perms.user_id
        : this.accountService.getUserId(),
      name: null,
      svg: null,
    };
    this.modal.show();
  }

  save() {
    let data = this.signature.getSvg();
    let idx = data.indexOf(',');
    this.data.svg = atob(data.substring(idx + 1));
    this.signatureService.update(this.data).subscribe(data => {
      this.accountService.setLocation().subscribe(() => {});
      this.doc.signature_id = data.id;
      this.signature.clear();
      this.onComplete.emit(true);
      this.modal.hide();
    });
  }
  cancel() {
    this.modal.hide();
  }
}
