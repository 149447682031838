import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { RecipeBlockService } from '../services/recipeBlock.service';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './recipe.html',
})
export class RecipeComponent extends baseRole implements OnInit {
  public data: any[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected recipeBlockService: RecipeBlockService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.ManageInventory)) {
      this.router.navigate(['/']);
      return;
    }
    this.recipeBlockService.list().subscribe(data => {
      this.data = data;
    });
  }

  delete(obj) {
    this.recipeBlockService.delete(obj.id).subscribe(() => {
      let idx = this.data.indexOf(obj);
      this.data.splice(idx, 1);
    });
  }
}
