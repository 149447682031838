<delete-modal
  [deleteObject]="deleteObj"
  [name]="deleteObj?.title"
  (onDelete)="deleteEvent($event)"
  #deleteModal></delete-modal>
<modal-event #modalEvent></modal-event>
<modal-task #modalTask></modal-task>

<div class="row">
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body" *ngIf="calendarOptions">
        <full-calendar
          id="dash-calendar"
          #ucCalendar
          [options]="calendarOptions"
          class="thumbnail"></full-calendar>
      </div>

      <div *ngIf="todaysDate && todaysEvents.length>0" class="px-3 pb-3">
        <label class="text-center">{{ todaysDate | date }}</label>
        <div
          *ngFor="let t of todaysEvents"
          [style.background-color]="t.backgroundColor"
          [style.color]="textColor(t.backgroundColor)"
          class="py-1">
          <span
            style="font-size: 1.2rem"
            class="pointer px-2"
            *ngIf="t.data && t.data.event_type==1"
            (click)="isAllowed(Permission.EditEvent) && modalEvent.show(t.data)">
            {{t.title}}</span
          >
          <span
            style="font-size: 1.2rem"
            class="pointer px-2"
            *ngIf="t.data && t.data.event_type==2"
            (click)="isAllowed(Permission.EditEvent) && modalTask.show(t.data)">
            {{t.title}}</span
          >
          <span style="font-size: 1.2rem" *ngIf="!t.data" class="px-2">
            {{t.title}}</span
          >
          <span
            *ngIf="t.data && t.data.repeat_option > 0"
            class="cdi-s-refresh align-bottom"></span>
          <span
            class="float-right cdi-s-trash pointer calendar-delete"
            *ngIf="isAllowed(Permission.EditEvent) && t.data"
            (click)="deleteObj =t;deleteModal.show()"></span>
        </div>
      </div>
    </div>
  </div>
</div>
