import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { baseRole } from '../shared/baseRole';
import { IDocGroup, DocGroupService } from '../services/docGroup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './docs.html',
})
export class DocsComponent extends baseRole implements OnInit, OnDestroy {
  public collapse = true;
  public data: IDocGroup[];
  public doc_group_id: number;
  public doc_group_name: string;
  public updateSub;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected docGroupService: DocGroupService,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Documents');
  }

  ngOnInit() {
    //console.log('Main docs');
    if (!this.isAllowed(this.Permission.ManageDoc)) {
      this.router.navigate(['/']);
      return;
    }
    this.updateSub = this.docGroupService
      .getUpdateObservable()
      .subscribe(() => {
        //			console.log('Got Update');
        this.load();
      });
    this.load();
  }

  ngOnDestroy() {
    this.updateSub.unsubscribe();
  }

  load() {
    //console.log('Loading');
    //console.log(this);
    this.docGroupService.list().subscribe(data => {
      this.data = data;
      this.data.sort((a, b) => a.name.localeCompare(b.name));
      //console.log(data);
      if (this.data.length == 0) {
        this.router.navigate(['/docs/add']);
        return;
      } else if (!this.doc_group_id) {
        this.doc_group_id = this.data[0].id;
        this.doc_group_name = this.data[0].name;
        this.navigate();
      } else {
        this.navigate();
      }
    });
  }

  navigate() {
    this.router.navigate(['/docs/', this.doc_group_id]);
    let obj = this.data.filter(e => {
      return e.id == this.doc_group_id;
    })[0];
    this.doc_group_name = obj.name;
  }

  deleteFolder() {
    this.docGroupService.delete(this.doc_group_id).subscribe(data => {
      this.doc_group_id = null;
      this.load();
    });
  }
}
