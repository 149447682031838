<modal-timeclock-edit
  (onComplete)="load()"
  #modalEditTimeclock></modal-timeclock-edit>
<form ngNativeValidate (submit)="load()">
  <div class="row d-print-none">
    <div class="col-md-6 col-12">
      <div class="card">
        <div class="card-body">
          <label>Start Date</label>
          <input
            type="text"
            class="form-control input-calendar"
            bsDatepicker
            [(ngModel)]="search.start"
            name="other.start_date"
            required />
          <label>End Date</label>
          <input
            type="text"
            class="form-control input-calendar"
            bsDatepicker
            [(ngModel)]="search.end"
            name="other.end_date"
            required />
        </div>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="card">
        <div class="card-body">
          <label>Employee</label>
          <select
            name="user_id"
            [ngModel]="search.user_id ||''"
            class="form-control d-inline-block"
            (ngModelChange)="search.user_id = $event">
            <option *ngFor="let u of users | orderBy:'lastname'" [value]="u.id">
              {{u.firstname}} {{u.lastname}}
            </option>
          </select>
          <label *ngIf="loc"
            >Pay Period Ends On {{ DaysOfWeek[loc.payroll_day]}}</label
          >
          Payroll Day is set
          <a routerLink="/settings/dashboard">here.</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3 d-print-none">
    <div class="col-12">
      <button
        class="btn btn-secondary float-left mr-2"
        [class.btn-primary]="!showDetailed"
        (click)="setDetailed(false)">
        Run Summary
      </button>
      <button
        class="btn btn-secondary float-left"
        (click)="setDetailed(true)"
        [class.btn-primary]="showDetailed">
        Run Detailed
      </button>
      <button
        class="btn btn-secondary float-right"
        type="button"
        (click)="print()">
        Print
      </button>
    </div>
  </div>
</form>
<div *ngIf="done">
  <div
    class="row text-white d-print-none"
    *ngIf="isAllowed(Permission.ViewSales)">
    <div class="col-md-4 col-xs-12">
      <ul class="list-group">
        <li class="list-group-item blue-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">Scheduled Hours</h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">
                {{scheduled_minutes+scheduled_ot_minutes | bliMinutes}}
              </h3>
            </div>
          </div>
        </li>
        <li class="list-group-item grey-dark-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">
              Hours Reg / <span class="text-danger">OT </span>
            </h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">
                {{timeclock_minutes | bliMinutes}} /
                <span class="text-danger">
                  {{timeclock_ot_minutes | bliMinutes}}
                </span>
              </h3>
            </div>
          </div>
        </li>
        <li class="list-group-item grey-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">Total Hours</h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">
                {{ timeclock_minutes + timeclock_ot_minutes | bliMinutes}}
              </h3>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="d-flex flex-row">
            <div class="p-0 m-0 text-secondary">
              Salaried employees who do not clock in will not show up here.
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-md-4 col-xs-12" *ngIf="isAllowed(Permission.ViewSales)">
      <ul class="list-group">
        <li class="list-group-item blue-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">Net Wages</h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">{{ actual_cost | currency: 'USD'}}</h3>
            </div>
          </div>
        </li>
        <li class="list-group-item grey-dark-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">Est. Payroll Costs</h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">
                {{ actual_cost*(payrollPercent-1) | currency: 'USD'}}
              </h3>
            </div>
          </div>
        </li>
        <li class="list-group-item grey-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">Est. Gross Pay</h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">
                {{ actual_cost*payrollPercent | currency: 'USD'}}
              </h3>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="d-flex flex-row">
            <div class="p-0 m-0 text-secondary">
              Adjust your est additional payroll expense
              <a routerLink="/settings/goals"> here.</a>
              Current: {{getLocation().payroll_goal}}%
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-4 col-xs-12" *ngIf="isAllowed(Permission.ViewSales)">
      <ul class="list-group">
        <li class="list-group-item blue-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">Total Sales</h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">{{ total_sales | currency: 'USD'}}</h3>
            </div>
          </div>
        </li>
        <li class="list-group-item grey-dark-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">Scheduled Cost</h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">{{scheduled_cost | currency: 'USD'}}</h3>
            </div>
          </div>
        </li>
        <li class="list-group-item grey-bg">
          <div class="d-flex flex-row">
            <h4 class="p-0 m-0">Est. Gross Labor %</h4>
            <div class="ml-auto">
              <h3 class="p-0 m-0">
                {{ (total_sales>0?scheduled_cost/total_sales:'0') | percent }}
              </h3>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card d-none d-print-block">
    <div class="card-body">
      <h2>Payroll for {{getLocation().name}}</h2>
      {{search.start | date: 'shortDate' }} - {{search.end| date: 'shortDate' }}
      <img
        class="float-right"
        *ngIf="loc.has_image"
        [src]="getLocationImage(loc.has_image)"
        width="120"
        height="120" />
    </div>
  </div>
  <div class="card" d-print-none>
    <div class="card-body">
      <label>TOTAL TIPS REPORTED: {{ total_tips | currency: 'USD' }}</label>
    </div>
  </div>

  <!-- Put a loading spinner in here -->
  <div class="card" *ngIf="!showDetailed && data">
    <div class="card-body">
      <div class="d-none d-sm-block">
        <div class="row body-bg my-2 mx-0 border text-md-right">
          <div class="col-md-3 col-xs-12 text-left">
            <strong>Employee </strong>
          </div>
          <div class="col">
            <strong>Reg Hours</strong>
          </div>
          <div class="col">
            <strong>Reg Wages</strong>
          </div>
          <div class="col">
            <strong>OT Hours</strong>
          </div>
          <div class="col">
            <strong>OT Wages</strong>
          </div>
          <div class="col">
            <strong>Tips</strong>
          </div>
          <div class="col">
            <strong>Total Hours</strong>
          </div>
          <div class="col">
            <strong>Net Wages</strong>
          </div>
          <div class="col">
            <strong>Est Gross Pay</strong>
          </div>
        </div>
      </div>
      <div
        class="row body-bg my-3 mx-0 border text-md-right"
        *ngFor="let u of summary | filterBy: [ 'id', search.user_id ] | orderBy: ['lastname','firstname']">
        <div class="col-md-3 col-xs-12 text-left">
          <img
            class="rounded float-left mb-0"
            style="margin-right: 1.5rem"
            [src]="getUserProfileImage(u.has_image) | safeUrl"
            height="40px" />
          {{u.firstname}} {{u.lastname}}
        </div>
        <div class="col">
          <span class="d-block d-sm-none">
            <strong>Reg&nbsp;Hours</strong></span
          >
          {{u.minutes | bliMinutes}}
        </div>
        <div class="col">
          <span class="d-block d-sm-none">
            <strong>Reg&nbsp;Wages</strong></span
          >
          {{u.cost| currency: 'USD' }}
        </div>
        <div class="col">
          <span class="d-block d-sm-none"> <strong>OT&nbsp;Hours</strong></span>
          {{ u.ot_minutes | bliMinutes }}
        </div>
        <div class="col">
          <span class="d-block d-sm-none"> <strong>OT&nbsp;Wages</strong></span>
          {{ u.ot_cost | currency: 'USD' }}
        </div>
        <div class="col">
          <span class="d-block d-sm-none"> <strong>Tips</strong></span>
          {{ u.tips | currency: 'USD' }}
        </div>
        <div class="col">
          <span class="d-block d-sm-none">
            <strong>Total&nbsp;Hours</strong></span
          >
          {{ (u.minutes + u.ot_minutes) | bliMinutes}}
        </div>
        <div class="col">
          <span class="d-block d-sm-none">
            <strong>Net&nbsp;Wages</strong></span
          >
          {{ (u.cost + u.ot_cost ) | currency: 'USD' }}
        </div>
        <div class="col">
          <span class="d-block d-sm-none">
            <strong>Est&nbsp;Gross&nbsp;Pay</strong></span
          >
          {{ (u.cost+u.ot_cost)*payrollPercent | currency: 'USD' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showDetailed">
    <div
      class="card"
      *ngFor="let u of summary | filterBy: [ 'id', search.user_id ] | orderBy: ['lastname','firstname']">
      <div class="card-body p-3">
        <div class="row mb-2">
          <div class="col-12 text-left">
            <img
              class="rounded float-left mb-0"
              style="margin-right: 1.5rem"
              [src]="getUserProfileImage(u.has_image) | safeUrl"
              height="40px" />
            <h4><b>{{u.firstname}} {{u.lastname}}</b></h4>
          </div>
        </div>
        <ng-template ngFor let-week [ngForOf]="u.weekMap">
          <div *ngFor="let t of week.timeclocks | orderBy:'start'">
            <div class="row" style="line-height: 1">
              <div class="col-md-3 col-12 align-self-center">
                {{ t.start | date: 'EEE, MMM d' }}
              </div>
              <div class="col-md-3 col-12 align-self-center">
                {{(t.salary_name?t.salary_name:t.name)}}:
                <span *ngIf="!t.is_salary"> {{t.rate | currency}}/hour</span>
                <span *ngIf="t.is_salary">
                  {{t.salary_rate | currency}}/year</span
                >
              </div>
              <div class="col-md-6 pt-3">
                Time Clock {{t.start | date:'shortTime'}}
                <span *ngIf="t.end">- {{t.end | date:'shortTime'}}</span>
                <span class="text-dark" *ngIf="!t.end">Clocked In</span>
                ({{ t.minutes +t.ot_minutes | bliMinutes }} hrs)
                <span
                  class="float-right"
                  *ngIf="isAllowed(Permission.EditTimeCard)">
                  <span
                    class="cdi-s-edit cdi-smaller align-middle pointer py-0"
                    (click)="modalEditTimeclock.show(t)"></span>
                  <delete-modal
                    name="timeclock"
                    [deleteObject]="t"
                    (onDelete)="delete(t)">
                    <span
                      class="cdi-s-trash cdi-smaller align-middle pointer py-0"></span>
                  </delete-modal>
                </span>
              </div>
            </div>
            <div class="row border-bottom" style="line-height: 1">
              <div class="col-md-4 col-12"></div>
              <div class="col">
                Reg: {{t.minutes | bliMinutes }}&nbsp;
                <span *ngIf="t.ot_minutes" class="text-danger"
                  >OT {{t.ot_minutes| bliMinutes}}</span
                >
              </div>
              <div class="col">
                <span class="float-right pr-5 mr-5"
                  >{{ t.minutes +t.ot_minutes | bliMinutes }}
                </span>
              </div>
              <div class="col">
                <span class="float-right pr-5 mr-5"
                  >{{t.tips | currency: 'USD'}} tips
                </span>
              </div>
              <div class="col mb-2">
                <span class="float-right pr-5 mr-5">
                  <span *ngIf="t.is_salary"> $0.00</span>
                  <span *ngIf="!t.is_salary">
                    <span *ngIf="!t.ot_cost">
                      {{t.cost | currency: 'USD' }}
                    </span>
                    <span *ngIf="t.ot_cost">
                      {{t.cost | currency: 'USD' }}
                      <span class="text-danger"
                        >{{ t.ot_cost | currency: 'USD' }}</span
                      >
                      {{t.cost+t.ot_cost | currency: 'USD' }}
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="row py-2 bg-light border">
            <div class="col-md-4 col-12">
              <span class="d-block">
                <strong
                  >Total for Week&nbsp;ending {{ week.date | date: 'EEE, MMM
                  d'}}
                </strong>
              </span>
            </div>

            <div class="col">
              Reg: {{week.minutes | bliMinutes }}&nbsp;
              <span *ngIf="week.ot_minutes" class="text-danger"
                >OT {{week.ot_minutes| bliMinutes}}</span
              >
            </div>
            <div class="col">
              <span class="float-right pr-5 mr-5"
                >{{week.minutes + week.ot_minutes| bliMinutes}}</span
              >
            </div>
            <div class="col">
              <span class="float-right pr-5 mr-5"
                >{{week.tips| currency:'USD'}} tips</span
              >
            </div>
            <div class="col">
              <span class="float-right pr-5 mr-5"
                >{{week.cost+week.ot_cost | currency:'USD'}}</span
              >
            </div>
          </div>
        </ng-template>

        <div class="row py-2 orange-thin-bg border" style="font-weight: bolder">
          <div class="col-md-4 col-12 py-0" style="font-size: 1.2rem">
            Total for {{ u.firstname }} {{ u.lastname}}
          </div>
          <div class="col">
            Reg: {{u.minutes | bliMinutes }}&nbsp;
            <span *ngIf="u.ot_minutes" class="text-danger"
              >OT {{u.ot_minutes| bliMinutes}}</span
            >
          </div>
          <div class="col">
            <span class="float-right pr-5 mr-5"
              >{{u.minutes + u.ot_minutes| bliMinutes}}</span
            >
          </div>
          <div class="col">
            <span class="float-right pr-5 mr-5"
              >{{u.tips| currency:'USD'}} tips</span
            >
          </div>
          <div class="col">
            <span class="float-right pr-5 mr-5"
              >{{u.cost +u.ot_cost | currency:'USD'}}</span
            >
          </div>
        </div>
        <!--<span class='mt-5'>  This is a salaried employee..</span>-->
      </div>
    </div>
  </div>
</div>
