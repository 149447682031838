import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { DocService } from '../services/doc.service';
import {
  RateType,
  UserType,
  PositionType,
  getUserProfileImage,
} from '../shared/api';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';
import {
  PermissionUserService,
  IPermissionUser,
} from '../services/permissionUser.service';
import { baseRole } from '../shared/baseRole';
import {
  EmployeePositionService,
  IEmployeePosition,
} from '../services/employeePosition.service';
import { LocationService, ILocation } from '../services/location.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

// Lets you use moment to do things.
import * as moment_ from 'moment';
var moment: any = (<any>moment_).default || moment_;

@Component({
  templateUrl: './manage.html',
  animations: [
    trigger('flipState', [
      state(
        'active',
        style({
          transform: 'rotateY(179deg)',
        })
      ),
      state(
        'inactive',
        style({
          transform: 'rotateY(0)',
        })
      ),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in')),
    ]),
  ],
})
export class ManageComponent extends baseRole implements OnInit {
  public _allUsers: any[];
  public data: any[];
  public RateType = RateType;
  public loc: IApiLocation;
  public showHidden = false;
  public filterObject: any;
  public userMap: ILocationUserMap;
  public isFiltered = false;

  public viewDocUser: any;
  public additionalPositionsUser: any;

  public hideUser: any;

  @ViewChild('hideModal')
  public modal: ModalDirective;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected employeePositionService: EmployeePositionService,
    protected accountService: AccountService,
    protected locationService: LocationService,
    protected docService: DocService,
    protected locationUserMapService: LocationUserMapService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.showHidden = this.accountService.getTempState(
      'user_manage_hidden',
      false
    );
    let now = new Date();
    this.userService
      .listWithPositions(now, null, { hide: null })
      .subscribe(data => {
        this._allUsers = data;
        let now = moment();
        for (let u of this._allUsers) {
          //let end = moment(u.dob);
          //let duration= moment.duration(now.diff(end));
          //u.age = duration._data.years;
          u.quiz_count = 0;
          u.quiz_complete = 0;
          u.doc_count = 0;
          u.doc_signed = 0;
          u.docs = [];
          for (let p of u.positions) {
            if (p.position_type == PositionType.FrontOfHouse) {
              u.foh = true;
            } else if (p.position_type == PositionType.BackOfHouse) {
              u.boh = true;
            }
          }
        }

        for (let u of this._allUsers) {
          u.primaryPosition = u.positions.filter(e => {
            return e.is_primary;
          });
          if (u.primaryPosition.length > 0) {
            u.primaryPosition = u.primaryPosition[0];
          }
          u.additionalPosition = u.positions.filter(e => {
            return !e.is_primary;
          });
          u.hasSalary = u.positions.some(e => {
            return e.rate_type == RateType.Salary;
          });
        }

        this.docService.listForUser(null).subscribe(docInfo => {
          for (let d of docInfo) {
            let match = this._allUsers.filter(e => {
              return e.id == d.user_id;
            });
            //			console.log('Match');
            //			console.log(match);
            if (match.length == 1) {
              match[0].docs.push(d);
              if (d.signature) match[0].doc_count++;
              if (d.quizReady) match[0].quiz_count++;
              if (d.signature_id) match[0].doc_signed++;
              if (d.quiz_passed_date) match[0].quiz_complete++;
            }
          }
        });
        this.filter();
      });
    this.loc = this.accountService.getLocation();
  }

  getUserProfileImage(
    has_image: string,
    firstName?: string,
    lastName?: string,
    color?: string
  ): string {
    return getUserProfileImage(has_image, firstName, lastName, color);
  }

  filter(obj: any = null) {
    if (!this._allUsers) return;

    this.filterObject = this.accountService.getTempState('user_manage_filter', {
      user_type: [],
      position_ids: {},
      foh: null,
      order_by: 'lastname',
    });
    if (
      this.filterObject.user_type[UserType.Regular] ||
      this.filterObject.user_type[UserType.ShiftLead] ||
      this.filterObject.user_type[UserType.Manager] ||
      this.filterObject.foh !== null ||
      Object.keys(this.filterObject.position_ids).length > 0
    ) {
      this.isFiltered = true;
    } else {
      this.isFiltered = false;
    }

    this.data = this._allUsers.filter(e => {
      if (!this.showHidden && e.hide) return false;
      if (!this.isFiltered) return true; // no filter

      if (
        this.filterObject.user_type[UserType.Regular] ||
        this.filterObject.user_type[UserType.ShiftLead] ||
        this.filterObject.user_type[UserType.Manager]
      ) {
        if (!this.filterObject.user_type[e.user_type]) return false;
      }

      if (this.filterObject.foh !== null) {
        if (this.filterObject.foh == true && !e.foh) return false;
        else if (this.filterObject.foh == false && !e.boh) return false;
      }
      //		console.log(e);
      //acceptance check, rest are out
      if (Object.keys(this.filterObject.position_ids).length > 0) {
        for (let ep of e.positions) {
          if (ep.id in this.filterObject.position_ids) return true;
        }
        return false;
      }
      return true;
    });
    // For each data item, add a card flip 'inactive' state
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].flipState = 'inactive';
    }
  }

  toggleHidden() {
    this.showHidden = !this.showHidden;
    this.accountService.setTempState('user_manage_hidden', this.showHidden);
    this.filter();
  }

  toggleUser(user) {
    this.locationUserMapService
      .update({ id: user.location_user_map_id, hide: !user.hide })
      .subscribe(() => {
        user.hide = !user.hide;
        this.filter();
      });
  }

  toggleFlipCard(id: number) {
    const flipState = this.data[id].flipState;
    if (flipState === 'inactive') {
      this.data[id].flipState = 'active';
    } else {
      this.data[id].flipState = 'inactive';
    }
  }

  print() {
    let link = this.userService.getPdfStaffUrl({
      location_id: this.getLocationId(),
    });
    window.open(link, '_blank');
  }

  doHideUser() {
    this.toggleUser(this.hideUser);
    this.modal.hide();
  }

  showHideModal(user) {
    this.hideUser = user;
    this.modal.show();
  }
  cancelModal() {
    this.modal.hide();
    this.hideUser = null;
  }

  sendResetPassword(user) {
    this.accountService.forgotPassword(user.email).subscribe(() => {
      this.doEmailSent(user);
    });
  }

  sendVerification(user) {
    this.userService.sendUnverified([user.id]).subscribe(() => {
      this.doEmailSent(user);
    });
  }

  doEmailSent(user) {
    user.emailSent = true;
    setTimeout(() => {
      delete user.emailSent;
    }, 5000);
  }
}
