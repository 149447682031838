import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import {
  AccountService,
  IUser,
  IApiLocation,
} from '../services/account.service';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';
import { ReviewService, IReview } from '../services/review.service';
import { SignatureService, ISignature } from '../services/signature.service';
import { WriteUpService, IWriteUp } from '../services/writeUp.service';
import { WidgetType, WidgetNames } from '../shared/api';

@Component({
  selector: 'writeup-sign',
  templateUrl: './writeupSign.html',
})
export class WriteupSignComponent {
  @Input() showClose = true;
  @Input() perms;
  @Output() onClose = new EventEmitter<boolean>();
  // Signature Bits
  @ViewChild('signature') signature;
  @ViewChild('modalDocSign') modal;
  public checked = false;
  public hasDrawn = false;
  public sig: any;
  public writeup;

  constructor(
    protected accountService: AccountService,
    public writeUpService: WriteUpService,
    public signatureService: SignatureService
  ) {}

  show(writeup) {
    this.writeup = writeup;
    this.sig = {
      write_up_id: writeup.id,
      perms: this.perms,
      user_id: this.perms
        ? this.perms.user_id
        : this.accountService.getUserId(),
      name: null,
      svg: null,
    };
    this.modal.show();
  }

  saveSig() {
    let data = this.signature.getSvg();
    let idx = data.indexOf(',');
    this.sig.svg = atob(data.substring(idx + 1));
    this.signatureService.update(this.sig).subscribe(data => {
      this.writeup.signature_id = data.id;
      if (this.showClose) {
        this.accountService.setLocation().subscribe(data => {
          // We might want to something if the jail has ended here.
        });
      } else {
        this.onClose.emit(true);
      }
      this.modal.hide();
    });
  }
  cancelSig() {
    this.modal.hide();
  }
}
