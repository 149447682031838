import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  NotificationService,
  NotificationType,
  NotificationTypeDataHints,
} from '../services/notification.service';

@Component({
  templateUrl: './notification.list.html',
})
export class NotificationListComponent implements OnInit {
  public data;
  public NotificationType = NotificationType;

  constructor(
    protected router: Router,
    protected notificationService: NotificationService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.notificationService.list().subscribe(data => {
      this.data = data;
    });
  }

  delete(d) {
    this.notificationService.delete(d.id).subscribe(() => {
      let idx = this.data.indexOf(d);
      this.data.splice(idx, 1);
    });
  }

  changeStatus(d, stat) {
    let updateObj = {
      id: d.id,
      is_active: stat,
    };

    this.notificationService.update(updateObj).subscribe(() => {
      d.is_active = stat;
    });
  }
}

@Component({
  templateUrl: './notification.edit.html',
})
export class NotificationEditComponent implements OnInit {
  public NotificationTypeDataHints = NotificationTypeDataHints;
  public NotificationType = NotificationType;
  public NotificationTypeKeys = Object.keys(NotificationType).filter(Number);
  public data;
  public user;
  public testSend = null;
  public testError: string;
  public testDataString: string = '{}';
  public error: any = {};
  public predefined = {
    baseUrl: 'string',
    user: {
      id: 'number',
      firstname: 'string',
      lastname: 'string',
      username: 'string',
      email: 'string',
      phone: 'string',
    },
    location: {
      id: 'number',
      name: 'string',
      iso: 'string',
    },
  };

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected notificationService: NotificationService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.user = this.accountService.getUser();
    this.route.params.subscribe(params => {
      let id = params['id'];
      if (id) {
        this.notificationService.get(id).subscribe(data => {
          this.data = data;
          this.typeChange();
        });
      } else {
        this.data = {
          to: '{{user.email}}',
          notification_type: NotificationType.PasswordReset,
          is_active: false,
        };
        this.typeChange();
      }
    });
  }

  typeChange() {
    this.testDataString = JSON.stringify(
      NotificationTypeDataHints[this.data.notification_type],
      null,
      2
    );
  }

  save() {
    this.notificationService.update(this.data).subscribe(
      () => {
        this.router.navigate(['/admin/notification']);
      },
      err => {
        console.log(err);
        this.error = {};
        let props = err.__properties;
        for (let field in props) {
          this.error[field] = '';
          for (let e of props[field]) {
            this.error[field] += e;
          }
        }
      }
    );
  }

  sendTest() {
    let testData: any = null;
    this.testError = null;

    try {
      testData = JSON.parse(this.testDataString);
    } catch (err) {
      this.testError = err.toString();
      return;
    }
    this.testSend = 'Sending';
    this.notificationService.update(this.data).subscribe(r => {
      this.data.id = r.id;
      this.notificationService
        .sendTest(this.data.id, this.data.notification_type, testData)
        .subscribe(() => {
          this.testSend = null;
        });
    });
  }
}
