<modal-edit-checklist #modalEditChecklist></modal-edit-checklist>
<div *ngIf="loc">
  <div class="card">
    <div class="card-body">
      <label
        >Checklists
        <button
          routerLink="/settings/dashboard/checklist/add"
          class="btn btn-primary float-right"
          type="button">
          Add Checklist
        </button>
      </label>
      <div class="card" *ngIf="checklists && checklists.length>0">
        <div class="card-body">
          <ng-container
            *ngFor="let d of checklists | orderBy: ['start', 'name']">
            <div *ngIf="!d._edit">
              <div class="d-flex flex-row body-bg pointer">
                <span [routerLink]="['/settings/dashboard/checklist',d.id]"
                  >{{d.name }} {{d.start | bliTime}} - {{d.end | bliTime}}</span
                >
                <span
                  class="cdi-s-edit ml-auto pointer"
                  [routerLink]="['/settings/dashboard/checklist',d.id]"></span>
                <delete-modal
                  [name]="'Check list '+d.name+' and all associated data'"
                  [deleteObject]="d"
                  (onDelete)="deleteChecklist(d)">
                  <span class="cdi-s-trash pointer"></span>
                </delete-modal>
              </div>
            </div>

            <div class="row">
              <div class="flex-7 p-1">
                <input-toggle [(ngModel)]="d.days[0]" [disabled]="!d._edit"
                  ><small>Sun</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle [(ngModel)]="d.days[1]" [disabled]="!d._edit"
                  ><small>Mon</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle [(ngModel)]="d.days[2]" [disabled]="!d._edit"
                  ><small>Tue</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle [(ngModel)]="d.days[3]" [disabled]="!d._edit"
                  ><small>Wed</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle [(ngModel)]="d.days[4]" [disabled]="!d._edit"
                  ><small>Thu</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle [(ngModel)]="d.days[5]" [disabled]="!d._edit"
                  ><small>Fri</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle [(ngModel)]="d.days[6]" [disabled]="!d._edit"
                  ><small>Sat</small></input-toggle
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Dark Days</label>
      <div class="row">
        <div class="flex-7 p-1">
          <input-toggle
            [(ngModel)]="loc.dark_days[DaysOfWeek.Sunday]"
            (ngModelChange)="saveLocationField('dark_days')"
            ><small>Sun</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle
            [(ngModel)]="loc.dark_days[DaysOfWeek.Monday]"
            (ngModelChange)="saveLocationField('dark_days')"
            ><small>Mon</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle
            [(ngModel)]="loc.dark_days[DaysOfWeek.Tuesday]"
            (ngModelChange)="saveLocationField('dark_days')"
            ><small>Tue</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle
            [(ngModel)]="loc.dark_days[DaysOfWeek.Wednesday]"
            (ngModelChange)="saveLocationField('dark_days')"
            ><small>Wed</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle
            [(ngModel)]="loc.dark_days[DaysOfWeek.Thursday]"
            (ngModelChange)="saveLocationField('dark_days')"
            ><small>Thu</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle
            [(ngModel)]="loc.dark_days[DaysOfWeek.Friday]"
            (ngModelChange)="saveLocationField('dark_days')"
            ><small>Fri</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle
            [(ngModel)]="loc.dark_days[DaysOfWeek.Saturday]"
            (ngModelChange)="saveLocationField('dark_days')"
            ><small>Sat</small></input-toggle
          >
        </div>
        <div class="my-2">
          Select the days you are usually closed. You can still record sales on
          these days for special events but CheddrSuite won't alert you that a
          log has been skipped if you don't record sales.
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Day to Start the Week</label>
      <select
        [(ngModel)]="loc.week_start"
        class="form-control"
        style="width: 30%"
        (ngModelChange)="saveLocationField('week_start')">
        <option *ngFor="let d of DaysOfWeekKeys" [value]="d">
          {{DaysOfWeek[d]}}
        </option>
      </select>
      <div class="my-2">
        Changing your week starting day may make your current published schedule
        have days without shifts and the current week may not have sales goals
        set.
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Payroll Start Day</label>
      <select
        [(ngModel)]="loc.payroll_day"
        class="form-control"
        style="width: 30%"
        (ngModelChange)="saveLocationField('payroll_day')">
        <option *ngFor="let d of DaysOfWeekKeys" [value]="d">
          {{DaysOfWeek[d]}}
        </option>
      </select>
      <div class="my-2">
        If Payroll Day differs from Start of Week, there may be overtime in pay
        that is not obvious. <br />
        Changing this day will affect all labor related dollar values including
        the payroll report.
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Module Settings</label>
      <div class="row" style="font-size: 10px">
        <!--<div class=' col-md-3 col-sm-12 mb-2'>
					<input-toggle [(ngModel)]="loc.has_inventory" (ngModelChange)="saveLocationField('has_inventory')">Inventory</input-toggle></div>-->
        <!--<div class=' col-md-3 col-sm-12 mb-2'>
					<input-toggle [(ngModel)]="has_budgets" (ngModelChange)="saveLocationField('has_budgets')">Budgets &amp; Sales Goals</input-toggle></div>-->
        <div class="col-md-3 col-sm-12 mb-2">
          <input-toggle
            [(ngModel)]="loc.has_finances"
            (ngModelChange)="saveLocationField('has_finances')"
            >Financial Reporting</input-toggle
          >
        </div>
        <div class="col-md-3 col-sm-12 mb-2">
          <input-toggle
            [(ngModel)]="loc.has_tip_reporting"
            (ngModelChange)="saveLocationField('has_tip_reporting')"
            >Tip Reporting</input-toggle
          >
        </div>
        <div class="col-md-3 col-sm-12 mb-2">
          <input-toggle
            [(ngModel)]="loc.has_houses"
            (ngModelChange)="saveLocationField('has_houses')"
            >Use FOH &amp; BOH</input-toggle
          >
        </div>
        <div class="col-md-3 col-sm-12 mb-2" *ngIf="loc.has_houses">
          <input-toggle
            [(ngModel)]="loc.segment_houses"
            (ngModelChange)="saveLocationField('segment_houses')"
            >Segment FOH &amp; BOH</input-toggle
          >
        </div>
      </div>
      <div class="my-2">
        <ul>
          <li>
            Use FOH &amp; BOH to track labor separately using Front-Of-House and
            Back-Of-House, versus your restaurant as a whole.
          </li>
          <li>
            Segmenting FOH and BOH separates schedules, staff lists, and
            messaging. FOH will not be able to see BOH and vice versa.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
