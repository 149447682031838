<modal-message-reply #modalReply></modal-message-reply>

<div class="card">
  <div class="card-body">
    <!-- Header for medium and larger screens -->
    <div class="d-none d-md-block">
      <div class="row row-table row-header align-items-center">
        <div class="col-md-3">Sent To</div>
        <div class="col-md-3">Title</div>
        <div class="col-md-3">From</div>
        <div class="col-md-3">Date</div>
      </div>
    </div>

    <!-- Message rows -->
    <div class="row-table row-striped align-items-center" *ngIf="messages">
      <div
        class="row pointer py-1"
        *ngFor="let message of messages"
        (click)="modalReply.show(message)">
        <!-- Sent To -->
        <div class="col-md-3 col-12 my-1">
          <span class="d-block d-md-none text-primary">Sent To: </span>
          <div class="d-flex">
            <ng-container *ngFor="let r of message.recipients; let i = index">
              <!-- Display the first three names -->
              <ng-template [ngIf]="i < 3">
                <div>
                  <span>{{ r.firstname}}</span>
                  <span *ngIf="i !== 3 && i !== message.recipients.length - 1"
                    >,&nbsp;
                  </span>
                </div>
              </ng-template>
            </ng-container>

            <!-- Display the number of remaining names if there are more than three -->
            <span *ngIf="message.recipients.length > 3">
              and {{ message.recipients.length - 3 }} more
            </span>
          </div>
        </div>

        <!-- Title -->
        <div class="col-md-3 col-12 my-1">
          <span class="d-block d-md-none text-primary">Title:</span>
          {{message.title}}
        </div>

        <!-- From -->
        <div class="col-md-3 col-6 my-1">
          <span class="d-block d-md-none text-primary">From: </span
          >{{message.firstname}} {{ message.lastname}}
        </div>

        <!-- Date -->
        <div class="col-md-3 col-6 my-1">
          <span class="d-block d-md-none text-primary">Date: </span
          >{{message.created_at | bliDate }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="numSent>maxPerPage">
  <div class="col-md-12" style="text-align: center">
    <pagination
      [totalItems]="numSent"
      [maxSize]="10"
      (pageChanged)="pageChanged($event)"
      [itemsPerPage]="maxPerPage"
      class="pagination-sm"
      [boundaryLinks]="true"
      [rotate]="false"></pagination>
  </div>
</div>
