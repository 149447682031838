import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IIntegrationSales, moment } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class IntegrationSalesService extends BaseService<IIntegrationSales> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'integrationSales');
  }

  list(filter?: any): Observable<any[]> {
    filter = Object.assign({}, filter);
    console.log(filter);
    if (filter) {
      if (filter.start) filter.start = filter.start.toISOString();
      if (filter.end) filter.end = filter.end.toISOString();
    }
    return super.list(filter);
  }
}

export { IIntegrationSales } from '../shared/api';
