<!--<doc-quiz-view #docQuizView (onClose)="doQuizClose()" [perms]="perms" [showClose]="false"></doc-quiz-view>-->
<writeup-sign
  #writeupSign
  (onClose)="doWriteupSign()"
  [showClose]="false"
  [perms]="perms"></writeup-sign>

<div class="row authentication">
  <div class="col-xl-4 col-lg-5 col-md-6 col-11 text-center card">
    <div class="card-body">
      <div style="width: 100%" class="mt-4 mb-3">
        <span class="cdi-l-logo mx-auto cdi-biggest"></span>
      </div>

      <form ngNativeValidate *ngIf="step==1" (submit)="save()">
        <div class="font-weight-bold mb-3">
          Online time clock for: {{ timeclock.name }}<br />
          Clock in using your CheddrSuite email and password.
        </div>

        <select name="user_id" [(ngModel)]="perms.user_id" class="form-control">
          <option *ngFor="let u of users" [value]="u.id">{{u.name}}</option>
        </select>
        <input
          class="form-control"
          type="password"
          name="password"
          placeholder="Password"
          [(ngModel)]="perms.password" />
        <div class="font-weight-bold mt-4 mb-3">
          Or clock in using your CheddrSuite PIN
        </div>
        <input
          class="form-control"
          type="password"
          name="pin"
          placeholder="PIN #"
          [(ngModel)]="perms.pin" />

        <h3>
          <div class="row my-3">
            <div class="col-4" (click)="pinInput(1)">1</div>
            <div class="col-4" (click)="pinInput(2)">2</div>
            <div class="col-4" (click)="pinInput(3)">3</div>
          </div>
          <div class="row my-3">
            <div class="col-4" (click)="pinInput(4)">4</div>
            <div class="col-4" (click)="pinInput(5)">5</div>
            <div class="col-4" (click)="pinInput(6)">6</div>
          </div>
          <div class="row my-3">
            <div class="col-4" (click)="pinInput(7)">7</div>
            <div class="col-4" (click)="pinInput(8)">8</div>
            <div class="col-4" (click)="pinInput(9)">9</div>
          </div>
          <div class="row my-3">
            <div class="col-4" (click)="pinBack()">Back</div>
            <div class="col-4" (click)="pinInput(0)">0</div>
            <div class="col-4" (click)="pinReset()">Clear</div>
          </div>
        </h3>

        <button class="btn btn-primary w-100 my-4" type="submit">
          <span>Clock In/Clock Out</span>
        </button>
        <local-error [error]="error" class="mt-3"></local-error>
        <br />
        <p class="text-center mt-2">
          <a routerLink="/forgotPassword">Forgot Password?</a> /
          <a (click)="deAuthorize()">Deauthorize</a> /
          <a [routerLink]="['/login', { login: true} ]">Main Cheddr Login</a>
        </p>
      </form>

      <form (submit)="managerSave()" ngNativeValidate *ngIf="step==2">
        <p>
          <span class="mb-2">
            Manager approval required for clock in not matching a shift.
          </span>
        </p>
        <label>Select position</label>
        <select
          name="employee_position_id"
          [(ngModel)]="info.employee_position_id"
          class="form-control"
          required>
          <option *ngFor="let p of this.info.employee_positions" [value]="p.id">
            {{p.name}}
          </option>
        </select>
        <input
          class="form-control"
          type="email"
          name="manager_email"
          placeholder="Manager Email"
          [(ngModel)]="info.approver_email" />
        <input
          class="form-control"
          type="password"
          name="manager_password"
          placeholder="Manager Password"
          [(ngModel)]="info.approver_password" />

        <button class="btn btn-primary w-100 my-3" type="submit">
          <span>Approve Clock In</span>
        </button>

        <button class="btn btn-secondary w-100" (click)="cancelApproval()">
          Cancel
        </button>

        <local-error [error]="manager_error" class="mt-3"></local-error>
      </form>

      <form (submit)="reportTips()" ngNativeValidate *ngIf="step==5">
        <p>Please enter the tips you have earned during your shift.</p>
        <input
          class="form-control"
          type="number"
          name="tips"
          step="0.01"
          min="0.00"
          placeholder="Tips"
          [(ngModel)]="tips" />
        <button class="btn btn-primary w-100 my-3" type="submit">
          <span>Continue</span>
        </button>
      </form>

      <alert type="success" *ngIf="step==3">
        <h5>{{ finalMessage }}</h5>
      </alert>
      <alert type="danger" *ngIf="step==4">
        <h5>{{ finalMessage }}</h5>
      </alert>

      <div class="row" *ngIf="step==6">
        <div class="col" *ngFor="let d of info.quizes">
          <div class="card my-1" style="width: 12.8rem">
            <div class="card-body">
              <doc-view
                [doc]="d"
                [perms]="perms"
                (onComplete)="doQuizClose(d)"
                [canEdit]="false"></doc-view>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="step==7">
        <div class="row">
          <div class="col-12 text-center">
            {{info.messages.length}} message(s) to read
          </div>
        </div>
        <div class="row" *ngFor="let d of info.messages">
          <div class="col-12 text-left">
            <b>From:</b> {{d.firstname}} {{d.lastname}}
          </div>
          <div class="col-12 text-left"><b>Subject:</b> {{d.title}}</div>
          <div class="col-12 text-left">
            <b>Date:</b> {{d.created_at |bliDate}}
          </div>

          <div class="col-12 text-left">
            <b>Message:</b> {{d.body}}<br /><br />
          </div>

          <div class="col-12">
            <button
              type="button"
              class="btn btn-primary"
              (click)="doMarkRead(d)">
              Mark Read
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
