import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { map } from 'rxjs/operators';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import {
  IPaginator,
  getUserProfileImage,
  TradeType,
  TradeTypeNames,
} from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { ShiftTradeService, IShiftTrade } from '../services/shiftTrade.service';

@Component({
  templateUrl: './trade.html',
})
export class TradeComponent extends baseRole implements OnInit, IPaginator {
  public _data: any[];
  public data: any[];
  public filterObject: any = {
    trade_type: 0,
  };
  public orderByField: string;
  public TradeType = TradeType;
  // IPaginator
  public currentPage = 0;
  public maxPerPage = 20;
  public numPages = 0;
  public TradeTypeKeys = Object.keys(TradeType).filter(Number);
  public TradeTypeNames = TradeTypeNames;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected shiftTradeService: ShiftTradeService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.shiftTradeService
      .listAll()
      .pipe(
        map((data: any[]) => {
          for (let d of data) {
            this.shiftTradeService.fixDate(d, 'created_at');
            this.shiftTradeService.fixDate(d, 'start');
            this.shiftTradeService.fixDate(d, 'end');

            d.full_name = (d.firstname || '') + ' ' + (d.lastname || '');
            d.approver =
              (d.action_firstname || '') + ' ' + (d.action_lastname || '');
            d.hours = (d.end.getTime() - d.start.getTime()) / 3600000;
          }
          return data;
        })
      )
      .subscribe(data => {
        this._data = data;
        console.log(data);
        this.filter();
      });
  }

  filter() {
    if (!this._data) return;

    this.data = this._data.filter(e => {
      if (
        this.filterObject.name &&
        e.full_name.indexOf(this.filterObject.name) < 0
      )
        return false;

      if (
        this.filterObject.trade_type > 0 &&
        this.filterObject.trade_type != e.trade_type
      )
        return false;

      if (
        this.filterObject.date_start &&
        this.filterObject.date_start.getTime() > e.created_at.getTime()
      )
        return false;

      if (
        this.filterObject.date_end &&
        this.filterObject.date_end.getTime() < e.created_at.getTime()
      )
        return false;

      return true;
    });
  }

  pageChanges(event: any) {
    this.currentPage = event.page - 1;
  }
}
