<div *ngIf="user">
  <!--<main-header class="d-print-none"></main-header>-->
  <div
    id="leftside-menu"
    class="d-print-none"
    [class.visible]="!leftIsCollapsed">
    <div class="col px-0 d-flex flex-column" style="height: 100vh">
      <div class="logo" routerLink="/">
        <img src="../images/cheddrsuite-logo-long.png" alt="logo" />
      </div>
      <div class="mobile-logo" routerLink="/">
        <img src="../images/cheddrsuite-logo.png" alt="logo" />
      </div>
      <div
        class="text-center mb-2"
        style="color: #fff; height: auto; line-height: 1.5rem">
        <span class="d-md-none d-xl-block"
          >Welcome, {{ user.firstname }} {{ user.lastname }}<br
        /></span>
        <span *ngIf="loc">PIN {{ loc?.pin }}</span>
      </div>
      <div>
        <ul class="pointer" (click)="leftIsCollapsed = true">
          <li
            *ngIf="loc"
            routerLink="/"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <a>
              <icons name="Dashboard"></icons>
              <span>Dashboard</span>
            </a>
          </li>
          <li *ngIf="loc" routerLink="/log" routerLinkActive="active">
            <a>
              <icons name="Log"></icons>
              <span>Log</span>
            </a>
          </li>
          <li *ngIf="loc" routerLink="/calendar" routerLinkActive="active">
            <a>
              <icons name="Calendar"></icons>
              <span>Calendar</span>
            </a>
          </li>
          <li *ngIf="loc" routerLink="/schedule" routerLinkActive="active">
            <a>
              <icons name="Clock"></icons>
              <span>Schedule</span>
            </a>
          </li>
          <li
            *ngIf="
              isAllowed(Permission.ManageInventory) ||
              isAllowed(Permission.CountInventory)
            "
            routerLink="/inventory"
            routerLinkActive="active">
            <a>
              <icons name="Package"></icons>
              <span>Inventory</span>
            </a>
          </li>
          <li *ngIf="loc" routerLink="/messages" routerLinkActive="active">
            <a>
              <icons name="Message"></icons>
              <span>Messages</span>
            </a>
          </li>
          <li
            *ngIf="isAllowed(Permission.EditStaff)"
            routerLink="/users"
            routerLinkActive="active">
            <a>
              <icons name="Users"></icons>
              <span>Staff</span>
            </a>
          </li>
          <li
            *ngIf="isAllowed(Permission.ManageDoc)"
            routerLink="/docs"
            routerLinkActive="active">
            <a>
              <icons name="File"></icons>
              <span>Documents</span>
            </a>
          </li>
          <li
            *ngIf="isAllowed(Permission.ViewVendor)"
            routerLink="/contacts"
            routerLinkActive="active">
            <a>
              <icons name="Vendor"></icons>
              <span>Vendors</span>
            </a>
          </li>
          <li
            *ngIf="
              loc &&
              (isAllowed(Permission.ViewSales) ||
                isAllowed(Permission.EditTimeCard))
            "
            routerLink="/reports"
            routerLinkActive="active">
            <a>
              <icons name="Report"></icons>
              <span>Reports</span>
            </a>
          </li>
          <li
            *ngIf="
              (loc && loc.user_type == UserType.Owner) ||
              isAllowed(Permission.AccessSettings)
            "
            routerLink="/settings"
            routerLinkActive="active">
            <a>
              <icons name="Settings"></icons>
              <span>Settings</span>
            </a>
          </li>
          <li routerLink="/profile" routerLinkActive="active">
            <a>
              <icons name="User"></icons>
              <span>Profile</span>
            </a>
          </li>
        </ul>
      </div>
      <div
        *ngIf="
          (loc && loc.user_type == UserType.Owner) ||
          (loc && loc.user_type == UserType.Manager)
        "
        class="d-flex justify-content-center mt-auto mb-4">
        <div>
          <a class="btn btn-primary" [href]="'/financing'">
            <icons class="d-xl-none d-md-block d-none" name="Bank"></icons>
            <span class="d-xl-block d-md-none block">Need financing?</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <nav
    class="navbar navbar-expand-md"
    style="flex-wrap: nowrap !important"
    id="header-menu"
    [class.body-push-right]="!leftIsCollapsed"
    [class.body-push-left]="!rightIsCollapsed">
    <div
      class="navbar-toggler d-flex d-md-none cdi-l-burger nostate"
      (click)="leftIsCollapsed = !leftIsCollapsed"
      aria-expanded="false"
      aria-label="Toggle navigation"></div>
    <div
      class="ml-3 text-uppercase orange d-flex navbar-title"
      *ngIf="locations.length > 0">
      <h4
        *ngIf="locations.length == 1"
        class="float-left text-truncate"
        style="white-space: nowrap; width: 100%">
        {{ loc?.name }}
      </h4>
      <span *ngIf="locations.length > 1" class="bgn-group dropdown" dropdown>
        <h4
          class="dropdown-toggle float-left"
          style="white-space: nowrap"
          dropdownToggle>
          {{ loc.name }}<span class="caret"></span>
        </h4>
        <div *dropdownMenu id="header-title" class="dropdown-menu" role="menu">
          <li
            *ngFor="let l of locations"
            (click)="setLocation(l)"
            class="dropdown-item">
            {{ l.name }}
          </li>
        </div>
      </span>
    </div>
    <div class="mr-auto"></div>
    <div *ngIf="loc" class="d-none d-md-block">
      <form class="header-search-form" (submit)="search()">
        <icons name="Search"></icons>
        <input
          type="search"
          name="headerSearch"
          [(ngModel)]="searchText"
          placeholder="Search..."
          aria-label="Search" />
      </form>
    </div>

    <div class="header-divider d-none d-md-block"></div>

    <div class="header-icons">
      <div class="header-icons-item">
        <div class="icons">
          <div class="col p-0 text-center">
            <a
              style="color: #1d2038"
              href="https://support.cheddrsuite.com"
              target="_blank">
              <div class="card-img pointer">
                <icons name="QuestionMarkCircle"></icons>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="header-icons-item">
        <div class="icons">
          <div class="col p-0 text-center">
            <div routerLink="/messages">
              <div class="card-img pointer">
                <icons name="Bell"></icons>
                <div
                  class="card-img-overlay"
                  *ngIf="messageCount > 0"
                  style="top: -120%; left: 20%; z-index: 5">
                  <span class="notification-badge">{{ messageCount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="header-icons-item dropdown" dropdown>
        <div class="icons">
          <icons
            class="dropdown-toggle"
            style="white-space: nowrap"
            dropdownToggle
            name="Grid">
          </icons>
        </div>

        <div *dropdownMenu id="quick-links" class="dropdown-menu" role="menu">
          <li
            *ngIf="isAllowed(Permission.EditEvent)"
            class="dropdown-item"
            [class.freemium-icon]="isFreemium()"
            (click)="!isFreemium() && !inJail() && modalEvent.show()"
            [tooltip]="isFreemium() && 'Upgrade to Pro to Add Event'">
            Add Event
          </li>
          <li
            *ngIf="isAllowed(Permission.EditTask)"
            class="dropdown-item"
            [class.freemium-icon]="isFreemium()"
            (click)="!isFreemium() && !inJail() && modalTask.show()"
            [tooltip]="isFreemium() && 'Upgrade to Pro to Add Task'">
            Add Task
          </li>
          <li
            *ngIf="loc && isAllowed(this.Permission.EditInvoices)"
            class="dropdown-item"
            [class.freemium-icon]="isFreemium()"
            (click)="!isFreemium() && !inJail() && modalInvoice.show()"
            [tooltip]="isFreemium() && 'Upgrade to Pro to Log Invoice'">
            Log Invoice
          </li>
          <li
            *ngIf="loc"
            class="dropdown-item"
            (click)="!inJail() && modalTimeOff.show(user)">
            Time Off
          </li>
          <li
            *ngIf="loc"
            class="dropdown-item"
            (click)="!inJail() && modalMessage.show()">
            Staff Messaging
          </li>
        </div>
      </div>

      <div class="header-icons-item dropdown" dropdown>
        <div class="dropdown-toggle" style="white-space: nowrap" dropdownToggle>
          <div class="icons">
            <img
              [src]="
                getUserProfileImage(
                  user.has_image,
                  user.firstname,
                  user.lastname,
                  user.color
                ) | safeUrl
              " />
          </div>
        </div>
        <div *dropdownMenu id="quick-profile" class="dropdown-menu" role="menu">
          <li class="dropdown-item" routerLink="/profile">View my profile</li>
          <li class="dropdown-item" routerLink="/profile/profile">
            Edit my profile
          </li>
          <li *ngIf="loc" class="dropdown-item" routerLink="/profile/docs">
            Docs & quizzes
          </li>
          <li *ngIf="loc" class="dropdown-item" routerLink="/profile/reviews">
            Reviews & writeups
          </li>
          <li
            class="dropdown-item"
            *ngIf="loc && loc.allow_preferences"
            (click)="!inJail() && modalPreferences.show()">
            Work Preferences
          </li>
          <li class="dropdown-item" (click)="logout()">Logout</li>
        </div>
      </div>
    </div>
  </nav>

  <modal-event #modalEvent></modal-event>
  <modal-task #modalTask></modal-task>
  <modal-invoice #modalInvoice></modal-invoice>
  <modal-timeoff #modalTimeOff></modal-timeoff>
  <modal-preferences #modalPreferences></modal-preferences>
  <modal-message #modalMessage></modal-message>

  <div
    id="main-container"
    [class.body-push-right]="!leftIsCollapsed"
    [class.body-push-left]="!rightIsCollapsed">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</div>
<div *ngIf="!user">
  <div id="nouser-container">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</div>

<ng-template #template>
  <router-outlet> </router-outlet>
</ng-template>

<div
  bsModal
  #timeoutModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Inactivity Timeout"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-center" style="width: 100%">
          Inactivity Timeout
        </div>
        <button type="button" class="close" aria-label="Close" (click)="stay()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        You have not been active in the past few minutes, and will be logged out
        soon.
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-primary w-100"
              (click)="stay()">
              Continue Working
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
