<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Time Off"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" *ngIf="data">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Time Off
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" *ngIf="data">
        <local-error [error]="blackoutError"></local-error>
        <form ngNativeValidate (submit)="save()">
          <div class="row">
            <div class="col-md-6 col-12" *ngIf="!baseUser">
              <div class="card my-1">
                <div class="card-body" *ngIf="users">
                  <label>Choose Employee</label>
                  <select
                    name="user_id"
                    [(ngModel)]="data.user_id"
                    class="form-control d-inline-block"
                    (ngModelChange)="changeUser()"
                    required>
                    <option
                      *ngFor="let u of users | orderBy:'lastname'"
                      [value]="u.id">
                      {{u.firstname}} {{u.lastname}}
                    </option>
                  </select>
                  <!--logged in employee is the only one, no select input.This field does not show.  But we can print the name.  Otherwise,the list is sorted by last name  -->
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12" *ngIf="baseUser">
              <div class="card my-1">
                <div class="card-body">
                  <label>For Employee </label>
                  <span class="ml-2">
                    {{baseUser.firstname}} {{baseUser.lastname}}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="card my-1">
                <div class="card-body">
                  <label>What kind of time off</label>
                  <div class="row">
                    <div class="col">
                      <div class="btn-group-toggle">
                        <label
                          class="btn btn-radio"
                          [class.active]="data.whole_day">
                          <input
                            type="radio"
                            name="options"
                            [value]="true"
                            [(ngModel)]="data.whole_day" />Whole Days
                        </label>
                      </div>
                    </div>
                    <div class="col">
                      <div class="btn-group-toggle">
                        <label
                          class="btn btn-radio"
                          [class.active]="!data.whole_day">
                          <input
                            type="radio"
                            name="options"
                            [value]="false"
                            [(ngModel)]="data.whole_day" />Partial Day
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-12">
              <div class="card my-1">
                <div class="card-body">
                  <label
                    ><span *ngIf="data.whole_day">Starting </span>Date</label
                  >
                  <input
                    type="text"
                    class="form-control input-calendar"
                    [minDate]="minDate"
                    bsDatepicker
                    [(ngModel)]="data.start_date"
                    name="start_date"
                    required />
                  <p class="mb-0">
                    Time Off Request must be made {{to_days}} days in advance.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="card my-1">
                <div class="card-body">
                  <div *ngIf="data.whole_day">
                    <label for="employee"
                      ><label>For how many days</label></label
                    >
                    <input
                      type="number"
                      min="1"
                      class="form-control override-invalid"
                      name="days_off"
                      [(ngModel)]="daysOff"
                      placeholder="Please enter the number of days"
                      required />
                  </div>
                  <div *ngIf="!data.whole_day">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <label>Start Time</label>
                        <timepicker
                          name="start"
                          [(ngModel)]="data.start_date"></timepicker>
                      </div>
                      <div class="col-md-6 col-12">
                        <label>End&nbsp;Time</label>
                        <timepicker
                          name="end"
                          [(ngModel)]="data.end_date"></timepicker>
                        <local-error [error]="error.end_date"></local-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card my-1">
                <div class="card-body">
                  <label for="employee">Notes</label>
                  <textarea
                    [(ngModel)]="data.notes"
                    rows="3"
                    class="form-control"
                    name="notes"
                    placeholder="Comments to your manager"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <save-button class="float-right"></save-button>
            </div>
          </div>
          <div class="row" *ngIf="timeOffs">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <label> Requests Status</label>
                  <ng-container
                    *ngFor="let d of timeOffs | orderBy: 'start_date'">
                    <div class="my-1">
                      <span class="ml-3">
                        <h5>{{d.firstname}} {{d.lastname }}</h5>
                      </span>
                      {{d.start_date | date:"EEE, MMM d, y, h:mm a"}} -
                      {{d.end_date | date:"EEE, MMM d, y, h:mm a"}}
                      <span *ngIf="d.approved" class="alert-success"
                        >Approved
                        <span class="font-weight-light">
                          by {{ d.action_firstname }} {{d.action_lastname}}
                          {{d.action_at | date:"EEE, MMM d, y, h:mm a"}}</span
                        ></span
                      >
                      <span
                        *ngIf="!d.approved && d.action_at"
                        class="alert-danger"
                        >Denied
                        <span class="font-weight-light"
                          >by {{ d.action_firstname }} {{d.action_lastname}}
                          {{d.action_at | date:"EEE, MMM d, y, h:mm a"}}</span
                        ></span
                      >
                      <br />Note: {{ d.notes }}
                      <br />
                      Requested {{d.created_at |date: "EEE, MMM d, y, h:mm a" }}
                      <delete-modal
                        [deleteObject]="d"
                        name="this time off"
                        (onDelete)="delete($event)">
                        <button
                          type="button"
                          class="btn btn-dark btn-sm float-right mb-2">
                          Delete
                        </button>
                      </delete-modal>
                    </div>
                  </ng-container>
                  <div *ngIf="timeOffs.length==0">No time off requests.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="blackOuts">
            <div class="col-md-12">
              <div class="card my-1">
                <div class="card-body">
                  <label>Current Black Out Dates</label>
                  <ng-container
                    *ngFor="let blackOut of blackOuts | orderBy: 'start'">
                    <alert type="danger">
                      <span>
                        Blackout date from {{ blackOut.start | date: 'longDate'
                        }} to {{ blackOut.end | date: 'longDate'}}
                      </span>
                    </alert>
                  </ng-container>
                  <div *ngIf="blackOuts.length==0">No blackout dates</div>
                </div>
              </div>
            </div>
          </div>
          <!--				<div class='row'>
						<div class='col-md-12'>
							<label class='ml-3 mt-2'>Final Status</label>
							<div class='card my-0' *ngIf="approvedTimeOffs">
								<ng-container *ngFor="let d of approvedTimeOffs | orderBy: 'start_date'">
								<div class='card-body'>
										<h5>{{d.firstname}} {{d.lastname}}</h5>
										{{d.start_date |  date:"EEE, MMM d, y, h:mm a" }} - {{d.end_date |  date:"EEE, MMM d, y, h:mm a"}}
										<span *ngIf="d.approved" class="alert-success">Approved
											<span class="font-weight-light"> by {{ d.action_firstname }} {{d.action_lastname}} {{d.action_at | date:"EEE, MMM d, y, h:mm a"}}</span></span>
										<span *ngIf="!d.approved" class="alert-danger">Denied
											<span class="font-weight-light">by {{ d.action_firstname }} {{d.action_lastname}} {{d.action_at | date:"EEE, MMM d, y, h:mm a"}}</span></span>
									<br>
										<small>
						 					Requested {{d.created_at | date:"EEE, MMM d, y, h:mm a"}}
										</small>
										<delete-modal [deleteObject]="d" name="this approved time off" (onDelete)="delete($event)">
											<button type="button" class="btn btn-warning float-right" style='font-size: 12px; '> Delete Approved Time Off </button>
										</delete-modal>
								</div>
								</ng-container>
								<div class='card-body' *ngIf="approvedTimeOffs.length==0">
									No approved time off requests.
								</div>
							</div>
						</div>
					</div> -->
        </form>
      </div>
    </div>
  </div>
</div>
