import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';
import { BlackOutService } from '../services/blackOut.service';
import { ChecklistService } from '../services/checklist.service';
import { ChecklistItemService } from '../services/checklistItem.service';
import { ChecklistUserMapService } from '../services/checklistUserMap.service';
import { ChecklistPositionMapService } from '../services/checklistPositionMap.service';
import { CustomLogFieldService } from '../services/customLogField.service';
import { EmployeePositionService } from '../services/employeePosition.service';
import { FixedCostService } from '../services/fixedCost.service';
import { IntegrationService } from '../services/integration.service';
import { LocationService } from '../services/location.service';
import { ExpenseAccountService } from '../services/expenseAccount.service';
import { PermissionLocationService } from '../services/permissionLocation.service';
import { PaymentService } from '../services/payment.service';

import {
  AccountListComponent,
  AccountEditComponent,
  AccountEditIntegrationComponent,
  AccountEditPackageComponent,
} from './account.component';
import { ChecklistComponent } from './checklist.component';
import { CostComponent } from './cost.component';
import { DashboardComponent } from './dashboard.component';
import { GoalsComponent } from './goals.component';
import { LogComponent } from './log.component';
import { ModalEditChecklistComponent } from './modalEditChecklist.component';
import { ModalFixedCostComponent } from './modalFixedCost.component';
import { PermissionsComponent } from './permissions.component';
import { IntegrationComponent } from './integration.component';
import { NewLocationComponent } from './newLocation.component';
import { ScheduleComponent } from './schedule.component';
import { SettingsComponent } from './settings.component';

import { routing } from './settings.routing';

@NgModule({
  declarations: [
    AccountEditComponent,
    AccountEditIntegrationComponent,
    AccountEditPackageComponent,
    AccountEditComponent,
    AccountListComponent,
    ChecklistComponent,
    CostComponent,
    DashboardComponent,
    GoalsComponent,
    IntegrationComponent,
    LogComponent,
    ModalEditChecklistComponent,
    ModalFixedCostComponent,
    NewLocationComponent,
    PermissionsComponent,
    ScheduleComponent,
    SettingsComponent,
  ],
  imports: [SharedModule, routing],
  providers: [
    BlackOutService,
    ChecklistService,
    ChecklistItemService,
    ChecklistUserMapService,
    ChecklistPositionMapService,
    CustomLogFieldService,
    ExpenseAccountService,
    EmployeePositionService,
    LocationService,
    IntegrationService,
    FixedCostService,
    PermissionLocationService,
    PaymentService,
  ],
})
export class SettingsModule {}
