<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Template Manager
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <form ngNativeValidate (submit)="addNew()">
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="form-group">
                <label for="messageTitle"
                  >Save this schedule to this name</label
                >
                <input
                  [(ngModel)]="templateName"
                  type="text"
                  maxlength="50"
                  class="form-control override-invalid"
                  name="templateName"
                  placeholder="Name for new template"
                  required />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body" *ngIf="data && data.length>0">
              <label>Existing Templates</label>
              <!--							<table class='table table-hover nav-padding'>
								<tbody >
									<tr  *ngFor="let d of data">
										<td>
											{{d.name}}
											<div class="btn-group-toggle"  >
												<span class="btn btn-radio" [class.active]="d==selectedTemplate">
													<input type="radio" name="options" [value]='d' [(ngModel)]="selectedTemplate" (ngModelChange)="selectedTemplate = d;areYouSure.show()">{{d.name}}
												</span>
											</div>
										</td>
										<td>
											<delete-modal class='float-right' [name]="d.name" [deleteObject]="d" (onDelete)="deleteTemplate($event)">
											<span class='cdi-m-trash'></span>
											</delete-modal>
										</td>
									</tr>
								</tbody>
							</table>-->
              <div class="row" *ngFor="let d of data">
                <div class="col">
                  {{d.name}}
                  <span
                    class="float-right cdi-s-trash pointer"
                    (click)="deleteModal.name=d.name;deleteModal.deleteObject=d;deleteModal.show()"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <cancel-button
                class="float-right"
                (click)="modal.hide()"></cancel-button>
              <save-button class="float-right"></save-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<delete-modal
  #deleteModal
  class="float-right"
  (onDelete)="deleteTemplate($event)"></delete-modal>
<div
  bsModal
  #areYouSure="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">REPLACING TEMPLATE</div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="selectedTemplate=null;areYouSure.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="selectedTemplate">
        Are you sure you want to replace the {{selectedTemplate.name}} template?
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="selectedTemplate=null;areYouSure.hide()"></cancel-button>
            <button
              type="button"
              class="btn btn-primary float-right mr-2"
              (click)="areYouSure.hide();replace()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
