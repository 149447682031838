import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { LogService, ILogReport } from '../services/log.service';
import { ExpenseType } from '../shared/api';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-profit-loss',
  templateUrl: 'modalProfitLoss.html',
})
export class ModalProfitLossComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;
  public start: Date;
  public end: Date;
  public daily: boolean;
  public salesTotal: number[];
  public foh_labor: number;
  public boh_labor: number;
  //public cogs: any[];
  //public fixed: any[];
  //public cTotal: number;
  //public fTotal: number;
  public loaded = false;

  public ExpenseType = ExpenseType;
  public costMap: any = {};
  public costData: any = {};

  constructor(
    public accountService: AccountService,
    public logService: LogService
  ) {
    super(accountService);
    this.daily = this.accountService.getLocation().is_service_time_daily;
  }

  ngOnInit() {}

  load() {
    this.loaded = false;
    this.logService
      .getReport({ start: this.start, end: this.end, include_expenses: true })
      .subscribe((data: any[]) => {
        let reportData = data;
        //let invoiceMap: any= {};
        //let fixedMap: any= {};
        this.costMap = {
          '1': { name: 'COGS', value: 0.0 },
          '2': { name: 'FOH', value: 0.0 },
          '3': { name: 'BOH', value: 0.0 },
          '4': { name: 'Fixed', value: 0.0 },
        };

        this.salesTotal = [0, 0, 0, 0];
        this.foh_labor = 0;
        this.boh_labor = 0;
        // Doing sales totals
        console.log('Doing report data');
        for (let d of reportData) {
          for (let i = 0; i < (this.daily ? 1 : 4); i++) {
            this.salesTotal[i] += d.service_time[i].sales;
          }
          this.foh_labor += d.foh_cost;
          this.boh_labor += d.boh_cost;
          for (let i of d.invoices) {
            this.addToCostMap(i);
            /*if(!(i.expense_type in invoiceMap)) {
						invoiceMap[i.expense_type]={ name: i.expense_account_name, value: 0 };
					}
					if(!(i.expense_account_id in invoiceMap[i.expense_type])) {
						invoiceMap[i.expense_type][i.expense_account_id]={ name: i.name, value: 0 };
					}
					invoiceMap[i.expense_type].value+=i.value;*/
          }
          for (let i of d.expenses) {
            if (i.expense_type != ExpenseType.Fixed) continue;
            this.addToCostMap(i);
            /*
					if(!(i.expense_type in fixedMap)) {
						fixedMap[i.expense_type]={ name: i.expense_account_name, value: 0 };
					}
					if(!(i.expense_account_id in fixedMap[i.expense_type])) {
						fixedMap[i.expense_type][i.expense_account_id]={ name: i.name, value: 0 };
					}
					fixedMap[i.expense_type][i.expense_account_id].value+=i.amt;
					fixedMap[i.expense_type].value+=i.amt;*/
          }
        }
        //this.fTotal = this.cTotal = 0;
        //this.cogs = this.convert(invoiceMap,false);
        //this.fixed = this.convert(fixedMap,true);

        this.convert();
        this.loaded = true;
        this.modal.show();
      });
    // Load the data you need, then
  }

  convert() {
    this.costData = {};
    for (let etype in this.costMap) {
      this.costData[etype] = {
        name: this.costMap[etype].name,
        value: this.costMap[etype].value,
        data: [],
      };
      for (let key in this.costMap[etype]) {
        if (key == 'name' || key == 'value') continue;
        this.costData[etype].data.push(this.costMap[etype][key]);
      }
    }
    console.log(this.costMap);
    console.log(this.costData);
  }
  addToCostMap(data: any) {
    /*if(!(data.expense_type in this.costMap)) {
			this.costMap[data.expense_type]={ name: data.expense_account_name, value: 0 };
		}*/
    if (!(data.expense_account_id in this.costMap[data.expense_type])) {
      this.costMap[data.expense_type][data.expense_account_id] = {
        name: data.expense_account_name,
        value: 0,
      };
    }
    this.costMap[data.expense_type][data.expense_account_id].value += data.amt;
    this.costMap[data.expense_type].value += data.amt;
  }

  /*convert(data: any,fixedTotal: boolean): any [] {
		let out = [];
		for(let t in data) {
			let obj = { expense_type: t, name: data[t].name, value: data[t].value, data: [] };
			for(let id in data[t]) {
				if(id=='value' || id == 'name') continue;
				obj.data.push(data[t][id]);
			}
			out.push(obj);
			if(fixedTotal) this.fTotal+=data[t].value;
			else this.cTotal+=data[t].value;
		}
		console.log('Converting');
		console.log(data);
		console.log(out);
		return out;
	}*/
  show(start: Date, end: Date) {
    this.start = start;
    this.end = end;
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  print() {
    window.print();
  }
}
