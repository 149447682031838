import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { LogService } from '../services/log.service';
import { baseRole } from '../shared/baseRole';
import { LaborStatType, PositionType, moment } from '../shared/api';

const shiftcol = 1;
const hourscol = 2;
const plannedcol = 3;
const actualcol = 4;
const diffcol = 5;

@Component({
  selector: 'modal-labor-stat',
  templateUrl: 'modalLaborStat.html',
})
export class ModalLaborStatComponent extends baseRole implements OnInit {
  public LaborStatType = LaborStatType;
  @ViewChild('modal')
  public modal: ModalDirective;
  public day: any;
  public dayMap: any;
  public statMap: any;

  public fohBohChartData: any[];

  public hours: number[] = [];
  public shifts: number[] = [];
  public planned: number[] = [];
  public actual: number[] = [];
  public diff: number[] = [];
  public totals: number[] = [];
  public foh_budget: number;
  public boh_budget: number;
  public unassignedShifts: number;
  public unassignedHours: number;
  public daily = false;
  public ready = false;
  public has_houses: boolean;

  public users: any[];

  constructor(
    public accountService: AccountService,
    public logService: LogService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.has_houses = this.getLocation().has_houses;
  }

  load() {
    let keys: string[] = [];

    if (this.daily) {
      keys.push(moment(this.day.date).format('YYYY-MM-DD'));
    } else {
      for (let k of Object.keys(this.dayMap)) {
        keys.push(moment(this.dayMap[k].date).format('YYYY-MM-DD'));
      }
    }

    this.hours = [];
    this.shifts = [];
    this.planned = [];
    this.actual = [];
    this.diff = [];
    this.foh_budget = 0;
    this.boh_budget = 0;
    this.unassignedShifts = 0;
    this.unassignedHours = 0;
    for (let i = 0; i <= 7; i++) {
      this.hours[i] = 0;
      this.shifts[i] = 0;
      this.planned[i] = 0;
      this.actual[i] = 0;
      this.diff[i] = 0;
    }

    let userMap: any = {};
    let keyUserMap: any = {};
    for (let k of keys) {
      let stat = this.statMap[k];
      let day = this.dayMap[k];
      /*console.log('Stats / Days');
			console.log(stat);
			console.log(day);*/
      let future = moment(k).isAfter(moment());

      this.foh_budget += day.goal * (this.getLocation().foh_goal / 100);
      this.boh_budget += day.goal * (this.getLocation().boh_goal / 100);

      this.shifts[LaborStatType.SalariedFOH] += stat.shifts.filter(e => {
        return e.is_salary && e.position_type == PositionType.FrontOfHouse;
      }).length;
      this.shifts[LaborStatType.SalariedBOH] += stat.shifts.filter(e => {
        return e.is_salary && e.position_type == PositionType.BackOfHouse;
      }).length;
      this.shifts[LaborStatType.HourlyFOH] += stat.shifts.filter(e => {
        return !e.is_salary && e.position_type == PositionType.FrontOfHouse;
      }).length;
      this.shifts[LaborStatType.HourlyBOH] += stat.shifts.filter(e => {
        return !e.is_salary && e.position_type == PositionType.BackOfHouse;
      }).length;

      this.hours[LaborStatType.SalariedFOH] += stat.shifts.reduce((a, e) => {
        return (
          a +
          (e.is_salary && e.position_type == PositionType.FrontOfHouse
            ? e.minutes
            : 0)
        );
      }, 0);
      this.hours[LaborStatType.SalariedBOH] += stat.shifts.reduce((a, e) => {
        return (
          a +
          (e.is_salary && e.position_type == PositionType.BackOfHouse
            ? e.minutes
            : 0)
        );
      }, 0);
      this.hours[LaborStatType.HourlyFOH] += stat.shifts.reduce((a, e) => {
        return (
          a +
          (!e.is_salary && e.position_type == PositionType.FrontOfHouse
            ? e.minutes
            : 0)
        );
      }, 0);
      this.hours[LaborStatType.HourlyBOH] += stat.shifts.reduce((a, e) => {
        return (
          a +
          (!e.is_salary && e.position_type == PositionType.BackOfHouse
            ? e.minutes
            : 0)
        );
      }, 0);

      this.planned[LaborStatType.SalariedFOH] += stat.salary.foh_cost;
      this.planned[LaborStatType.SalariedBOH] += stat.salary.boh_cost;
      this.planned[LaborStatType.HourlyFOH] +=
        stat.scheduled.foh_cost + stat.scheduled.ot_foh_cost;
      this.planned[LaborStatType.HourlyBOH] +=
        stat.scheduled.boh_cost + stat.scheduled.ot_boh_cost;

      if (!future) {
        this.actual[LaborStatType.SalariedFOH] += stat.salary.foh_cost;
        this.actual[LaborStatType.SalariedBOH] += stat.salary.boh_cost;
        this.actual[LaborStatType.HourlyFOH] +=
          stat.timeclock.foh_cost + stat.timeclock.ot_foh_cost;
        this.actual[LaborStatType.HourlyBOH] +=
          stat.timeclock.boh_cost + stat.timeclock.ot_boh_cost;
      }

      let unassigned = stat.shifts.filter(e => {
        return !e.user_id;
      });
      this.unassignedShifts += unassigned.length;
      for (let u of unassigned) {
        this.unassignedHours += u.minutes;
      }
      for (let u of stat.users) {
        if (!(u.id in userMap)) {
          userMap[u.id] = {
            id: u.id,
            firstname: u.firstname,
            lastname: u.lastname,
            shifts: 0,
            minutes: 0,
            planned: 0,
            actual: 0,
          };
        }
        let tKey = k + '_' + u.id;
        let firstMap = !(tKey in keyUserMap);
        if (firstMap) keyUserMap[tKey] = true;
        //console.log(tKey+ "  "+firstMap);

        userMap[u.id].shifts += u.shifts.length;
        if (u.salary && firstMap) {
          userMap[u.id].planned +=
            u.salary.foh_cost +
            u.salary.boh_cost +
            u.salary.ot_foh_cost +
            u.salary.ot_boh_cost;
          if (!future) {
            userMap[u.id].actual +=
              u.salary.foh_cost +
              u.salary.boh_cost +
              u.salary.ot_foh_cost +
              u.salary.ot_boh_cost;
          }
        } else {
          for (let t of u.timeclocks) {
            if (t.is_salary) continue;
            userMap[u.id].actual +=
              (t.rate * t.minutes) / 60 + (t.rate * 0.15 * t.ot_minutes) / 60;
          }
        }

        for (let s of u.shifts) {
          userMap[u.id].minutes += s.minutes + s.ot_minutes;
          if (s.is_salary) continue;
          userMap[u.id].planned +=
            (s.rate * s.minutes) / 60 + (s.rate * 1.5 * s.ot_minutes) / 60;
        }
      }
    }

    this.users = [];
    for (let k in userMap) {
      this.users.push(userMap[k]);
    }

    for (let i = 1; i <= 5; i++) {
      this.diff[i] = this.actual[i] - this.planned[i];
      this.shifts[6] += this.shifts[i];
      this.hours[6] += this.hours[i];
      this.planned[6] += this.planned[i];
      this.actual[6] += this.actual[i];
    }

    /*console.log('Stats');
		console.log(this.hours);
		console.log(this.shifts);
		console.log(this.planned);
		console.log(this.actual);
		console.log(this.diff);*/
    if (this.getLocation().has_houses) {
      let foh_actual =
        this.actual[LaborStatType.SalariedFOH] +
        this.actual[LaborStatType.HourlyFOH];
      let boh_actual =
        this.actual[LaborStatType.SalariedBOH] +
        this.actual[LaborStatType.HourlyBOH];
      let foh_scheduled =
        this.shifts[LaborStatType.SalariedFOH] +
        this.shifts[LaborStatType.HourlyFOH];
      let boh_scheduled =
        this.shifts[LaborStatType.SalariedBOH] +
        this.shifts[LaborStatType.HourlyBOH];

      this.fohBohChartData = [
        [
          ['FOH Historical', 50],
          ['BOH Historical', 50],
        ],
        [
          ['FOH Scheduled', foh_scheduled],
          ['BOH Scheduled', boh_scheduled],
        ],
        [
          ['FOH Actual', foh_actual],
          ['BOH Actual', boh_actual],
        ],
      ];
    }

    this.ready = true; // Block all data access 'til we're ready.
    this.modal.show();
  }

  show(statMap: any, day: any, dayMap: any) {
    // day is null for week
    this.statMap = statMap;
    this.day = day;
    this.dayMap = dayMap;
    this.daily = !(this.day === null);
    /*console.log("day index "+this.day);
		console.log(this.days);*/
    this.load();
  }

  cancel() {
    this.modal.hide();
  }
}
