<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          <span *ngIf="isReview"> Reviews </span>
          <span *ngIf="!isReview"> Write Ups</span>

          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <tabset>
        <tab heading="Reviews" (selectTab)="changeTab(true)"></tab>
        <tab heading="Write Ups" (selectTab)="changeTab(false)"></tab>
      </tabset>

      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form ngNativeValidate>
                  <div class="input-group">
                    <textarea
                      name="text"
                      [(ngModel)]="text"
                      class="form-control"
                      rows="3"
                      [placeholder]="'Add Employee '+(isReview?'Review':'Write Up')"
                      required></textarea>
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary btn-icon bg-white"
                        type="submit"
                        (click)="save()">
                        <span class="cdi-s-add"></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card" *ngFor="let r of data | orderBy:'-created_at'">
              <div class="card-body">
                <label>{{r.created_at | date:'MMMM d, y, h:mm a'}}</label>
                <strong>By:</strong>&nbsp; {{ r.firstname }} {{ r.lastname }}
                <delete-modal
                  [name]="'this'"
                  [deleteObject]="r"
                  (onDelete)="deleteObj($event)"
                  ><span class="cdi-s-trash float-right pointer"></span
                ></delete-modal>
                <p class="px-3 pt-2">{{r.body}}</p>
                <h6 class="text-center" *ngIf="!isReview && !r.signature_id">
                  Unsigned
                </h6>
                <img
                  class="signature-display"
                  *ngIf="r.signature_id"
                  [src]="baseUrl+'signature/'+r.signature_id+'/view'" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button
              type="button"
              (click)="cancel()"
              class="btn btn-secondary float-right">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
