import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { DocService } from '../services/doc.service';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-view',
  templateUrl: 'modalView.html',
})
export class ModalViewComponent extends baseRole {
  @ViewChild('modal')
  public modal: ModalDirective;
  public data;

  constructor(
    public accountService: AccountService,
    public docService: DocService
  ) {
    super(accountService);
  }

  show(data: { mime_type: string; url: string }) {
    this.data = data;
    if (data) {
      this.modal.show();
    }
  }

  isPdf() {
    return this.docService.isPdf(this.data);
  }
  isImage() {
    return this.docService.isImage(this.data);
  }
  isOther() {
    return !(this.isPdf() || this.isImage());
  }

  cancel() {
    this.data = null;
    this.modal.hide();
  }
}
