import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  PasswordStrength,
  MinimumPasswordStrength,
} from '../shared/passwordStrength';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './verify.html',
})
export class VerifyComponent implements OnInit {
  public error: any = {};
  public warning: string;
  public suggestions: string[];
  public verificationCode: string;
  public tnc = 0;
  public data: any = {};
  public pTest = new PasswordStrength();

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Verify');
  }

  ngOnInit() {
    if (this.accountService.getUser()) {
      this.router.navigate(['/']);
      return;
    }
    this.route.queryParams.subscribe(params => {
      this.data.username = params['username'];
      this.data.firstname = params['firstname'];
      this.data.lastname = params['lastname'];
      this.data.token = params['token'];

      if (!this.data.token) {
        this.error.token = 'We were unable to find your verifcation token.';
      }
    });
  }

  checkPassword() {
    delete this.warning;
    delete this.suggestions;
    let [score, warning, suggestions] = this.pTest.measure(this.data.password);
    if (score < MinimumPasswordStrength) {
      this.warning = warning;
      this.suggestions = suggestions;
      return false;
    }
    return true;
  }

  matchPassword() {
    delete this.error.matchPassword;
    if (this.data.password !== this.data.cpassword) {
      this.error.matchPassword = 'Passwords do not match';
      return false;
    }
    return true;
  }

  verify() {
    if (this.error.token) return;
    if (!this.matchPassword()) return;

    this.error = {};
    if (!this.data.username || this.data.username.length == 0) {
      this.error.username = 'Please enter a username.';
      return;
    }
    if (!this.data.token || this.data.token.length == 0) {
      this.error.global =
        'We are unable to find your token. Please contact customer support.';
      return;
    }

    this.accountService.verify(this.data).subscribe(
      valid => {
        if (valid) {
          this.accountService
            .login(this.data.username, this.data.password)
            .subscribe(user => {
              if (!user) {
                this.error.global =
                  'There was a problem with verifying your account. Please try again.';
              } else {
                this.accountService
                  .setLocation(user.locations[0].id)
                  .subscribe(() => {});
                this.router.navigate(['/profile']);
              }
            });
        } else {
          this.error.global =
            'There was a problem with verifying your account. Please try again.';
        }
      },
      err => {
        this.error.global =
          'There was a problem with verifying your account. Please try again.';
      }
    );
  }
}
