import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ContactService, IContact } from '../services/contact.service';
import {
  ContactGroupService,
  IContactGroup,
} from '../services/contactGroup.service';

@Component({
  templateUrl: './vendor.edit.html',
})
export class VendorEditComponent implements OnInit {
  public vendor: IContact;
  public vendorGroups: IContactGroup[];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactGroupService: ContactGroupService,
    protected contactService: ContactService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.contactGroupService.list().subscribe(data => {
        this.vendorGroups = data;
        if (params['id']) {
          // Edit
          this.contactService.get(params['id']).subscribe(data => {
            this.vendor = data;
          });
        } else {
          this.vendor = {
            is_vendor: true,
            phone: null,
            cell: null,
            fax: null,
          } as IContact;
          //			console.log(this.vendor);
        }
      });
    });
  }

  save() {
    this.contactService.update(this.vendor).subscribe(result => {
      this.router.navigate(['/contacts/']);
    });
  }

  cancel() {
    this.router.navigate(['/contacts/']);
  }
}

@Component({
  templateUrl: './vendor.view.html',
})
export class VendorViewComponent implements OnInit {
  public vendor: IContact;
  public vendorGroups: any = {};

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactGroupService: ContactGroupService,
    protected contactService: ContactService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.contactGroupService.list().subscribe(data => {
        for (let d of data) {
          this.vendorGroups[d.id] = d;
        }
        this.contactService.get(id).subscribe(data => {
          this.vendor = data;
        });
      });
    });
  }

  cancel() {
    this.router.navigate(['/contacts/']);
  }
}
