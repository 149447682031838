import { Injectable } from '@angular/core';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  IPurchaseOrder,
  IPurchaseOrderCreate,
  PurchaseOrderStatus,
  IPurchaseOrderChangeStatus,
} from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class PurchaseOrderService extends BaseService<IPurchaseOrder> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'purchaseOrder');
  }

  sumByType(
    start: Date,
    end: Date
  ): Observable<{ status: number; price: number }[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    queryParameters = queryParameters.set('start', start.toString());
    queryParameters = queryParameters.set('end', end.toString());
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl, { params: queryParameters })
      .pipe(map(this.postProcess()), catchError(BaseService.handleError));
  }

  createMany(data: IPurchaseOrderCreate): Observable<number> {
    return this.http.post(this.baseUrl + 'many', data).pipe(
      map(this.postProcess()),
      map(data => {
        this.notifyUpdate();
        return data;
      }),
      catchError(BaseService.handleError)
    );
  }

  resend(id: number): Observable<boolean> {
    return this.http.post(this.baseUrl + 'resend/' + id, {}).pipe(
      map(this.postProcess()),
      map(data => {
        this.notifyUpdate();
        return data;
      }),
      catchError(BaseService.handleError)
    );
  }

  changeStatus(data: IPurchaseOrderChangeStatus): Observable<any> {
    return this.http
      .put<any>(
        this.baseUrl + 'statusChange/' + data.id,
        data,
        this.defaultOptions
      )
      .pipe(
        map(data => {
          this.notifyUpdate();
          return data;
        }),
        catchError(BaseService.handleError)
      );
  }

  getPdfUrl(data: { location_id: number; purchase_order_id: number }): string {
    return (
      this.baseUrl +
      'pdf?location_id=' +
      data.location_id +
      '&purchase_order_id=' +
      data.purchase_order_id
    );
  }
}

export { IPurchaseOrder, PurchaseOrderStatus } from '../shared/api';
