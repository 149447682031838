import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardJail } from '../app/auth-guard';
import {
  AccountListComponent,
  AccountEditComponent,
  AccountEditIntegrationComponent,
  AccountEditPackageComponent,
} from './account.component';
import { ChecklistComponent } from './checklist.component';
import { CostComponent } from './cost.component';
import { DashboardComponent } from './dashboard.component';
import { GoalsComponent } from './goals.component';
import { IntegrationComponent } from './integration.component';
import { NewLocationComponent } from './newLocation.component';
import { LogComponent } from './log.component';
import { PermissionsComponent } from './permissions.component';
import { ScheduleComponent } from './schedule.component';
import { SettingsComponent } from './settings.component';

const appRoutes: Routes = [
  {
    path: 'settings',
    canActivate: [AuthGuard],
    component: SettingsComponent,
    children: [
      { path: '', canActivate: [AuthGuardJail], component: LogComponent },
      {
        path: 'account',
        children: [
          { path: '', component: AccountListComponent },
          { path: 'new', component: NewLocationComponent },
          { path: 'edit', component: AccountEditComponent },
          {
            path: ':id',
            children: [
              { path: '', component: AccountEditComponent },
              { path: 'package', component: AccountEditPackageComponent },
              {
                path: 'integration',
                component: AccountEditIntegrationComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: DashboardComponent },
          { path: 'checklist/add', component: ChecklistComponent },
          { path: 'checklist/:id', component: ChecklistComponent },
        ],
      },
      { path: 'cost', canActivate: [AuthGuard], component: CostComponent },
      { path: 'goals', canActivate: [AuthGuard], component: GoalsComponent },
      {
        path: 'permissions',
        canActivate: [AuthGuard],
        component: PermissionsComponent,
      },
      {
        path: 'integration',
        canActivate: [AuthGuard],
        component: IntegrationComponent,
      },
      {
        path: 'schedule',
        canActivate: [AuthGuard],
        component: ScheduleComponent,
      },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
