import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService, IUser } from '../services/account.service';
import { ISubNavItem, UserType } from '../shared/api';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './profile.html',
})
export class ProfileComponent extends baseRole implements OnInit {
  public _menu: ISubNavItem[] = [
    {
      label: 'Account',
      routerLink: '/profile',
      exact: true,
    },
    {
      label: 'Profile',
      routerLink: '/profile/profile',
      exact: true,
    },
    {
      label: 'Docs & Quizes',
      routerLink: '/profile/docs',
    },
    {
      label: 'Reviews & Writeups',
      routerLink: '/profile/reviews',
    },
    {
      label: 'Work Preferences',
      routerLink: '/profile/scheduling',
    },
    {
      label: 'Dashboard',
      routerLink: '/profile/dashboard',
    },
  ];
  public menu: ISubNavItem[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Profile');
  }

  ngOnInit() {
    let loc = this.accountService.getLocation();
    if (!loc) {
      this.menu = [
        {
          label: 'Account',
          routerLink: '/profile',
          exact: true,
        },
        {
          label: 'Profile',
          routerLink: '/profile/profile',
          exact: true,
        },
      ];
    } else {
      this.menu = this._menu.slice();
      // No cards if you aren't important. :)
      if (!this.inRole([UserType.Admin, UserType.Owner, UserType.Manager])) {
        this.menu = this.menu.filter(e => {
          return !(e.routerLink.indexOf('cards') > 0);
        });
      }

      if (!this.isAllowed(this.Permission.ViewSales)) {
        this.menu = this.menu.filter(e => {
          return !(e.routerLink.indexOf('dashboard') > 0);
        });
      }

      this.menu = this.menu.filter(e => {
        if (!loc.allow_preferences && e.routerLink.indexOf('scheduling') > 0)
          return false;
        if (
          this.isFreemium() &&
          (e.routerLink.indexOf('dashboard') > 0 ||
            e.routerLink.indexOf('docs') > 0)
        )
          return false;
        return true;
      });
    }
    //console.log(this.menu);
  }
}
