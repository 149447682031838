import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { LocationService } from '../services/location.service';
import { UserService } from '../services/user.service';
import { BillingService } from '../services/billing.service';
import {
  getSignInUrl,
  StringSort,
  SelectAddAll,
  PackageType,
  PackageTypeNames,
  SalesIntegrationType,
  LocationStatus,
  moment,
} from '../shared/api';

@Component({
  templateUrl: './billingReport.html',
})
export class BillingReportComponent implements OnInit {
  public search: any;
  public orderByField = 'date';
  public data: any[];
  public totals: number[];
  public grandSignups: number;
  public grandPromos: number;
  public grandTotal: number[];
  public changes: number[][];
  public PackageType = PackageType;
  public PackageTypeNames = PackageTypeNames;
  public PackageTypeKeys = [0, 1, 2, 3];
  //public SalesIntegrationType = SalesIntegrationType;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected billingService: BillingService,
    protected locationService: LocationService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.search = {
      start: moment().startOf('month').toDate(),
      end: moment().endOf('month').toDate(),
    };
    this.load();
  }

  load() {
    forkJoin([
      this.locationService.list(this.search),
      this.billingService.list(this.search),
    ]).subscribe(result => {
      this.data = null;
      this.grandTotal = [0, 0, 0, 0];
      this.grandSignups = 0;
      this.totals = [0, 0, 0, 0];
      this.changes = [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ];
      let map: any = {};

      let locs = result[0];
      let data = result[1];

      for (
        let m = moment(this.search.start);
        m.isSameOrBefore(this.search.end);
        m.add(1, 'days')
      ) {
        map[m.format('YYYY-MM-DD')] = {
          date: m.toDate(),
          signup: 0,
          promo: 0,
          amount: [0, 0, 0, 0],
          changes: [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
          ],
        };
      }

      for (let l of locs) {
        let key = moment(l.created_at).format('YYYY-MM-DD');
        if (key in map) {
          map[key].signup++;
          this.grandSignups++;
        }
      }

      for (let d of data) {
        let key = moment(d.created_at).format('YYYY-MM-DD');
        let m = map[key];

        m.amount[d.new_package_type] += d.amt;
        this.totals[d.new_package_type] += d.amt;
        if ((<any>d).is_promo) {
          map[key].promo++;
          this.grandPromos++;
        }
        this.grandTotal[d.new_package_type] += d.amt;
        if (d.package_type == d.new_package_type) continue;
        m.changes[d.new_package_type][d.package_type]++;
      }

      this.data = [];
      for (let key in map) {
        map[key].total = map[key].amount[1] + map[key].amount[2];
        this.data.push(map[key]);
      }
    });
  }
}
