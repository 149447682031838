<div class="container-fluid subnav-padding">
  <modal-standing-order
    (onChange)="load()"
    #modalStandingOrder></modal-standing-order>
  <div class="nav-padding"></div>
  <div class="card">
    <div class="card-body">
      <label
        >Active Standing Orders
        <button
          class="btn btn-primary float-right"
          type="button"
          (click)="modalStandingOrder.show()">
          Add Standing Order
        </button>
      </label>
      <table
        class="table table-sm table-striped table-hover"
        style="font-size: 12px">
        <thead class="thead-light">
          <tr>
            <th>Vendor</th>
            <th>Name</th>
            <th>Frequency</th>
            <th class="d-none d-sm-table-cell">Starting Date</th>
            <th class="text-right">Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let s of standingList | orderBy:'contact_company'">
            <td>{{s.contact_company}}</td>
            <td>{{s.name}}</td>
            <td>{{RepeatOptions[s.repeat_option]}}</td>
            <td class="d-none d-sm-table-cell">
              {{ s.date | date: 'EEE M/d/yy'}}
            </td>
            <td class="text-right">{{ s.price | currency: 'USD' }}</td>
            <td>
              <span
                class="cdi-s-trash float-right pointer"
                (click)="delete(s,true)"></span>
              <span
                class="cdi-s-edit float-right pointer"
                (click)="modalStandingOrder.show(s)"></span>
              <span
                class="cdi-s-pause float-right pointer"
                (click)="togglePause(s)"></span>
            </td>
          </tr>
        </tbody>

        <tbody></tbody>
      </table>

      <hr />
      <label>Paused Standing Orders </label>
      <table
        class="table table-sm table-striped table-hover"
        style="font-size: 12px">
        <thead class="thead-light">
          <tr>
            <th>Vendor</th>
            <th>Name</th>
            <th>Frequency</th>
            <th class="d-none d-sm-table-cell">Starting Date</th>
            <th class="text-right">Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let s of pausedList | orderBy:'contact_company'">
            <td>{{s.contact_company}}</td>
            <td>{{s.name}}</td>
            <td>{{RepeatOptions[s.repeat_option]}}</td>
            <td class="d-none d-sm-table-cell">
              {{ s.date | date: 'EEE M/d/yy'}}
            </td>
            <td class="text-right">{{ s.price | currency: 'USD'}}</td>
            <td>
              <span
                class="cdi-s-trash float-right pointer"
                (click)="delete(s,false)"></span>
              <span
                class="cdi-s-edit float-right pointer"
                (click)="modalStandingOrder.show(s)"></span>
              <span
                class="cdi-s-play float-right pointer"
                (click)="togglePause(s)"></span>
            </td>
          </tr>
        </tbody>

        <tbody></tbody>
      </table>

      <p class="m-3">
        Standing orders are automatically generated purchase orders for items
        you regularly order. For example, a coffee shop may take 5 dozen
        pastries every morning from their local baker. Entering these PO's
        manually every morning is tiresome, yet not entering them will leave
        their cost out of your COGS.
      </p>
    </div>
  </div>
</div>
