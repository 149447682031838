import * as moment from 'moment';

export const BasePricePerMonth = 49.0;
export const IntegrationPricePerMonth = 79.0;

export enum ActionType {
  Create = 1,
  Read = 2,
  Update = 3,
  Delete = 4,
}

export enum ResultType {
  Failure = 0,
  Success = 1,
}

export interface IResult {
  /** Name */
  __status: ResultType;
  __message?: string;
  __properties?: any;
}

export enum ApiServerMode {
  Dev = 1,
  Secure = 2,
}

export enum ChecklistItemType {
  Check = 1,
  Number = 2,
}

export enum ChecklistAssignmentType {
  User = 1,
  Position = 2,
}

export enum DaysOfWeek { // This has to match javascript days
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const DaysOfWeekKeys = [0, 1, 2, 3, 4, 5, 6];

export enum TradeType {
  GiveUp = 1, // Giving this shift up..
  Pickup = 2, // I'd like a shift..
  Trade = 3, // 1 to 1 trade
}

export const TradeTypeNames = [
  null,
  'Give Up',
  'Trade for any Shift',
  'Trade for specific Shift ',
];

export enum SkuUnit {
  Quantity = 0,
  Grams = 1,
  Oz = 2,
  Lbs = 3,
  FluidOz = 4,
}

export const SkuUnitNames = ['Quantity', 'Grams', 'Oz', 'Lbs', 'Fluid Oz'];

export enum BillingPaymentOption {
  Comped = 0,
  PrePay = 1,
  ACH = 2,
  Check = 3,
}

export enum BillingPlanType {
  Monthly = 1,
  Invoice = 2,
  Promo = 3,
}

export enum EventType {
  Event = 1,
  Task = 2,
}

export enum LocationStatus {
  Live = 1,
  Cancelled = 2,
}

export enum LogNoteType {
  General = 1,
  Feedback = 2,
  Issues = 3,
  Repair = 4,
}

export const LogNoteNames = [
  null,
  'General Notes',
  'Feedback, Complaints & Customer Issues',
  'Employee Issues',
  'Repair & Maintenance',
];

export enum MediaType {
  Image = 1,
}

export enum PackageType {
  Cancelled = 0,
  Freemium = 1,
  Normal = 2,
  Pro = 3,
}

export const PackageTypeNames = ['Cancelled', 'Basic', 'Plus', 'Pro'];

export enum CancellationDetails {
  'too_expensive' = 'It’s too expensive',
  'missing_features' = 'Some features are missing',
  'switched_service' = 'I’m switching to a different service',
  'unused' = 'I don’t use the service enough',
  'customer_service' = 'Customer service was less than expected',
  'too_complex' = 'Ease of use was less than expected',
  'low_quality' = 'Quality was less than expected',
  'other' = 'Other',
}

//Lavu = 1,
//Square = 2,
//Omnivore = 3,
export enum SalesIntegrationType {
  None = 0,
  Cake = 1,
  Square = 2,
  Clover = 3,
  // Omnivore
  Dinerware = 4,
  MaitreD = 5,
  Micros3700 = 6,
  MicrosSimphony = 7,
  MicrosSimphonyFE = 8,
  Aloha = 9,
  POSitouch = 10,
  Squirrel = 11,
  Xpient = 12,
  Brink = 13,
  Doshii = 14,
  Lavu = 15,
  NCRCloudConnect = 16,
  Northstar = 17,
  Toast = 18,
  // Others.
  Lightspeed = 19,
  WineDirect = 20,
  Heartland = 21,
}

export enum SalesIntegrationAuthType {
  Oauth = 1,
  Token = 2,
}

export enum SalesIntegrationShiftSyncType {
  None = 0,
  Import = 1, // Import from POS
  Export = 2, // Export to POS
}

export interface ISalesIntegrationFeature {
  id: SalesIntegrationType;
  name: string;
  image: string;
  auth_type: SalesIntegrationAuthType;
  omnivore: boolean;
  omnivore_app: boolean;
  sales: boolean;
  employees: boolean;
  payrate: boolean;
  write_schedule: boolean;
  read_schedule: boolean;
  timeclock: boolean;
  breaks: boolean;
  employee_data: string;
  shift_data: string;
  sales_data: string;
  confirmation_data: string;
}

export const SalesIntegrationFeatures: ISalesIntegrationFeature[] = [
  null,
  {
    // Cake
    id: SalesIntegrationType.Cake,
    name: 'Cake',
    image: 'cake-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: false,
    omnivore_app: false,
    sales: true,
    employees: true,
    payrate: false,
    write_schedule: false,
    read_schedule: true,
    timeclock: true,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. Once the data has been imported, you will be able to confirm which users you are importing and add email information onto their account if none was included in the import. </p>
<p>We will match imported employees to users in CheddrSuite by email address. If a different email address or no email address is used in your POS, then a duplicate user may be created. Data received from the POS will not overwrite data for users that already exist.</p>
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS</li>
        <li>Pay rate - will not be imported, but we will be able to calculate how much an employee made per shift while the time-clocks segment is turned on. We will have a very close average for the employee's wage</li>
        <li>Jobs - the user's job description will be imported.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges</li>
<li>Tips - Cheddr will only import declared tips, not card tips</li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>If your POS has the data, Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>

<p>Time-clocks</p>
<ul class="list-style-disc ml-4">
        <li>Time-clocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your CAKE integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Both Cake and CheddrSuite have been alerted and the integration process and will immediately begin. You may be contacted to provide your Cake account ID. More information is provided in the email. You will receive another email when the import has finished. Please allow up to 2 - 3 business days to complete the process.</p>
	<p>Once the initial import is complete, you will be able to select which employees to import into Cheddr and add or update email address and phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>`,
  },
  {
    // Square
    id: SalesIntegrationType.Square,
    name: 'Square',
    image: 'square-pos.png',
    auth_type: SalesIntegrationAuthType.Oauth,
    omnivore: false,
    omnivore_app: false,
    sales: true,
    employees: true,
    payrate: true,
    write_schedule: true,
    read_schedule: true,
    timeclock: true,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. Once the data has been imported, you will be able to confirm which users you are importing and add email information onto their account if none was included in the import. </p>
<p>We will match imported employees to users in CheddrSuite by email address. If a different email address or no email address is used in your POS, then a duplicate user may be created. Data received from the POS will not overwrite data for users that already exist.</p>
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS</li>
        <li>Pay rate - we will import hourly employee's pay rate. If you want to switch an employee to salary, you must do so in their CheddrSuite profile.</li>
        <li>Jobs - the user's job description will be imported.</li>
        <li>System role - users will be imported at the closest system role ie. managers will be granted manger permissions</li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>If your POS has the data, Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>
<p>Export to Square</p>
<ul class="list-style-disc ml-4">
        <li>If you choose this option, you can export schedules that you have created in CheddrSuite to Square when you click the Publish Schedule button.</li>
</ul>
<p>Time-clocks</p>
<ul class="list-style-disc ml-4">
        <li>Time-clocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly. The amount will insert into the log and is defined by Square as "the amount of money processed for this payment, not including tip money."</p>`,
    confirmation_data: `<p>Thank you for setting up your Square integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with more information. Please be sure to check your junk or promotions folders. </p>
	<p>If you elected to import staff members (employee data), you will receive another email when the import has finished. Once the initial import is complete, you will be able to select which employees to import into Cheddr and add or update email address and phone numbers through the  <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p> 
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>`,
  },
  {
    // Clover
    id: SalesIntegrationType.Clover,
    name: 'Clover',
    image: 'clover-pos.png',
    auth_type: SalesIntegrationAuthType.Oauth,
    omnivore: false,
    omnivore_app: false,
    sales: true,
    employees: true,
    payrate: false,
    write_schedule: false,
    read_schedule: true,
    timeclock: true,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. Once the data has been imported, you will be able to confirm which users you are importing and add email information onto their account if none was included in the import. </p>
<p>We will match imported employees to users in CheddrSuite by email address. If a different email address or no email address is used in your POS, then a duplicate user may be created. Data received from the POS will not overwrite data for users that already exist.</p>
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS</li>
        <li>Pay rate - Clover does not export pay rate. We will import all users at the pay rate of $0/hour. Please change the pay-rate as needed.</li>
        <li>Jobs - Clover does not support the export of job titles.</li>
        <li>System role - users will be imported at the closest system role ie. managers will be granted manger permissions</li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>To import Shift data, you must be using the Shifts app through Clover. Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>

<p>Time-clocks</p>
<ul class="list-style-disc ml-4">
        <li>To import Shift data, you must be using the Shifts app through Clover. Time-clocks will be imported and updated hourly.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your Clover integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with more information. Please be sure to check your junk or promotions folders. </p>
	<p>If you elected to import staff members (employee data), you will receive another email when the import has finished. <p>Once the initial import is complete, you will be able to select which employees to import into Cheddr and add or update email address and phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Dinerware
    id: SalesIntegrationType.Dinerware,
    name: 'Dinerware',
    image: 'dinerware-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - Dinerware does not export pay rate. We will import all users at the pay rate of $0/hour. Please change the pay-rate as needed.</li>
        <li>Jobs - Dinerware does not export job types.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>Dinerware is unable to export data to CheddrSuite.</li>
</ul>
<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from Dinerware.</li>
</ul>
`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Maitre 'D
    id: SalesIntegrationType.MaitreD,
    name: "Maitre 'D",
    image: 'maitre-d-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: false,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: false,
    employee_data: '',
    shift_data: '',
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Micros 3700
    id: SalesIntegrationType.Micros3700,
    name: 'Micros 3700',
    image: 'micros.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: true,
    write_schedule: true,
    read_schedule: true,
    timeclock: true,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - we will import hourly employee's pay rate. If you want to switch an employee to salary, you must do so in their CheddrSuite profile.</li>
        <li>Jobs - the user's job description will be imported.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>If your POS has the data, Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>

<p>Export to Micros3700</p>
<ul class="list-style-disc ml-4">
        <li>If you choose this option, you can export schedules that you have created in CheddrSuite to Micros3700 when you click the Publish Schedule button. This option may require additional time to set up by the CheddrSuite team. Please email us at support@cheddrsuite.com if you have any difficulty.</li>
</ul>

<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite. </li>
</ul>
`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Micros Simphony
    id: SalesIntegrationType.MicrosSimphony,
    name: 'Micros Simphony',
    image: 'micros.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - Micros Simphony does not export pay rate. We will import all users at the pay rate of $0/hour. Please change the pay-rate as needed.</li>
        <li>Jobs - Micros Simphony does not export job types.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>Micros Simphony is unable to export data to CheddrSuite.</li>
</ul>

<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from Micros Simphony.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Micros Simphony FE
    id: SalesIntegrationType.MicrosSimphonyFE,
    name: 'Micros Simphony FE',
    image: 'micros.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - Micros Simphony FE does not export pay rate. We will import all users at the pay rate of $0/hour. Please change the pay-rate as needed.</li>
        <li>Jobs - Micros Simphony FE does not export job types.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>Micros Simphony FE is unable to export data to CheddrSuite.</li>
</ul>

<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from Micros Simphony FE.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Aloha
    id: SalesIntegrationType.Aloha,
    name: 'Aloha',
    image: 'aloha-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: true,
    write_schedule: true,
    read_schedule: true,
    timeclock: true,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - we will import hourly employee's pay rate. If you want to switch an employee to salary, you must do so in their CheddrSuite profile.</li>
        <li>Jobs - the user's job description will be imported.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>If your POS has the data, Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>

<p>Export to Aloha</p>
<ul class="list-style-disc ml-4">
        <li>If you choose this option, you can export schedules that you have created in CheddrSuite to Aloha when you click the Publish Schedule button.This option may require additional time to set up by the CheddrSuite team. Please email us at support@cheddrsuite.com if you have any difficulty.</li>
</ul>

<p>Time-clocks</p>
<ul class="list-style-disc ml-4">
        <li>Time-clocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>
<p>Aloha provides us a report of all tickets from the previous business day from 12 am to the previous 12 am period. Any tickets generated after 12 am from today will be in tomorrow's data update.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // POSitouch
    id: SalesIntegrationType.POSitouch,
    name: 'POSitouch',
    image: 'positouch-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: true,
    write_schedule: true,
    read_schedule: true,
    timeclock: true,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - we will import hourly employee's pay rate. If you want to switch an employee to salary, you must do so in their CheddrSuite profile.</li>
        <li>Jobs - the user's job description will be imported.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>If your POS has the data, Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>

<p>Export to POSitouch</p>
<ul class="list-style-disc ml-4">
        <li>If you choose this option, you can export schedules that you have created in CheddrSuite to POSitouch when you click the Publish Schedule button.This option may require additional time to set up by the CheddrSuite team. Please email us at support@cheddrsuite.com if you have any difficulty.</li>
        <li>This will only post for the current week and next week.</li>
</ul>

<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Squirrel
    id: SalesIntegrationType.Squirrel,
    name: 'Squirrel',
    image: 'squirrel-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: false,
    employees: true,
    payrate: true,
    write_schedule: false,
    read_schedule: false,
    timeclock: true,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - we will import hourly employee's pay rate. If you want to switch an employee to salary, you must do so in their CheddrSuite profile.</li>
        <li>Jobs - Squirrel POS does not export job types.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>
`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>Squirrel is unable to export data to CheddrSuite.</li>
</ul>

<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from Squirrel.</li>
</ul>`,
    sales_data: '',
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // XPIENT
    id: SalesIntegrationType.Xpient,
    name: 'XPIENT',
    image: 'xpient-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - XPIENT does not export pay rate. We will import all users at the pay rate of $0/hour. Please change the pay-rate as needed.</li>
        <li>Jobs - XPIENT does not support the export of job titles</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>
`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>XPIENT does not support the export of shift data</li>
</ul>


<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from XPIENT.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Brink
    id: SalesIntegrationType.Brink,
    name: 'Brink',
    image: 'brink-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: false,
    sales: true,
    employees: true,
    payrate: true,
    write_schedule: true,
    read_schedule: true,
    timeclock: true,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - we will import hourly employee's pay rate. If you want to switch an employee to salary, you must do so in their CheddrSuite profile.</li>
        <li>Jobs - the user's job description will be imported.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>
`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>If your POS has the data, Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>

<p>Export to Brink</p>
<ul class="list-style-disc ml-4">
        <li>If you choose this option, you can export schedules that you have created in CheddrSuite to Brink when you click the Publish Schedule button.</li>
</ul>

<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  null,
  /*{ // Doshii
	id: SalesIntegrationType.Doshii,
	name: 'Doshii',
	image: 'doshii.png',
	auth_type: SalesIntegrationAuthType.Token,
	omnivore: true,
	omnivore_app:	true,
	sales:	false,
	employees:	false,
	payrate:	false,
	write_schedule:	false,
	read_schedule: false,
	timeclock: false,
	breaks:	false,
	employee_data: '',
	shift_data: '',
	sales_data: '',
	confirmation_data: `<p>Thank you for setting up your POS integration!</p>
<p>You have completed the initial inegration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
<p>If you have elected to import employees, you will be able to onboard employees and update email address/phone numbers through the Staff > Import Staff section.</p> `,
},*/
  {
    // Lavu
    id: SalesIntegrationType.Lavu,
    name: 'Lavu',
    image: 'lavu.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - Lavu does not export pay rate. We will import all users at the pay rate of $0/hour. Please change the pay-rate as needed.</li>
        <li>Jobs - Lavu does not support the export of job titles</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>Lavu does not support the export of shift data</li>
</ul>


<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from Lavu.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // NCR Cloud Connect
    id: SalesIntegrationType.NCRCloudConnect,
    name: 'NCR Cloud Connect',
    image: 'ncr.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: false,
    employees: true,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS.</li>
        <li>Pay rate - NCR Cloud Connect does not export pay rate. We will import all users at the pay rate of $0/hour. Please change the pay-rate as needed.</li>
        <li>Jobs - NCR Cloud Connect does not support the export of job titles.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>NCR Cloud Connect does not support the export of shift data</li>
</ul>


<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from NCR Cloud Connect.</li>
</ul>`,
    sales_data: '',
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Northstar
    id: SalesIntegrationType.Northstar,
    name: 'Northstar',
    image: 'northstar-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: true,
    sales: true,
    employees: true,
    payrate: true,
    write_schedule: false,
    read_schedule: false,
    timeclock: true,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS. If you have multiple sites in Northstar, all will be pulled in.</li>
        <li>Pay rate - we will import hourly employee's pay rate. If you want to switch an employee to salary, you must do so in their CheddrSuite profile.</li>
        <li>Jobs - the user's job description will be imported. If you have multiple sites in Northstar, all will be pulled in.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>If your POS has the data, Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>


<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Toast
    id: SalesIntegrationType.Toast,
    name: 'Toast',
    image: 'toast-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: true,
    omnivore_app: false,
    sales: true,
    employees: true,
    payrate: true,
    write_schedule: false,
    read_schedule: false,
    timeclock: true,
    breaks: true,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Your Point of Sale does not provide email address or cell phone numbers. Many of CheddrSuite's features require employees to be able to log into the platform. For employees to log in, they will need an email address or phone number attached to their account. </p>
<p>Once you import your employee's data, you will be directed to the employee import confirmation page where you will be able to confirm which users you are importing and add email addresses or phone numbers onto their account. </p> 
<p>Imported Data Includes:</p>

<ul class="list-style-disc ml-4">
        <li>User Profile Data - we will import any data on the user that we are able to get from your POS. If you have multiple sites in Northstar, all will be pulled in.</li>
        <li>Pay rate - we will import hourly employee's pay rate. If you want to switch an employee to salary, you must do so in their CheddrSuite profile.</li>
        <li>Jobs - the user's job description will be imported.</li>
        <li>System role - users will be imported as an employee, please update CheddrSuite if the user needs higher privileges. </li>
</ul>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>If your POS has the data, Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
</ul>


<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will import up to two weeks of historical net sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
	<p>Please <a href="https://connect.omnivore.io/invite/CHEDDR">follow this link </a> to download the application onto your back office POS machine. Omnivore is a perfectly safe third party platform that allows us to talk directly to your Point of Sale system.</p>
	<p>Once you have successfully finished installing the omnivore application, please allow up to 2 business days for Cheddr to finish the integration process.</p>
	<p>When the connection process has finished, If you have elected to import employees, you will receive an email showing you how to complete the process by on-boarding employees and updating email address/phone numbers through the <a href="https://app.cheddrsuite.com/users/integration">Staff > Import Staff section page </a>.</p>
	<p>Once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While shift data is synced, it will keep in sync with your POS and any changes in Cheddr may be overwritten. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features.</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // Lightspeed
    id: SalesIntegrationType.Lightspeed,
    name: 'Lightspeed',
    image: 'lightspeed-pos.png',
    auth_type: SalesIntegrationAuthType.Oauth,
    omnivore: false,
    omnivore_app: false,
    sales: true,
    employees: false,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Employee Data is not available for import from Lightspeed</p>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>Lightspeed does not support the export of shift data</li>
</ul>


<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from Lightspeed.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical sales data, and continue to import data hourly.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
	<p>You have completed the initial integration set up process!</p>
	<p>Sales data should begin populating in the system immediately, but it may take up to an hour to complete the process.</p>`,
  },
  {
    // WineDirect
    id: SalesIntegrationType.WineDirect,
    name: 'Wine Direct',
    image: 'winedirect-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: false,
    omnivore_app: false,
    sales: true,
    employees: false,
    payrate: false,
    write_schedule: false,
    read_schedule: false,
    timeclock: false,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>Employee Data is not available for import from WineDirect</p>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
<p>Shift Data</p>
<ul class="list-style-disc ml-4">
        <li>WineDirect does not support the export of shift data</li>
</ul>


<p>Timeclocks</p>
<ul class="list-style-disc ml-4">
        <li>Timeclocks are not available for import from WineDirect.</li>
</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data once the Cheddr Support Team has received your login credentials, and continue to import data hourly. You will see each category of item sold your daily log and daily email.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
  <p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
  <p>Please <a href=https://documentation.vin65.com/Settings/Admin-Users/Webservice-Accounts#how-to-setup-a-webservice-account> follow this link </a> to see instructions on creating a webservice account for CheddrSuite to integrate with WineDirect. Once you have completed the details, please email us at support@cheddrsuite.com with the credentials that you have created.</p>
  <p>The Cheddr Support team will be able to connect CheddrSuite to WineDirect within one to two business days.</p>
  <p>Please message us at support@cheddrsuite.com, connect to us through live-chat or call us at 1 (855) 805-0722 if you need additional assistance setting up a webservice account.</p>`,
  },
  {
    // Heartland
    id: SalesIntegrationType.Heartland,
    name: 'Heartland',
    image: 'heartland-pos.png',
    auth_type: SalesIntegrationAuthType.Token,
    omnivore: false,
    omnivore_app: false,
    sales: true,
    employees: true,
    payrate: true,
    write_schedule: false,
    read_schedule: true,
    timeclock: true,
    breaks: false,
    employee_data: `<p><strong>Employee Data Sync Notes</strong></p>
<p>We will import employee information, including name, phone number, pay rate and position, if available.</p>`,
    shift_data: `<p><strong>Shift Data Sync Notes</strong></p>
	<p>Shift Data</p>
	<ul class="list-style-disc ml-4">
					<li>If your POS has the data, once you have confirmed which employees you are importing, we will begin importing shifts on a daily basis moving forward for the employees that you have imported into the system. While the data is synced, it will keep in sync with your POS. You may turn off the shift sync to use Cheddr’s shift system, which will allow you to schedule and use our shift trading features. </li>
	</ul>
	
	
	<p>Timeclocks</p>
	<ul class="list-style-disc ml-4">
					<li>Timeclocks will be imported and updated hourly. User breaks are not currently tracked in CheddrSuite.</li>
	</ul>`,
    sales_data: `<p><strong>Sales Data Sync Notes</strong></p>
<p>We will attempt  to import up to two weeks of historical net sales data once the Cheddr Support Team has received your API key, and continue to import data hourly. You will see each category of item sold your daily log and daily email.</p>`,
    confirmation_data: `<p>Thank you for setting up your POS integration!</p>
  <p>You have completed the initial integration set up process. We have sent you an email with further instructions. Please be sure to check your junk or promotions folders. </p>
  <p>Please generate an API key for CheddrSuite in your <a href=https://hrpos.heartland.us/#/integrations/integrations>Heartland POS admin portal's integrations page</a>. Once you have created the API key, please send it to us at support@cheddrsuite.com.</p>
  <p>The Cheddr Support team will be able to connect CheddrSuite to Heartland within one to two business days.</p>
  <p>Please message us at support@cheddrsuite.com, connect to us through live-chat or call us at 1 (855) 805-0722 if you need additional assistance.</p>`,
  },
];

export enum SalesIntegrationSyncShiftType {
  None = 0,
  Pull = 1,
  Push = 2,
}

export const ShiftHistoryNames = [null, 'Shift Created', 'Shift Edited'];

export enum SkuCostMethod {
  Neither = 0,
  Quantity = 1,
  Weight = 2,
}

export enum WidgetType {
  SalesThisWeek = 1,
  YTDQuarters = 2,
  YearToDate = 3,
  SalesPerWeek = 4,
  SalesLastWeek = 5,
  AvgSalesPerDayofWeek = 6,
}

export const WidgetNames = [
  null,
  'Sales This Week',
  'YTD Quarters',
  'Year To Date',
  'Sales/Week Last 30 Day',
  'Sales Last Week',
  'Average Sales By Week Day',
];

export enum RateType {
  Salary = 1,
  Hourly = 2,
}
export const InventoryUnits = [
  'Bag',
  'Basket',
  'Bottle',
  'Box',
  'Bucket',
  'Can',
  'Carton',
  'Case',
  'Container',
  'Each',
  'Flat',
  'Gallon',
  'Gram',
  'Jar',
  'Jug',
  'Keg',
  'Ounce',
  'Pallet',
  'Pound',
  'Portion',
  'Roll',
  'Sleeve',
];

export const RepeatOptionsKeys: number[] = [0, 1, 2, 3, 4, 5, 6];
export enum RepeatOptions {
  None = 0,
  Daily = 1,
  Weekly = 2,
  BiWeekly = 3,
  Monthly = 4,
  Quarterly = 5,
  Annually = 6,
}

export enum UserStatus {
  None = 0,
  Suspended = 1,
  Active = 10,
}

export enum LocationAccountType {
  Terminated = 0,
  Fremium = 10,
  Paid = 50,
}

export enum UserType {
  Anonymous = 0,
  Regular = 1,
  ShiftLead = 2,
  Manager = 3,
  Owner = 4,
  Admin = 100,
}

export enum ExpenseType {
  COGS = 1,
  FOH = 2,
  BOH = 3,
  Fixed = 4,
}

export enum IntegrationUserStatus {
  New = 1,
  Import = 2,
  Blocked = 3,
}

export enum LaborStatType {
  SalariedFOH = 1,
  SalariedBOH = 2,
  HourlyFOH = 3,
  HourlyBOH = 4,
  UnassignedFOH = 5,
  UnassignedBOH = 6,
}

export enum FixedCostType {
  Normal = 1,
}

export enum FixedCostPeriod {
  Daily = 1,
  Weekly = 2,
  Monthly = 4,
  Quarterly = 5,
  Annually = 6,
}

export enum InvoiceItemType {
  Regular = 1,
  PO = 2,
}

export enum LocationType {
  FullService = 1,
  FastFood = 2,
  FastCasual = 3,
  Truck = 4,
  Bar = 5,
  Hotel = 6,
}

export enum LocationLaborType {
  Timeclock = 1,
  Scheduled = 2,
  None = 3, // AKA FixedCost method
}

// This is setup contact
export enum LocationContactType {
  None = 0,
  Email = 1,
  Phone = 2,
  Full = 3, // We help you
}

export enum ScheduleSortOrder {
  Time = 1,
  Position = 2,
}

export enum ServiceTimeType {
  Daily = 0, // This makes my life really easy..
  Breakfast = 1,
  Lunch = 2,
  Dinner = 3,
}
export const LocationTypeName = [
  null,
  'Full Service',
  'Fast Food',
  'Fast Casual',
  'Bar/Lounge',
  'Truck/Mobile',
  'Resort/Hotel',
];

export const LocationCategoryNameDef = [
  'American',
  'Bakery',
  'BBQ',
  'Chinese',
  'Coffee/Tea',
  'Deli & Sandwich',
  'Dessert & Ice Cream',
  'Ethiopian/African',
  'Diner',
  'German',
  'Greek',
  'Indian',
  'Italian',
  'Mexican',
  'Middle Eastern',
  'None',
  'Other',
  'Pizza',
  'Thai',
  'Seafood',
  'Steakhouse',
  'Sushi/Japanese',
  'Vietnamese',
];

export const LocationTypeGoalDefaults = [
  null,
  {
    // Full Service
    foh_goal: 16,
    boh_goal: 16,
    cogs_goal: 32,
    fixed_goal: 8,
    exp_goal: 12,
    payroll_goal: 12,
  },
  {
    // Fast Food
    foh_goal: 12,
    boh_goal: 12,
    cogs_goal: 25,
    fixed_goal: 6,
    exp_goal: 12,
    payroll_goal: 10,
  },
  {
    // Fast Casual
    foh_goal: 14,
    boh_goal: 14,
    cogs_goal: 32,
    fixed_goal: 6,
    exp_goal: 12,
    payroll_goal: 10,
  },
  {
    // Truck
    foh_goal: 12,
    boh_goal: 10,
    cogs_goal: 25,
    fixed_goal: 3,
    exp_goal: 12,
    payroll_goal: 10,
  },
  {
    // Bar
    foh_goal: 15,
    boh_goal: 0,
    cogs_goal: 15,
    fixed_goal: 8,
    exp_goal: 8,
    payroll_goal: 10,
  },
  {
    // Hotel
    foh_goal: 15,
    boh_goal: 0,
    cogs_goal: 15,
    fixed_goal: 8,
    exp_goal: 8,
    payroll_goal: 10,
  },
];

export const LocationCategoryName = [
  null,
  LocationCategoryNameDef,
  LocationCategoryNameDef,
  LocationCategoryNameDef,
  ['Beer & Wine', 'Full Liquor', 'Wine Bar'],
  LocationCategoryNameDef,
  LocationCategoryNameDef,
];

export enum NotificationDevMode {
  None = 0,
  Dev = 1,
  Prod = 2,
}

export enum NotificationType {
  BusinessRegistration = 1,
  UnverifiedUser = 2, // Sign up new users.
  PasswordReset = 3,
  SchedulePublished = 4,
  EventMessage = 5,
  EventReminder = 6, // Text Message
  Message = 7, // (if selected), (text message take the first 900 + other bits.)
  Writeup = 8,
  TimeOffRecipt = 9,
  TimeOffApprovedDenied = 10,
  PurchaseOrder = 11,
  DailyFull = 12, // Included payroll as pdf.
  FreemiumEnding = 13,
  MonthlyBillingSuccess = 14,
  MonthlyBillingFailure = 15,
  ScheduleStats = 16,
  TradeBoardOwnShift = 17, // Posted my shift
  TradeBoardPosting = 18, // Available to user
  TradeNeedsApproval = 19, // Send to admin for approval
  TradeBoardPickup = 20, // Dunno.
  TradeBoardPendingRequest = 21, // Pending Request on other user to approve
  TradeRequestApprovedDenied = 22, // Fire on approved / denied.
  SchedulePublishedDetails = 23,
  MontlyTextLimitReached = 24,
  NewIntegration = 25,
  AddExistingUser = 26, // Add existing user
  IntegrationFirstUsers = 27, // First integration Email
  IntegrationNewUser = 28, // integration New User(s) Email
  FreemiumEndingNoCC = 29, // Free mode is ending, no CC & will be billed
  MonthlyBillingCancel = 30, // Account Cancelled.
  Checklist = 31,
  ScheduleReminder = 32,
  ChangePackageType = 33,
  SubscriptionPaymentFailed = 34,
  UpdateError = 35,
}

export const NotificationTypeDataHints = [
  null,
  {},
  { token: 'string' },
  { token: 'string' }, // Password reset
  { schedule: { isUpdate: 'boolean', week: 'date', note: 'string' } }, // Sched Pub
  {
    event: {
      name: 'string',
      event_id: 'number',
      event_type: 'number',
      description: 'string',
      due: 'date',
      repeat_option: 'number',
      repeat_option_name: 'string',
    },
  }, // Event Message
  {
    event: {
      name: 'string',
      event_id: 'number',
      event_type: 'number',
      event_type_name: 'string',
      description: 'string',
      due: 'date',
      repeat_option: 'number',
      repeat_option_name: 'string',
    },
  }, // Event reminder
  {
    fromUser: { firstname: 'string', lastname: 'string' },
    message: {
      id: 'number',
      has_attachment: 'boolean',
      is_single_user: 'boolean',
      title: 'string',
      body: 'string',
    },
  }, // Message,
  {}, // Writeup
  {
    time_off: {
      start_date: 'date',
      end_date: 'date',
      whole_day: 'boolean',
      notes: 'string',
    },
  }, // Time off receipt
  {
    time_off: {
      start_date: 'date',
      end_date: 'date',
      action: 'Approvved || Denied',
    },
  }, //TimeOffApprovedDenied
  { data: { number: 'number' } }, // PurchaseOrder
  {
    data: {
      goal: 'number',
      sales: { today: 'number', lastYear: 'number' },
      events: [
        {
          start_date: 'date',
          end_date: 'date',
          name: 'string',
          notes: 'string',
          color: 'string',
          date: { start: 'date', end: 'date' },
        },
      ],
      tasks: [
        {
          start_date: 'date',
          end_date: 'date',
          name: 'string',
          notes: 'string',
          is_repair: 'boolean',
          color: 'string',
          date: { start: 'date', end: 'date' },
        },
      ],
      messages: [
        {
          created_at: 'date',
          title: 'string',
          body: 'string',
          firstname: 'string',
          lastname: 'string',
        },
      ],
    },
    full_logs: {
      logs: [
        {
          service_time_id: 'number',
          service_time_name: 'string',
          date: 'date',
          custom_fields: 'string[]',
        },
      ],
      total: { custom_fields: 'string[]', net_sales: 'number' },
    },
  }, // DailyFull
  { end: 'date', has_cc: 'boolean' }, // FreemiumEnding
  {
    date: 'date',
    locations: [{ amount: 'string', packageName: 'string', name: 'string' }],
  }, // MonthlyBillingSuccess
  {
    date: 'date',
    locations: [{ amount: 'string', packageName: 'string', name: 'string' }],
  }, // MonthlyBillingFailure
  {}, // ScheduleStats				WARNING I think this one is just missing.
  { shift: { start: 'date', end: 'date' } }, // TradeBoardOwnShift
  { shift: { start: 'date', end: 'date' } }, // TradeBoardPosting  (Needs to go to everyone WARNING Needs Q/A)
  { shift: { start: 'date', end: 'date' } }, // TradeNeedsApproval
  { shift: { start: 'date', end: 'date' } }, // TradeBoardPickup
  { shift: { start: 'date', end: 'date' } }, // TradeBoardPendingRequest
  {
    shift: {
      start: 'date',
      end: 'date',
      final_approval: 'date',
      reject: 'date',
    },
  }, // TradeRequestApprovedDenied
  {
    schedule: { isUpdate: 'boolean', week: 'date', note: 'string' },
    stats: {
      estSales: 'number',
      budget: 'number',
      hours: 'number',
      estCost: 'number',
    },
  }, // SchedulePublishedDetails
  {}, // MontlyTextLimitReached
  {
    id: 'number',
    name: 'string',
    image: 'string',
    auth_type: 'number',
    omnivore: 'boolean',
    omnivore_app: 'boolean',
    sales: 'boolean',
    employees: 'boolean',
    payrate: 'boolean',
    write_schedule: 'boolean',
    read_schedule: 'boolean',
    timeclock: 'boolean',
    breaks: 'boolean',
  }, // New Sales Integration
  {}, // AddExistingUser
  {
    users: {
      firstname: 'string',
      lastname: 'string',
      email: 'string',
      phone: 'string',
      pin: 'string',
    },
  }, // Integration First Users
  {
    users: {
      firstname: 'string',
      lastname: 'string',
      email: 'string',
      phone: 'string',
      pin: 'string',
    },
  }, // Integration New User(s)
  { end: 'date', has_cc: 'boolean' }, // FreemiumEnding	Couldn't bill Success
  {}, // Billing Cancel Location.
  {
    checklists: [
      {
        location: 'string',
        name: 'string',
        start: 'Date',
        end: 'Date',
        items: [{ name: 'string', type: 'string' }],
      },
    ],
  }, // Checklist
];

/*export const NotificationTypeNames = [
	'',		// We have no 0 index on notificationTypes.
	'Notify staff when a new schedule is published.',
	'Notify staff when a new schedule is un-published.',
	'Notify staff when their schedule changes.',
	'Notify staff when their own shift is posted on the trade board',
	'Notify management when a shift is added to the trade board.',
	'Notify managemenr when a shift trade needs approval.',
	'Notify staff when a shift they are eligible to pickup is posted to the trade board',
	'Notify staff when they have a pending trade request, both staff members',
	'Notify staff when their trade requests are approved or denied, both staff members',
	'Notify staff when their time off request is received',
	'Notify staff when their time off request is approved or denied',
];*/

export enum Permission {
  LogShiftData = 1,
  EditEvent = 2,
  EditTask = 3,
  EditInvoices = 4,
  PostWallMessage = 5,
  EditSchedule = 6,
  PublishSchedule = 7,
  ApproveTimeOff = 8,
  ApproveTrade = 9,
  EditTimeCard = 10,
  ApproveUnschedClockIn = 11,
  AuthorizeDeviceAsTimeClock = 12,
  ManageDoc = 13,
  EditStaff = 14,
  EditHourlyWages = 15,
  EditSalaries = 16,
  ManageInventory = 17,
  CountInventory = 18,
  EditPO = 19,
  ViewVendor = 20,
  EditVendor = 21,
  CCPO = 22,
  ViewSales = 23,
  CreateBudget = 24,
  ReceiveDigest = 25,
  ReceiveLaborDigest = 26,
  SendSchedStatsWhenPublished = 27,
  AccessSettings = 28,
  EditEmployeePermission = 29,
  ViewUnpublishedSchedule = 30,
  ReceiveIntegrationNotifications = 31,
}

export const PermissionCategory = {
  Staff: [
    Permission.LogShiftData,
    Permission.EditEvent,
    Permission.EditTask,
    Permission.EditInvoices,
    Permission.PostWallMessage,
  ],
  'Scheduling & Time Clock': [
    Permission.EditSchedule,
    Permission.PublishSchedule,
    Permission.ApproveTimeOff,
    Permission.ApproveTrade,
    Permission.EditTimeCard,
    Permission.ApproveUnschedClockIn,
    Permission.AuthorizeDeviceAsTimeClock,
    Permission.ViewUnpublishedSchedule,
  ],
  HR: [
    Permission.ManageDoc,
    Permission.EditStaff,
    Permission.EditHourlyWages,
    Permission.EditSalaries,
  ],
  Inventory: [
    Permission.ManageInventory,
    Permission.CountInventory,
    Permission.EditPO,
    Permission.ViewVendor,
    Permission.EditVendor,
    Permission.CCPO,
  ],
  Financial: [Permission.ViewSales, Permission.CreateBudget],
  Email: [
    Permission.ReceiveDigest,
    Permission.ReceiveLaborDigest,
    Permission.SendSchedStatsWhenPublished,
    Permission.ReceiveIntegrationNotifications,
  ],
  Manager: [Permission.AccessSettings, Permission.EditEmployeePermission],
};

export const PermissionNames = [
  '',
  'Log Shift Data',
  'Add/Edit Events',
  'Add/Edit Tasks',
  'Add/Edit Invoices',
  'Post Messages to Wall',
  'Create/Edit Schedule',
  'Publish/Unpublish Schedule',
  'Add/Approve Time Off',
  'Approve Trade Requests',
  'Add/Edit Time Cards',
  'Approve Unscheduled Clockins',
  'Authorize Device as TimeClock',
  'Upload/Edit/Manage Docs & Quizzes',
  'Add/Edit Staff',
  'View/Edit Hourly Wages',
  'View/Edit Salaries',
  'Manage Inventory',
  'Count Inventory',
  'Create/Edit/Receive Purchase Order',
  'View Vendor Contact Info',
  'Edit Vendor Info',
  'CC on Purchase Orders',
  'View Financial/Sales Data',
  'Create Sales Goals/Budgets',
  'Receive Daily Digest',
  'Attach Detailed Labor Report to Digest',
  'Send Detailed Schedule Stats Upon Publishing',
  'Access Settings Tab',
  'Adjust Individual Staff Permissions',
  'View Unpublished Schedules',
  'Receive Integration Notifications',
];

export function AllowPermission(permission: boolean[], which: number): boolean {
  switch (which) {
    case Permission.PublishSchedule:
      return permission[Permission.EditSchedule];
    case Permission.EditHourlyWages:
    case Permission.EditSalaries:
      return permission[Permission.EditStaff];
    case Permission.EditVendor:
      return permission[Permission.ViewVendor];
    case Permission.CreateBudget:
      return permission[Permission.ViewSales];
    case Permission.ReceiveLaborDigest:
      return permission[Permission.ReceiveDigest];
    default:
      return true;
  }
}

export enum PurchaseOrderStatus {
  Approved = 1,
  Paused = 2,
  Pending = 3,
  Received = 4,
  Standing = 5,
}

export enum PositionType {
  FrontOfHouse = 1,
  BackOfHouse = 2,
}

export enum OverTimeType {
  None = 0,
  Weekly = 0x01,
  Daily = 0x02,
  Both = 0x03,
}

export enum PublishLogType {
  Unpublish = 0,
  Publish = 1,
}

export enum FrequencyOfReview {
  None = 0,
  Monthly = 1,
  Quarterly = 2,
  SemiAnnually = 3,
  Annually = 4,
}

export const FrequencyOfReviewKeys = [0, 1, 2, 3, 4];
export const FrequencyOfReviewName = [
  'None',
  'Monthly',
  '3 Months',
  '6 Months',
  'Yearly',
];

export interface ICCInfo {
  amount?: number;
  location_id?: number;
  card_type: string;
  cardholder_name: string;
  card_number: string;
  exp_date: string;
  cvv: string;
}

export interface IIdentifiable {
  id?: number;
  created_at?: Date;
  updated_at?: Date;
  _edit?: any; // This is used to edit objects and keep typescript happy.
  _can_delete?: boolean;
  _selected?: boolean;
}

// This is for internal usage only..

export interface IApiLocation {
  id: number;
  name: string;
  status: LocationStatus;
  billing_plan_type: BillingPlanType; // This is off the billing record.
  package_type: PackageType;
  payment_status: boolean;
  user_type: UserType;
  user_status: UserStatus;
  widgets: number[];
  pin: number;
  zip: number;
  permissions: boolean[];
  is_service_time_daily: boolean;
  has_finances: boolean;
  has_inventory: boolean; // DEPRECTAED WARNING Use the settings instead
  block_freemium_warning: boolean;
  block_credit_card_warning: boolean;
  allow_preferences: boolean; // DEPRECATED Use the settings instead.
  has_houses: boolean; // Switch FOH/BOH on/off
  segment_houses: boolean; // And do we need to segment them?
  has_budgets: boolean;
  has_tip_reporting: boolean;
  require_w4: boolean;
  require_i9: boolean;
  week_start: number;
  foh_goal: number;
  boh_goal: number;
  cogs_goal: number;
  fixed_goal: number;
  payroll_goal: number;
  exp_goal: number;
  location_type: number;
  sales_integration_type: number;
  sales_integration_location_id: string;
  dark_days: boolean[];
  to_days: number;
  print_schedule_allow_phone: boolean;
  level_1: string;
  level_2: string;
  level_3: string;
  sched_color: string;
  sched_sort_order: number;
  max_labor: number[];
  min_labor: number[];
  labor_type: number;
  payroll_day: number;
  jail_writeup?: boolean;
  jail_doc?: boolean;
  jail_profile?: boolean;
  jail_upload?: boolean;
  jail_no_package?: boolean;
  has_picked_package?: boolean;
  free_until?: Date;
  iso: string; // Timezone iso string.
  has_image: string;
  cancel_at: number | null;
  canceled_at: number | null;
}

// External usage
export interface IApiUser {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  has_image?: string;
  cell?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  is_super: boolean;
  jail_bad_password?: boolean;
  location_index: number;
  locations: IApiLocation[];
  color: string;
}

export interface ITimeClockUser {
  location_id?: number; // Filled out by the middle ware.
  user_id?: number;
  pin?: string;
  password?: string;
  shift_id: number;
  approver_email?: string;
  approver_password?: string;
}

export interface IUserPermission extends IApiUser {
  location_id?: number; // It's a timeclock.
}

// This is what the user gets serialized to.
//export interface IInternalApiUser extends IApiUser{
/*username: string;
	firstname: string,
	lastname: string,
	pin: string,
  //timezone_id: number;
}*/

export interface IBlackOut extends IIdentifiable {
  start: Date;
  end: Date;
  location_id: number;
}

export interface IBilling extends IIdentifiable {
  created_at?: Date;
  amt: number;
  exp: string;
  cardtype: string;
  name: string;
  bank_message: string;
  bank_code: number;
  package_type?: number;
  new_package_type?: number;
  token_returned: string;
  last_four: string;
  location_id: number;
  response: string;
}

export interface IBillingPlan extends IIdentifiable {
  created_at?: Date;
  is_default?: boolean;
  is_active?: boolean;
  billing_plan_type?: BillingPlanType;
  date_end?: Date;
  code: string;
  name: string;
  description: string;
  package_1: number;
  package_2: number;
  package_3: number;
}

export interface IChecklist extends IIdentifiable {
  location_id?: number;
  name: string;
  start: Date; // Time
  end: Date; // Time
  days: boolean[];
  active: boolean;
  has_notification: boolean;
  has_manager_notification: boolean;
  has_assignments: boolean;
  has_position_assignments: boolean;
}

export interface IChecklistUserMap extends IIdentifiable {
  location_id?: number;
  checklist_id?: number;
  user_id?: number;
}

export interface IChecklistPositionMap extends IIdentifiable {
  location_id?: number;
  checklist_id?: number;
  position_id?: number;
}

export interface IChecklistItem extends IIdentifiable {
  checklist_id: number;
  name: string;
  order_by: number;
  days: boolean[];
  checklist_item_type: number;
  min?: number;
  max?: number;
}

export interface IChecklistCompleted extends IIdentifiable {
  checklist_id: number;
  status: boolean;
  date: Date;
}

export interface IChecklistItemCompleted extends IIdentifiable {
  checklist_id: number;
  checklist_item_id: number;
  checklist_completed_id: number;
  user_id: number;
  data?: number;
}

export interface IClearedEvent extends IIdentifiable {
  event_id: number;
  date: Date;
}

export interface IClearedTask extends IIdentifiable {
  task_id: number;
  date: Date;
}

export interface IContact extends IIdentifiable {
  location_id: number;
  company: string;
  name?: string;
  title?: string;
  address?: string;
  phone?: string;
  cell?: string;
  fax?: string;
  email?: string;
  contact_group_id: number;
  notes?: string;
  tags?: string;
  is_vendor?: boolean;
  hide?: boolean;
  order_notes?: string;
}

export interface IContactGroup extends IIdentifiable {
  location_id: number;
  name: string;
}

/*export interface ICreditCardInfo  {
	first_name: string,
	last_name: string,
	cc_num: string,
	month: number,
	year: number,
	amt?: number
}*/

export interface ICustomLogField extends IIdentifiable {
  location_id: number;
  name: string;
  service_time_id: number;
  days: boolean[];
}

export interface ICustomer extends IIdentifiable {
  phone: string;
  count_check_ins: number;
  credits_used: number;
}

export interface ICustomerCheckIn extends IIdentifiable {
  created_at: Date;
  phone: string;
}

export interface IDailySalesGoal extends IIdentifiable {
  location_id: number;
  goal: number;
  date: Date;
}

export interface IDoc extends IIdentifiable {
  location_id: number;
  name: string;
  path: string;
  pathThumb: string;
  mime_type: string;
  salt: string;
  doc_group_id: number;
  signature: boolean;
  quiz: boolean;
  quizReady: boolean;
  video: string;
  mandatory: boolean;
  has_user_permissions: boolean;
  i9: boolean;
  w4: boolean;
  source_name: string;
}

export interface IDocUserMap extends IIdentifiable {
  doc_id: number;
  user_id: number;
  location_id: number;
}

export interface IDocGroupPermission extends IIdentifiable {
  doc_group_id: number;
  position_id?: number;
  user_id?: number;
}

export interface IDocGroup extends IIdentifiable {
  location_id: number;
  name: string;
  is_everyone?: boolean;
  is_foh?: boolean;
  is_boh?: boolean;
  level_1?: boolean;
  level_2?: boolean;
  level_3?: boolean;
  position_ids?: number[];
  user_ids?: number[];
}

export interface IEmailLog extends IIdentifiable {
  user_id: number;
  location_id: number;
  body: string;
  timestamp: Date;
  email: string;
  subject: string;
  from: string;
}

export interface IEmailQueue extends IIdentifiable {
  user_id: number;
  type: string;
  object_id: string;
  time: Date;
  location_id: number;
}

export interface IEmployeePosition extends IIdentifiable {
  user_id: number;
  location_id: number;
  position_id: number;
  rate: number;
  rate_type: number;
  is_primary: boolean;
  start: Date;
  end?: Date;
  active: boolean;
}

export interface IErrorLog extends IIdentifiable {
  type: string;
  error: string;
  ip: string;
}

export interface IEvent extends IIdentifiable {
  event_type: number;
  location_id: number;
  start_date: Date;
  end_date: Date;
  name: string;
  notes?: string;
  repeat_option: number;
  text_alert: boolean;
  text_alert_days?: number;
  email_alert: boolean;
  email_alert_days?: number;
  text_time?: Date;
  email_time?: Date;
  text_sent?: boolean;
  email_sent?: boolean;
  repeat_until?: Date;
  is_repair?: boolean;
  color: string;
  start_local?: Date;
  end_local?: Date;
}

export interface IEventAssignment extends IIdentifiable {
  event_id: number;
  user_id?: number;
  position_id?: number;
}

export interface INotification extends IIdentifiable {
  notification_type: number;
  is_active?: boolean;
  subject: string;
  to: string;
  email_html?: string;
  email_text?: string;
  sms_text?: string;
  attachment_name?: string;
}

export interface INotificationLog extends IIdentifiable {
  location_id: number;
  event_id?: number;
  date: Date;
  is_sms: boolean;
  is_email: boolean;
  sms_result?: string;
  email_result?: string;
}

export interface IExpenseAccount extends IIdentifiable {
  location_id: number;
  name: string;
  expense_type: number;
}

export interface IFixedCost extends IIdentifiable {
  location_id: number;
  name: string;
  amt: number;
  period: number; // FixedCostPeriod
  start: Date;
  end: Date;
  type: number; // FixedCostType
  expense_account_id: number;
}

export interface IInventoryCount extends IIdentifiable {
  location_id: number;
  count: number;
  date: Date;
  sku_id: number;
  sku_location_id: number;
}

export interface IInventoryReport extends IIdentifiable {
  buying_unit: string;
  name: string;
  price: number;
  qty_inner_units: number;
  quantity: number;
  sku_num: string;
  sub_unit: string;
  total: number;
  expense_account_id: number;
}

export interface IInventoryLocation extends IIdentifiable {
  name: string;
  location_id: number;
  order_by?: number;
}

export interface IInvoice extends IIdentifiable {
  contact_id: number;
  location_id: number;
  date: Date;
  ponumber: number;
  terms: number; // null is due on delivery.
}

export interface IInvoiceItem extends IIdentifiable {
  invoice_id: number;
  expense_account_id: number;
  amount: number;
  name: string;
}

export interface IItem extends IIdentifiable {
  square_id?: number;
  name: string;
  location_id: number;
  sq_image_url?: string;
  has_image?: string;
  item_category_id?: number;
}

export interface IItemCategory extends IIdentifiable {
  location_id: number;
  square_cat_id?: number;
  name: string;
}

export interface IItemRecipe extends IIdentifiable {
  item_id: number;
  item_varitation_id: number;
  sku_id: number;
  recipe_block_id: number;
  qty: number;
  unit: number;
}

export interface IItemVariation extends IIdentifiable {
  location_id: number;
  square_id: string;
  item_id: number;
  name: string;
  price: number;
}

export interface ILogSegmentedTotals {
  date: Date;
  service_time_type: number;
  max_temp: number;
  sales: number;
  guest_count: number;
}

export interface ILogQuery {
  location_id?: number;
  start: Date;
  end: Date;
  group_by: string;
}

export interface ILaborReport {
  date: string; // Short format
  total_foh_cost: number;
  total_boh_cost: number;
  total_ot_foh_cost: number;
  total_ot_boh_cost: number;
  total_minutes: number;
  user: {
    id: number;
    firstname: string;
    lastname: string;
    minutes: number;
    foh_cost: number;
    boh_cost: number;
    ot_foh_cost: number;
    ot_boh_cost: number;
    salary: boolean;
    timeclock: any[];
  }[];
}

export interface IReportBreakdown {
  foh_minutes: number;
  boh_minutes: number;
  foh_cost: number;
  boh_cost: number;
  ot_foh_minutes: number;
  ot_boh_minutes: number;
  ot_foh_cost: number;
  ot_boh_cost: number;
  ot_type: number;
}

export interface ILogReport {
  date: string;
  d?: any;
  salary?: IReportBreakdown;
  timeclock?: IReportBreakdown;
  scheduled?: IReportBreakdown;
  users?: any[];
  foh_cost: number;
  boh_cost: number;
  log_foh_cost: number;
  log_boh_cost: number;
  cogs_cost: number;
  fixed_cost: number;
  max_temp: number;
  service_time: { sales: number; guest_count: number }[];
  shifts?: any[];
  timeclocks?: any[];
}

export interface ILog extends IIdentifiable {
  date: Date | string;
  service_time_id: string;
  location_id: number;
  user_id: number;
  net_sales?: number;
  tax?: number;
  foh_labor_hours?: number;
  foh_labor_cost?: number;
  boh_labor_hours?: number;
  boh_labor_cost?: number;
  guest_count?: number;
  comps?: number;
  discounts?: number;
  employee_discounts?: number;
  cc_batch_no?: number;
  pay_outs?: number;
  cash?: number;
  expected_cash?: number;
  gift_redeemed?: number;
  gift_sold?: number;
  cc_tips_collected?: number;
  actual_bank?: number;
  custom?: Object | string;
}

export interface IIntegrationBreak extends IIdentifiable {
  location_id: number;
  integration_id: string;
  time_clock_id?: number;
  is_paid: boolean;
  start: Date;
  end?: Date;
  parent_id?: string; // Used for time_clock linking.
}

export interface IIntegrationSales extends IIdentifiable {
  date?: Date | string;
  actual_date: Date | moment.Moment;
  location_id: number;
  service_time_id?: number;
  unique_id: string;
  net_sales?: number;
  guest_count?: number;
  tax?: number;
  comps?: number;
  discounts?: number;
  employee_discounts?: number;
  pay_outs?: number;
  cash?: number;
  expected_cash?: number;
  gift_redeemed?: number;
  gift_sold?: number;
  cc_tips_collected?: number;
  actual_bank?: number;
  custom_fields?: Object | string;
}

export interface IIntegrationPosition {
  id?: number;
  location_id: number;
  integration_id: string;
  name: string;
  color?: string;
  rate?: number;
  rate_type?: RateType;
  position_type?: PositionType;
}

// You need either rate, or cost & ot_cost
export interface IIntegrationTimeClock {
  id?: number; // time_clock.id
  location_id: number;
  integration_id: string;
  employee_position_id: number;
  user_id: number;
  shift_id?: number;
  start: Date;
  end: Date;
  rate?: number;
  rate_type: number;
  cost?: number;
  ot_cost?: number;
  tips?: number;
  ot_hours?: number;
  breakTime?: number;
}

export interface IIntegrationShift {
  id?: number; // shift.id
  location_id: number;
  integration_id: string;
  start: Date;
  end: Date;
  position_id: number;
  user_id?: number;
  employee_id: number;
  employee_position_id?: number; // This is around if you have time clocks.
}

export interface IIntegrationUser extends IIdentifiable {
  location_id: number;
  user_id?: number;
  username?: string;
  integration_id: string;
  firstname: string;
  lastname: string;
  email: string;
  pin?: number;
  phone?: string;
  birthday?: Date;
  date_of_hire?: Date;
  integration_user_status?: IntegrationUserStatus;
  jobs?: { rate: number; position_id: number }[];
}

export interface ILogCustom extends IIdentifiable {
  service_time_id: string;
  location_id: number;
  log_id: number;
  custom_log_field_id: number;
  data: number;
}

export interface ILocationCreate {
  email: string;
  password: string;
  user_id: number;
  billing_plan_id: number;
  name: string;
  location_type: number;
  location_category: string;

  sales_tracking?: boolean;
  has_houses?: boolean;
  has_tip_reporting?: boolean;
  payroll_day: number;
  week_start: number;
  is_service_time_daily: boolean;

  timezone_id: number;
  zip: string;
  country: string;

  //cc?: ICCInfo,
  unlimited_texts: boolean;
  contact_method: LocationContactType;

  freeTrialConfig: IFreeTrialConfig;
}

export interface IFreeTrialConfig {
  enabled: boolean;
  days: number;
}

export interface ILocation extends IIdentifiable {
  created_at?: Date;
  updated_at?: Date;
  name: string;
  payment_status: boolean;
  payment_failed_at?: boolean;
  status: string;
  is_service_time_daily: boolean;
  billing_plan_id: number;
  free_until: Date;
  package_type: PackageType;
  new_package_type: PackageType;
  unlimited_texts: boolean;
  //token: string,
  //card_holders_name: string,
  //card_type: string,
  //exp: string,
  //last4: string,
  user_credit_card_id?: number;
  has_finances: boolean;
  has_inventory: boolean;
  allow_preferences: boolean;
  has_houses: boolean; // Does it do FOH/BOH?
  segment_houses: boolean;
  has_budgets: boolean;
  has_tip_reporting: boolean;
  block_freemium_warning: boolean;
  block_credit_card_warning: boolean;
  require_w4: boolean;
  require_i9: boolean;
  week_start: number;
  foh_goal: number;
  boh_goal: number;
  cogs_goal: number;
  fixed_goal: number;
  payroll_goal: number;
  exp_goal: number;
  location_type: number;
  category: string;
  date: Date;
  dark_days: boolean[];
  preloads: string;
  qsg_id: number;
  dedicated_ip: string;
  salt: string;
  has_image?: string;

  sales_integration_type: number;
  sales_integration_update?: Date;
  sales_integration_last?: Date;

  sales_integration_token?: string;
  sales_integration_refresh_token?: string;
  sales_integration_token_update?: Date;
  sales_integration_location_data?: string;

  sales_integration_sync_employee_data?: boolean;
  sales_integration_send_employee_email?: boolean;
  sales_integration_sync_sales_data?: boolean;
  sales_integration_sync_shift?: number;
  sales_integration_sync_timeclock?: boolean;
  sales_integration_merchant_id?: string;
  sales_integration_location_id?: string;
  sales_integration_location_name?: string;
  sales_integration_sales_filter?: string;

  labor_type: number;
  logo_salt: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  to_days: number;
  print_schedule_allow_phone: boolean;
  contact_method: number;
  level_1: string;
  level_2: string;
  level_3: string;
  sched_color: string;
  sched_sort_order: number;
  pref_auto_off: Date;
  max_labor: string | Object;
  min_labor: string | Object;
  payroll_day: number;
}

export interface ILocationUserMap extends IIdentifiable {
  location_id: number;
  user_id: number;
  hide?: boolean;
  user_type: number;
  pref_dna?: string;
  primary_position_id?: number;
  position_id?: number;
  date_of_hire?: Date;
  freq_of_review?: number;
  date_of_termination?: Date;
  rate_of_pay?: number;
  type_of_pay?: string;
  max_hours?: number;
  ot_hours?: number;
  color?: string;
  notes?: string;
  last_login?: Date;
  live_status?: boolean;
  pin: string;
  mime_type_w4?: boolean;
  mime_type_i9?: boolean;
  no_email?: boolean;
  widgets?: number[];
  integration_id?: string;
}

export interface ILogNote extends IIdentifiable {
  created_at?: Date;
  location_id: number;
  log_id: number;
  service_time_id: number;
  log_note_type: number;
  user_id: number;
  date: Date;
  note: string;
}

export interface ILogComment extends IIdentifiable {
  created_at?: Date;
  location_id: number;
  user_id: number;
  response: string;
  date: Date;
}

export interface ILogImage extends IIdentifiable {
  location_id: number;
  log_note_id: number;
  mime_type?: string;
  //date: Date,
  //salt: string,
}

export interface ILoginAttempt extends IIdentifiable {
  user_id: number;
  time: Date;
  ip: string;
}

export interface IMedia extends IIdentifiable {
  user_id: number;
}

export interface IMessage extends IIdentifiable {
  created_at?: Date;
  sender_user_id: number;
  title: string;
  body: string;
  location_id: number;
  thread?: string;
  salt?: string;
  salt_all?: string;
  wall: boolean;
  signed: boolean;
  comments: boolean;
  old: boolean;
}

export interface IMessageComment extends IIdentifiable {
  created_at?: Date;
  message_id: number;
  user_id: number;
  comment: string;
}

export interface IMessageImage extends IIdentifiable {
  location_id: number;
  message_id: number;
  mime_type?: string;
}

export interface IMessageRecipient extends IIdentifiable {
  message_id: number;
  user_id: number;
  read: boolean;
  archived: boolean;
  email_sent: boolean;
  email_sent_time: Date;
  text_send: boolean;
  text_send_time: Date;
  read_salt: string;
  archive_salt: string;
}

// This is here to keep the notification system happy.
// Also, might log at some point..
export interface INotification {}

export interface INoteResponse extends IIdentifiable {
  date: Date;
  location_id: number;
  user_id: number;
  code: string;
}

export interface INoteDate extends IIdentifiable {
  date: Date;
  location_id: number;
  user_id: number;
}

/*
export interface IPayment extends IIdentifiable {
	location_id: number
	amt: number,
	token: string,
	timestamp: Date,
	card4: number
}
*/
export interface IPermissionLocation extends IIdentifiable {
  location_id: number;
  permission_id: number;
  user_type: number;
  value: boolean;
}

export interface IPermissionUser extends IIdentifiable {
  location_id: number;
  permission_id: number;
  user_type: number;
  value: boolean;
}

export interface IPersistentLogin extends IIdentifiable {
  location_id: number;
  permission_id: number;
  user_type: number;
  value: boolean;
}

export interface IShiftTrade extends IIdentifiable {
  location_id?: number;
  original_approval?: boolean;
  reject?: boolean;
  final_approval?: boolean;
  shift_id: number;
  user_id: number;
  trade_shift_id?: number;
  trade_user_id?: number;
  trade_type: number;
}

export interface IPurchaseOrderCreate {
  location_id: number;
  date: Date;
  skus: any[];
}

export interface IPurchaseOrderChangeStatus {
  id: number;
  invoice_id: number;
  invoice_num?: string;
  oldStatus: PurchaseOrderStatus;
  newStatus: PurchaseOrderStatus;
  sendEmail: boolean;
}

export interface IPurchaseOrder extends IIdentifiable {
  contact_id: number;
  location_id: number;
  invoice_id?: number;
  date: Date;
  status: number;
  number?: number;
  days: boolean[];
  repeat_option: number;
  name: string;
  parent_id?: number;
}

export interface IPurchaseOrderItem extends IIdentifiable {
  purchase_order_id: number;
  sku_id: number;
  name: string;
  qty: number;
  buying_unit: string;
  price_per_unit: string;
  qty_received?: number;
  price_received?: string;
}

export interface IPosition extends IIdentifiable {
  name: string;
  position_type: number; // Enum
  location_id: number;
  color?: string;
  hide?: boolean;
  integration_id?: string;
}

export interface IPublishLog extends IIdentifiable {
  location_id: number;
  week: Date;
  active: boolean;
  publish_log_type: number; // Enum
  user_id: number;
  timestamp: Date;
  note: string;
}

export interface IQuizQuestion extends IIdentifiable {
  answer_a: string;
  answer_b: string;
  answer_c: string;
  answer_d: string;
  right_answer: string;
  doc_id: number;
  question: string;
}

export interface IQuizPassed extends IIdentifiable {
  doc_id: number;
  user_id: number;
}

export interface IRecipeBlock extends IIdentifiable {
  location_id: number;
  name: string;
  qty?: number;
  price: number;
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
  water: number;
}

export interface IRecipeBlockItem extends IIdentifiable {
  location_id: number;
  recipe_block_id: number;
  sku_id?: number;
  child_recipe_block_id?: number;
  qty: number;
  unit: number;
}

export interface IRepeatingEmailAlertTask extends IIdentifiable {
  task_id: number;
  date: Date;
}

export interface IRepeatingTextAlertEvent extends IIdentifiable {
  event_id: number;
  date: Date;
}

export interface IRepeatingTextAlertTask extends IIdentifiable {
  task_id: number;
  date: Date;
}

export interface IReview extends IIdentifiable {
  created_at?: Date;
  user_id: number;
  body: string;
  location_id: number;
  manager_user_id: number;
}

export interface ISalesGoal extends IIdentifiable {
  location_id: number;
  date: Date;
  goal: number;
}

export interface IServiceTime extends IIdentifiable {
  service_time_type: number;
  location_id: number;
  net_sales: boolean;
  tax: boolean;
  foh_labor_hours: boolean;
  foh_labor_cost: boolean;
  boh_labor_hours: boolean;
  boh_labor_cost: boolean;
  guest_count: boolean;
  comps: boolean;
  discounts: boolean;
  employee_discounts: boolean;
  notes_general: boolean;
  notes_feedback: boolean;
  notes_employee: boolean;
  notes_repair: boolean;
  cc_batch_no: boolean;
  pay_outs: boolean;
  cash: boolean;
  expected_cash: boolean;
  gift_redeemed: boolean;
  gift_sold: boolean;
  cc_tips_collected: boolean;
  actual_bank: boolean;
  start: Date;
  end: Date;
}

export interface IShift extends IIdentifiable {
  start: Date;
  end: Date;
  first_break_time?: Date;
  first_break_length?: number;
  second_break_time?: Date;
  second_break_length?: number;
  third_break_time?: Date;
  third_break_length?: number;
  position_id: number;
  user_id?: number;
  location_id?: number;
  published?: boolean;
  trade?: boolean;
  trade_type?: number;
  integration_id?: string;
}

export interface IShiftHistory extends IIdentifiable {
  agent_id: number;
  shift_id: number;
  action_type: number;
  start: Date;
  end: Date;
  position_id: number;
  user_id?: number;
  locaiton_id: number;
  published: boolean;
  trade: boolean;
  trade_type: number;
}

export interface ISignature extends IIdentifiable {
  created_at?: Date;
  doc_id?: number;
  write_up_id?: number;
  user_id: number;
  name: string;
  svg: string;
}

export interface ISku extends IIdentifiable {
  location_id: number;
  expense_account_id: number;
  contact_id: number;
  name: string;
  sku_num?: string;
  price: number;
  total_individual_units: number;
  buying_unit: string;
  subdivided: boolean;
  sub_unit: string;
  qty_inner_units: number;
  has_nutrition: boolean;
  cost_method: number;
  how_count: number;
  reorder_qty: number;
  par_qty: number;
  par_qty_monday: number;
  reorder_qty_monday: number;
  par_qty_tuesday: number;
  reorder_qty_tuesday: number;
  par_qty_wednesday: number;
  reorder_qty_wednesday: number;
  par_qty_thursday: number;
  reorder_qty_thursday: number;
  par_qty_friday: number;
  reorder_qty_friday: number;
  par_qty_saturday: number;
  reorder_qty_saturday: number;
  par_qty_sunday: number;
  reorder_qty_sunday: number;
  par_type: string;
  master_weight: number;
  master_weight_unit?: number;
  nutritional_portion_weight: number;
  nutritional_portion_weight_unit?: number;
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
  water: number;
  hide: boolean;
}

export interface ISkuLocation extends IIdentifiable {
  location_id: number;
  sku_id: number;
  inventory_location_id: number;
  order_by: number;
}

export interface ISkuCategory extends IIdentifiable {
  name: string;
}
/*
export interface ITask extends IIdentifiable {
	location_id: number,
	name: string,
	added_date: Date,
	description: string,
	start_date: Date,
	repeat_option: number,
	text_alert: boolean,
	text_alert_days: number,
	email_alert: boolean,
	email_alert_days: number,
	text_time: Date,
	email_time: Date,
	text_sent: boolean,
	email_sent: boolean,
	repair: boolean,
	color: string,
	end_date: Date,
}*/

export interface ITaskAssignment extends IIdentifiable {
  task_id: number;
  user_id: number;
}

export interface IShiftTemplate extends IIdentifiable {
  location_id: number;
  name: string;
}

export interface IShiftTemplateItem extends IIdentifiable {
  location_id: number;
  shift_template_id: number;
  start: Date;
  end: Date;
  position_id: number;
  user_id: number;
}

export interface ITextLog extends IIdentifiable {
  location_id: number;
  user_id?: number;
  xml: string;
}

export interface ITextQueue extends IIdentifiable {
  user_id: number;
  type: string;
  object_id: number;
  time: Date;
  location_id: number;
}

export interface ITimeOff extends IIdentifiable {
  created_at?: Date;
  user_id: number;
  location_id: number;
  start_date: Date;
  end_date: Date;
  whole_day: boolean;
  approved?: boolean;
  action_at?: Date;
  action_user_id?: number;
  notes?: string;
}

// You need rate or cost/cost_ot
export interface ITimeClock extends IIdentifiable {
  approved_by: number;
  employee_position_id: number;
  cost?: number;
  ot_cost?: number;
  rate?: number;
  rate_type: number;
  shift_id: number;
  start: Date;
  end: Date;
  user_id: number;
  location_id: number;
  tips: number;
  ot_hours: number;
  integration_id?: string;
}

export interface ITimezone extends IIdentifiable {
  name: string;
  iso: string;
  order_by: number;
}

export interface IUsdaListItem {
  ndbno: number;
  group: string;
  name: string;
}

export interface IUsdaItem {
  ndbno: number;
  name: string;
  water?: number;
  kcal?: number;
  protein?: number;
  fat?: number;
  carbs?: number;
}

export interface IUserBusinessCreate {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}

export interface IUserCreate {
  id?: number;
  location_id: number;
  user_type: number;
  username?: string;
  password?: string;
  no_email?: boolean;
  freq_of_review?: number;
  email: string;
  phone: string;
  cell?: string;
  firstname: string;
  lastname: string;
  pin: string;
  color: string;
  notes?: string;
  birthday?: Date;
  date_of_hire?: Date;
  max_hours: number;
  positions: {
    is_primary: boolean;
    position_id: number;
    rate: number;
    rate_type: number;
  }[];

  live_status?: boolean;
  has_image: string;
  user_id?: number;
  widgets?: number[];
  integration_id?: string;
  location_user_map_id?: number; // This is used during validation.
}

export interface IUser extends IIdentifiable {
  user_status?: number;
  user_type?: UserType;
  username?: string;
  email?: string;
  password?: string;

  firstname?: string;
  lastname?: string;
  timezone_id?: number;
  phone?: string;
  cell?: string;
  company?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  color?: string;
  //dob?: Date,

  has_image: string;
  permission_override?: Object;
  notification_override?: Object; // BUG BUG BUG, no idea what this hooks to.
  external_id?: string;
}

export interface IUserCreditCardData {
  cardholder_name: string;
  card_number: string;
  card_type: string;
  last4: string;
  exp: Date;
  cvv: string;
  token?: string;
}

export interface IUserCreditCard extends IIdentifiable {
  user_id: number;
  token: any;
  name: string;
  cardholder_name: string;
  card_type: string;
  last4: string;
  exp: Date;
  is_primary?: boolean;
}

export interface IWriteUp extends IIdentifiable {
  created_at?: Date;
  user_id: number;
  location_id: number;
  manager_user_id: number;
  body: string;
}

export interface Pricing {
  [key: string]: {
    [key: string]: {
      priceId: string;
      discount: string;
    };
  };
}
export function jailUserProfile(user: IApiUser): boolean {
  // console.log('Jail User Profile');
  // console.log(user);
  return !(
    user.firstname &&
    user.firstname.length > 0 &&
    user.lastname &&
    user.lastname.length > 0 &&
    user.email &&
    user.email.length > 0 &&
    user.cell &&
    user.cell.length > 0 &&
    user.address &&
    user.address.length > 0 &&
    user.city &&
    user.city.length > 0 &&
    user.state &&
    user.state.length > 0 &&
    user.country &&
    user.country.length > 0 &&
    user.zip &&
    user.zip.length > 0
  );
}

export class ReportBreakdown implements IReportBreakdown {
  public foh_minutes: number;
  public boh_minutes: number;
  public foh_cost: number;
  public boh_cost: number;
  public ot_foh_minutes: number;
  public ot_boh_minutes: number;
  public ot_foh_cost: number;
  public ot_boh_cost: number;
  public ot_type: number;

  constructor(a?: IReportBreakdown) {
    if (a) {
      this.foh_minutes = a.foh_minutes;
      this.boh_minutes = a.boh_minutes;
      this.foh_cost = a.foh_cost;
      this.boh_cost = a.boh_cost;
      this.ot_foh_minutes = a.ot_foh_minutes;
      this.ot_boh_minutes = a.ot_boh_minutes;
      this.ot_foh_cost = a.ot_foh_cost;
      this.ot_boh_cost = a.ot_boh_cost;
      this.ot_type = a.ot_type;
    } else {
      this.foh_minutes = 0;
      this.boh_minutes = 0;
      this.foh_cost = 0;
      this.boh_cost = 0;
      this.ot_foh_minutes = 0;
      this.ot_boh_minutes = 0;
      this.ot_foh_cost = 0;
      this.ot_boh_cost = 0;
      this.ot_type = 0;
    }
  }

  add(a: ReportBreakdown): ReportBreakdown {
    if (!a) return;
    this.foh_minutes += a.foh_minutes;
    this.boh_minutes += a.boh_minutes;
    this.foh_cost += a.foh_cost;
    this.boh_cost += a.boh_cost;
    this.ot_foh_minutes += a.ot_foh_minutes;
    this.ot_boh_minutes += a.ot_boh_minutes;
    this.ot_foh_cost += a.ot_foh_cost;
    this.ot_boh_cost += a.ot_boh_cost;
    this.ot_type |= a.ot_type;
    return this;
  }
}

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#Examples
export function encodeAttachmentName(str: string): string {
  //encodeRFC5987ValueChars//
  return (
    encodeURIComponent(str)
      // Note that although RFC3986 reserves "!", RFC5987 does not,
      // so we do not need to escape it
      .replace(/['()]/g, escape) // i.e., %27 %28 %29
      .replace(/\*/g, '%2A')
      // The following are not required for percent-encoding per RFC5987,
      // so we can allow for a little better readability over the wire: |`^
      .replace(/%(?:7C|60|5E)/g, unescape)
  );
}

export function toFixed(num: number): string {
  const precision = 2;
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(
    precision
  );
}

export interface IIntegrationPeriod {
  start: moment.Moment;
  end: moment.Moment;
}

export function phoneToUsername(phone: string): string {
  if (!phone) return null;
  return phone.replace(/\D/g, '');
}

const colorArray = [
  '#a52a2a',
  '#008b8b',
  '#4169E1',
  '#FF69b4',
  '#cd5c5c',
  '#4b0082',
  '#787878',
  '#32CD32',
  '#000000',
  '#A0522D',
];
export function getRandomColor(): string {
  return colorArray[Math.floor(Math.random() * colorArray.length)];
}

export interface ICapital extends IIdentifiable {
  user_id: number;
  location_id?: number;
  kanmon_business_owner_id?: string;
  kanmon_business_id?: string;
}

export interface IKanmonBusiness {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  address?: IKanmonAddress;
}

export interface IKanmonAddress {
  id: string;
  city: string;
  addressLineOne: string;
  addressLineTwo?: string;
  state: string;
  zipcode: string;
  country: string;
}

export interface IKanmonBusinessOwner {
  id: number;
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  businessId: string;
  address?: IKanmonAddress;
}

export interface IKanmonBusinessCreationRequest {
  name: string;
  address?: IKanmonAddress;
}

export interface IKanmonBusinessUpdateRequest {
  name?: string;
  address?: IKanmonAddress;
}

export interface IKanmonBusinessOwnerCreationRequest {
  email: string;
  businessId: string;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  address?: IKanmonAddress;
}

export interface IKanmonBusinessOwnerUpdateRequest {
  email?: string;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  address?: IKanmonAddress;
}

export interface IKanmonConnectTokenCreationRequest {
  userId: string;
}

export interface IKanmonConnectToken {
  connectToken: string;
}

export type RequestObject = {
  uri: string;
  method: string;
  body: any;
  json: boolean;
  headers: {
    Authorization: string;
    Accept: string;
  };
};

export interface IKanmonError {
  code: number;
  message: string;
}

export const errorMessages: Record<any, string> = {
  201: 'Success',
  200: 'Business owner with email already exists for a business',
  409: 'Business owner email already taken or business already has a primary business owner',
  400: 'Validation error',
};

export enum FoodVendorIntegrationType {
  Sysco = 'sysco',
  Shamrock = 'shamrock',
}

export interface IFoodVendorIntegrationCreds {
  vendor: FoodVendorIntegrationType;
  host: string;
  port: number;
  username: string;
  password: string;
  status?: string;
}

export interface IFoodVendorIntegrationRequest {
  locationId: string;
  credentials: IFoodVendorIntegrationCreds[];
}