import { IconsModule } from './../app/icons/icons.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';
import { LocationUserMapService } from '../services/locationUserMap.service';
import { IntegrationUserService } from '../services/integrationUser.service';
import { PositionService } from '../services/position.service';
import { PermissionUserService } from '../services/permissionUser.service';
import { ReviewService } from '../services/review.service';
import { WriteUpService } from '../services/writeUp.service';

import { IntegrationUserComponent } from './integrationUser.component';
import { ModalFilterUsersComponent } from './modalFilterUsers.component';
import { ModalReWriteupComponent } from './modalReWriteup.component';
import { ModalSendUnverifiedComponent } from './modalSendUnverified.component';
import { ManageComponent } from './manage.component';
import { PositionsComponent } from './positions.component';
import { TimeOffComponent } from './timeOff.component';
import { TradeComponent } from './trade.component';
import { UsersComponent } from './users.component';
import { UserAddComponent } from './userAdd.component';

import {
  UserEditComponent,
  UserEditEmployeeComponent,
  UserEditWorkPrefComponent,
  UserEditRolePosComponent,
  UserEditPermissionsComponent,
} from './userEdit.component';

import { routing } from './users.routing';

@NgModule({
  declarations: [
    IntegrationUserComponent,
    ModalFilterUsersComponent,
    ModalReWriteupComponent,
    ModalSendUnverifiedComponent,
    ManageComponent,
    PositionsComponent,
    TimeOffComponent,
    TradeComponent,
    UsersComponent,
    UserAddComponent,
    UserEditComponent,
    UserEditEmployeeComponent,
    UserEditWorkPrefComponent,
    UserEditRolePosComponent,
    UserEditPermissionsComponent,
  ],
  imports: [SharedModule, routing, IconsModule],
  providers: [
    IntegrationUserService,
    LocationUserMapService,
    PositionService,
    PermissionUserService,
    ReviewService,
    WriteUpService,
  ],
})
export class UsersModule {}
