<form ngNativeValidate>
  <div class="title text-center mt-3">Staff Positions</div>

  <div class="input-group">
    <input
      type="text"
      maxlength="50"
      class="form-control form-control-lg override-invalid"
      name="add_name"
      [(ngModel)]="newObj.name"
      placeholder="Add A New Position"
      required />
    <div class="input-group-append" *ngIf="loc.has_houses">
      <select
        name="position_type"
        [(ngModel)]="newObj.position_type"
        class="form-control-select">
        <option *ngFor="let p of PositionTypeKeys" [value]="p">
          {{PositionType[p]}}
        </option>
      </select>
    </div>
    <div class="input-group-append">
      <button class="btn btn-icon override-invalid" type="button">
        <input-color
          [(ngModel)]="newObj.color"
          name="add_color"
          placement="bottom">
        </input-color>
      </button>
    </div>
    <div class="input-group-append">
      <button
        class="btn btn-primary override-invalid"
        type="submit"
        (click)="save(newObj)">
        <icons name="Plus"></icons>
      </button>
    </div>
  </div>
</form>

<div class="card">
  <div class="border" *ngFor="let d of data | orderBy: 'name'">
    <form ngNativeValidate (submit)="save(d)">
      <div *ngIf="d._edit">
        <div class="input-group">
          <input
            type="text"
            maxlength="50"
            class="form-control form-control-lg override-invalid"
            [name]="'name_'+d.id"
            [(ngModel)]="d._edit.name"
            required />
          <div class="input-group-append" *ngIf="loc.has_houses">
            <select
              name="position_type"
              [(ngModel)]="d._edit.position_type"
              class="form-control-select">
              <option *ngFor="let p of PositionTypeKeys" [value]="p">
                {{PositionType[p]}}
              </option>
            </select>
          </div>
          <div class="input-group-append">
            <button
              class="btn btn-icon z-index-override override-invalid"
              type="button">
              <input-color
                [(ngModel)]="d._edit.color"
                name="change_color"
                placement="bottom"></input-color>
            </button>
            <button type="submit" class="cdi-s-save"></button>
            <span class="cdi-s-cancel pointer" (click)="cancel(d)"></span>
          </div>
        </div>
      </div>
      <div *ngIf="!d._edit">
        <div class="row">
          <div class="col-7 mt-2 ml-2">
            <span [style.color]="d.color">{{d.name}}</span>
            <!--		<input type='text' class="form-control-plaintext" [style.color]="d.color" style="min-width:20rem" readonly [name]="'name_'+d.id" [value]="d.name"> -->
          </div>
          <div class="col mt-2">
            <span *ngIf="loc.has_houses">
              {{PositionType[d.position_type]}}</span
            >
            <div class="float-right">
              <span class="cdi-s-edit pointer" (click)="edit(d)"></span>
              <delete-modal
                *ngIf="d._can_delete"
                [deleteObject]="d"
                [name]="d.name"
                (onDelete)="delete($event)">
                <span class="cdi-s-trash pointer"></span>
              </delete-modal>
              <span *ngIf="!d._can_delete" class="cdi-s-no-trash"></span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- color of text for position match the color used for schedule-->
<!--<div class='card'>
			<div class='card-body'>
					<table class='table table-hover table-small table-striped mb-0'>
						<tbody>
							<tr *ngFor="let d of data | orderBy: 'name'">
								<td>
								<form ngNativeValidate (submit)="save(d)">
									<div class='d-flex  flex-row justify-content-between flex-wrap'>
										<div >
											<input type='text' class="form-control-plaintext" [style.color]="d.color" style="min-width:20rem" *ngIf="!d._edit" readonly [name]="'name_'+d.id" [value]="d.name">
											<input type='text'  maxlength="50" class="form-control mt-1 " *ngIf="d._edit" [name]="'name_'+d.id" [(ngModel)]="d._edit.name" required>
										</div>
										<div *ngIf="d._edit">
											<select class='form-control mt-1 align-self-center'>
													<option value='0'>Front of House</option>
													<option value='1'>Back of House</option>
											</select>
										</div>
										<div  *ngIf="d._edit">
							 			   <button class="btn btn-outline-secondary btn-icon"   type="button">
												 <input-color [(ngModel)]="d.color" name="change_color"   placement="bottom"></input-color>
											 </button>
										</div>
										<div class="d-inline-flex ">
											<span class='cdi-s-edit' *ngIf="!d._edit" (click)="edit(d)"></span>
											<button type='submit' class='cdi-s-save' *ngIf="d._edit"></button>
											<delete-modal *ngIf="!d._edit" [deleteObject]="d" [name]="d.name" (onDelete)="delete($event)">
												<span class='cdi-s-trash'></span>
											</delete-modal>
											<span class='cdi-s-cancel' *ngIf="d._edit" (click)="cancel(d)"></span>
										</div>
									</div>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>-->
<!--	<form ngNativeValidate>
		<div class='card'>
	    <table class='table table-sm table-hover mb-0'>
	      <tbody>
	        <tr *ngFor="let d of data | orderBy: 'name'">
						<td class='input-group'>
							<span *ngIf="!d._edit" [style.color]="d.color">  {{d.name}}</span>
							<input type='text'  maxlength="100" class="form-control mt-1 " *ngIf="d._edit" [name]="'name_'+d.id" [(ngModel)]="d._edit.name" required>
						</td>
						<td *ngIf="!d._edit && loc.has_houses">
								{{PositionType[d.position_type]}}
						</td>
						<td *ngIf="d._edit && loc.has_houses">
							<select name='position_type' [(ngModel)]="d._edit.position_type" class='form-control  d-inline-block mb-0'>
								<option *ngFor='let p of PositionTypeKeys' [value]='p'>{{PositionType[p]}}</option>
							</select>
						</td>
						<td *ngIf="d._edit">
							 <button class="btn btn-outline-secondary btn-icon" type="button">
								 <input-color [(ngModel)]="newObj.color" name="change_color" placement="bottom"></input-color>
							 </button>
						</td>
						<td *ngIf="!d._edit">
							<button class='btn btn-outline-light btn-icon' type='button'>
							</button>
						</td>
						<td  *ngIf="d._edit" class='input-group' >
							<span class='cdi-s-save' (click)='save(d)'></span>
							<span class='cdi-s-cancel'  (click)="cancel(d)"></span>
						</td>
						<td *ngIf="!d._edit" class='input-group '>
							<span class='cdi-s-edit' (click)="edit(d)"></span>
							<delete-modal  [deleteObject]="d" [name]="d.name" (onDelete)="delete($event)">
								<span class='cdi-s-trash'></span>
							</delete-modal>
						</td>
					</tr>
		    </tbody>
    	</table>
		</div>
	</form> -->
