import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { Meta, Title } from '@angular/platform-browser';
import { baseRole } from 'source/shared/baseRole';

@Component({
  templateUrl: './financing.html',
})
export class FinancingComponent extends baseRole implements OnInit {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Financing');
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.AccessSettings)) {
      this.router.navigate(['/']);
      return;
    }
  }
}
