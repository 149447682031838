import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver_ from 'file-saver';

@Component({
  selector: 'export-to-csv',
  template:
    '<button *ngIf="isReady" class="btn btn-primary my-2" type="button" (click)="exportToCsv()">Download</button>',
})
export class ExportToCsvComponent {
  @Input() header: any;
  @Input() data: any;
  @Input() filename: any;

  public isReady = true;
  public _data: any[];

  constructor(protected sanitizer: DomSanitizer) {}

  exportToCsv(): string {
    if (!this.filename) {
      console.log('Missing Filename');
      return;
    }

    if (!this.data || (Array.isArray(this.data) && this.data.length == 0)) {
      console.log('No Data');
      return;
    }

    if (Array.isArray(this.data)) {
      this._data = this.data;
      this.isReady = false;
      this._export();
    } else {
      // It's a function, lets normalize it.
      //			var p = Promise.resolve(this.data);

      //p.then((data)=> {
      this.data.subscribe(data => {
        this._data = data;
        this.isReady = false;
        this._export();
      });
    }
  }

  _export() {
    // Giant Kludge for rollup WARNING
    let fileSaver: any = FileSaver_;
    if ((<any>FileSaver_).default) {
      fileSaver = (<any>FileSaver_).default.saveAs;
    }

    let filename =
      typeof this.filename === 'function' ? this.filename() : this.filename;
    let out = '';

    if (!this.header || this.header.length == 0) {
      this.header = {};
      for (let k in this._data[0]) {
        this.header[k] = k;
      }
    }

    // Get a list of the column headers.
    let keys = Object.keys(this.header);

    let needsComma = false;
    // Put the list of the column headers on the output
    for (let k of keys) {
      if (needsComma) out += ',';
      out += '"' + escapeQuotes(this.header[k]) + '"';
      needsComma = true;
    }
    out += '\n';

    // And then run over the columns for each object.
    for (let i = 0; i < this._data.length; i++) {
      needsComma = false;
      for (let k of keys) {
        if (needsComma) out += ',';
        if (k in this._data[i]) {
          if (
            this._data[i][k] === null ||
            typeof this._data[i][k] == 'undefined'
          ) {
            //out+='""';
          } else {
            out += '"' + escapeQuotes(this._data[i][k]) + '"';
          }
        }
        needsComma = true;
      }
      out += '\n';
    }

    let blob = new Blob([out], { type: 'text/csv' });
    fileSaver(blob, filename);
    this.isReady = true;
  }
}

function escapeQuotes(str) {
  if (str === null) return '';
  //console.log(str);
  return str.toString().replace(/\"/g, '"');
}
