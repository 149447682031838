import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IApiLocation,
  IUser,
} from '../services/account.service';
import {
  CustomLogFieldService,
  ICustomLogField,
} from '../services/customLogField.service';
import {
  ServiceTimeService,
  IServiceTime,
} from '../services/serviceTime.service';
import { LocationService, ILocation } from '../services/location.service';
import { SalesIntegrationType } from '../shared/api';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './log.html',
})
export class LogComponent extends baseRole implements OnInit {
  public custom: ICustomLogField;
  public customFields: ICustomLogField[];
  public serviceTimePercents;
  public apiLoc: IApiLocation;
  public data: IServiceTime;
  public _allServices: IServiceTime[];
  public serviceModified = false;
  public SalesIntegrationType = SalesIntegrationType;
  public salesIntegrationChanged = false;
  public dataLoc: any = {};

  public saveMessage: string;
  public saveError: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected serviceTimeService: ServiceTimeService,
    protected customLogFieldService: CustomLogFieldService,
    protected locationService: LocationService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.apiLoc = this.accountService.getLocation();
    if (!this.apiLoc) {
      this.router.navigate(['/settings/account']);
      return;
    }
    this.dataLoc = { id: this.apiLoc.id };
    this.custom = {} as ICustomLogField;
    this.serviceTimeService.list().subscribe(data => {
      this._allServices = data;
      this._allServices.sort((a, b) => {
        return a.start.getTime() - b.start.getTime();
      });
      /*console.log('Sorted');
			console.log(this._allServices);*/
      this.data = this._allServices[0];
      this.loadCustomForService();
      this.serviceTimeUpdate(false);
    });
  }

  saveField(field: string) {
    let updateObj: any = {
      id: this.data.id,
    };
    updateObj[field] = this.data[field];
    this.serviceTimeService.update(updateObj).subscribe(() => {});
  }

  saveLavu() {
    this.saveError = null;
    this.saveMessage = 'Saving and verifying';
    this.locationService.update(this.dataLoc).subscribe(data => {
      this.salesIntegrationChanged = false;
      this.saveMessage = null;
    });
  }

  doSaveError(error: string) {
    this.saveError = error;
    setTimeout(() => {
      this.saveError = null;
    }, 5000);
  }

  newDefaultCustom(serviceTimeId: number): ICustomLogField {
    return {
      location_id: this.accountService.getLocationId(),
      service_time_id: serviceTimeId,
      name: null,
      days: [true, true, true, true, true, true, true],
    };
  }

  loadCustomForService() {
    this.custom = this.newDefaultCustom(this.data.id);
    this.customFields = null;
    this.customLogFieldService
      .list({ service_time_id: this.data.id })
      .subscribe(data => {
        this.customFields = data;
      });
  }

  updateImport() {
    this.salesIntegrationChanged = true;
  }

  editCustom(obj) {
    obj._edit = Object.assign({}, obj);
  }
  cancelCustom(obj) {
    obj = Object.assign(obj, obj._edit);
    delete obj._edit;
  }
  deleteCustom(obj) {
    this.customLogFieldService.delete(obj.id).subscribe(data => {
      let idx = this.customFields.indexOf(obj);
      this.customFields.splice(idx, 1);
    });
  }

  saveCustom(obj) {
    this.customLogFieldService.update(obj).subscribe(data => {
      if (obj.id) {
        delete obj._edit;
      } else {
        obj.id = data.id;
        this.customFields.push(obj);
        this.custom = this.newDefaultCustom(this.data.id);
      }
    });
  }
  /*saveCurrentService() {
		this.serviceTimeService.update(this.data).subscribe(()=> {});
	}*/

  changeService(serviceIndex) {
    this.data = this._allServices[serviceIndex];
    this.loadCustomForService();
  }

  serviceTimeUpdate(update = true) {
    this.serviceModified = false;
    if (this._allServices.length < 2) return;
    this._allServices.sort((a, b) => {
      return a.service_time_type - b.service_time_type;
    });

    let year = 2010;
    let month = 3;
    let day = 1;
    for (let a of this._allServices) {
      a.start.setFullYear(year);
      a.start.setMonth(month);
      a.start.setDate(day);
      a.end.setFullYear(year);
      a.end.setMonth(month);
      a.end.setDate(day);
    }
    this._allServices[2].end.setDate(day + 1); // Off the next day.

    const names = ['Breakfast', 'Lunch', 'Dinner'];
    const colorName = ['success', 'warning', 'secondary'];

    //console.log(this._allServices);
    if (this._allServices[0].start > this._allServices[0].end) {
      this._allServices[0].start = this._allServices[0].end;
    }
    if (this._allServices[1].end > this._allServices[2].start) {
      this._allServices[1].end = this._allServices[2].start;
    }
    if (this._allServices[1].start > this._allServices[1].end) {
      this._allServices[1].start = this._allServices[1].end;
    }
    if (this._allServices[2].start < this._allServices[1].end) {
      this._allServices[2].start = this._allServices[0].end;
    }
    this._allServices[0].end = this._allServices[1].start;
    this._allServices[1].end = this._allServices[2].start;
    this._allServices[2].end = new Date(this._allServices[0].start);

    let val = 0;
    let total = 0;
    val =
      (100.0 *
        (this._allServices[0].start.getHours() * 60.0 +
          this._allServices[0].start.getMinutes())) /
      (24.0 * 60.0);
    total += val;
    this.serviceTimePercents = [];
    // Put in the late night shift if we need to.
    this.serviceTimePercents.push({
      type: colorName[2],
      label: names[2],
      value: val,
    });

    val =
      (100.0 *
        (this._allServices[0].end.getTime() -
          this._allServices[0].start.getTime())) /
      (1000 * 24.0 * 60 * 60);
    total += val;
    // Breakfast
    this.serviceTimePercents.push({
      type: colorName[0],
      label: names[0],
      value: val,
    });
    val =
      (100.0 *
        (this._allServices[1].end.getTime() -
          this._allServices[1].start.getTime())) /
      (1000 * 24.0 * 60 * 60);
    total += val;
    // Breakfast
    this.serviceTimePercents.push({
      type: colorName[1],
      label: names[1],
      value: val,
    });
    val = 100.0 - total;
    // Breakfast
    this.serviceTimePercents.push({
      type: colorName[2],
      label: names[2],
      value: val,
    });
    //console.log('Updating');
    /*console.log('Service Times');
		console.log(this._allServices);
		console.log(this.serviceTimePercents);*/
    if (update) {
      this.serviceModified = true;
    }
  }

  saveServiceTimes() {
    let updates = [];
    for (let d of this._allServices) {
      updates.push({
        id: d.id,
        start: d.start,
        end: d.end,
      });
    }
    this.serviceTimeService.bulkUpdate(updates).subscribe(data => {
      this.serviceModified = false;
    });
  }
}
