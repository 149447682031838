<confirm-modal
  #confirmModal
  title="Change Owner?"
  action="make this user the owner of this location? You will be removed as the owner."
  (onConfirm)="changeType(UserType.Owner)"></confirm-modal>
<div *ngIf="user && userMap && loc">
  <div class="card">
    <div class="card-body">
      <label> Permissions Type for Employee:</label>
      <div class="row mb-2">
        <div class="col" *ngIf="getLocation().user_type==UserType.Owner">
          <div class="btn-group-toggle">
            <label
              class="btn btn-radio"
              [class.active]="userMap.user_type==UserType.Owner">
              <input
                type="radio"
                name="options"
                [value]="UserType.Owner"
                [(ngModel)]="userMap.user_type"
                (ngModelChange)="confirmModal.show()" />
              Owner
            </label>
          </div>
        </div>
        <div class="col">
          <div class="btn-group-toggle">
            <label
              class="btn btn-radio"
              [class.disabled]="userMap.user_type==UserType.Owner"
              [class.active]="userMap.user_type==UserType.Manager">
              <input
                type="radio"
                name="options"
                [value]="UserType.Manager"
                [disabled]="userMap.user_type==UserType.Owner"
                [(ngModel)]="userMap.user_type"
                (ngModelChange)="changeType(UserType.Manager)" />
              {{ loc.level_3 }}
            </label>
          </div>
        </div>
        <div class="col">
          <div class="btn-group-toggle">
            <label
              class="btn btn-radio"
              [class.disabled]="userMap.user_type==UserType.Owner"
              [class.active]="userMap.user_type==UserType.ShiftLead">
              <input
                type="radio"
                name="options"
                [value]="UserType.ShiftLead"
                [disabled]="userMap.user_type==UserType.Owner"
                [(ngModel)]="userMap.user_type"
                (ngModelChange)="changeType(UserType.ShiftLead)" />{{loc.level_2}}
            </label>
          </div>
        </div>
        <div class="col">
          <div class="btn-group-toggle">
            <label
              class="btn btn-radio"
              [class.disabled]="userMap.user_type==UserType.Owner"
              [class.active]="userMap.user_type==UserType.Regular">
              <input
                type="radio"
                name="options"
                [value]="UserType.Regular"
                [disabled]="userMap.user_type==UserType.Owner"
                [(ngModel)]="userMap.user_type"
                (ngModelChange)="changeType(UserType.Regular)" />{{loc.level_1}}
            </label>
          </div>
        </div>
      </div>
      Changing the permission type for an employee resets their permissions to
      the default for that employee type.<br />
      Settings altered here are for this employee only. Default permissions are
      set at Settings &gt; Permissions.
      <button
        type="button"
        class="btn btn-primary float-right"
        (click)="resetPerms()">
        Restore Defaults
      </button>
    </div>
  </div>

  <div class="card nav-padding" *ngIf="perms && locPerms">
    <div class="card-body">
      <ng-container *ngFor="let category of PermissionCategoryKeys">
        <div class="row">
          <div class="col-9">
            <label>{{category}}</label>
          </div>
          <div class="col-3">
            <h6 class="text-center"><b> Default</b></h6>
          </div>
        </div>
        <div class="row" *ngFor="let permId of PermissionCategory[category]">
          <div class="col-5">{{ PermissionNames[permId] }}</div>
          <div class="col-4">
            <input-toggle
              [(ngModel)]="perms[permId].value"
              (ngModelChange)="toggleEdit(perms[permId])">
            </input-toggle>
          </div>
          <div class="col-3">
            <div class="text-center">
              {{locPerms[permId][userMap.user_type].value?'On':'Off'}}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!--    <div class='card'>
	      <div class='card-body'>
	        <label>Staff
						<h6 class='float-right mb-0 mt-3'>Default</h6>
					</label>
	        <div class='row my-2'>
	            <div class='col align-self-center'>
	            Upload/Edit/Manage Docs &amp; Quizzes
	          </div>
        <div class='col-4'>
	            <div class="row no-gutters">
	              <div class="col-6">
	                <div class="btn-group-toggle">
	                <label class="btn btn-radio" [class.active]="pbutton==1">
	                  <input type="radio" name="options1" [value]='1' [(ngModel)]="pbutton">Yes
	                </label>
	                </div>
	              </div>
	              <div class="col-6">
	                <div class="btn-group-toggle">
	                <label class="btn btn-radio" [class.active]="pbutton==2">
	                  <input type="radio" name="options1" [value]='2' [(ngModel)]="pbutton">No
	                </label>
	                </div>
	              </div>
	            </div>
	          </div>
	          <div class='col-2 text-right align-self-center'>
	            No
	          </div>
	        </div>


				<div class='d-flex flex-row justify-content-between align-items-center'>
					 <div >
						 Create/Edit/Receive Purchase Order
					 </div>
					 <div class="d-inline-flex ml-auto pr-4">
						 <div class="btn-group-toggle ">
						 <label class="btn btn-radio" [class.active]="p1button==1">
							 <input type="radio" name="options1" [value]='1' [(ngModel)]="p1button">Yes
						 </label>
						 </div>
						 <div class="btn-group-toggle ">
						 <label class="btn btn-radio" [class.active]="p1button==2">
							 <input type="radio" name="options1" [value]='2' [(ngModel)]="p1button">No
						 </label>
						 </div>
					 </div>
					 <div >
						 Yes
					 </div>
				 </div>-->

  <div class="row mt-3">
    <div class="col-sm-12">
      <done-button class="float-right" routerLink="/users"></done-button>
      <!--			<save-button class='float-right' (click)="save()"></save-button>-->
    </div>
  </div>
</div>
