<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Trade Board"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Trade Board
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" *ngIf="ready">
        <div *ngIf="data.length==0" class="card">
          <div class="card-body">No active trades.</div>
        </div>
        <div class="card" *ngFor="let trade of data">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-12">
                <b>Position:</b> {{trade.position_name}} <br />
                <b>Trade Type:</b> {{TradeTypeNames[trade.trade_type]}}<br />
                <p>
                  <img
                    [src]="getUserProfileImage(trade.has_image) | safeUrl"
                    class="pr-2"
                    align="left"
                    style="height: 40px" />
                  <b *ngIf="trade.user_id"
                    >{{trade.firstname}} {{trade.lastname}}</b
                  >
                  <b *ngIf="!trade.user_id">Unassigned Shift</b>
                  <br />
                  {{trade.created_at| date: 'longDate'}} <br />
                  <span class="ml-4"
                    ><b
                      >{{trade.start | date:'EEEE MMMM d, y, h:mm a'}} until
                      {{trade.end | date:'EEEE MMMM d, y, h:mm a'}}</b
                    >
                  </span>
                </p>
              </div>
              <div class="col-md-6 col-12" *ngIf="trade.original_approval">
                <b>Trade To:</b><br />
                <p>
                  <img
                    [src]="getUserProfileImage(trade.shift_trade_user_has_image) | safeUrl"
                    class="pr-2"
                    style="height: 40px"
                    align="left" />
                  <b
                    >{{trade.shift_trade_user_firstname}}
                    {{trade.shift_trade_user_lastname}}</b
                  ><br />
                </p>
                <b *ngIf="trade.trade_shift_start"
                  >{{trade.trade_shift_start | date:'EEEE MMMM d, y, h:mm a'}}
                  until {{trade.trade_shift_end | date:'EEEE MMMM d, y, h:mm
                  a'}}</b
                ><br />
              </div>
            </div>

            <!-- Cancel my trade -->
            <div *ngIf="trade.user_id==getUserId()">
              <button
                type="button"
                class="btn btn-sm mt-2"
                (click)="cancelTrade(trade)">
                Cancel this trade
              </button>
            </div>

            <!-- Cancel a trade I'm on the other side of -->
            <div *ngIf="trade.trade_user_id==getUserId()">
              <button
                *ngIf="!trade.original_approval"
                type="button"
                class="btn btn-sm btn-primary float-right mx-2 mt-2"
                (click)="approveTrade(trade)">
                Approve trade
              </button>
              <button
                type="button"
                class="btn btn-sm mt-2"
                (click)="cancelTrade(trade)">
                Reject this trade
              </button>
            </div>

            <!-- Things I could pick up -->
            <div
              *ngIf="!trade.trade_user_id && trade.user_id!=getUserId() && !trade.original_approval && !showAll">
              <div *ngIf="trade.trade_type==TradeType.Pickup">
                <label> To pick up this trade, choose a shift to trade. </label>
                <strong *ngIf="trade.available.length==0"
                  >There are no shifts avilable for you to trade.</strong
                >
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio mb-1"
                    *ngFor="let s of trade.available | orderBy:'start'"
                    [class.active]="trade.selectedTrade==s">
                    <input
                      type="radio"
                      [name]="'shift_'+trade.id"
                      required
                      [value]="s"
                      [(ngModel)]="trade.selectedTrade" />
                    {{s.position_name}} - {{s.start | bliDate }} -
                    {{s.end|bliDate}}
                  </label>
                </div>
                <local-error [error]="trade.error"></local-error>
                <button
                  type="button"
                  *ngIf="trade.available.length>0"
                  [disabled]="!trade.selectedTrade"
                  class="btn btn-primary btn-sm float-right mt-2"
                  (click)="selectTrade(trade)">
                  Trade for this shift
                </button>
                <hr style="margin-top: 3rem" />
              </div>
              <div
                *ngIf="trade.trade_type!=TradeType.Pickup && !trade.original_approval && !showAll">
                <button
                  type="button"
                  class="btn btn-primary btn-sm float-right mt-2"
                  (click)="selectTrade(trade)">
                  Pick up this shift
                </button>
              </div>
              <div *ngIf="trade.ogininal_approval">
                <h4 class="text-success">
                  You have requested this shift. It is waiting approval.
                </h4>
                <button
                  type="button"
                  class="btn btn-primary btn-sm float-right mt-2">
                  Cancel pickup
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-2">
          <div class="col-sm-12">
            <button
              *ngIf="isAllowed(Permission.ApproveTrade)"
              type="button"
              (click)="modalApprove.show()"
              class="btn btn-primary">
              Approvals
            </button>
            <done-button class="float-right" (click)="cancel()"></done-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<modal-trade-board-approve
  (done)="load()"
  #modalApprove></modal-trade-board-approve>
