import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { baseRole } from '../shared/baseRole';
import {
  ContactGroupService,
  IContactGroup,
} from '../services/contactGroup.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './contacts.html',
})
export class ContactsComponent extends baseRole implements OnInit, OnDestroy {
  public sub;
  protected filterKey = 'contact_filter_key';
  public collapse = true;
  public vendorGroups: any[];
  public showHidden = false;
  public groupId = '';
  protected updateSub;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactGroupService: ContactGroupService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Vendors');
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.ViewVendor)) {
      this.router.navigate(['/']);
      return;
    }
    this.sub = this.route.url.subscribe(url => {
      //console.log(url);
      let filter = this.accountService.getTempState(this.filterKey, {});
      if ('contactGroupId' in filter) {
        this.groupId = filter.contactGroupId;
      }
    });

    this.updateSub = this.contactGroupService
      .getUpdateObservable()
      .subscribe(() => {
        this.load();
      });
    this.load();
  }

  ngOnDestroy() {
    if (this.updateSub) this.updateSub.unsubscribe();
    if (this.sub) this.sub.unsubscribe();
  }

  load() {
    this.contactGroupService.list().subscribe(data => {
      this.vendorGroups = [{ id: '', name: 'All Groups' }].concat(<any[]>data);
    });
  }

  toggleHidden() {
    this.showHidden = !this.showHidden;
    this.accountService.updateTempState(
      this.filterKey,
      'showHidden',
      this.showHidden
    );
    this.navigate();
  }

  navigate() {
    this.accountService.updateTempState(
      this.filterKey,
      'contactGroupId',
      this.groupId
    );
    if (this.showHidden) {
      this.router.navigate(['/contacts/', this.groupId], {
        queryParams: { hidden: true },
      });
    } else {
      this.router.navigate(['/contacts/', this.groupId]);
    }
  }
}
