import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { SignaturePadModule } from 'angular2-signaturepad';
import { DragulaModule } from 'ng2-dragula';

import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TimepickerModule, TimepickerConfig } from 'ngx-bootstrap/timepicker';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from 'ngx-bootstrap/datepicker';

import { MediaService } from '../services/media.service';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

Highcharts.setOptions({
  time: {
    useUTC: false,
  },
  colors: ['#7dd3fc', '#0ea5e9', '#1d2038', '#1d2038'],
});

import {
  DateMenuComponent,
  // Buttons
  AccordionButtonComponent,
  AddButtonComponent,
  AutoBackButtonComponent,
  BackButtonComponent,
  //CardHeaderComponent,
  CardSubHeaderComponent,
  CancelButtonComponent,
  ClearButtonComponent,
  CommentModalComponent,
  ConfirmModalComponent,
  DatetimeComponent,
  DateValueAccessor,
  DeleteButtonComponent,
  DeleteModalComponent,
  DoneButtonComponent,
  EditButtonComponent,
  //FileUploadComponent,
  GlobalErrorComponent,
  LoadingRowComponent,
  LoadingSmallComponent,
  LocalErrorComponent,
  LocalErrorArrayComponent,
  MessageButtonComponent,
  OkButtonComponent,
  PhoneLinkComponent,
  EmailLinkComponent,
  PrintButtonComponent,
  SaveButtonComponent,
  SearchButtonComponent,
  ViewButtonComponent,
  YesButtonComponent,
  YesNoSelectComponent,
  YesNoButtonComponent,
  InputToggleComponent,
  WorkPrefGraphComponent,

  // Formatting Directive
  PhoneFormattingDirective,
  EmailFormattingDirective,
  ThSortDirective,
  DebounceDirective,

  // Pipes
  DateFormattingPipe,
  DateLongFormattingPipe,
  DateShortFormattingPipe,
  DateMenuFormattingPipe,
  HoursFormattingPipe,
  MinutesFormattingPipe,
  MinutesDecimalFormattingPipe,
  TimeFormattingPipe,
  PriceFormattingPipe,
  FilterByPipe,
  FilterNotByPipe,
  FilterSoftByPipe,
  FilterByPagePipe,
  OrderByPipe,
  PhoneFormattingPipe,
  EmailFormattingPipe,
  SafeUrlPipe,
  SafeHtmlPipe,
} from './shared.component';

import { getTimepickerConfig, getDatepickerConfig } from './timepickerConfig';
import { CardAccountPackagesComponent } from './cardAccountPackages.component';
import { ExportToCsvComponent } from './export.component';
import { InputColorPickerComponent } from './colorPicker.component';
import { LocationAddComponent } from './locationAdd.component';
import { IntegrationOptionsComponent } from './integrationOptions.component';
import {
  DocQuizSignComponent,
  DocQuizSignAllComponent,
  DocQuizComponent,
  DocViewComponent,
} from './doc.component';
import {
  ChecklistListComponent,
  ChecklistEditComponent,
} from './checklist.component';
import { ModalDocStatsComponent } from './modalDocStats.component';
import { ModalDocSignComponent } from './modalDocSign.component';
import { ModalTimeClockEditComponent } from './modalTimeClockEdit.component';
import { ShiftPreferencesComponent } from './shiftPreferences.component';
import {
  SignatureComponent,
  SignatureDisplayComponent,
} from './signature.component';
import { WriteupSignComponent } from './writeupSign.component';
import { SubNavComponent } from './subnav.component';
import {
  ChartSummaryComponent,
  ChartBillingReportComponent,
  ChartLaborServiceComponent,
  ChartMainSalesComponent,
  ChartSalesReportComponent,
  ChartLaborPayrollComponent,
} from './charts.component';
import { ModalEventComponent } from './modalEvent.component';
import { ModalTaskComponent } from './modalTask.component';
import { ModalTradeBoardComponent } from './modalTradeBoard.component';
import { ModalTradeBoardApprovalComponent } from './modalTradeBoardApproval.component';
import { ModalTradeRequestComponent } from './modalTradeRequest.component';
import { ModalInvoiceComponent } from './modalInvoice.component';
import { ModalTimeOffComponent } from './modalTimeOff.component';
import { ModalTimeOffApprovalComponent } from './modalTimeOffApproval.component';
import { ModalPreferencesComponent } from './modalPreferences.component';
import { ModalMessageComponent } from './modalMessage.component';
import { ModalMessageReplyComponent } from './modalMessageReply.component';
import { ModalViewFileComponent } from './modalViewFile.component';
import { ModalViewComponent } from './modalView.component';
import {
  WidgetComponent,
  WidgetSalesLastWeekComponent,
  WidgetSalesThisWeekComponent,
  WidgetSalesThisMonthComponent,
  WidgetYTDQuartersComponent,
  WidgetYearToDateComponent,
  WidgetSalesPerWeekComponent,
  WidgetAvgSalesByWeekdayComponent,
  WidgetNoChartDataComponent,
} from './widgets/widget.component';
import { FreemiumComponent } from './freemium.component';
import { IconsModule } from '../app/icons/icons.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    HighchartsChartModule,
    FullCalendarModule,
    //PdfViewerModule,
    SignaturePadModule,
    DragulaModule.forRoot(),
    AlertModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    IconsModule,
  ],
  declarations: [
    DateMenuComponent,
    // Local Components
    AccordionButtonComponent,
    AddButtonComponent,
    AutoBackButtonComponent,
    BackButtonComponent,
    //CardHeaderComponent,
    CardSubHeaderComponent,
    CancelButtonComponent,
    ClearButtonComponent,
    CommentModalComponent,
    ConfirmModalComponent,
    DatetimeComponent,
    DateValueAccessor,
    DeleteButtonComponent,
    DeleteModalComponent,
    DoneButtonComponent,
    EditButtonComponent,
    //FileUploadComponent,
    GlobalErrorComponent,
    //HelpComponent,
    LoadingRowComponent,
    LoadingSmallComponent,
    LocalErrorComponent,
    LocalErrorArrayComponent,
    MessageButtonComponent,
    OkButtonComponent,
    PhoneLinkComponent,
    EmailLinkComponent,
    PrintButtonComponent,
    SaveButtonComponent,
    SearchButtonComponent,
    ViewButtonComponent,
    YesButtonComponent,
    YesNoSelectComponent,
    YesNoButtonComponent,
    InputToggleComponent,
    WorkPrefGraphComponent,

    ShiftPreferencesComponent,
    IntegrationOptionsComponent,
    ExportToCsvComponent,

    // Formatting Directive
    PhoneFormattingDirective,
    EmailFormattingDirective,
    ThSortDirective,
    DebounceDirective,

    // Pipes
    DateFormattingPipe,
    DateLongFormattingPipe,
    DateShortFormattingPipe,
    DateMenuFormattingPipe,
    HoursFormattingPipe,
    MinutesFormattingPipe,
    MinutesDecimalFormattingPipe,
    TimeFormattingPipe,
    PriceFormattingPipe,
    FilterByPipe,
    FilterByPagePipe,
    FilterNotByPipe,
    FilterSoftByPipe,
    OrderByPipe,
    PhoneFormattingPipe,
    EmailFormattingPipe,
    SafeHtmlPipe,
    SafeUrlPipe,

    InputColorPickerComponent,
    SubNavComponent,
    CardAccountPackagesComponent,
    ChartSummaryComponent,
    ChartBillingReportComponent,
    ChartMainSalesComponent,
    ChartLaborServiceComponent,
    ChartLaborPayrollComponent,
    ChartSalesReportComponent,
    ModalEventComponent,
    ModalTaskComponent,
    ModalTradeBoardComponent,
    ModalTradeBoardApprovalComponent,
    ModalTradeRequestComponent,
    ModalInvoiceComponent,
    ModalTimeOffComponent,
    ModalTimeOffApprovalComponent,
    ModalPreferencesComponent,
    ModalMessageComponent,
    ModalMessageReplyComponent,
    ModalViewFileComponent,
    ModalViewComponent,

    WidgetComponent,
    WidgetSalesLastWeekComponent,
    WidgetSalesThisWeekComponent,
    WidgetSalesThisMonthComponent,
    WidgetYTDQuartersComponent,
    WidgetYearToDateComponent,
    WidgetSalesPerWeekComponent,
    WidgetAvgSalesByWeekdayComponent,
    WidgetNoChartDataComponent,

    ChecklistListComponent,
    ChecklistEditComponent,
    DocQuizSignComponent,
    DocQuizSignAllComponent,
    DocViewComponent,
    DocQuizComponent,
    LocationAddComponent,
    ModalTimeClockEditComponent,
    ModalDocStatsComponent,
    ModalDocSignComponent,

    SignatureComponent,
    SignatureDisplayComponent,
    WriteupSignComponent,

    FreemiumComponent,
  ],
  providers: [
    MediaService,
    { provide: TimepickerConfig, useFactory: getTimepickerConfig },
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
  ],
  exports: [
    // Some of the global bits.
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    FullCalendarModule,
    //PdfViewerModule,
    AlertModule,
    CarouselModule,
    CollapseModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    PopoverModule,
    ProgressbarModule,
    TabsModule,
    TimepickerModule,
    TypeaheadModule,
    BsDatepickerModule,
    TooltipModule,
    DragulaModule,
    DateMenuComponent,
    // Local Components
    AccordionButtonComponent,
    AddButtonComponent,
    AutoBackButtonComponent,
    BackButtonComponent,
    //CardHeaderComponent,
    CardSubHeaderComponent,
    CancelButtonComponent,
    ClearButtonComponent,
    CommentModalComponent,
    ConfirmModalComponent,
    DatetimeComponent,
    DateValueAccessor,
    DeleteButtonComponent,
    DeleteModalComponent,
    DoneButtonComponent,
    EditButtonComponent,
    //FileUploadComponent,
    GlobalErrorComponent,
    //HelpComponent,
    LoadingRowComponent,
    LoadingSmallComponent,
    LocalErrorComponent,
    LocalErrorArrayComponent,
    MessageButtonComponent,
    OkButtonComponent,
    PhoneLinkComponent,
    EmailLinkComponent,
    PrintButtonComponent,
    SaveButtonComponent,
    SearchButtonComponent,
    ViewButtonComponent,
    YesButtonComponent,
    YesNoSelectComponent,
    YesNoButtonComponent,
    InputToggleComponent,
    WorkPrefGraphComponent,

    // Formatting Directive
    PhoneFormattingDirective,
    EmailFormattingDirective,
    ThSortDirective,
    DebounceDirective,

    ShiftPreferencesComponent,
    IntegrationOptionsComponent,
    ExportToCsvComponent,

    // Pipes
    DateFormattingPipe,
    DateLongFormattingPipe,
    DateShortFormattingPipe,
    DateMenuFormattingPipe,
    HoursFormattingPipe,
    MinutesFormattingPipe,
    MinutesDecimalFormattingPipe,
    TimeFormattingPipe,
    PriceFormattingPipe,
    FilterByPipe,
    FilterByPagePipe,
    FilterSoftByPipe,
    FilterNotByPipe,
    OrderByPipe,
    PhoneFormattingPipe,
    EmailFormattingPipe,
    SafeHtmlPipe,
    SafeUrlPipe,

    InputColorPickerComponent,
    SubNavComponent,
    CardAccountPackagesComponent,
    ChartSummaryComponent,
    ChartBillingReportComponent,
    ChartMainSalesComponent,
    ChartLaborServiceComponent,
    ChartLaborPayrollComponent,
    ChartSalesReportComponent,
    ModalEventComponent,
    ModalTaskComponent,
    ModalTradeBoardComponent,
    ModalTradeBoardApprovalComponent,
    ModalTradeRequestComponent,
    ModalInvoiceComponent,
    ModalTimeOffComponent,
    ModalTimeClockEditComponent,
    ModalTimeOffApprovalComponent,
    ModalPreferencesComponent,
    ModalMessageComponent,
    ModalMessageReplyComponent,
    ModalViewFileComponent,
    ModalViewComponent,

    WidgetComponent,
    WidgetSalesLastWeekComponent,
    WidgetSalesThisWeekComponent,
    WidgetSalesThisMonthComponent,
    WidgetYTDQuartersComponent,
    WidgetYearToDateComponent,
    WidgetSalesPerWeekComponent,
    WidgetAvgSalesByWeekdayComponent,
    WidgetNoChartDataComponent,

    ChecklistListComponent,
    ChecklistEditComponent,
    DocQuizSignComponent,
    DocQuizSignAllComponent,
    DocQuizComponent,
    DocViewComponent,
    LocationAddComponent,
    ModalTimeClockEditComponent,
    ModalDocStatsComponent,
    ModalDocSignComponent,

    SignatureComponent,
    SignatureDisplayComponent,
    WriteupSignComponent,

    FreemiumComponent,
  ],
})
export class SharedModule {}
