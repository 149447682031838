<div class="row" *ngIf="missed && missed.length>0">
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body">
        <label>Days missing logs</label>
        <div class="row">
          <div class="col-6 border-right" *ngFor="let m of missed">
            <div class="inline-block">
              <button
                type="button"
                class="btn btn-task btn-block mt-0"
                (click)="gotoLog(m)">
                {{ m | date: 'shortDate' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
