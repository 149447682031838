import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ISubNavItem, isIntegrationActive } from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { ModalSendUnverifiedComponent } from './modalSendUnverified.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './users.html',
})
export class UsersComponent extends baseRole implements OnInit {
  public menu: ISubNavItem[];
  @ViewChild('modalSendUnverified') modal: ModalSendUnverifiedComponent;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Staff');
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.EditStaff)) {
      this.router.navigate(['/']);
      return;
    }
    this.menu = [
      {
        label: 'Manage Staff',
        routerLink: '/users',
        exact: true,
        children: [
          {
            icon: 'cdi-m-staff-add',
            label: 'Add Staff',
            routerLink: '/users/user/add',
          },
          {
            icon: 'cdi-m-staff-email',
            label: 'Send Email to Unverified Staff',
            click: () => {
              this.sendUnverified();
            },
          },
        ],
      },
      {
        label: 'Staff Positions',
        routerLink: '/users/positions',
      },
    ];

    if (isIntegrationActive(this.getLocation())) {
      this.menu.push({
        label: 'Import Staff',
        routerLink: '/users/integration',
      });
    }

    this.menu.push({
      label: 'Time Off Log',
      routerLink: '/users/timeoff',
    });

    this.menu.push({
      label: 'Trade Board Log',
      routerLink: '/users/trade',
    });
  }

  sendUnverified() {
    this.modal.show();
  }
}
