<div class="row row-table row-header mt-2">
  <div class="col-4">Total Billing</div>
  <div class="col-2">
    {{ BillingPlanType[1] }}: {{ grandTotalByType[1] | currency }}
  </div>
  <div class="col-2">
    {{ BillingPlanType[2] }}: {{ grandTotalByType[2] | currency }}
  </div>
  <div class="col-2">
    {{ BillingPlanType[3] }}: {{ grandTotalByType[3] | currency }}
  </div>
  <div class="col-2">Declined: {{ grandTotalByType[0] | currency }}</div>
</div>
<chart-billing-report
  *ngIf="chart"
  [chartData]="chart"
  style="
    height: 400px;
    width: 100%;
    display: block;
    margin-top: 1rem;
  "></chart-billing-report>
<br /><br />
<div class="row">
  <div class="col-md-2">
    <label class="mb-0 mt-2">Select Month</label>
  </div>
  <div class="col-md-2">
    <select
      class="form-control"
      [(ngModel)]="dateKey"
      (ngModelChange)="filter()">
      <option *ngFor="let k of keys" [value]="k">{{k}}</option>
    </select>
  </div>
  <div class="col-md-8">
    <export-to-csv
      class="float-right"
      [data]="billing_csv"
      [filename]="'billing_'+dateKey+'.csv'"></export-to-csv>
  </div>
</div>

<div class="row row-table row-header mt-2" *ngIf="dateKey">
  <div class="col-4">Billing for month: {{ dateKey }}</div>
  <div class="col-2">
    {{ BillingPlanType[1] }}: {{ monthlyTotalByType[1] | currency }}
  </div>
  <div class="col-2">
    {{ BillingPlanType[2] }}: {{ monthlyTotalByType[2] | currency }}
  </div>
  <div class="col-2">
    {{ BillingPlanType[3] }}: {{ monthlyTotalByType[3] | currency }}
  </div>
  <div class="col-2">Declined: {{ monthlyTotalByType[0] | currency }}</div>
</div>
<div class="row row-table row-header mt-2" *ngIf="dateKey">
  <div class="col-2">Date</div>
  <div class="col-5">Location</div>
  <div class="col-2">Plan</div>
  <div class="col-1">Exp</div>
  <div class="col-1">Amount</div>
  <div class="col-1">Success</div>
</div>
<div class="row-table row-striped" *ngIf="billing && billing.length==0">
  <div class="col-12">None found</div>
</div>
<div class="row-table row-striped" *ngIf="billing && billing.length>0">
  <div
    class="row"
    *ngFor="let u of billing| orderBy: ['-created_at','id']"
    [class.bg-danger]="u.expiring"
    [class.text-white]="u.expiring">
    <div class="col-2">{{ u.created_at | bliDate}}</div>
    <div class="col-5">{{ u.name }}</div>
    <div class="col-2">
      {{ BillingPlanType[u.billing_plan_type] }}
      <span
        *ngIf="u.billing_plan_type != BillingPlanType.Monthly && u.billing_payment_options">
        -
        <span *ngFor="let val of u.billing_payment_options; let i = index">
          <span *ngIf="val">{{ BillingPaymentOption[i]}}</span>
        </span>
      </span>
    </div>
    <div class="col-1">
      <span
        *ngIf="u.billing_plan_type!=BillingPlanType.Monthly && u.billing_plan_exp"
        >{{ u.billing_plan_exp | date: 'shortDate' }}</span
      >
    </div>
    <div class="col-1">{{ u.amt | currency: 'USD' }}</div>
    <div class="col-1">{{ u.success?'Yes':'No'}}</div>
  </div>
</div>
