import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService, IApiLocation } from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { LocationService, ILocation } from '../services/location.service';
import { MediaService } from '../services/media.service';
import {
  Countries,
  Provinces,
  States,
  getUserProfileImage,
} from '../shared/api';
import {
  PasswordStrength,
  MinimumPasswordStrength,
} from '../shared/passwordStrength';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';

@Component({
  templateUrl: './profileEdit.html',
})
export class ProfileEditComponent extends baseRole implements OnInit {
  public saveMessage: string = null;
  public user: IUser;
  public userMap: ILocationUserMap;
  public loc: IApiLocation;
  public States = States;
  public Provinces = Provinces;
  public Countries = Countries;
  public StateKeys = Object.keys(States);
  public ProvinceKeys = Object.keys(Provinces);
  public CountryKeys = Object.keys(Countries);
  public fileData;
  public pTest = new PasswordStrength();

  public emailError: string;
  public oldPassword: string;
  public password: string;
  public cpassword: string;
  public errorPassword: string;
  public passwordSaved: string;
  public warning: string;
  public suggestions: string[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected userService: UserService,
    protected mediaService: MediaService,
    protected locationUserMapService: LocationUserMapService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.userService.get(this.accountService.getUserId()).subscribe(data => {
      this.user = data;
    });

    if (this.accountService.getLocation()) {
      this.locationUserMapService
        .list({
          user_id: this.accountService.getUserId(),
          location_id: this.accountService.getLocationId(),
        })
        .subscribe(data => {
          this.userMap = data[0];
        });
    }
  }

  handleFileChange(files) {
    if (!files || files.length == 0) this.fileData = null;
    else this.fileData = files[0];
  }

  getUserProfileImage(
    has_image: string,
    firstName?: string,
    lastName?: string,
    color?: string
  ): string {
    return getUserProfileImage(has_image, firstName, lastName, color);
  }

  checkPassword() {
    delete this.warning;
    delete this.suggestions;
    let [score, warning, suggestions] = this.pTest.measure(this.password);
    if (score < MinimumPasswordStrength) {
      this.warning = warning;
      this.suggestions = suggestions;
      return false;
    }
    return true;
  }

  matchPassword() {
    delete this.errorPassword;
    if (this.password !== this.cpassword) {
      this.errorPassword = 'Passwords do not match';
      return false;
    }
    return true;
  }

  checkEmail() {
    this.emailError = null;
    if (this.user.email === this.accountService.getUser().email) return;
    this.accountService.usernameExists(this.user.email).subscribe(match => {
      if (match) {
        this.emailError =
          'This email address is already in use. Please try using a different email address.';
      }
    });
  }
  changePassword() {
    this.errorPassword = null;
    if (this.password !== this.cpassword) {
      this.errorPassword = 'New Passwords do not match.';
      return;
    }
    this.accountService
      .changePassword(this.password, this.oldPassword)
      .subscribe(
        () => {
          this.passwordSaved = 'Success';
          this.accountService.getUser().jail_bad_password = false;
          this.password = this.cpassword = this.oldPassword = null;
          setTimeout(() => {
            this.passwordSaved = null;
          }, 3000);
        },
        err => {
          console.log('Error');
          console.log(err.error);
          this.errorPassword = err.error.__message;
        }
      );
  }

  save() {
    if (this.emailError) return;
    this.saveMessage = 'Saving now...';
    this.accountService.update(this.user).subscribe(data => {
      let user = this.accountService.getUser();
      user.firstname = data.firstname;
      user.lastname = data.lastname;
      this.locationUserMapService.update(this.userMap).subscribe(data => {
        this.accountService.setLocation().subscribe(() => {});
        if (this.fileData) {
          this.mediaService
            .upload(this.fileData, 'user', this.user.id)
            .subscribe(res => {
              //console.log('Media returned: '+res);
              this.user.has_image = res;
              this.accountService.getUser().has_image = res;
              this.saveMessage = 'Done';
              //						console.log(this.accountService.getUser());
              setTimeout(() => {
                this.saveMessage = null;
                this.router.navigate(['/profile']);
              }, 3000);
            });
        } else {
          this.saveMessage = 'Done';
          setTimeout(() => {
            this.saveMessage = null;
            this.router.navigate(['/profile']);
          }, 3000);
        }
      });
    });
  }
}
