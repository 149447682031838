<div class="row" *ngIf="loc && (loc.require_w4 || loc.require_i9)">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-12">
            W9 Template HEre<br />
            <input type="file" class="form-control" />
          </div>
          <div class="col-md-4 col-12">
            W9 Template HEre<br />
            <input type="file" class="form-control" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <doc-quiz-sign (count)="docCount=$event"></doc-quiz-sign>
    <div *ngIf="docCount==0">No documents are available right now.</div>
  </div>
</div>
