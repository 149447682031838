import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { RepeatOptions } from '../shared/api';

@Component({
  templateUrl: './testHome.html',
})
export class TestHomeComponent implements OnInit {
  public RepeatOptions = RepeatOptions;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {}

  ngOnInit() {}
}
