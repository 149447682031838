<div class="navbar navbar-expand-md navbar-light subnav">
  <select
    name="contact_group_id"
    [(ngModel)]="groupId"
    class="form-control d-inline-block mb-0 ml-2 my-auto"
    style="width: 10rem"
    (ngModelChange)="navigate()">
    <option *ngFor="let g of vendorGroups " [value]="g.id">{{g.name}}</option>
  </select>

  <span
    class="navbar-toggler cdi-m-soft-arrow border-circle m-1 pointer"
    [class.active]="!collapse"
    (click)="collapse=!collapse"></span>

  <div
    class="collapse navbar-collapse"
    [collapse]="collapse"
    (click)="$event.preventDefault()">
    <ul class="navbar-nav mr-auto" (click)="collapse=true">
      <li *ngIf="isAllowed(Permission.EditVendor)">
        <a routerLink="/contacts/group/" class="py-0">
          <span class="cdi-m-vendor-group float-left mt-1"></span>
          <span class="d-inline-block pt-3">Manage Groups</span>
        </a>
      </li>
      <li (click)="toggleHidden()">
        <a class="py-0">
          <span
            class="cdi-m-hidden float-left mt-1"
            [class.active]="showHidden"></span>
          <span class="d-inline-block pt-3">Hidden Vendors</span>
        </a>
      </li>
      <li class="navbar-item" *ngIf="isAllowed(Permission.EditVendor)">
        <a routerLink="/contacts/vendor/add" class="py-0">
          <span class="cdi-m-add-vendor float-left mt-1"></span>
          <span class="d-inline-block pt-3">Add Vendor</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="container-fluid subnav-padding">
  <router-outlet></router-outlet>
</div>
