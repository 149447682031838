import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, SelectAddAll } from '../services/account.service';
import { LogService } from '../services/log.service';
import { UserService, IUser } from '../services/user.service';
import { TimeClockService, ITimeClock } from '../services/timeClock.service';
import {
  DaysOfWeek,
  DaysOfWeekKeys,
  moment,
  LocationLaborType,
  RateType,
} from '../shared/api';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './payroll.html',
})
export class PayrollComponent extends baseRole implements OnInit {
  public users: any[];
  public DaysOfWeek = DaysOfWeek;
  public DaysOfWeekKeys = DaysOfWeekKeys;
  public showDetailed = true;
  public data;
  public loc;
  public total_tips = 0;
  public payrollPercent = 0;
  public done = false;
  public startPayroll;

  public summary = [];
  public detailed = [];

  public total_sales = 0;
  public scheduled_cost = 0;
  public scheduled_minutes = 0;
  public scheduled_ot_minutes = 0;
  public timeclock_minutes = 0;
  public timeclock_ot_minutes = 0;
  public actual_cost = 0;

  public search: any = {
    start: Date,
    end: Date,
    user_id: null,
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected logService: LogService,
    protected timeclockService: TimeClockService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.search.start = moment().startOf('month').toDate();
    this.search.end = new Date();
    this.search.end.setHours(0);
    this.search.end.setMinutes(0);
    this.search.end.setSeconds(0);
    this.search.end.setMilliseconds(0);
    this.loc = this.accountService.getLocation();

    this.userService.list().subscribe(data => {
      this.users = SelectAddAll(data, 'All Employees', 'lastname');
    });
    this.load();
  }

  setDetailed(val) {
    this.showDetailed = val;
  }

  load() {
    //console.log('Loading: '+this.showDetailed);
    //console.log(this.search);
    this.done = false;
    let search = {
      start: this.search.start,
      end: this.search.end,
      include_user_data: true,
      include_shift_data: true,
    };
    this.logService.getReport(search).subscribe(data => {
      this.data = data;
      this.calc();
    });
  }

  print() {
    window.print();
  }

  weekKey(date: any): number {
    let ret = Math.floor(this.startPayroll.diff(date, 'days') / -7);
    //		console.log(ret+"  = "+this.startPayroll+" : "+date+ " : "+this.startPayroll.diff(date,'days')+ " : "+this.startPayroll.diff(date,'days')/(-7));
    return ret;
  }
  endOfWeek(date: any): any {
    let weekOffset = this.weekKey(date);
    return moment(this.startPayroll).add(weekOffset * 7 + 6, 'days');
  }

  delete(obj) {
    this.timeclockService.delete(obj.id).subscribe(data => {
      this.load();
    });
  }

  calc() {
    let payrollDay = this.getLocation().payroll_day;
    this.startPayroll = moment(this.search.start);
    if (this.startPayroll.day() != payrollDay) {
      let offset = payrollDay - this.startPayroll.day();
      if (offset > 0) offset -= 7;
      this.startPayroll.add(offset, 'days');
    }
    //		console.log('Payroll Day');
    //		console.log(this.startPayroll.toDate());
    let totalWeeks = this.weekKey(this.search.end);

    this.payrollPercent =
      1 + this.accountService.getLocation().payroll_goal / 100;
    let laborType = this.loc.labor_type;
    let startWeek = moment(this.search.start).day(payrollDay);

    if (startWeek.isAfter(this.search.start)) {
      startWeek.add(-1, 'weeks');
    }

    let sumMap: any = {};

    this.total_sales = 0;
    this.scheduled_cost = 0;
    this.scheduled_minutes = 0;
    this.scheduled_ot_minutes = 0;
    this.timeclock_minutes = 0;
    this.timeclock_ot_minutes = 0;
    this.actual_cost = 0;
    this.total_tips = 0;
    for (let d of this.data) {
      this.total_sales += d.service_time[0].sales;
      this.scheduled_minutes +=
        d.scheduled.foh_minutes + d.scheduled.boh_minutes;
      this.scheduled_ot_minutes +=
        d.scheduled.ot_foh_minutes + d.scheduled.ot_boh_minutes;
      this.scheduled_cost +=
        d.scheduled.foh_cost +
        d.scheduled.boh_cost +
        d.scheduled.ot_foh_cost +
        d.scheduled.ot_boh_cost +
        d.scheduled.foh_cost +
        d.scheduled.boh_cost +
        d.scheduled.ot_foh_cost +
        d.scheduled.ot_boh_cost;
      this.actual_cost +=
        d.timeclock.foh_cost +
        d.timeclock.boh_cost +
        d.timeclock.ot_foh_cost +
        d.timeclock.ot_boh_cost +
        d.salary.foh_cost +
        d.salary.boh_cost;
      this.timeclock_minutes +=
        d.timeclock.foh_minutes + d.timeclock.boh_minutes;
      this.timeclock_ot_minutes +=
        d.timeclock.ot_foh_minutes + d.timeclock.ot_boh_minutes;

      for (let t of d.timeclocks) {
        this.total_tips += t.tips;
      }
      for (let u of d.users) {
        if (!(u.id in sumMap)) {
          sumMap[u.id] = {
            id: u.id,
            firstname: u.firstname,
            lastname: u.lastname,
            has_image: u.has_image,
            minutes: 0,
            ot_minutes: 0,
            cost: 0,
            ot_cost: 0,
            tips: 0,
            weekMap: new Array(totalWeeks + 1),
          };
          let s = moment(startWeek).add(6, 'days');
          for (let i = 0; i <= totalWeeks; i++, s.add(1, 'weeks')) {
            sumMap[u.id].weekMap[i] = {
              date: moment(s),
              minutes: 0,
              ot_minutes: 0,
              cost: 0,
              ot_cost: 0,
              tips: 0,
              timeclocks: <any[]>[],
            };
          }
        }
        let wKey = this.weekKey(d.date);

        if (u.salary) {
          sumMap[u.id].weekMap[wKey].cost +=
            u.salary.foh_cost + u.salary.boh_cost;
        } else {
          sumMap[u.id].weekMap[wKey].cost =
            u.timeclock.foh_cost + u.timeclock.boh_cost;
          sumMap[u.id].weekMap[wKey].ot_cost =
            u.timeclock.ot_foh_cost + u.timeclock.ot_boh_cost;
        }

        for (let t of u.timeclocks) {
          sumMap[u.id].minutes += t.minutes;
          sumMap[u.id].ot_minutes += t.ot_minutes;
          sumMap[u.id].weekMap[wKey].timeclocks.push(t);
          sumMap[u.id].weekMap[wKey].minutes += t.minutes;
          sumMap[u.id].weekMap[wKey].ot_minutes += t.ot_minutes;
          sumMap[u.id].weekMap[wKey].tips += t.tips;
        }
      }
    }

    for (let u in sumMap) {
      for (let w in sumMap[u].weekMap) {
        sumMap[u].cost += sumMap[u].weekMap[w].cost;
        sumMap[u].ot_cost += sumMap[u].weekMap[w].ot_cost;
        sumMap[u].tips += sumMap[u].weekMap[w].tips;
      }
    }
    //		console.log(sumMap);

    this.summary = [];
    for (let k in sumMap) {
      this.summary.push(sumMap[k]);
    }

    this.done = true;
  }
}
