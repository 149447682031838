<div class="mt-4" *ngIf="packageCount">
  <div class="row">
    <div class="col-md-4" *ngFor="let k of PackageTypeKeys">
      <div class="card shadow-sm">
        <div class="card-body text-center">
          <h4>{{PackageTypeNames[k]}}</h4>
          <h5>{{packageCount[k]}}</h5>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-4" *ngIf="data">
  <!-- Header -->
  <div class="row row-table bg-dark text-white font-weight-bold mb-2">
    <div class="col" sort fieldname="created_at" [(orderBy)]="orderByField">
      Signup Date
    </div>
    <div class="col" sort fieldname="lastname" [(orderBy)]="orderByField">
      Contact Name
    </div>
    <div class="col" sort fieldname="name" [(orderBy)]="orderByField">
      Location Name
    </div>
    <div class="col" sort fieldname="package_type" [(orderBy)]="orderByField">
      Package
    </div>
    <div
      class="col"
      sort
      fieldname="sales_integration_type"
      [(orderBy)]="orderByField">
      API Type
    </div>
    <div
      class="col"
      sort
      fieldname="sales_integration_update"
      [(orderBy)]="orderByField">
      API Update
    </div>
    <div class="col" sort fieldname="num_employees" [(orderBy)]="orderByField">
      # Employees
    </div>
    <div class="col" sort fieldname="last_schedule" [(orderBy)]="orderByField">
      Last Schedule
    </div>
    <div class="col" sort fieldname="last_login" [(orderBy)]="orderByField">
      Last Login
    </div>
    <div
      class="col"
      sort
      fieldname="payment_failed_at"
      [(orderBy)]="orderByField">
      Payment
    </div>
  </div>

  <!-- Data Rows -->
  <div class="row-table row-striped">
    <div
      *ngFor="let d of data | orderBy: orderByField"
      [class.text-danger]="!d.payment_status"
      class="row mb-1">
      <div class="col">{{ d.created_at | bliDate }}</div>
      <div class="col">{{ d.firstname }} {{ d.lastname}}</div>
      <div class="col">
        <a [href]="'/admin/location/'+d.id">{{ d.name }}</a>
      </div>
      <div class="col">{{ PackageTypeNames[d.package_type]}}</div>
      <div class="col" *ngIf="d.sales_integration_type && !d.food_vendor_integration">
        {{ SalesIntegrationType[d.sales_integration_type] }}
      </div>
      <div class="col" *ngIf="!d.sales_integration_type && d.food_vendor_integration">
        {{ d.food_vendor_integration }}
      </div>
      <div class="col" *ngIf="d.sales_integration_type && d.food_vendor_integration">
        {{ SalesIntegrationType[d.sales_integration_type] }},
        {{ d.food_vendor_integration }}
      </div>
      <div class="col" *ngIf="!d.sales_integration_type && !d.food_vendor_integration"></div>
      <div class="col">{{ d.sales_integration_update | bliDate}}</div>
      <div class="col">{{ d.num_employees }}</div>
      <div class="col">{{ d.last_schedule | bliShortDate}}</div>
      <div class="col">{{ d.last_login| bliDate}}</div>
      <div class="col">{{ d.payment_failed_at| bliDate}}</div>
    </div>
  </div>
</div>
