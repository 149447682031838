import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService, IUser } from '../services/account.service';
import { ContactService, IContact } from '../services/contact.service';

interface IVendorFilter {
  firstChar?: number;
  contactGroupId?: number;
  showHidden?: boolean;
}

@Component({
  templateUrl: './home.html',
})
export class HomeComponent extends baseRole implements OnInit {
  public vendors: IContact[]; // Vendors
  public filteredVendors: IContact[];
  public letters: any[]; // Array of the letters
  protected filterKey = 'contact_filter_key';

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactService: ContactService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.filterVendors();
    });

    // Get updates on the route
    this.route.params.subscribe(params => {
      this.filterVendors();
    });

    this.contactService.list().subscribe(data => {
      this.vendors = data;
      this.filterVendors();
    });
  }

  filterVendors() {
    if (!this.vendors) return;

    let filter = this.accountService.getTempState(this.filterKey, {});
    if (typeof filter.firstChar == 'undefined') {
      filter.firstChar = null;
    }
    this.letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ?'.split('').map(e => {
      return { name: e, hasVendor: false };
    });

    this.filteredVendors = this.vendors.filter(v => {
      let idx = this.stringToIdx(v.company);
      if (!filter.showHidden && v.hide) return false;
      this.letters[idx].hasVendor = true;
      if (filter.contactGroupId && filter.contactGroupId != v.contact_group_id)
        return false;
      if (
        filter.firstChar === null ||
        (filter.firstChar !== null && idx == filter.firstChar)
      ) {
        if (filter.firstChar !== null) {
          this.letters[idx].selected = true;
        }
        return true;
      }
      return false;
    });
  }

  selectChar(letter: any) {
    if (!letter.hasVendor) return;
    let idx = this.stringToIdx(letter.name);
    this.accountService.updateTempState(this.filterKey, 'firstChar', idx);
    this.filterVendors();
  }

  unsetChar() {
    let filter = this.accountService.getTempState(this.filterKey, {});
    filter.firstChar = null;
    this.accountService.setTempState(this.filterKey, filter);
    this.filterVendors();
  }

  stringToIdx(c: string): number {
    if (!c || c.length == 0 || c == '?') return 26;
    let idx = c.toUpperCase().charCodeAt(0) - 65;
    if (idx > 25 || idx < 0) idx = 26;
    return idx;
  }

  toggleView(obj) {
    obj.hide = !obj.hide;
    this.contactService
      .update({ id: obj.id, hide: obj.hide })
      .subscribe(result => {
        this.filterVendors();
      });
  }
}
