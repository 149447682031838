<!-- used by profile
<div class='card' *ngIf="data && data.length>0">
	<div class='card-body'>
		<label>docs to sign / quizzes to take</label>
		<div class='row'>
			<div class='col my-3' *ngFor="let d of data">
				<doc-view [doc]="d"></doc-view>
			</div>
		</div>
	</div>
</div>-->
<div class="nav-padding"></div>
<div *ngIf="data && data.length>0">
  <label>docs to sign / quizzes to take</label>
  <div class="row">
    <!--		<div class='col' *ngFor="let d of data">
			<div class='card my-1' style='width: 12.8rem'>
				<div class='card-body'>
					<doc-view [doc]="d"></doc-view>
				</div>
			</div>
		</div>  -->

    <div class="d-flex justify-content-around" *ngFor="let d of data">
      <div class="card my-1 mx-2" style="width: 12.8rem">
        <div class="card-body">
          <doc-view [doc]="d"></doc-view>
        </div>
      </div>
    </div>
  </div>
</div>
