import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import {
  AccountService,
  IUser,
  IApiLocation,
} from '../services/account.service';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';
import { ReviewService, IReview } from '../services/review.service';
import { SignatureService, ISignature } from '../services/signature.service';
import { WriteUpService, IWriteUp } from '../services/writeUp.service';
import { WidgetType, WidgetNames, getBaseUrl } from '../shared/api';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  templateUrl: './rewriteup.html',
})
export class ReWriteupComponent extends baseRole implements OnInit {
  public reviews: any[];
  public writeups: any[];
  public isReview = true;
  public data: any[];
  public baseUrl = getBaseUrl();

  @ViewChild('tabs') tabs: TabsetComponent;

  @ViewChild('writeupSign') modal;
  public signingWriteup;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    public writeUpService: WriteUpService,
    public reviewService: ReviewService,
    public signatureService: SignatureService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.reviewService.list({ user_id: this.getUserId() }).subscribe(data => {
      this.reviews = data;
      if (this.isReview) {
        this.data = data;
      }
    });
    this.writeUpService.list({ user_id: this.getUserId() }).subscribe(data => {
      this.writeups = data;
      this.isReview =
        data.filter(e => {
          return !(<any>e).signature_id;
        }).length == 0;
      if (!this.isReview) {
        this.tabs.tabs[1].active = true;
        this.data = data;
      }
    });
  }

  changeTab(isReview: boolean) {
    this.isReview = isReview;
    this.data = this.isReview ? this.reviews : this.writeups;
  }

  showSig(writeup) {
    this.signingWriteup = writeup;
    this.modal.show(writeup);
  }

  doWriteupSign() {
    // Dunno if we have to do anything.
  }
}
