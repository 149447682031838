<div class="row" *ngIf="checklist && checklist.length>0">
  <checklist-edit #checklistEdit></checklist-edit>
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body">
        <label>{{_date | date: 'EEEE, MMMM d'}} Checklists</label>
        <div class="inline-block">
          <button
            (click)="checklistEdit.show(d,_date)"
            type="button"
            class="btn btn-task btn-block mt-0 mb-1"
            style="text-align: left !important"
            *ngFor="let d of checklist | orderBy:'start'">
            {{d.name}}
            <span class="float-right text-orange" *ngIf="d.before"
              >COMING UP</span
            >
            <span class="float-right text-danger" *ngIf="d.missed">MISSED</span>
            <span class="float-right text-success" *ngIf="d.pending">OPEN</span>
            <span class="float-right text-dark" *ngIf="d.status">DONE</span>
            <br />
            {{ d.start | date: 'M/d/yy h:mm a'}} - {{ d.end | date:
            'shortTime'}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
