import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TimeoutService } from './timeout.service';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(public timeoutService: TimeoutService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.timeoutService.resetTimeout();
    return next.handle(request);
  }
}
