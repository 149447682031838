import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import {
  NgModel,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { noop } from './api';

function singleColorTpl(color, index, picked) {
  var pickedClass = picked ? 'color-picker-single-picked' : '';
  return (
    '<div class="color-picker-single ' +
    pickedClass +
    '" tabindex="' +
    index +
    '" data-color="' +
    color +
    '" style="background-color:' +
    color +
    '"></div>'
  );
}

export const CUSTOM_INPUTTOGGLE_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputColorPickerComponent),
  multi: true,
};
@Component({
  selector: 'input-color',
  templateUrl: 'colorPicker.html',
  providers: [CUSTOM_INPUTTOGGLE_CONTROL_VALUE_ACCESSOR],
})
export class InputColorPickerComponent implements ControlValueAccessor {
  public _value: string;
  public colors = [
    /*	"#1abc9c",
		"#16a085",
		"#2ecc71",
		"#27ae60",
		"#00FF00",
		"#3498db",
		"#2980b9",
		"#0000FF",
		"#9b59b6",
		"#8e44ad",
		"#34495e",
		"#2c3e50",
		"#f1c40f",
		"#f39c12",
		"#e67e22",
		"#d35400",
		"#e74c3c",
		"#c0392b",
		"#FF0000",
		"#FFFFFF",
		"#bdc3c7",
		"#95a5a6",
		"#7f8c8d",
		"#000000", */

    '#800000', //maroon
    '#C71585', //mediumvioletred
    '#B22222', //firebrick
    '#FA8072', //salmon
    '#D2691E', //chocolate
    '#FF6347', //tomato
    '#FFA500', //orange
    '#DAA520', //goldenrod
    '#FFD700', //gold
    '#FFFF00', //yellow
    '#9ACD32', //yellowgreen
    '#ADFF2F', //greenyellow
    '#6B8E23', //olivedrab
    '#3CB371', //mediumseagreen
    '#228B22', //forestgreen
    '#008080', //teal
    '#7FFFD4', //aquamarine
    '#AFEEEE', //paleturquoise
    '#1E90FF', //dodgerblue
    '#0000FF', //blue
    '#000080', //navy
    '#7B68EE', //mediumslateblue
    '#663399', //rebeccapurple
    '#BA55D3', //mediumorchid
    '#EE82EE', //violet
    '#FFC0CB', //pink
    '#FF1493', //deeppink
    '#D8BFD8', //thistle
    '#BC8F8F', //rosybrown
    '#D2B48C', //tan
    '#808080', //grey
    '#2F4F4F', //darkslategray
    '#BDB76B', //darkkahki
  ];

  @Input() placement: string = 'left'; // Options are top,right,bottom,left,auto
  @ViewChild('pop')
  public pop: any;

  constructor() {}

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): string {
    return this._value;
  }

  //set accessor including call the onchange callback
  set value(v: string) {
    let needsUpdate = v != this._value;
    /*if((<any>v)==null ||(<any>v)==0)  {
			v = false;
			needsUpdate = true;
		}
		if (v !== this._value) {
			this._value = v;
			needsUpdate = true;
		}*/
    this._value = v;
    if (needsUpdate) this.onChangeCallback(this._value);
  }

  //From ControlValueAccessor interface
  writeValue(v: any) {
    if (v == null || v == 0) {
      v = false;
      this.onChangeCallback(this._value);
    }
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(this._value);
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  selectColor(color) {
    console.log('Got Click: ' + color);
    this.value = color;
    this.pop.hide();
  }
}
