import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { AdminModule } from '../admin/admin.module';
import { CalendarModule } from '../calendar/calendar.module';
import { ContactsModule } from '../contacts/contacts.module';
import { DocsModule } from '../docs/docs.module';
import { InventoryModule } from '../inventory/inventory.module';
import { LogModule } from '../log/log.module';
import { MessagesModule } from '../messages/messages.module';
import { ProfileModule } from '../profile/profile.module';
import { ReportsModule } from '../reports/reports.module';
import { ScheduleModule } from '../schedule/schedule.module';
import { SettingsModule } from '../settings/settings.module';
import { FinancingModule } from '../financing/financing.module';
import {
  AuthGuard,
  AuthGuardFreemium,
  AuthGuardJail,
  AuthGuardAdmin,
} from './auth-guard';
import { AccountService } from '../services/account.service';
import { BillingPlanService } from '../services/billingPlan.service';
import { BlackOutService } from '../services/blackOut.service';
import { ContactService } from '../services/contact.service';
import { ChecklistCompletedService } from '../services/checklistCompleted.service';
import { ChecklistItemCompletedService } from '../services/checklistItemCompleted.service';
import { MessageCommentService } from '../services/messageComment.service';
import { DocService } from '../services/doc.service';
import { EventService } from '../services/event.service';
import { EventAssignmentService } from '../services/eventAssignment.service';
import { InvoiceService } from '../services/invoice.service';
import { InvoiceItemService } from '../services/invoiceItem.service';
import { LogService } from '../services/log.service';
import { LogCommentService } from '../services/logComment.service';
import { LogNoteService } from '../services/logNote.service';
import { MessageService } from '../services/message.service';
import { MessageRecipientService } from '../services/messageRecipient.service';
import { PositionService } from '../services/position.service';
import { ShiftService } from '../services/shift.service';
import { ShiftTradeService } from '../services/shiftTrade.service';
import { TimeOffService } from '../services/timeOff.service';
import { TimeoutService } from '../services/timeout.service';
import { TimezoneService } from '../services/timezone.service';
import { UserService } from '../services/user.service';
import { UserCreditCardService } from '../services/userCreditCard.service';

import { TimeoutInterceptor } from '../services/timeout.interceptor';

import { AppComponent } from './app.component';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgotPassword.component';
import { PasswordResetComponent } from './passwordReset.component';
import { RegisterComponent } from './register.component';
import { VerifyComponent } from './verify.component';

import {
  HomeComponent,
  HomeScheduleRequestsComponent,
  HomeCalendarComponent,
  HomeLogSkippedComponent,
  HomeShiftListComponent,
  HomeRepairTodoComponent,
  HomeMessagesComponent,
} from '../home.component';
import { TimeclockComponent } from '../timeclock.component';
import { SearchComponent } from '../search.component';
import { FreemiumPageComponent } from './freemiumPage.component';

import { UsersModule } from '../users/users.module';
import { IconsModule } from './icons/icons.module';
import { routing } from './app.routing';

@NgModule({
  imports: [
    BrowserModule,
    routing,

    AdminModule,
    CalendarModule,
    ContactsModule,
    DocsModule,
    InventoryModule,
    LogModule,
    MessagesModule,
    ProfileModule,
    ReportsModule,
    ScheduleModule,
    SettingsModule,
    UsersModule,
    SharedModule,
    IconsModule,
    FinancingModule,
  ],
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    FreemiumPageComponent,
    HomeComponent,
    HomeCalendarComponent,
    HomeScheduleRequestsComponent,
    HomeLogSkippedComponent,
    HomeMessagesComponent,
    HomeShiftListComponent,
    HomeRepairTodoComponent,
    LoginComponent,
    RegisterComponent,
    SearchComponent,
    TimeclockComponent,
    VerifyComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-N8SFZ3B',
    },
    AuthGuard,
    AuthGuardFreemium,
    AuthGuardJail,
    AuthGuardAdmin,
    AccountService,
    BillingPlanService,
    BlackOutService,
    ChecklistCompletedService,
    ChecklistItemCompletedService,
    ContactService,
    DocService,
    EventService,
    EventAssignmentService,
    LogService,
    LogCommentService,
    LogNoteService,
    InvoiceService,
    InvoiceItemService,
    MessageService,
    MessageCommentService,
    MessageRecipientService,
    PositionService,
    ShiftService,
    ShiftTradeService,
    TimeOffService,
    TimeoutService,
    TimezoneService,
    UserService,
    UserCreditCardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
