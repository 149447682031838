<div class="card">
  <div class="card-header">Filters</div>
  <div class="col-12 card-body">
    <div class="row">
      <div class="col-md-4 col-12">
        <label>Name</label>
        <input
          type="text"
          class="form-control"
          name="filter_name"
          [(ngModel)]="filterObject.name"
          (ngModelChange)="filter()" />
      </div>
      <div class="col-md-4 col-12">
        <div class="row">
          <div class="col">
            <label>Date Start</label>
            <input
              type="text"
              class="form-control"
              name="filter_date_start"
              [(ngModel)]="filterObject.date_start"
              bsDatepicker
              (ngModelChange)="filter()" />
          </div>
          <div class="col">
            <label>Date End</label>
            <input
              type="text"
              class="form-control"
              name="filter_date_end"
              [(ngModel)]="filterObject.date_end"
              bsDatepicker
              (ngModelChange)="filter()" />
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <label>Trade Type</label>
        <select
          class="form-control"
          name="filter_type"
          [(ngModel)]="filterObject.trade_type"
          (ngModelChange)="filter()">
          <option [value]="0">All</option>
          <option *ngFor="let k of TradeTypeKeys" [value]="k">
            {{ TradeTypeNames[k]}}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>

<div *ngIf="data" class="card">
  <div class="card-body card-header card-table">
    <div class="col-12 row card-table-header">
      <div class="col-2" sort fieldname="full_name" [(orderBy)]="orderByField">
        Name
      </div>
      <div class="col-2" sort fieldname="created_at" [(orderBy)]="orderByField">
        Requested
      </div>
      <div class="col-2" sort fieldname="updated_at" [(orderBy)]="orderByField">
        Updated
      </div>
      <div
        class="col-2"
        sort
        fieldname="final_approval"
        [(orderBy)]="orderByField">
        Approved
      </div>
      <div class="col-2" sort fieldname="trade_type" [(orderBy)]="orderByField">
        Type
      </div>
      <div class="col-2" sort fieldname="hours" [(orderBy)]="orderByField">
        Hours
      </div>
    </div>
  </div>
  <div
    class="card-body card-table"
    *ngFor="let d of data | orderBy: orderByField | filterByPage: [ currentPage, maxPerPage ]">
    <div class="col-12 row">
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Name:</div>
            <div class="col-6">{{ d.full_name }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">{{ d.full_name }}</div>
      </div>
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Requested:</div>
            <div class="col-6">{{ d.created_at | date: 'shortDate' }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">
          {{ d.created_at | date: 'shortDate' }}
        </div>
      </div>
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Updated:</div>
            <div class="col-6">
              {{ (d.final_approval || d.original_approval)| date: 'shortDate' }}
            </div>
          </div>
        </div>
        <div class="d-none d-md-block">
          {{ (d.final_approval || d.original_approval)| date: 'shortDate' }}
        </div>
      </div>
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Approved:</div>
            <div class="col-6">{{ ( d.final_approval ? 'Yes': 'No' )}}</div>
          </div>
        </div>
        <div class="d-none d-md-block">
          {{ ( d.final_approval ? 'Yes': 'No' )}}
        </div>
      </div>
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Type:</div>
            <div class="col-6">{{ TradeTypeNames[d.trade_type] }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">{{ TradeTypeNames[d.trade_type] }}</div>
      </div>
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Hours:</div>
            <div class="col-6">{{ d.hours }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">{{ d.hours }}</div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="data && data.length>maxPerPage">
    <div class="col-12 d-flex justify-content-center mt-4">
      <pagination
        [totalItems]="data.length"
        [maxSize]="10"
        [itemsPerPage]="maxPerPage"
        class="pagination-sm"
        [(ngModel)]="currentPage"
        [boundaryLinks]="true"
        [rotate]="false">
      </pagination>
    </div>
  </div>
</div>
