import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { FinancingComponent } from './financing.component';
import { HomeComponent } from './home.component';

import { IconsModule } from 'source/app/icons/icons.module';
import { routing } from './financing.routing';
import { CapitalService } from 'source/services/capital.service';

@NgModule({
  declarations: [FinancingComponent, HomeComponent],
  imports: [SharedModule, routing, IconsModule],
  providers: [CapitalService],
})
export class FinancingModule {}
