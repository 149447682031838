import { Component, ViewChild, OnInit, Input, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { PositionService, IPosition } from '../services/position.service';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';
import {
  EmployeePositionService,
  IEmployeePosition,
} from '../services/employeePosition.service';
import {
  Countries,
  Provinces,
  RateType,
  States,
  UserType,
  SelectAddAll,
  getUserProfileImage,
} from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { LocationService, ILocation } from '../services/location.service';
import {
  PermissionLocationService,
  IPermissionLocation,
  Permission,
  PermissionCategory,
  PermissionNames,
} from '../services/permissionLocation.service';
import {
  PermissionUserService,
  IPermissionUser,
} from '../services/permissionUser.service';

@Component({
  templateUrl: 'userEdit.html',
})
export class UserEditComponent extends baseRole implements OnInit {
  public user: IUser;
  public userMap: ILocationUserMap;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public accountService: AccountService,
    public userService: UserService,
    public locationUserMapService: LocationUserMapService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.locationUserMapService
        .list({ user_id: id, location_id: this.accountService.getLocationId() })
        .subscribe(data => {
          this.userMap = data[0];
        });
      this.userService.get(id).subscribe(data => {
        this.user = data;
        if (!('permission_override' in this.user)) {
          this.user.permission_override = {};
        }
      });
    });
  }
}

@Component({
  selector: 'user-edit-employee',
  templateUrl: 'userEdit.employee.html',
})
export class UserEditEmployeeComponent {
  @Input() user: IUser;
  @Input() userMap: ILocationUserMap;
  public States = States;
  public Provinces = Provinces;
  public Countries = Countries;
  public StateKeys = Object.keys(States);
  public ProvinceKeys = Object.keys(Provinces);
  public CountryKeys = Object.keys(Countries);
  public error: any = {};
  constructor(
    protected router: Router,
    public userService: UserService,
    public locationUserMapService: LocationUserMapService,
    public accountService: AccountService
  ) {}

  getUserProfileImage(): string {
    return getUserProfileImage(this.user.has_image);
  }

  save() {
    if ('global' in this.error) {
      delete this.error.global;
    }
    if (Object.keys(this.error).length > 0) {
      this.error.global = 'Please fix the errors before saving';
      return false;
    }
    this.userService.update(this.user).subscribe(data => {});
    this.locationUserMapService.update(this.userMap).subscribe(data => {});
    this.router.navigate(['/users']);
  }

  checkPin(pin: string) {
    this.error = {};
    return this.locationUserMapService
      .checkPinExists({
        user_id: this.user.id,
        location_id: this.accountService.getLocationId(),
        pin: pin,
      })
      .subscribe(data => {
        if (data) {
          this.error.pin = 'This pin needs to be unique';
        }
      });
  }
}

@Component({
  selector: 'user-edit-work-pref',
  templateUrl: 'userEdit.workpref.html',
})
export class UserEditWorkPrefComponent {
  @Input() user: IUser;
  @Input() userMap: ILocationUserMap;

  constructor(
    protected router: Router,
    public locationUserMapService: LocationUserMapService
  ) {}

  save() {
    //console.log('Saving');
    this.locationUserMapService
      .update({ id: this.userMap.id, pref_dna: this.userMap.pref_dna })
      .subscribe(() => {});
    this.router.navigate(['/users']);
  }
}

@Component({
  selector: 'user-edit-role-pos',
  templateUrl: 'userEdit.rolepos.html',
})
export class UserEditRolePosComponent extends baseRole implements OnInit {
  @Input() user: IUser;
  @Input() userMap: ILocationUserMap;
  public allPositions: IPosition[];
  public availablePositions: IPosition[];
  public myPositions: any[];
  public historyPositions: any[];
  public newPosition: any;
  public RateType = RateType;
  public RateTypeKeys = Object.keys(RateType).filter(Number);
  public loc: IApiLocation;
  public isSalary = false;
  public newPositionError: string;

  constructor(
    public router: Router,
    protected positionService: PositionService,
    protected employeePositionService: EmployeePositionService,
    public accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.positionService.list().subscribe(data => {
      this.allPositions = data;
      this.filterPositions(true);
    });
    this.loc = this.getLocation();
  }

  deletePosition(obj) {
    this.employeePositionService
      .update({ id: obj.id, active: false, end: new Date() })
      .subscribe(data => {
        obj.active = false;
        this.filterPositions(false);
      });
  }

  editPosition(obj) {
    obj._edit = Object.assign({}, obj);
  }

  cancelPosition(obj) {
    obj = Object.assign(obj, obj._edit);
    delete obj._edit;
  }

  savePosition(obj, routeOnDone = false) {
    obj.user_id = this.user.id;
    this.employeePositionService.update(obj).subscribe(data => {
      this.filterPositions(!obj.id);
      if (routeOnDone) {
        this.router.navigate(['/users']);
      }
    });
  }

  addPosition(routeOnDone = false) {
    this.newPositionError = null;
    if (!this.newPosition.position_id) {
      this.newPositionError = 'Position is a required field';
      return;
    }

    if (!this.newPosition.rate) {
      this.newPositionError = 'Rate is a required field';
      return;
    }
    if (!this.newPosition.rate_type) {
      this.newPositionError = 'Rate Type is a required field';
      return;
    }
    this.savePosition(this.newPosition);
  }

  initializePosition() {
    this.newPosition = {
      rate_type: RateType.Hourly,
      rate: 10.0,
      is_primary: false,
      active: true,
      start: new Date(),
      position_id: null,
    };
  }

  // BUG BUG BUG This needs to be per position.
  blockHistoryEdit() {
    let max = new Date();
    let obj: any = null;
    for (let p of this.historyPositions) {
      //			console.log(p.start);
      //			console.log(max);
      if (p.start < max) {
        max = p.start;
        obj = p;
      }
    }
    /*console.log('Fixing');
		console.log(obj);*/
    if (obj) {
      obj._blockEdit = true;
    }
  }

  filterPositions(doInit: boolean) {
    this.employeePositionService
      .list({ location_id: this.getLocationId(), user_id: this.user.id })
      .subscribe(data => {
        this.myPositions = data.filter(e => {
          return e.active;
        });
        this.historyPositions = data.filter(e => {
          if (e.active) return false;
          if (
            e.rate_type == RateType.Salary &&
            this.isAllowed(this.Permission.EditSalaries)
          )
            return true;
          if (
            e.rate_type == RateType.Hourly &&
            this.isAllowed(this.Permission.EditHourlyWages)
          )
            return true;
          return false;
        });
        this.blockHistoryEdit();
        let myPosIds: any = {};
        this.isSalary = false;
        for (let m of this.myPositions) {
          if (m.rate_type == RateType.Salary) this.isSalary = true;
          myPosIds[m.position_id] = true;
          m.exists = false; //  position deleted?
          for (let p of this.allPositions) {
            if (m.position_id == p.id) m.exists = true;
          }
        }
        /*			console.log("mypositions");
			console.log(this.myPositions);*/
        this.availablePositions = this.allPositions.filter(e => {
          return !(e.id in myPosIds);
        });
        if (this.availablePositions.length > 0) {
          SelectAddAll(this.availablePositions, 'Select a Position');
        }
        if (doInit) {
          this.initializePosition();
        }
        this.newPosition.is_primary = this.myPositions.length == 0;
      });
  }
}

@Component({
  selector: 'user-edit-permissions',
  templateUrl: 'userEdit.permissions.html',
})
export class UserEditPermissionsComponent extends baseRole implements OnInit {
  @Input() user: IUser;
  @Input() userMap: ILocationUserMap;
  public loc: IApiLocation;
  public perms: any[];
  public locPerms: any[];
  public UserType = UserType;
  public PermissionCategory = PermissionCategory;
  public PermissionCategoryKeys = Object.keys(PermissionCategory);
  public PermissionNames = PermissionNames;

  constructor(
    public accountService: AccountService,
    public locationService: LocationService,
    public userService: UserService,
    public locationUserMapService: LocationUserMapService,
    protected permissionLocationService: PermissionLocationService,
    protected permissionUserService: PermissionUserService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.loc = this.accountService.getLocation();
    this.permissionLocationService
      .list({ location_id: this.accountService.getLocationId() })
      .subscribe(data => {
        this.permsToArray(data);
        this.permissionUserService
          .list({
            location_id: this.accountService.getLocation().id,
            user_id: this.user.id,
          })
          .subscribe(data => {
            this.perms = [];
            let userType = this.userMap.user_type;
            for (
              let i = 1;
              i <= Object.keys(Permission).filter(Number).length;
              i++
            ) {
              this.perms[i] = {
                value: this.locPerms[i][userType].value,
                permission_id: i,
              };
            }
            for (let d of data) {
              this.perms[d.permission_id] = d;
            }
            //console.log(this.perms)
          });
      });
  }

  toggleEdit(obj) {
    if (obj.value && obj.id) {
      this.permissionUserService.delete(obj.id).subscribe(() => {});
    } else {
      this.permissionUserService
        .update({
          location_id: this.getLocationId(),
          user_id: this.user.id,
          value: obj.value,
          permission_id: obj.permission_id,
        })
        .subscribe(data => {
          obj.id = data.id;
        });
    }
  }
  permsToArray(data) {
    this.locPerms = [];
    for (let i = 0; i <= Object.keys(Permission).filter(Number).length; i++) {
      this.locPerms[i] = [];
    }
    for (let d of data) {
      this.locPerms[d.permission_id][d.user_type] = d;
    }
    //console.log(this.locPerms);
  }

  resetPerms() {
    this.permissionUserService
      .reset(this.getLocationId(), this.user.id)
      .subscribe(() => {
        let userType = this.userMap.user_type;
        for (
          let i = 1;
          i <= Object.keys(Permission).filter(Number).length;
          i++
        ) {
          this.perms[i] = {
            value: this.locPerms[i][userType].value,
            permission_id: i,
          };
        }
      });
  }

  changeType(userType: number) {
    this.locationUserMapService
      .update({
        id: this.userMap.id,
        location_id: this.userMap.location_id,
        user_type: userType,
        user_id: this.userMap.user_id,
      })
      .subscribe(data => {
        this.userMap.user_type = userType;
        this.resetPerms();
        if (userType == UserType.Owner) {
          // Move myself down..
          this.getLocation().user_type = UserType.Manager;
        }
      });
  }
}
