<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Edit Checklist"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" *ngIf="data && checklist">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Edit Checklist - {{ checklist.name}}
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div class="card">
          <form ngNativeValidate>
            <div class="card-body">
              <label>Add a Checklist Item</label>
              <div class="row">
                <div class="col">
                  <div class="input-group mb-0">
                    <input
                      [(ngModel)]="newItem.name"
                      type="text"
                      name="new_name"
                      class="form-control"
                      placeholder="Add a checklist Item"
                      required />
                    <div class="input-group-append">
                      <button
                        class="btn btn-icon bg-white"
                        (click)="saveObj(newItem)"
                        type="button">
                        <span class="cdi-s-add"> </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="flex-7 p-1">
                  <input-toggle [(ngModel)]="newItem.days[0]" name="new_sun"
                    ><small>Sun</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle [(ngModel)]="newItem.days[1]" name="new_mon"
                    ><small>Mon</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle [(ngModel)]="newItem.days[2]" name="new_tue"
                    ><small>Tue</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle [(ngModel)]="newItem.days[3]" name="new_wed"
                    ><small>Wed</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle [(ngModel)]="newItem.days[4]" name="new_thu"
                    ><small>Thu</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle [(ngModel)]="newItem.days[5]" name="new_fri"
                    ><small>Fri</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle [(ngModel)]="newItem.days[6]" name="new_sat"
                    ><small>Sat</small></input-toggle
                  >
                </div>
              </div>
              Checklist items are active only on days the checklist is active.
              All on assures the item is included if the checklist days change.
            </div>
          </form>
        </div>

        <div class="card">
          <label class="ml-2 mt-3">Checklist Items</label>
          <div class="card-body sortable-dragula" [dragula]="'checklist_item'">
            <div *ngFor="let d of data" [attr.data-id]="d.id">
              <div class="row no-gutters body-bg" *ngIf="!d._edit">
                <div class="col-1">
                  <span class="cdi-s-reorder pointer"></span>
                </div>
                <div class="col-11 mt-2">
                  {{d.name}}
                  <span
                    class="cdi-s-trash float-right pointer"
                    (click)="deleteObj(d)"></span>
                  <span
                    class="cdi-s-edit float-right pointer"
                    (click)="editObj(d)"></span>
                </div>
              </div>

              <div class="row no-gutters body-bg" *ngIf="d._edit">
                <div class="col-5">
                  <input
                    [(ngModel)]="d.name"
                    [name]="'name_'+d.id"
                    type="text"
                    class="form-control" />
                </div>
                <div class="col-5">
                  <select
                    [(ngModel)]="d.checklist_id"
                    [name]="'new_cl_'+d.id"
                    class="form-control">
                    <option *ngFor="let c of checklists" [value]="c.id">
                      {{c.name}}
                    </option>
                  </select>
                </div>
                <div class="col-2">
                  <span
                    *ngIf="d._edit"
                    class="cdi-s-cancel float-right pointer"
                    (click)="cancelEdit(d)"></span>
                  <span
                    *ngIf="d._edit"
                    class="cdi-s-save float-right pointer"
                    (click)="saveObj(d)"></span>
                </div>
              </div>

              <div class="row">
                <div class="flex-7 p-1">
                  <input-toggle
                    [(ngModel)]="d.days[0]"
                    [disabled]="!d._edit"
                    [name]="'sun_'+d.id"
                    ><small>Sun</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle
                    [(ngModel)]="d.days[1]"
                    [disabled]="!d._edit"
                    [name]="'mon_'+d.id"
                    ><small>Mon</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle
                    [(ngModel)]="d.days[2]"
                    [disabled]="!d._edit"
                    [name]="'tue_'+d.id"
                    ><small>Tue</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle
                    [(ngModel)]="d.days[3]"
                    [disabled]="!d._edit"
                    [name]="'wed_'+d.id"
                    ><small>Wed</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle
                    [(ngModel)]="d.days[4]"
                    [disabled]="!d._edit"
                    [name]="'thu_'+d.id"
                    ><small>Thu</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle
                    [(ngModel)]="d.days[5]"
                    [disabled]="!d._edit"
                    [name]="'fri_'+d.id"
                    ><small>Fri</small></input-toggle
                  >
                </div>
                <div class="flex-7 p-1">
                  <input-toggle
                    [(ngModel)]="d.days[6]"
                    [disabled]="!d._edit"
                    [name]="'sat_'+d.id"
                    ><small>Sat</small></input-toggle
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <done-button class="float-right" (click)="cancel()"></done-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
