<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Checklist"
  aria-hidden="true">
  <div class="modal-dialog modal-md" *ngIf="checklist && checklistItems">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Checklist {{checklist.name}} -
          <span class="text-orange" *ngIf="checklist.before">COMING UP</span>
          <span class="text-danger" *ngIf="checklist.missed">MISSED</span>
          <span class="text-dark" *ngIf="checklist.pending">OPEN</span>
          <span class="text-dark" *ngIf="checklist.status">DONE</span>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <form ngNativeValidate (submit)="save()">
          <div class="card">
            <div class="card-body my-0" *ngIf="checklistItems.length==0">
              There are no checklist items on this checklist. Please use the
              settings to add checklist items.
            </div>

            <div
              class="card-body my-0"
              *ngFor="let d of checklistItems | orderBy: 'order_by'">
              <div class="row" *ngIf="d.completed_id">
                <div class="col-12 border" *ngIf="d.completed_id">
                  <span class="cdi-s-check active"></span>
                  <span class="text-success">{{d.name}}</span>
                  <strong
                    *ngIf="d.checklist_item_type==ChecklistItemType.Number">
                    ({{d.data | number: '1.0-2'}})
                  </strong>
                  <span class="text-secondary">
                    - completed by {{d.firstname}} {{d.lastname}}
                    {{d.completed_at | date: 'M/d/yy h:mm a'}}</span
                  >
                </div>
              </div>
              <div class="row" *ngIf="!d.is_completed_id">
                <div
                  class="col-12"
                  *ngIf="!checklist.pending && !d.completed_id">
                  <span class="cdi-s-check"></span>{{d.name}}
                </div>
                <div
                  class="col-12"
                  *ngIf="checklist.pending && !d.completed_id">
                  <input-toggle
                    class="p1-2"
                    disabled
                    [(ngModel)]="d.completed"
                    name="'c_'+d.id"
                    >{{d.name}}
                  </input-toggle>
                  <div
                    class="slidecontainer input-group"
                    *ngIf="d.checklist_item_type==ChecklistItemType.Number">
                    <div class="input-group-prepend">
                      <input
                        type="number"
                        [min]="d.min"
                        [max]="d.max"
                        step="0.01"
                        [(ngModel)]="d.data"
                        class="form-control px-0 text-right"
                        style="width: 5rem"
                        [name]="'checklist_data_'+d.id" />
                    </div>
                    <input
                      type="range"
                      [min]="d.min"
                      [max]="d.max"
                      step="0.01"
                      [(ngModel)]="d.data"
                      class="slider form-control px-0"
                      [name]="'checklist_range_'+d.id" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <save-button
                *ngIf="checklist.pending"
                class="float-right"></save-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
