import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  ServiceTimeService,
  IServiceTime,
} from '../services/serviceTime.service';
import { ShiftService, IShift } from '../services/shift.service';
import { ShiftTemplateItemService } from '../services/shiftTemplateItem.service';
import { PositionService, IPosition } from '../services/position.service';
import { EventService, EventType } from '../services/event.service';
import { LogService } from '../services/log.service';
import {
  RepeatOptions,
  ScheduleSortOrder,
  ServiceTimeType,
  PositionType,
  RateType,
  PublishLogType,
  LaborStatType,
  DateMenuType,
  moment,
  getScheduledDays,
  textColor,
  getStartOfCurrentWeek,
  Permission,
} from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { PublishLogService } from '../services/publishLog.service';
import { SalesGoalService, ISalesGoal } from '../services/salesGoal.service';
import { UserService } from '../services/user.service';
import * as momentHoliday_ from 'moment-holiday-us';
import { TimeOffService } from '../services/timeOff.service';
import { tap, delay } from 'rxjs/operators';

@Component({
  templateUrl: './home.html',
})
export class HomeComponent extends baseRole implements OnInit, OnDestroy {
  private displayKey = 'schedule_display_mode';
  public now = new Date();
  public displayLong: boolean;
  public sortByTime: boolean = false;
  public minLabor: any[];
  public maxLabor: any[];
  public collapse = true;
  public date: Date; // Current Date off the menu
  public endDate: Date; // Plus a week.
  public fullEndDate: Date; // Plus some hours and things so I don't have to timezone the result.
  public dateSub;
  public eventSub;
  public salesGoalSub;
  public events;
  public RepeatOptions = RepeatOptions;
  public DateMenuType = DateMenuType;
  public serviceTimes: any[];
  public ServiceTimeType = ServiceTimeType;
  public HourHeader: number[] = [];
  public published = false;
  public publishSaving = false;
  public currentPublished;
  public publishNotes: string;
  public _users: any[]; // Pre Filtered.
  public users: any[];
  public salaried_users: any[];
  @ViewChild('modalAdjustSales')
  public modalAdjustSales;
  @ViewChild('modalEditShift')
  public modalEditShift;
  @ViewChild('modalTradeRequest')
  public modalTradeRequest;
  @ViewChild('tradeShiftModal')
  public modal;
  @ViewChild('modalAddShift')
  public modalAddShift;
  @ViewChild('confirmModal')
  public confirmModal;
  public trade: any;
  public shifts: any[] = [];
  public stats: any[] = [];
  public statMap: any = {};
  public lastYearMap: any = {};
  public publishLogs: any[];
  public PublishLogType = PublishLogType;
  public conflicts: { shift: any; name: string }[];
  public otwarnings: { user: any; day: string; minutes: number }[];
  public timeOffs: any[];
  public timeOffSub;
  public shiftSub;
  public filter: any = {};
  public myPositionMap: any = {};

  public salesGoalData: ISalesGoal[]; // Blank? Haven't set sales goals..
  public salesGoalMap: any = {};
  public totalMinutes = 0;
  public estSales = 0;
  public budgetCost = 0;
  public scheduleCost = 0;
  public actualCost = 0;

  public copyShifts: {
    start: Date;
    shifts: any[];
  };

  public days: any[];
  public dayMap: any = {};

  public shortView: any;
  public mainLoading: boolean;
  public serviceTimeArray: number[] = [];

  private position: number;
  @HostListener('window:beforeunload', ['$event'])
  saveScrollPosition($event: any): void {
    localStorage.setItem('scrollPosition', window.scrollY.toString());
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected serviceTimeService: ServiceTimeService,
    protected userService: UserService,
    protected shiftService: ShiftService,
    protected logService: LogService,
    protected publishLogService: PublishLogService,
    protected shiftTemplateItemService: ShiftTemplateItemService,
    protected eventService: EventService,
    protected timeOffService: TimeOffService,
    protected salesGoalService: SalesGoalService,
    protected accountService: AccountService
  ) {
    super(accountService);

    for (let i = 0; i < 24; i++) {
      this.HourHeader.push(i);
    }
  }

  ngOnInit() {
    this.sortByTime =
      this.getLocation().sched_sort_order == ScheduleSortOrder.Time;
    this.displayLong = this.accountService.getTempState(this.displayKey, {
      displayLong: true,
    }).displayLong;
    this.minLabor = this.getLocation().min_labor;
    this.maxLabor = this.getLocation().max_labor;

    this.serviceTimeService.list().subscribe(serviceTimes => {
      // Load in the service times once.
      this.serviceTimes = serviceTimes;
      for (let i = 0; i < 24 * 4; i++) {
        this.serviceTimeArray[i] =
          this.serviceTimes.length == 1
            ? ServiceTimeType.Daily
            : ServiceTimeType.Dinner;
      }
      if (this.serviceTimes.length > 1) {
        for (let t of this.serviceTimes) {
          t.start_idx =
            t.start.getHours() * 4 + Math.floor(t.start.getMinutes() / 15);
          t.end_idx =
            t.end.getHours() * 4 + Math.floor(t.end.getMinutes() / 15);
        }
        for (let i = 0; i < 24 * 4; i++) {
          for (let t of this.serviceTimes) {
            if (i >= t.start_idx && i < t.end_idx) {
              this.serviceTimeArray[i] = t.service_time_type;
              break;
            }
          }
        }
      }
    });

    this.dateSub = this.accountService
      .getCurrentDateUpdateObservable(DateMenuType.Weekly)
      .subscribe(data => {
        if (!data) return;
        this.date = data;
        this.endDate = new Date(this.date);
        this.endDate.setDate(this.endDate.getDate() + 6);
        this.fullEndDate = new Date(this.endDate);
        this.fullEndDate.setMinutes(
          this.fullEndDate.getMinutes() - 1 + 60 * 24
        );

        this.loadMainData();

        this.eventSub = this.eventService
          .getUpdateObservable()
          .subscribe(data => {
            this.loadMainData();
          });
        this.salesGoalSub = this.salesGoalService
          .getUpdateObservable()
          .subscribe(data => {
            this.loadMainData();
          });
        this.timeOffSub = this.timeOffService
          .getUpdateObservable()
          .subscribe(() => {
            this.loadMainData();
          });
        this.shiftSub = this.shiftService
          .getUpdateObservable()
          .subscribe(() => {
            this.loadMainData();
          });
      });
  }

  loadMainData() {
    if (this.mainLoading) return;
    this.publishSaving = false;
    this.mainLoading = true;
    let p = [];
    let preStart = new Date(this.date);
    preStart.setDate(preStart.getDate() - 28); // Need the last 4 weeks worth of data
    let lastYearStart = new Date(this.date);
    let lastYearEnd = new Date(this.endDate);
    lastYearStart.setFullYear(lastYearStart.getFullYear() - 1);
    lastYearEnd.setFullYear(lastYearEnd.getFullYear() - 1);

    let date = new Date(this.date);
    this.days = [];
    this.dayMap = {};
    this.publishLogs = [];
    this.currentPublished = null;
    this.published = false;
    for (let i = 0; i < 7; i++, date.setDate(date.getDate() + 1)) {
      let nDay = {
        date: new Date(date),
        dayOfWeek: new Date(date).getDay(),
        events: [],
        holidays: [],
        //birthdays: [],
        shifts: [],
        _shifts: [],
        hours: 0,
        goal: 0,
        budget: 0,
        cost: 0,
        actual: 0,
      };
      this.days.push(nDay);
      this.dayMap[moment(nDay.date).format('YYYY-MM-DD')] = nDay;
    }

    p.push(this.salesGoalService.list({ start: this.date, end: this.endDate }));
    p.push(this.publishLogService.list({ week: this.date }));
    p.push(
      this.userService.listWithPositions(this.date, this.endDate, {
        hide: false,
      })
    );
    p.push(
      this.logService.getReport({
        start: preStart,
        end: this.endDate,
        include_user_data: true,
        include_shift_data: true,
      })
    );
    p.push(this.timeOffService.list({ approved: true }));
    p.push(
      this.eventService.shortList({
        start: this.date,
        end: this.fullEndDate,
        event_type: EventType.Event,
      })
    );
    p.push(
      this.logService.getReport({ start: lastYearStart, end: lastYearEnd })
    );

    forkJoin(p).subscribe(results => {
      // Sales Goal Service
      this.salesGoalData = <ISalesGoal[]>results[0];
      this.salesGoalMap = {};
      this.estSales = 0;
      for (let d of this.salesGoalData) {
        let k = moment(d.date).format('YYYY-MM-DD');
        this.salesGoalMap[k] = d;
        this.dayMap[k].goal = d.goal;
        this.dayMap[k].budget =
          d.goal *
          (this.getLocation().foh_goal / 100 +
            this.getLocation().boh_goal / 100);

        this.estSales += d.goal;
      }
      if (
        this.salesGoalData.length == 0 &&
        !this.isFreemium() &&
        this.isAllowed(Permission.ViewSales)
      ) {
        this.modalAdjustSales.showReview();
      }
      this.budgetCost =
        this.estSales *
        (this.getLocation().foh_goal / 100 + this.getLocation().boh_goal / 100);

      // publish Log Service
      this.publishLogs = <any[]>results[1];
      this.published = false;
      if (this.publishLogs.length > 0) {
        this.currentPublished = this.publishLogs.filter(e => {
          return e.active;
        })[0];
        this.published =
          this.currentPublished.publish_log_type == PublishLogType.Publish;
      }
      if (
        !this.published &&
        !this.isAllowed(Permission.ViewUnpublishedSchedule)
      ) {
        results[3] = []; // KILL THE SHIFTS. You can't see them, and they aren't published.
      }

      // User listWithPositions
      this.myPositionMap = {};
      for (let u of <any[]>results[2]) {
        for (let p of u.positions) {
          if (u.id == this.getUserId()) {
            this.myPositionMap[p.id] = p;
          }
          if (p.position_type == PositionType.FrontOfHouse) {
            u.foh = true;
          } else if (p.position_type == PositionType.BackOfHouse) {
            u.boh = true;
          }
        }
      }
      this._users = <any[]>results[2];
      this.users = this._users;

      // Log Stats / Shift data
      this.stats = <any[]>results[3];
      this.statMap = {};
      this.shortView = {};

      // Pull in the starts that are in the date range we are actually in.
      this.totalMinutes = 0;
      this.scheduleCost = 0;
      this.actualCost = 0;
      this.shifts = [];
      this.otwarnings = [];
      for (let s of this.stats) {
        this.statMap[s.date] = s;
      }

      let payrollExtra = 1.0;
      for (let s of this.stats) {
        if (s.date in this.dayMap) {
          let mins =
            s.scheduled.foh_minutes +
            s.scheduled.boh_minutes +
            s.scheduled.ot_foh_minutes +
            s.scheduled.ot_boh_minutes;
          let cost =
            s.log_foh_cost +
            s.log_boh_cost +
            s.salary.foh_cost +
            s.salary.boh_cost +
            s.scheduled.foh_cost +
            s.scheduled.boh_cost +
            s.scheduled.ot_foh_cost +
            s.scheduled.ot_boh_cost;
          this.dayMap[s.date].hours = mins / 60;
          this.dayMap[s.date].cost = payrollExtra * cost;
          this.dayMap[s.date].actual = payrollExtra * (s.foh_cost + s.boh_cost);
          this.totalMinutes += mins;
          this.scheduleCost += payrollExtra * cost;
          this.actualCost += payrollExtra * (s.foh_cost + s.boh_cost);

          // Do the ot warning checks.
          for (let u of s.users) {
            for (let us of u.shifts) {
              if (!us.is_salary && us.ot_minutes > 0) {
                this.otwarnings.push({
                  day: s.date,
                  user: u,
                  minutes: us.ot_minutes,
                });
              }
            }
          }
        }

        for (let shift of s.shifts) {
          shift.start = moment(shift.start);
          shift.end = moment(shift.end);
          let start = moment(shift.start);
          let end = moment(shift.end);

          if (end.isBefore(this.date)) continue; // Too early.

          shift.uiOffset = 0;
          shift.uiStart = Number(moment(start).format('H'));

          const shiftDuration = moment.duration(end.diff(start));
          const shiftDurationHours = parseInt(shiftDuration.asHours());
          const shiftDurationMinutes = parseInt(shiftDuration.asMinutes()) % 60;
          const shiftMinuteStart = Math.floor(moment(start).format('m') / 15);

          shift.uiEnd =
            (shift.uiStart + shiftDurationHours) * 4 +
            Math.floor(shiftDurationMinutes / 15) +
            shiftMinuteStart +
            1;
          shift.uiStart = shift.uiStart * 4 + shiftMinuteStart + 1;

          if (shift.uiEnd > 24 * 4 + 1) {
            shift.uiOffset = shift.uiEnd - 24 * 4;
            shift.uiEnd = 24 * 4 + 1;
          }

          this.shifts.push(shift);

          // Is this shift clickable?
          shift._isClickable = this.isShiftClickable(shift);

          if (shift.user_id) {
            if (!(shift.user_id in this.shortView)) {
              let u = this._users.find(e => {
                return e.id == shift.user_id;
              });
              if (!u) continue;
              this.shortView[shift.user_id] = {
                firstname: shift.user.firstname,
                lastname: shift.user.lastname,
                positions: u.positions,
                user_type: u.user_type,
                user_id: shift.user_id,
                days: [[], [], [], [], [], [], []],
              };
            }
            let dayIndex = Math.floor(
              moment(shift.start).diff(this.date, 'days')
            );
            if (dayIndex >= 0 && dayIndex < 7) {
              this.shortView[shift.user.id].days[dayIndex].push(shift);
            }
          }
          let dayShift = {
            shift: shift,
          };
          if (s.date in this.dayMap) {
            this.dayMap[s.date]._shifts.push(dayShift);
          }
        }
      }

      this.timeOffs = <any[]>results[4];
      this.events = <any[]>results[5];
      this.lastYearMap = {};
      for (let d of <any[]>results[6]) {
        this.lastYearMap[d.date] = d;
      }
      this.postLoadCalc();
      this.mainLoading = false;
      this.position = parseInt(localStorage.getItem('scrollPosition'));
      if (this.position) {
        of(null)
          .pipe(
            delay(100),
            tap(() => {
              window.scrollTo(0, this.position);
              localStorage.removeItem('scrollPosition');
            })
          )
          .subscribe();
      }
    });
  }

  isShiftClickable(s) {
    if (!this.published) {
      return this.isAllowed(this.Permission.EditSchedule);
    } else {
      if (moment(s.start).isBefore(moment())) {
        return false;
      }
      if (s.user_id != this.getUserId()) return false; // Check for only my shifts.
      return true;
      // Is it before now?
      /*else {
				if(s.position_id in this.myPositionMap) {	// Can I even do it?
					return true;
				}
				return false;
			}*/
    }
  }

  postLoadCalc() {
    //this.calcBirthdays();
    this.addHolidays(this.date, this.endDate); // Add the holidays

    // Sort the shifts.
    for (let k in this.dayMap) {
      this.dayMap[k]._shifts.sort((a, b) => {
        if (this.sortByTime) {
          let dif = a.shift.start - b.shift.start;
          if (dif != 0) return dif;
          return a.shift.position_name.localeCompare(
            b.shift.position_name,
            'en',
            { sensitivity: 'base' }
          );
        } else {
          let dif = a.shift.position_name.localeCompare(
            b.shift.position_name,
            'en',
            { sensitivity: 'base' }
          );
          if (dif != 0) return dif;
          return a.shift.start - b.shift.start;
        }
      });
    }

    // Do last year sales:
    for (let k in this.dayMap) {
      let lastKey = moment(k).add(-1, 'years').format('YYYY-MM-DD');
      let d = this.dayMap[k];
      if (lastKey in this.lastYearMap) {
        d.last_year_sales = this.lastYearMap[lastKey].service_time[0].sales;
      } else {
        d.last_year_sales = 0;
      }
    }
    if (this.events) {
      for (let d of this.days) {
        d.events = [];
      }
      for (let e of this.events) {
        let dates = getScheduledDays(
          e.start_date,
          e.end_date,
          e.repeat_option,
          e.repeat_until,
          this.date,
          this.fullEndDate
        );
        for (let d of dates) {
          let k = d.format('YYYY-MM-DD');
          if (k in this.dayMap) {
            this.dayMap[k].events.push(e);
          }
        }
      }
    }
    this.calcConflicts();
    this.filterShifts();
  }

  calcConflicts() {
    // commented out because no timeArray
    //these conflicts are pointers to edit the shift
    let timeOffMaps: any = {};
    this.conflicts = [];
    for (let t of this.timeOffs) {
      t.range = moment.range(moment(t.start_date), moment(t.end_date));
    }
    for (let u of this.users) {
      timeOffMaps[u.id] = this.timeOffs.filter(e => {
        return e.user_id == u.id && e.approved;
      });
    }

    for (let shift of this.shifts) {
      if (!shift.user_id) continue;
      if (!shift.is_valid) {
        console.log('Invalid shift?');
        console.log(shift);
        this.conflicts.push({
          shift: shift,
          name: 'User does not have this position available at this time',
        });
        continue;
      }
      if (shift.user_id in timeOffMaps) {
        let shiftRange = moment.range(moment(shift.start), moment(shift.end));
        for (let t of timeOffMaps[shift.user_id]) {
          if (shiftRange.overlaps(t.range)) {
            this.conflicts.push({
              shift: shift,
              name: t.whole_day ? 'Whole day time off' : 'Partial day time off',
            });
          }
        }
      }
    }

    this.conflicts.sort((a, b) => {
      return a.shift.start.diff(b.shift.start);
    });
  }

  /*
	calcBirthdays() {
		if( !this.days || this.days.length==0) return;
		for(let u of this._users) {
			if(!u.dob) continue;
			for(let d of this.days) {
				if(u.dob.getMonth()==d.date.getMonth() && u.dob.getDate()==d.date.getDate()) {
					d.birthdays.push(u);
				}
			}
		}
	}*/

  showAdjust() {
    if (this.isFreemium()) {
      this.router.navigate(['/freemium']);
    } else {
      this.modalAdjustSales.showAdjust();
    }
  }

  showReview() {
    if (this.isFreemium()) {
      this.router.navigate(['/freemium']);
    } else {
      this.modalAdjustSales.showReview();
    }
  }

  ngOnDestroy() {
    if (this.shiftSub) this.shiftSub.unsubscribe();
    if (this.salesGoalSub) this.salesGoalSub.unsubscribe();
    if (this.timeOffSub) this.timeOffSub.unsubscribe();
    if (this.dateSub) this.dateSub.unsubscribe();
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  copyPrevWeek() {
    this._clearShifts().subscribe(() => {
      let start = moment(this.date).add(-7, 'days');
      let list = [];
      for (let i = 0; i < 7; i++, start.add(1, 'days')) {
        let key = start.format('YYYY-MM-DD');
        for (let d of this.statMap[key].shifts) {
          let start = moment(d.start).add(7, 'days').toDate();
          let end = moment(d.end).add(7, 'days').toDate();
          list.push({
            start: start,
            end: end,
            position_id: d.position_id,
            user_id: d.user_id,
            location_id: this.getLocationId(),
          });
        }
      }
      this.shiftService.bulkUpdate(list).subscribe(() => {});
    });
  }

  _clearShifts(): Observable<any> {
    if (!this.shifts || this.shifts.length == 0) {
      return of(null);
    }
    let p = [];
    for (let d of this.shifts) {
      p.push(d.id);
    }
    return this.shiftService.bulkDelete(p);
  }

  clearShifts() {
    this._clearShifts().subscribe(data => {});
  }

  copy() {
    this.copyShifts = {
      start: new Date(this.date),
      shifts: [],
    };
    for (let s of this.shifts) {
      this.copyShifts.shifts.push(Object.assign({}, s));
    }
    for (let s of this.copyShifts.shifts) {
      delete s.user;
      s.start = new Date(s.start);
      s.end = new Date(s.end);
    }
    if (this.copyShifts.shifts.length == 0) this.copyShifts = null;
  }

  clearCopy() {
    this.copyShifts = null;
  }

  // Move an existing copied shift into the current week.
  _moveToCurrentDate(shifts: { start: Date; end: Date }[]) {
    for (let s of shifts) {
      let offset = Math.ceil(
        (this.date.getTime() - s.start.getTime()) / (86400 * 1000 * 7)
      );
      s.start.setDate(s.start.getDate() + 7 * offset);
      s.end.setDate(s.end.getDate() + 7 * offset);
    }
  }

  paste() {
    if (!this.copyShifts) return;

    this._moveToCurrentDate(this.copyShifts.shifts);
    for (let s of this.copyShifts.shifts) {
      delete s.id;
    }
    this.shiftService.bulkUpdate(this.copyShifts.shifts).subscribe(data => {
      this.copyShifts = null;
      this.loadMainData();
    });
  }

  loadEvents() {}

  templateLoader(templateInfo) {
    let o = of(null);
    this.shiftService._blockUpdate(true);
    if (templateInfo.clear) {
      o = this._clearShifts();
    }
    o.subscribe(() => {
      this.shiftService._blockUpdate(false);
      this.shiftTemplateItemService
        .list({ shift_template_id: templateInfo.shift_template_id })
        .subscribe(data => {
          for (let d of data) {
            delete d.id;
            if (templateInfo.unassign || (<any>d).hide) d.user_id = null;
          }
          this._moveToCurrentDate(data);
          this.shiftService.bulkUpdate(data).subscribe(data => {});
        });
    });
  }

  shortViewKeys() {
    //return Object.keys(this.shortView);
    let keys = Object.keys(this.shortView);

    keys = keys.filter(e => {
      if (
        this.filter.permissionFilter &&
        !this.filter.permission_level[this.shortView[e].user_type]
      )
        return false;
      if (this.filter.positionFilter) {
        //filters in those with matching positions in shift.
        for (let day of this.shortView[e].days) {
          for (let s of day) {
            if (this.filter.position_ids[s.position_id]) return true;
          }
        }
        return false;
      }
      return true;
    });

    let userFilterMap: any = {};
    for (let u of this.users) {
      userFilterMap[u.id] = true;
    }

    let tmpKeys = keys.filter(e => {
      return e in userFilterMap;
    });

    for (let t of tmpKeys) {
      this.shortView[t].firstShift = null;
      for (let d of this.shortView[t].days) {
        if (d.length > 0 && !this.shortView[t].firstShift) {
          this.shortView[t].firstShift = d[0];
        }
      }
    }

    tmpKeys.sort((aKey, bKey) => {
      let a = this.shortView[aKey].firstShift;
      let b = this.shortView[bKey].firstShift;
      if (this.sortByTime) {
        let dif = a.start - b.start;
        if (dif != 0) return dif;
        return a.position_name.localeCompare(b.position_name, 'en', {
          sensitivity: 'base',
        });
      } else {
        let dif = a.position_name.localeCompare(b.position_name, 'en', {
          sensitivity: 'base',
        });
        if (dif != 0) return dif;
        return a.start - b.start;
      }
    });

    return tmpKeys;
  }

  shortViewDayKeys(day) {
    if (!this.filter.positionFilter) return day;
    return day.filter(e => {
      if (this.filter.position_ids[e.position_id]) return true;
      return false;
    });
  }

  updateFilter(filter) {
    this.filter = filter;
    this.filterShifts();
  }

  filterShifts() {
    //filter users by permission.
    this.users = this._users.slice();
    if (this.filter) {
      this.users = this.users.filter(e => {
        if (!this.filter.permissionFilter) return true;
        if (!this.filter.permission_level[e.user_type]) return false;
        else return true;
      });
    }

    let userFilterMap: any = {};
    for (let u of this.users) {
      userFilterMap[u.id] = true;
    }

    let shiftUserMap: any = {}; //this has to be unfiltered users or would unassign a filtered shift
    for (let u of this._users) {
      shiftUserMap[u.id] = true;
    }

    for (let day of this.days) {
      for (let s of day._shifts) {
        let ds = s.shift.user_id;
        if (!ds || !(ds in shiftUserMap)) {
          //find trashed users

          s.shift.user_id = null; // unassigned shift
          s.shift.firstname = null;
          s.shift.lastname = null;
          s.shift.color = '#FF0000';
        }
      }
      day.shifts = day._shifts.slice();
      if (this.filter && this.filter.isFiltered) {
        if (this.filter.permissionFilter) {
          day.shifts = day.shifts.filter(e => {
            return !e.shift.user_id || userFilterMap[e.shift.user_id];
          });
        }
        if (this.filter.positionFilter) {
          day.shifts = day.shifts.filter(e => {
            // position filter
            return this.filter.position_ids[e.shift.position_id];
          });
        }
      }
    }
  }

  addHolidays(start: Date, end: Date) {
    let nStart = moment(start);
    let nEnd = moment(end);
    nStart.add(-1, 'days');
    nEnd.add(1, 'days');
    let dates = nStart.holidaysBetween(nEnd);
    if (dates) {
      for (let d of dates) {
        let k = d.format('YYYY-MM-DD');
        if (k in this.dayMap) {
          this.dayMap[k].holiday = { name: 'today is ' + d.isHoliday() };
        }
      }
    }
  }

  toggleView() {
    this.displayLong = !this.displayLong;
    this.accountService.updateTempState(
      this.displayKey,
      'displayLong',
      this.displayLong
    );
  }

  doShiftClick(day, index, shift) {
    if (!shift._isClickable) return;

    if (!this.published) {
      this.modalEditShift.show(day, index, shift);
    } else {
      this.modalTradeRequest.show(shift);
    }
  }

  // BUG BUG BUG
  doShortShiftClick(day, index, shift) {
    if (!shift._isClickable) return;

    if (!this.published) {
      this.modalEditShift.show(this.days[index], index, shift);
    } else {
      this.modalTradeRequest.show(shift);
    }
  }

  doPublish() {
    this.publishSaving = true;
    this.publishLogService
      .update({
        week: this.date,
        publish_log_type: this.published
          ? PublishLogType.Unpublish
          : PublishLogType.Publish,
        note: this.publishNotes,
      })
      .subscribe(data => {
        this.loadMainData();
        this.publishNotes = null;
      });
  }

  dateFormat(date: Date): string {
    return moment(date).format('ddd Do h:mm a');
  }

  showTrade() {
    this.modal.show();
  }
  cancelTrade() {
    this.modal.hide();
  }
  saveTrade() {
    this.modal.hide();
  }
  textColor(color) {
    return textColor(color);
  }

  addShiftWithConfirm() {
    let start = getStartOfCurrentWeek(this.getLocation());
    if (this.date < start) {
      this.confirmModal.action = 'add a shift to a schedule in the past?';
      this.confirmModal.show();
    } else {
      this.modalAddShift.show();
    }
  }

  print() {
    let filter: string = null;
    if (this.filter && this.filter.isFiltered) {
      let tmpFilter: any = {};
      if (this.filter.permissionFilter) {
        tmpFilter.permission_level = [];
        for (let i = 1; i <= 4; i++) {
          if (this.filter.permission_level[i])
            tmpFilter.permission_level.push(i);
        }
      }
      if (this.filter.positionFilter) {
        tmpFilter.position_ids = [];
        for (let id in this.filter.position_ids) {
          tmpFilter.position_ids.push(+id);
        }
      }
      filter = JSON.stringify(tmpFilter);
    }

    let link = this.shiftService.getPdfScheduleUrl({
      location_id: this.getLocationId(),
      start: this.date,
      filter: filter,
    });
    window.open(link, '_blank');
  }
}
