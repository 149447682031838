<modal-profit-loss #modalProfitLoss></modal-profit-loss>
<form ngNativeValidate (submit)="load()">
  <div class="row">
    <div class="col-md-4 col-12">
      <div class="card">
        <div class="card-body">
          <label>Field</label>
          <select
            name="field"
            [disabled]="isFreemium()"
            [(ngModel)]="search.search_field"
            class="form-control">
            <option value="net_sales" selected>Sales</option>
            <option value="tax">Tax</option>
            <option
              *ngIf="getLocation().has_houses && search.foh_labor_hours"
              value="foh_labor_hours">
              FOH Labor Hours
            </option>
            <option
              *ngIf="getLocation().has_houses && search.foh_labor_cost"
              value="foh_labor_cost">
              FOH Labor Cost
            </option>
            <option
              *ngIf="getLocation().has_houses && search.boh_labor_hours"
              value="boh_labor_hours">
              BOH Labor Hours
            </option>
            <option
              *ngIf="getLocation().has_houses && search.boh_labor_cost"
              value="boh_labor_cost">
              BOH Labor Cost
            </option>
            <option
              *ngIf="!getLocation().has_houses && search.foh_labor_hours"
              value="foh_labor_hours">
              Labor Hours
            </option>
            <option
              *ngIf="!getLocation().has_houses && search.foh_labor_cost"
              value="foh_labor_cost">
              Labor Cost
            </option>
            <option *ngIf="search.guest_count" value="guest_count">
              Guest Count
            </option>
            <option *ngIf="search.comps" value="comps">
              Comps/House Account
            </option>
            <option *ngIf="search.discounts" value="discounts">
              Discounts
            </option>
            <option
              *ngIf="search.employee_discounts"
              value="employee_discounts">
              Employee Discounts
            </option>
            <option *ngIf="search.pay_outs" value="pay_outs">Payouts</option>
            <option *ngIf="search.cash" value="cash">Cash Collected</option>
            <option *ngIf="search.expected_cash" value="expected_cash">
              Expected Cash Drop
            </option>
            <option *ngIf="search.gift_redeemed" value="gift_redeemed">
              Gift Cards Redeemed
            </option>
            <option *ngIf="search.gift_sold" value="gift_sold">
              Gift Cards Sold
            </option>
            <option *ngIf="search.cc_tips_collected" value="cc_tips_collected">
              Credit Card Tips Collected
            </option>
            <option *ngIf="search.actual_bank" value="actual_bank">
              Actual Bank Deposits
            </option>
            <option *ngFor="let c of customFields" [value]="c.name">
              {{c.name}}
            </option>
          </select>
          <div
            *ngIf="!isDaily && !(search.search_field == 'net_sales' && groupByLabor.indexOf(search.group_by)>=0 && !search.compare_last_year)">
            <label>Service Time</label>
            <select
              name="service_time"
              [disabled]="isFreemium()"
              [ngModel]="''"
              (ngModelChange)="changeServiceTime($event)"
              class="form-control">
              <option [value]="''">All</option>
              <option [value]="ServiceTimeType.Breakfast">Breakfast</option>
              <option [value]="ServiceTimeType.Lunch">Lunch</option>
              <option [value]="ServiceTimeType.Dinner">Dinner</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-12">
      <div class="card">
        <div class="card-body">
          <label>Start Date</label>
          <input
            type="text"
            class="form-control input-calendar"
            [disabled]="isFreemium()"
            bsDatepicker
            [(ngModel)]="search.start"
            name="start_date"
            required />
          <label>End Date</label>
          <input
            type="text"
            class="form-control input-calendar"
            [disabled]="isFreemium()"
            bsDatepicker
            [(ngModel)]="search.end"
            name="end_date"
            required />
        </div>
      </div>
    </div>

    <div class="col-md-4 col-12">
      <div class="card">
        <div class="card-body">
          <label>Group By</label>
          <select
            name="group_by"
            [disabled]="isFreemium()"
            [(ngModel)]="search.group_by"
            (ngModelChange)="updateGroupBy()"
            class="form-control">
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month" selected>Month</option>
            <option value="quarter">Quarter</option>
            <option value="year">Year</option>
            <option disabled>--------------------------------------</option>
            <option value="total_week">Total By Day Of Week</option>
            <option value="average_week">Average By Day Of Week</option>
            <option disabled>--------------------------------------</option>
            <option value="0">Sunday</option>
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thursday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>
          </select>

          <div class="row no-gutters">
            <div class="col-6">
              <input-toggle
                [disabled]="isFreemium()"
                [(ngModel)]="search.compare_last_year"
                class="mr-1"
                name="compare_last_year"
                >Last Year Compare
              </input-toggle>
            </div>
            <div
              class="col-6"
              *ngIf="search.match_week_day_available && search.compare_last_year">
              <input-toggle
                [disabled]="isFreemium() || search.match_week_day_disabled"
                [(ngModel)]="search.match_week_day"
                class="ml-1"
                name="match_last_week"
                >Day Match
              </input-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="row" *ngIf="!isFreemium()">
        <div class="col-12">
          <h1 class="float-left">
            {{ total | currency: 'USD' }}
            <span *ngIf="search.compare_last_year && lyTotal>0">
              LY: {{ lyTotal | currency: 'USD' }} Change:
              {{(total-lyTotal)/lyTotal | percent }}</span
            >
          </h1>
          <button class="btn btn-secondary float-right">Run Report</button>
          <button
            class="btn btn-secondary float-right mr-3"
            type="button"
            (click)="modalProfitLoss.show(search.start,search.end)">
            Run Profit/Loss
          </button>
        </div>
      </div>

      <div class="upgrade-warning" *ngIf="isFreemium()">
        <div class="row">
          <div class="col-12">
            <h1 class="float-left">{{165607.90 | currency: 'USD' }}</h1>
            <button class="btn btn-secondary float-right">Run Report</button>
            <button class="btn btn-secondary float-right mr-3" type="button">
              Run Profit/Loss
            </button>
          </div>
        </div>
        <img src="images/freemium/report-upgrade.png" />
        <div class="coverup">
          <h2>Premium Feature!</h2>
          <a
            [routerLink]="'/settings/account/'+loc.id+'/package'"
            class="btn btn-warning btn-lg"
            style="width: 150px; margin: 0 auto"
            >Upgrade Now</a
          >
        </div>
      </div>

      <div class="row mb-3" *ngIf="!isFreemium() && chartData">
        <div class="col-12" style="height: 25%">
          <!--<chart-main-sales *ngIf="mainSalesChart" [chartData]="chartData"></chart-main-sales> This if switched between this and the one below-->
          <chart-sales-report [chartData]="chartData"></chart-sales-report>
        </div>
      </div>
    </div>
  </div>
</form>
