<!-- Nav Bar -->

<div class="navbar navbar-expand-md subnav">
  <ul class="navbar-nav d-flex d-md-none">
    <li class="nav-item fake-active">
      <a>Menu</a>
    </li>
  </ul>
  <span
    class="navbar-toggler cdi-m-soft-arrow border-circle pointer"
    [class.active]="!collapse"
    (click)="collapse=!collapse">
  </span>

  <div
    class="navbar-collapse collapse"
    [collapse]="collapse"
    (click)="$event.preventDefault()">
    <ul class="navbar-nav schedule-subnav" (click)="collapse=true">
      <li
        class="nav-item"
        (click)="!isFreemium() && !inJail() && modalEvent.show()"
        [tooltip]="isFreemium() && 'Upgrade to Pro to Add Event'">
        <a class="nav-link">
          <icons class="mx-2" name="Plus"></icons>
          Add Event
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          (click)="!isFreemium() && !inJail() && modalTask.show()"
          [tooltip]="isFreemium() && 'Upgrade to Pro to Add Task'">
          <icons class="mx-2" name="Plus"></icons>
          <span class="d-block d-md-none d-lg-block">Add task</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="print()">
          <icons class="mx-2" name="Printer"></icons>
          <span class="d-block d-md-none d-lg-block">Print</span>
        </a>
      </li>
    </ul>
  </div>
</div>

<modal-event #modalEvent></modal-event>
<modal-task #modalTask></modal-task>
<delete-modal
  [deleteObject]="deleteObj"
  [name]="deleteName"
  (onDelete)="deleteEvent()"
  #deleteModal></delete-modal>
<div style="height: 1px; width: 100%; margin-top: 50px"></div>

<div class="container-fluid nav-padding">
  <div class="card mb-0">
    <div class="card-body" *ngIf="calendarOptions">
      <full-calendar
        class="schedule-calendar"
        #ucCalendar
        [options]="calendarOptions"></full-calendar>
      <!-- <full-calendar #ucCalendar [options]="calendarOptions" (eventClick)="eventClick($event.detail)" (eventDrop)="eventDrop($event.detail)" (eventResize)="eventResize($event.detail)" (eventRender)="eventRender($event.detail)"></full-calendar>-->
    </div>
  </div>
</div>
