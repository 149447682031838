<modal-message-reply #modalReply></modal-message-reply>

<div class="card">
  <div class="card-body">
    <div class="d-none d-md-block">
      <div class="row row-table row-header align-items-center">
        <div class="col-md-1 col-1">
          <input-toggle
            [(ngModel)]="selectAll"
            *ngIf="msgType!='archive'"
            (ngModelChange)="changeSelectAll()"></input-toggle>
        </div>
        <div class="col-md-3 col-3">Sent To</div>
        <div class="col-md-3 col-3">Title</div>
        <div class="col-md-2 col-2">From</div>
        <div class="col-md-2 col-2">Date</div>
        <div class="col-md-1 col-1"></div>
      </div>
    </div>

    <div class="row-table row-striped align-items-center" *ngIf="messages">
      <div class="row py-2 align-items-center" *ngFor="let message of messages">
        <div class="col-md-1 col-2 my-1">
          <input-toggle
            [(ngModel)]="message._selected"
            *ngIf="msgType!='archive'"></input-toggle>
        </div>

        <div class="col-md-3 col-12 my-1" (click)="modalReply.show(message)">
          <!-- Loop through the recipients -->

          <span class="d-block d-md-none text-primary">Sent To:</span>
          <div class="d-flex">
            <ng-container *ngFor="let r of message.recipients; let i = index">
              <!-- Display the first three names -->
              <ng-template [ngIf]="i < 3">
                <div>
                  <span>{{ r.firstname}}</span>
                  <span *ngIf="i !== 3 && i !== message.recipients.length - 1"
                    >,&nbsp;
                  </span>
                </div>
              </ng-template>
            </ng-container>

            <!-- Display the number of remaining names if there are more than three -->
            <span *ngIf="message.recipients.length > 3">
              and {{ message.recipients.length - 3 }} more
            </span>
          </div>
        </div>
        <div class="col-md-3 col-12 my-1" (click)="modalReply.show(message)">
          <span *ngIf="message.read">
            <span class="d-block d-md-none text-primary">Title:</span>
            {{message.title}}
          </span>
          <span *ngIf="!message.read">
            <span class="d-block d-md-none text-primary">Title:</span>
            <b>{{message.title}}</b>
          </span>
        </div>
        <div class="col-md-2 col-5 my-1" (click)="modalReply.show(message)">
          <span class="d-block d-md-none text-primary">From:</span>
          <span *ngIf="!message.read"
            >{{message.firstname}} {{ message.lastname}}</span
          >
          <span *ngIf="message.read"
            ><strong>{{message.firstname}} {{ message.lastname}}</strong></span
          >
        </div>

        <div class="col-md-2 col-5 my-1">
          <span class="d-block d-md-none text-primary">Date:</span>
          {{message.created_at | bliDate }}
        </div>
        <div class="col-md-1 col-2 my-1">
          <span *ngIf="msgType!='archive'" (click)="archiveMessage(message)">
            <icons class="icons pointer" name="Trash"></icons>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<button
  class="btn btn-primary my-2"
  *ngIf="msgType!='archive'"
  (click)="archiveSelected()">
  Archive Selected
</button>

<div class="row" *ngIf="numMessages>maxPerPage">
  <div class="col-md-12" style="text-align: center">
    <pagination
      [totalItems]="numMessages"
      [maxSize]="10"
      (pageChanged)="pageChanged($event)"
      [itemsPerPage]="maxPerPage"
      class="pagination-sm"
      [boundaryLinks]="true"
      [rotate]="false"></pagination>
  </div>
</div>
