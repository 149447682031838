import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { MessageService, IMessage } from '../services/message.service';
import {
  MessageRecipientService,
  IMessageRecipient,
} from '../services/messageRecipient.service';
import { MediaService } from '../services/media.service';
import { UserService, IUser } from '../services/user.service';
import { PositionService, IPosition } from '../services/position.service';
import { baseRole } from '../shared/baseRole';
import { getMessageImage, DocMimeTypes, StringSort } from '../shared/api';

@Component({
  selector: 'modal-message-reply',
  templateUrl: 'modalMessageReply.html',
})
export class ModalMessageReplyComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;
  public origMessage: any;
  public message: any;
  public replyAll = false;
  public reply = false;
  public fileError: string = null;
  public fileData;
  public error: string = null;

  @ViewChild('fileInput')
  public fileInput: ElementRef;

  constructor(
    public accountService: AccountService,
    public messageService: MessageService,
    public messageRecipientService: MessageRecipientService,
    public mediaService: MediaService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  initMessage() {
    this.message = {
      title: this.origMessage.title,
      body: null,
      send_email: false,
      send_text: false,
      wall: this.origMessage.wall,
      signed: false,
      comments: this.origMessage.comments,
    };
  }

  load() {
    this.initMessage();
    /*		console.log('Matching');
		console.log(this.origMessage);
		console.log(this.getUserId()); */
    if (this.origMessage) {
      StringSort(this.origMessage.recipients, 'lastname');
      let matches = this.origMessage.recipients.filter(e => {
        return e.user_id == this.getUserId();
      });
      //			console.log(matches);
      if (matches.length == 1) {
        let me = matches[0];
        if (!me.read) {
          this.messageRecipientService
            .update({ id: me.id, read: true })
            .subscribe(data => {
              me.read = true;
              this.messageService.notifyUpdate();
            });
        }
      }
    }
    this.modal.show();
  }

  show(obj: any) {
    this.origMessage = obj;
    this.load();
  }

  cancel() {
    this.modal.hide();
    this.resetFileInput();
  }

  save() {
    let wmi = this.getUserId();
    this.message.title = 'RE: ' + this.message.title;
    this.message.recipients = [{ user_id: this.origMessage.sender_user_id }];
    if (this.replyAll) {
      for (let u of this.origMessage.recipients) {
        //			console.log (u);
        if (u.user_id != wmi) {
          this.message.recipients.push({ user_id: u.user_id });
        }
      }
    }
    this.message.has_attachment = this.fileData && this.fileData.length > 0;
    this.messageService.update(this.message).subscribe(data => {
      if (this.fileData && this.fileData.length > 0) {
        let p = [];
        for (let f of this.fileData) {
          p.push(this.mediaService.upload(f, 'message', data.id));
        }
        forkJoin(p).subscribe(() => {
          this.resetFileInput();
          this.modal.hide();
        });
      } else {
        this.resetFileInput();
        this.modal.hide();
      }
    });
  }

  resetFileInput() {
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
    this.reply = false;
  }
  imageUrl(c): string {
    return getMessageImage(this.getLocationId(), this.origMessage.id, c.id);
  }

  getImageView(c) {
    return {
      mime_type: c.mime_type,
      url: this.imageUrl(c),
    };
  }

  handleFileChange(files) {
    if (!files || files.length == 0) this.fileData = null;
    else this.fileData = files;
    this.checkFiles();
  }

  checkFiles() {
    this.fileError = null;
    if (!this.fileData || this.fileData.length == 0) return true;
    for (let f of this.fileData) {
      if (DocMimeTypes.indexOf(f.type) < 0) {
        this.fileError = 'Upload must be an image or pdf file';
        this.fileData = null;
        this.fileInput.nativeElement.value = '';
        setTimeout(() => {
          this.fileError = null;
        }, 5000);
        return false;
      }
    }
    return true;
  }
}
