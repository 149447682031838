<freemium *ngIf="isFreemium()"></freemium>
<div *ngIf="data && !isFreemium()">
  <div class="row d-flex justify-content-center text-center">
    <div class="col-xl-2 col-lg-2 col-md-12 col-12 pb-0">
      <div class="card">
        <div
          class="card-body text-center"
          *ngIf="!edit"
          (click)="confirmModal.show()"
          style="min-height: 8.5rem">
          <div class="cdi-l-lock nostate mx-auto pointer"></div>
          Unlock
        </div>
        <div
          class="card-body text-center"
          *ngIf="edit"
          (click)="edit=false"
          style="min-height: 8.5rem">
          <div class="cdi-l-lock active nostate mx-auto pointer"></div>
          Lock
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-5 col-md-6 col-12 pb-0">
      <div class="card">
        <div class="card-body" style="min-height: 8.5rem">
          <label class="mb-3">Business Type</label>
          <select
            name="location_type"
            [(ngModel)]="current_location_type"
            (ngModelChange)="changeModal.show()"
            class="form-control"
            [disabled]="!edit"
            required>
            <option *ngFor="let t of LocationTypeKeys" [value]="t">
              {{LocationTypeName[t]}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-5 col-md-6 col-12 pb-0">
      <div class="card">
        <div class="card-body" style="min-height: 8rem">
          <label class="mb-3">Category</label>
          <select
            name="category"
            [(ngModel)]="data.category"
            (ngModelChange)="save()"
            class="form-control"
            [disabled]="!edit"
            required>
            <option *ngFor="let c of LocationCategoryName" [value]="c">
              {{c}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col-12 pb-0">
      <div class="card">
        <div class="card-body" style="min-height: 8.5rem">
          <label>Profit Goal</label>
          <h1 class="text-center">{{ profitGoal | number: '1.0-2' }}%</h1>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-6 col-md-6 col-12 pb-0">
      <div class="card">
        <div class="card-body" style="min-height: 8.5rem">
          <label>Prime Cost Goal</label>
          <h1 class="text-center">{{ primeCostGoal | number: '1.0-2' }}%</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-0">
    <div class="card-body">
      <label
        >Goal -
        <span *ngIf="getLocation().has_houses"> Front of House</span> Labor % of
        Sales</label
      >
      <div class="slidecontainer input-group">
        <div class="input-group-prepend">
          <div class="input-group-text orange-bg">
            <h3 class="mb-0" style="min-width: 2.5rem; color: white">
              {{data.foh_goal}}
            </h3>
          </div>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="0"
          [disabled]="!edit"
          [(ngModel)]="data.foh_goal"
          class="slider form-control px-0"
          (ngModelChange)="recalc()"
          id="myRange" />
      </div>
    </div>
  </div>

  <div *ngIf="getLocation().has_houses">
    <div class="card">
      <div class="card-body">
        <label>Goal - Back of House Labor % of Sales</label>
        <div class="slidecontainer input-group">
          <div class="input-group-prepend">
            <div class="input-group-text orange-bg">
              <h3 class="mb-0" style="min-width: 2.5rem; color: white">
                {{data.boh_goal}}
              </h3>
            </div>
          </div>
          <input
            type="range"
            min="0"
            max="100"
            value="0"
            [disabled]="!edit"
            [(ngModel)]="data.boh_goal"
            class="slider form-control px-0"
            (ngModelChange)="recalc()"
            id="myRange" />
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Goal - Cost of Goods % of Sales</label>
      <div class="slidecontainer input-group">
        <div class="input-group-prepend">
          <div class="input-group-text orange-bg">
            <h3 class="mb-0" style="min-width: 2.5rem; color: white">
              {{data.cogs_goal}}
            </h3>
          </div>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="0"
          [disabled]="!edit"
          [(ngModel)]="data.cogs_goal"
          class="slider form-control px-0"
          (ngModelChange)="recalc()"
          id="myRange" />
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Goal - Fixed Costs % of Sales</label>
      <div class="slidecontainer input-group">
        <div class="input-group-prepend">
          <div class="input-group-text orange-bg">
            <h3 class="mb-0" style="min-width: 2.5rem; color: white">
              {{data.fixed_goal}}
            </h3>
          </div>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="0"
          [disabled]="!edit"
          [(ngModel)]="data.fixed_goal"
          class="slider form-control px-0"
          (ngModelChange)="recalc()"
          id="myRange" />
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Goal - Other Expense % of Sales</label>
      <div class="slidecontainer input-group">
        <div class="input-group-prepend">
          <div class="input-group-text orange-bg">
            <h3 class="mb-0" style="min-width: 2.5rem; color: white">
              {{data.exp_goal}}
            </h3>
          </div>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="0"
          [disabled]="!edit"
          [(ngModel)]="data.exp_goal"
          class="slider form-control px-0"
          (ngModelChange)="recalc()"
          id="myRange" />
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Additional Payroll Expenses %</label>
      <div class="slidecontainer input-group">
        <div class="input-group-prepend">
          <div class="input-group-text orange-bg">
            <h3 class="mb-0" style="min-width: 2.5rem; color: white">
              {{data.payroll_goal}}
            </h3>
          </div>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value="0"
          [disabled]="!edit"
          [(ngModel)]="data.payroll_goal"
          class="slider form-control px-0"
          (ngModelChange)="recalc()"
          id="myRange" />
      </div>
      <br />
      Workers comp insurance and payroll taxes. This percentage is automatically
      multiplied by your labor cost to reflect what you really pay.
    </div>
  </div>
</div>

<div
  bsModal
  #confirmModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Confirm Edit"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-center" style="width: 100%">
          Unlock Biz Goals
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="confirmModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to unlock your business type and goals?
        <br />
        <div class="row mt-3">
          <div class="col-12">
            <cancel-button
              class="float-right ml-2"
              (click)="confirmModal.hide()"></cancel-button>
            <button
              class="btn btn-primary float-right"
              (click)="edit=true;confirmModal.hide()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #changeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Change Business Type"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-center" style="width: 100%">
          Change Business Type
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="current_location_type=data.location_type;changeModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Changing to a different business type will reset your business goals
          to industry standards for your new type. Any custom changes will be
          erased, however you can always adjust these new goals at any time.
        </p>
        <p>Are you sure you want to change your business type?</p>
        <br />
        <div class="row mt-3">
          <div class="col-12">
            <cancel-button
              class="float-right"
              (click)="current_location_type=data.location_type;changeModal.hide()"></cancel-button>
            <button
              class="btn btn-primary float-right mr-2"
              (click)="changeModal.hide();changeBusinessType()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
