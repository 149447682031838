import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ContactService, IContact } from '../services/contact.service';
import {
  InventoryLocationService,
  IInventoryLocation,
} from '../services/inventoryLocation.service';
import { InventoryCountService } from '../services/inventoryCount.service';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
import { SkuService, ISku } from '../services/sku.service';
import { SelectAddAll, ExpenseType, DateMenuType } from '../shared/api';
import { baseRole } from '../shared/baseRole';
import * as moment from 'moment';

@Component({
  templateUrl: './take.html',
})
export class TakeComponent extends baseRole implements OnInit, OnDestroy {
  public saveMessage: string = null;
  public vendors: IContact[];
  public categories: IExpenseAccount[];
  public _allLocations: any[];
  public locations: any[];
  public contactFilterId: string = '';
  public expenseAccountFilterId: string = '';
  public _allSkus: any[];
  public skus: any[];
  public date: Date;
  public formatDate: string;
  public sub;
  public updatedSku: any;
  public error: boolean = false;
  public savingState: string;
  public gridListView: string = 'list';

  @HostListener('keydown.tab', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    event.preventDefault();
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected contactService: ContactService,
    protected inventoryLocationService: InventoryLocationService,
    protected inventoryCountService: InventoryCountService,
    protected expenseAccountService: ExpenseAccountService,
    protected skuService: SkuService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (
      !this.isAllowed(this.Permission.CountInventory) &&
      !this.isAllowed(this.Permission.ManageInventory)
    ) {
      this.router.navigate(['/inventory']);
      return;
    }
    this.inventoryLocationService.list().subscribe(data => {
      this._allLocations = data;
      this.sub = this.accountService
        .getCurrentDateUpdateObservable(DateMenuType.Daily)
        .subscribe(date => {
          if (!date) return;
          this.date = date;
          this.formatDate = moment(this.date).format('MM/DD/YYYY');
          this.load(false);
        });
    });

    this.contactService
      .list({ hide: false, is_vendor: true })
      .subscribe(data => {
        data.sort((a, b) => {
          return a.company.localeCompare(b.company);
        });
        this.vendors = SelectAddAll(data, 'All', 'company');
      });
    this.expenseAccountService
      .list({ expense_type: ExpenseType.COGS })
      .subscribe(data => {
        this.categories = SelectAddAll(data, 'All', 'name');
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  load(needsLocs: boolean) {
    if (needsLocs) {
      this.inventoryLocationService.list().subscribe(data => {
        this._allLocations = data;
        this.updateFilter();
      });
    }
    this.skuService.listForInventory(this.date).subscribe(data => {
      for (let d of data) {
        d.orig_count = d.count;
      }
      this._allSkus = data;
      this.updateFilter();
    });
  }

  getSkusForLocation(location) {
    return this.skus.filter(e => {
      return e.inventory_location_id == location.id;
    });
  }

  updateFilter() {
    if (
      !this._allSkus ||
      (!this.contactFilterId && !this.expenseAccountFilterId)
    ) {
      this.skus = this._allSkus;
    } else {
      this.skus = this._allSkus.filter(e => {
        if (
          this.contactFilterId.length > 0 &&
          +this.contactFilterId != e.contact_id
        )
          return false;

        if (
          this.expenseAccountFilterId.length > 0 &&
          +this.expenseAccountFilterId != e.expense_account_id
        )
          return false;
        return true;
      });
    }
    for (let locs of this._allLocations) {
      locs.items = this.skus.filter(e => {
        return e.inventory_location_id == locs.id;
      });
    }
    this.locations = this._allLocations.filter(e => {
      return e.items.length > 0;
    });

    for (let locs of this.locations) {
      let validUpdateDates = locs.items
        .filter(item => item.inventory_count_updated_at !== null)
        .map(item => moment(item.inventory_count_updated_at).local().valueOf());

      if (validUpdateDates.length > 0) {
        let latestUpdate = Math.max(...validUpdateDates);
        locs.last_modified = moment
          .utc(latestUpdate)
          .local()
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        locs.last_modified = null;
      }
    }
  }

  getForInvLoc(invLocId: number): any[] {
    if (!this.skus) return [];
    return this.skus.filter(e => {});
  }

  updateDate(newDate: Date) {
    this.date = newDate;
    this.formatDate = moment(this.date).format('MM/DD/YYYY');
    this.load(false);
  }

  filterTakeInventoryLocation() {
    if (!this.skus) return [];
    const unfilteredSkus = this.skus;

    const fieldToExtract = [
      'sku_num',
      'name',
      'buying_unit',
      'sub_unit',
      'qty_inner_units',
      'expense_account_id',
      'count',
      'price',
      'inventory_location_id',
    ];

    const filteredSkus: any[] = unfilteredSkus.map(sku => {
      return fieldToExtract.reduce((obj, key) => {
        if (sku.hasOwnProperty(key)) {
          return {
            ...obj,
            [key]: sku[key],
          };
        }
        return obj;
      }, {});
    });

    filteredSkus.forEach(sku => {
      this.locations.forEach(location => {
        if (sku.inventory_location_id === location.id) {
          sku.inventory_location_name = location.name;
          delete sku.inventory_location_id;
        }
      });
    });

    return [...filteredSkus].sort((a, b) => {
      return a.inventory_location_name.localeCompare(b.inventory_location_name);
    });
  }

  saveItem(
    sku: any,
    inventory_count_id: number,
    count: number,
    location_id: number
  ) {
    this.error = false;
    this.saveMessage = null;

    if (!(count >= 0) || count === null) {
      this.error = true;
      setTimeout(() => {
        this.error = false;
      }, 3000);
    }
    let updates = [];

    if (count > -1) {
      if (inventory_count_id > 0) {
        updates.push({ id: inventory_count_id, count: count });
      } else {
        updates.push({
          date: this.date,
          location_id: this.accountService.getLocationId(),
          sku_id: sku.id,
          count: count,
          sku_location_id: sku.sku_location_id,
        });
      }
    }

    if (updates.length > 0 && !this.error) {
      this.inventoryCountService
        .updateInventoryCount(updates)
        .subscribe(updated => {
          if (updated[0]['success'] === false) {
            this.error = true;
            this.load(false);
            setTimeout(() => {
              this.error = false;
            }, 3000);
          }
        });
    }
  }

  gridListToggle(option: string) {
    this.gridListView = option;
  }

  onFocus(event: Event) {
    const input = event.target as HTMLInputElement;
    input.select();
  }
}
