<freemium *ngIf="isFreemium()"></freemium>

<div class="card nav-padding" *ngIf="loc && !isFreemium()">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <label>
          Permission Groups
          <span
            *ngIf="loc._edit"
            class="cdi-s-cancel float-right pointer"
            (click)="cancelLocEdit()"></span>
          <span
            *ngIf="loc._edit"
            class="cdi-s-save float-right pointer"
            (click)="saveLoc()"></span>
          <span
            *ngIf="!loc._edit"
            class="cdi-s-edit float-right pointer"
            (click)="editLoc()"></span>
        </label>
      </div>
    </div>
    <strong>
      <div class="row no-gutters" *ngIf="!loc._edit">
        <div class="col-3"></div>
        <div class="col-3">{{ loc.level_3 }}</div>
        <div class="col-3">{{ loc.level_2 }}</div>
        <div class="col-3">{{ loc.level_1 }}</div>
      </div>
    </strong>
    <!-- edit version-->
    <form ngNativeValidate *ngIf="loc._edit">
      <div class="row no-gutters">
        <div class="col-3"></div>
        <div class="col-3">
          <input
            type="text"
            [(ngModel)]="loc._edit.level_3"
            name="level_3"
            class="form-control"
            required
            placeholder="Manager" />
        </div>
        <div class="col-3">
          <input
            type="text"
            [(ngModel)]="loc._edit.level_2"
            name="level_2"
            class="form-control"
            required
            placeholder="Shift Leader" />
        </div>
        <div class="col-3">
          <input
            type="text"
            [(ngModel)]="loc._edit.level_1"
            name="level_1"
            class="form-control"
            required
            placeholder="Regular Staff" />
        </div>
      </div>
    </form>
  </div>
</div>

<div class="card nav-padding" *ngIf="perms && !isFreemium()">
  <div class="card-body">
    <ng-container *ngFor="let category of PermissionCategoryKeys">
      <div class="row">
        <div class="col-12">
          <label class="mt-3 mb-0">{{category}}</label>
        </div>
      </div>

      <strong>
        <div class="row">
          <div class="col"></div>
          <div class="col">Owner</div>
          <div class="col">{{ loc.level_3 }}</div>
          <div class="col">{{ loc.level_2 }}</div>
          <div class="col">{{ loc.level_1 }}</div>
        </div>
      </strong>

      <div class="row" *ngFor="let permId of PermissionCategory[category]">
        <div class="col">{{ PermissionNames[permId] }}</div>
        <div class="col">
          <input-toggle
            [(ngModel)]="perms[permId][UserType.Owner].value"
            [disabled]="isOwner && permId == Permission.AccessSettings"
            (ngModelChange)="toggleEdit(perms[permId][UserType.Owner])">
          </input-toggle>
        </div>
        <div class="col">
          <input-toggle
            [(ngModel)]="perms[permId][UserType.Manager].value"
            (ngModelChange)="toggleEdit(perms[permId][UserType.Manager])">
          </input-toggle>
        </div>
        <div class="col">
          <input-toggle
            [(ngModel)]="perms[permId][UserType.ShiftLead].value"
            (ngModelChange)="toggleEdit(perms[permId][UserType.ShiftLead])">
          </input-toggle>
        </div>
        <div class="col">
          <input-toggle
            [(ngModel)]="perms[permId][UserType.Regular].value"
            (ngModelChange)="toggleEdit(perms[permId][UserType.Regular])">
          </input-toggle>
        </div>
      </div>
    </ng-container>

    <!--<save-button class='float-right' (click)="save()"></save-button>-->
    <button
      type="button"
      class="btn btn-primary float-right mt-3"
      (click)="reset()">
      Restore Defaults
    </button>
  </div>
</div>
