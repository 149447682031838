import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import { ChecklistService, IChecklist } from '../services/checklist.service';
import {
  ChecklistItemService,
  IChecklistItem,
} from '../services/checklistItem.service';
import {
  ChecklistItemCompletedService,
  IChecklistItemCompleted,
} from '../services/checklistItemCompleted.service';
import {
  ChecklistCompletedService,
  IChecklistCompleted,
} from '../services/checklistCompleted.service';
import { baseRole } from '../shared/baseRole';
import { ChecklistItemType } from '../shared/api';
import { UserService, IUser } from '../services/user.service';
import { ChecklistPositionMapService } from 'source/services/checklistPositionMap.service';
import { EmployeePositionService } from 'source/services/employeePosition.service';
import * as moment from 'moment';
@Component({
  selector: 'checklist-list',
  templateUrl: 'checklist.list.html',
})
export class ChecklistListComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public checklist: any[];
  public accountSub;
  public currentPositions: any[];
  public ChecklistItemType = ChecklistItemType;
  public _date: Date = new Date();
  @Input() set date(value: Date) {
    if (value != this._date) {
      this._date = value;
      this.getChecklistItem(value);
    }
  }

  constructor(
    public accountService: AccountService,
    public checklistService: ChecklistService,
    public checklistPositionMapService: ChecklistPositionMapService,
    public userService: UserService,
    public employeePositionService: EmployeePositionService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.getChecklistItem(this._date);
  }

  ngOnDestroy() {
    if (this.accountSub) this.accountSub.unsubscribe();
  }

  load() {}

  getChecklistItem(date) {
    this.accountSub = this.accountService
      .getUserUpdateObservable()
      .subscribe(() => {
        this.load();
      });

    this.employeePositionService
      .list({
        location_id: this.accountService.getLocationId(),
        user_id: this.accountService.getUserId(),
      })
      .subscribe(data => {
        this.currentPositions = data.filter(e => {
          return e.active;
        });
      });
    this.checklistService.listWithComplete(date).subscribe(data => {
      this.checklist = data;
      if (!this.accountService.isOwner()) {
        this.checklist = this.checklist.filter(checklist => {
          if (checklist.has_position_assignments) {
            if (checklist.position_id === null) return true;
            return this.currentPositions.find(
              position => position.position_id === checklist.position_id
            );
          } else return true;
        });
      }

      // Filter out duplicates
      // this.checklist = this.checklist.filter((checklist, index, self) => {
      //   return index === self.findIndex(filter => filter.id === checklist.id);
      // });
    });
  }
}

@Component({
  selector: 'checklist-edit',
  templateUrl: 'checklist.edit.html',
})
export class ChecklistEditComponent extends baseRole {
  public checklist: any;
  public checklistItems: any[];
  public date: Date;
  public ChecklistItemType = ChecklistItemType;

  @ViewChild('modal')
  public modal: ModalDirective;

  constructor(
    public accountService: AccountService,
    public checklistService: ChecklistService,
    public checklistCompletedService: ChecklistCompletedService,
    public checklistItemService: ChecklistItemService,
    public checklistItemCompletedService: ChecklistItemCompletedService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    this.checklistItemService
      .listWithComplete(this.checklist.id, this.date)
      .subscribe(checklistItemData => {
        this.checklistItems = checklistItemData;
        for (let item of this.checklistItems) {
          item.completed = item.completed_id != null;
          if (
            !item.completed &&
            item.checklist_item_type == ChecklistItemType.Number
          ) {
            item.data = item.min;
          }
        }
        this.modal.show();
      });
    if (!this.checklist.completed_id) {
      this.checklistCompletedService
        .update({
          checklist_id: this.checklist.id,
          status: 0,
          date: this.date,
        })
        .subscribe(checklistCompletedData => {
          this.checklist.completed_id = checklistCompletedData.id;
        });
    }
  }

  save() {
    let done = true;

    if (!this.checklist.pending || !this.checklist.completed_id) {
      this.modal.hide();
      return;
    }

    let p = [];
    for (let i of this.checklistItems) {
      if (i.completed_id) continue;
      if (i.completed) {
        let obj: any = {
          checklist_id: this.checklist.id,
          checklist_item_id: i.id,
          checklist_completed_id: this.checklist.completed_id,
          user_id: this.getUserId(),
        };
        if (i.checklist_item_type == ChecklistItemType.Number) {
          obj.data = i.data;
        }
        p.push(obj);
      } else done = false;
    }

    if (p.length == 0) {
      this.modal.hide();
    } else {
      this.checklistItemCompletedService.bulkUpdate(p).subscribe(data => {
        if (done && !this.checklist.status) {
          this.checklistCompletedService
            .update({ id: this.checklist.completed_id, status: true })
            .subscribe(() => {
              this.checklist.pending = false;
              this.checklist.status = true;
              this.modal.hide();
            });
        } else {
          this.modal.hide();
        }
      });
    }
  }

  cancel() {
    this.modal.hide();
  }

  show(d, date: Date) {
    this.checklist = d;
    this.date = date;
    this.load();
  }
}
