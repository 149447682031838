import { Injectable } from '@angular/core';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IReview } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ReviewService extends BaseService<IReview> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'review');
  }
}

export { IReview } from '../shared/api';
