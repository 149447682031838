<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Fixed Cost Editor"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content" *ngIf="data && newItem">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Edit Menu Categories
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div class="card">
          <form ngNativeValidate>
            <div class="input-group">
              <input
                [(ngModel)]="newItem._edit.name"
                type="text"
                name="name"
                maxlength="50"
                class="form-control override-invalid"
                placeholder="Add Category"
                required />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary bg-white btn-icon"
                  type="submit"
                  (click)="saveObj(newItem)">
                  <span class="cdi-s-add"></span>
                </button>
              </div>
            </div>
          </form>
        </div>

        <form ngNativeValidate>
          <div class="card">
            <table class="table table-hover mb-0">
              <tbody>
                <tr *ngFor="let d of data | orderBy: 'name'">
                  <td>
                    <span *ngIf="!d._edit">{{d.name}}</span>
                    <input
                      type="text"
                      [name]="'name['+d.id+']'"
                      maxlength="50"
                      class="form-control"
                      [(ngModel)]="d._edit.name"
                      *ngIf="d._edit" />
                  </td>
                  <td class="cdi-s">
                    <span
                      class="cdi-s-edit pointer"
                      *ngIf="!d._edit"
                      (click)="editObj(d)"></span>
                    <span
                      class="cdi-s-save pointer"
                      *ngIf="d._edit"
                      (click)="saveObj(d)"></span>
                  </td>
                  <td class="cdi-s">
                    <delete-modal
                      *ngIf="!d._edit && d._can_delete"
                      (onDelete)="deleteObj($event)"
                      [deleteObject]="d"
                      [name]="d.name">
                      <span class="cdi-s-trash pointer"></span>
                    </delete-modal>
                    <span
                      *ngIf="!d._edit && !d._can_delete"
                      class="cdi-s-no-trash"></span>
                    <span
                      class="cdi-s-cancel"
                      *ngIf="d._edit"
                      (click)="cancelEdit(d)"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          Menu categories are used solely for filtering the menu item list
          view.<br />
          Categories with items cannot be deleted.
          <div class="row mt-3">
            <div class="col-sm-12">
              <done-button class="float-right" (click)="cancel()"></done-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
