import { PaymentService } from '../services/payment.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { baseRole } from './baseRole';
import { AccountService } from '../services/account.service';
import { LocationService } from '../services/location.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PackageType, PackageTypeNames } from './api';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'card-account-packages',
  templateUrl: './cardAccountPackages.html',
})
export class CardAccountPackagesComponent extends baseRole implements OnInit {
  public locId: number;
  public now = new Date();
  public loc: any;
  public isFreePro = false;
  public unPaidPrice: number;
  public newType: number = null;
  public packageError: string;
  public packageMessage: string;
  public PackageType = PackageType;
  public PackageTypeNames = PackageTypeNames;
  public priceId: string;
  public billStatus = 0; // 1 = success, 2 = failure
  public status: string;
  public stripeSessionId: string;
  public paymentStatus: string;
  private currentRoute: string;

  @ViewChild('ccModal') public ccModal;
  @ViewChild('confirmModal') public confirmModal: ModalDirective;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    protected accountService: AccountService,
    protected paymentService: PaymentService,
    private http: HttpClient
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
      this.stripeSessionId = params['session_id'];
    });
    this.route.params.subscribe(params => {
      this.locId = params['id'] || this.getLocationId();
      this.load();
    });
    this.currentRoute = this.router.url.substring(1);
  }

  load() {
    forkJoin([
      this.locationService.get(this.locId),
      this.paymentService.getPaymentStatus(this.locId),
    ]).subscribe(results => {
      this.loc = results[0];
      const paymentStatusObject: any = results[1];
      if (paymentStatusObject?.status) {
        this.paymentStatus = paymentStatusObject.status;
      }
      if (this.status === 'cancelled') {
        this.paymentService
          .removeStripeSession(this.stripeSessionId, this.loc.id)
          .subscribe(() => {
            console.log('Stripe session removed');
          });
      }
      if (this.status === 'success') {
        if (paymentStatusObject?.status === 'active') {
          this.newType = this.loc.package_type;
          this.changePackage();
        }
      }
    });
  }

  changePackage() {
    this.packageMessage = 'Working....';
    this.locationService
      .changePlan({
        location_id: this.loc.id,
        package_type: this.newType,
      })
      .subscribe(data => {
        if (data) {
          this.accountService.getLocation().jail_no_package = false;
          this.accountService.getLocation().has_picked_package = true;
          this.loc.new_package_type = null;
          if (this.newType !== this.loc.package_type) {
            this.loc.package_type = this.newType;
            this.getLocation().package_type = this.newType;
          }
          // if (this.newType == PackageType.Pro) {
          //   this.router.navigate([
          //     '/settings/account',
          //     this.loc.id,
          //     'integration',
          //   ]);
          //   return;
          // }
          this.packageMessage = 'Done';
          this.newType = null;
          setTimeout(() => {
            this.packageMessage = null;
            this.router.navigate(['/settings/account', this.loc.id, 'package']);
          }, 3000);
        } else {
          this.packageError =
            'Unable to change package. Please contact support for assistance..';
          setTimeout(() => {
            this.packageError = null;
          }, 3000);
        }
      });
  }

  checkout(newType) {
    this.newType = newType;
    this.paymentService
      .findOrCreateCustomer(this.locId)
      .subscribe((customer: any) => {
        this.paymentService
          .subscribeCustomer(
            this.currentRoute,
            newType,
            customer.id,
            this.locId
          )
          .subscribe((subscription: any) => {
            if (subscription.status === 'active') {
              this.changePackage();
            }
          });
      });
  }

  createStripePortalSession() {
    this.paymentService
      .createStripePortalSession(
        '/settings/account/' + this.loc.id + '/package',
        this.loc.id
      )
      .subscribe((data: any) => {
        window.location.href = data;
      });
  }
}
