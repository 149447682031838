import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';

@Component({
  templateUrl: './testUI.html',
})
export class TestUIComponent implements OnInit {
  public date = new Date();
  public rbutton = 1;
  public cbox = false;

  public select;

  public data: any[] = [
    { data: 'There is some test data' },
    { data: 'More data here' },
  ];

  public color = '#FF0000';
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {}

  ngOnInit() {}
}
