import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  PasswordStrength,
  MinimumPasswordStrength,
} from '../shared/passwordStrength';

const strength = new PasswordStrength();

@Component({
  templateUrl: './passwordReset.html',
})
export class PasswordResetComponent implements OnInit {
  private token: string;
  public email: string;
  public password: string;
  public cpassword: string;
  public error: any = {};
  public warning: string;
  public suggestions: string[];
  public pTest = new PasswordStrength();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    if (this.accountService.getUser() != null) {
      console.log('User signed in, redirecting.');
      this.router.navigate(['/']);
      return;
    }
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
    });
  }

  checkPassword() {
    delete this.warning;
    delete this.suggestions;
    let [score, warning, suggestions] = this.pTest.measure(this.password);
    if (score < MinimumPasswordStrength) {
      this.warning = warning;
      this.suggestions = suggestions;
      return false;
    }
    return true;
  }

  matchPassword() {
    delete this.error.matchPassword;
    if (this.password !== this.cpassword) {
      this.error.matchPassword = 'Passwords do not match';
      return false;
    }
    return true;
  }

  resetPassword() {
    if (this.error.token) return;
    if (!this.matchPassword()) return;
    if (!this.checkPassword()) return;
    this.error = {};

    this.accountService
      .resetPassword(this.email, this.token, this.password)
      .subscribe(
        data => {
          if (data) {
            this.accountService
              .login(this.email, this.password)
              .subscribe(user => {
                if (!user) {
                  this.error.global =
                    'There was an error. Please contact customer support.';
                } else {
                  if (this.accountService.getLocations().length == 0) {
                    this.router.navigate(['/profile']);
                  } else if (this.accountService.checkJail()) {
                    this.accountService
                      .setLocation(user.locations[0].id)
                      .subscribe(() => {});
                    this.router.navigate(['/profile']);
                  } else if (user.locations.length == 1) {
                    this.accountService
                      .setLocation(user.locations[0].id)
                      .subscribe(() => {});
                    this.router.navigate(['/']);
                  } else {
                    this.router.navigate(['/']);
                  }
                }
              });
          } else {
            this.error.global =
              'There was an error. Please contact customer support.';
            this.removeError();
          }
        },
        err => {
          this.error.global =
            'There was an error. Please contact customer support.';
          this.removeError();
        }
      );
  }

  removeError() {
    setTimeout(() => {
      this.error = {};
    }, 5000);
  }
}
