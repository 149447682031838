import {
  Component,
  Input,
  Output,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'signature',
  template:
    '<signature-pad #sigPad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>',
})
export class SignatureComponent implements AfterViewInit {
  @ViewChild('sigPad')
  signaturePad: SignaturePad;

  @Output() hasDrawn: EventEmitter<boolean>;

  public signaturePadOptions: Object = {
    canvasWidth: 450,
    canvasHeight: 200,
  };

  constructor() {
    this.hasDrawn = new EventEmitter<boolean>();
    this.hasDrawn.emit(false);
  }

  ngAfterViewInit() {
    this.signaturePad.clear();
  }

  clear() {
    this.signaturePad.clear();
  }

  drawComplete() {
    this.hasDrawn.emit(true);
  }

  getSvg() {
    return this.signaturePad.toDataURL('image/svg+xml');
  }
}

@Component({
  selector: 'signature-display',
  template: '<div [innerHTML]="svg">',
})
export class SignatureDisplayComponent {
  @Input() svg: string;
}
