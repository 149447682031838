<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" *ngIf="invoice">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Invoice
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <form ngNativeValidate (submit)="save()">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <label>Company</label>
                  <input
                    [(ngModel)]="vendorName"
                    autocomplete="off"
                    [typeahead]="vendors"
                    typeaheadOptionField="company"
                    typeaheadOptionsLimit="20"
                    class="form-control override-invalid"
                    name="vendor"
                    required
                    placeholder="Enter Vendor"
                    (typeaheadOnSelect)="vendorName=$event;vendorNameToId()"
                    (blur)="vendorNameToId()" />
                  <span *ngIf="newVendor"
                    >Looks like you've entered a new vendor. Choose a group to
                    add it to your contact list. Fill in the details later.
                  </span>
                  <select
                    *ngIf="newVendor"
                    [(ngModel)]="newVendor.contact_group_id"
                    name="vendor_group_id"
                    class="form-control d-inline-block"
                    style="width: 15rem">
                    <option *ngFor="let g of contactGroups" [value]="g.id">
                      {{g.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <label>Invoice #</label>
                  <input
                    [(ngModel)]="invoice.ponumber"
                    type="text"
                    maxlength="50"
                    class="override-invalid form-control"
                    name="invoice_id"
                    debounce
                    (result)="checkInvoice()"
                    required
                    placeholder="Enter Invoice #" />
                  <local-error [error]="error.ponumber"></local-error>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <label>Date</label>
                  <input
                    type="text"
                    class="form-control input-calendar"
                    bsDatepicker
                    [(ngModel)]="invoice.date"
                    name="invoice_date" />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <label>Terms</label>
                  <select
                    name="field"
                    [(ngModel)]="invoice.terms"
                    class="form-control"
                    required>
                    <option [value]="0">Due on delivery</option>
                    <option value="7">Net 7</option>
                    <option value="14">Net 14</option>
                    <option value="21">Net 21</option>
                    <option value="30">Net 30</option>
                    <option value="60">Net 60</option>
                    <option value="90">Net 90</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="card" *ngIf="newItem && items && expenseAccounts">
            <div class="card-body">
              <label>Line Items - Total {{total | currency: 'USD' }}</label>
              <div class="input-group">
                <input
                  type="text"
                  [(ngModel)]="newItem.name"
                  name="new_item_name"
                  maxlength="100"
                  class="form-control"
                  style="width: 40%; height: calc(2.25rem + 4px)"
                  placeholder="Line Item Name" />
                <select
                  [(ngModel)]="newItem.expense_account_id"
                  class="form-control"
                  style="width: 30%"
                  name="item_category_new">
                  <option
                    *ngFor="let e of expenseAccounts | orderBy: 'name'"
                    [value]="e.id">
                    {{e.name}}
                  </option>
                  <!--								<option [value]='null'>Add New Category</option> -->
                </select>
                <!--They messed up here, owner can turn off BOH FOH  BUG BUG BUG-->
                <div class="input-group-append">
                  <!-- amount accepts only digits and decimal. and limited to 2 places after decimal  -->
                  <span class="input-group-text">$</span>
                  <input
                    [(ngModel)]="newItem.amount"
                    type="number"
                    step="0.01"
                    min="0"
                    class="form-control mb-0"
                    style="height: calc(2.25rem + 4px)"
                    placeholder="00.00"
                    name="amount_new"
                    (ngModelChange)="doItemUpdate(false)" />
                  <span class="cdi-s-add pointer" (click)="addItem()"></span>
                </div>
              </div>
              <div *ngFor="let item of items;let i = index">
                <div class="input-group">
                  <input
                    type="text"
                    [(ngModel)]="item.name"
                    [name]="'line_item_name'+i"
                    maxlength="100"
                    required
                    class="form-control"
                    style="width: 40%" />
                  <select
                    [(ngModel)]="item.expense_account_id"
                    class="form-control"
                    style="width: 30%"
                    name="{{'item_category_list_'+i}}">
                    <option
                      *ngFor="let e of expenseAccounts | orderBy: 'name'"
                      value="{{e.id}}">
                      {{e.name}}
                    </option>
                  </select>
                  <div class="input-group-append">
                    <!-- amount accepts only digits and decimal. and limited to 2 places after decimal  -->
                    <span class="input-group-text">$</span>
                    <input
                      [(ngModel)]="item.amount"
                      type="number"
                      step="0.01"
                      class="form-control mb-0"
                      placeholder="00.00"
                      [name]="'amount_'+i"
                      (ngModelChange)="doItemUpdate(false)" />
                    <span
                      class="cdi-s-trash pointer"
                      (click)="deleteItem(item)"></span>
                  </div>
                </div>

                <!--			<div class="input-group" *ngIf="item.expense_account_id==null">
									<input type="text" maxlength="50" class="form-control" placeholder="Enter New Category">
									<div class="input-group-append" >
										<select class='form-control mb-0' [name]="'invoice_item_type_'+i">
											<option value='0'>Treat as COGS</option>
											<option *ngIf='getLocation().has_houses' value='1'>Treat as FOH Labor</option>
											<option *ngIf='getLocation().has_houses' value='2'>Treat as BOH Labor</option>
											<option *ngIf='!getLocation().has_houses' value='3'>Treat as Fixed Labor</option>
										</select>
										<button class="btn border-0 bg-white btn-icon" type="submit"><span class='cdi-s-add'> </span></button>
									</div>
								</div> -->
              </div>
            </div>
          </div>
          <!--					<label>Total {{total | currency: 'USD' }}</label>  -->

          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <button
                *ngIf="!invoice.id"
                class="btn btn-primary float-right mx-2"
                (click)="setFlag(true)">
                Add Invoice &amp; Close
              </button>
              <button
                *ngIf="!invoice.id"
                class="btn btn-primary float-right"
                (click)="setFlag(false)">
                Add &amp; New
              </button>
              <button
                *ngIf="invoice.id"
                class="btn btn-primary float-right mr-2">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
