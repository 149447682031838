import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ISubNavItem } from '../shared/api';

@Component({
  templateUrl: './billing.html',
})
export class BillingComponent implements OnInit {
  public menu: ISubNavItem[] = [
    // {
    //   label: 'Chart',
    //   routerLink: '/admin/billing',
    //   exact: true,
    // },
    {
      label: 'Active',
      routerLink: '/admin/billing',
    },
    // {
    //   label: 'Payments',
    //   routerLink: '/admin/billing/payments',
    // },
  ];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {}

  ngOnInit() {}
}
