<div class="row">
  <div class="col-12">
    <a class="float-right" style="font-size: 1rem" routerLink="contact"
      >Import Contacts</a
    >
  </div>
</div>
<div class="row" *ngIf="!success">
  <div class="col-12">
    <label>Import SKU File</label>
    <input
      type="file"
      (change)="onFileUpload($event)"
      multiple="false"
      size="1"
      class="form-control" />
  </div>
</div>
<div class="row" *ngIf="filename">
  <div class="col-12">
    <button
      type="button"
      *ngIf="!error.global && data"
      (click)="doImport()"
      class="float-right btn btn-primary">
      Import
    </button>
    <h4>Filename: {{ filename }}</h4>
  </div>
</div>

<alert type="danger" *ngIf="error.global">
  <strong>Error: </strong> {{ error.global }}
</alert>

<alert type="success" *ngIf="success">
  Success.
  <a style="font-size: 1rem" routerLink="/admin/">Back</a>
</alert>

<div class="table-responsive">
  <table class="table table-stripped table-hover">
    <thead *ngIf="headers">
      <tr>
        <td *ngFor="let h of headers" [class.bg-danger]="!h.type">
          {{ h.name }}
        </td>
      </tr>
    </thead>

    <tbody *ngIf="data">
      <tr *ngFor="let d of data;let i=index">
        <td
          *ngFor="let v of d;let j=index"
          [class.bg-danger]="dataError[i] && dataError[i][j]">
          {{v}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
