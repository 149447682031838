import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardFreemium, AuthGuardJail } from '../app/auth-guard';
import { InventoryComponent } from './inventory.component';
import { InventoryCountComponent } from './inventoryCount.component';
import { LocationsComponent } from './locations.component';
import { MenuComponent } from './menu.component';
import { PurchaseOrderComponent } from './purchaseOrder.component';
import { PurchaseOrderEditComponent } from './purchaseOrderEdit.component';
import { PurchaseOrderGenerateComponent } from './purchaseOrderGenerate.component';
import { RecipeComponent } from './recipe.component';
import { RecipeBlockEditComponent } from './recipeBlockEdit.component';
import { SkuComponent } from './sku.component';
import { SkuEditComponent } from './skuEdit.component';
import { SkuPriceComponent } from './skuPrice.component';
import { StandingOrderComponent } from './standingOrder.component';
import { TakeComponent } from './take.component';
import { PurchaseOrderStatus } from '../shared/api';

const appRoutes: Routes = [
  {
    path: 'inventory',
    canActivate: [AuthGuard, AuthGuardFreemium, AuthGuardJail],
    component: InventoryComponent,
    children: [
      {
        path: '',
        children: [
          { path: '', component: SkuComponent },
          { path: 'sku/import/price', component: SkuPriceComponent },
          { path: 'sku/add', component: SkuEditComponent },
          { path: 'sku/:id', component: SkuEditComponent },
        ],
      },
      { path: 'locations', component: LocationsComponent },
      {
        path: 'recipe',
        children: [
          { path: '', component: RecipeComponent },
          { path: 'add', component: RecipeBlockEditComponent },
          { path: ':id', component: RecipeBlockEditComponent },
        ],
      },
      { path: 'menu', component: MenuComponent },

      { path: 'take', component: TakeComponent },

      {
        path: 'orders/purchase',
        children: [
          { path: '', component: PurchaseOrderComponent },
          { path: 'add', component: PurchaseOrderGenerateComponent },
          { path: ':id', component: PurchaseOrderEditComponent },
        ],
      },
      {
        path: 'orders/standing',
        children: [
          { path: '', component: StandingOrderComponent },
          {
            path: 'add',
            component: PurchaseOrderEditComponent,
            data: { status: PurchaseOrderStatus.Standing },
          },
          {
            path: ':id',
            component: PurchaseOrderEditComponent,
            data: { status: PurchaseOrderStatus.Standing },
          },
        ],
      },
      { path: 'report', component: InventoryCountComponent },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  appRoutes,
  {
    scrollPositionRestoration: 'enabled',
  }
);
