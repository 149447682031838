import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { LogService, ILog, ILogReport } from '../services/log.service';
import { ServiceTimeType, moment, IChartSales } from '../shared/api';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'chart-summary',
  templateUrl: './chart.summary.html',
})
export class ChartSummaryComponent {
  public chart: any;
  public lastYear: ILogReport;
  public thisYear: ILogReport;
  public Highcharts = Highcharts;

  @Input() set reportData(value: any) {
    this.lastYear = value.lastYear;
    this.thisYear = value.thisYear;
    this.salesChart();
  }

  constructor(public accountService: AccountService) {}

  salesChart() {
    let categories = [];
    let dataLastYear = [];
    let data = [];

    if (this.accountService.getLocation().is_service_time_daily) {
      categories.push('Daily');
      data.push(this.thisYear.service_time[0].sales);
      dataLastYear.push(this.lastYear.service_time[0].sales);
    } else {
      dataLastYear = [0, 0, 0];
      data = [0, 0, 0];
      categories.push('Breakfast');
      categories.push('Lunch');
      categories.push('Dinner');
      for (
        let i = ServiceTimeType.Breakfast;
        i <= ServiceTimeType.Dinner;
        i++
      ) {
        dataLastYear[i - 1] = this.lastYear
          ? this.lastYear.service_time[i].sales
          : 0;
        data[i - 1] = this.thisYear ? this.thisYear.service_time[i].sales : 0;
      }
    }

    this.chart = {
      chart: {
        type: 'column',
        marginTop: 50,
      },
      legend: {
        labelFormatter: function () {
          var total = 0;
          for (var i = 0; i < this.yData.length; i++) {
            total += this.yData[i];
          }
          return this.name + ': $' + Math.round(total).toLocaleString('en');
        },
        enabled: true,
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: 0,
        floating: true,
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      title: {
        text: null, // This changes if empty week.
      },
      subtitle: { text: null },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        title: { text: null },
        gridLineColor: '#aaaaaa',
        gridLineWidth: 1,
        showEmpty: true,
        min: 0,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            color: 'black',
            style: { fontWeight: 'bold' },
            formatter: function () {
              return '$' + Math.round(this.y).toLocaleString('en');
            },
          },
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      tooltip: { enabled: false },
      series: [
        {
          name: 'Last Year',
          data: dataLastYear,
        },
        {
          name: 'This Year',
          data: data,
        },
      ],
    };
  }
}

@Component({
  selector: 'chart-labor-service-time',
  templateUrl: './chart.laborServiceTimes.html',
})
export class ChartLaborServiceComponent implements OnInit {
  public chart: any;
  public Highcharts = Highcharts;
  @Input() set data(value: any[]) {
    this._data = value;
    this.buildChart();
  }
  public _lastYearData;
  public _data;
  constructor() {}

  ngOnInit() {
    //this.buildChart();
  }

  buildChart() {
    //	let dataLastYear = [ ['Breakfast',0], ['Lunch', 0 ],['Dinner',100]];
    //let data = [ ['Breakfast',23], ['Lunch', 145 ],['Dinner',10]];
    let data = this._data[0];
    let dataLastYear = this.data[1];

    this.chart = {
      chart: {
        type: 'pie',
      },
      title: {
        text: 'Service Time Sched vs Historical (%)',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          size: '75%',
          showInLegend: true,
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: 'Historical',
          innerSize: '0%',
          size: '60%',
          animation: false,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: '#000000',
            distance: 28,
          },
          data: dataLastYear,
        },
        {
          name: 'Scheduled',
          innerSize: '60%',
          size: '100%',
          animation: false,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: '#000000',
            distance: 28,
          },
          data: data,
        },
      ],
    };
  }
}

@Component({
  selector: 'chart-labor-payroll',
  templateUrl: './chart.laborPayroll.html',
})
export class ChartLaborPayrollComponent implements OnInit {
  public chart: any;
  public Highcharts = Highcharts;
  @Input() set data(value: any[]) {
    this._data = value;
    if (this._data) {
      this.buildChart();
    }
  }
  public _lastYearData;
  public _data;
  constructor() {}

  ngOnInit() {
    //this.buildChart();
  }

  buildChart() {
    /*let dataLastYear = [ ['Breakfast',0], ['Lunch', 0 ],['Dinner',100]];
		let data = [ ['Breakfast',23], ['Lunch', 145 ],['Dinner',10]];
		console.log(this._data);
		console.log(data);
		console.log(dataLastYear);*/

    this.chart = {
      chart: {
        type: 'pie',
      },
      title: {
        text: 'Payroll FOH vs BOH (%)',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          size: '75%',
          showInLegend: true,
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: 'Historical',
          innerSize: '0%',
          size: '33%',
          animation: false,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: '#000000',
            distance: 28,
          },
          data: this._data[0],
        },
        {
          name: 'Scheduled',
          innerSize: '33%',
          size: '66%',
          animation: false,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: '#000000',
            distance: 28,
          },
          data: this._data[1],
        },
        {
          name: 'Actual',
          innerSize: '66%',
          size: '100%',
          animation: false,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: '#000000',
            distance: 28,
          },
          data: this._data[2],
        },
      ],
    };
    //console.log(this.chart);
  }
}

@Component({
  selector: 'chart-main-sales',
  template:
    '<highcharts-chart [Highcharts]="Highcharts" [options]="chart" style="height: 100%; width: 100%; display: block;"></highcharts-chart>',
})
export class ChartMainSalesComponent {
  public chart: any;
  public Highcharts = Highcharts;
  public _chartData: IChartSales;
  public isDaily: boolean;
  public weekStart: number;

  @Input() set chartData(value: IChartSales) {
    this._chartData = value;
    /*		console.log('Main Chart');
		console.log(value.data[0].data[0]);
		console.log(value.data[0].data[1]); */
    if (this._chartData) this.buildChart();
  }
  constructor(protected accountService: AccountService) {
    this.isDaily = this.accountService.getLocation().is_service_time_daily;
    this.weekStart = this.accountService.getLocation().week_start;
  }

  buildChart() {
    let self = this;
    //console.log('Main Salse Chart Data');
    //console.log(this._chartData);
    this.chart = {
      chart: {
        backgroundColor: null,
        type: 'column',
        credits: {
          enabled: false,
        },
        zoomType: 'none',
        marginTop: 50,
        animation: false,
      },
      legend: {
        labelFormatter: function () {
          var total = 0;
          for (var i = 0; i < this.yData.length; i++) {
            total += this.yData[i];
          }
          return this.name + ': $' + Math.round(total).toLocaleString('en');
        },
        enabled: this._chartData.titleBar,
        align: 'right',
        x: 0,
        y: 0,
        verticalAlign: 'top',
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      title: { text: null },
      subtitle: { text: null },
      xAxis: {
        type: 'datetime',
        tickInterval: this._chartData.tickInterval,
        startOfWeek: this.weekStart,
        labels: {
          formatter: function () {
            return moment(this.value).format(
              self._chartData.labelFormat || 'D... MMM'
            );
          },
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        title: { text: null },
        gridLineColor: '#aaaaaa',
        gridLineWidth: 1,
        showEmpty: true,
        min: 0,
      },
      plotOptions: {
        series: {
          animation: false,
        },
        spline: {
          showInLegend: true,
          color: '#000000',
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
                radius: 3,
              },
            },
          },
          lineWidth: 3,
          states: {
            hover: {
              enabled: false,
              lineWidth: 2,
              lineWidthPlus: 0,
            },
          },
        },
        line: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true,
                radius: 3,
              },
            },
          },
          lineWidth: 2,
          states: {
            hover: {
              enabled: true,
              lineWidth: 2,
              lineWidthPlus: 0,
            },
          },
        },
        areaspline: {
          fillOpacity: '.65',
          stacking: 'normal',
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true,
                radius: 3,
              },
            },
          },
          lineWidth: 1,
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
              lineWidthPlus: 0,
            },
          },
        },
        area: {
          stacking: 'normal',
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true,
                radius: 3,
              },
            },
          },
          lineWidth: 1,
          lineColor: '#999999',
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
              lineWidthPlus: 0,
            },
          },
        },
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        borderColor: '#000000',
        enabled: true,
        hideDelay: 0,
        shared: true,

        useHTML: true,
        formatter: function () {
          var t = 0;
          var r = '';
          var h = 0;
          var laborPercent = '0.00';
          var cogsPercent = '0.00';
          var primePercent = '0.00';
          var cogsRaw = 0;
          var laborRaw = 0;
          var data = 0;

          var s =
            '<table cellspacing="0"><tr><td colspan="3" class ="tooltipH">' +
            moment(this.x).utc().format(self._chartData.popupFormat) +
            '</td></tr>';

          var total = 0;
          //console.log(this.points);
          for (var i = 0; i < this.points.length; i++) {
            var point = this.points[i];
            if (
              point.series.name != 'Labor Costs' &&
              point.series.name != 'COGS' &&
              point.series.name != 'Prime Costs'
            ) {
              total += point.y;
              s +=
                '<tr><td class="tooltipR"><b>' +
                point.series.name +
                ':</b></td><td>$' +
                Number(point.y).toFixed(2) +
                '</td><td class="tooltipSmall">' +
                Number(point.percentage).toFixed(2) +
                '%</td</tr>';
            } else if (point.series.name == 'Labor Costs') {
              t = point.y;
            } else if (point.series.name == 'COGS') {
              h = point.y;
            }

            if (point.y != 0) {
              data++;
            }
          }

          if (this.points[0].total != 0) {
            laborRaw = (t / this.points[0].total) * 100;
            laborPercent = Number((t / this.points[0].total) * 100).toFixed(2);
            cogsRaw = (h / this.points[0].total) * 100;
            cogsPercent = Number((h / this.points[0].total) * 100).toFixed(2);
          }

          primePercent = Number(laborRaw + cogsRaw).toFixed(2);
          r =
            '<tr class="subTip"><td class="tooltipR"><b>Labor Costs:</b></td><td>$' +
            Number(t).toFixed(2) +
            '</td><td class="tooltipSmall">' +
            laborPercent +
            '%</td</tr>';
          var q =
            '<tr class="subTip"><td class="tooltipR"><b>COGS:</b></td><td>$' +
            Number(h).toFixed(2) +
            '</td><td class="tooltipSmall">' +
            cogsPercent +
            '%</td</tr>';
          var p =
            '<tr class="subTip"><td class="tooltipR"><b>Prime Costs:</b></td><td>$' +
            Number(t + h).toFixed(2) +
            '</td><td class="tooltipSmall">' +
            primePercent +
            '%</td</tr>';

          s +=
            '<tr class="tooltipT"><td class="tooltipR"><b>Total:</b></td><td>$' +
            Number(total + 0.0001).toFixed(2) +
            '</td><td class="tooltipSmall">100%</td></tr>';

          s += r;
          s += q;
          s += p;

          s += '<tr><td colspan="3"></td></tr></table>';

          if (data > 0) {
            return s;
          } else {
            return false;
          }
        },
      },
      series: this._chartData.data,
      exporting: {
        enabled: false,
      },
    };
  }
}

@Component({
  selector: 'chart-sales-report',
  template:
    '<highcharts-chart [Highcharts]="Highcharts" [options]="chart" style="height: 100%; width: 100%; display: block;"></highcharts-chart>',
})
export class ChartSalesReportComponent {
  public chart: any;
  public Highcharts = Highcharts;
  public _chartData: IChartSales;
  public isDaily: boolean;
  public weekStart: number;

  @Input() set chartData(value: IChartSales) {
    this._chartData = value;
    if (this._chartData) this.buildChart();
  }
  constructor(protected accountService: AccountService) {
    this.isDaily = this.accountService.getLocation().is_service_time_daily;
    this.weekStart = this.accountService.getLocation().week_start;
  }

  buildChart() {
    let self = this;
    //console.log('Salse Report Data');
    //console.log(this._chartData);
    this.chart = {
      chart: {
        backgroundColor: null,
        type: 'column',
        credits: {
          enabled: false,
        },
        zoomType: 'none',
        marginTop: 50,
        animation: false,
      },
      legend: {
        labelFormatter: function () {
          var total = 0;
          for (var i = 0; i < this.yData.length; i++) {
            total += this.yData[i];
          }
          return this.name + ': $' + Math.round(total).toLocaleString('en');
        },
        enabled: this._chartData.titleBar,
        align: 'right',
        x: 0,
        y: 0,
        verticalAlign: 'top',
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      title: { text: null },
      subtitle: { text: null },
      xAxis: {
        type: 'datetime',
        tickInterval: this._chartData.tickInterval,
        startOfWeek: this.weekStart,
        labels: {
          formatter: function () {
            return moment(this.value).format(
              self._chartData.labelFormat || 'D MMM'
            );
          },
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        title: { text: null },
        gridLineColor: '#aaaaaa',
        gridLineWidth: 1,
        showEmpty: true,
        min: 0,
      },
      plotOptions: {
        series: {
          animation: false,
        },
        spline: {
          showInLegend: true,
          color: '#000000',
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
                radius: 3,
              },
            },
          },
          lineWidth: 1,
          states: {
            hover: {
              enabled: false,
              lineWidth: 2,
              lineWidthPlus: 0,
            },
          },
        },
        /*line:{
					marker: {
						enabled: false,
						states: {
							hover: {
								enabled:true,
								radius: 3
							}
						}
					},
					lineWidth: 2,
					states: {
							hover: {
								enabled: true,
								lineWidth: 2,
								lineWidthPlus: 0
							}
						}
					},*/
        areaspline: {
          fillOpacity: '.65',
          stacking: 'normal',
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true,
                radius: 3,
              },
            },
          },
          lineWidth: 1,
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
              lineWidthPlus: 0,
            },
          },
        },
        area: {
          stacking: 'normal',
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true,
                radius: 3,
              },
            },
          },
          lineWidth: 1,
          lineColor: '#999999',
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
              lineWidthPlus: 0,
            },
          },
        },
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        borderColor: '#000000',
        enabled: true,
        hideDelay: 0,
        shared: true,

        useHTML: true,
        formatter: function () {
          var t = 0;
          var h = 0;
          var data = 0;

          var s =
            '<table cellspacing="0"><tr><td colspan="3" class ="tooltipH">' +
            moment(this.x)
              .utc()
              .format(self._chartData.popupFormat || 'D MMM') +
            '</td></tr>';

          var total = 0;
          //console.log(this.points);
          if (this.points.length == 2) {
            // Doing year vs year
            var point1 = this.points[0];
            var point2 = this.points[1];
            var percent =
              point2 == 0 ? '' : ((100 * point1) / point2).toFixed(2);
            s +=
              '<tr><td class="tooltipR"><b>' +
              point1.series.name +
              ':</b></td><td>$' +
              Number(point1.y).toFixed(2) +
              '</td><td class="tooltipSmall">' +
              Number(point1.percentage).toFixed(2) +
              '%</td</tr>';
            s +=
              '<tr><td class="tooltipR"><b>' +
              point2.series.name +
              ':</b></td><td>$' +
              Number(point2.y).toFixed(2) +
              '</td><td class="tooltipSmall">' +
              Number(point2.percentage).toFixed(2) +
              '%</td</tr>';

            s +=
              '<tr class="tooltipT"><td class="tooltipR"><b>Diff:</b></td><td>$' +
              Number(point1 - point2 + 0.0001).toFixed(2) +
              '</td><td class="tooltipSmall">' +
              percent +
              '</td></tr>';
          } else {
            let doTotal = true;
            for (var i = 0; i < this.points.length; i++) {
              var point = this.points[i];
              if (doTotal) {
                total += point.y;
                point.percentage_text =
                  Number(point.percentage).toFixed(2) + '%';
              } else if (total > 0) {
                point.percentage_text =
                  Number(point.y / total).toFixed(2) + '%';
              } else {
                point.percentage_text = '';
              }
              s +=
                '<tr><td class="tooltipR"><b>' +
                point.series.name +
                ':</b></td><td>$' +
                Number(point.y).toFixed(2) +
                '</td><td class="tooltipSmall">' +
                point.percentage_text +
                '</td</tr>';

              if (point.y != 0) {
                data++;
              }
              if (
                point.series.name == 'Daily' ||
                point.series.name == 'Dinner'
              ) {
                doTotal = false;
                s +=
                  '<tr class="tooltipT"><td class="tooltipR"><b>Total:</b></td><td>$' +
                  Number(total + 0.0001).toFixed(2) +
                  '</td><td class="tooltipSmall">100%</td></tr>';
              }
            }
          }

          s += '<tr><td colspan="3"></td></tr></table>';

          if (data > 0) {
            return s;
          } else {
            return false;
          }
        },
      },
      series: this._chartData.data,
      exporting: {
        enabled: false,
      },
    };
  }
}

@Component({
  selector: 'chart-billing-report',
  template:
    '<highcharts-chart [Highcharts]="Highcharts" [options]="chart" style="height: 400px; width: 100%; display: block;"></highcharts-chart>',
})
export class ChartBillingReportComponent {
  public chart: any;
  public Highcharts = Highcharts;
  public _chartData: any;

  @Input() set chartData(value: IChartSales) {
    this._chartData = value;
    if (this._chartData) this.buildChart();
  }
  constructor(protected accountService: AccountService) {}

  buildChart() {
    let self = this;
    this.chart = {
      chart: {
        backgroundColor: null,
        type: 'column',
        credits: {
          enabled: false,
        },
        zoomType: 'none',
        marginTop: 50,
        animation: false,
      },
      legend: {
        labelFormatter: function () {
          var total = 0;
          for (var i = 0; i < this.yData.length; i++) {
            total += this.yData[i];
          }
          return this.name + ': $' + Math.round(total).toLocaleString('en');
        },
        enabled: false,
        align: 'right',
        x: 0,
        y: 0,
        verticalAlign: 'top',
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      title: { text: 'Billing' },
      subtitle: { text: null },
      xAxis: {
        type: 'datetime',
        tickInterval: 30 * 86400 * 1000,
        startOfWeek: 0,
        labels: {
          formatter: function () {
            return moment(this.value).format('MMM YY');
          },
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        title: { text: null },
        gridLineColor: '#aaaaaa',
        gridLineWidth: 1,
        showEmpty: true,
        min: 0,
      },
      plotOptions: {
        areaspline: {
          fillOpacity: '.65',
          stacking: 'normal',
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true,
                radius: 3,
              },
            },
          },
          lineWidth: 1,
          states: {
            hover: {
              enabled: true,
              lineWidth: 1,
              lineWidthPlus: 0,
            },
          },
        },
      },
      series: this._chartData,
    };
  }
}
