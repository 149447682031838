import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';
import { MessageRecipientService } from '../services/messageRecipient.service';

import { InboxComponent } from './inbox.component';
import { MessagesComponent } from './messages.component';
import { SentComponent } from './sent.component';

import { routing } from './messages.routing';
import { IconsModule } from 'source/app/icons/icons.module';

@NgModule({
  declarations: [InboxComponent, MessagesComponent, SentComponent],
  imports: [SharedModule, routing, IconsModule],
  providers: [MessageRecipientService],
})
export class MessagesModule {}
