import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IApiUser } from '../services/account.service';
import { SkuService, ISku } from '../services/sku.service';
import {
  SkuLocationService,
  ISkuLocation,
} from '../services/skuLocation.service';
import { ExpenseAccountService } from '../services/expenseAccount.service';
import { ContactService, IContact } from '../services/contact.service';
import { baseRole } from '../shared/baseRole';
import {
  InventoryUnits,
  SelectAddAll,
  StringSort,
  SkuCostMethod,
  SkuUnit,
  SkuUnitNames,
  ExpenseType,
  gramsToSkuUnit,
  skuUnitToGrams,
} from '../shared/api';
import {
  UsdaService,
  IUsdaListItem,
  IUsdaItem,
} from '../services/usda.service';
import {
  ItemCategoryService,
  IItemCategory,
} from '../services/itemCategory.service';
import {
  InventoryLocationService,
  IInventoryLocation,
} from '../services/inventoryLocation.service';

@Component({
  templateUrl: 'skuEdit.html',
})
export class SkuEditComponent extends baseRole implements OnInit {
  public data: ISku;
  public vendors: IContact[];
  public skuLocations: ISkuLocation[];
  public expenseAccounts: any[];
  public InventoryUnits = InventoryUnits;
  public subUnitToggle: boolean;
  public usdaSearch: string;
  public usdaList: IUsdaListItem[];
  //public usdaId: string;
  //public usdaItem: IUsdaItem;
  public SkuCostMethod = SkuCostMethod;
  public SkuUnitNames = SkuUnitNames;
  public SkuUnitKeys = Object.keys(SkuUnit).filter(Number);
  public itemCategory: IItemCategory[];
  public inventoryLocations: IInventoryLocation[];
  public expenseName: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected expenseAccountService: ExpenseAccountService,
    protected contactService: ContactService,
    protected skuService: SkuService,
    protected skuLocationService: SkuLocationService,
    protected usdaService: UsdaService,
    protected inventoryLocationService: InventoryLocationService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.expenseAccountService
      .list({ expense_type: ExpenseType.COGS })
      .subscribe(data => {
        StringSort(data, 'name');
        this.expenseAccounts = expenseExtras(
          data,
          'Please choose an Expense category'
        );
      });
    this.contactService.list({ hide: false }).subscribe(data => {
      StringSort(data, 'company');
      this.vendors = SelectAddAll(data, 'Please choose a Vendor', 'company');
    });

    this.route.params.subscribe(params => {
      let id = params['id'];
      //console.log('Editing: '+id);
      if (id) {
        this.skuService.get(id).subscribe(data => {
          this.data = data;
          this.data.master_weight = gramsToSkuUnit(
            this.data.master_weight_unit,
            this.data.master_weight
          );
          if (this.data.has_nutrition) {
            this.data.nutritional_portion_weight = gramsToSkuUnit(
              this.data.nutritional_portion_weight_unit,
              this.data.nutritional_portion_weight
            );
          }
        });
      } else {
        this.data = {
          expense_account_id: null,
          contact_id: null,
          buying_unit: InventoryUnits[7],
          sub_unit: InventoryUnits[7],
          par_type: 'std',
          subdivided: false,
          reorder_qty: 1,
          par_qty: 1,
          total_individual_units: 1,
          qty_inner_units: 1,
          cost_method: SkuCostMethod.Weight,
          nutritional_portion_weight_unit: SkuUnit.Grams,
          nutritional_portion_weight: 100,
          master_weight_unit: 1,
        } as ISku;
      }
      this.inventoryLocationService.list().subscribe(data => {
        this.inventoryLocations = data;
        this.inventoryLocations.sort((a, b) => {
          return a.order_by - b.order_by;
        });
        if (id) {
          this.skuLocationService.list({ sku_id: id }).subscribe(skuLocs => {
            this.skuLocations = skuLocs;
            for (let s of skuLocs) {
              let res = this.inventoryLocations.filter(e => {
                return e.id == s.inventory_location_id;
              });
              if (res.length != 1) continue; // No idea what happened here.
              res[0]._selected = true;
            }
          });
        } else {
          this.skuLocations = [];
        }
      });
    });
  }

  cancel() {
    this.router.navigate(['/inventory']);
  }

  nutrition() {
    // change suggested
    if (this.data.cost_method == SkuCostMethod.Quantity)
      this.data.nutritional_portion_weight = 1;
    this.data.nutritional_portion_weight_unit = 0;
    if (this.data.cost_method == SkuCostMethod.Weight)
      this.data.nutritional_portion_weight = 100;
    this.data.nutritional_portion_weight_unit = 1;
  }

  // So the problem is that % signs are valid in numeric input fields...
  fixNutPercent(val: number | string): number {
    if (
      !val ||
      val.toString().length == 0 ||
      !this.data.nutritional_portion_weight
    )
      return 0;
    if (val.toString().indexOf('%') >= 0) {
      return parseFloat(val.toString().replace('%', ''));
    } else {
      return parseFloat(val.toString());
    }
  }

  save() {
    if (this.data.has_nutrition) {
      this.data.nutritional_portion_weight = skuUnitToGrams(
        this.data.nutritional_portion_weight_unit,
        this.data.nutritional_portion_weight
      );
    }
    this.data.kcal = this.fixNutPercent(this.data.kcal);
    this.data.protein = this.fixNutPercent(this.data.protein);
    this.data.fat = this.fixNutPercent(this.data.fat);
    this.data.carbs = this.fixNutPercent(this.data.carbs);
    this.data.master_weight = skuUnitToGrams(
      this.data.master_weight_unit,
      this.data.master_weight
    );

    // Do it so the unadded expense category works.
    this.addExpenseAcct().then(() => {
      this.skuService.update(this.data).subscribe(data => {
        let pList = [];
        for (let i of this.inventoryLocations) {
          let sLoc = this.skuLocations.filter(e => {
            return i.id == e.inventory_location_id;
          });
          if (sLoc.length == 1 && i._selected) continue;
          else if (sLoc.length == 0 && !i._selected) continue;
          else if (sLoc.length == 1) {
            pList.push(this.skuLocationService.delete(sLoc[0].id));
          } else {
            pList.push(
              this.skuLocationService.update({
                sku_id: data.id,
                inventory_location_id: i.id,
                order_by: 0,
              })
            );
          }
        }
        if (pList.length > 0) {
          forkJoin(pList).subscribe(() => {
            this.router.navigate(['/inventory']);
          });
        } else {
          this.router.navigate(['/inventory']);
        }
      });
    });
  }

  onChange() {
    this.data.sub_unit = this.data.buying_unit;
  }

  addExpenseAcct() {
    if (!this.expenseName) return Promise.resolve();
    let obj: any = {
      name: this.expenseName,
      expense_type: ExpenseType.COGS,
    };
    return this.expenseAccountService
      .update(obj)
      .toPromise()
      .then(data => {
        this.expenseAccounts.push(data);
        this.expenseName = null;
        this.data.expense_account_id = data.id;
      });
  }

  doUsdaSearch() {
    //console.log('Search: '+this.usdaSearch);
    this.usdaService.search(this.usdaSearch).subscribe(data => {
      //console.log(data);
      this.usdaList = data;
    });
  }

  clearusda() {
    this.usdaList = null;
  }

  doUsdaItem(obj) {
    this.usdaService.get(obj.ndbno).subscribe(data => {
      this.usdaList = null;
      //console.log(data);
      this.data.kcal = data.kcal;
      this.data.fat = data.fat;
      this.data.protein = data.protein;
      this.data.carbs = data.carbs;
      //this.usdaItem = data;
    });
  }
}

function expenseExtras(data: any[], label: string = 'All', fieldName = 'name') {
  let obj: any = { id: '' };
  obj[fieldName] = label;
  data.unshift(obj);
  data.push({ id: -1, name: 'Add new COG expense category' });
  return data;
}
