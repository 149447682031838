import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  Observable,
  ReplaySubject,
  BehaviorSubject,
  of,
  throwError,
} from 'rxjs';
import { map, share, catchError } from 'rxjs/operators';
import {
  IApiLocation,
  IApiUser,
  ICCInfo,
  IUser,
  IResult,
  IEvent,
  DateMenuType,
  Permission,
  PackageType,
  UserType,
  UserStatus,
  getBaseUrl,
  moment,
} from '../shared/api';

declare var gtag: Function;
declare var __googleUA: string;
// Internal Storage
var __globalStorage: any = {};

class UserFilter {
  public updateQuery(queryParameters: HttpParams): HttpParams {
    if (globalUserInfo && globalUserInfo.locations.length > 0) {
      let loc = globalUserInfo.locations[globalUserInfo.location_index || 0];
      return queryParameters.set('location_id', loc.id.toString());
    }
    return queryParameters;
  }

  public updateObject(obj: any): any {
    if (globalUserInfo && globalUserInfo.locations.length > 0) {
      let loc = globalUserInfo.locations[globalUserInfo.location_index || 0];
      obj.location_id = loc.id;
    }
    return obj;
  }
}

declare var globalUserInfo: IApiUser;
declare var localStorage;
var globalUserFilter: UserFilter = new UserFilter();
var userUpdateSubject: ReplaySubject<boolean> = <ReplaySubject<boolean>>(
  new ReplaySubject(1)
);
var calendarUpdateSubject: ReplaySubject<boolean> = <ReplaySubject<boolean>>(
  new ReplaySubject(1)
);
var curDateUpdateSubject: ReplaySubject<Date>[] = [
  <ReplaySubject<Date>>new ReplaySubject(1),
  <ReplaySubject<Date>>new ReplaySubject(1),
];

@Injectable()
export class AccountService {
  protected baseUrl: string;
  protected user: IApiUser;
  protected defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  protected defaultOptions = { headers: this.defaultHeaders };

  constructor(private http: HttpClient, public router: Router) {
    this.http = http;
    this.baseUrl = getBaseUrl() + 'user/';
    this.user = globalUserInfo;
    userUpdateSubject.next(true);
    curDateUpdateSubject[0].next(null); // Get one in the queue
    curDateUpdateSubject[1].next(null); // Get one in the queue
    if (this.isLoggedIn()) {
      // 2 lines go here if it's a sub redirect.
    } else {
      //AccountService.logoutRedirect();
    }
  }

  notifyCalendarUpdate() {
    calendarUpdateSubject.next(true);
  }
  getCalendarUpdateObservable(): Observable<boolean> {
    return calendarUpdateSubject.asObservable();
  }

  // Date Menu Updates
  notifyCurrentDateUpdate(repeatType: DateMenuType, date: Date) {
    curDateUpdateSubject[repeatType].next(date);
  }
  getCurrentDateUpdateObservable(repeatType: DateMenuType): Observable<Date> {
    return curDateUpdateSubject[repeatType].asObservable();
  }

  getUserUpdateObservable(): Observable<boolean> {
    return userUpdateSubject.asObservable();
  }

  login(
    username: string,
    password: string,
    doRedirect: boolean = true
  ): Observable<IApiUser> {
    let options = {
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.http
      .post<IApiUser>(
        getBaseUrl() + 'user/login',
        { username: username, password: password },
        this.defaultOptions
      )
      .pipe(
        map(v => {
          if (v && v.id) {
            this.user = globalUserInfo = v;
            if (!this.user.location_index) this.user.location_index = 0;
            if (doRedirect) {
              userUpdateSubject.next(true);
            }
          }
          return v;
        })
      );
  }

  reloadPerms(): Observable<boolean> {
    return this.http.get<IApiUser>(getBaseUrl() + 'user/perms').pipe(
      map(v => {
        if (v && v.id) {
          this.user = globalUserInfo = v;
          if (!this.user.location_index) this.user.location_index = 0;
          userUpdateSubject.next(true);
          return true;
        }
        return false;
      })
    );
  }

  changePassword(password: string, oldPassword: string): Observable<number> {
    return this.http.post<number>(
      getBaseUrl() + 'user/changePassword',
      { oldPassword: oldPassword, password: password },
      this.defaultOptions
    );
  }

  forgotPassword(username: string): Observable<boolean> {
    return this.http.put<boolean>(
      this.baseUrl + 'forgotPassword',
      { username: username },
      this.defaultOptions
    );
  }

  resetPassword(
    username: string,
    token: string,
    newPassword: string
  ): Observable<boolean> {
    return this.http.put<boolean>(
      this.baseUrl + 'resetPassword',
      { username: username, token: token, password: newPassword },
      this.defaultOptions
    );
  }

  checkLogin(
    username: string,
    password: string
  ): Observable<{ id: number; name: string }[]> {
    return this.http.post<{ id: number; name: string }[]>(
      getBaseUrl() + 'user/loginCheck',
      { username: username, password: password },
      this.defaultOptions
    );
  }
  timeclockDevice(
    username: string,
    password: string,
    location_id: number
  ): Observable<string> {
    return this.http.post<string>(
      getBaseUrl() + 'user/authTimeclock',
      { username: username, password: password, location_id: location_id },
      this.defaultOptions
    );
  }
  deAuthDevice(): Observable<boolean> {
    return this.http.post<boolean>(
      getBaseUrl() + 'user/deauthTimeclock',
      {},
      this.defaultOptions
    );
  }

  register(data: IUser): Observable<IUser> {
    return this.http.post<IUser>(this.baseUrl, data, this.defaultOptions);
  }

  verify(user: any): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, user, this.defaultOptions);
  }

  get(): Observable<IUser> {
    let url = this.baseUrl + this.getUser().id;
    return this.http.get<IUser>(url);
  }

  update(data: IUser): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'profile',
      data,
      this.defaultOptions
    );
  }

  /*
	updateCreditCard(data: ICCInfo): Observable<IResult> {
    return this.http.post<IResult>(getBaseUrl()+'location/cc',data, this.defaultOptions)
			.pipe(
				catchError((error: Response)=> {
					if(error.status==422) {
						return throwError((<any>error).error);
					}
					else {
						 return throwError({ __status: 0, __message: 'Server error: '+error.status } as IResult)
					}
				})
			);
	}*/

  getUserFilter() {
    return globalUserFilter;
  }

  usernameExists(username: string): Observable<string> {
    return this.http.post<string>(
      this.baseUrl + 'usernameExists',
      { username: username },
      this.defaultOptions
    );
  }

  logout() {
    this.http.get(getBaseUrl() + 'user/logout').subscribe(data => {
      globalUserInfo = null;
      AccountService.logoutRedirect();
    });
  }

  public static logoutRedirect() {
    window.location.href = '/';
  }

  getUser(): IApiUser {
    return globalUserInfo;
  }
  getUserId(): number {
    return globalUserInfo ? globalUserInfo.id : -1;
  }
  getLocation(): IApiLocation {
    if (!globalUserInfo) return null;
    if (globalUserInfo.locations.length == 0) return null;
    return globalUserInfo.locations[globalUserInfo.location_index || 0];
  }
  getLocationId(): number {
    if (!this.getLocation()) return -1;
    return this.getLocation().id;
  }

  getLocations(): IApiLocation[] {
    return globalUserInfo ? globalUserInfo.locations : [];
  }

  isFreemium(): boolean {
    return (
      !this.getLocation() ||
      this.getLocation().package_type == PackageType.Freemium
    );
  }

  isAllowed(perm: Permission): boolean {
    let loc = this.getLocation();
    if (!loc) return false;
    return loc.permissions[perm];
  }

  // This also causes a recalc of the jail params.
  setLocation(
    locationId?: number,
    doRedirect = false
  ): Observable<IApiLocation> {
    if (this.getLocations().length == 0) return of(null);
    if (locationId) {
      let match = this.getLocations().filter(e => {
        return e.id == locationId;
      });
      if (match.length != 1) throw Error('Unable to find location');
      globalUserInfo.location_index = this.getLocations().indexOf(match[0]);
    } else {
      globalUserInfo.location_index = 0;
    }

    return this.http
      .post<IApiLocation>(
        getBaseUrl() + 'user/setLocation',
        {
          location_id:
            globalUserInfo.locations[globalUserInfo.location_index].id,
        },
        this.defaultOptions
      )
      .pipe(
        map(data => {
          //		console.log('Changed api location');
          if (globalUserInfo.locations.length > 0) {
            globalUserInfo.locations[globalUserInfo.location_index] = data;
          }
          userUpdateSubject.next(true);
          if (!doRedirect) {
            curDateUpdateSubject[DateMenuType.Weekly].next(null);
          } else {
            // Fire this after the navigate has gone off
            setTimeout(() => {
              curDateUpdateSubject[DateMenuType.Weekly].next(null);
            }, 100);
            this.router.navigate(['/']);
          }
          return data;
        })
      );
  }

  hasBillingFailed(): boolean {
    return this.getLocation() && !this.getLocation().payment_status;
  }

  inJail(): boolean {
    let user = this.getUser();
    let loc = this.getLocation();

    if (!user) return true; // Not a user? Probably jailed.
    if (user.is_super) return false;
    return this.checkJail();
  }

  checkJail(): boolean {
    let user = this.getUser();
    let loc = this.getLocation();
    if (!loc) return true; // No Location? Jailed..
    if (this.hasBillingFailed()) return true; // No Payment? Jailed.
    if (loc.user_type == UserType.Owner && loc.jail_no_package) {
      return true;
    }

    return (
      user.jail_bad_password ||
      loc.jail_writeup ||
      loc.jail_doc ||
      loc.jail_profile ||
      loc.jail_upload
    );
  }

  getEffectiveUserType(): UserType {
    if (this.getUser())
      return this.getUser().locations[this.getUser().location_index].user_type;
    return UserType.Anonymous;
  }

  isLoggedIn(): boolean {
    return typeof globalUserInfo != 'undefined' && globalUserInfo != null;
  }

  isAdmin(): boolean {
    //	console.log(this.getUser());
    return this.getUser() && this.getUser().is_super;
  }

  isOwner(): boolean {
    if (!this.getLocation()) return false;
    return this.getLocation().user_type == UserType.Owner;
  }

  inRole(role: UserType[] | UserType): boolean {
    const userType = this.getEffectiveUserType();
    if (this.getUser().is_super || role == UserType.Anonymous) return true;
    if (Array.isArray(role)) {
      for (var i = 0; i < role.length; i++) {
        if (role[i] == userType) return true;
      }
      return false;
    } else if (role == userType) return true;
    return false;
  }

  setTempState(key: string, data: any) {
    __globalStorage[key] = data;
  }

  getTempState(key, def: any = null): any {
    if (!(key in __globalStorage)) {
      return def;
    }
    return __globalStorage[key];
  }

  updateTempState(key: string, field: string, data: any) {
    let filter = this.getTempState(key, {});
    filter[field] = data;
    this.setTempState(key, filter);
  }

  getCookie(name: string) {
    //		console.log(document.cookie);
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
  /*
	setCookie(name: string, value: string, expireDays: number, path: string = '') {
		let d:Date = new Date();
		d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
		let expires:string = `expires=${d.toUTCString()}`;
		let cpath:string = path ? `; path=${path}` : '';
		document.cookie = `${name}=${value}; ${expires}${cpath}`;
	}

	deleteCookie(name) {
		this.setCookie(name, '', -1);
	}
	*/
  getLastPeriod(): { start: Date; end: Date } {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    let dayOfWeek = this.getLocation().week_start;
    let offset = date.getDay();
    if (dayOfWeek - offset != 0) {
      date.setDate(date.getDate() - (offset - dayOfWeek));
    }
    let end = new Date(date);
    end.setDate(end.getDate() - 28);
    return { start: date, end: end };
  }

  tagNewOwner(email: string) {
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set(
      'offerId',
      '5c6dac786637613c3a000132'
    );
    queryParameters = queryParameters.set('email', email);
    this.http
      .get('https://restaurantownersguide.com/conversion/', {
        params: queryParameters,
      })
      .subscribe(data => {});

    this.http
      .get(this.baseUrl + 'tagNewOwner', { params: queryParameters })
      .subscribe(() => {});
  }
}

export {
  IUser,
  UserType,
  IApiLocation,
  IApiUser,
  SelectAddAll,
} from '../shared/api';
