import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService, IUser } from '../services/account.service';
import {
  LogService,
  ILog,
  ILogReport,
  DateMenuType,
  ServiceTimeType,
} from '../services/log.service';
import {
  LogNoteService,
  ILogNote,
  LogNoteType,
  LogNoteNames,
} from '../services/logNote.service';
import { LogCommentService } from '../services/logComment.service';
import { formatDateOnly } from '../services/base.service';

@Component({
  templateUrl: './summary.html',
})
export class SummaryComponent extends baseRole implements OnInit, OnDestroy {
  public LogNoteNames = LogNoteNames;
  public LogNoteType = LogNoteType;
  public LogNoteTypeKeys = Object.keys(LogNoteType).filter(Number);
  public ServiceTimeType = ServiceTimeType;
  public deleteCommentObj: any;
  public sub: any;
  public date: Date;
  public sales: ILogReport;
  public lastYearSales: ILogReport;
  public salesPercent: number;
  public noteCount = 0;
  public logNotes: any[];
  public comments: any[];
  public payrollCost;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected logService: LogService,
    protected logNoteService: LogNoteService,
    protected logCommentService: LogCommentService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.payrollCost = 1.0; // (1.0+this.getLocation().payroll_goal/100);

    this.sub = this.accountService
      .getCurrentDateUpdateObservable(DateMenuType.Daily)
      .subscribe(date => {
        //			console.log('Update thingy');
        //			console.log(date);
        if (!date) return;
        this.date = date;
        let lastYear = new Date(date);
        lastYear.setFullYear(lastYear.getFullYear() - 1);

        if (this.isAllowed(this.Permission.ViewSales)) {
          this.logService
            .getReport({ start: this.date, end: this.date })
            .subscribe(data => {
              this.sales = data[0];

              this.logService
                .getReport({ start: lastYear, end: lastYear })
                .subscribe(data => {
                  this.lastYearSales = data[0];
                  if (this.lastYearSales.service_time[0].sales != 0) {
                    this.salesPercent =
                      (100.0 *
                        (this.sales.service_time[0].sales -
                          this.lastYearSales.service_time[0].sales)) /
                      this.lastYearSales.service_time[0].sales;
                  }
                });
            });
        }
        if (this.isAllowed(this.Permission.ViewSales)) {
          this.logCommentService
            .list({ date: formatDateOnly(this.date) })
            .subscribe(data => {
              this.comments = data;
            });
        }

        if (this.isAllowed(this.Permission.ViewSales)) {
          this.logNoteService
            .list({ date: formatDateOnly(this.date) })
            .subscribe(data => {
              /*				console.log('Log Notes');
					console.log(data);
					console.log(this.LogNoteTypeKeys); */
              this.noteCount = data.length;
              this.logNotes = [];
              for (let t of this.LogNoteTypeKeys) {
                this.logNotes[t] = data.filter(e => {
                  return e.log_note_type == +t;
                });
              }
              //			console.log(this.logNotes);
            });
        }
      });
  }

  addLogComment(note) {
    let data = {
      location_id: this.getLocationId(),
      user_id: this.getUserId(),
      response: note,
      date: new Date(this.date),
    };
    this.logCommentService.dateToLocal(data, 'date'); // Flatted the date.
    this.logCommentService.update(data).subscribe(data => {
      this.logCommentService
        .list({ date: formatDateOnly(this.date) })
        .subscribe(data => {
          this.comments = data;
        });
    });
  }
  deleteLogComment() {
    if (!this.deleteCommentObj) return;
    this.logCommentService.delete(this.deleteCommentObj.id).subscribe(data => {
      let idx = this.comments.indexOf(this.deleteCommentObj);
      this.comments.splice(idx, 1);
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
  delete() {}
}
