import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IEmployeePosition } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class EmployeePositionService extends BaseService<IEmployeePosition> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'employeePosition');
  }

  preProcess(data: any): any {
    //console.log('Preprocess');
    if (Array.isArray(data)) {
      for (let d of data) {
        this.dateToLocal(d, 'start');
        this.dateToLocal(d, 'end');
      }
    } else {
      this.dateToLocal(data, 'start');
      this.dateToLocal(data, 'end');
    }
    return data;
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'start');
          this.fixDate(d, 'end');
        }
      } else {
        this.fixDate(data, 'start');
        this.fixDate(data, 'end');
      }
      return data;
    };
  }

  list(filter: any): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      if ('start' in filter) {
        this.formatDateOnly(filter, 'start');
      }
      if ('end' in filter) {
        this.formatDateOnly(filter, 'end');
      }
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl, { params: queryParameters })
      .pipe(map(this.postProcess()));
  }
}

export { IEmployeePosition, RateType } from '../shared/api';
