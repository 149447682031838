import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { EventService, IEvent, EventType } from '../services/event.service';
import {
  EventAssignmentService,
  IEventAssignment,
} from '../services/eventAssignment.service';
import { UserService, IUser } from '../services/user.service';
import { baseRole } from '../shared/baseRole';
import {
  RepeatOptions,
  RepeatOptionsKeys,
  DaysBefore,
  DaysBeforeKeys,
  dateDoOffset,
} from '../shared/api';

@Component({
  selector: 'modal-task',
  templateUrl: 'modalTask.html',
})
export class ModalTaskComponent extends baseRole implements OnInit {
  @Input() repair = false;

  @ViewChild('modal')
  public modal: ModalDirective;
  public data: IEvent;
  public users: IUser[];
  public RepeatOptions = RepeatOptions;
  public RepeatOptionsKeys = RepeatOptionsKeys;
  public DaysBefore = DaysBefore;
  public DaysBeforeKeys = DaysBeforeKeys;
  public userMap: any = {};

  constructor(
    public accountService: AccountService,
    public userService: UserService,
    public eventService: EventService,
    public eventAssignmentService: EventAssignmentService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    this.userService.list().subscribe(data => {
      this.users = data;
      for (let d of data) {
        this.userMap[d.id] = d;
      }
      if (this.data.id) {
        this.eventAssignmentService
          .list({ event_id: this.data.id })
          .subscribe(data => {
            for (let d of data) {
              if (d.user_id in this.userMap) {
                this.userMap[d.user_id]._selected = true;
              }
            }
            this.modal.show();
          });
      } else {
        this.modal.show();
      }
    });
  }

  show(obj = null) {
    if (obj) {
      this.data = obj;
      if (!this.data.text_time)
        this.data.text_time = new Date(this.data.start_date);
      if (!this.data.email_time)
        this.data.email_time = new Date(this.data.start_date);
    } else {
      let now = new Date();
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      this.data = {
        event_type: EventType.Task,
        location_id: this.getLocationId(),
        start_date: new Date(now),
        end_date: new Date(now),
        text_time: new Date(now),
        email_time: new Date(now),
        repeat_option: RepeatOptions.None,
        is_repair: this.repair,
        text_alert_days: 0,
        email_alert_days: 0,
        color: '#769de5',
      } as IEvent;
    }
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    this.data.end_date = this.data.start_date;
    if (this.data.text_alert) {
      this.data.text_time = dateDoOffset(
        this.data.text_time,
        this.data.start_date,
        this.data.text_alert_days
      );
    } else {
      this.data.text_time = null;
    }
    if (this.data.email_alert) {
      this.data.email_time = dateDoOffset(
        this.data.email_time,
        this.data.start_date,
        this.data.email_alert_days
      );
    }
    (<any>this.data).users = this.users
      .filter(e => {
        return e._selected;
      })
      .map(e => {
        return e.id;
      });
    this.eventService.update(this.data).subscribe(data => {
      this.accountService.notifyCalendarUpdate();
      this.modal.hide();
    });
  }
}
