import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { ILocationUserMap } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class LocationUserMapService extends BaseService<ILocationUserMap> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'locationUserMap');
  }

  // This will return true if the pin already exists.
  checkPinExists(data: {
    pin: string;
    location_id: number;
    user_id: number;
  }): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + 'checkPin',
      data,
      this.defaultOptions
    );
  }

  update(data: any): Observable<ILocationUserMap> {
    return super.update(data).pipe(
      map(results => {
        if (results.user_id != this.accountService.getUserId()) return results;
        let loc = this.accountService.getLocation();

        for (let key in results) {
          if (!(key in loc) || key == 'id') continue;
          loc[key] = data[key];
        }
        return results;
      })
    );
  }
}

export { ILocationUserMap } from '../shared/api';
