import { Component, ViewChild, OnInit, Input, Output } from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IApiUser } from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { PositionService, IPosition } from '../services/position.service';
import { LocationService, ILocation } from '../services/location.service';
import {
  EmployeePositionService,
  IEmployeePosition,
} from '../services/employeePosition.service';
import { baseRole } from '../shared/baseRole';
import {
  Countries,
  Provinces,
  RateType,
  States,
  UserType,
  SelectAddAll,
} from '../shared/api';

@Component({
  templateUrl: 'userAdd.html',
})
export class UserAddComponent extends baseRole implements OnInit {
  public data: any;
  public emailError: string;
  public loc: ILocation;
  public allLocations: any[];
  public allPositions: IPosition[];
  public myPositions: any[];
  public availablePositions: IPosition[];
  public newPosition: any;
  public RateType = RateType;
  public RateTypeKeys = Object.keys(RateType).filter(Number);
  public States = States;
  public Provinces = Provinces;
  public Countries = Countries;
  public StateKeys = Object.keys(States);
  public ProvinceKeys = Object.keys(Provinces);
  public CountryKeys = Object.keys(Countries);
  public newPositionError: string;
  public isSalary: boolean = false;
  public addType: number;

  public complex = false;

  constructor(
    protected router: Router,
    public accountService: AccountService,
    public userService: UserService,
    public positionService: PositionService,
    public locationService: LocationService
  ) {
    super(accountService);
  }

  initPosition() {
    this.newPosition = {
      rate_type: RateType.Hourly,
      rate: null,
      is_primary: false,
      active: true,
      position_id: null,
      name: '',
      start: new Date(),
    };
  }

  initData() {
    this.myPositions = [];
    this.data = {
      color: '#769de5',
      state: 'AL',
      pin: null, //this.padLeft((Math.floor(Math.random()*9999)+1).toString(),'0',4),
      locations: [],
      positions: [],
      user_type: this.UserType.Regular,
      date_of_hire: new Date(),
      freq_of_review: 4,
      max_hours: 40,
      ot_hours: 8,
    };
    this.initPosition();
    this.filterPositions(true);
    this.userService.getNewPin().subscribe(data => {
      this.data.pin = data;
    });
  }
  ngOnInit() {
    this.initData();

    this.positionService.list().subscribe(data => {
      this.allPositions = data;
      this.filterPositions(true);
    });
    this.locationService
      .get(this.accountService.getLocationId())
      .subscribe(data => {
        this.loc = data;
      });
    this.locationService.list().subscribe(data => {
      this.data.locations = [];
      for (let d of data) {
        this.data.locations.push({ id: d.id, name: d.name, selected: false });
      }
      this.data.locations[0].selected = true;
    });
  }

  checkEmail() {
    this.emailError = null;
    this.userService
      .getUsernameInLocation({
        username: this.data.email,
        location_id: this.accountService.getLocationId(),
      })
      .subscribe(match => {
        if (match) {
          this.emailError = 'This email already exists in your location.';
        }
      });
  }

  // Force only one position to be valid.
  checkPrimary(obj) {
    if (!obj.is_primary) return;
    for (let p of this.myPositions) {
      if (p == obj) continue;
      if (p.is_primary) p.is_primary = false; //switch to new primary on edit
    }
  }

  filterPositions(doInit: boolean) {
    if (!this.allPositions) return;

    let myPosIds: any = {};
    this.isSalary = false;
    for (let m of this.myPositions) {
      if (m.rate_type == RateType.Salary) {
        this.isSalary = true;
      }
      if (m.active) {
        myPosIds[m.id] = true;
      }
    }
    this.availablePositions = this.allPositions.filter(e => {
      return !(e.id in myPosIds);
    });
    if (this.availablePositions.length > 0) {
      SelectAddAll(this.availablePositions, 'Select a Position');
    }
    if (doInit) {
      this.initPosition();
    }
    this.newPosition.is_primary = this.myPositions.length == 0;
  }

  deletePosition(obj) {
    let i = 0;
    for (let m of this.myPositions) {
      if (m.id == obj.id) break;
      i++;
    }
    this.myPositions.splice(i, 1);
    this.filterPositions(false);
  }
  editPosition(obj) {
    obj._edit = Object.assign({}, obj);
  }
  cancelPosition(obj) {
    obj = Object.assign(obj, obj._edit);
    delete obj._edit;
  }

  savePosition(obj) {
    this.checkPrimary(obj);
    delete obj._edit; // this holds the orig object for cancel
    this.filterPositions(false);
  }
  addPosition() {
    this.newPositionError = null;
    if (!this.newPosition.position_id) {
      this.newPositionError = 'Position is a required field';
      return;
    }

    if (!this.newPosition.rate) {
      this.newPositionError = 'Rate is a required field';
      return;
    }
    if (!this.newPosition.rate_type) {
      this.newPositionError = 'Rate Type is a required field';
      return;
    }

    //all we have is the id, need the name of the position
    for (let p of this.availablePositions) {
      if (this.newPosition.position_id == p.id) {
        this.newPosition.name = p.name;
        break;
      }
    }

    this.checkPrimary(this.newPosition);
    //	this.newPosition = {} as IEmployeePosition;
    this.myPositions.push(this.newPosition);
    this.filterPositions(true);
  }

  save() {
    if (!this.complex) {
      this.data.state = null;
    }
    if (this.data.email && this.data.email.length == 0) this.data.email = null;
    this.data.username = this.data.email;
    this.userService.formatDateOnly(this.data, 'date_of_hire');
    if (this.newPosition.position_id) this.addPosition();
    this.data.location_id = this.getLocationId();
    this.data.positions = this.myPositions;
    this.userService.addUser(this.data).subscribe(data => {
      if (!this.addType) {
        this.router.navigate(['/users']);
      } else {
        this.initData();
      }
    });
  }

  /*padLeft(text:string, padChar:string, size:number): string {
			return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
	}*/
}
