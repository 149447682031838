<div *ngIf="user">
  <form ngNativeValidate (submit)="save()">
    <div class="row my-1">
      <div class="col-md-4 col-xs-12">
        <div class="card my-1">
          <div class="card-body">
            <img
              class="rounded mb-3"
              [src]="getUserProfileImage()"
              height="90px" />
            <label>First Name</label>
            <input
              [(ngModel)]="user.firstname"
              type="text"
              maxlength="50"
              class="form-control"
              name="firstname"
              placeholder="First Name"
              required />
            <label>Last Name </label>
            <input
              [(ngModel)]="user.lastname"
              type="text"
              maxlength="50"
              class="form-control"
              name="lastname"
              placeholder="Last Name"
              required />
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xs-12">
        <div class="card my-1">
          <div class="card-body">
            <label> Email </label>
            <input
              [(ngModel)]="user.email"
              type="email"
              class="form-control"
              placeholder="Email"
              name="email" />
            <p *ngIf='user.email==""' style="font-size: 12px" class="mb-1">
              Employees without an email have limited access to CheddrSuite.
              These employees can log in and use timecards using their PIN, but
              only on activated devices.
            </p>
            <label>Cell</label>
            <input
              [(ngModel)]="user.cell"
              type="text"
              maxlength="15"
              class="form-control"
              required
              name="cell"
              phone />
            <label>PIN </label>
            <input
              [(ngModel)]="userMap.pin"
              type="text"
              maxlength="12"
              class="form-control"
              required
              name="pin"
              debounce
              (result)="checkPin($event)" />
            <local-error [error]="error.pin"></local-error>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xs-12">
        <div class="card my-1">
          <div class="card-body">
            <label> Address </label>
            <input
              [(ngModel)]="user.address"
              type="text"
              class="form-control"
              placeholder="Address"
              name="address" />
            <label>City</label>
            <input
              [(ngModel)]="user.city"
              type="text"
              class="form-control"
              placeholder="City"
              name="city" />
            <label>Country</label>
            <select
              [(ngModel)]="user.country"
              class="form-control"
              required
              name="country">
              <option *ngFor="let c of CountryKeys" [value]="c">
                {{Countries[c]}}
              </option>
            </select>
            <label *ngIf="user.country=='US' || user.country==''">State</label>
            <select
              *ngIf="user.country=='US' || user.country==''"
              [(ngModel)]="user.state"
              class="form-control"
              required
              name="state">
              <option *ngFor="let s of StateKeys" [value]="s">
                {{States[s]}}
              </option>
            </select>
            <label *ngIf="user.country=='CA'">Province</label>
            <select
              *ngIf="user.country=='CA'"
              [(ngModel)]="user.state"
              class="form-control"
              required
              name="state">
              <option *ngFor="let p of ProvinceKeys" [value]="p">
                {{Provinces[p]}}
              </option>
            </select>
            <label *ngIf="user.country=='US' || user.country==''">ZIP </label>
            <input
              *ngIf="user.country=='US' || user.country==''"
              [(ngModel)]="user.zip"
              type="text"
              pattern="[0-9]{5}"
              maxlength="5"
              class="form-control"
              placeholder="ZIP"
              required
              name="zip" />
            <label *ngIf="user.country=='CA'">Postal Code </label>
            <input
              *ngIf="user.country=='CA'"
              [(ngModel)]="user.zip"
              type="text"
              maxlength="7"
              class="form-control"
              placeholder="Postal Code"
              required
              name="zip" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-xs-12">
        <div class="card my-1">
          <div class="card-body">
            <label>Date of Hire </label>
            <input
              type="text"
              class="form-control input-calendar"
              placeholder="yyyy-mm-dd"
              bsDatepicker
              [(ngModel)]="userMap.date_of_hire"
              name="hire_start_date" />
            <label>Frequency of Review</label>
            <select
              name="freq_of_review"
              [(ngModel)]="userMap.freq_of_review"
              class="form-control">
              <option value="0">None</option>
              <option value="1">Monthly</option>
              <option value="2">3 Months</option>
              <option value="3">6 Months</option>
              <option value="4">Yearly</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xs-12">
        <div class="card my-1">
          <div class="card-body">
            <label>
              Schedule Color
              <input-color
                [(ngModel)]="userMap.color"
                class="float-right"
                name="color"></input-color>
            </label>
            <label>Max Week Hours for Schedule Warning</label>
            <input
              [(ngModel)]="userMap.max_hours"
              type="number"
              min="1"
              class="form-control"
              name="max_hours" />
            <label> Max Day Hours before Overtime pay</label>
            <input
              [(ngModel)]="userMap.ot_hours"
              type="number"
              min="1"
              class="form-control"
              name="ot_hours" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card mt-1">
          <div class="card-body">
            <label> Notes</label>
            <textarea
              [(ngModel)]="userMap.notes"
              class="form-control"
              maxlength="5000"
              placeholder="Employee Notes"
              name="notes"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-12">
        <global-error [error]="error.global"></global-error>
        <button
          class="btn btn-secondary float-right"
          type="button"
          routerLink="/users">
          Cancel
        </button>
        <save-button class="float-right"></save-button>
      </div>
    </div>
  </form>
</div>
