<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Filter Shifts
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" *ngIf="loc && positions">
        <label
          >Multiple Select
          <button
            class="btn btn-sm btn-radio active float-right"
            style="width: auto"
            (click)="noFilter()">
            No Filter
          </button></label
        >
        <div class="row">
          <div class="col">
            <div *ngIf="getLocation().has_houses">
              <div class="card mt-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col py-0">
                      <strong>Group Filter</strong>
                      <button
                        class="btn btn-sm float-right orange-thin-bg"
                        (click)="clearHouses()">
                        <small>Clear FOH &amp; BOH </small>
                      </button>
                    </div>
                  </div>
                  <div class="row no-gutter">
                    <div class="col-md-6 my-2">
                      <div class="btn-group-toggle">
                        <label class="btn btn-radio" [class.active]="fohButton">
                          <input
                            type="radio"
                            name="options"
                            [value]="true"
                            [(ngModel)]="fohButton"
                            (ngModelChange)="houseUpdate()" />FOH
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6 my-2">
                      <div class="btn-group-toggle">
                        <label
                          class="btn btn-radio"
                          [class.active]="fohButton===false">
                          <input
                            type="radio"
                            name="options"
                            [value]="false"
                            [(ngModel)]="fohButton"
                            (ngModelChange)="houseUpdate()" />BOH
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-0">
              <div class="card-body">
                <div class="py-1"><strong> Permission Type Filter</strong></div>
                <div class="row">
                  <div class="col-md-4 my-2">
                    <input-toggle [(ngModel)]="permission_level[1]"
                      >{{loc.level_1}}</input-toggle
                    >
                  </div>
                  <div class="col-md-4 my-2">
                    <input-toggle [(ngModel)]="permission_level[2]"
                      >{{loc.level_2}}</input-toggle
                    >
                  </div>
                  <div class="col-md-4 my-2">
                    <input-toggle [(ngModel)]="permission_level[3]"
                      >{{loc.level_3}}</input-toggle
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card mt-0">
              <div class="card-body">
                <label>Position Filter</label>
                <div class="col-12">
                  <div class="row d-flex">
                    <div
                      class="col-xl-4 col-lg-6 col-12"
                      *ngFor='let p of positions | orderBy:"name"'>
                      <div
                        *ngIf="fohButton===null ||  (p.position_type==1 && fohButton) || (p.position_type==2 && fohButton===false)">
                        <input-toggle name="'p.id" [(ngModel)]="p._selected"
                          >{{p.name}}
                        </input-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <ok-button class="float-right" (click)="save()"></ok-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
