import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';

@Component({
  templateUrl: './testIcon.html',
})
export class TestIconComponent implements OnInit {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {}

  public sizes = ['l', 'm', 's'];
  public iconList = [
    [
      'logo',
      'add-task',
      'add-event',
      'send-message',
      'log-invoice',
      'work-pref',
      'time-off',
      'dash',
      'log',
      'calendar',
      'schedule',
      'inventory',
      'messages',
      'staff',
      'docs',
      'vendors',
      'reports',
      'settings',
      'logout',
    ],
    [
      'calendar-sm',
      'trash',
      'no-trash',
      'edit',
      'broadcast',
      'view',
      'save',
      'cancel',
      'view-file',
      'add',
      'subtract',
      'refresh',
      'credit-card',
      'resize',
      'file-pdf',
      'file',
      'pause',
      'play',
      'sliders',
      'arrow-up',
      'arrow-down',
    ],
    [
      'wrench',
      'reorder',
      'cross',
      'burger',
      'check',
      'checkbox',
      'search',
      'help',
      'shift-add',
      'shift-paste',
      'shift-copy',
      'filter',
      'template-save',
      'template-load',
      'shift-clear',
      'trade-board',
      'shift-time-off',
      'print',
    ],
    [
      'lock',
      'add-vendor',
      'vendor-group',
      'hidden',
      'doc-add',
      'doc-modify',
      'doc-trash',
      'doc-upload',
      'soft-arrow',
      'staff-add',
      'staff-email',
      'pick-up',
      'warning',
      'menu-calendar',
      'reorder-up',
      'reorder-down',
    ],
  ];

  ngOnInit() {}
}
