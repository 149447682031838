<script
  src="https://kit.fontawesome.com/7dd83f309c.js"
  crossorigin="anonymous"></script>
<div class="row authentication" *ngIf="!user">
  <div class="col-md-4 col-10 text-center card">
    <div class="card-body">
      <div style="width: 100%" class="my-4">
        <span class="cdi-l-logo cdi-biggest mx-auto"></span>
      </div>
      <span *ngIf="timeclock" class="font-weight-bold"
        >This device is not set up as a timeclock. Please log in as manager to
        authorize this device as a clock in terminal.</span
      >
      <div *ngIf="maintenance">
        <h2>Maintenace in Progress</h2>
        <p>
          Bear with us for a few minutes...we're growing by leaps and bounds,
          and we're upgrading our technology to stay on the cutting edge.
        </p>
        <p>
          Please give us a moment to wrap up, then refresh this page to resume
          using CheddrSuite!
        </p>
      </div>
      <form ngNativeValidate>
        <input
          type="text"
          class="form-control"
          name="username"
          placeholder="Username"
          [(ngModel)]="username"
          required />
        <input
          class="form-control"
          type="password"
          name="password"
          placeholder="Password"
          [(ngModel)]="password"
          required />
        <button class="btn btn-primary w-100" type="submit" (click)="login()">
          <span *ngIf="!timeclock">Sign In</span>
          <span *ngIf="timeclock">Authorize</span>
        </button>
        <local-error [error]="error.global" class="mt-1"></local-error>
      </form>
      <br />
      <div *ngIf="!maintenance">
        <p class="text-center">
          <small>
            <input type="checkbox" name="stay" [(ngModel)]="stay" /> Stay Logged
            In </small
          ><br />
          <a routerLink="/forgot">Forgot Password?</a>
          <span> / </span>
          <a *ngIf="!timeclock" [routerLink]="['/login', { timeclock: true} ]"
            >Timeclock</a
          >
          <a *ngIf="timeclock" routerLink="/login">Main Cheddr Login</a>
        </p>
        <div *ngIf="!timeclock">
          <p>Don't have an account? <a routerLink="/signup">Sign up</a></p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row justify-content-center" *ngIf="user">
  <div class="col-md-4 col-10 text-center card">
    <div class="card-body">
      <div style="width: 100%" class="my-4">
        <span class="cdi-l-logo cdi-biggest mx-auto"></span>
      </div>
      <form ngNativeValidate (submit)="setLocation()">
        Please pick a location to log into:
        <select
          class="form-control"
          required
          name="location_id"
          [(ngModel)]="user.location_index">
          <option *ngFor="let l of user.locations" [value]="l.id">
            {{l.name}}
          </option>
        </select>
        <button class="btn btn-primary float-right">Continue</button>
      </form>
    </div>
  </div>
</div>

<!-- Timeclock warning dialog -->
<div
  bsModal
  #timeclockWarningModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Timeclock Warning"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content" *ngIf="locs">
      <div class="modal-header">
        <div class="modal-title">AUTHORIZE TIME CLOCK</div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="cancelTimeclock()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        You are about to authorize this browser as a timeclock for the location
        below:
        <br />
        <select [(ngModel)]="locId" class="form-control" name="loc_id">
          <option *ngFor="let l of locs" [value]="l.id">{{l.name}}</option>
        </select>
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <button class="btn btn-primary" (click)="authorizeTimeclock()">
              Authorize
            </button>
            <cancel-button
              class="float-right"
              (click)="cancelTimeclock()"></cancel-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
