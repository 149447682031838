<modal-invoice #modalInvoice></modal-invoice>
<div class="card">
  <div class="card-body" *ngIf="invoice && contact">
    <div class="card-header text-center">Invoice # {{invoice.ponumber}}</div>
    <dl class="row">
      <dt class="col-sm-3 col-md-2">Company</dt>
      <dd class="col-sm-9 col-md-4">{{contact.company}}</dd>
      <dt class="col-sm-3 col-md-2">Contact</dt>
      <dd class="col-sm-9 col-md-4">{{contact.name}}</dd>
      <dt class="col-sm-3 col-md-2">Date</dt>
      <dd class="col-sm-9 col-md-4">{{invoice.date | date:'EEEE MMM d, y'}}</dd>
      <dt class="col-sm-3 col-md-2">Terms</dt>
      <dd class="col-sm-9 col-md-4">Net {{invoice.terms}}</dd>
    </dl>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th>Line Item Name</th>
          <th>Expense Category</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody *ngIf="data">
        <tr *ngFor="let i of data">
          <td>{{ i.name}}</td>
          <td>{{ i.expense_account_name}}</td>
          <td>{{ i.amount | currency: 'USD' }}</td>
        </tr>
      </tbody>
    </table>
    <label>Total {{total | currency: 'USD' }}</label>

    <div class="row mt-3">
      <div class="col-sm-12">
        <cancel-button class="float-right" routerLink="../"></cancel-button>
        <button
          type="button"
          class="btn btn-primary float-right mx-3"
          (click)="edit()">
          Edit
        </button>
      </div>
    </div>
  </div>
</div>
