<delete-modal
  #deleteModal
  [name]="doc_group_name"
  (onDelete)="deleteFolder()"></delete-modal>
<div class="navbar navbar-expand-sm navbar-light subnav">
  <select
    name="doc_group_id"
    [(ngModel)]="doc_group_id"
    class="form-control d-flex mb-0 ml-2 my-auto"
    style="width: 16rem"
    (ngModelChange)="navigate()">
    <option *ngFor="let g of data" [value]="g.id">{{g.name}}</option>
  </select>
  <!--<button class="navbar-toggler m-2" type="button" (click)="collapse=!collapse" aria-label="Toggle navigation">-->
  <span
    class="navbar-toggler cdi-m-soft-arrow border-circle m-1"
    [class.active]="!collapse"
    (click)="collapse=!collapse"></span>

  <div
    class="collapse navbar-collapse"
    [collapse]="collapse"
    (click)="$event.preventDefault()">
    <ul class="navbar-nav" (click)="collapse=true">
      <li class="navbar-item ml-md-auto" *ngIf="doc_group_id">
        <a [routerLink]="['/docs',doc_group_id,'upload']" class="py-0">
          <span
            class="cdi-m-doc-upload float-left mt-1"
            tooltip="Upload Document"
            placement="bottom"></span>
          <span class="label">Upload Document</span>
        </a>
      </li>
      <li *ngIf="doc_group_id">
        <a [routerLink]="['/docs',doc_group_id,'edit']" class="py-0">
          <span
            class="cdi-m-doc-modify float-left mt-1"
            tooltip="Manage This Folder"
            placement="bottom"></span>
          <span class="label">Manage This Folder</span>
        </a>
      </li>
      <li>
        <a routerLink="/docs/add" class="py-0">
          <span
            class="cdi-m-doc-add float-left mt-1"
            tooltip="Add Document Folder"
            placement="bottom"></span>
          <span class="label">Add Document Folder</span>
        </a>
      </li>
      <li *ngIf="doc_group_id">
        <a class="py-0" style="color: black" (click)="deleteModal.show()">
          <span
            class="cdi-m-doc-trash float-left mt-1"
            tooltip="Delete This Folder"
            placement="bottom"></span>
          <span class="label">Delete This Folder</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="container-fluid subnav-padding">
  <router-outlet></router-outlet>
</div>
