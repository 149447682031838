import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { baseRole } from '../shared/baseRole';
import {
  MessageService,
  IMessage,
  MessagePageSize,
} from '../services/message.service';
import { IPaginator } from '../shared/api';

@Component({
  templateUrl: './sent.html',
})
export class SentComponent
  extends baseRole
  implements OnInit, OnDestroy, IPaginator
{
  public sub;
  public messages: IMessage[];
  public numSent: number = 0;
  public numPages = 0;
  public currentPage = 0;
  public maxPerPage = MessagePageSize;
  public currentOffset = 0;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected messageService: MessageService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.sub = this.messageService.getUpdateObservable().subscribe(data => {
      this.load();
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  load() {
    this.messageService.countSent().subscribe(data => {
      this.numSent = data;
      this.messageService
        .listSent({
          _limit: this.maxPerPage,
          _offset: this.maxPerPage * this.currentPage,
        })
        .subscribe(data => {
          this.messages = data;
        });
    });
  }

  pageChanged(event: any) {
    this.currentPage = event.page - 1;
    this.load();
  }
}
