<div class="row py-2">
  <div class="col-5">
    <label class="form-label">Start</label>
    <input
      type="text"
      required
      class="form-control input-calendar"
      name="start"
      bsDatepicker
      [(ngModel)]="search.start" />
  </div>
  <div class="col-5">
    <label class="form-label">End</label>
    <input
      type="text"
      required
      class="form-control input-calendar"
      name="end"
      bsDatepicker
      [(ngModel)]="search.end" />
  </div>
  <div class="col-2">
    <label class="form-label">{{ loc?.name }}</label>
    <button (click)="load()" class="btn btn-primary">Search</button>
  </div>
</div>

<table class="table" *ngIf="data">
  <thead>
    <tr>
      <th>Actual Date</th>
      <th>Date</th>
      <th>Service Time</th>
      <th>Unique Id</th>
      <th>Net Sales</th>
      <th>Tax</th>
      <th>Guest Count</th>
      <th>Comps</th>
      <th>Discounts</th>
      <th>Employee Discounts</th>
      <th>Pay Outs</th>
      <th>Cash</th>
      <th>Expected Cash</th>
      <th>Gift Redeemed</th>
      <th>Gift Sold</th>
      <th>CC Tips</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let d of data | orderBy: 'actual_date' ">
      <td>{{ d.date | date: 'longDate' }}</td>
      <td>{{ d.actual_date | date: 'shortDate' }}</td>
      <td>{{ ServiceTimeType[d.service_time_type]}}</td>
      <td>{{d.unique_id}}</td>
      <td>{{d.net_sales | currency: 'USD' }}</td>
      <td>{{d.tax | currency: 'USD' }}</td>
      <td>{{d.guest_count }}</td>
      <td>{{d.comps | currency: 'USD' }}</td>
      <td>{{d.discounts | currency: 'USD' }}</td>
      <td>{{d.employee_discounts | currency: 'USD' }}</td>
      <td>{{d.pay_outs | currency: 'USD' }}</td>
      <td>{{d.cash | currency: 'USD' }}</td>
      <td>{{d.expected_cash | currency: 'USD' }}</td>
      <td>{{d.gift_redeemed | currency: 'USD' }}</td>
      <td>{{d.gift_sold | currency: 'USD' }}</td>
      <td>{{d.cc_tips_collected | currency: 'USD' }}</td>
    </tr>

    <tr class="font-weight-bold">
      <td colspan="4">Totals</td>
      <td>{{totals.net_sales | currency: 'USD' }}</td>
      <td>{{totals.tax | currency: 'USD' }}</td>
      <td>{{totals.guest_count }}</td>
      <td>{{totals.comps | currency: 'USD' }}</td>
      <td>{{totals.discounts | currency: 'USD' }}</td>
      <td>{{totals.employee_discounts | currency: 'USD' }}</td>
      <td>{{totals.pay_outs | currency: 'USD' }}</td>
      <td>{{totals.cash | currency: 'USD' }}</td>
      <td>{{totals.expected_cash | currency: 'USD' }}</td>
      <td>{{totals.gift_redeemed | currency: 'USD' }}</td>
      <td>{{totals.gift_sold | currency: 'USD' }}</td>
      <td>{{totals.cc_tips_collected | currency: 'USD' }}</td>
    </tr>
  </tbody>
</table>
