<add-comment #msgComment (onAdd)="addMessageComment($event)"></add-comment>
<modal-view #modalView></modal-view>
<confirm-modal
  #confirmModal
  title="ARCHIVE WALL POST?"
  action="archive this wall post?"
  (onConfirm)="archiveMessage()"></confirm-modal>
<delete-modal
  #deleteModal
  name="this comment"
  [deleteObject]="deleteComment"
  (onDelete)="deleteMessageComment(deleteMessage,deleteComment)"></delete-modal>

<div
  bsModal
  #confirmReadModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Mark as Read"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          MARK AS READ?
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="confirmReadModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body align-self-center">
        Are you sure you want to mark this wall post as read?
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="confirmReadModal.hide()">
            </cancel-button>
            <button
              type="button"
              class="btn btn-primary float-right"
              (click)="markRead(true)">
              Yes, and archive
            </button>
            <button
              type="button"
              class="btn btn-primary float-right mr-2"
              (click)="markRead(false)">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="messages && messages.length>0">
  <carousel [interval]="0">
    <slide *ngFor="let m of messages">
      <div class="card mb-0">
        <div class="card-body mx-4">
          <div class="py-2">
            <img
              class="float-left mb-0"
              style="margin-right: 1rem; border-radius: 50%"
              [src]="getUserProfileImage(m.has_image) | safeUrl"
              height="40px" />
            <span
              class="cdi-s-cancel pointer float-right"
              *ngIf="getUserId()==m.sender_user_id || isOwner()"
              (click)="this.deleteMessage=m;confirmModal.show()"></span>
            <div class="mb-2">
              {{m.firstname}} {{m.lastname}} - {{m.title}}
              <br />{{ m.created_at | date:'short' }}
            </div>
            {{m.body}}
          </div>
          <div class="py-2">
            <ng-container *ngIf="m.images && m.images.length>0">
              <span *ngFor="let i of m.images">
                <img
                  [src]="imageUrl(m,i) | safeUrl"
                  class="img-responsive m-1 pointer"
                  style="height: 5rem"
                  (click)="modalView.show(getImageView(m,i))" />
                <!-- <span
        class="pointer"
        (click)="modalView.show(getImageView(m,i))"
        >Attachment</span     > -->
              </span>
            </ng-container>
          </div>
          <div
            class="ml-4 py-2 body-bg"
            *ngFor="let c of m.comments | orderBy:'created_at'">
            <img
              class="float-left mb-0"
              style="margin-right: 1rem; border-radius: 50%"
              [src]="getUserProfileImage(c.has_image) | safeUrl"
              height="28px" />
            <div style="font-size: 12px">
              {{c.firstname}} {{ c.lastname }} - {{ c.created_at | date:'short'
              }}
              <span
                class="cdi-s-cancel cdi-smaller pointer float-right"
                *ngIf="getUserId()==c.user_id || isOwner()"
                (click)="deleteMessage=m;deleteComment=c;deleteModal.show()"></span>
              <br />
              {{c.comment }}
            </div>
          </div>
          <div
            *ngIf="m.can_comment || m.signed"
            class="d-flex justify-content-end pointer px-4 py-4">
            <span
              *ngIf="m.can_comment"
              class="pointer float-right mr-2"
              (click)="noteMessage = m;msgComment.show()">
              <icons name="Message"></icons>
              <span class="align-self-center" style="font-size: 12px">
                Add Comment
              </span>
            </span>
            <span
              *ngIf="m.signed"
              class="float-right pointer"
              (click)="signMessage= m;confirmReadModal.show()">
              <icons name="Filetext"></icons>
              <span class="align-self-center" style="font-size: 12px">
                Mark as Read
              </span>
            </span>
          </div>
        </div>
      </div>
    </slide>
  </carousel>
</div>
