<div class="card">
  <div class="card-header">Filters</div>
  <div class="col-12 card-body">
    <div class="row">
      <div class="col-md-4 col-12">
        <label>Name</label>
        <input
          type="text"
          class="form-control"
          name="filter_name"
          [(ngModel)]="filterObject.name"
          (ngModelChange)="filter()" />
      </div>
      <div class="col-md-4 col-12">
        <div class="row">
          <div class="col">
            <label>Date Start</label>
            <input
              type="text"
              class="form-control"
              name="filter_date_start"
              [(ngModel)]="filterObject.date_start"
              bsDatepicker
              (ngModelChange)="filter()" />
          </div>
          <div class="col">
            <label>Date End</label>
            <input
              type="text"
              class="form-control"
              name="filter_date_end"
              [(ngModel)]="filterObject.date_end"
              bsDatepicker
              (ngModelChange)="filter()" />
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <label>Time Off Type</label>
        <select
          class="form-control"
          name="filter_type"
          [(ngModel)]="filterObject.whole_day"
          (ngModelChange)="filter()">
          <option [value]="1">All</option>
          <option [value]="2">Partial Day</option>
          <option [value]="3">Full Day</option>
        </select>
      </div>
    </div>
  </div>
</div>

<div *ngIf="data" class="card">
  <div class="card-body card-header card-table">
    <div class="col-12 row card-table-header">
      <div class="col-2" sort fieldname="full_name" [(orderBy)]="orderByField">
        Name
      </div>
      <div class="col-2" sort fieldname="created_at" [(orderBy)]="orderByField">
        Requested
      </div>
      <div class="col-2" sort fieldname="action_at" [(orderBy)]="orderByField">
        Updated
      </div>
      <div class="col-2" sort fieldname="approver" [(orderBy)]="orderByField">
        Approver
      </div>
      <div class="col-1" sort fieldname="approved" [(orderBy)]="orderByField">
        Status
      </div>
      <div class="col-1" sort fieldname="whole_day" [(orderBy)]="orderByField">
        Type
      </div>
      <div class="col-1" sort fieldname="start_date" [(orderBy)]="orderByField">
        Start Date
      </div>
      <div class="col-1" sort fieldname="time" [(orderBy)]="orderByField">
        Time
      </div>
    </div>
  </div>
  <div
    class="card-body card-table"
    *ngFor="let d of data | orderBy: orderByField | filterByPage: [ currentPage, maxPerPage ]">
    <div class="col-12 row">
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Name:</div>
            <div class="col-6">{{ d.full_name }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">{{ d.full_name }}</div>
      </div>
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Requested:</div>
            <div class="col-6">{{ d.created_at | date: 'shortDate' }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">
          {{ d.created_at | date: 'shortDate' }}
        </div>
      </div>
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Updated:</div>
            <div class="col-6">{{ d.action_at | date: 'shortDate' }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">
          {{ d.action_at | date: 'shortDate' }}
        </div>
      </div>
      <div class="col-md-2 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Approver:</div>
            <div class="col-6">{{ d.approver }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">{{ d.approver }}</div>
      </div>
      <div class="col-md-1 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Status:</div>
            <div class="col-6">{{ d.approved?'Approved':'Pending'}}</div>
          </div>
        </div>
        <div class="d-none d-md-block">
          {{ d.approved?'Approved':'Pending'}}
        </div>
      </div>
      <div class="col-md-1 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Type:</div>
            <div class="col-6">{{ d.whole_day?'Full Day':'Partial Day'}}</div>
          </div>
        </div>
        <div class="d-none d-md-block">
          {{ d.whole_day?'Full Day':'Partial Day'}}
        </div>
      </div>
      <div class="col-md-1 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Start Date:</div>
            <div class="col-6">{{ d.start_date | date: 'shortDate' }}</div>
          </div>
        </div>
        <div class="d-none d-md-block">
          {{ d.start_date | date: 'shortDate' }}
        </div>
      </div>
      <div class="col-md-1 col-12">
        <div class="d-flex d-md-none row">
          <div class="d-flex col-12">
            <div class="col-6">Time:</div>
            <div class="col-6">
              <div *ngIf="d.time<24*60-1">{{ d.time/60 }} hrs</div>
              <div *ngIf="d.time>=24*60-1">{{ d.time/(24*60) }} days</div>
            </div>
          </div>
        </div>
        <div class="d-none d-md-block">
          <div *ngIf="d.time<24*60-1">{{ d.time/60 }} hrs</div>
          <div *ngIf="d.time>=24*60-1">{{ d.time/(24*60) }} days</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="data && data.length>maxPerPage">
    <div class="col-12 d-flex justify-content-center mt-4">
      <pagination
        [totalItems]="data.length"
        [maxSize]="10"
        [itemsPerPage]="maxPerPage"
        class="pagination-sm"
        [(ngModel)]="currentPage"
        [boundaryLinks]="true"
        [rotate]="false">
      </pagination>
    </div>
  </div>
</div>
