import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService, IUser } from '../services/account.service';
import { ISubNavItem } from '../shared/api';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './log.html',
})
export class LogComponent extends baseRole implements OnInit {
  public menu: ISubNavItem[] = [];
  /*{ 
			label: 'Summary',
			routerLink: "/log",
			exact: true
		},
		{ 
			label: 'Breakfast',
			routerLink: "/log/add/breakfast",
		},
		{ 
			label: 'Lunch',
			routerLink: "/log/add/lunch",
		},
		{ 
			label: 'Dinner',
			routerLink: "/log/add/dinner",
		},
		{ 
			label: 'Invoices',
			routerLink: "/log/invoices",
		},
		{ 
			label: 'Timecards',
			routerLink: "/log/timecards",
		},
	];*/
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Log');

    let loc = this.accountService.getLocation();
    if (!loc) {
      this.router.navigate(['/']);
      return;
    }
    this.menu = [
      {
        label: 'Summary',
        routerLink: '/log',
        exact: true,
      },
    ];
    if (
      this.isAllowed(this.Permission.LogShiftData) &&
      loc.is_service_time_daily
    ) {
      this.menu.push({
        label: 'Daily',
        routerLink: '/log/add/daily',
      });
    } else if (this.isAllowed(this.Permission.LogShiftData)) {
      this.menu.push({
        label: 'Breakfast',
        routerLink: '/log/add/breakfast',
      });
      this.menu.push({
        label: 'Lunch',
        routerLink: '/log/add/lunch',
      });
      this.menu.push({
        label: 'Dinner',
        routerLink: '/log/add/dinner',
      });
    }

    if (this.isAllowed(this.Permission.EditInvoices) && !this.isFreemium()) {
      this.menu.push({
        label: 'Invoices',
        routerLink: '/log/invoices',
      });
    }
    this.menu.push({
      label: 'Timecards',
      routerLink: '/log/timecards',
    });
  }

  ngOnInit() {}
}
