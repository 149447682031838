import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  ContactGroupService,
  IContactGroup,
} from '../services/contactGroup.service';

@Component({
  templateUrl: './group.list.html',
})
export class GroupListComponent implements OnInit {
  public data: IContactGroup[];
  public name: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactGroupService: ContactGroupService
  ) {}

  ngOnInit() {
    this.contactGroupService.list().subscribe(data => {
      this.data = data;
    });
  }

  addGroup() {
    this.contactGroupService.update({ name: this.name }).subscribe(obj => {
      obj._can_delete = true;
      this.name = null;
      this.data.push(obj);
      this.router.navigate(['/contacts/group']);
    });
  }

  editGroup(obj) {
    obj._edit = Object.assign({}, obj);
  }

  cancelGroup(obj) {
    delete obj._edit;
  }

  saveGroup(obj) {
    this.contactGroupService.update(obj._edit || obj).subscribe(data => {
      obj.name = obj._edit.name;
      delete obj._edit;
    });
  }

  delete(obj) {
    this.contactGroupService.delete(obj.id).subscribe(result => {
      let idx = this.data.indexOf(obj);
      this.data.splice(idx, 1);
    });
  }
}
