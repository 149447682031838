<div *ngIf="user">
  <form ngNativeValidate (submit)="save()">
    <label class="m-2 m">Please assure the information below is accurate</label>
    <div class="card-columns">
      <div class="card">
        <div class="card-body">
          <label> Your Picture <small>(Not required)</small> </label>
          <img
            class="rounded mb-1"
            [src]="getUserProfileImage(user.has_image, user.firstname, user.lastname, userMap?.color)| safeUrl"
            height="60px"
            width="60px" />
          <input
            name="profile"
            type="file"
            class="form-control-file mb-3"
            id="formControlFile"
            (change)="handleFileChange($event.target.files)"
            accept="image/*" />
          <label>First name</label>
          <input
            [(ngModel)]="user.firstname"
            name="first_name"
            type="text"
            maxlength="50"
            class="form-control"
            required />
          <label>Last name </label>
          <input
            [(ngModel)]="user.lastname"
            name="last_name"
            type="text"
            maxlength="50"
            class="form-control"
            required />
          <!--<label> Birth Date </label>
					<input name='dob'  [(ngModel)]="user.dob" type='text' class='form-control input-calendar'  bsDatepicker name='dob' required placeholder="yyyy-mm-dd">-->
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label> Email </label>
          <input
            name="email"
            [(ngModel)]="user.email"
            type="email"
            class="form-control"
            (ngModelChange)="checkEmail()"
            required />
          <label>Cell</label>
          <input
            name="cell"
            [(ngModel)]="user.cell"
            type="text"
            maxlength="15"
            class="form-control"
            phone
            required />
          <div *ngIf="userMap">
            <label>PIN </label>
            <input
              disabled
              name="pin"
              [(ngModel)]="userMap.pin"
              type="number"
              class="form-control"
              required />
            <p>Your manager can change your pin for you.</p>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label> Address </label>
          <input
            [(ngModel)]="user.address"
            type="text"
            class="form-control"
            placeholder="Address"
            name="address"
            required />
          <label>City</label>
          <input
            [(ngModel)]="user.city"
            type="text"
            class="form-control"
            placeholder="City"
            name="city"
            required />
          <label>Country</label>
          <select
            [(ngModel)]="user.country"
            class="form-control"
            required
            name="country">
            <option *ngFor="let c of CountryKeys" [value]="c">
              {{Countries[c]}}
            </option>
          </select>
          <label *ngIf="user.country=='US' || user.country==''">State</label>
          <select
            *ngIf="user.country=='US' || user.country==''"
            [(ngModel)]="user.state"
            class="form-control"
            required
            name="state">
            <option *ngFor="let s of StateKeys" [value]="s">
              {{States[s]}}
            </option>
          </select>
          <label *ngIf="user.country=='CA'">Province</label>
          <select
            *ngIf="user.country=='CA'"
            [(ngModel)]="user.state"
            class="form-control"
            required
            name="state">
            <option *ngFor="let p of ProvinceKeys" [value]="p">
              {{Provinces[p]}}
            </option>
          </select>
          <label *ngIf="user.country=='US' || user.country==''">ZIP </label>
          <input
            *ngIf="user.country=='US' || user.country==''"
            [(ngModel)]="user.zip"
            type="string"
            class="form-control"
            pattern="[0-9]{5}"
            maxlength="5"
            placeholder="ZIP"
            required
            name="zip" />
          <label *ngIf="user.country=='CA'">Postal Code </label>
          <input
            *ngIf="user.country=='CA'"
            [(ngModel)]="user.zip"
            type="string"
            class="form-control"
            maxlength="7"
            placeholder="Postal Code"
            required
            name="zip" />
        </div>
      </div>
    </div>

    <div class="row mt-2" *ngIf="emailError">
      <div class="col-12">
        <alert type="danger">{{emailError}} </alert>
      </div>
    </div>
    <div class="row mt-2" *ngIf="saveMessage">
      <div class="col-12">
        <alert type="success"> <strong>Save</strong> - {{saveMessage}} </alert>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <save-button class="float-right" [disabled]="saveMessage"></save-button>
      </div>
    </div>
  </form>

  <form ngNativeValidate (submit)="changePassword()" class="card">
    <label class="text-center pt-4">Change your password</label>
    <div class="card-body">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <label>Old password</label>
          <input
            type="password"
            name="old_password"
            [(ngModel)]="oldPassword"
            class="form-control override-invalid"
            required />
        </div>
        <div class="col-md-4">
          <label>New password</label>
          <input
            type="password"
            name="new_password"
            [(ngModel)]="password"
            class="form-control override-invalid"
            (ngModelChange)="checkPassword()"
            required />
        </div>
        <div class="col-md-4">
          <label>Confirm password</label>
          <input
            type="password"
            name="cpassword"
            [(ngModel)]="cpassword"
            class="form-control override-invalid"
            (ngModelChange)="matchPassword()"
            required />
        </div>
      </div>
      <div class="row mt-2" *ngIf="passwordSaved">
        <div class="col-12">
          <alert type="success">
            <strong>Save</strong> - {{passwordSaved}}
          </alert>
        </div>
      </div>
      <global-error [error]="errorPassword"></global-error>
      <local-error [error]="warning"></local-error>
      <local-error-array [errors]="suggestions"></local-error-array>
      <div class="row mt-2">
        <div class="col-12">
          <save-button
            [disabled]="errorPassword || !oldPassword || !password || !cpassword"
            class="float-right"></save-button>
        </div>
      </div>
    </div>
  </form>
</div>
