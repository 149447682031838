import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  moment,
  WidgetType,
  ILogReport,
  IChartSales,
  ServiceTimeType,
} from '../api';
import { AccountService } from '../../services/account.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'widgets',
  templateUrl: 'widget.html',
})
export class WidgetComponent implements OnInit {
  public daily: boolean;
  public WidgetType = WidgetType;
  public widgetList: number[];
  public doneData = false;
  public weekStart: number;
  public _reportData: ILogReport[];
  public _ytdMonth: ILogReport[];
  public _ytdQuarter: ILogReport[];
  public _salesThisWeek: ILogReport[];
  public _salesLastWeek: ILogReport[];
  public _salesThisMonth: ILogReport[];
  public _salesThisMonthByWeek: ILogReport[];
  public _avgSalesPerDay: ILogReport[];

  @Input() set reportData(value: ILogReport[]) {
    this.doneData = false;
    if (!value) return;
    this._reportData = value;
    this.grindData();
  }

  constructor(public accountService: AccountService) {}

  ngOnInit() {
    this.daily = this.accountService.getLocation().is_service_time_daily;
    this.weekStart = this.accountService.getLocation().week_start;
    this.widgetList = this.accountService.getLocation().widgets;
    // filter out invalid widgets (in case we add more later)
    this.widgetList = this.widgetList.filter(w => w in WidgetType);
    moment.updateLocale(moment.locale(), {
      week: {
        dow: this.weekStart,
      },
    });
  }

  // This needs to do:
  // Sales this week
  // Sales last week
  // Sales per week last 4 weeks.
  // YTD by quarters
  // YTS by months
  // avg sales by weekday
  grindData() {
    let startOfYear = moment().startOf('year');
    let startOfMonth = moment().startOf('month');
    let startOfWeek = moment().startOf('week');
    let startOfLastWeek = moment().startOf('week').add(-7, 'days');
    let startOfMonthByWeek = moment().add(-28, 'days').startOf('week');

    this._ytdMonth = new Array(12);
    this._ytdQuarter = new Array(4);
    this._salesThisWeek = new Array(7);
    this._salesLastWeek = new Array(7);
    this._salesThisMonthByWeek = new Array(5);
    this._salesThisMonth = new Array(startOfMonth.daysInMonth());
    this._avgSalesPerDay = new Array(7);
    let avgCnt = [0, 0, 0, 0, 0, 0, 0];

    zeroLogReports(this._ytdMonth, startOfYear, 1, 'months', this.daily);
    zeroLogReports(this._ytdQuarter, startOfYear, 3, 'months', this.daily);
    zeroLogReports(this._salesThisWeek, startOfWeek, 1, 'days', this.daily);
    zeroLogReports(this._salesLastWeek, startOfLastWeek, 1, 'days', this.daily);
    zeroLogReports(
      this._salesThisMonthByWeek,
      startOfMonthByWeek,
      7,
      'days',
      this.daily
    );
    zeroLogReports(this._salesThisMonth, startOfMonth, 1, 'days', this.daily);
    zeroLogReports(this._avgSalesPerDay, startOfWeek, 1, 'days', this.daily);
    for (let d of this._reportData) {
      let day = d.d.day() - this.weekStart;
      if (day < 0) day += 7;

      addLogReport(this._avgSalesPerDay[day], d);
      avgCnt[day]++;

      if (d.d.isSameOrAfter(startOfWeek)) {
        addLogReport(this._salesThisWeek[day], d);
      }
      if (d.d.isSameOrAfter(startOfMonthByWeek)) {
        let k = d.d.week() - startOfMonthByWeek.week();
        if (k < 0) k += 52;
        addLogReport(this._salesThisMonthByWeek[k], d);
      }
      if (d.d.isSameOrAfter(startOfMonth)) {
        addLogReport(this._salesThisMonth[d.d.date() - 1], d);
      }
      if (d.d.isSameOrAfter(startOfLastWeek) && d.d.isBefore(startOfWeek)) {
        addLogReport(this._salesLastWeek[day], d);
      }
      if (d.d.isSameOrAfter(startOfYear)) {
        addLogReport(this._ytdMonth[d.d.month()], d);
        addLogReport(this._ytdQuarter[d.d.quarter() - 1], d);
      }
    }
    for (let i = 0; i < 7; i++) {
      let a = this._avgSalesPerDay[i];
      a.foh_cost /= avgCnt[i];
      a.boh_cost /= avgCnt[i];
      a.fixed_cost /= avgCnt[i];

      for (let i = 0; i < a.service_time.length; i++) {
        a.service_time[i].sales /= avgCnt[i];
        a.service_time[i].guest_count /= avgCnt[i];
      }
    }
    this.doneData = true;
  }
}

@Component({
  selector: 'widget-sales-last-week',
  templateUrl: 'widget.salesLastWeek.html',
})
export class WidgetSalesLastWeekComponent {
  public Highcharts = Highcharts;
  public total = 0;
  public avg = 0;
  public chartData: IChartSales;
  public _reportData;
  @Input() set reportData(data: any[]) {
    if (!data || data.length == 0) return;
    this._reportData = data;
    this.buildChart();
  }

  constructor(public accountService: AccountService) {}

  buildChart() {
    this.chartData = {
      popupFormat: 'ddd MMM D, YYYY',
      titleBar: false,
      tickInterval: 86400 * 1000,
      data: [],
    };
    this.total = 0;
    for (let d of this._reportData) {
      this.total += d.service_time[0].sales;
    }
    this.avg = this.total / 7;
    let isDaily = this.accountService.getLocation().is_service_time_daily;
    dataToChart(this.chartData, this._reportData, isDaily);
  }
}

@Component({
  selector: 'widget-sales-this-week',
  templateUrl: 'widget.salesThisWeek.html',
})
export class WidgetSalesThisWeekComponent {
  public Highcharts = Highcharts;
  public total = 0;
  public avg = 0;
  public chartData: IChartSales;
  public _reportData;
  @Input() set reportData(data: any[]) {
    if (!data || data.length == 0) return;
    this._reportData = data;
    this.buildChart();
  }

  constructor(public accountService: AccountService) {}

  buildChart() {
    this.chartData = {
      popupFormat: 'ddd MMM D, YYYY',
      titleBar: false,
      tickInterval: 86400 * 1000,
      data: [],
    };
    this.total = 0;
    for (let d of this._reportData) {
      this.total += d.service_time[0].sales;
    }
    this.avg = this.total / 7;
    let isDaily = this.accountService.getLocation().is_service_time_daily;
    dataToChart(this.chartData, this._reportData, isDaily);
  }
}

@Component({
  selector: 'widget-sales-this-month',
  templateUrl: 'widget.salesThisMonth.html',
})
export class WidgetSalesThisMonthComponent {
  public Highcharts = Highcharts;
  public total = 0;
  public avg = 0;
  public chartData: IChartSales;
  public _reportData;
  @Input() set reportData(data: any[]) {
    if (!data || data.length == 0) return;
    this._reportData = data;
    this.buildChart();
  }

  constructor(public accountService: AccountService) {}

  buildChart() {
    this.chartData = {
      popupFormat: 'ddd MMM D, YYYY',
      titleBar: true,
      tickInterval: 86400 * 1000,
      data: [],
    };
    this.total = 0;
    for (let d of this._reportData) {
      this.total += d.service_time[0].sales;
    }
    this.avg = this.total / 7;
    let isDaily = this.accountService.getLocation().is_service_time_daily;
    dataToChart(this.chartData, this._reportData, isDaily);
  }
}

@Component({
  selector: 'widget-ytd-quarters',
  templateUrl: 'widget.ytdQuarters.html',
})
export class WidgetYTDQuartersComponent {
  public Highcharts = Highcharts;
  public chartData: IChartSales;
  public _reportData;
  @Input() set reportData(data: any[]) {
    if (!data || data.length == 0) return;
    this._reportData = data;
    this.buildChart();
  }

  constructor(public accountService: AccountService) {}

  buildChart() {
    this.chartData = {
      popupFormat: 'MMM YYYY',
      titleBar: false,
      tickInterval: 90 * 86400 * 1000,
      data: [],
    };
    let isDaily = this.accountService.getLocation().is_service_time_daily;
    dataToChart(this.chartData, this._reportData, isDaily);
  }
}

@Component({
  selector: 'widget-year-to-date',
  templateUrl: 'widget.yearToDate.html',
})
export class WidgetYearToDateComponent {
  public Highcharts = Highcharts;
  public chartData: IChartSales;
  public _reportData;
  public total = 0;
  @Input() set reportData(data: any[]) {
    if (!data || data.length == 0) return;
    this._reportData = data;
    this.buildChart();
  }

  constructor(public accountService: AccountService) {}

  buildChart() {
    this.chartData = {
      popupFormat: 'MMM YYYY',
      titleBar: false,
      tickInterval: 30 * 86400 * 1000,
      data: [],
    };

    this.total = 0;
    for (let d of this._reportData) {
      this.total += d.service_time[0].sales;
    }

    let isDaily = this.accountService.getLocation().is_service_time_daily;
    dataToChart(this.chartData, this._reportData, isDaily);
  }
}

@Component({
  selector: 'widget-sales-per-week',
  templateUrl: 'widget.salesPerWeek.html',
})
export class WidgetSalesPerWeekComponent {
  public Highcharts = Highcharts;
  public chartData: IChartSales;
  public _reportData;
  @Input() set reportData(data: any[]) {
    if (!data || data.length == 0) return;
    this._reportData = data;
    this.buildChart();
  }

  constructor(public accountService: AccountService) {}

  buildChart() {
    this.chartData = {
      popupFormat: 'ddd MMM D, YYYY',
      titleBar: false,
      tickInterval: 7 * 86400 * 1000,
      data: [],
    };

    let isDaily = this.accountService.getLocation().is_service_time_daily;
    dataToChart(this.chartData, this._reportData, isDaily);
  }
}

@Component({
  selector: 'widget-avg-sales-by-weekday',
  templateUrl: 'widget.avgSalesByWeekday.html',
})
export class WidgetAvgSalesByWeekdayComponent {
  public Highcharts = Highcharts;
  public chartData: IChartSales;
  public _reportData;
  public total = 0;
  @Input() set reportData(data: any[]) {
    if (!data || data.length == 0) return;
    this._reportData = data;
    this.buildChart();
  }

  constructor(public accountService: AccountService) {}

  buildChart() {
    this.chartData = {
      popupFormat: 'dddd',
      titleBar: false,
      tickInterval: 86400 * 1000,
      data: [],
      labelFormat: 'ddd',
      //categories: [ 'Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
    };

    this.total = 0;
    for (let d of this._reportData) {
      this.total += d.service_time[0].sales;
    }

    let isDaily = this.accountService.getLocation().is_service_time_daily;
    dataToChart(this.chartData, this._reportData, isDaily);
  }
}

@Component({
  selector: 'widget-nochart-data',
  template: '<div class="text-center">No data for this graph</div>',
})
export class WidgetNoChartDataComponent {}

export function dataToChart(
  chartData: IChartSales,
  data: ILogReport[],
  isDaily: boolean,
  includeLabor = true
) {
  if (isDaily) {
    chartData.data[0] = {
      name: ServiceTimeType[ServiceTimeType.Daily],
      type: 'column',
      data: [],
    };
  } else {
    chartData.data[0] = {
      name: ServiceTimeType[ServiceTimeType.Breakfast],
      type: 'column',
      data: [],
    };
    chartData.data[1] = {
      name: ServiceTimeType[ServiceTimeType.Lunch],
      type: 'column',
      data: [],
    };
    chartData.data[2] = {
      name: ServiceTimeType[ServiceTimeType.Dinner],
      type: 'column',
      data: [],
    };
  }

  if (includeLabor) {
    chartData.data.push({
      name: 'Labor Costs',
      type: 'spline',
      color: '#888888',
      lineWidth: 2,
      data: [],
    });
    chartData.data.push({
      name: 'COGS',
      type: 'spline',
      color: '#444444',
      lineWidth: 2,
      data: [],
    });
  }

  for (let d of data) {
    let t = d.d.unix() * 1000; //+offset;
    if (isDaily) {
      chartData.data[0].data.push([t, d.service_time[0].sales]);
    } else {
      chartData.data[0].data.push([
        t,
        d.service_time[ServiceTimeType.Breakfast].sales,
      ]);
      chartData.data[1].data.push([
        t,
        d.service_time[ServiceTimeType.Lunch].sales,
      ]);
      chartData.data[2].data.push([
        t,
        d.service_time[ServiceTimeType.Dinner].sales,
      ]);
    }
    if (includeLabor) {
      chartData.data[isDaily ? 1 : 3].data.push([t, d.foh_cost + d.boh_cost]);
      chartData.data[isDaily ? 2 : 4].data.push([t, d.cogs_cost]);
    }
  }
}

export function zeroLogReports(
  data: ILogReport[],
  startDate: any,
  inc: number,
  offset: string,
  isDaily: boolean
) {
  let s = moment(startDate);
  for (let i = 0; i < data.length; i++, s.add(inc, offset)) {
    data[i] = {
      date: null,
      d: moment(s),
      foh_cost: 0,
      boh_cost: 0,
      /*salary: {
				foh_cost: 0,
				boh_cost: 0,
				foh_minutes: 0,
				boh_minutes: 0,
				ot_foh_cost: 0,
				ot_boh_cost: 0,
				ot_foh_minutes: 0,
				ot_boh_minutes: 0,
				ot_type: 0,
			},
			scheduled: {
				foh_cost: 0,
				boh_cost: 0,
				foh_minutes: 0,
				boh_minutes: 0,
				ot_foh_cost: 0,
				ot_boh_cost: 0,
				ot_foh_minutes: 0,
				ot_boh_minutes: 0,
				ot_type: 0,
			},
			timeclock: {
				foh_cost: 0,
				boh_cost: 0,
				foh_minutes: 0,
				boh_minutes: 0,
				ot_foh_cost: 0,
				ot_boh_cost: 0,
				ot_foh_minutes: 0,
				ot_boh_minutes: 0,
				ot_type: 0,
			},*/
      log_foh_cost: 0,
      log_boh_cost: 0,
      cogs_cost: 0,
      fixed_cost: 0,
      max_temp: 0,
      service_time: null,
    };
    if (isDaily) {
      data[i].service_time = [{ sales: 0, guest_count: 0 }];
    } else {
      data[i].service_time = [
        { sales: 0, guest_count: 0 },
        { sales: 0, guest_count: 0 },
        { sales: 0, guest_count: 0 },
        { sales: 0, guest_count: 0 },
      ];
    }
  }
}

export function addLogReport(a: ILogReport, b: ILogReport) {
  if (!a || !b) {
    //		console.error('Missing log report in addLogReport');
    return;
  }
  a.foh_cost += b.foh_cost;
  a.boh_cost += b.boh_cost;
  a.fixed_cost += b.fixed_cost;
  a.cogs_cost += b.cogs_cost;

  for (let i = 0; i < a.service_time.length; i++) {
    a.service_time[i].sales += b.service_time[i].sales;
    a.service_time[i].guest_count += b.service_time[i].guest_count;
  }
}

// import { Component, OnInit, OnDestroy, Input } from '@angular/core';
// import { Router, ActivatedRoute, Params } from '@angular/router';
// import {
//   moment,
//   WidgetType,
//   ILogReport,
//   IChartSales,
//   ServiceTimeType,
// } from '../api';
// import { AccountService } from '../../services/account.service';
// import * as Highcharts from 'highcharts';

// moment.updateLocale(moment.locale(), {
//   week: {
//     dow: 1, // Monday is the first day of the week
//   },
// });

// @Component({
//   selector: 'widgets',
//   templateUrl: 'widget.html',
// })
// export class WidgetComponent implements OnInit {
//   public daily: boolean;
//   public WidgetType = WidgetType;
//   public widgetList: number[];
//   public doneData = false;
//   public weekStart: number;
//   public _reportData: ILogReport[];
//   public _ytdMonth: ILogReport[];
//   public _ytdQuarter: ILogReport[];
//   public _salesThisWeek: ILogReport[];
//   public _salesLastWeek: ILogReport[];
//   public _salesThisMonth: ILogReport[];
//   public _salesThisMonthByWeek: ILogReport[];
//   public _avgSalesPerDay: ILogReport[];

//   @Input() set reportData(value: ILogReport[]) {
//     this.doneData = false;
//     if (!value) return;
//     this._reportData = value;
//     this.grindData();
//   }

//   constructor(public accountService: AccountService) {}

//   ngOnInit() {
//     this.daily = this.accountService.getLocation().is_service_time_daily;
//     this.weekStart = this.accountService.getLocation().week_start;
//     this.widgetList = this.accountService.getLocation().widgets;
//     // filter out invalid widgets (in case we add more later)
//     this.widgetList = this.widgetList.filter(w => w in WidgetType);
//   }

//   // This needs to do:
//   // Sales this week
//   // Sales last week
//   // Sales per week last 4 weeks.
//   // YTD by quarters
//   // YTS by months
//   // avg sales by weekday
//   grindData() {
//     let startOfYear = moment().startOf('year');
//     let startOfMonth = moment().startOf('month');

//     let startOfWeek = moment().startOf('week').add(this.weekStart, 'days');
//     let startOfLastWeek = moment()
//       .startOf('week')
//       .add(this.weekStart, 'days')
//       .add(-7, 'days');
//     let startOfMonthByWeek = moment()
//       .startOf('week')
//       .add(-28, 'days')
//       .add(this.weekStart, 'days');
//     this._ytdMonth = new Array(12);
//     this._ytdQuarter = new Array(4);
//     this._salesThisWeek = new Array(7);
//     this._salesLastWeek = new Array(7);
//     this._salesThisMonthByWeek = new Array(5);
//     this._salesThisMonth = new Array(startOfMonth.daysInMonth());
//     this._avgSalesPerDay = new Array(7);
//     let avgCnt = [0, 0, 0, 0, 0, 0, 0];

//     zeroLogReports(this._ytdMonth, startOfYear, 1, 'months', this.daily);
//     zeroLogReports(this._ytdQuarter, startOfYear, 3, 'months', this.daily);
//     zeroLogReports(this._salesThisWeek, startOfWeek, 1, 'days', this.daily);
//     zeroLogReports(this._salesLastWeek, startOfLastWeek, 1, 'days', this.daily);
//     zeroLogReports(
//       this._salesThisMonthByWeek,
//       startOfMonthByWeek,
//       7,
//       'days',
//       this.daily
//     );
//     zeroLogReports(this._salesThisMonth, startOfMonth, 1, 'days', this.daily);
//     zeroLogReports(this._avgSalesPerDay, startOfWeek, 1, 'days', this.daily);
//     console.log(this._reportData);
//     for (let d of this._reportData) {
//       let day = d.d.day() - this.weekStart;
//       if (day < 0) day += 7;
//       // console.log(day);
//       addLogReport(this._avgSalesPerDay[d.d.day() - this.weekStart], d);
//       avgCnt[day]++;

//       if (d.d.isSameOrAfter(startOfWeek)) {
//         addLogReport(this._salesThisWeek[day], d);
//       }

//       if (d.d.isSameOrAfter(startOfMonthByWeek)) {
//         let k = d.d.week() - startOfMonthByWeek.week();
//         // console.log(d);
//         // if (d.date == '2023-04-16') {
//         //   console.log(d.d.week(), startOfMonthByWeek.week());
//         //   k = 15;
//         // }
//         if (k < 0) k += 52;
//         // console.log(k, d.d.week(), startOfMonthByWeek.week());
//         addLogReport(this._salesThisMonthByWeek[k], d);
//       }
//       if (d.d.isSameOrAfter(startOfMonth)) {
//         addLogReport(this._salesThisMonth[d.d.date() - 1], d);
//       }
//       if (d.d.isSameOrAfter(startOfLastWeek) && d.d.isBefore(startOfWeek)) {
//         addLogReport(this._salesLastWeek[day], d);
//       }
//       if (d.d.isSameOrAfter(startOfYear)) {
//         addLogReport(this._ytdMonth[d.d.month()], d);
//         addLogReport(this._ytdQuarter[d.d.quarter() - 1], d);
//       }
//     }
//     // console.log(this._salesThisMonthByWeek);
//     for (let i = 0; i < 7; i++) {
//       let a = this._avgSalesPerDay[i];
//       a.foh_cost /= avgCnt[i];
//       a.boh_cost /= avgCnt[i];
//       a.fixed_cost /= avgCnt[i];

//       for (let i = 0; i < a.service_time.length; i++) {
//         a.service_time[i].sales /= avgCnt[i];
//         a.service_time[i].guest_count /= avgCnt[i];
//       }
//     }
//     this.doneData = true;
//   }
// }

// @Component({
//   selector: 'widget-sales-last-week',
//   templateUrl: 'widget.salesLastWeek.html',
// })
// export class WidgetSalesLastWeekComponent {
//   public Highcharts = Highcharts;
//   public total = 0;
//   public avg = 0;
//   public chartData: IChartSales;
//   public _reportData;
//   @Input() set reportData(data: any[]) {
//     if (!data || data.length == 0) return;
//     this._reportData = data;
//     this.buildChart();
//   }

//   constructor(public accountService: AccountService) {}

//   buildChart() {
//     this.chartData = {
//       popupFormat: 'ddd MMM D, YYYY',
//       titleBar: false,
//       tickInterval: 86400 * 1000,
//       data: [],
//     };
//     this.total = 0;
//     for (let d of this._reportData) {
//       this.total += d.service_time[0].sales;
//     }
//     this.avg = this.total / 7;
//     let isDaily = this.accountService.getLocation().is_service_time_daily;
//     dataToChart(this.chartData, this._reportData, isDaily);
//   }
// }

// @Component({
//   selector: 'widget-sales-this-week',
//   templateUrl: 'widget.salesThisWeek.html',
// })
// export class WidgetSalesThisWeekComponent {
//   public Highcharts = Highcharts;
//   public total = 0;
//   public avg = 0;
//   public chartData: IChartSales;
//   public _reportData;
//   @Input() set reportData(data: any[]) {
//     if (!data || data.length == 0) return;
//     this._reportData = data;
//     this.buildChart();
//   }

//   constructor(public accountService: AccountService) {}

//   buildChart() {
//     this.chartData = {
//       popupFormat: 'ddd MMM D, YYYY',
//       titleBar: false,
//       tickInterval: 86400 * 1000,
//       data: [],
//     };
//     this.total = 0;
//     for (let d of this._reportData) {
//       this.total += d.service_time[0].sales;
//     }
//     this.avg = this.total / 7;
//     let isDaily = this.accountService.getLocation().is_service_time_daily;
//     dataToChart(this.chartData, this._reportData, isDaily);
//   }
// }

// @Component({
//   selector: 'widget-sales-this-month',
//   templateUrl: 'widget.salesThisMonth.html',
// })
// export class WidgetSalesThisMonthComponent {
//   public Highcharts = Highcharts;
//   public total = 0;
//   public avg = 0;
//   public chartData: IChartSales;
//   public _reportData;
//   @Input() set reportData(data: any[]) {
//     if (!data || data.length == 0) return;
//     this._reportData = data;
//     this.buildChart();
//   }

//   constructor(public accountService: AccountService) {}

//   buildChart() {
//     this.chartData = {
//       popupFormat: 'ddd MMM D, YYYY',
//       titleBar: true,
//       tickInterval: 86400 * 1000,
//       data: [],
//     };
//     this.total = 0;
//     for (let d of this._reportData) {
//       this.total += d.service_time[0].sales;
//     }
//     this.avg = this.total / 7;
//     let isDaily = this.accountService.getLocation().is_service_time_daily;
//     dataToChart(this.chartData, this._reportData, isDaily);
//   }
// }

// @Component({
//   selector: 'widget-ytd-quarters',
//   templateUrl: 'widget.ytdQuarters.html',
// })
// export class WidgetYTDQuartersComponent {
//   public Highcharts = Highcharts;
//   public chartData: IChartSales;
//   public _reportData;
//   @Input() set reportData(data: any[]) {
//     if (!data || data.length == 0) return;
//     this._reportData = data;
//     this.buildChart();
//   }

//   constructor(public accountService: AccountService) {}

//   buildChart() {
//     this.chartData = {
//       popupFormat: 'MMM YYYY',
//       titleBar: false,
//       tickInterval: 90 * 86400 * 1000,
//       data: [],
//     };
//     let isDaily = this.accountService.getLocation().is_service_time_daily;
//     dataToChart(this.chartData, this._reportData, isDaily);
//   }
// }

// @Component({
//   selector: 'widget-year-to-date',
//   templateUrl: 'widget.yearToDate.html',
// })
// export class WidgetYearToDateComponent {
//   public Highcharts = Highcharts;
//   public chartData: IChartSales;
//   public _reportData;
//   public total = 0;
//   @Input() set reportData(data: any[]) {
//     if (!data || data.length == 0) return;
//     this._reportData = data;
//     this.buildChart();
//   }

//   constructor(public accountService: AccountService) {}

//   buildChart() {
//     this.chartData = {
//       popupFormat: 'MMM YYYY',
//       titleBar: false,
//       tickInterval: 30 * 86400 * 1000,
//       data: [],
//     };

//     this.total = 0;
//     for (let d of this._reportData) {
//       this.total += d.service_time[0].sales;
//     }

//     let isDaily = this.accountService.getLocation().is_service_time_daily;
//     dataToChart(this.chartData, this._reportData, isDaily);
//   }
// }

// @Component({
//   selector: 'widget-sales-per-week',
//   templateUrl: 'widget.salesPerWeek.html',
// })
// export class WidgetSalesPerWeekComponent {
//   public Highcharts = Highcharts;
//   public chartData: IChartSales;
//   public _reportData;
//   @Input() set reportData(data: any[]) {
//     if (!data || data.length == 0) return;
//     this._reportData = data;
//     this.buildChart();
//   }

//   constructor(public accountService: AccountService) {}

//   buildChart() {
//     this.chartData = {
//       popupFormat: 'ddd MMM D, YYYY',
//       titleBar: false,
//       tickInterval: 7 * 86400 * 1000,
//       data: [],
//     };

//     let isDaily = this.accountService.getLocation().is_service_time_daily;
//     dataToChart(this.chartData, this._reportData, isDaily);
//   }
// }

// @Component({
//   selector: 'widget-avg-sales-by-weekday',
//   templateUrl: 'widget.avgSalesByWeekday.html',
// })
// export class WidgetAvgSalesByWeekdayComponent {
//   public Highcharts = Highcharts;
//   public chartData: IChartSales;
//   public _reportData;
//   public total = 0;
//   @Input() set reportData(data: any[]) {
//     if (!data || data.length == 0) return;
//     this._reportData = data;
//     this.buildChart();
//   }

//   constructor(public accountService: AccountService) {}

//   buildChart() {
//     this.chartData = {
//       popupFormat: 'dddd',
//       titleBar: false,
//       tickInterval: 86400 * 1000,
//       data: [],
//       labelFormat: 'ddd',
//       //categories: [ 'Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
//     };

//     this.total = 0;
//     for (let d of this._reportData) {
//       this.total += d.service_time[0].sales;
//     }

//     let isDaily = this.accountService.getLocation().is_service_time_daily;
//     dataToChart(this.chartData, this._reportData, isDaily);
//   }
// }

// @Component({
//   selector: 'widget-nochart-data',
//   template: '<div class="text-center">No data for this graph</div>',
// })
// export class WidgetNoChartDataComponent {}

// export function dataToChart(
//   chartData: IChartSales,
//   data: ILogReport[],
//   isDaily: boolean,
//   includeLabor = true
// ) {
//   if (isDaily) {
//     chartData.data[0] = {
//       name: ServiceTimeType[ServiceTimeType.Daily],
//       type: 'column',
//       data: [],
//     };
//   } else {
//     chartData.data[0] = {
//       name: ServiceTimeType[ServiceTimeType.Breakfast],
//       type: 'column',
//       data: [],
//     };
//     chartData.data[1] = {
//       name: ServiceTimeType[ServiceTimeType.Lunch],
//       type: 'column',
//       data: [],
//     };
//     chartData.data[2] = {
//       name: ServiceTimeType[ServiceTimeType.Dinner],
//       type: 'column',
//       data: [],
//     };
//   }

//   if (includeLabor) {
//     chartData.data.push({
//       name: 'Labor Costs',
//       type: 'spline',
//       color: '#888888',
//       lineWidth: 2,
//       data: [],
//     });
//     chartData.data.push({
//       name: 'COGS',
//       type: 'spline',
//       color: '#444444',
//       lineWidth: 2,
//       data: [],
//     });
//   }

//   for (let d of data) {
//     let t = d.d.unix() * 1000; //+offset;
//     if (isDaily) {
//       chartData.data[0].data.push([t, d.service_time[0].sales]);
//     } else {
//       chartData.data[0].data.push([
//         t,
//         d.service_time[ServiceTimeType.Breakfast].sales,
//       ]);
//       chartData.data[1].data.push([
//         t,
//         d.service_time[ServiceTimeType.Lunch].sales,
//       ]);
//       chartData.data[2].data.push([
//         t,
//         d.service_time[ServiceTimeType.Dinner].sales,
//       ]);
//     }
//     if (includeLabor) {
//       chartData.data[isDaily ? 1 : 3].data.push([t, d.foh_cost + d.boh_cost]);
//       chartData.data[isDaily ? 2 : 4].data.push([t, d.cogs_cost]);
//     }
//   }
// }

// export function zeroLogReports(
//   data: ILogReport[],
//   startDate: any,
//   inc: number,
//   offset: string,
//   isDaily: boolean
// ) {
//   let s = moment(startDate);
//   for (let i = 0; i < data.length; i++, s.add(inc, offset)) {
//     data[i] = {
//       date: null,
//       d: moment(s),
//       foh_cost: 0,
//       boh_cost: 0,
//       /*salary: {
// 				foh_cost: 0,
// 				boh_cost: 0,
// 				foh_minutes: 0,
// 				boh_minutes: 0,
// 				ot_foh_cost: 0,
// 				ot_boh_cost: 0,
// 				ot_foh_minutes: 0,
// 				ot_boh_minutes: 0,
// 				ot_type: 0,
// 			},
// 			scheduled: {
// 				foh_cost: 0,
// 				boh_cost: 0,
// 				foh_minutes: 0,
// 				boh_minutes: 0,
// 				ot_foh_cost: 0,
// 				ot_boh_cost: 0,
// 				ot_foh_minutes: 0,
// 				ot_boh_minutes: 0,
// 				ot_type: 0,
// 			},
// 			timeclock: {
// 				foh_cost: 0,
// 				boh_cost: 0,
// 				foh_minutes: 0,
// 				boh_minutes: 0,
// 				ot_foh_cost: 0,
// 				ot_boh_cost: 0,
// 				ot_foh_minutes: 0,
// 				ot_boh_minutes: 0,
// 				ot_type: 0,
// 			},*/
//       log_foh_cost: 0,
//       log_boh_cost: 0,
//       cogs_cost: 0,
//       fixed_cost: 0,
//       max_temp: 0,
//       service_time: null,
//     };
//     if (isDaily) {
//       data[i].service_time = [{ sales: 0, guest_count: 0 }];
//     } else {
//       data[i].service_time = [
//         { sales: 0, guest_count: 0 },
//         { sales: 0, guest_count: 0 },
//         { sales: 0, guest_count: 0 },
//         { sales: 0, guest_count: 0 },
//       ];
//     }
//   }
// }

// export function addLogReport(a: ILogReport, b: ILogReport) {
//   if (!a || !b) {
//     //		console.error('Missing log report in addLogReport');
//     return;
//   }
//   a.foh_cost += b.foh_cost;
//   a.boh_cost += b.boh_cost;
//   a.fixed_cost += b.fixed_cost;
//   a.cogs_cost += b.cogs_cost;

//   for (let i = 0; i < a.service_time.length; i++) {
//     a.service_time[i].sales += b.service_time[i].sales;
//     a.service_time[i].guest_count += b.service_time[i].guest_count;
//   }
// }
