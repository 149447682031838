<modal-invoice (done)="load()" #modalInvoice></modal-invoice>
<div class="text-center">
  <span class="title">Invoices</span>
  <button
    class="btn btn-primary float-right mb-2"
    type="button"
    (click)="modalInvoice.show()">
    Add Invoice
  </button>
</div>
<table class="table table-striped table-hover">
  <thead style="background-color: #c6c8ca">
    <tr>
      <th sort fieldname="company" [(orderBy)]="orderBy" class="align-middle">
        Company
      </th>
      <th sort fieldname="ponumber" [(orderBy)]="orderBy" class="align-middle">
        Invoice #
      </th>
      <th sort fieldname="amount" [(orderBy)]="orderBy" class="align-middle">
        Amount
      </th>
      <th></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let i of invoices| orderBy: [orderBy]">
      <td class="align-middle">{{i.company}}</td>
      <td class="align-middle d-lg-table-cell">#{{i.ponumber}}</td>
      <td class="align-middle d-lg-table-cell">
        {{i.amount | currency: 'USD'}}
      </td>
      <td
        class="cdi-m-trash float-right pointer"
        (click)="deleteInvoice(i)"></td>
      <td
        class="cdi-m-edit float-right pointer"
        (click)="modalInvoice.show(i)"></td>
    </tr>
  </tbody>
</table>

<hr />
<label class="align-middle"
  >Total Invoices Today: {{total | currency: 'USD'}}
</label>
