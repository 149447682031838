<form ngNativeValidate>
  <div class="title text-center mt-3">Vendor Groups</div>

  <div class="input-group">
    <input
      type="text"
      name="name"
      class="form-control form-control-lg"
      [(ngModel)]="name"
      placeholder="Add new group name" />
    <div class="input-group-append">
      <button class="btn btn-primary" type="submit" (click)="addGroup()">
        <icons name="Plus"></icons>
      </button>
    </div>
  </div>
</form>

<div class="card" *ngIf="data && data.length>0">
  <div class="card-body">
    <table class="table table-hover mb-3">
      <tbody>
        <tr *ngFor="let d of data | orderBy: ['name','id']">
          <td>
            <span *ngIf="!d._edit" style="cursor: move"> {{d.name}} </span>
            <input
              type="text"
              maxlength="50"
              class="form-control"
              [(ngModel)]="d._edit.name"
              *ngIf="d._edit" />
          </td>
          <td class="cdi-s">
            <span
              class="cdi-s-edit pointer"
              *ngIf="!d._edit"
              (click)="editGroup(d)"></span>
            <span
              class="cdi-s-save pointer"
              *ngIf="d._edit"
              (click)="saveGroup(d)"></span>
          </td>
          <td class="cdi-s">
            <delete-modal
              *ngIf="!d._edit && d._can_delete"
              [deleteObject]="d"
              [name]="d.name"
              (onDelete)="delete(d)">
              <span class="cdi-s-trash pointer"></span>
            </delete-modal>
            <span
              *ngIf="!d._edit && !d._can_delete"
              class="cdi-s-no-trash"></span>
            <span
              class="cdi-s-cancel pointer"
              *ngIf="d._edit"
              (click)="cancelGroup(d)"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="data && data.length>0">
  Groups are used solely for filtering the vendor list view.<br />
  Groups with vendors cannot be deleted.
</div>

<div class="row">
  <div class="col-sm-12">
    <a routerLink="/contacts/" class="btn btn-secondary float-right">Back</a>
  </div>
</div>
