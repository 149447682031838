<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Profit &amp; Loss
          <button
            type="button"
            class="close d-print-none"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <!-- table interior lines override border, so border has to be at bottom line of table-->

      <div class="modal-body" *ngIf="loaded">
        <b> <u>{{ start | date: 'medium' }} -{{ end | date: 'medium' }}</u> </b>

        <table class="table table-sm mb-0">
          <b>Sales</b>
          <tr *ngIf="daily">
            <td class="pl-3">Daily</td>
            <td class="text-right" style="width: 17%">
              {{salesTotal[0] | currency: 'USD'}}
            </td>
            <td
              *ngIf="salesTotal[0]>0"
              class="text-right"
              style="width: 17%; color: green">
              100%
            </td>
          </tr>

          <tr *ngIf="!daily">
            <td class="pl-3">Breakfast</td>
            <td class="text-right" style="width: 17%">
              {{salesTotal[1] | currency: 'USD'}}
            </td>
            <td
              *ngIf="salesTotal[0]>0"
              class="text-right"
              style="width: 17%; color: green">
              {{ (salesTotal[1]/salesTotal[0]) | percent: '1.2-2' }}
            </td>
          </tr>
          <tr *ngIf="!daily">
            <td class="pl-3">Lunch</td>
            <td class="text-right" style="width: 17%">
              {{salesTotal[2] | currency: 'USD'}}
            </td>
            <td
              *ngIf="salesTotal[0]>0"
              class="text-right"
              style="width: 17%; color: green">
              {{ (salesTotal[2]/salesTotal[0]) | percent: '1.2-2' }}
            </td>
          </tr>
          <tr *ngIf="!daily">
            <td class="pl-3">Dinner</td>
            <td class="text-right" style="width: 17%">
              {{salesTotal[3] | currency: 'USD'}}
            </td>
            <td
              *ngIf="salesTotal[0]>0"
              class="text-right"
              style="width: 17%; color: green">
              {{ (salesTotal[3]/salesTotal[0]) | percent: '1.2-2' }}
            </td>
          </tr>
          <tr>
            <td class="text-right"><b>Total Sold / Gross Profit</b></td>
            <td class="text-right" style="width: 17%">
              <b>{{salesTotal[0]|currency: 'USD'}}</b>
            </td>
            <td
              class="text-right"
              *ngIf="salesTotal[0]>0"
              style="width: 17%; color: green">
              100.00%
            </td>
          </tr>
          <tr></tr>

          <tr>
            <td colspan="3"><b>Cost of Goods Sold</b></td>
          </tr>

          <tr
            *ngFor="let c of costData[ExpenseType.COGS].data | orderBy:'name'"
            class="border-dark border-bottom">
            <td class="pl-3">{{c.name}}</td>
            <td class="text-right" style="width: 17%">
              {{c.value|currency: 'USD'}}
            </td>
            <td
              class="text-right"
              *ngIf="salesTotal[0]>0"
              style="width: 17%; color: green">
              {{ (c.value/salesTotal[0]) | percent: '1.2-2' }}
            </td>
          </tr>

          <tr class="border-dark border-bottom">
            <td class="text-right"><b>Total Costs of Goods Sold</b></td>
            <td class="text-right" style="width: 17%">
              <b>{{costData[ExpenseType.COGS].value | currency: 'USD'}}</b>
            </td>
            <td
              *ngIf="salesTotal[0]>0"
              class="text-right"
              style="width: 17%; color: green">
              {{ (costData[ExpenseType.COGS].value / salesTotal[0]) | percent:
              '1.2-2' }}
            </td>
          </tr>

          <!--<tr>
						<td> <b>GROSS PROFIT</b> </td>
						<td class="text-right" style='width: 17%'>{{ salesTotal[0]-cTotal | currency: 'USD' }}</td>
						<td class="text-right" *ngIf="salesTotal[0]>0" style='width: 17%; color: green'>{{(salesTotal[0]-cTotal)/salesTotal[0] | percent: '1.2-2' }}</td>
					</tr>-->

          <tr>
            <td colspan="3"><b>Other Expenses</b></td>
          </tr>
          <tr
            *ngFor="let c of costData[ExpenseType.Fixed].data "
            class="border-dark border-bottom">
            <td class="pl-3">{{c.name}}</td>
            <td class="text-right" style="width: 17%">
              {{c.value|currency: 'USD'}}
            </td>
            <td
              class="text-right"
              *ngIf="salesTotal[0]>0"
              style="width: 17%; color: green">
              {{ (c.value/salesTotal[0]) | percent: '1.2-2' }}
            </td>
          </tr>

          <tr class="border-dark border-bottom">
            <td class="text-right"><b>Total Other Expenses</b></td>
            <td class="text-right" style="width: 17%">
              <b>{{costData[ExpenseType.Fixed].value| currency: 'USD'}}</b>
            </td>
            <td
              *ngIf="salesTotal[0]>0"
              class="text-right"
              style="width: 17%; color: green">
              {{ (costData[ExpenseType.Fixed].value/salesTotal[0]) | percent:
              '1.2-2' }}
            </td>
          </tr>

          <tr>
            <td colspan="3"><b>Labor</b></td>
          </tr>
          <tr *ngIf="getLocation().has_houses">
            <td>FOH Labor</td>
            <td class="text-right" style="width: 17%">
              {{ foh_labor | currency: 'USD' }}
            </td>
            <td
              class="text-right"
              *ngIf="salesTotal[0]>0"
              style="width: 17%; color: green">
              {{foh_labor/salesTotal[0] | percent: '1.2-2' }}
            </td>
          </tr>
          <tr *ngIf="getLocation().has_houses">
            <td>BOH Labor</td>
            <td class="text-right" style="width: 17%">
              {{ boh_labor | currency: 'USD' }}
            </td>
            <td
              class="text-right"
              *ngIf="salesTotal[0]>0"
              style="width: 17%; color: green">
              {{boh_labor/salesTotal[0] | percent: '1.2-2'}}
            </td>
          </tr>

          <tr>
            <td class="text-right"><b>Total Labor</b></td>
            <td class="text-right" style="width: 17%">
              <b>{{ foh_labor+boh_labor | currency: 'USD' }}</b>
            </td>
            <td
              class="text-right"
              *ngIf="salesTotal[0]>0"
              style="width: 17%; color: green">
              {{(foh_labor+boh_labor)/salesTotal[0] | percent: '1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="text-right"><b>TOTAL EXPENSE</b></td>
            <td class="text-right" style="width: 17%">
              <b
                >{{
                (costData[ExpenseType.COGS].value+costData[ExpenseType.Fixed].value+foh_labor+boh_labor)
                | currency: 'USD' }}</b
              >
            </td>
            <td
              class="text-right"
              *ngIf="salesTotal[0]>0"
              style="width: 17%; color: green">
              {{(costData[ExpenseType.COGS].value+costData[ExpenseType.Fixed].value+foh_labor+boh_labor)/salesTotal[0]
              | percent: '1.2-2'}}
            </td>
          </tr>
          <tr>
            <td class="text-right"><b>NET PROFIT</b></td>
            <td class="text-right" style="width: 17%">
              <b
                >{{
                salesTotal[0]-(costData[ExpenseType.COGS].value+costData[ExpenseType.Fixed].value+foh_labor+boh_labor)
                | currency: 'USD' }}</b
              >
            </td>
            <td
              class="text-right"
              *ngIf="salesTotal[0]>0"
              style="width: 17%; color: green">
              {{(salesTotal[0]-
              (costData[ExpenseType.COGS].value+costData[ExpenseType.Fixed].value+foh_labor+boh_labor))/salesTotal[0]
              | percent: '1.2-2'}}
            </td>
          </tr>
        </table>

        <div class="row mt-3 d-print-none">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="cancel()"></cancel-button>
            <button
              class="btn float-right btn-primary mx-2"
              (click)="print()"
              type="button">
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
