<div class="container-fluid nav-padding">
  <h3 class="text-center pt-3" style="width: 100%">
    <b>Search Results For {{searchString}}</b>
  </h3>
  <label>{{matchCount}} items found</label>
  <div class="card-columns">
    <div *ngIf="logs && logs.length>0">
      <div *ngFor="let t of LogNoteTypeKeys">
        <div class="card my-1" *ngIf="logs[t].length>0">
          <div class="card-header pb-0">{{ LogNoteNames[t] }}</div>
          <div class="card-body py-1" *ngFor="let log of logs[t]">
            <span class="mb-0" style="font-size: 1.2rem"
              ><b>{{log.date|date}}</b>
            </span>
            {{ ServiceTimeType[log.service_time_id] }} log by {{ log.firstname
            }} {{ log.lastname}}
            <div>{{log.note}}</div>
          </div>
        </div>
      </div>
    </div>

    <modal-event #modalEvent></modal-event>
    <div *ngIf="events && events.length>0">
      <div class="card my-1">
        <div class="card-header pb-0">Events</div>
        <div
          class="card-body py-0"
          *ngFor="let e of events | orderBy:'start_date'"
          (click)="isAllowed(Permission.EditEvent) && modalEvent.show(e)">
          <span class="mb-0" style="font-size: 1.2rem"><b>{{e.name}}</b> </span>
          - {{e.start_date | date }}
          <div class="mb-2">{{e.notes}}</div>
        </div>
      </div>
    </div>

    <modal-task #modalTask></modal-task>
    <div *ngIf="tasks && tasks.length>0">
      <div class="card my-1">
        <div class="card-header pb-0">Tasks</div>
        <div
          class="card-body pt-0"
          *ngFor="let e of tasks | orderBy:'start_date'"
          (click)="isAllowed(Permission.EditTask) && modalTask.show(e)">
          <span class="mb-0" style="font-size: 1.2rem"><b>{{e.name}}</b></span>
          - {{e.start_date | date }}
          <div class="mb-2">{{e.notes}}</div>
        </div>
      </div>
    </div>

    <div *ngIf="contacts && contacts.length>0">
      <div class="card my-1">
        <div class="card-header pb-0">Vendors <small> link </small></div>
        <div class="card-body pt-0">
          <div class="row" *ngFor="let c of contacts | orderBy:'company'">
            <div
              class="col pointer mb-2"
              *ngIf="!isAllowed(Permission.EditVendor)">
              <b>Company: </b> {{c.company}} - <phone [phone]="c.phone"></phone
              ><br />
              <b>Contact: </b> {{c.name}} - <phone [phone]="c.cell"></phone
              ><br />
              <b>Tags: </b> {{c.tags}}<br />
              <b>Notes:</b> {{c.notes}}
            </div>
            <div
              class="col pointer mb-2"
              *ngIf="isAllowed(Permission.EditVendor)"
              [routerLink]="['/contacts/vendor/view',c.id]">
              <b>Company: </b> {{c.company}} - <phone [phone]="c.phone"></phone
              ><br />
              <b>Contact: </b> {{c.name}} - <phone [phone]="c.cell"></phone
              ><br />
              <b>Tags: </b> {{c.tags}}<br />
              <b>Notes:</b> {{c.notes}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="invoices && invoices.length>0">
      <div class="card my-1">
        <div class="card-header pb-0">Invoices <small> link </small></div>
        <div class="card-body pt-0">
          <!--			<div class='row' *ngIf="invoices.length==0">
						<div class='col-12'>No invoice match.</div>
					</div> -->
          <div class="row" *ngFor="let i of invoices | orderBy:'-date'">
            <div
              class="col pointer mb-2"
              [routerLink]="['/contacts/vendor/invoices',i.contact_id,i.id]">
              <b>Company: </b> {{i.company}} - {{i.date | date}}<br />
              <b>Invoice # </b>{{i.ponumber}} - <b>Amount: </b> {{i.amount |
              currency: 'USD'}}<br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  Search covers the following

  <div class="row">
    <div class="col">
      <ul class="list-unstyled mb-0">
        <li>Log Notes</li>
        <ul>
          <li>
            General Notes,&nbsp; Feedback Complaints &amp; Customer
            Issues,&nbsp; Employee Issues,&nbsp; Repair &amp; Maintenace
          </li>
        </ul>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <ul class="list-unstyled">
        <li>Events</li>
        <ul>
          <li>name,&nbsp; description</li>
        </ul>
      </ul>
    </div>
    <div class="col-3">
      <ul class="list-unstyled">
        <li>Tasks</li>
        <ul>
          <li>name,&nbsp; description</li>
        </ul>
      </ul>
    </div>
    <div class="col-3">
      <ul class="list-unstyled">
        <li>Vendors</li>
        <ul>
          <li>company,&nbsp; contact,&nbsp; notes,&nbsp; tags</li>
        </ul>
      </ul>
    </div>
    <div class="col-3">
      <ul class="list-unstyled">
        <li>Invoice</li>
        <ul>
          <li>number</li>
        </ul>
      </ul>
    </div>
  </div>
</div>
