import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardFreemium, AuthGuardJail } from '../app/auth-guard';
import { CalendarComponent } from './calendar.component';
import { HomeComponent } from './home.component';

const appRoutes: Routes = [
  {
    path: 'calendar',
    canActivate: [AuthGuard, AuthGuardFreemium, AuthGuardJail],
    component: CalendarComponent,
    children: [{ path: '', component: HomeComponent }],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
