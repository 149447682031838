import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardJail } from '../app/auth-guard';
import { InboxComponent } from './inbox.component';
import { MessagesComponent } from './messages.component';
import { SentComponent } from './sent.component';

const appRoutes: Routes = [
  {
    path: 'messages',
    canActivate: [AuthGuard, AuthGuardJail],
    component: MessagesComponent,
    children: [
      { path: '', component: InboxComponent },
      { path: 'sent', component: SentComponent },
      { path: 'archive', component: InboxComponent, data: { type: 'archive' } },
      { path: 'wall', component: InboxComponent, data: { type: 'wall' } },
      { path: ':id', component: InboxComponent },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
