import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { LocationService, ILocation } from '../services/location.service';
import { TimezoneService } from '../services/timezone.service';
import { BillingPlanService } from '../services/billingPlan.service';
import {
  Countries,
  LocationType,
  LocationTypeName,
  LocationCategoryName,
  ServiceTimeType,
  moment,
  ILocationCreate,
  IFreeTrialConfig,
} from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { environment } from 'environments/environment';

@Component({
  selector: 'location-add',
  templateUrl: 'locationAdd.html',
})
export class LocationAddComponent extends baseRole implements OnInit {
  @Output() onComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() email: string;
  @Input() password: string;
  @Input() user_id: number;

  public data: ILocationCreate;
  public code: string;
  public error: string = null;
  public LocationType = LocationType;
  public LocationTypeKeys = Object.keys(LocationType).filter(Number);
  public Countries = Countries;
  public CountryKeys = Object.keys(Countries);
  public LocationTypeName = LocationTypeName;
  public LocationCategoryName = LocationCategoryName;
  public needsDocs: false;
  public toc: false;
  //public proRate: number;
  public saving = false;
  public timezones = [];
  public billingPlan = null;
  public defaultPlan;

  constructor(
    protected route: ActivatedRoute,
    public accountService: AccountService,
    public billingPlanService: BillingPlanService,
    public timezoneService: TimezoneService,
    public locationService: LocationService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.billingPlanService.getDefault().subscribe(data => {
      this.defaultPlan = data;
      if (!this.billingPlan) {
        this.billingPlan = this.defaultPlan;
        if (this.defaultPlan) {
          this.data.billing_plan_id = this.defaultPlan.id;
        }
      }
    });

    this.data = {
      email: this.email,
      password: this.password,
      user_id: this.user_id,
      billing_plan_id: null,
      name: null,
      location_type: LocationType.FastCasual,
      location_category: LocationCategoryName[LocationType.FastCasual][0],
      is_service_time_daily: true,
      week_start: 0,
      payroll_day: 0,
      has_houses: false,
      has_tip_reporting: true,
      unlimited_texts: false,
      contact_method: 0,
      timezone_id: 4,
      zip: null,
      country: 'US',
      freeTrialConfig: environment.freeTrial,
      /*require_w4: false,
			require_i9: false,*/
    };

    this.timezoneService.list().subscribe(data => {
      this.timezones = data;
    });
  }

  changeDocs() {
    //		this.data.require_w4 = this.needsDocs;
    //		this.data.require_i9 = this.needsDocs;
  }

  cancel() {
    this.onComplete.emit(false);
  }

  save() {
    this.data.billing_plan_id = this.billingPlan.id;
    this.data.email = this.email;
    this.data.password = this.password;
    this.saving = true;
    this.locationService.create(this.data).subscribe(
      data => {
        if (this.accountService.getUser()) {
          this.accountService.reloadPerms().subscribe(result => {
            this.accountService.setLocation(data.id).subscribe(() => {
              this.saving = false;
              this.onComplete.emit(true);
            });
          });
        } else {
          this.saving = false;
          this.onComplete.emit(true);
        }
      },
      err => {
        console.log('Error?');
        console.log(err);
        this.saving = false;
        this.error =
          err.__message ||
          'There was an error creating your business. Please contact customer support.';
      }
    );
  }
}
