<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Time Clock Edit"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content" *ngIf="data">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Edit Timecard
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <form ngNativeValidate (submit)="save()">
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <label
                >Edit Timecard for {{data.firstname}} {{data.lastname}}</label
              >
              <div *ngIf="user">
                <div class="d-flex flex-wrap flex-row justify-content-around">
                  <div>
                    <label>Position</label>
                    <select
                      name="employee_position_id"
                      [(ngModel)]="data.employee_position_id"
                      class="form-control d-inline-block override-invalid"
                      [disabled]="!user.positions || user.positions.length==0"
                      required>
                      <option
                        *ngFor="let p of user.positions"
                        [value]="p.employee_position_id">
                        {{p.name}}
                      </option>
                    </select>
                  </div>
                  <div>
                    <label> Clock In </label>
                    <input
                      type="text"
                      class="form-control input-calendar"
                      bsDatepicker
                      name="start_date"
                      [(ngModel)]="data.start"
                      required />
                    <timepicker
                      name="start"
                      [(ngModel)]="data.start"
                      required></timepicker>
                  </div>
                  <div>
                    <label>Clock Out </label>
                    <input
                      type="text"
                      class="form-control input-calendar"
                      bsDatepicker
                      name="end_date"
                      [(ngModel)]="data.end"
                      required />
                    <timepicker
                      name="end"
                      [(ngModel)]="data.end"
                      required></timepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <save-button class="float-right"></save-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
