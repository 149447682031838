import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { loadStripe } from '@stripe/stripe-js';
import { BaseService } from './base.service';
import { AccountService } from './account.service';
import { Stripe } from '@stripe/stripe-js';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseService<any> {
  stripePromise: Promise<Stripe | null> = loadStripe(
    environment.stripePublishableKey
  );
  constructor(
    protected accountService: AccountService,
    protected http: HttpClient
  ) {
    super(accountService, http, 'payment');
  }

  subscribeCustomer(url, type, customer_id, location_id) {
    return this.http
      .post(this.baseUrl + 'subscription/create/session', {
        url: url,
        type: type,
        customer_id: customer_id,
        location_id: location_id,
      })
      .pipe(
        tap(async (session: any) => {
          const stripe = await this.stripePromise;
          stripe.redirectToCheckout({
            sessionId: session.id,
          });
        })
      );
  }

  subscribeUpdateCustomer(url, type, price_id, customer_id) {
    return this.http.post(this.baseUrl + 'subscription/create', {
      url: url,
      type: type,
      price_id: price_id,
      customer_id: customer_id,
    });
  }

  cancelSubscription(subscription_id, cancel_at_period_end) {
    return this.http.post(this.baseUrl + 'subscription/cancel', {
      subscription_id: subscription_id,
      cancel_at_period_end: cancel_at_period_end,
    });
  }

  findOrCreateCustomer(location_id: number) {
    return this.http.post(this.baseUrl + 'customer/create', {
      location_id: location_id,
    });
  }

  findCustomer(location_id: number) {
    return this.http.post(this.baseUrl + 'customer/find', {
      location_id: location_id,
    });
  }

  getCustomerInvoices(location_id: string, cursor: string = null) {
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('location_id', location_id);
    if (cursor) {
      queryParameters = queryParameters.set('cursor', cursor);
    }
    queryParameters = queryParameters.set('limit', '5');
    return this.http.get(this.baseUrl + 'customer/invoices', {
      params: queryParameters,
    });
  }

  getCustomerPaymentIntents(location_id: string, cursor: string = null) {
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('location_id', location_id);
    if (cursor) {
      queryParameters = queryParameters.set('cursor', cursor);
    }
    queryParameters = queryParameters.set('limit', '5');
    return this.http.get(this.baseUrl + 'customer/paymentIntents', {
      params: queryParameters,
    });
  }

  removeStripeSession(stripeSessionId, location_id) {
    return this.http.post(this.baseUrl + 'session/remove', {
      location_id: location_id,
      stripe_session_id: stripeSessionId,
    });
  }

  createStripePortalSession(url, location_id) {
    return this.http.post(this.baseUrl + 'session/create/portal', {
      location_id: location_id,
      url: url,
    });
  }

  createPaymentMethod(location_id) {
    return this.http.post(this.baseUrl + 'create', {
      location_id: location_id,
    });
  }

  getPaymentStatus(location_id) {
    return this.http.post(this.baseUrl + 'status', {
      location_id: location_id,
    });
  }

  getPricing() {
    return this.http.get(this.baseUrl + 'pricing');
  }

  getProduct(product_id) {
    return this.http.post(this.baseUrl + 'product', {
      product_id: product_id,
    });
  }
  getCoupon() {
    return this.http.get(this.baseUrl + 'coupon');
  }
}
