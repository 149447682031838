<div class="row" *ngIf="data && data.length>0">
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body">
        <div class="row mb-2">
          <label class="col-xl-4 col-md-12 col-sm-6 col-4">Task list</label>
          <div class="col-xl-4 col-md-6 col-sm-3 col-4">
            <div class="btn-group-toggle">
              <label class="btn btn-radio" [class.active]="!selectMine">
                <input
                  type="radio"
                  name="options"
                  [value]="false"
                  [(ngModel)]="selectMine"
                  (ngModelChange)="filter()" />All
              </label>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 col-sm-3 col-4">
            <div class="btn-group-toggle">
              <label class="btn btn-radio" [class.active]="selectMine">
                <input
                  type="radio"
                  name="options"
                  [value]="true"
                  [(ngModel)]="selectMine"
                  (ngModelChange)="filter()" />
                Mine
              </label>
            </div>
          </div>
        </div>

        <div class="inline-block" *ngIf="tasks && tasks.length>0">
          <ng-container *ngFor="let t of tasks">
            <div class="row">
              <div class="col-12">
                <button
                  type="button"
                  class="btn btn-task btn-block mt-0"
                  style="text-align: left !important"
                  (click)="showTask(t)">
                  <div class="d-flex justify-content-between">
                    <span
                      >{{ t.name }}<br />
                      DUE: {{t.end_date | date}}
                    </span>

                    <delete-modal
                      [name]="t.name"
                      [deleteObject]="t"
                      (onDelete)="deleteTask(t)">
                      <span class="cdi-s-trash pointer mt-3"></span>
                    </delete-modal>
                  </div>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="repairs && repairs.length>0">
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body">
        <label>repair list</label>
        <div class="inline-block">
          <ng-container *ngFor="let t of repairs">
            <div class="row">
              <div class="col-11">
                <button
                  type="button"
                  class="btn btn-task btn-block mt-0 mb-1"
                  style="text-align: left !important"
                  (click)="showTask(t)">
                  <span class="text-left"
                    >{{ t.name }}<br />
                    DUE: {{t.start_date | date}}</span
                  >
                </button>
              </div>
              <div class="col=1">
                <delete-modal
                  *ngIf="isAllowed(Permission.EditTask)"
                  [name]="t.name"
                  [deleteObject]="t"
                  (onDelete)="deleteTask(t)">
                  <span class="cdi-s-trash pointer mt-3"></span>
                </delete-modal>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<modal-task #modalTask></modal-task>
