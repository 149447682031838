import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IContact } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ContactService extends BaseService<IContact> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'contact');
  }

  adminBulkImport(location_id: number, data: any[]): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + location_id + '/bulkImport',
      data,
      this.defaultOptions
    );
  }
}

export { IContact } from '../shared/api';
