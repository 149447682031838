import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';

import { EmployeePositionService } from '../services/employeePosition.service';
import { LogService } from '../services/log.service';
import { LogNoteService } from '../services/logNote.service';
import { LogImageService } from '../services/logImage.service';
import { InvoiceService } from '../services/invoice.service';
import { TimeClockService } from '../services/timeClock.service';

import { InvoicesComponent } from './invoices.component';
import { LogComponent } from './log.component';
import { LogAddComponent } from './logAdd.component';
import { SummaryComponent } from './summary.component';
import { TimecardsComponent } from './timecards.component';

import { routing } from './log.routing';

@NgModule({
  declarations: [
    InvoicesComponent,
    LogComponent,
    LogAddComponent,
    SummaryComponent,
    TimecardsComponent,
  ],
  imports: [SharedModule, routing],
  providers: [
    EmployeePositionService,
    LogService,
    LogNoteService,
    LogImageService,
    InvoiceService,
    TimeClockService,
  ],
})
export class LogModule {}
