<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Notifiy unverified employees
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" *ngIf="data && data.length>0">
        <label>
          Emails and/or Texts will be sent to these unverified employees</label
        >
        <div class="card mt-0">
          <div class="card-body">
            <table class="table mb-0">
              <tr *ngFor="let d of data">
                <td>{{d.firstname}} {{d.lastname}}</td>
                <td>{{d.email}}</td>
                <td>{{d.cell | phone}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="!data || data.length==0" class="mx-5">
          There are no unverified employees.
        </div>

        <div class="row" *ngIf="saving">
          <div class="col-12">
            <alert type="success">Sending notifications</alert>
          </div>
        </div>

        <div class="row" *ngIf="!saving">
          <div class="col-12 mb-2 mt-0">
            <cancel-button
              class="float-right mx-3"
              (click)="cancel()"></cancel-button>
            <button
              class="btn float-right btn-primary"
              *ngIf="data"
              type="button"
              (click)="save()">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
