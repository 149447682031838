<!--<modal-doc-edit #modalDocEdit></modal-doc-edit>-->
<div class="nav-padding"></div>
<div *ngIf="data">
  <div class="row">
    <div class="d-flex justify-content-around" *ngFor="let d of data">
      <div class="card my-1 mx-2" style="width: 12.8rem">
        <div class="card-body">
          <doc-view
            [canEdit]="true"
            [doc]="d"
            (onDelete)="deleteDoc(d)"></doc-view>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div *ngIf="data">
	<div class="row">
		<div class='col' *ngFor="let d of data">
			<div class='card my-1' style='width: 12.8rem'>
				<div class='card-body'>
					<doc-view [canEdit]="true" [doc]="d" (onDelete)="deleteDoc(d)"></doc-view>
				</div>
			</div>
		</div>
	</div>
</div> -->
