import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { AccountService } from '../services/account.service';
import { ContactGroupService } from '../services/contactGroup.service';
import { ContactService } from '../services/contact.service';
import { InvoiceItemService } from '../services/invoiceItem.service';

import { ContactsComponent } from './contacts.component';
import { HomeComponent } from './home.component';
import { GroupListComponent } from './group.component';
import { IconsModule } from '../app/icons/icons.module';
import {
  InvoiceListComponent,
  InvoiceViewComponent,
} from './invoice.component';
import { VendorEditComponent, VendorViewComponent } from './vendor.component';

import { routing } from './contacts.routing';

@NgModule({
  declarations: [
    ContactsComponent,
    HomeComponent,
    GroupListComponent,
    InvoiceListComponent,
    InvoiceViewComponent,
    VendorEditComponent,
    VendorViewComponent,
  ],
  imports: [SharedModule, routing, IconsModule],
  providers: [ContactService, ContactGroupService, InvoiceItemService],
})
export class ContactsModule {}
