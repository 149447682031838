import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AccountService, IApiUser } from '../services/account.service';
import { ContactService, IContact } from '../services/contact.service';
import { InvoiceService, IInvoice } from '../services/invoice.service';
import {
  InvoiceItemService,
  IInvoiceItem,
} from '../services/invoiceItem.service';
import { baseRole } from '../shared/baseRole';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';

@Component({
  templateUrl: 'invoice.list.html',
})
export class InvoiceListComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public contact_id: number;
  public data: any[];
  public total: number;
  public sub;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public accountService: AccountService,
    public invoiceService: InvoiceService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.contact_id = params['id'];
      this.sub = this.invoiceService.getUpdateObservable().subscribe(() => {
        this.invoiceService
          .list({ contact_id: this.contact_id })
          .subscribe(data => {
            this.data = data;
            this.total = 0;
            for (let d of this.data) {
              this.total += Number(d.amount);
            }
          });
      });
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}

@Component({
  templateUrl: 'invoice.view.html',
})
export class InvoiceViewComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public contact_id: number;
  public invoice: IInvoice;
  public data: any[];
  public contact: IContact;
  public total: number;
  public sub;

  @ViewChild('modalInvoice')
  public modal;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public accountService: AccountService,
    public contactService: ContactService,
    public invoiceService: InvoiceService,
    public invoiceItemService: InvoiceItemService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let contact_id = params['id'];
      let invoice_id = params['invoice_id'];
      if (!this.sub) {
        this.sub = this.invoiceService.getUpdateObservable().subscribe(() => {
          this.contactService.get(contact_id).subscribe(data => {
            this.contact = data;
          });

          this.invoiceService.get(invoice_id).subscribe(data => {
            this.invoice = data;
          });
          this.invoiceItemService
            .list({ invoice_id: invoice_id })
            .subscribe(data => {
              this.data = data;
              this.total = 0;
              for (let d of this.data) {
                this.total += Number(d.amount);
              }
            });
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  edit() {
    let obj: any = Object.assign({}, this.invoice);
    obj.company = this.contact.company;
    this.modal.show(obj);
  }
}
