import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  AccountService,
  IApiUser,
  SelectAddAll,
} from '../services/account.service';
import {
  FixedCostService,
  IFixedCost,
  FixedCostPeriod,
} from '../services/fixedCost.service';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
import { baseRole } from '../shared/baseRole';
import { StringSort } from '../shared/api';

@Component({
  selector: 'modal-fixed-cost',
  templateUrl: 'modalFixedCost.html',
})
export class ModalFixedCostComponent extends baseRole implements OnInit {
  public data: IFixedCost;
  public origData: IFixedCost;
  public expenseAccounts: IExpenseAccount[];
  public FixedCostPeriod = FixedCostPeriod;
  public FixedCostPeriodKeys = Object.keys(FixedCostPeriod).filter(Number);
  @ViewChild('modal')
  public modal: ModalDirective;

  @Output() onNew: EventEmitter<IFixedCost> = new EventEmitter();

  constructor(
    public accountService: AccountService,
    public expenseAccountService: ExpenseAccountService,
    public fixedCostService: FixedCostService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  show(data: IFixedCost = null) {
    if (!data) {
      this.data = {
        period: FixedCostPeriod.Daily,
      } as IFixedCost;
    } else {
      this.data = Object.assign({}, data);
    }
    //	console.log(data);
    this.origData = data;
    this.expenseAccountService.list().subscribe(data => {
      StringSort(data);
      this.expenseAccounts = data.filter(e => {
        return e.expense_type == 4;
      });
      this.expenseAccounts = SelectAddAll(
        this.expenseAccounts,
        'Select an Expense Category'
      );
      if (!this.data.id) {
        //				this.data.expense_account_id = data[0].id;
        this.data.expense_account_id = this.expenseAccounts[0].id;
      }
      this.modal.show();
    });
  }

  save() {
    this.fixedCostService.update(this.data).subscribe(data => {
      if (!this.data.id) {
        this.onNew.emit(data);
      } else {
        Object.assign(this.origData, this.data);
      }
      this.modal.hide();
    });
  }

  cancel() {
    this.origData = null;
    this.modal.hide();
  }
}
