import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { ShiftService, IShift } from '../services/shift.service';
import { ShiftTradeService, IShiftTrade } from '../services/shiftTrade.service';
import { UserService } from '../services/user.service';
import { baseRole } from '../shared/baseRole';
import { moment, TradeType, TradeTypeNames } from '../shared/api';

@Component({
  selector: 'modal-trade-board',
  templateUrl: 'modalTradeBoard.html',
})
export class ModalTradeBoardComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;
  public data;
  public TradeType = TradeType;
  public TradeTypeNames = TradeTypeNames;
  public showAll: boolean;
  public ready = false;

  constructor(
    public accountService: AccountService,
    public shiftTradeService: ShiftTradeService,
    public userService: UserService,
    public shiftService: ShiftService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  dateFormat(date: Date): string {
    return moment(date).format('ddd Do h:mm a');
  }

  load() {
    this.ready = false;
    this.shiftTradeService.list().subscribe((data: any[]) => {
      this.data = data;
      let end = new Date();
      for (let d of data) {
        if (d.end > end) end = new Date(d.end);
      }
      //console.log('Pre Show All');
      //console.log(this.data);
      if (!this.showAll) {
        this.data = this.data.filter(e => {
          return (
            e.user_id == this.getUserId() ||
            e.trade_user_id == this.getUserId() ||
            (e.is_available &&
              (!e.conflict_start || e.trade_type != TradeType.GiveUp))
          );
        });
      }
      //console.log('After showAll');
      //console.log(this.data);

      this.shiftService
        .list({ start: new Date(), published: true, user_id: this.getUserId() })
        .subscribe(shifts => {
          for (let d of data) {
            d.conflicts = [];
            d.available = [];
            let range = moment.range(d.start, d.end);
            for (let s of shifts) {
              if (s.id == d.shift_id) continue; // Matched my own?
              if (s.position_id != d.position_id) continue; // No trading between positions
              let srange = moment.range(s.start, s.end);
              if (range.overlaps(srange)) {
                d.conflicts.push(s);
              } else {
                d.available.push(s);
              }
            }
          }
          //console.log('Post Filter');
          //console.log(this.data);
          this.ready = true;
          this.modal.show();
        });
    });
  }

  show(showAll = false) {
    this.showAll = showAll && this.isAllowed(this.Permission.ApproveTrade);
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  approveTrade(trade) {
    let updateObj: any = {
      id: trade.id,
      original_approval: new Date(),
      trade_user_id: this.getUserId(),
    };
    this.shiftTradeService.update(updateObj).subscribe(() => {
      this.load();
    });
  }

  selectTrade(trade) {
    trade.error = null;
    if (trade.trade_type == TradeType.Trade) {
      let s = trade.available.filter(e => {
        return e._selected;
      });
      if (s.length == 1) {
        let newShift = s[0];
        this.shiftTradeService
          .update({
            id: trade.id,
            trade_user_id: this.getUserId(),
            trade_shift_id: newShift.id,
            original_approval: new Date(),
          })
          .subscribe(data => {
            this.load();
          });
      }
    } else if (trade.trade_type == TradeType.Pickup) {
      if (!trade.selectedTrade) {
        trade.error = 'Please select a shift to trade.';
        return;
      }
      this.shiftTradeService
        .update({
          id: trade.id,
          trade_user_id: this.getUserId(),
          trade_shift_id: trade.selectedTrade.id,
          original_approval: new Date(),
        })
        .subscribe(data => {
          this.load();
        });
    } else {
      this.shiftTradeService
        .update({
          id: trade.id,
          trade_user_id: this.getUserId(),
          original_approval: new Date(),
        })
        .subscribe(data => {
          this.load();
        });
    }
  }

  cancelTrade(trade) {
    // Owner
    if (
      trade.user_id == this.getUserId() ||
      trade.trade_type == TradeType.Trade
    ) {
      this.shiftTradeService.delete(trade.id).subscribe(() => {
        this.load();
      });
    }
    // Traded to..
    else {
      this.shiftTradeService
        .update({
          id: trade.id,
          original_approval: null,
          trade_shift_id: null,
          trade_user_id: null,
        })
        .subscribe(() => {
          this.load();
        });
    }
  }

  save() {
    this.modal.hide();
  }
}
