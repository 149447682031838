import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ISubNavItem } from '../shared/api';

@Component({
  templateUrl: './test.html',
})
export class TestComponent implements OnInit {
  public menu: ISubNavItem[] = [
    {
      label: 'Layout',
      routerLink: '/admin/test',
      exact: true,
    },
    {
      label: 'Actions',
      routerLink: '/admin/test/ui',
    },
    {
      label: 'Icons',
      routerLink: '/admin/test/icon',
    },
  ];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {}

  ngOnInit() {}
}
