import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { SkuLocationService } from '../services/skuLocation.service';
import { IInventoryLocation } from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'modal-inventory-order',
  templateUrl: 'modalInventoryOrder.html',
})
export class ModalInventoryOrderComponent extends baseRole implements OnInit {
  public sub;
  public location: IInventoryLocation;
  public data: any[];
  @ViewChild('modal')
  public modal: ModalDirective;

  constructor(
    public accountService: AccountService,
    public dragulaService: DragulaService,
    public skuLocationService: SkuLocationService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.data = [];
    this.setupDragNDrop();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    this.dragulaService.destroy('inventory');
  }

  setupDragNDrop() {
    this.dragulaService.createGroup('inventory', {
      direction: 'vertical',
    });

    if (!this.sub) {
      this.sub = this.dragulaService.drop('inventory').subscribe(value => {
        var myId = value.el.getAttribute('data-id');

        let i = 0;
        for (i = 0; i < this.data.length; i++) {
          if (this.data[i].sku_location_id == myId) break;
        }
        if (i == this.data.length) {
          return; // No idea what happened..
        }

        let me = this.data[i];

        if (value.sibling) {
          // Was inserted before someone...
          var targetId = value.sibling.getAttribute('data-id');
          let j = 0;
          for (j = 0; j < this.data.length; j++) {
            if (this.data[j].sku_location_id == targetId) break;
          }

          if (j == this.data.length) return; // No idea what happened..
          // Carefull when splicing the array you don't delete before
          // the index you want the second insert into.
          if (i < j) {
            this.data.splice(j, 0, me);
            this.data.splice(i, 1);
          } else {
            this.data.splice(i, 1);
            this.data.splice(j, 0, me);
          }
        } else {
          this.data.splice(i, 1);
          this.data.push(me);
        }

        let updateObjs: any[] = [];
        for (i = 0; i < this.data.length; i++) {
          if (this.data[i].s_order_by != i) {
            updateObjs.push({ id: this.data[i].sku_location_id, order_by: i });
          }
          this.data[i].s_order_by = i;
        }
        this.skuLocationService.reOrder(updateObjs).subscribe(data => {});
      });
    }
  }

  // This is based on an ISkuLocation
  show(loc: IInventoryLocation, data: any[]) {
    this.location = loc;
    this.data = data;
    this.modal.show();
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    this.modal.hide();
  }
}
