import { NgModule } from '@angular/core';
import { IconsComponent } from './icons.component';
import * as Icons from './icons';
import { IconsRegistry } from './icons.service';

@NgModule({
  declarations: [IconsComponent],
  imports: [],
  exports: [IconsComponent],
})
export class IconsModule {
  constructor(private iconRegistry: IconsRegistry) {
    this.iconRegistry.registerIcons(Object.values(Icons.IconObjects));
  }
}
