<div *ngIf="userMap">
  <div class="card">
    <div class="card-body">
      <label>Dashboard Graphs</label>
      <div class="row" style="font-size: 10px">
        <div class="col-md-3 col-sm-12 mb-2" *ngFor="let k of WidgetTypeKeys">
          <input-toggle
            [ngModel]="userMap.widgets.indexOf(k)>=0"
            (click)="toggleWidget(k)"
            >{{WidgetNames[k]}}</input-toggle
          >
        </div>
      </div>
    </div>
  </div>
</div>
