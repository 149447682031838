import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IShift, moment } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ShiftService extends BaseService<IShift> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'shift');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'start');
          this.fixDate(d, 'end');
        }
      } else {
        this.fixDate(data, 'start');
        this.fixDate(data, 'end');
      }
      return data;
    };
  }

  getPdfScheduleUrl(data: {
    location_id: number;
    start: Date;
    filter: string;
  }): string {
    let url =
      this.baseUrl +
      'pdf?location_id=' +
      data.location_id +
      '&start=' +
      moment(data.start).format('YYYY-MM-DD');
    if (data.filter) url += '&filter=' + encodeURIComponent(data.filter);
    return url;
  }
}

export { IShift } from '../shared/api';
