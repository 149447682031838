<div class="row authentication" *ngIf="step==1">
  <div class="col-md-4 col-10 text-center card">
    <form
      id="register"
      (ngSubmit)="register()"
      class="card-body"
      ngNativeValidate>
      <div style="width: 100%" class="my-4">
        <span class="cdi-l-logo cdi-biggest mx-auto"></span>
      </div>
      <p class="text-center">
        Please enter some information about yourself so we can get started.
      </p>
      <div class="text-center pb-3">Step 1 of 3</div>

      <input
        class="form-control"
        type="text"
        placeholder="Firstname"
        name="firstname"
        [(ngModel)]="user.firstname"
        required />
      <input
        class="form-control"
        type="text"
        placeholder="Lastname"
        name="lastname"
        [(ngModel)]="user.lastname"
        required />
      <input
        class="form-control"
        type="email"
        placeholder="Email"
        debounce
        [delay]="500"
        (result)="checkUsername($event)"
        name="email"
        [(ngModel)]="user.email"
        required />
      <local-error [error]="error.email"></local-error>
      <input
        class="form-control"
        type="password"
        placeholder="Password"
        name="password"
        [(ngModel)]="user.password"
        (ngModelChange)="checkPassword()"
        required />
      <input
        class="form-control"
        type="password"
        placeholder="Confirm Password"
        name="cpassword"
        [(ngModel)]="user.cpassword"
        debounce
        [delay]="500"
        (result)="matchPassword()"
        required />
      <local-error [error]="error.matchPassword"></local-error>
      <local-error [error]="warning"></local-error>
      <local-error-array [errors]="suggestions"></local-error-array>

      <!--<input class="form-control" type="text" bsDatepicker placeholder="Date Of Birth (YYYY-MM-DD)" name="dob" [(ngModel)]="user.dob" required>-->
      <p>
        <input type="checkbox" name="tnc" value="1" [(ngModel)]="tnc" /> I agree
        to the
        <a href="https://www.cheddrsuite.com/terms" target="_blank"
          >Terms &amp; Conditions</a
        >
        and
        <a href="https://www.cheddrsuite.com/terms" target="_blank"
          >Privacy Policy</a
        >
      </p>
      <button
        class="btn btn-primary mx-2"
        [disabled]="!(tnc && user.email && user.password && user.firstname && user.lastname && !error?.username)">
        Sign up
      </button>
      <local-error
        [error]="error.global"
        style="margin-top: 1rem"></local-error>
      <div>
        <p>Already have an account? <a routerLink="/login">Sign In</a></p>
      </div>
      <p class="text-center" *ngIf="freeTrialConfig.enabled">
        You will be set up with a {{freeTrialConfig.days}} day free trial of
        Cheddrsuite Pro and unlimited text messaging. Upgrade at any time by
        going to Settings &gt; Account.
      </p>
    </form>
  </div>
</div>

<div class="row justify-content-center" *ngIf="step==2">
  <div class="col-md-8 col-10 mx-auto card">
    <location-add
      (onComplete)="payment()"
      [email]="user.email"
      [password]="user.password"
      [user_id]="user.id">
    </location-add>
  </div>
</div>

<div class="row authentication" *ngIf="step==3">
  <div class="col-md-8 col-12 text-center">
    <card-account-packages></card-account-packages>
  </div>
</div>
