import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IServiceTime } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ServiceTimeService extends BaseService<IServiceTime> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'serviceTime');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'start');
          this.fixDate(d, 'end');
        }
      } else {
        this.fixDate(data, 'start');
        this.fixDate(data, 'end');
      }
      return data;
    };
  }

  // Needs a start and end on it
  getDailyTotals(filter?: any): Observable<any[]> {
    let queryParameters = new HttpParams();
    //let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    //userFilter.updateQuery(queryParameters);
    return this.http
      .get<any[]>(this.baseUrl + 'dailyTotals', { params: queryParameters })
      .pipe(
        map(data => {
          for (let d of data) {
            this.fixDate(d, 'date');
          }
          return data;
        })
      );
  }
}

export { IServiceTime } from '../shared/api';
