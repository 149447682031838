<modal-timeclock-edit #modalTimeClockEdit></modal-timeclock-edit>
<div class="row text-white" *ngIf="stats">
  <div class="col-md-4 col-12">
    <ul class="list-group">
      <li class="list-group-item blue-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">Scheduled Hours</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ (stats.scheduled.foh_minutes+stats.scheduled.boh_minutes +
              stats.scheduled.ot_foh_minutes + stats.scheduled.ot_boh_minutes) |
              bliMinutes }}
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item grey-dark-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">
            Hours Reg / <span class="text-danger">OT </span>
          </h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ (stats.timeclock.foh_minutes+stats.timeclock.boh_minutes) |
              bliMinutes }} /
              <span class="text-danger"
                >{{
                (stats.timeclock.ot_foh_minutes+stats.timeclock.ot_boh_minutes)
                | bliMinutes }}</span
              >
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item grey-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">Total Hours</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ ( stats.timeclock.foh_minutes
              +stats.timeclock.boh_minutes+stats.timeclock.ot_foh_minutes+
              stats.timeclock.ot_boh_minutes ) | bliMinutes }}
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-row">
          <div class="p-0 m-0 text-secondary">
            Salaried employees who do not clock in will not show up here.
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-md-4 col-12">
    <ul class="list-group">
      <li class="list-group-item blue-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">Net Pay</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ (stats.foh_cost+stats.boh_cost) | currency: 'USD'}}
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item grey-dark-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">Est. Payroll Costs</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ ((stats.foh_cost+stats.boh_cost)*(this.loc.payroll_goal/100))|
              currency: 'USD' }}
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item grey-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">Est. Gross Pay</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">{{ estGross | currency: 'USD' }}</h3>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-row" *ngIf="loc">
          <div class="p-0 m-0 text-secondary">
            Adjust your est additional payroll expense
            <a routerLink="/settings/goals">here.</a>
            Current: {{loc.payroll_goal}}%
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-md-4 col-12">
    <ul class="list-group">
      <li class="list-group-item blue-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">Total Sales</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ stats.service_time[0].sales | currency: 'USD'}}
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item grey-dark-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">Scheduled Cost</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0">
              {{ stats.log_foh_cost + stats.log_boh_cost + stats.salary.foh_cost
              + stats.salary.boh_cost +
              stats.scheduled.foh_cost+stats.scheduled.boh_cost | currency:
              'USD'}}
            </h3>
          </div>
        </div>
      </li>
      <li class="list-group-item grey-bg">
        <div class="d-flex flex-row">
          <h4 class="p-0 m-0">Est. Gross Labor %</h4>
          <div class="ml-auto">
            <h3 class="p-0 m-0" *ngIf="totalSales==0">N/A</h3>
            <h3 class="p-0 m-0" *ngIf="totalSales!=0">
              {{ 100*(estGross/totalSales) | number: '1.0-0'}}%
            </h3>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="row" *ngIf="timeClocks && timeClocks.length>0">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <label class="mb-0">Employees With Clockins</label>
        <hr />
        <div *ngFor="let u of timeClocks | orderBy:['lastname']">
          <div>
            <label class="mt-2 mb-0">{{u.firstname}} {{u.lastname}}</label>
          </div>
          <div *ngFor="let t of u.timeclocks| orderBy:['start']">
            <div class="row">
              <div class="col-md-4 col-12">
                <!--					{{(t.salary_name?t.salary_name:t.name)}}: -->
                {{t.name}}:
                <span *ngIf="!t.is_salary"> {{t.rate | currency}}/hour</span>
                <span *ngIf="t.is_salary">
                  {{t.salary_rate | currency}}/year</span
                >
              </div>
              <div class="col-md-4 col-12">
                Clocked in {{t.start | date:'shortTime'}}
                <span *ngIf="t.end">- {{t.end | date:'shortTime'}}</span>
                <span class="text-dark" *ngIf="!t.end">Clock out Pending</span>
              </div>
              <div class="col-md-4 col-12" *ngIf="loc.has_tip_reporting">
                Tips: {{t.tips| currency}}
                <span
                  class="float-right"
                  *ngIf="isAllowed(Permission.EditTimeCard)">
                  <span
                    class="cdi-s-edit align-middle pointer"
                    (click)="modalTimeClockEdit.show(t)"></span>
                  <delete-modal
                    [name]="' this timecard for: '+u.firstname+' '+u.lastname"
                    [deleteObject]="t"
                    (onDelete)="deleteTimeclock(t)"
                    ><span class="cdi-s-trash align-middle pointer"></span
                  ></delete-modal>
                </span>
              </div>
              <div class="col-md-4 col-12" *ngIf="!loc.has_tip_reporting">
                <span class="float-right">
                  <span
                    class="cdi-s-edit align-middle pointer"
                    (click)="modalTimeClockEdit.show(t)"></span>
                  <delete-modal
                    [name]="' this timecard for: '+u.firstname+' '+u.lastname"
                    [deleteObject]="t"
                    (onDelete)="deleteTimeclock(t)"
                    ><span class="cdi-s-trash align-middle pointer"></span
                  ></delete-modal>
                </span>
              </div>
            </div>
            <div class="row" *ngIf="userShiftMap[t.user_id]">
              <div class="col-md-4 col-12">
                Scheduled as {{userShiftMap[t.user_id].position_name}}
              </div>
              <div class="col-md-4 col-12">
                Scheduled {{userShiftMap[t.user_id].start | date:'shortTime'}} -
                {{userShiftMap[t.user_id].end | date:'shortTime'}}
              </div>
            </div>
            <!--<div class='row' *ngIf="!userShiftMap[t.user_id] || t.approved_lastname" >-->
            <div class="row" *ngIf="t.approved_lastname">
              <div class="col-12 text-danger">
                Exception: Clock-In Approved by {{t.approved_firstname}} {{
                t.approved_lastname}}
              </div>
            </div>
            <hr />
          </div>
          <div class="row body-bg">
            <div class="col-4">
              <label><strong>Total</strong></label>
            </div>
            <div class="col-4">
              <strong>
                Hours: {{
                (u.timeclock.foh_minutes+u.timeclock.boh_minutes+u.timeclock.ot_foh_minutes+u.timeclock.ot_boh_minutes)
                | bliMinutes}}
              </strong>
              <span *ngIf="u.ot_minutes>0">
                &nbsp;&nbsp;Reg:
                {{u.timeclock.foh_minutes+u.timeclock.boh_minutes| bliMinutes}}
                <span class="text-danger">
                  / OT: {{u.timeclock.ot_foh_minutes+u.timeclock.ot_boh_minutes|
                  bliMinutes}}</span
                ></span
              >
            </div>
            <div class="col-4">
              <span
                *ngIf="!u.salary"
                class="float-right"
                style="font-size: 1.2rem"
                ><strong
                  >{{(u.timeclock.foh_cost+u.timeclock.boh_cost+u.timeclock.ot_foh_cost+u.timeclock.ot_boh_cost)|
                  currency:'USD'}}</strong
                ></span
              >
              <span
                *ngIf="u.salary"
                class="float-right"
                style="font-size: 1.2rem"
                ><strong
                  >{{(u.salary.foh_cost+u.salary.boh_cost+u.salary.ot_foh_cost+u.salary.ot_boh_cost)|
                  currency:'USD'}}</strong
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- possible they sorted for those that were scheduled but did not clockin before the not scheduled -
not large enough sample to tell and not alphabetical-->
<div
  class="row"
  *ngIf="salaryUserNoClockIn && salaryUserNoClockIn.length>0 && userShiftMap">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <label class="mb-0">Salaried Employees Without Clockins</label>
        These salaried employees contribute to the labor totals above. Annual
        salary is used instead of time clock punches.

        <div *ngFor="let s of salaryUserNoClockIn">
          <!--<img class="rounded float-left" style='margin-right: 0.5rem;margin-bottom: 0.5rem;' src="/images/test/meerkat.jpg" height="48px" width="48px">-->
          <label class="mt-2 mb-0">{{s.firstname}} {{s.lastname}}</label>
          Annual salary {{s.salary_rate | currency: 'USD' }} as
          {{s.salary_name}}
          <div *ngIf="userShiftMap[s.id]">
            Scheduled as {{userShiftMap[s.id].position_name}} -
            {{userShiftMap[s.id].start | date:'shortTime'}} -
            {{userShiftMap[s.id].end | date:'shortTime'}}
          </div>
          <label class="body-bg"
            ><strong
              >Total<span class="float-right"
                >{{(s.salary.foh_cost+s.salary.boh_cost)| currency:'USD'}}</span
              ></strong
            ></label
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="isAllowed(Permission.EditTimeCard)">
  <div class="col-12">
    <div class="card" style="width: 100%">
      <div class="card-header" style="font-size: 1.25rem">
        Add A Timecard Here
      </div>
      <div class="card-body">
        <form ngNativeValidate (submit)="saveTimeclock(timeclock)">
          <div class="d-flex flex-wrap flex-row justify-content-between">
            <div>
              <label style="max-width: 20rem">Choose an Employee</label>
              <select
                name="user_id"
                [ngModel]="timeclock.user_id"
                class="form-control d-inline-block override-invalid"
                (ngModelChange)="changeStaff($event)"
                required
                style="max-width: 20rem">
                <option *ngFor="let u of users" [value]="u.id">
                  {{u.firstname}} {{u.lastname}}
                </option>
              </select>
            </div>
            <div>
              <label>Choose a Position</label>
              <select
                name="employee_position_id"
                [(ngModel)]="timeclock.employee_position_id"
                class="form-control d-inline-block override-invalid"
                [disabled]="!positions || positions.length==0"
                required>
                <option *ngFor="let p of positions" [value]="p.id">
                  {{p.name}}
                </option>
              </select>
            </div>
            <div *ngIf="getLocation().has_tip_reporting">
              <label>Tips</label>
              <input
                type="number"
                step="0.01"
                min="0.00"
                class="form-control"
                name="tips"
                [(ngModel)]="timeclock.tips"
                style="max-width: 6rem" />
            </div>
            <div>
              <label> Clock In </label>
              <timepicker
                name="start"
                [(ngModel)]="timeclock.start"></timepicker>
            </div>
            <div>
              <label>Clock Out </label>
              <timepicker name="end" [(ngModel)]="timeclock.end"></timepicker>
            </div>
          </div>
          <div>
            <label>&nbsp;</label>
            <save-button class="float-right"></save-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
