<div class="row">
  <div class="col-3 col-xs-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Title</h4>
        Stuff in a card here.
      </div>
    </div>
  </div>
  <div class="col-3 col-xs-12">
    <div class="card">
      <div class="card-header">Header Bits</div>
      <div class="card-body">Stuff in a card here.</div>
    </div>
  </div>

  <div class="col-6 col-xs-12 text-white font-headers nav-padding">
    <ul class="list-group">
      <li class="list-group-item align-middle">
        <h4 class="text-dark font-weight-bold">TOTAL SCHEDULED COST</h4>
      </li>
      <li class="list-group-item blue-bg">
        <h4 class="float-left">ESTIMATED SALES (link here)</h4>
        <h3 class="float-right font-weight-bold">$302.33</h3>
      </li>
      <li class="list-group-item grey-dark-bg">
        <h4 class="float-left">BUDGET</h4>
        <h3 class="float-right font-weight-bold">$30.23</h3>
      </li>
      <li class="list-group-item grey-bg">
        <h4 class="float-left">SCHEDULES & SALARIES</h4>
        <h3 class="float-right font-weight-bold">$1,060.07</h3>
      </li>
      <li class="list-group-item grey-light-bg">
        <h4 class="float-left">ACTUAL</h4>
        <h3 class="float-right font-weight-bold">$808.88</h3>
      </li>
    </ul>
  </div>
</div>

<div class="card nav-padding">
  <div class="card-body">
    Some stuff here
    <accordion-button class="row">
      <div class="row">
        <div class="col-6">Look More Stuff</div>
        <div class="col-6">In 2 columns</div>
      </div>
    </accordion-button>
  </div>
</div>

<carousel [interval]="0">
  <slide>
    <div class="card nav-padding">
      <div class="card-header">Title 1</div>
      <div class="card-body">Page 2..</div>
    </div>
  </slide>
  <slide>
    <div class="card nav-padding">
      <div class="card-header">Title 2</div>
      <div class="card-body">
        Page 1..<br />
        this<br />
        is<br />
        a<br />
        longer <br />
        card<br />
        to<br />
        check<br />
        things.<br />
      </div>
    </div>
  </slide>
  <slide>
    <div class="card nav-padding">
      <div class="card-header">Title 3</div>
      <div class="card-body">Page 3..</div>
    </div>
  </slide>
</carousel>

<div class="row">
  <div class="col-12" style="background-color: white">
    Checking carousel bottom spacing here.
  </div>
</div>
