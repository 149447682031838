<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Message"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" *ngIf="users">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Staff Messaging
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <form ngNativeValidate (submit)="save()">
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <label for="messageTitle">Subject</label>
              <input
                [(ngModel)]="message.title"
                type="text"
                maxlength="500"
                class="form-control override-invalid"
                name="message_title"
                placeholder="Message Title"
                required />
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <label for="message"> Message</label>
              <textarea
                [(ngModel)]="message.body"
                class="form-control"
                name="message"
                placeholder="Message Text"
                required></textarea>
              <input
                #fileInput
                type="file"
                class="form-control-file"
                (change)="handleFileChange($event.target.files)"
                name="message_file"
                multiple
                accept="application/pdf,image/*" />
              <local-error [error]="fileError"></local-error>
              <small>Attachments will not be included in text messages</small>
            </div>
          </div>

          <div class="card" *ngIf="positions">
            <div class="card-body">
              <label style="width: auto"
                >Select staff positions to recieve the message</label
              >
              <div class="row">
                <div
                  class="flex-200 p-1"
                  *ngFor="let p of positions | orderBy: 'name'">
                  <input-toggle
                    [(ngModel)]="p._selected"
                    (ngModelChange)="positionChange(p)"
                    [name]="p.id"
                    >{{p.name}}</input-toggle
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="card" *ngIf="users">
            <div class="card-body">
              <div>
                <label style="width: auto"
                  >View and Edit selected recipients here</label
                >
                <clear-button
                  class="float-right"
                  (click)="clearUsers()"></clear-button>
                <button
                  type="button"
                  class="btn btn-primary btn-sm float-right mx-2"
                  (click)="selectAllUsers()">
                  Select All
                </button>
              </div>
              <div class="row">
                <div class="flex-200 p-1" *ngFor="let u of users">
                  <input-toggle
                    [(ngModel)]="u._selected"
                    (ngModelChange)="userChange(u._selected)"
                    [name]="'user_'+u.id"
                    >{{u.firstname}} {{u.lastname}}</input-toggle
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="card" *ngIf="message">
            <div class="card-body">
              <label>Select message types</label>
              <div class="row">
                <div class="flex-200 p-1">
                  <input-toggle name="send_email" [(ngModel)]="message.is_email"
                    >Send as Email</input-toggle
                  >
                </div>
                <div class="flex-200 p-1">
                  <input-toggle name="send_text" [(ngModel)]="message.is_text"
                    >Send as Text</input-toggle
                  >
                </div>
                <div
                  class="flex-200 p-1"
                  *ngIf="isAllowed(Permission.PostWallMessage)">
                  <input-toggle name="send_wall" [(ngModel)]="message.wall"
                    >Post to Wall</input-toggle
                  >
                </div>
                <div class="flex-200 p-1" *ngIf="message.wall">
                  <input-toggle name="wall_SF" [(ngModel)]="message.signed"
                    >Require Sign off</input-toggle
                  >
                </div>
                <div class="flex-200 p-1" *ngIf="message.wall">
                  <input-toggle name="wall_AC" [(ngModel)]="message.can_comment"
                    >Allow Comments</input-toggle
                  >
                </div>
              </div>
              <small>
                By default, all messages get sent through CheddrSuite's internal
                messaging. If you would like to send your message by an
                additional method, please check it here.</small
              >
            </div>
          </div>

          <global-error [error]="error"></global-error>
          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <button type="submit" class="btn btn-primary mx-2 float-right">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
