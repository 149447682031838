<div class="container-fluid subnav-padding">
  <div class="title text-center">Edit Purchase Order</div>

  <form ngNativeValidate *ngIf="data" (submit)="save()">
    <div class="row">
      <div class="col-12 text-white font-headers nav-padding">
        <ul class="list-group">
          <li class="list-group-item blue-bg">
            <h4 class="float-left my-0 pt-2 pb-0">Budget</h4>
            <h3 class="float-right my-0 pt-2 pb-0">
              {{budget | currency: 'USD'}}
            </h3>
          </li>
          <li class="list-group-item grey-dark-bg">
            <h4 class="float-left my-0 pt-2 pb-0">This Order</h4>
            <h3 class="float-right my-0 pt-2 pb-0">
              {{ total | currency: 'USD' }} - {{ 100*total/budget | number:
              '1.0-0' }} %
            </h3>
          </li>
          <li class="list-group-item grey-bg">
            <h4 class="float-left my-0 pt-2 pb-0">Pending</h4>
            <h3 class="float-right my-0 pt-2 pb-0">
              {{ pendingPrice | currency: 'USD' }} - {{ pendingPercent *100 |
              number: '1.0-0'}}%
            </h3>
          </li>
          <li class="list-group-item grey-light-bg">
            <h4 class="float-left my-0 pt-2 pb-0">Approved</h4>
            <h3 class="float-right my-0 pt-2 pb-0">
              {{ approvedPrice | currency: 'USD'}} - {{ approvedPercent*100 |
              number: '1.0-0' }}%
            </h3>
          </li>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-12">
            <label> Vendor: {{ data.contact_company }} </label>
          </div>
          <div class="col-md-4 col-12">
            <label> PO # {{ data.number }} </label>
          </div>
          <div class="col-md-4 col-12">
            <label class="d-inline">PO Date </label>
            <input
              [(ngModel)]="data.date"
              type="text"
              class="d-inline form-control input-calendar w-auto"
              bsDatepicker
              name="po_date"
              required />
          </div>
        </div>
      </div>
    </div>

    <div style="font-size: 12px">
      <div class="d-none d-md-block">
        <div
          class="row font-weight-bold p-1 mb-2 mx-0"
          style="background-color: #c6c8ca">
          <div class="col-3">SKU</div>
          <div class="col-3">QTY</div>
          <div class="col-3">Price</div>
          <div class="col-3">Cost</div>
        </div>
      </div>
      <div class="row mx-0 border-bottom" *ngFor="let s of items">
        <div class="col-md-3">
          <span class="d-inline-block d-md-none text-primary">SKU:&nbsp; </span
          >{{ s.name }}
        </div>
        <div class="col-md-3">
          <span class="d-inline-block d-md-none text-primary">Qty: </span>
          <input
            type="number"
            step="0.25"
            [name]="'name_'+s.id"
            [(ngModel)]="s.qty"
            class="inline-form-control"
            (ngModelChange)="calcTotal()"
            required />
          {{s.buying_unit}}(s)
        </div>
        <div class="col-md-3">
          <span class="d-inline-block d-md-none text-primary">Price: </span
          >{{s.price_per_unit | currency: 'USD' }}
        </div>
        <div class="col-md-3">
          <span class="d-inline-block d-md-none text-primary">Cost: </span
          >{{s.qty*s.price_per_unit | currency: 'USD' }}
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <cancel-button class="float-right" (click)="cancel()"></cancel-button>
        <save-button class="float-right"></save-button>
      </div>
    </div>
  </form>
</div>
