import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import { TimeoutService } from '../services/timeout.service';
import { baseRole } from '../shared/baseRole';
import { MessageService } from '../services/message.service';
import { MessageRecipientService } from '../services/messageRecipient.service';
import { UserType, moment } from '../shared/api';
import { environment } from 'environments/environment';
declare var growsumo: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent extends baseRole implements OnInit, OnDestroy {
  public leftIsCollapsed = true;
  public rightIsCollapsed = true;
  public user: IApiUser;
  public loc: IApiLocation;
  public locations: IApiLocation[];
  public now = new Date();
  public searchText: string;
  public messageSub;
  public messageRSub;
  public timerSub;
  public logoutTimer;
  protected timer;
  @ViewChild('timeoutModal')
  public timeout;
  public lastnameInitial: string;
  public messageCount = 0;

  @HostListener('document:click', ['$event'])
  public onAnyClick(event) {
    this.timeoutService.resetTimeout();
  }

  constructor(
    public router: Router,
    protected accountService: AccountService,
    protected timeoutService: TimeoutService,
    protected messageService: MessageService,
    protected messageRecipientService: MessageRecipientService
  ) {
    super(accountService);
    router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          if (current instanceof NavigationEnd) {
            return previous.url === current.url;
          }
          return true;
        })
      )
      .subscribe((x: any) => {
        if ((<any>window).vgo) {
          try {
            (<any>window).vgo('process');
          } catch (err) {}
        }
      });

    this.setupHolidays();
    this.locations = this.accountService.getLocations();

    this.accountService.getUserUpdateObservable().subscribe(() => {
      this.user = this.accountService.getUser();
      this.locations = this.accountService.getLocations();
      this.loc = this.accountService.getLocation();

      if (!this.messageSub) {
        this.messageSub = this.messageService
          .getUpdateObservable()
          .subscribe(() => {
            this.loadMessages();
          });
      } else {
        this.loadMessages();
      }

      if (!this.messageRSub) {
        this.messageRSub = this.messageRecipientService
          .getUpdateObservable()
          .subscribe(() => {
            this.loadMessages();
          });
      } else {
        this.loadMessages();
      }

      if (this.user) this.timeoutService.startTimeout();
    });
  }

  inJail(): boolean {
    return this.accountService.inJail();
  }

  loadMessages() {
    if (!this.user) return;
    this.messageService
      .countReceived({ archived: false, wall: false, read: false })
      .subscribe(count => {
        this.messageCount = count;
      });
  }

  stay() {
    this.timeout.hide();
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
    this.timeoutService.resetTimeout();
  }

  setupHolidays() {
    moment.modifyHolidays.add({
      'Groundhog Day': {
        date: '2/2',
      },
      'Earth Day': {
        date: '4/22',
      },
      'Cinco de Mayo': {
        date: '5/5',
      },
    });
  }

  clickHelp() {
    window.open('https://support.cheddrsuite.com/support/home', '_blank');
  }

  ngOnInit() {
    setTimeout(() => {
      this.now = new Date();
      this.timer = setInterval(() => {
        this.now = new Date();
      }, 60 * 1000);
    }, 60000 - (this.now.getTime() % (60 * 1000)) + 10); // This should cause it to fire on the minute
    this.loadScript();
  }

  ngAfterViewInit() {
    if (!this.timerSub) {
      this.timerSub = this.timeoutService
        .getTimeoutObservable()
        .subscribe(() => {
          if (!this.user) return;
          console.log('Got Tiemout');
          this.timeout.show();
          this.logoutTimer = setTimeout(() => {
            this.logout();
          }, 30 * 1000);
        });
    }
  }
  public loadScript() {
    // loading kanmon script
    const kanmon = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.src = environment.kanmonUrl;
    kanmon.appendChild(script);

    // loading partnerstack script
    const partnerstack = document.createElement('script');
    partnerstack.src = 'https://get.cheddrsuite.com/pr/js';
    partnerstack.type = 'text/javascript';
    partnerstack.async = true;
    partnerstack.onload = (partnerstack as any).onreadystatechange =
      function () {
        var rs = this.readyState;
        if (rs && rs != 'complete' && rs != 'loaded') return;
        try {
          growsumo._initialize(environment.partnerStackApiKey);
          // @ts-ignore
          if (typeof growsumoInit === 'function') {
            // @ts-ignore
            growsumoInit();
          }
        } catch (e) {}
      };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(partnerstack, s);
  }

  setLocation(loc) {
    this.accountService.setLocation(loc.id, true).subscribe(() => {});
  }

  ngOnDestroy() {
    if (this.messageSub) this.messageSub.unsubscribe();
    if (this.messageRSub) this.messageRSub.unsubscribe();
    if (this.timerSub) this.timerSub.unsubscribe();
    if (this.timer) clearInterval(this.timer);
    if (this.logoutTimer) clearTimeout(this.logoutTimer);
  }

  search() {
    if (!this.searchText) return;
    this.router.navigate(['/search', this.searchText]);
    this.searchText = null;
  }

  logout() {
    this.accountService.logout();
  }
}
