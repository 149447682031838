import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IApiUser } from '../services/account.service';
import {
  ChecklistItemService,
  IChecklistItem,
  IChecklist,
  ChecklistItemType,
} from '../services/checklistItem.service';
import {
  ChecklistService,
  ChecklistAssignmentType,
} from '../services/checklist.service';
import { ChecklistUserMapService } from '../services/checklistUserMap.service';
import { ChecklistPositionMapService } from '../services/checklistPositionMap.service';
import { UserService } from '../services/user.service';
import { baseRole } from '../shared/baseRole';
import { DragulaService } from 'ng2-dragula';
import { PositionService, IPosition } from '../services/position.service';

@Component({
  templateUrl: 'checklist.html',
})
export class ChecklistComponent extends baseRole implements OnInit, OnDestroy {
  public sub;
  public checklist: IChecklist;
  public checklistItems: IChecklistItem[];
  public users: any[];
  public checklists: IChecklist[]; // All checklists.
  public ChecklistItemType = ChecklistItemType;
  public ChecklistAssignmentType = ChecklistAssignmentType;
  public ChecklistItemTypeKeys = Object.keys(ChecklistItemType).filter(Number);
  public ChecklistItemTypeName = [null, 'Checklist', 'Number'];
  public newItem: any;
  public positions: any[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected dragulaService: DragulaService,
    protected userService: UserService,
    protected checklistService: ChecklistService,
    protected checklistItemService: ChecklistItemService,
    protected checklistUserMapService: ChecklistUserMapService,
    protected checklistPositionMapService: ChecklistPositionMapService,
    protected positionService: PositionService
  ) {
    super(accountService);
  }
  ngOnInit() {
    this.resetNewItem();
    this.route.params.subscribe(params => {
      let id: number = parseInt(params['id']);

      this.userService.listForChecklist(id).subscribe(data => {
        for (let user of data) {
          user._checked = user.id;
        }
        this.users = data;
      });
      // Get all staff positions
      this.positionService.list(id).subscribe(data => {
        // Get all current checklists positions
        this.checklistPositionMapService.list(id).subscribe(data => {
          // filter out the positions that are not in the current checklist
          data = data.filter(positionMapData => {
            return positionMapData.checklist_id === id;
          });
          // add the _checked property to the data
          for (let position of this.positions) {
            if (
              data.filter(checkedData => {
                return checkedData.position_id === position.id;
              }).length > 0
            ) {
              position._checked = true;
            }
          }
        });
        this.positions = data;
      });

      if (id) {
        this.checklistService.list().subscribe(data => {
          this.checklists = data;
          this.checklist = data.filter(checklist => {
            return checklist.id === id;
          })[0];
        });
        this.checklistItemService.list({ checklist_id: id }).subscribe(data => {
          this.checklistItems = data;
        });
      } else {
        let startDate = new Date();
        let endDate = new Date();
        startDate.setSeconds(0);
        startDate.setMinutes(0);
        endDate.setSeconds(0);
        endDate.setMinutes(0);
        this.checklist = {
          start: startDate,
          end: endDate,
          days: [true, true, true, true, true, true, true],
        } as IChecklist;
        this.checklistItems = [];
      }
    });

    this.setupDragNDrop();
  }
  resetNewItem() {
    this.newItem = {
      checklist_item_type: ChecklistItemType.Check,
      min: null,
      max: null,
      days: [true, true, true, true, true, true, true],
    };
  }

  editObj(obj) {
    obj._edit = Object.assign({}, obj);
  }

  cancelEdit(obj) {
    obj = Object.assign(obj, obj._edit);
    delete obj._edit;
  }

  deleteObj(obj) {
    if (!obj.id) {
      let idx = this.checklistItems.indexOf(obj);
      this.checklistItems.splice(idx, 1);
      return;
    }
    this.checklistItemService.delete(obj.id).subscribe(data => {
      let idx = this.checklistItems.indexOf(obj);
      this.checklistItems.splice(idx, 1);
    });
  }

  saveObj(obj, isAdd: boolean) {
    if (!obj.name) return;
    obj.checklist_id = this.checklist.id;
    obj.order_by = this.checklistItems.length;
    if (!this.checklist.id && isAdd) {
      this.checklistItems.push(obj);
      this.resetNewItem();
      return;
    }
    if (!obj.checklist_id) {
      delete obj._edit;
      return;
    }
    this.checklistItemService.update(obj).subscribe(data => {
      if (!obj.id) {
        obj.id = data.id;
        this.checklistItems.push(obj);
        this.resetNewItem();
      } else {
        delete obj._edit;
      }
    });
  }

  save() {
    this.checklistService.update(this.checklist).subscribe(data => {
      this.checklist.id = data.id;
      let i = 0;
      for (let item of this.checklistItems) {
        item.order_by = i++;
        item.checklist_id = this.checklist.id;
      }
      if (this.checklistItems.length == 0) {
        this.cancel();
      }
      this.checklistItemService.bulkUpdate(this.checklistItems).subscribe(data => {
        if (this.checklist.has_position_assignments) {
          // remove all the current user assignments
          const assignedUserMap = this.users.map(userMap => {
            return {
              id: userMap.id,
              checklist_id: this.checklist.id,
              location_id: userMap.location_id,
              user_id: userMap.user_id,
              _checked: false,
            };
          });
          this.checklistUserMapService.bulkUpdate(assignedUserMap).subscribe();

          // add the new position assignments
          const assignedPositionMap = this.positions.map(positionMap => {
            return {
              checklist_id: this.checklist.id,
              location_id: positionMap.location_id,
              position_id: positionMap.id,
              _checked: positionMap._checked,
            };
          });
          this.checklistPositionMapService
            .bulkUpdate(assignedPositionMap)
            .subscribe();
        }
        if (this.checklist.has_assignments) {
          // remove all the current position assignments
          const assignedPositionMap = this.positions.map(positionMap => {
            return {
              checklist_id: this.checklist.id,
              location_id: positionMap.location_id,
              position_id: positionMap.id,
              _checked: false,
            };
          });
          this.checklistPositionMapService
            .bulkUpdate(assignedPositionMap)
            .subscribe();

          // add the new user assignments
          const assignedUserMap = this.users.map(userMap => {
            return {
              id: userMap.id,
              checklist_id: this.checklist.id,
              location_id: userMap.location_id,
              user_id: userMap.user_id,
              _checked: userMap._checked,
            };
          });
          this.checklistUserMapService.bulkUpdate(assignedUserMap).subscribe();
        }
      });
    });
    this.cancel();
  }

  cancel() {
    this.router.navigate(['/settings/dashboard']);
  }

  onChangeAssignee(option) {
    if (option === ChecklistAssignmentType.Position) {
      this.checklist.has_assignments = false;
    } else if (option === ChecklistAssignmentType.User) {
      this.checklist.has_position_assignments = false;
    }
  }

  // DRAG N DROP
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    this.dragulaService.destroy('checklist_items');
  }

  setupDragNDrop() {
    this.dragulaService.createGroup('checklist_items', {
      direction: 'vertical',
    });

    if (!this.sub) {
      this.sub = this.dragulaService.drop('checklist_item').subscribe(value => {
        var myId = value[1].getAttribute('data-id');

        if (!this.checklistItems) {
          console.error('this.items is null');
          console.error(this);
          return;
        }
        let i = 0;
        for (i = 0; i < this.checklistItems.length; i++) {
          if (this.checklistItems[i].id == myId) break;
        }
        if (i == this.checklistItems.length) return; // No idea what happened..

        let me = this.checklistItems[i];
        //console.log('me: '+me.id +" at index "+i);

        if (value[4]) {
          // Was inserted before someone...
          var targetId = value[4].getAttribute('data-id');
          let j = 0;
          for (j = 0; j < this.checklistItems.length; j++) {
            if (this.checklistItems[j].id == targetId) break;
          }
          //console.log('Target Id: '+targetId+ " at index: "+j);
          if (j == this.checklistItems.length) return; // No idea what happened..
          //  console.log('target: '+this.locations[j].id);
          // Carefull when splicing the array you don't delete before
          // the index you want the second insert into.
          if (i < j) {
            this.checklistItems.splice(j, 0, me);
            this.checklistItems.splice(i, 1);
          } else {
            this.checklistItems.splice(i, 1);
            this.checklistItems.splice(j, 0, me);
          }
        } else {
          //console.log('Target Id: null');
          this.checklistItems.splice(i, 1);
          this.checklistItems.push(me);
          //this.locations.splice(0, 0, me);
        }

        var update = [];
        for (i = 0; i < this.checklistItems.length; i++) {
          this.checklistItems[i].order_by = i;
          update.push({ id: this.checklistItems[i].id, order_by: i });
        }
        if (!this.checklist.id) return; // Don't really have a object..

        this.checklistItemService.reOrder(update).subscribe(data => {});
      });
    }
  }
}
