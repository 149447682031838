<div class="row py-2">
  <div class="col-5">
    <label class="form-label">Start</label>
    <input
      type="text"
      required
      class="form-control input-calendar"
      name="start"
      bsDatepicker
      [(ngModel)]="search.start" />
  </div>
  <div class="col-5">
    <label class="form-label">End</label>
    <input
      type="text"
      required
      class="form-control input-calendar"
      name="end"
      bsDatepicker
      [(ngModel)]="search.end" />
  </div>
  <div class="col-2">
    <label class="form-label"></label>
    <button (click)="load()" class="btn btn-primary">Search</button>
  </div>
</div>

<table class="table" *ngIf="data">
  <thead>
    <tr>
      <th sort fieldname="date" [(orderBy)]="orderByField">Date</th>
      <th sort fieldname="signup" [(orderBy)]="orderByField">Signups</th>
      <th sort fieldname="promo" [(orderBy)]="orderByField">Promos</th>
      <th>{{PackageTypeNames[PackageType.Freemium]}}</th>
      <th>{{PackageTypeNames[PackageType.Normal]}}</th>
      <th>{{PackageTypeNames[PackageType.Pro]}}</th>
      <th sort fieldname="total" [(orderBy)]="orderByField">Total</th>
      <th>F &lt;- N</th>
      <th>F &lt;- P</th>
      <th>N &lt;- F</th>
      <th>N &lt;- P</th>
      <th>P &lt;- F</th>
      <th>P &lt;- N</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let d of data | orderBy: [ orderByField ]">
      <td>{{ d.date | date: 'shortDate' }}</td>
      <td>{{d.signup}}</td>
      <td>{{d.promo}}</td>
      <td>{{d.amount[1] | currency: 'USD' }}</td>
      <td>{{d.amount[2] | currency: 'USD' }}</td>
      <td>{{d.amount[3] | currency: 'USD' }}</td>
      <td>{{d.total | currency: 'USD' }}</td>
      <td>{{d.changes[1][2]}}</td>
      <td>{{d.changes[1][1]}}</td>
      <td>{{d.changes[2][0]}}</td>
      <td>{{d.changes[2][2]}}</td>
      <td>{{d.changes[3][0]}}</td>
      <td>{{d.changes[3][1]}}</td>
    </tr>

    <tr class="font-weight-bold">
      <td>Totals</td>
      <td>{{grandSignups}}</td>
      <td>{{grandPromos}}</td>
      <td>{{grandTotal[1] | currency: 'USD' }}</td>
      <td>{{grandTotal[2] | currency: 'USD' }}</td>
      <td>{{grandTotal[3] | currency: 'USD' }}</td>
      <td>{{grandTotal[1]+grandTotal[2]+grandTotal[3] | currency: 'USD' }}</td>
      <td>{{changes[1][2]}}</td>
      <td>{{changes[1][1]}}</td>
      <td>{{changes[2][0]}}</td>
      <td>{{changes[2][2]}}</td>
      <td>{{changes[3][0]}}</td>
      <td>{{changes[3][1]}}</td>
    </tr>
  </tbody>
</table>
