<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Standing Order Editor"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" *ngIf="data">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          {{ data?.id?'Edit Standing Order':'Add Standing Order' }}
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <form ngNativeValidate class="modal-body" (submit)="save()">
        <div class="row">
          <div class="col-12 text-white font-headers nav-padding">
            <ul class="list-group">
              <li class="list-group-item blue-bg">
                <h4 class="float-left my-0 pt-2 pb-0">Budget</h4>
                <h3 class="float-right my-0 pt-2 pb-0">$302.33</h3>
              </li>
              <li class="list-group-item grey-dark-bg">
                <h4 class="float-left my-0 pt-2 pb-0">This Order</h4>
                <h3 class="float-right my-0 pt-2 pb-0">$30.23 - 3%</h3>
              </li>
              <li class="list-group-item grey-bg">
                <h4 class="float-left my-0 pt-2 pb-0">Pending</h4>
                <h3 class="float-right my-0 pt-2 pb-0">$0.00 - 0%</h3>
              </li>
              <li class="list-group-item grey-light-bg">
                <h4 class="float-left my-0 pt-2 pb-0">Approved</h4>
                <h3 class="float-right my-0 pt-2 pb-0">$0.00 - 0%</h3>
              </li>
            </ul>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12">
                <label class="text-center">
                  Name
                  <input
                    [(ngModel)]="data.name"
                    name="name"
                    type="text"
                    style="min-width: 20rem"
                    class="inline-form-control override-invalid"
                    placeholder="Standing Order Name"
                    required />
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-xs-12">
                <label class="pl-3"> Vendor </label>
                <select
                  [(ngModel)]="data.contact_id"
                  (ngModelChange)="filter()"
                  class="form-control d-inline-block"
                  name="contact_id"
                  [disabled]="data.id"
                  required>
                  <option *ngFor="let c of contacts" [value]="c.id">
                    {{c.company}}
                  </option>
                </select>
              </div>

              <div class="col-md-3 col-xs-12">
                <label class="pl-3">Expense Category</label>
                <select
                  [ngModel]="''"
                  (ngModelChange)="expenseAccountId=$event;filter()"
                  class="form-control d-inline-block"
                  name="expense_account_id">
                  <option *ngFor="let a of this.accounts" [value]="a.id">
                    {{a.name}}
                  </option>
                  <option></option>
                </select>
              </div>

              <div class="col-md-3 col-xs-12">
                <label class="pl-3"> Frequency </label>
                <select
                  [(ngModel)]="data.repeat_option"
                  class="form-control d-inline-block"
                  name="freq">
                  <option *ngFor="let opt of RepeatKeys" [value]="opt">
                    {{RepeatText[opt]}}
                  </option>
                </select>
              </div>

              <div class="col-md-3 col-xs-12">
                <label class="pl-3"> Starting On </label>
                <input
                  type="text"
                  class="form-control input-calendar"
                  bsDatepicker
                  [(ngModel)]="data.date"
                  name="date"
                  required />
              </div>
            </div>
            <div class="row" *ngIf="data.repeat_option==RepeatOptions.Daily">
              <div class="flex-7 p-1">
                <input-toggle
                  name="sun"
                  [(ngModel)]="data.days[DaysOfWeek.Sunday]"
                  ><small>Sun</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle
                  name="mon"
                  [(ngModel)]="data.days[DaysOfWeek.Monday]"
                  ><small>Mon</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle
                  name="tue"
                  [(ngModel)]="data.days[DaysOfWeek.Tuesday]"
                  ><small>Tue</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle
                  name="web"
                  [(ngModel)]="data.days[DaysOfWeek.Wednesday]"
                  ><small>Wed</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle
                  name="thu"
                  [(ngModel)]="data.days[DaysOfWeek.Thursday]"
                  ><small>Thu</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle
                  name="fri"
                  [(ngModel)]="data.days[DaysOfWeek.Friday]"
                  ><small>Fri</small></input-toggle
                >
              </div>
              <div class="flex-7 p-1">
                <input-toggle
                  name="sat"
                  [(ngModel)]="data.days[DaysOfWeek.Saturday]"
                  ><small>Sat</small></input-toggle
                >
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <table class="table table-sm table-striped table-hover">
              <thead class="thead-light">
                <tr>
                  <th>SKU</th>
                  <th>Vendor</th>
                  <th>Qty</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody *ngIf="skus && skus.length==0">
                <tr>
                  <td colspan="4">No Skus</td>
                </tr>
              </tbody>
              <tbody style="font-size: 12px" *ngIf="skus && skus.length>0">
                <tr *ngFor="let s of skus">
                  <td>{{s.name}}</td>
                  <td>{{s.company}}</td>
                  <td>
                    <input
                      [(ngModel)]="s.qty"
                      (ngModelChange)="recalc()"
                      [name]="'qty_'+s.id"
                      type="number"
                      min="0"
                      class="inline-form-control override-invalid"
                      style="
                        max-width: 70px;
                        margin-top: 0rem;
                        margin-bottom: 0rem;
                      "
                      required />
                    {{ s.buying_unit}}(s)
                  </td>
                  <td>{{(s.price*s.qty )| currency: 'USD' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="cancel()"></cancel-button>
            <save-button
              *ngIf="skus && skus.length>0"
              class="float-right"></save-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
