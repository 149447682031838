import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import {
  AccountService,
  IUser,
  IApiLocation,
} from '../services/account.service';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';
import { WidgetType, WidgetNames } from '../shared/api';

@Component({
  templateUrl: './doc.html',
})
export class DocComponent extends baseRole implements OnInit {
  public loc;
  public docCount = 1;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {
    super(accountService);
    this.loc = this.accountService.getLocation();
  }

  ngOnInit() {}
}
