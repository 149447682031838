<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Inventory Counting Order"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" *ngIf="location && data">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Rearrange Inventory Items
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <label>For Location {{location.name}}</label>

            <table class="table table-hover mb-0 sortable-dragula">
              <tbody [dragula]="'inventory'">
                <tr
                  *ngFor="let item of data | orderBy: 's_order_by'"
                  [attr.data-id]="item.sku_location_id">
                  <td>
                    <icons name="GripVertical"></icons>
                    <span>{{item.name}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12">
            <done-button class="float-right" (click)="save()"></done-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
