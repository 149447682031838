import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardFreemium, AuthGuardJail } from '../app/auth-guard';
import { PayrollComponent } from './payroll.component';
import { ReportsComponent } from './reports.component';
import { SalesComponent } from './sales.component';

const appRoutes: Routes = [
  {
    path: 'reports',
    canActivate: [AuthGuard, AuthGuardJail],
    component: ReportsComponent,
    children: [
      { path: '', component: SalesComponent },
      { path: 'payroll', component: PayrollComponent },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
