<form class="card" (submit)="save()" *ngIf="data" ngNativeValidate>
  <div class="card-body">
    <h4>{{(data.id?'Edit':'Add')}} Billing Plan</h4>
    <div class="row">
      <div class="col-md-3 col-12">
        <label>Name</label>
        <input
          type="text"
          name="name"
          [(ngModel)]="data.name"
          class="form-control"
          required />
      </div>
      <div class="col-md-3 col-12">
        <label>Code</label>
        <input
          type="text"
          name="code"
          [(ngModel)]="data.code"
          class="form-control"
          required />
      </div>
      <div class="col-md-3 col-12">
        <label>Date End</label>
        <input
          type="text"
          bsDatepicker
          name="date_end"
          [(ngModel)]="data.date_end"
          class="form-control input-calendar" />
      </div>
      <div class="col-md-3 col-12">
        <label>Plan Type</label>
        <select
          name="billing_plan_type"
          [(ngModel)]="data.billing_plan_type"
          required
          class="form-control">
          <option *ngFor="let p of BillingPlanTypeKeys" [value]="p">
            {{BillingPlanType[p]}}
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 col-12">
        <input-toggle name="is_active" [(ngModel)]="data.is_active"
          >Active</input-toggle
        >
      </div>
      <div class="col-md-2 col-12">
        <input-toggle name="is_promo" [(ngModel)]="data.is_promo"
          >Promo</input-toggle
        >
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 col-12">
        <label>Basic</label>
        <input
          type="number"
          min="0"
          step="0.01"
          name="package_1"
          [(ngModel)]="data.package_1"
          class="form-control"
          required />
      </div>
      <div class="col-md-3 col-12">
        <label>Plus</label>
        <input
          type="number"
          min="0"
          step="0.01"
          name="package_2"
          [(ngModel)]="data.package_2"
          class="form-control"
          required />
      </div>
      <div class="col-md-3 col-12">
        <label>Pro</label>
        <input
          type="number"
          min="0"
          step="0.01"
          name="package_3"
          [(ngModel)]="data.package_3"
          class="form-control"
          required />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label>Description</label>
        <textarea
          name="description"
          required
          class="form-control"
          [(ngModel)]="data.description"></textarea>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <cancel-button class="float-right" routerLink=".."></cancel-button>
        <save-button class="float-right"></save-button>
      </div>
    </div>
  </div>
</form>

<div class="card" *ngIf="locations">
  <div class="card-body">
    <div class="row-table row-striped">
      <div class="row" *ngFor="let l of locations| orderBy: 'id'">
        <div class="col-12">
          <label class="pointer mb-0" [routerLink]="['/admin/location',l.id]">
            <span class="cdi-s-edit float-right"></span>
            {{ l.name}}
            <small>({{ l.id }}) Created: {{ l.created_at | bliDate }}</small>
          </label>
        </div>

        <div class="col-md-3 col-12">
          Condition: {{LocationStatus[l.status]}}
        </div>
        <div class="col-md-3 col-12">
          Billing Plan: {{PackageType[l.package_type]}}
        </div>
        <div class="col-md-2 col-12">
          Free Until: {{ l.free_until | date: 'mediumDate' }}
        </div>
        <div class="col-md-2 col-12">
          Payment Status: {{ l.payment_status?'Okay':'Failed'}}
        </div>
        <div class="col-md-2 col-12">
          Payment Fail: {{ l.payment_failed_at | date: 'mediumDate' }}
        </div>

        <div class="col-12">
          <div *ngFor="let u of l.user">
            Owner: {{ u.firstname }} {{u.lastname}} ({{u.id}}) - {{u.email}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
