import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ModalMessageComponent } from '../shared/modalMessage.component';
import { ISubNavItem } from '../shared/api';

@Component({
  templateUrl: './messages.html',
})
export class MessagesComponent implements OnInit {
  @ViewChild('modalMessage')
  public modal: ModalMessageComponent;

  public menu: ISubNavItem[] = [
    {
      label: 'Inbox',
      routerLink: '/messages',
      exact: true,
    },
    {
      label: 'Sent Messages',
      routerLink: '/messages/sent',
    },
    {
      label: 'Archive',
      routerLink: '/messages/archive',
    },
    {
      label: 'Old Wall Posts',
      routerLink: '/messages/wall',
    },
    {
      label: 'Compose',
      click: () => {
        this.modal.show();
      },
    },
  ];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {}

  ngOnInit() {}
}
