<div *ngIf="user">
  <div class="title text-center">{{ user.firstname }} {{user.lastname}}</div>
</div>
<tabset *ngIf="user && userMap">
  <tab heading="Edit Employee">
    <user-edit-employee [user]="user" [userMap]="userMap"></user-edit-employee>
  </tab>

  <tab heading="Work Preferences">
    <user-edit-work-pref
      [user]="user"
      [userMap]="userMap"></user-edit-work-pref>
  </tab>

  <tab heading="Positions">
    <user-edit-role-pos [user]="user" [userMap]="userMap"></user-edit-role-pos>
  </tab>

  <tab
    heading="Permissions"
    *ngIf="isAllowed(Permission.EditEmployeePermission)">
    <user-edit-permissions
      [user]="user"
      [userMap]="userMap"></user-edit-permissions>
  </tab>
</tabset>

<!--<div class='row mt-3'>
	<div class='col-sm-12'>
		<cancel-button class='float-right' routerLink="/users"></cancel-button>
		<save-button class='float-right' (click)="save()"></save-button>
	</div>
</div>-->
