<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Fixed Cost Editor"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" *ngIf="data && expenseAccounts">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          {{ data?.id?'Edit':'Add' }} Fixed Costs
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <form ngNativeValidate (submit)="save()">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 col-12">
              <div class="card">
                <div class="card-body">
                  <label> Description </label>
                  <input
                    type="text"
                    [(ngModel)]="data.name"
                    name="name"
                    maxlength="50"
                    class="form-control override-invalid"
                    placeholder="Description"
                    required />

                  <label>Fixed Cost Expense Category</label>
                  <select
                    [(ngModel)]="data.expense_account_id"
                    class="form-control d-inline-block override-invalid"
                    name="account"
                    required>
                    <option *ngFor="let e of expenseAccounts" [value]="e.id">
                      {{e.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-12">
              <div class="card">
                <div class="card-body">
                  <label> Amount </label>
                  <input
                    [(ngModel)]="data.amt"
                    name="amt"
                    type="number"
                    min="0"
                    step="0.01"
                    class="form-control override-invalid"
                    placeholder="Amount"
                    required />
                  <label>Frequency</label>
                  <select
                    [(ngModel)]="data.period"
                    name="period"
                    class="form-control d-inline-block"
                    required>
                    <option *ngFor="let t of FixedCostPeriodKeys" [value]="t">
                      {{FixedCostPeriod[t]}}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-12">
              <div class="card">
                <div class="card-body">
                  <label> Start</label>
                  <input
                    type="text"
                    [(ngModel)]="data.start"
                    name="start"
                    class="form-control input-calendar"
                    placeholder="yyyy-mm-dd"
                    bsDatepicker
                    name="cost_start_date"
                    required />
                  <label>End (Optional)</label>
                  <input
                    type="text"
                    [(ngModel)]="data.end"
                    name="end"
                    class="form-control input-calendar"
                    placeholder="yyyy-mm-dd"
                    bsDatepicker
                    name="cost_end_date" />
                </div>
              </div>
            </div>
          </div>
          <label>What are start and end dates </label>
          The fields are optional but very useful if your fixed costs change
          sometimes and you want historical stats to be accurate. For example,
          your rent may increase each year, or your insurance rates may drop.
          Using start and end dates keeps your dash and Profit &amp; Loss
          reports accurate.
          <label class="mt-3">Do not add salaries here</label>
          Salaries are added and edited on the staff page.

          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <save-button class="float-right"></save-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
