<freemium *ngIf="isFreemium()"></freemium>

<div *ngIf="!isFreemium() && newUsers && blockedUsers" class="card">
  <div class="card-body">
    <label>Import Users</label>

    <p>
      Please review the employees and any associated information that you wish
      to import below. Click the x on the right to prevent importing the user.
    </p>

    <p>
      <strong
        >If you want your employees to be able to log in and use CheddrSuite,
        please add an email address or phone number now. Otherwise, employees
        will not have contact details associated with their account.
      </strong>
    </p>

    <p>
      <strong
        >Each employee with an email address or phone number will receive a
        welcome email with credentials to log into CheddrSuite and complete
        their employee profile. Depending on the preference selected during
        integration setup, the welcome email will be sent automatically once you
        complete this page, or manually on the Manage Staff page at a later
        date.</strong
      >
    </p>

    <p>
      If you wish to use Cheddr without employees being able to log in, you may
      clear the email and phone fields for each user and import them now.
    </p>
    <hr />
    <div *ngIf="newUsers.length>0">
      <label>New Staff</label>
      <div class="row mb-2" *ngFor="let u of newUsers">
        <div class="col-12 pt-2 body-bg">
          <div class="row">
            <div class="col">
              <input
                type="text"
                class="form-control"
                placeholder="First Name"
                [name]="'firstname_'+u.id"
                [(ngModel)]="u.firstname" />
            </div>
            <div class="col">
              <input
                type="text"
                class="form-control"
                placeholder="Last NAme"
                [name]="'lastname_'+u.id"
                [(ngModel)]="u.lastname" />
            </div>
            <div class="col">
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                [name]="'email_'+u.id"
                [(ngModel)]="u.email" />
            </div>
            <div class="col">
              <input
                type="phone"
                class="form-control"
                placeholder="Phone"
                [name]="'phone_'+u.id"
                [(ngModel)]="u.phone" />
            </div>
            <div class="col">
              <span class="mt-2 d-inline-block"
                ><strong class="ph-no-capture">Pin:</strong> {{ u.pin }}</span
              >
              <div
                class="cdi-s-cancel cdi-border float-right mt-1"
                (click)="moveList(u,newUsers,blockedUsers,IntegrationUserStatus.Blocked)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="newUsers.length>0">
      <div class="col-12">
        <button type="button" class="float-right" (click)="save()">
          Import
        </button>
      </div>
    </div>

    <div class="row" *ngIf="blockedUsers.length==0 && newUsers.length==0">
      <div class="col-12">There are no users to be imported at this time.</div>
    </div>

    <div *ngIf="blockedUsers.length>0">
      <label>Non Imported Staff</label>
      <div class="row mb-2" *ngFor="let u of blockedUsers">
        <div class="col-12 pt-2 body-bg">
          <div class="row">
            <div class="col">{{u.firstname}}</div>
            <div class="col">{{u.lastname}}</div>
            <div class="col">{{u.email}}</div>
            <div class="col">{{u.phone}}</div>
            <div class="col">
              <span class="d-inline-block"
                ><strong class="ph-no-capture">Pin:</strong> {{ u.pin }}</span
              >
              <div
                class="cdi-s-cancel cdi-border float-right pb-1"
                style="margin-top: -0.25rem"
                (click)="moveList(u,blockedUsers,newUsers,IntegrationUserStatus.New)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
