<div class="navbar navbar-expand-md subnav" *ngIf="!canCollapse">
  <ul class="navbar-nav" style="flex-direction: row">
    <li
      *ngFor="let d of menu"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: d.exact}"
      class="nav-item"
      (click)="isCollapsed=true">
      <a *ngIf="!d.click" class="nav-link" [routerLink]="d.routerLink"
        >{{d.label}}</a
      >
      <a *ngIf="d.click" class="nav-link" (click)="d.click()">
        <span
          *ngIf="d.icon"
          [ngClass]="d.icon"
          class="float-left mt-1"
          [tooltip]="d.label"
          placement="bottom"></span>

        <span class="label">{{d.label}}</span>
      </a>
    </li>

    <li
      *ngFor="let d of activeMenu?.children"
      class="nav-item"
      (click)="isCollapsed=true">
      <a *ngIf="d.routerLink" [routerLink]="d.routerLink" class="py-0 nav-link">
        <span
          *ngIf="d.icon"
          [ngClass]="d.icon"
          class="float-left mt-1"
          [tooltip]="d.label"
          placement="bottom"></span>

        <span class="label">{{d.label}}</span>
      </a>
      <a *ngIf="d.click" class="py-0 nav-link" (click)="d.click()">
        <span
          *ngIf="d.icon"
          [ngClass]="d.icon"
          class="float-left mt-1"
          [tooltip]="d.label"
          placement="bottom"></span>

        <span class="label">{{d.label}}</span>
      </a>
    </li>
  </ul>
</div>

<div class="navbar navbar-expand-md subnav" *ngIf="canCollapse">
  <ul class="navbar-nav d-flex d-md-none">
    <li class="nav-item fake-active">
      <a>{{title}}</a>
    </li>
  </ul>
  <span
    class="navbar-toggler cdi-m-soft-arrow border-circle pointer"
    [class.active]="!collapse"
    (click)="collapse=!collapse"></span>

  <div
    class="navbar-collapse collapse"
    [collapse]="collapse"
    (click)="$event.preventDefault()">
    <ul class="navbar-nav" (click)="collapse=true">
      <li
        *ngFor="let d of menu"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: d.exact}"
        class="nav-item"
        (click)="isCollapsed=true">
        <a *ngIf="!d.click" class="nav-link" [routerLink]="d.routerLink"
          >{{d.label}}</a
        >
        <a *ngIf="d.click" class="nav-link" (click)="d.click()">
          <span *ngIf="d.icon" [ngClass]="d.icon" class="float-left"></span>

          {{d.label}}
        </a>
      </li>

      <li
        *ngFor="let d of activeMenu?.children"
        class="nav-item"
        (click)="isCollapsed=true">
        <a
          *ngIf="d.routerLink"
          [routerLink]="d.routerLink"
          class="py-0 nav-link">
          <span
            *ngIf="d.icon"
            [ngClass]="d.icon"
            class="float-left mt-1"
            [tooltip]="d.label"
            placement="bottom"></span>

          <span class="label">{{d.label}}</span>
        </a>
        <a *ngIf="d.click" (click)="d.click()" class="py-0 nav-link">
          <span
            *ngIf="d.icon"
            [ngClass]="d.icon"
            class="float-left mt-1"
            [tooltip]="d.label"
            placement="bottom"></span>

          <span class="label">{{d.label}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
