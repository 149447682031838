import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import { IPaginator, getUserProfileImage } from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { TimeOffService, ITimeOff } from '../services/timeOff.service';

@Component({
  templateUrl: './timeOff.html',
})
export class TimeOffComponent extends baseRole implements OnInit {
  public data: any[];
  public _data: any[];
  public filterObject: any = {
    whole_day: 1,
  };
  public orderByField: string;
  // IPaginator
  public currentPage = 0;
  public maxPerPage = 20;
  public numPages = 0;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected timeOffService: TimeOffService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.timeOffService
      .list({ all: true })
      .pipe(
        map((data: any[]) => {
          for (let d of data) {
            this.timeOffService.fixDate(d, 'created_at');

            d.full_name = d.firstname + ' ' + d.lastname;
            d.approver =
              (d.action_firstname || '') + ' ' + (d.action_lastname || '');
            d.time = Math.round(
              (d.end_date.getTime() - d.start_date.getTime()) / 60000
            );
          }
          return data;
        })
      )
      .subscribe(data => {
        this._data = data;
        this.filter();
      });
  }

  filter() {
    if (!this._data) return;

    this.data = this._data.filter(e => {
      if (this.filterObject.name) {
        if (e.full_name.indexOf(this.filterObject.name) < 0) return false;
      }
      if (this.filterObject.whole_day > 1) {
        if (
          (this.filterObject.whole_day == 2 && e.whole_day) ||
          (this.filterObject.whole_day == 3 && !e.whole_day)
        )
          return false;
      }
      if (
        this.filterObject.date_start &&
        this.filterObject.date_start.getTime() > e.created_at.getTime()
      ) {
        return false;
      }
      if (
        this.filterObject.date_end &&
        this.filterObject.date_end.getTime() < e.created_at.getTime()
      ) {
        return false;
      }

      return true;
    });
  }
}
