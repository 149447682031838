import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';

import { LocationUserMapService } from '../services/locationUserMap.service';
import { UserService } from '../services/user.service';
import { MediaService } from '../services/media.service';

import { AccountComponent } from './account.component';
import { DashboardComponent } from './dashboard.component';
import { DocComponent } from './doc.component';
import { ProfileComponent } from './profile.component';
import { ProfileEditComponent } from './profileEdit.component';
import { ReWriteupComponent } from './rewriteup.component';
import { WorkPrefsComponent } from './workPrefs.component';

import { routing } from './profile.routing';

@NgModule({
  declarations: [
    AccountComponent,
    DashboardComponent,
    DocComponent,
    ProfileComponent,
    ProfileEditComponent,
    ReWriteupComponent,
    WorkPrefsComponent,
  ],
  imports: [SharedModule, routing],
  providers: [LocationUserMapService, MediaService],
})
export class ProfileModule {}
