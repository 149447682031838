import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
  timeToString,
  dateToLocal,
} from './base.service';
import { IChecklist, moment } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ChecklistService extends BaseService<IChecklist> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'checklist');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'start');
          this.fixDate(d, 'end');
        }
      } else {
        this.fixDate(data, 'start');
        this.fixDate(data, 'end');
      }
      return data;
    };
  }

  listWithComplete(date?: Date): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (!date) date = new Date();
    let tmp = { date: date };

    dateToLocal(tmp, 'date');
    queryParameters = queryParameters.set('date', tmp.date.toJSON());
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get<any[]>(this.baseUrl + 'listWithComplete', {
        params: queryParameters,
      })
      .pipe(
        map(this.postProcess()),
        map(data => {
          let dayOfWeek = date.getDay();
          data = data.filter(e => {
            return e.days[dayOfWeek];
          });
          let mDate = moment(new Date());
          for (let d of data) {
            let start = new Date(date);
            let end = new Date(date);
            start.setHours(d.start.getHours());
            start.setMinutes(d.start.getMinutes());
            start.setSeconds(d.start.getSeconds());
            end.setHours(d.end.getHours());
            end.setMinutes(d.end.getMinutes());
            end.setSeconds(d.end.getSeconds());
            d.start = start;
            d.end = end;
            /*d.start.setDate(date.getDate());
						d.start.setMonth(date.getMonth());
						d.start.setFullYear(date.getFullYear());
						d.end.setDate(date.getDate());
						d.end.setMonth(date.getMonth());
						d.end.setFullYear(date.getFullYear());*/
            if (!d.status) {
              /*console.log(mDate.toDate());
							console.log(d.start);
							console.log(d.end);*/
              if (mDate.isBetween(d.start, d.end)) d.pending = true;
              else if (mDate.isAfter(d.end)) d.missed = true;
              else d.before = true;
              //console.log(d);
            }
          }
          return data;
        }),
        catchError(BaseService.handleError)
      );
  }
}

export { IChecklist, ChecklistAssignmentType } from '../shared/api';
