import {
  Component,
  Input,
  Output,
  ElementRef,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { AccountService } from '../services/account.service';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './freemiumPage.html',
})
export class FreemiumPageComponent
  extends baseRole
  implements OnInit, AfterViewInit
{
  constructor(protected accountService: AccountService) {
    super(accountService);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    /*	if(!this.section) return;
		const element = document.getElementById(this.section);
		if(element) {
			window.scroll({
				top: element.scrollTop,
				behavior: 'smooth',
			}) 	
		}*/
  }
}
