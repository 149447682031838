import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { AccountService } from './account.service';

@Injectable()
export class BillingService extends BaseService<{
  id: number;
  amt: number;
  created_at: Date;
  location_id: number;
  success: boolean;
  package_type: number;
  new_package_type: number;
  billing_plan_type: number;
}> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'billing');
  }

  /*postProcess(): (data: any) => any {
		return (data)=> { 
			if(Array.isArray(data)) { 
				for(let d of data) { 
					this.fixDate(d,'start');
					this.fixDate(d,'end');
				}
			}
			else { 
				this.fixDate(data,'start');
				this.fixDate(data,'end');
			}
			return data;
		}
	}*/
}

export { BillingPlanType } from '../shared/api';
