import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IApiLocation,
  IUser,
} from '../services/account.service';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './newLocation.html',
})
export class NewLocationComponent extends baseRole implements OnInit {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  save() {
    this.router.navigate(['/settings/account']);
  }
}
