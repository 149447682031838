<div class="card" (mouseup)="mouseUp($event)" (mouseleave)="mouseMasterLeave()">
  <div class="card-body mb-2">
    <span *ngIf="!disabled" class="mb-2">
      Click the boxes below and then click on the schedule grid to set your
      employee preferences. You can assign a whole day by clicking on the name
      of the day in the first column.
    </span>
    <div class="d-flex flex-wrap flex-row justify-content-around my-2">
      <div>
        <button
          class="btn"
          (click)="curWorkState=WorkState.None"
          [disabled]="disabled">
          No Preference
        </button>
      </div>
      <div>
        <button
          class="btn"
          style="background-color: green; color: white"
          (click)="curWorkState=WorkState.Prefer"
          [disabled]="disabled">
          Prefer shifts
        </button>
      </div>
      <div>
        <button
          class="btn"
          style="background-color: pink"
          (click)="curWorkState=WorkState.Dislike"
          [disabled]="disabled">
          Dislike shifts
        </button>
      </div>
      <div>
        <button
          class="btn"
          style="background-color: red; color: white"
          (click)="curWorkState=WorkState.Cannot"
          [disabled]="disabled">
          Cannot work
        </button>
      </div>
      <div>
        <button class="btn btn-warning" *ngIf="!disabled" (click)="clearAll()">
          Clear All
        </button>
      </div>
    </div>
    <table class="pref-grid mb-3">
      <thead>
        <tr>
          <th></th>
          <th colspan="4" *ngFor="let h of HourHeader">
            {{(h==0?12:(h>12?h-12:h))}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let day of DaysOfWeekKeys">
          <td class="pointer" (click)="setWeekday(day)">{{DayList[day]}}</td>
          <td
            *ngFor="let t of TimeSlots"
            (mouseenter)="mouseEnter(day,t)"
            (mouseleave)="mouseLeave(day,t)"
            (mousedown)="mouseDown(day,t)"
            [style.background-color]="this.workStateColor[this.getState(day,t)]"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
