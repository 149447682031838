import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ITimezone, getBaseUrl } from '../shared/api';
import { BaseService } from './base.service';

@Injectable()
export class TimezoneService {
  protected baseUrl: string;
  protected defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  protected defaultOptions = {
    withCredentials: true,
    headers: this.defaultHeaders,
  };

  constructor(protected http: HttpClient) {
    this.baseUrl = getBaseUrl() + 'timezone/';
  }

  list(): Observable<ITimezone[]> {
    return this.http
      .get<ITimezone[]>(this.baseUrl)
      .pipe(catchError(BaseService.handleError));
  }
}

export { ITimezone } from '../shared/api';
