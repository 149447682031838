<div
  bsModal
  #modalDocStats="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Document Stats"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Document Stats
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body" *ngIf="doc && data">
        <div class="card" *ngFor="let d of data">
          <div class="card-body">
            <label>{{ d.firstname }} {{ d.lastname }}</label>
            <div class="row">
              <div class="col-md-6 col-12" *ngIf="!doc.quiz">
                No quiz for this doc
              </div>
              <div class="col-md-6 col-12" *ngIf="!doc.quizReady && doc.quiz">
                Quiz not ready
              </div>
              <div
                class="col-md-6 col-12"
                *ngIf="doc.quiz && !d.quiz_passed_id && doc.quizReady">
                Quiz not taken
              </div>
              <div
                class="col-md-6 col-12 text-success"
                *ngIf="doc.quiz && doc.quizReady && d.quiz_passed_id">
                <b>Quiz Passed</b> on {{d.quiz_passed_date | date }} --
                <a (click)="resetSingle(d)" class="text-warning"
                  ><b>Reset Quiz</b></a
                >
              </div>

              <div class="col-md-6 col-12" *ngIf="!doc.signature">
                No signature required
              </div>
              <div
                class="col-md-6 col-12"
                *ngIf="doc.signature && !d.signature_id">
                Unsigned
              </div>
              <div
                class="col-md-3 col-6 text-dark"
                *ngIf="doc.signature && d.signature_id">
                <b>Signed</b> by {{ d.signature_name}} on {{d.signature_date |
                date}}
              </div>
              <div
                class="col-md-3 col-6 signature"
                *ngIf="d.signature_id && d.svg"
                [innerHTML]="d.svg | safeHTML"></div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <done-button
              class="float-right ml-2"
              (click)="cancel()"></done-button>
            <button
              *ngIf="doc.quiz"
              class="btn btn-warning float-right"
              type="button"
              (click)="resetModal.show()">
              Reset Quiz for All
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #resetModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Reset Quiz"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Reset Quiz
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="resetModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="card">
          <div class="card-body">
            <label>Are you sure you want to reset this quiz?</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <cancel-button
              class="float-right ml-2"
              (click)="resetModal.hide()"></cancel-button>
            <button
              type="button"
              class="btn btn-warning float-right"
              (click)="resetAll()">
              Reset All
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
