import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from './base.service';

import { IFoodVendorIntegrationRequest } from '../shared/types'
import { environment } from 'environments/environment';

@Injectable()
export class FoodVendorService {
  private baseUrl = `${environment.vendorsIntegrationBaseUrl}credentials`;

  private defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': environment.vendorsIntegrationApiKey,
  });

  private defaultOptions = { headers: this.defaultHeaders };

  constructor(private http: HttpClient) {}

  addCredentials(data: IFoodVendorIntegrationRequest): Observable<boolean> {
    return this.http
      .post<boolean>(this.baseUrl, data, this.defaultOptions)
      .pipe(catchError(this.handleError));
  }

  getCredentialsById(ids: string): Observable<IFoodVendorIntegrationRequest[]> {
    const queryParams = new HttpParams().set('ids', ids);
    return this.http
      .get<IFoodVendorIntegrationRequest[]>(this.baseUrl, { ...this.defaultOptions, params: queryParams })
      .pipe(catchError(this.handleError));
  }

  deleteCredentialsByIdAndVendor(id: string, vendor: string): Observable<boolean> {
    const deleteUrl = `${this.baseUrl}/${id}`;
    const queryParams = new HttpParams().set('vendor', vendor);

    return this.http
      .delete<boolean>(deleteUrl, { ...this.defaultOptions, params: queryParams })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error) {
      return throwError(`Error: ${error.error.error}`);
    } else {
      return throwError(`Status: ${error.status}, Message: ${error.message}`);
    }
  }
}
