import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AccountService, IUser } from './services/account.service';
import { baseRole } from './shared/baseRole';
import { formatDateOnly } from './services/base.service';
import { IMessage, MessageService } from './services/message.service';
import { MessageCommentService } from './services/messageComment.service';
import { MessageRecipientService } from './services/messageRecipient.service';
import { LogCommentService } from './services/logComment.service';
import { LogNoteService } from './services/logNote.service';
import { LocationUserMapService } from './services/locationUserMap.service';
import {
  LogService,
  ILogReport,
  ILogSegmentedTotals,
} from './services/log.service';
import { ShiftTradeService } from './services/shiftTrade.service';
import { TimeClockService } from './services/timeClock.service';
import { EventService } from './services/event.service';
import { ShiftService } from './services/shift.service';
import { UserService } from './services/user.service';
import {
  DateMenuType,
  WidgetType,
  EventType,
  TradeType,
  getUserProfileImage,
  getMessageImage,
  textColor,
  moment,
  LogNoteType,
  LogNoteNames,
  ServiceTimeType,
  getStartEndDays,
  IApiUser,
  IApiLocation,
} from './shared/api';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { UserCreditCardService } from './services/userCreditCard.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'home',
  templateUrl: './home.html',
})
export class HomeComponent extends baseRole implements OnInit, OnDestroy {
  public logCommentsToday: any[];
  public logCommentsYesterday: any[];
  public startDate: Date;
  public reportData: any[];
  public today: ILogReport;
  public yesterday: ILogReport;
  public salesToday: any;
  public salesYesterday: any;
  public logNotesToday: any[];
  public logNotesYesterday: any[];
  public LogNoteNames = LogNoteNames;
  public ServiceTimeType = ServiceTimeType;
  public freeDaysLeft = 0;
  public hasPicked = true;
  public hasCreditCard = true;
  public expireSoonCreditCard = false;
  public blockCreditCardWarning = false;
  public primaryLastFour = '';
  public primaryExpire = '';
  public user: IApiUser;
  public locations: IApiLocation;
  public now = new Date();
  public searchText: string;
  public sub;
  public logSub;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected logService: LogService,
    protected locationUserMapService: LocationUserMapService,
    protected logNoteService: LogNoteService,
    protected logCommentService: LogCommentService,
    protected accountService: AccountService,
    protected UserCreditCardService: UserCreditCardService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Dashboard');
  }

  ngOnInit() {
    this.sub = this.accountService.getUserUpdateObservable().subscribe(() => {
      this.user = this.accountService.getUser();
      this.locations = this.accountService.getLocation();
      const loc = this.locations;
      if (!loc) {
        if (this.isAdmin()) {
          this.router.navigate(['/admin']);
        } else {
          this.router.navigate(['/profile']);
        }
        return;
      }
      if (this.accountService.isOwner()) {
        this.hasPicked = loc
          ? loc.has_picked_package || loc.block_freemium_warning
          : true;

        // Check if credit card is on file.
        this.UserCreditCardService.list().subscribe(data => {
          if (data.length > 0) {
            // Set flag to show a primary credit card is on file.
            this.hasCreditCard = true;
            for (let i = 0; i < data.length; i++) {
              if (data[i].is_primary) {
                this.primaryLastFour = data[i].last4;
                this.primaryExpire = moment(data[i].exp).format('YYYY-MM');

                // Check if primary credit card is soon to be expired or already expired.
                let expDate = moment(data[i].exp, 'YYYY-MM-DD');
                if (moment().add(1, 'month').isAfter(expDate)) {
                  this.expireSoonCreditCard = true;
                }
              }
            }
          } else {
            this.hasCreditCard = false;
          }
        });
        this.blockCreditCardWarning = loc.block_credit_card_warning;
        this.locationUserMapService
          .count({ location_id: loc.id, hide: false })
          .subscribe(data => {
            if (data == 1) {
              this.router.navigate(['/profile']);
            } else {
              this.load();
            }
          });
      } else {
        this.load();
      }
    });
  }

  load() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    let end = new Date(start);
    let yesterday = new Date(start);
    yesterday.setDate(yesterday.getDate() - 1);

    this.startDate = start;
    // This is just unread stuff..
    if (this.isAllowed(this.Permission.LogShiftData)) {
      this.logSub = this.logCommentService
        .getUpdateObservable()
        .subscribe(() => {
          this.logCommentService
            .list({
              start: formatDateOnly(yesterday),
              end: formatDateOnly(end),
            })
            .subscribe(data => {
              this.logCommentsToday = data.filter(e => {
                return e.date >= start;
              });
              this.logCommentsYesterday = data.filter(e => {
                return e.date < start;
              });
            });
        });

      this.logNoteService
        .list({ start: formatDateOnly(yesterday), end: formatDateOnly(end) })
        .subscribe(data => {
          let y = moment(yesterday);
          let t = moment(end);
          this.logNotesToday = data.filter(e => {
            return t.isSame(e.date);
          });
          this.logNotesYesterday = data.filter(e => {
            return y.isSame(e.date);
          });
        });
    }

    //
    let lastYear = new Date(start);
    // BUG BUG BUG Debug purposes only.
    //lastYear.setFullYear(lastYear.getFullYear()-1);
    lastYear.setMonth(lastYear.getMonth() - 1);

    let todayKey = moment(start).format('YYYY-MM-DD');
    let yesterdayKey = moment(yesterday).format('YYYY-MM-DD');
    if (this.isAllowed(this.Permission.ViewSales)) {
      this.logService
        .getReport({ start: lastYear, end: end })
        .subscribe(data => {
          this.reportData = data;
          let match = data.filter(e => {
            return e.date == todayKey;
          });
          if (match.length == 1) {
            this.today = match[0];
          }
          match = data.filter(e => {
            return e.date == yesterdayKey;
          });
          if (match.length == 1) {
            this.yesterday = match[0];
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.logSub) this.logSub.unsubscribe();
  }

  deleteLogComment(note) {
    this.logCommentService.delete(note.id).subscribe(data => {
      let idx = this.logCommentsToday.indexOf(note);
      if (idx >= 0) {
        this.logCommentsToday.splice(idx, 1);
      } else {
        let idx = this.logCommentsYesterday.indexOf(note);
        this.logCommentsYesterday.splice(idx, 1);
      }
    });
  }

  addLogComment(note, isToday) {
    let date = new Date();
    //		console.log(date + " - "+formatDateOnly(date));
    if (!isToday) date.setDate(date.getDate() - 1);
    let data = {
      location_id: this.getLocationId(),
      user_id: this.getUserId(),
      response: note,
      date: formatDateOnly(date),
    };
    this.logCommentService.update(data).subscribe(data => {});
  }

  search() {
    if (!this.searchText) return;
    this.router.navigate(['/search', this.searchText]);
    this.searchText = null;
  }
}

@Component({
  selector: 'home-schedule-requests',
  templateUrl: './home.scheduleRequests.html',
})
export class HomeScheduleRequestsComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public stSub;
  public accountSub;
  public data: any[];
  public shifts: any[];
  public allTrades = 0;
  public needsApproval = 0;
  public loading = false;

  public postedTrades = 0;
  public pickupsOnMyTrades = 0;
  public eligableTrades = 0;
  public pickupsRequested = 0;
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected shiftTradeService: ShiftTradeService,
    protected userService: UserService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.accountSub = this.accountService
      .getUserUpdateObservable()
      .subscribe(data => {
        if (!this.stSub) {
          this.stSub = this.shiftTradeService
            .getUpdateObservable()
            .subscribe(() => {
              //				console.log('Calling shiftTrade load');
              this.load();
            });
        } else {
          this.load();
        }
      });
  }

  ngOnDestroy() {
    if (this.stSub) this.stSub.unsubscribe();
    if (this.accountSub) this.accountSub.unsubscribe();
  }

  load() {
    if (this.loading) return;
    this.loading = true;
    this.postedTrades = 0;
    this.pickupsOnMyTrades = 0;
    this.eligableTrades = 0;
    this.pickupsRequested = 0;
    this.allTrades = 0;
    this.needsApproval = 0;

    this.shiftTradeService.list().subscribe((data: any[]) => {
      this.data = data;
      for (let d of data) {
        if (
          d.original_approval &&
          this.isAllowed(this.Permission.ApproveTrade)
        ) {
          this.needsApproval++;
        }
        if (d.user_id == this.getUserId()) {
          this.postedTrades++;
          if (d.trade_user_id && d.trade_type != TradeType.Trade)
            this.pickupsOnMyTrades++;
        } else if (d.trade_user_id == this.getUserId() && d.original_approval)
          this.pickupsRequested++;
        else if (d.trade_user_id == this.getUserId() && !d.original_approval)
          this.eligableTrades++;
        else if (
          d.is_available &&
          (!d.conflict_start || d.trade_type != TradeType.GiveUp) &&
          !d.trade_user_id
        ) {
          this.eligableTrades++;
        }
      }
      this.allTrades = this.data.length;
      this.loading = false;
    });
  }
}

@Component({
  selector: 'home-shift-list',
  templateUrl: './home.shiftList.html',
})
export class HomeShiftListComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public shifts;
  public accountSub;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected shiftService: ShiftService,
    protected timeClockService: TimeClockService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.accountSub = this.accountService
      .getUserUpdateObservable()
      .subscribe(() => {
        this.load();
      });
  }

  load() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);

    let end = new Date(start);
    end.setDate(end.getDate() + 1);

    if (!this.getLocationId()) return; // Admin type user.
    this.shiftService
      .list({
        location_id: this.getLocationId(),
        start: start,
        end: end,
        user: true,
      })
      .subscribe(data => {
        this.shifts = data;
        let now = new Date();
        for (let s of this.shifts) {
          if (s.start < now && !s.clocked) {
            s._notClocked = true;
          }
        }
        this.timeClockService
          .listClockedIn({ location_id: this.getLocationId(), no_shift: true })
          .subscribe(results => {
            let userIds: any = {};
            for (let d of results) {
              userIds[d.user_id] = true;
            }
            this.shifts = this.shifts.filter(e => {
              return !(e.user_id in userIds);
            });
            for (let d of results) {
              this.shifts.push(d);
            }
          });
      });
  }

  ngOnDestroy() {
    if (this.accountSub) this.accountSub.unsubscribe();
  }

  getUserProfileImage(
    has_image: string,
    firstName?: string,
    lastame?: string,
    color?: string
  ): string {
    return getUserProfileImage(has_image, firstName, lastame, color);
  }
}

@Component({
  selector: 'home-calendar',
  templateUrl: './home.calendar.html',
})
export class HomeCalendarComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public calendarOptions: CalendarOptions;
  public data: any[];
  public _data: any[];
  //public birthdayData: any[];
  public todaysDate: Date;
  public todaysEvents: any[] = [];
  public sub;
  public accountSub;
  public deleteObj: any;

  @ViewChild('ucCalendar')
  public fullCalendar: FullCalendarComponent;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected eventService: EventService,
    protected userService: UserService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }
  ngOnInit() {
    this.accountSub = this.accountService
      .getUserUpdateObservable()
      .subscribe(() => {
        let first = true;
        this._data = [];

        this.calendarOptions = {
          editable: false,
          //eventLimit: false,
          timeZone: 'local',
          slotEventOverlap: false,
          height: 'auto',
          headerToolbar: {
            start: 'prev',
            center: 'title',
            end: 'next',
          },
          eventClick: this.eventClick.bind(this),
          dateClick: this.dayClick.bind(this),
          events: this.load.bind(this),
        };
        if (!this.sub) {
          this.sub = this.eventService.getUpdateObservable().subscribe(() => {
            if (!first) this.refetch();
            first = false; // This is to stop a reload
          });
        } else {
          if (!first) this.refetch();
          first = false; // This is to stop a reload
        }
      });
  }

  refetch() {
    if (this.fullCalendar && this.fullCalendar.getApi()) {
      this.fullCalendar.getApi().refetchEvents();
    }
  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.accountSub) this.accountSub.unsubscribe();
  }

  load(
    arg: {
      start: Date;
      end: Date;
      startStr: string;
      endStr: string;
      timeZone: string;
    },
    successCallback,
    failureCallback
  ): void {
    let holidays = this.addHolidayEvents(arg.start, arg.end);
    //console.log(holidays);
    //let bdays = this.addBirthdays(start,end);

    this.eventService
      .shortList({ start: arg.start, end: arg.end })
      .subscribe(results => {
        //let backgroundMap: any = {};
        this.data = this._data.concat(holidays);
        //this.data = this.data.concat(bdays);
        for (let e of results) {
          // Events
          let dates = getStartEndDays(
            e.start_date,
            e.end_date,
            e.repeat_option,
            e.repeat_until,
            arg.start,
            arg.end
          );
          //console.log(e);
          //console.log(dates);
          for (let date of dates) {
            this.data.push({
              id: e.id,
              title: e.name,
              forceEventDuration: true,
              start: date.start.toDate(),
              end: date.end.toDate(),
              allDay: e.event_type == EventType.Task,
              editable: false,
              backgroundColor: e.color,
              textColor: textColor(e.color),
              extendedProps: { type: 'event', data: e },
            });
          }
        }
        successCallback(this.data);
      });
  }

  textColor(color): string {
    return textColor(color);
  }

  deleteEvent(evt) {
    this.eventService.delete(evt.id).subscribe(() => {
      if (this.todaysEvents) {
        let idx = this.todaysEvents.indexOf(evt);
        if (idx >= 0) this.todaysEvents.splice(idx, 1);
      }
    });
  }

  dayClick(data) {
    //console.log('Day Click');
    //console.log(data);
    this.updateDateClick(data.date);
  }

  updateDateClick(data) {
    let date = moment(data).local().startOf('day');
    this.todaysDate = date.toDate();
    let endDate = moment(date).add(1, 'days');
    let range = moment.range(date, endDate);
    console.log(range.start.toDate() + ' => ' + range.end.toDate());
    this.todaysEvents = this.data.filter(e => {
      console.log(e.start + ' ' + e.end);
      if (!e.end) {
        //console.log(e.start.toDate());
        return range.contains(e.start);
      } else {
        //console.log(e.start.toDate()+" - "+e.end.toDate());
        let eventRange = moment.range(e.start, e.end);
        //console.log(eventRange);
        console.log(
          eventRange.overlaps(range, { adjacent: false }) ? 'Yes' : 'No'
        );
        return eventRange.overlaps(range, { adjacent: false });
      }
    });
  }
  eventClick(data) {
    //console.log('Event Click');
    //console.log(data.event);
    return this.updateDateClick(data.event.start);
  }

  addHolidayEvents(start, end) {
    // The holidays are all in UTC, so adjust as necessary.
    let utcOffset = -moment().utcOffset();
    let dates = moment(start).holidaysBetween(end);
    let holidays: any[] = [];
    for (let d of dates) {
      d.add(utcOffset, 'minutes');
      holidays.push({
        title: d.isHoliday(),
        forceEventDuration: true,
        start: d.toDate(),
        allDay: true,
        editable: false,
        className: 'no-click',
        backgroundColor: '#b3ecff',
        extendedProps: { type: 'holiday' },
      });
    }
    return holidays;
  }
}

@Component({
  selector: 'log-skipped',
  templateUrl: './home.logSkipped.html',
})
export class HomeLogSkippedComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public accountSub;
  public data;
  public missed;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected logService: LogService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }
  ngOnInit() {
    this.accountSub = this.accountService
      .getUserUpdateObservable()
      .subscribe(() => {
        this.load();
      });
  }

  load() {
    this.logService.getSkippedLogDays().subscribe(data => {
      this.data = data;
      this.missed = data.slice(0, data.length > 10 ? 10 : data.length);
    });
  }

  ngOnDestroy() {
    if (this.accountSub) {
      this.accountSub.unsubscribe();
    }
  }

  gotoLog(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    this.accountService.notifyCurrentDateUpdate(
      DateMenuType.Daily,
      new Date(date)
    );
    this.router.navigate(['/log']);
  }
}

@Component({
  selector: 'home-repair-todo',
  templateUrl: './home.repairTodo.html',
})
export class HomeRepairTodoComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public data: any[];
  public tasks: any[];
  public repairs: any[];
  public sub;
  public accountSub;

  public selectMine = false;

  @ViewChild('modalTask')
  public modal;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected eventService: EventService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.accountSub = this.accountService
      .getUserUpdateObservable()
      .subscribe(() => {
        if (!this.sub) {
          this.sub = this.eventService.getUpdateObservable().subscribe(() => {
            this.load();
          });
        } else {
          this.load();
        }
      });
  }

  load() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    let end = moment().add(3, 'months').toDate();
    this.eventService
      .assignmentList({ event_type: EventType.Task, start: start, end: end })
      .subscribe(data => {
        this.data = data;
        for (let d of this.data) {
          let ends = getStartEndDays(
            d.start_date,
            d.end_date,
            d.repeat_option,
            d.repeat_until,
            start,
            end,
            true
          )[0];
          /*console.log('Ends');
				console.log(ends);*/
          if (ends) {
            d.start_date = ends.start.toDate();
            d.end_date = ends.end.toDate();
          }
          //		console.log(d);
        }
        this.filter();
      });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.accountSub) this.accountSub.unsubscribe();
  }

  filter() {
    this.tasks = this.data.filter(e => {
      if (e.is_repair) return false;
      if (!this.selectMine) return true;
      for (let a of e.assigned) {
        if (a.user_id == this.getUserId()) return true;
      }
      return false;
    });
    this.repairs = this.data.filter(e => {
      if (!e.is_repair) return false;
      if (!this.selectMine) return true;
      for (let a of e.assigned) {
        if (a.user_id == this.getUserId()) return true;
      }
      return false;
    });
  }

  showTask(t) {
    if (this.isAllowed(this.Permission.EditTask)) {
      this.modal.show(t);
    }
  }

  deleteTask(t) {
    this.eventService.delete(t.id).subscribe(() => {});
  }
}

@Component({
  selector: 'home-messages',
  templateUrl: './home.messages.html',
})
export class HomeMessagesComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public messages: any[];
  public noteMessage: any;
  public accountSub;
  public sub;
  public deleteMessage: any;
  public deleteComment: any;
  public signMessage: any;
  public viewImage: any;
  @ViewChild('confirmReadModal')
  public confirmReadModal;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected messageService: MessageService,
    protected messageRecipientService: MessageRecipientService,
    protected messageCommentService: MessageCommentService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.accountSub = this.accountService
      .getUserUpdateObservable()
      .subscribe(() => {
        if (!this.getUserId()) return;
        if (!this.sub) {
          this.sub = this.messageService
            .getUpdateObservable()
            .subscribe(data => {
              this.load();
            });
        } else {
          this.load();
        }
      });
  }

  load() {
    this.messageService
      .listReceived({ wall: true, archived: false })
      .subscribe(data => {
        this.messages = data;
        for (let m of this.messages) {
          for (let r of m.recipients) {
            if (r.user_id == this.getUserId()) {
              m.me = r;
              if (r.read) m.signed = false; // Unflag it as needing a signature
              break;
            }
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.accountSub) this.accountSub.unsubscribe();
  }

  deleteMessageComment(message, comment) {
    this.messageCommentService.delete(comment.id).subscribe(data => {
      let idx = message.comments.indexOf(comment);
      message.comments.splice(idx, 1);
      this.deleteMessage = null;
      this.deleteComment = null;
    });
  }

  imageUrl(m, c): string {
    return getMessageImage(this.getLocationId(), m.id, c.id);
  }

  getImageView(m, c) {
    return {
      mime_type: c.mime_type,
      url: this.imageUrl(m, c),
    };
  }

  getUserProfileImage(
    has_image: string,
    firstName?: string,
    lastName?: string,
    color?: string
  ): string {
    return getUserProfileImage(has_image, firstName, lastName, color);
  }

  markRead(archive: boolean) {
    if (!this.signMessage) return;
    let updateObj: any = {
      read: true,
    };

    if (archive) {
      updateObj.archived = true;
    }
    updateObj.id = this.signMessage.me.id;
    this.messageRecipientService.update(updateObj).subscribe(data => {
      this.signMessage.signed = false;
      if (archive) {
        let idx = this.messages.indexOf(this.signMessage);
        this.messages.splice(idx, 1);
        this.signMessage = null;
      }
      this.confirmReadModal.hide();
    });
  }

  archiveMessage() {
    if (!this.deleteMessage) return;
    let updateObj: any = {
      archived: true,
    };

    updateObj.id = this.deleteMessage.me.id;
    this.messageRecipientService.update(updateObj).subscribe(data => {
      let idx = this.messages.indexOf(this.deleteMessage);
      this.messages.splice(idx, 1);
      this.deleteMessage = null;
    });
  }

  addMessageComment(note) {
    let data = {
      message_id: this.noteMessage.id,
      user_id: this.getUserId(),
      comment: note,
    };
    this.messageCommentService.update(data).subscribe(data => {
      data.created_at = new Date();
      (<any>data).firstname = this.getUser().firstname;
      (<any>data).lastname = this.getUser().lastname;
      (<any>data).has_image = this.getUser().has_image;
      console.log(data);
      this.noteMessage.comments.push(data);
    });
  }
}
