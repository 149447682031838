<form ngNativeValidate *ngIf="vendor" class="nav-padding">
  <div class="card">
    <div class="card-header text-center">
      {{(vendor.id?'Edit':'Add')}} Vendor
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <label> Company </label>
          <input
            type="text"
            maxlength="50"
            class="form-control"
            placeholder="Company Name"
            name="company_name"
            [(ngModel)]="vendor.company"
            required />
        </div>
        <div class="col-md-6 col-xs-12">
          <label> Group </label>
          <select
            class="form-control d-inline-block"
            [(ngModel)]="vendor.contact_group_id"
            name="contact_group_id"
            required>
            <option
              *ngFor="let g of vendorGroups | orderBy: ['name','id']"
              [value]="g.id">
              {{g.name}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-0">
    <div class="card-body">
      <label> Invoice Vendor? </label>
      <input-toggle name="is_vendor" [(ngModel)]="vendor.is_vendor"
        >Show as Invoice Vendor</input-toggle
      >
      <label class="mt-3"> Order Notes</label>
      <textarea
        class="form-control"
        rows="2"
        maxlength="250"
        name="order_notes"
        [(ngModel)]="vendor.order_notes"
        placeholder="Order Notes"></textarea>
      Use to describe how items are ordered. Order Notes are displayed on the
      purchase order page to help staff place orders. E.g. Minimum order = $300.
      Order Wednesday for Friday delivery.
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <label> Title </label>
          <input
            type="text"
            maxlength="25"
            class="form-control"
            placeholder="Title"
            name="title"
            [(ngModel)]="vendor.title" />
        </div>
        <div class="col-md-6 col-xs-12">
          <label> Contact Name </label>
          <input
            type="text"
            maxlength="50"
            class="form-control"
            placeholder="Contact Name"
            name="name"
            [(ngModel)]="vendor.name" />
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Full Address </label>
      <textarea
        class="form-control"
        rows="3"
        maxlength="100"
        name="address"
        [(ngModel)]="vendor.address"
        placeholder="Full Address"></textarea>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <label> Office Phone </label>
          <input
            type="tel"
            class="form-control"
            name="phone"
            phone
            [(ngModel)]="vendor.phone" />
        </div>
        <div class="col-md-6 col-xs-12">
          <label> Contact Phone </label>
          <input
            type="tel"
            class="form-control"
            name="cell"
            phone
            [(ngModel)]="vendor.cell" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <label> Fax </label>
          <input
            type="tel"
            class="form-control"
            name="fax"
            phone
            [(ngModel)]="vendor.fax" />
        </div>
        <div class="col-md-6 col-xs-12">
          <label> Email </label>
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            name="email"
            [(ngModel)]="vendor.email" />
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label> Tags </label>
      <textarea
        class="form-control"
        rows="2"
        maxlength="1000"
        name="tags"
        placeholder="Tags"
        [(ngModel)]="vendor.tags"></textarea>
      <small> Tags are used by Search </small>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label> Notes </label>
      <textarea
        class="form-control"
        rows="2"
        maxlength="2500"
        name="notes"
        [(ngModel)]="vendor.notes"
        placeholder="Notes"></textarea>
      <small> Notes are used by Search </small>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <cancel-button class="float-right" (click)="cancel()"></cancel-button>
      <save-button class="float-right" (click)="save()"></save-button>
    </div>
  </div>
</form>
