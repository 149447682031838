import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IMessage } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class MessageService extends BaseService<IMessage> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'message');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (!data) return data;
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'created_at');
        }
      } else {
        this.fixDate(data, 'created_at');
      }
      return data;
    };
  }

  listSent(filter?: any): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl + 'sent/list', { params: queryParameters })
      .pipe(map(this.postProcess()));
  }

  listReceived(filter?: any): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl + 'received/list', { params: queryParameters })
      .pipe(map(this.postProcess()));
  }

  countSent(filter?: any): Observable<number> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http.get<number>(this.baseUrl + 'sent/count', {
      params: queryParameters,
    });
  }

  countReceived(filter?: any): Observable<number> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http.get<number>(this.baseUrl + 'received/count', {
      params: queryParameters,
    });
  }
}

export const MessagePageSize = 25;

export { IMessage } from '../shared/api';
