import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IApiLocation } from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { LocationService, ILocation } from '../services/location.service';
import { MediaService } from '../services/media.service';
import { States, getUserProfileImage } from '../shared/api';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';

@Component({
  templateUrl: './workPrefs.html',
})
export class WorkPrefsComponent implements OnInit {
  public saveMessage: string = null;
  public user: IUser;
  public userMap: ILocationUserMap;
  public loc: IApiLocation;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected locationUserMapService: LocationUserMapService
  ) {}

  ngOnInit() {
    this.locationUserMapService
      .list({
        user_id: this.accountService.getUserId(),
        location_id: this.accountService.getLocationId(),
      })
      .subscribe(data => {
        this.userMap = data[0];
      });
  }

  save() {
    this.saveMessage = 'Done.';
    this.locationUserMapService.update(this.userMap).subscribe(data => {
      setTimeout(() => {
        this.saveMessage = null;
      }, 3000);
    });
  }
}
