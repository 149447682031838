import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
  formatDateOnly,
} from './base.service';
import { ILog, ILogSegmentedTotals, ILogReport, moment } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class LogService extends BaseService<ILog> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'log');
  }

  getStatPeriod(): { start: Date; end: Date } {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    let endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() - 28);
    return { start: startDate, end: endDate };
  }

  getDailyTotals(data: {
    start: Date;
    end: Date;
    zip?: string;
  }): Observable<any[]> {
    let userFilter = this.accountService.getUserFilter();
    this.formatDateOnly(data, 'start');
    this.formatDateOnly(data, 'end');
    data = userFilter.updateObject(data);
    return this.http.post<any[]>(
      this.baseUrl + 'daily',
      data,
      this.defaultOptions
    );
  }

  sumTotal(data: { start: Date; end: Date }): Observable<any> {
    let userFilter = this.accountService.getUserFilter();
    this.formatDateOnly(data, 'start');
    this.formatDateOnly(data, 'end');
    data = userFilter.updateObject(data);
    return this.http.post<any[]>(
      this.baseUrl + 'sumTotal',
      data,
      this.defaultOptions
    );
  }

  // Only used in modalAdjustSales
  sumBreakdown(start: Date): Observable<{
    lastYear: number;
    lastMonth: number;
    lastWeek: number;
    thisWeek: number;
    data: {};
  }> {
    let end = moment(start).add('7', 'days');
    let lastYearStart = moment(start).subtract(1, 'years');
    let lastYearEnd = moment(end);
    let lastWeekStart = moment(start).subtract(7, 'days');
    let lastWeekEnd = moment(end).subtract(7, 'days');
    let lastMonthStart = moment(start).subtract(4, 'weeks');
    let lastMonthEnd = moment(end).subtract(4, 'weeks');

    let userFilter = this.accountService.getUserFilter();
    let data = {
      start: lastYearStart.toDate(),
      end: end,
    };
    this.formatDateOnly(data, 'start');
    this.formatDateOnly(data, 'end');
    data = userFilter.updateObject(data);
    return this.http
      .post<any[]>(this.baseUrl + 'daily', data, this.defaultOptions)
      .pipe(
        map(data => {
          let result = {
            lastYear: 0,
            lastMonth: 0,
            lastWeek: 0,
            thisWeek: 0,
            data: {},
          };
          for (let d of data) {
            let date = moment(d.date);
            result.data[date.format('YYYY-MM-DD')] = d;
            if (date.isBetween(lastYearStart, lastYearEnd))
              result.lastYear += d.sales;
            if (date.isBetween(lastMonthStart, lastMonthEnd))
              result.lastMonth += d.sales;
            if (date.isBetween(lastWeekStart, lastWeekEnd))
              result.lastWeek += d.sales;
            if (date.isBetween(start, end)) result.thisWeek += d.sales;
          }
          return result;
        })
      );
  }

  getSegmentedDailyTotals(data: {
    start: Date;
    end: Date;
  }): Observable<ILogSegmentedTotals[]> {
    let userFilter = this.accountService.getUserFilter();
    this.formatDateOnly(data, 'start');
    this.formatDateOnly(data, 'end');
    data = userFilter.updateObject(data);
    return this.http
      .post<any[]>(this.baseUrl + 'segmentedDaily', data, this.defaultOptions)
      .pipe(
        map(data => {
          for (let d of data) {
            this.fixDate(d, 'date');
          }
          return data;
        })
      );
  }

  getReport(data: any): Observable<ILogReport[]> {
    let userFilter = this.accountService.getUserFilter();
    let d = Object.assign({}, data);
    this.formatDateOnly(d, 'start');
    this.formatDateOnly(d, 'end');
    d = userFilter.updateObject(d);
    return this.http
      .post<any[]>(this.baseUrl + 'report', d, this.defaultOptions)
      .pipe(
        map(data => {
          for (let d of data) {
            d.d = moment(d.date);
            if ('users' in d && 'timeclocks' in d) {
              for (let u of d.users) {
                u.timeclocks = d.timeclocks.filter(e => {
                  return e.user_id == u.id;
                });
              }
            }
            if ('users' in d && 'shifts' in d) {
              for (let u of d.users) {
                u.shifts = d.shifts.filter(e => {
                  return e.user_id == u.id;
                });
              }
              for (let s of d.shifts) {
                s.user = d.users.filter(e => {
                  return e.id == s.user_id;
                })[0];
              }
            }
          }
          data.sort((a, b) => {
            return b.d - a.d;
          });
          return data;
        })
      );
  }

  getSalesReport(data: {
    start: Date;
    end: Date;
    search_field: string;
    service_time_id?: number;
  }): Observable<{ date: string; value: number; service_time_type: number }[]> {
    let userFilter = this.accountService.getUserFilter();
    let d = Object.assign({}, data);
    this.formatDateOnly(d, 'start');
    this.formatDateOnly(d, 'end');
    d = userFilter.updateObject(d);
    return this.http.post<any[]>(
      this.baseUrl + 'salesReport',
      d,
      this.defaultOptions
    );
    /*.map((data) => { 
			for(let d of data) { 
				d.d = moment(d.date);
				if('users' in d && 'timeclocks' in d) {
					for(let u of d.users) { 
						u.timeclocks = d.timeclocks.filter((e)=> { return e.user_id == u.id });
					}
				}
				if('users' in d && 'shifts' in d) {
					for(let u of d.users) { 
						u.shifts = d.shifts.filter((e)=> { return e.user_id == u.id });
					}
					for(let s of d.shifts) { 
						s.user = d.users.filter((e)=> { return e.id == s.user_id})[0];
					}
				}
			}
			data.sort((a,b)=> { return b.d-a.d; });
			return data;
		});*/
  }

  getSkippedLogDays(): Observable<Date[]> {
    let userFilter = this.accountService.getUserFilter();
    let data: any = {};
    data = userFilter.updateObject(data);
    return this.http
      .post<{ date: Date }[]>(
        this.baseUrl + 'logDays',
        data,
        this.defaultOptions
      )
      .pipe(
        map(data => {
          let loc = this.accountService.getLocation();
          let out: Date[] = [];
          let outMap: any = {};
          if (!data || data.length == 0) return out;
          for (let d of data) {
            this.fixDate(d, 'date');
            outMap[d.date.getTime()] = d;
          }
          let now = new Date();
          now.setDate(now.getDate() - 1); // Start yesterday.
          for (
            let startDate = data[0].date;
            startDate < now;
            startDate.setDate(startDate.getDate() + 1)
          ) {
            if (startDate.getTime() in outMap) continue;
            if (loc.dark_days[startDate.getDay()]) continue; // It's a dark day
            out.push(new Date(startDate));
          }
          return out;
        })
      );
  }
}

export function dateStringToUTC(date: string): number {
  let pieces = date.split('-');
  return Date.UTC(+pieces[0], +pieces[1], +pieces[2]);
}

export {
  ILog,
  DateMenuType,
  ServiceTimeType,
  ILogSegmentedTotals,
  ILogReport,
} from '../shared/api';
