import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IBlackOut } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class BlackOutService extends BaseService<IBlackOut> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'blackOut');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'start');
          this.fixDate(d, 'end');
        }
      } else {
        this.fixDate(data, 'start');
        this.fixDate(data, 'end');
      }
      return data;
    };
  }

  listCurrent(): Observable<IBlackOut[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl + 'current', { params: queryParameters })
      .pipe(map(this.postProcess()), catchError(BaseService.handleError));
  }
}

export { IBlackOut } from '../shared/api';
