import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import { BlackOutService, IBlackOut } from '../services/blackOut.service';
import { EventService, IEvent, EventType } from '../services/event.service';
import { TimeOffService, ITimeOff } from '../services/timeOff.service';
import { baseRole } from '../shared/baseRole';
import { UserService, IUser } from '../services/user.service';
import { getStartEndDays, moment } from '../shared/api';

@Component({
  selector: 'modal-timeoff-approval',
  templateUrl: 'modalTimeOffApproval.html',
})
export class ModalTimeOffApprovalComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public blackOuts: any[];
  public pendingTimeOffs: any[];
  //public eventDates: any[];
  public timeOffs: any[];
  public sub;
  private data: any[];

  @Input() hideDisplay = false;

  @ViewChild('modal')
  public modal: ModalDirective;

  constructor(
    public accountService: AccountService,
    public timeOffService: TimeOffService,
    public blackOutService: BlackOutService,
    public eventService: EventService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.sub = this.timeOffService.getUpdateObservable().subscribe(data => {
      this.loadTimeOffs();
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  deny(obj) {
    this.timeOffService.approved(obj.id, false).subscribe(data => {});
  }

  approve(obj) {
    this.timeOffService.approved(obj.id, true).subscribe(data => {});
  }

  doConflicts() {
    //console.log('Doing conflicts');

    for (let p of this.pendingTimeOffs) {
      p.conflicts = [];
    }
    /*console.log(this.blackOuts);
		console.log(this.pendingTimeOffs);*/
    if (this.blackOuts) {
      for (let b of this.blackOuts) {
        b.range = moment.range(b.start, b.end);
        for (let p of this.pendingTimeOffs) {
          if (p.range.overlaps(b.range)) {
            p.conflicts.push({
              is_blackout: true,
              name: 'Blackout Date',
              start: b.start,
              end: b.end,
            });
          }
        }
      }
    }
    if (this.data && this.pendingTimeOffs && this.pendingTimeOffs.length > 0) {
      let min = this.pendingTimeOffs[0].start_date;
      let max = this.pendingTimeOffs[0].end_date;
      for (let d of this.pendingTimeOffs) {
        if (d.start_date < min) min = d.start_date;
        if (d.end_date > max) max = d.end_date;
      }
      for (let e of this.data) {
        let dates = getStartEndDays(
          e.start_date,
          e.end_date,
          e.repeat_option,
          e.repeat_until,
          min,
          max
        );

        // Check the array for conflicts.
        for (let date of dates) {
          let range = moment.range(date.start, date.end);
          for (let p of this.pendingTimeOffs) {
            //					console.log(p);
            //					console.log(date);
            if (p.range.overlaps(range)) {
              p.conflicts.push({
                is_event: true,
                name: e.name,
                start: date.start,
                end: date.end,
              });
            }
          }
        }
      }
    }
  }

  load() {
    this.blackOutService.listCurrent().subscribe(data => {
      this.blackOuts = data;
      //console.log(this.blackOuts);
      for (let b of this.blackOuts) {
        b.end.setHours(23);
        b.end.setMinutes(59);
        b.end.setSeconds(59);
      }

      this.loadTimeOffs();
    });
  }

  loadTimeOffs() {
    this.timeOffService.list({ current: true }).subscribe(data => {
      //console.log('Got Data');
      //console.log(data);
      let toDays = this.accountService.getLocation().to_days;
      for (let d of data) {
        (<any>d).range = moment.range(d.start_date, d.end_date);
        (<any>d)._too_close = moment(d.start_date)
          .subtract(toDays, 'days')
          .isBefore(d.created_at);
      }
      this.pendingTimeOffs = data.filter(e => {
        return !e.action_at;
      });
      this.timeOffs = data.filter(e => {
        return e.action_at;
      });
      if (this.pendingTimeOffs.length > 0) {
        let min = this.pendingTimeOffs[0].start_date;
        let max = this.pendingTimeOffs[0].end_date;
        for (let d of this.pendingTimeOffs) {
          if (d.start_date < min) min = d.start_date;
          if (d.end_date > max) max = d.end_date;
        }
        //this.eventDates = [];
        this.eventService
          .shortList({
            start: min,
            end: max,
            event_type: EventType.Event,
            is_repair: false,
          })
          .subscribe(data => {
            this.data = data.filter(e => {
              return !e.is_repair;
            });
            this.doConflicts();
          });
      }

      this.doConflicts();
    });
  }
  show() {
    this.load();
    this.modal.show();
  }

  cancel() {
    this.modal.hide();
  }

  delete(obj) {
    this.timeOffService.delete(obj.id).subscribe(data => {});
  }
}
