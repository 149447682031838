import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService, IUser } from '../services/account.service';
import {
  ISubNavItem,
  UserType,
  PackageType,
  moment,
  isIntegrationActive,
} from '../shared/api';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './settings.html',
})
export class SettingsComponent extends baseRole implements OnInit {
  public menu: ISubNavItem[];
  public _menu: ISubNavItem[] = [
    {
      label: 'Log Settings',
      routerLink: '/settings',
      exact: true,
    },
    {
      label: 'Schedule Settings',
      routerLink: '/settings/schedule',
    },
    {
      label: 'Dash Options',
      routerLink: '/settings/dashboard',
    },
    {
      label: 'Cost Options',
      routerLink: '/settings/cost',
    },
    {
      label: 'Biz Goals',
      routerLink: '/settings/goals',
    },
    {
      label: 'Permissions',
      routerLink: '/settings/permissions',
    },
    {
      label: 'Integration',
      routerLink: '/settings/integration',
    },
    {
      label: 'Account',
      routerLink: '/settings/account',
    },
  ];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Settings');
  }
  ngOnInit() {
    let loc = this.getLocation();
    if (
      loc.user_type != UserType.Owner &&
      !this.isAllowed(this.Permission.AccessSettings)
    ) {
      this.router.navigate(['/']);
      return;
    }
    if (this.accountService.getLocations().length == 0) {
      this.menu = [
        {
          label: 'Account',
          routerLink: '/settings/account',
        },
      ];
    } else {
      this.menu = this._menu.slice();
      if (!this.isAllowed(this.Permission.CreateBudget)) {
        this.menu = this.menu.filter(e => {
          return !(e.routerLink.indexOf('/settings/goals') >= 0);
        });
      }
      if (!isIntegrationActive(loc)) {
        this.menu = this.menu.filter(e => {
          return e.label != 'Integration';
        });
      }
    }
  }
}
