<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Inoivce Edit"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Receive Purchase Order
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <form
        ngNativeValidate
        class="modal-body"
        *ngIf="data && invoice"
        (submit)="save()">
        <div class="row nav-padding">
          <div class="col-12 text-white font-headers">
            <ul class="list-group">
              <li class="list-group-item blue-bg">
                <h5 class="float-left my-0 pt-2 pb-0">Received Cost</h5>
                <h3 class="float-right my-0 pt-2 pb-0">
                  {{ total | currency: 'USD' }}
                </h3>
              </li>
            </ul>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <label>{{data.contact_company}}</label>
            <div class="row" *ngIf="isAllowed(Permission.EditInvoices)">
              <div class="col-md-4">
                <label> Received Date</label>
                <input
                  type="text"
                  class="form-control input-calendar"
                  bsDatepicker
                  [(ngModel)]="invoice.date"
                  name="invoice_date"
                  required />
              </div>
              <div class="col-md-4">
                <label>Invoice #</label>
                <input
                  type="text"
                  [(ngModel)]="invoice.ponumber"
                  maxlength="50"
                  class="form-control"
                  name="invoiceNumber"
                  placeholder="Enter Invoice #"
                  required
                  (ngModelChange)="checkPONumber()" />
                <input-toggle
                  class="mb-2"
                  name="add-invoice"
                  [(ngModel)]="add_invoice"
                  (ngModelChange)="addInvoiceChange()"
                  >Add Invoice</input-toggle
                >
                <local-error [error]="errorPONumber"></local-error>
              </div>
              <div class="col-md-4">
                <label>Terms</label>
                <select
                  name="terms"
                  [(ngModel)]="invoice.terms"
                  class="form-control">
                  <option value="0" selected>Due on delivery</option>
                  <option value="7">Net 7</option>
                  <option value="14">Net 14</option>
                  <option value="21">Net 21</option>
                  <option value="30">Net 30</option>
                  <option value="60">Net 60</option>
                  <option value="90">Net 90</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row font-weight-bold p-1 mx-0"
          style="background-color: #c6c8ca">
          <div class="col-3">SKU</div>
          <div class="col-2">Ordered</div>
          <div class="col-2">Received</div>
          <div class="col-2">Price</div>
          <div class="col-3">Extended</div>
        </div>
        <div class="row mx-0 border-bottom" *ngFor="let s of skus">
          <div class="col-md-3">
            <span class="d-block-inline d-md-none text-primary">SKU: </span
            >{{s.name}}
          </div>
          <div class="col-md-2">
            <span class="d-block-inline d-md-none text-primary">Ordered: </span
            >{{s.qty}} {{s.buying_unit}}(s)
          </div>
          <div class="col-md-2">
            <span class="d-block-inline d-md-none text-primary">Received:</span>
            <input
              [(ngModel)]="s.qty_received"
              (ngModelChange)="recalc()"
              [name]="'received_'+s.id"
              type="number"
              min="0"
              class="inline-form-control"
              placeholder="0"
              required />
          </div>
          <div class="col-md-2">
            <span class="d-block-inline d-md-none text-primary">Price: </span>
            <input
              type="number"
              [name]="'amt_'+s.id"
              [(ngModel)]="s.price_received"
              (ngModelChange)="recalc()"
              min="0"
              step=".01"
              class="inline-form-control"
              placeholder="30" />
          </div>
          <div class="col-md-3">
            <span class="d-block-inline d-md-none text-primary"
              >Extended:
            </span>
            {{ s.qty_received*s.price_received | currency: 'USD' }}
          </div>
        </div>

        <div
          class="card"
          *ngIf="isAllowed(Permission.EditInvoices) && add_invoice && newItem">
          <div class="card-body">
            <label>Line Items</label>
            <div class="input-group">
              <input
                type="text"
                [(ngModel)]="newItem.name"
                name="new_item_name"
                maxlength="100"
                class="form-control"
                style="width: 40%"
                placeholder="Line Item Name" />
              <select
                [(ngModel)]="newItem.expense_account_id"
                class="form-control"
                style="width: 30%"
                name="item_category">
                <option
                  *ngFor="let e of expenseAccounts | orderBy: 'name'"
                  [value]="e.id">
                  {{e.name}}
                </option>
                <!--								<option [value]='null'>Add New Category</option> -->
              </select>
              <!--They messed up here, owner can turn off BOH FOH  BUG BUG BUG-->
              <div class="input-group-append">
                <!-- amount accepts only digits and decimal. and limited to 2 places after decimal  -->
                <span class="input-group-text">$</span>
                <input
                  [(ngModel)]="newItem.amount"
                  type="number"
                  step="0.01"
                  min="0"
                  class="form-control mb-0"
                  placeholder="00.00"
                  name="newItemAmount" />
                <span class="cdi-s-add pointer" (click)="addItem()"></span>
              </div>
            </div>
            <div *ngFor="let item of items;let i = index">
              <div class="input-group">
                <input
                  type="text"
                  [(ngModel)]="item.name"
                  [name]="'line_item_name'+i"
                  maxlength="100"
                  required
                  class="form-control"
                  style="width: 40%" />
                <select
                  [(ngModel)]="item.expense_account_id"
                  class="form-control"
                  style="width: 30%"
                  [name]="'item_category_list_'+i">
                  <option
                    *ngFor="let e of expenseAccounts | orderBy: 'name'"
                    [value]="e.id">
                    {{e.name}}
                  </option>
                </select>
                <div class="input-group-append">
                  <!-- amount accepts only digits and decimal. and limited to 2 places after decimal  -->
                  <span class="input-group-text">$</span>
                  <input
                    [(ngModel)]="item.amount"
                    type="number"
                    step="0.01"
                    class="form-control mb-0"
                    placeholder="00.00"
                    [name]="'amount_'+i"
                    (ngModelChange)="recalc()" />
                  <span
                    class="cdi-s-trash pointer"
                    (click)="deleteItem(item)"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="cancel()"></cancel-button>
            <save-button
              class="float-right"
              *ngIf="!errorPONumber"></save-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
