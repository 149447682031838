import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

var timeoutTimer = null;
var needsTimeoutTimer = true;
var timeoutSubject: ReplaySubject<boolean> = <ReplaySubject<boolean>>(
  new ReplaySubject(1)
);

@Injectable()
export class TimeoutService {
  // Handle timeout stuff.
  getTimeoutObservable() {
    return timeoutSubject.asObservable();
  }

  setNeedsTimeout(value) {
    needsTimeoutTimer = value;
  }

  startTimeout() {
    if (!needsTimeoutTimer || timeoutTimer === null) return;
    //console.log('Starting Timeout');
    timeoutTimer = setTimeout(() => {
      if (!needsTimeoutTimer) return;
      timeoutSubject.next(true);
    }, 15 * 60 * 1000);
  }
  resetTimeout() {
    if (timeoutTimer) {
      //console.log('Reset Timeout');
      clearTimeout(timeoutTimer);
      timeoutTimer = null;
    }
    this.startTimeout();
  }
  cancelTimeout() {
    //console.log('Cancel timeout');
    if (timeoutTimer) {
      clearTimeout(timeoutTimer);
      timeoutTimer = null;
    }
  }
}
