import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { LocationService, ILocation } from '../services/location.service';
import {
  IntegrationUserService,
  IIntegrationUser,
  IntegrationUserStatus,
} from '../services/integrationUser.service';
import { baseRole } from '../shared/baseRole';
import { UserType } from '../shared/api';

@Component({
  templateUrl: './integration.user.html',
})
export class IntegrationUserComponent extends baseRole implements OnInit {
  public newUsers: IIntegrationUser[];
  public blockedUsers: IIntegrationUser[];
  public IntegrationUserStatus = IntegrationUserStatus;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected integrationUserService: IntegrationUserService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.accountService.getLocation()) {
      this.router.navigate(['/settings/account']);
      return;
    }
    this.load();
  }

  load() {
    this.newUsers = null;
    this.blockedUsers = null;
    this.integrationUserService.list().subscribe(data => {
      this.newUsers = data.filter(e => {
        return e.integration_user_status == IntegrationUserStatus.New;
      });
      this.blockedUsers = data.filter(e => {
        return e.integration_user_status == IntegrationUserStatus.Blocked;
      });
    });
  }

  moveList(user, fromList, toList, newStat) {
    this.integrationUserService
      .update({ id: user.id, integration_user_status: newStat })
      .subscribe(() => {
        user.integration_user_status = newStat;
        let idx = fromList.indexOf(user);
        fromList.splice(idx, 1);
        toList.unshift(user);
      });
  }

  save() {
    for (let d of this.newUsers) {
      d.integration_user_status = IntegrationUserStatus.Import;
    }
    // Force a blank out on load.
    let data = this.newUsers;
    this.newUsers = null;
    this.integrationUserService.userUpdate(data).subscribe(() => {
      this.load();
    });
  }
}
