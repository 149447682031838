import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { getBaseUrl } from '../shared/api';

import { AccountService } from './account.service';

@Injectable()
export class IntegrationService {
  protected baseUrl: string;
  protected defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  protected defaultOptions = { headers: this.defaultHeaders };

  constructor(
    protected http: HttpClient,
    protected accountService: AccountService
  ) {
    this.baseUrl = getBaseUrl() + 'integration/';
  }

  squareUrl(): Observable<string> {
    return this.http
      .get<string>(this.baseUrl + 'square/url')
      .pipe(catchError(BaseService.handleError));
  }

  lightspeedUrl(): Observable<string> {
    return this.http
      .get<string>(this.baseUrl + 'lightspeed/url')
      .pipe(catchError(BaseService.handleError));
  }

  squareLocations(): Observable<{ id: string; name: string }[]> {
    return this.http
      .get<{ id: string; name: string }[]>(
        this.baseUrl +
          'square/locations?location_id=' +
          this.accountService.getLocationId()
      )
      .pipe(catchError(BaseService.handleError));
  }

  lightspeedLocations(): Observable<{ id: string; name: string }[]> {
    return this.http
      .get<{ id: string; name: string }[]>(
        this.baseUrl +
          'lightspeed/locations?location_id=' +
          this.accountService.getLocationId()
      )
      .pipe(catchError(BaseService.handleError));
  }

  setSalesIntegrationType(data: {
    location_id: number;
    sales_integration_type: number;
  }): Observable<boolean> {
    return this.http
      .post<boolean>(this.baseUrl + 'type', data)
      .pipe(catchError(BaseService.handleError));
  }

  setSalesIntegrationKey(data: {
    location_id: number;
    sales_integration_token: string;
  }): Observable<boolean> {
    return this.http
      .post<boolean>(this.baseUrl + 'key', data)
      .pipe(catchError(BaseService.handleError));
  }
}
