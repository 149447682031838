import { IShift } from '../services/shift.service';
import * as moment from 'moment';

export type IShiftBreak = {
  toggle: boolean;
  time?: Date;
  length?: number;
};

function beforeAfterShiftValidation(
  breakTime: Date,
  breakLength: number,
  startTime: Date,
  endTime: Date,
  breakTimeString: string
): string[] {
  let error: string[] = [];
  if (breakLength) {
    if (breakTime < startTime) {
      error.push(
        `You cannot schedule the ${breakTimeString} break before the shift started`
      );
    }
    const breakTimeWithBreakLength = moment(breakTime)
      .add(breakLength, 'm')
      .toDate();
    if (breakTimeWithBreakLength > endTime) {
      error.push(
        `You cannot schedule the ${breakTimeString} break after the shift ended`
      );
    }
  }
  return error;
}
function checkBreakTimeOverlap(
  breakTimeArgOne: Date,
  breakLengthArgOne: number,
  breakTimeArgTwo: Date,
  breakTimeArgOneString: string,
  breakTimeArgTwoString: string
): string | null {
  if (breakTimeArgOne && breakTimeArgTwo && breakLengthArgOne) {
    const breakTimeWithBreakLength = moment(breakTimeArgOne)
      .add(breakLengthArgOne, 'm')
      .toDate();
    if (breakTimeWithBreakLength > breakTimeArgTwo) {
      return `The ${breakTimeArgOneString} break time must not overlap the ${breakTimeArgTwoString} break time`;
    }
  }
  return null;
}
export function shiftBreakValidation(shifts: IShift[]): string[] {
  let error: string[] = [];
  shifts.forEach(shift => {
    error.push(
      checkBreakTimeOverlap(
        shift.first_break_time,
        shift.first_break_length,
        shift.second_break_time,
        'first',
        'second'
      )
    );
    error.push(
      checkBreakTimeOverlap(
        shift.first_break_time,
        shift.first_break_length,
        shift.third_break_time,
        'first',
        'third'
      )
    );
    error.push(
      checkBreakTimeOverlap(
        shift.second_break_time,
        shift.second_break_length,
        shift.third_break_time,
        'second',
        'third'
      )
    );
    error.push(
      ...beforeAfterShiftValidation(
        shift.first_break_time,
        shift.first_break_length,
        shift.start,
        shift.end,
        'first'
      )
    );
    error.push(
      ...beforeAfterShiftValidation(
        shift.second_break_time,
        shift.second_break_length,
        shift.start,
        shift.end,
        'second'
      )
    );
    error.push(
      ...beforeAfterShiftValidation(
        shift.third_break_time,
        shift.third_break_length,
        shift.start,
        shift.end,
        'third'
      )
    );
  });
  return error;
}
export function createShiftBreakTime(
  startDate: Date,
  breakTime: Date,
  breakTimeOffset: number
): Date {
  let breakDateObj = moment(new Date(startDate)).format('YYYY-MM-DD');
  if (breakTime < startDate) {
    if (breakTimeOffset) {
      breakDateObj = moment(new Date(startDate))
        .add(breakTimeOffset, 'days')
        .format('YYYY-MM-DD');
    }
  }
  const breakTimeObj = moment(breakTime).format('HH:mm:ss');

  const breakDateTimeObj = moment(
    breakDateObj + breakTimeObj,
    'YYYY-MM-DDTHH:mm:ss'
  );
  return breakDateTimeObj.toDate();
}
