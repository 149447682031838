import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  AccountService,
  IApiUser,
  UserType,
} from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { PositionService, IPosition } from '../services/position.service';
import { LocationService, ILocation } from '../services/location.service';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-filter-users',
  templateUrl: 'modalFilterUsers.html',
})
export class ModalFilterUsersComponent extends baseRole implements OnInit {
  public positions: IPosition[];
  public loc: ILocation;

  //	public fohButton: number;
  public permission_level_1: boolean;
  public permission_level_2: boolean;
  public permission_level_3: boolean;
  public UserType = UserType;
  private nofilter: boolean = true;

  @ViewChild('modal')
  public modal: ModalDirective;

  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();

  public filter: any;

  constructor(
    public accountService: AccountService,
    public positionService: PositionService,
    public locationService: LocationService,
    public UserService: UserService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.filter = this.accountService.getTempState('user_manage_filter', {
      position_ids: {},
      user_type: [],
      foh: null,
      order_by: 'lastname',
    });
    //	console.log('Starting');
    //	console.log(this.filter);
  }

  show() {
    this.load();
  }

  load() {
    this.positionService.list().subscribe(data => {
      this.positions = data;
      for (let d of data) {
        d._selected = d.id in this.filter.position_ids;
      }
    });

    this.locationService
      .get(this.accountService.getLocationId())
      .subscribe(data => {
        this.loc = data;
      });

    this.modal.show();
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    this.filter.position_ids = {};
    // Clear can cause this to be null
    if (this.positions) {
      let selectedPos = this.positions.filter(e => {
        return e._selected;
      });
      for (let p of selectedPos) {
        this.filter.position_ids[p.id] = true;
      }
      if (
        selectedPos.length > 0 ||
        this.filter.user_type[UserType.Regular] ||
        this.filter.user_type[UserType.ShiftLead] ||
        this.filter.user_type[UserType.Manager]
      ) {
        this.nofilter = false;
      }
    }
    //		console.log(this.filter);
    this.accountService.setTempState('user_manage_filter', this.filter);
    this.filterChange.emit(this.filter);
    this.modal.hide();
  }

  clearHouses() {
    this.filter.foh = null;
  }

  switchPositions(foh) {
    for (let p of this.positions) {
      if ((p.position_type == 1 && foh) || (p.position_type == 2 && !foh)) {
        p._selected = true;
      } else {
        p._selected = false;
      }
    }
  }

  noFilter() {
    this.nofilter = true;
    //	console.log(this.filter);
    (this.filter.position_ids = {}),
      (this.filter.user_type[UserType.Regular] = false);
    this.filter.user_type[UserType.ShiftLead] = false;
    this.filter.user_type[UserType.Manager] = false;
    this.filter.foh = null;

    if (this.positions) {
      for (let p of this.positions) {
        p._selected = false;
      }
    }
  }

  clearFilter() {
    // used by manage.html to clear filter
    this.noFilter();
    this.save(); // get the main page redrawn
  }
}
