import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
import { InvoiceService, IInvoice } from '../services/invoice.service';
import {
  InvoiceItemService,
  IInvoiceItem,
} from '../services/invoiceItem.service';
import { ContactService, IContact } from '../services/contact.service';
import {
  ContactGroupService,
  IContactGroup,
} from '../services/contactGroup.service';
import { baseRole } from '../shared/baseRole';

const defaultItem = {
  name: '',
  expense_account_id: null,
  amount: null,
};

@Component({
  selector: 'modal-invoice',
  templateUrl: 'modalInvoice.html',
})
export class ModalInvoiceComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;
  @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();
  public invoice: IInvoice;
  public vendors: IContact[];
  public contactGroups: IContactGroup[];
  public expenseAccounts: IExpenseAccount[];
  public items: IInvoiceItem[];
  public vendorName: string;
  public addThenClose: boolean = null;
  public defaultAccountId: number;
  public newVendor: any;
  public newItem: any;
  public total = 0.0;
  public error: any = {};

  constructor(
    public accountService: AccountService,
    public contactService: ContactService,
    public contactGroupService: ContactGroupService,
    public invoiceService: InvoiceService,
    public invoiceItemService: InvoiceItemService,
    public expenseAccountService: ExpenseAccountService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    this.total = 0;
    if (this.invoice.id) {
      this.invoiceItemService
        .list({ invoice_id: this.invoice.id })
        .subscribe(data => {
          this.items = data;
          //console.log(this.items);
          this.doItemUpdate();
        });
    } else {
      this.invoice.terms = 0;
      this.items = [];
    }
    this.contactService
      .list({ hide: false, is_vendor: true })
      .subscribe(data => {
        this.vendors = data;
        //		console.log('Vendors');
        //		console.log(this.vendors);
        this.modal.show();
      });

    this.contactGroupService.list().subscribe(data => {
      data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      this.defaultAccountId = data[0].id;
      this.contactGroups = data;
      this.doItemUpdate();
    });

    this.expenseAccountService.list().subscribe(data => {
      this.expenseAccounts = data;
      defaultItem.expense_account_id = data[0].id;
      this.newItem = Object.assign({}, defaultItem);
    });
  }

  vendorNameToId() {
    //console.log('Converting name');
    //console.log(this.vendorName);
    this.newVendor = null;
    if (!this.vendorName || this.vendorName.length == 0) {
      this.invoice.contact_id = null;
      return;
    }
    let match = this.vendors.filter(e => {
      return e.company == this.vendorName;
    });
    //console.log(match);
    if (match.length > 0) {
      this.invoice.contact_id = match[0].id;
    } else {
      this.newVendor = {
        is_vendor: true,
        contact_group_id: this.contactGroups[0].id,
        name: this.vendorName,
        company: this.vendorName,
      };
    }
    this.checkInvoice();
  }

  deleteItem(obj) {
    if (obj.id) {
      this.invoiceItemService.delete(obj.id).subscribe(data => {
        let idx = this.items.indexOf(obj);
        this.items.splice(idx, 1);
        this.doItemUpdate();
      });
    } else {
      let idx = this.items.indexOf(obj);
      this.items.splice(idx, 1);
      this.doItemUpdate();
    }
  }

  addItem() {
    this.items.push(this.newItem);
    //console.log(this.items);
    this.doItemUpdate();
    this.newItem = Object.assign({}, defaultItem);
  }

  doItemUpdate(addBlank = false) {
    this.total = 0;
    if (!this.items) return;
    for (let item of this.items) {
      if (item.amount) this.total += item.amount;
    }
  }

  show(obj = null) {
    //	console.log(obj);
    this.invoice = obj || ({ ponumber: null, date: new Date() } as IInvoice);
    this.vendorName = obj ? obj.company : null;
    //	console.log('Company name: '+this.vendorName);
    this.items = null;
    this.expenseAccounts = null;
    this.contactGroups = null;
    this.defaultAccountId = null;
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    //console.log('Saving');
    if (this.newItem.amount && this.newItem.name.length > 1) {
      this.addItem();
      this.doItemUpdate(); //pass correct total to invoice page.
    }
    let p = Promise.resolve(this.invoice.contact_id);
    if (this.newVendor) {
      p = this.contactService
        .update(this.newVendor)
        .toPromise()
        .then(data => {
          this.newVendor = null;
          return data.id;
        });
    }
    p.then(contactId => {
      this.invoice.contact_id = contactId;
      let items = this.items.filter(e => {
        return e.amount && e.expense_account_id;
      });

      // Jam the invoice update until we get done.
      this.invoiceService._blockUpdate(true);
      this.invoiceService.update(this.invoice).subscribe(data => {
        this.invoiceService._blockUpdate(false);
        for (let item of items) {
          item.invoice_id = data.id;
        }
        if (items.length == 0) {
          this.doneSave();
        } else {
          this.invoiceItemService.bulkUpdate(items).subscribe(data => {
            this.doneSave();
          });
        }
      });
    });
  }

  checkInvoice() {
    this.error = {};
    if (!this.invoice.contact_id || !this.invoice.ponumber) return;
    this.invoiceService
      .list({
        contact_id: this.invoice.contact_id,
        ponumber: this.invoice.ponumber,
      })
      .subscribe(data => {
        if (data && data.length > 0) {
          if (this.invoice.id && data[0].id == this.invoice.id) return;
          this.error.ponumber =
            'There is already an invoice # ' + this.invoice.ponumber;
        }
      });
  }
  setFlag(flag) {
    this.addThenClose = flag;
  }

  doneSave() {
    this.done.emit(true);
    // And manually update it.
    this.invoiceService.notifyUpdate(true);
    if (this.addThenClose === null || this.addThenClose === true) {
      this.modal.hide();
    } else {
      this.invoice = { ponumber: null, date: new Date() } as IInvoice;
      this.vendorName = null;
      this.newVendor = null;
      this.items = [];
      this.total = 0;
      this.invoice.terms = 0;
    }
  }
}
