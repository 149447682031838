import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
  dateToLocal,
} from './base.service';
import { IChecklistItem } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ChecklistItemService extends BaseService<IChecklistItem> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'checklistItem');
  }

  listWithComplete(checklistId: number, date?: Date): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (!date) date = new Date();
    let tmp = { date: date };

    dateToLocal(tmp, 'date');
    queryParameters = queryParameters.set(
      'checklist_id',
      checklistId.toString()
    );
    queryParameters = queryParameters.set('date', tmp.date.toJSON());
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl + 'listWithComplete', { params: queryParameters })
      .pipe(
        map(this.postProcess()),
        map(data => {
          let dayOfWeek = date.getDay();
          data = data.filter(e => {
            return e.days[dayOfWeek];
          });
          for (let d of data) {
            d.complete = d.completed_id;
          }
          return data;
        }),
        catchError(BaseService.handleError)
      );
  }
}

export { IChecklist, IChecklistItem, ChecklistItemType } from '../shared/api';
