import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IDoc } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class DocService extends BaseService<IDoc> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'doc');
  }

  listStats(docId: number): Observable<any[]> {
    return (
      this.http
        .get<any[]>(this.baseUrl + 'stats/' + docId)
        //.map(this.postProcess())
        .pipe(catchError(BaseService.handleError))
    );
  }

  listForUser(userId: number): Observable<any[]> {
    let queryParameters = new HttpParams();
    let url = this.baseUrl + 'list';
    if (!userId) {
      queryParameters = queryParameters.set('all', 'true');
    }
    queryParameters = queryParameters.set(
      'location_id',
      this.accountService.getLocationId().toString()
    );
    return (
      this.http
        .get<any[]>(this.baseUrl + 'list', { params: queryParameters })
        //.map(this.postProcess())
        .pipe(catchError(BaseService.handleError))
    );
  }

  upload(doc: IDoc, file: any): Observable<boolean> {
    let formData: FormData = new FormData();
    //	console.log(file);
    formData.append('file', file, file.name);
    formData.append('content_type', file.type);
    formData.append('content_length', file.size);
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.http.post<boolean>(
      this.baseUrl + 'upload/' + doc.id,
      formData,
      options
    );
  }

  isEmpty(doc): boolean {
    if (!doc) return false;
    return !this.isVideo(doc) && !doc.mime_type;
  }
  isPdf(doc): boolean {
    if (!doc || !doc.mime_type) return false;
    return doc.mime_type == 'application/pdf';
  }
  isVideo(doc): boolean {
    if (!doc || !doc.video) return false;
    return doc.video && doc.video.length > 0;
  }
  isImage(doc): boolean {
    if (!doc || !doc.mime_type) return false;
    return doc.mime_type.substring(0, 5) == 'image';
  }
}

export { IDoc } from '../shared/api';
