import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardAdmin } from '../app/auth-guard';
import { AdminComponent } from './admin.component';
import {
  BillingPlanListComponent,
  BillingPlanEditComponent,
} from './billingPlan.component';
import { BillingComponent } from './billing.component';
import { BillingChartComponent } from './billingChart.component';
import { BillingReportComponent } from './billingReport.component';
import { HomeComponent } from './home.component';
import { ImportComponent, ImportContactComponent } from './import.component';
import { LocationEditComponent } from './location.component';
import { LocationActiveComponent } from './locationActive.component';
import { IntegrationSalesListComponent } from './integration.component';
import {
  NotificationListComponent,
  NotificationEditComponent,
} from './notification.component';
import { TestComponent } from './test.component';
import { TestHomeComponent } from './testHome.component';
import { TestIconComponent } from './testIcon.component';
import { TestUIComponent } from './testUI.component';

const appRoutes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuard, AuthGuardAdmin],
    component: AdminComponent,
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'billing',
        component: BillingComponent,
        children: [
          // { path: '', component: BillingChartComponent },
          { path: '', component: LocationActiveComponent },
          // { path: 'payments', component: BillingReportComponent },
        ],
      },
      { path: 'location/:id', component: LocationEditComponent },
      { path: 'location/:id/import', component: ImportComponent },
      {
        path: 'location/:id/import/contact',
        component: ImportContactComponent,
      },
      {
        path: 'integration/sales/:id',
        component: IntegrationSalesListComponent,
      },
      {
        path: 'billingPlan',
        children: [
          { path: '', component: BillingPlanListComponent },
          { path: 'add', component: BillingPlanEditComponent },
          { path: ':id', component: BillingPlanEditComponent },
        ],
      },
      {
        path: 'notification',
        children: [
          { path: '', component: NotificationListComponent },
          { path: 'add', component: NotificationEditComponent },
          { path: ':id', component: NotificationEditComponent },
        ],
      },
      {
        path: 'test',
        component: TestComponent,
        children: [
          { path: '', component: TestHomeComponent },
          { path: 'icon', component: TestIconComponent },
          { path: 'ui', component: TestUIComponent },
        ],
      },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
