<form class="card" (submit)="save()" *ngIf="data" ngNativeValidate>
  <div class="card-body">
    <h4>Edit {{ data.name }}</h4>

    <!-- Success Alert -->
    <div class="row mt-2" *ngIf="success">
      <div class="col-12">
        <alert type="success">{{ success }}</alert>
      </div>
    </div>

    <!-- Error Alert -->
    <div class="row mt-2" *ngIf="error">
      <div class="col-12">
        <alert type="danger">{{ error }}</alert>
      </div>
    </div>

    <!-- Form Fields -->
    <div class="row mt-2">
      <!-- Package Type -->
      <div class="col-md-3 col-12 my-2">
        <label>Package Type</label>
        <select
          name="package_type"
          required
          class="form-control"
          [(ngModel)]="data.package_type">
          <option *ngFor="let p of PackageTypeKeys" [value]="p">
            {{ PackageTypeNames[p] }}
          </option>
        </select>
      </div>

      <!-- Condition -->
      <div class="col-md-3 col-12 my-2">
        <label>Condition</label>
        <select name="status" class="form-control" [(ngModel)]="data.status">
          <option *ngFor="let s of LocationStatusKeys" [value]="s">
            {{ LocationStatus[s] }}
          </option>
        </select>
      </div>

      <!-- Free Until -->
      <div class="col-md-3 col-12 my-2">
        <label>Free Until</label>
        <input
          type="text"
          bsDatepicker
          name="free_until"
          [(ngModel)]="data.free_until"
          class="form-control" />
      </div>

      <!-- Signin Link -->
      <div class="col-md-3 col-12 my-2">
        <label> Owner Signin Link</label>
        <button
          *ngIf="!locationWithOwner.user[0].token"
          class="btn btn-primary form-control"
          type="button"
          (click)="signinAsOwner()">
          Get Signin Link
        </button>
        <div *ngIf="locationWithOwner.user[0].token">
          <a
            *ngIf="locationWithOwner.user[0].token"
            class="btn btn-light form-control"
            [href]="locationWithOwner.user[0].token | safeUrl"
            >Open in incognito</a
          >
        </div>
      </div>
    </div>

    <!-- Additional Fields -->
    <div class="row mt-2">
      <!-- Billing Plan Type -->
      <div class="col-md-3 col-12 my-2">
        <label>Billing Plan Type</label>
        <span *ngIf="billingPlan">
          {{ billingPlan?.charAt(0).toUpperCase() + billingPlan?.slice(1) }}
        </span>
        <span *ngIf="!billingPlan">None</span>
      </div>

      <!-- Payment Status -->
      <div class="col-md-3 col-12 my-2">
        <label>Payment Status</label>
        <input-toggle name="payment_status" [(ngModel)]="data.payment_status">
          <span *ngIf="data.payment_status">Paid</span>
          <span *ngIf="!data.payment_status">Unpaid</span>
        </input-toggle>
      </div>

      <!-- Freemium Banner -->
      <div class="col-md-3 col-12 my-2">
        <label>Freemium Banner</label>
        <input-toggle
          name="block_freemium_warning"
          [(ngModel)]="data.block_freemium_warning">
          Block Freemium Warning
        </input-toggle>
      </div>
    </div>

    <!-- Billing Payment Options -->
    <div class="row mt-2">
      <div class="col-12">
        <label>Billing Payment Options</label>
        <div class="row">
          <div class="col-md-3 col-12 my-2">
            <input-toggle
              name="billing_payment_options_0"
              [(ngModel)]="data.billing_payment_options[0]">
              {{ BillingPaymentOption[0] }}
            </input-toggle>
          </div>
          <div class="col-md-3 col-12 my-2">
            <input-toggle
              name="billing_payment_options_1"
              [(ngModel)]="data.billing_payment_options[1]">
              {{ BillingPaymentOption[1] }}
            </input-toggle>
          </div>
          <div class="col-md-3 col-12 my-2">
            <input-toggle
              name="billing_payment_options_2"
              [(ngModel)]="data.billing_payment_options[2]">
              {{ BillingPaymentOption[2] }}
            </input-toggle>
          </div>
          <div class="col-md-3 col-12 my-2">
            <input-toggle
              name="billing_payment_options_3"
              [(ngModel)]="data.billing_payment_options[3]">
              {{ BillingPaymentOption[3] }}
            </input-toggle>
          </div>
        </div>
      </div>
    </div>

    <!-- Additional Information -->
    <div class="row mt-2">
      <div class="col-12">
        <div class="row">
          <!-- Last Login -->
          <div class="col-md-3 col-12 my-2">
            <label>Last login</label>
            <span *ngIf="locationWithOwner && locationWithOwner.user[0]">
              {{ locationWithOwner.user[0].last_login | bliDate }}
            </span>
            <span *ngIf="!locationWithOwner && !locationWithOwner.user[0]">
              —
            </span>
          </div>

          <!-- Last Schedule -->
          <div class="col-md-3 col-12 my-2">
            <label>Last Schedule</label>
            <span *ngIf="locationWithOwner.last_schedule">
              {{ locationWithOwner.last_schedule | bliShortDate }}
            </span>
            <span *ngIf="!locationWithOwner.last_schedule"> — </span>
          </div>

          <!-- Last Payment -->
          <div class="col-md-3 col-12 my-2">
            <label>Last payment</label>
            <div *ngIf="paymentIntents">
              <span *ngIf="paymentIntents && paymentIntents.data[0]">
                {{ paymentIntents.data[0].created * 1000 | bliDate }}
              </span>
              <span *ngIf="!paymentIntents && !paymentIntents.data[0]">
                —
              </span>
            </div>
          </div>

          <!-- Number of Employees -->
          <div class="col-md-3 col-12 my-2">
            <label># Employees</label>
            <span *ngIf="users"> {{ users.length }} </span>
            <span *ngIf="!users"> — </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Point of Sale Integration -->
    <div
      class="row mt-2"
      *ngIf="(isFree || data.package_type == PackageType.Pro) && data.sales_integration_type && SalesIntegrationFeatures[data.sales_integration_type].auth_type == SalesIntegrationAuthType.Token">
      <div class="col-12">
        <label>Point of Sale Integration</label>
        <div class="row">
          <!-- API Type -->
          <div class="col-md-3 col-12 my-2">
            <label>API Type</label>
            <span *ngIf="data?.sales_integration_type">
              {{ SalesIntegrationType[data?.sales_integration_type] }}
            </span>
            <span *ngIf="!data?.sales_integration_type"> — </span>
          </div>
          <!-- API Update -->
          <div class="col-md-3 col-12 my-2">
            <label>API Update</label>
            <span *ngIf="data.sales_integration_update">
              {{ data.sales_integration_update }}
            </span>
            <span *ngIf="!data.sales_integration_update"> — </span>
          </div>

          <!-- API Key -->
          <div class="col-md-6 col-12 my-2">
            <label>API Key</label>
            <div class="input-group">
              <input
                type="text"
                name="integration_key"
                class="form-control"
                placeholder="Integration key"
                [(ngModel)]="data.sales_integration_token" />
              <input
                type="text"
                name="integration_key_2"
                class="form-control"
                placeholder="Integration Password"
                [(ngModel)]="data.sales_integration_refresh_token"
                *ngIf="data.sales_integration_type == SalesIntegrationType.WineDirect" />
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="updateSalesIntegrationKey()">
                  Update
                </button>
              </div>
            </div>
            <local-error [error]="salesIntegrationResult"></local-error>
          </div>
        </div>
      </div>
    </div>

    <!-- Stripe -->
    <div class="row mt-2">
      <div class="col-12">
        <label>Stripe</label>
        <button
          *ngIf="!data.stripe_customer_id"
          class="btn btn-primary mb-2 mr-2"
          type="button"
          (click)="createStripeCustomer(data.id)">
          <icons class="mr-2" name="Stripe"></icons>Create stripe customer
        </button>

        <div class="row">
          <!-- Stripe Details -->
          <div class="col-md-4 col-12 my-2">
            <p>
              Stripe ID:
              <a
                [href]="stripeUrl + 'customers/' + data?.stripe_customer_id"
                target="_blank">
                {{ data?.stripe_customer_id }}
              </a>
            </p>
            <p>
              Payment Method ID: {{
              customer?.invoice_settings?.default_payment_method ?? 'No payment
              method found' }}
            </p>
            <p>
              Subscription ID: {{ data?.stripe_subscription_id ?
              data?.stripe_subscription_id : 'No subscription found' }}
            </p>
            <p *ngIf="data.cancel_at" style="color: red">
              Cancellation: {{ data.cancel_at * 1000 | bliDateLong }}
            </p>
            <p>
              Payment failed:
              <span *ngIf="!data.payment_failed_at"> — </span>
              <span *ngIf="data.payment_failed_at" style="color: red">
                {{ data.payment_failed_at | bliDate }}
              </span>
            </p>
          </div>

          <!-- Stripe Actions -->
          <div class="col-md-8 col-12 my-2">
            <div class="my-2" *ngIf="customer">
              <button
                class="btn btn-secondary"
                type="button"
                (click)="createPaymentMethod(data.id)"
                [disabled]="customer?.invoice_settings?.default_payment_method || true">
                Create payment method
              </button>
            </div>
            <div class="my-2">
              <a
                [href]="stripeUrl + 'products?active=true'"
                class="btn btn-secondary"
                target="_blank">
                Create pricing plan
              </a>
            </div>
            <div class="my-2" *ngIf="!data.stripe_subscription_id">
              <a
                [href]="stripeUrl + 'customers/' + data.stripe_customer_id + '?create=subscription&subscription_default_customer=' + data.stripe_customer_id"
                class="btn btn-secondary"
                target="_blank">
                Create subscription
              </a>
            </div>
            <div class="my-2" *ngIf="data.stripe_subscription_id">
              <a
                [href]="stripeUrl + 'subscriptions/' + data.stripe_subscription_id"
                class="btn btn-secondary"
                target="_blank">
                Update subscription
              </a>
            </div>
            <div class="my-2" *ngIf="data.cancel_at">
              <button
                class="btn btn-primary"
                type="button"
                (click)="renewSubscription()">
                Renew subscription
              </button>
            </div>
            <div class="my-2" *ngIf="data.stripe_subscription_id">
              <button
                class="btn btn-danger"
                type="button"
                (click)="confirmCancelSubscriptionModal.show()"
                [disabled]="!data.stripe_subscription_id || data.cancel_at">
                Cancel subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <label>Food Vendors Integration</label>
        <button
          *ngIf="!syscoData?.vendor"
          class="btn btn-primary mb-2 mr-2"
          type="button"
          (click)="addFoodVendor(FoodVendorIntegrationType.Sysco)">
          <icons class="mr-2" name="Vendor"></icons>Add Sysco Integration
        </button>
        <div class="row col-12" *ngIf="syscoData?.vendor" id="outer-credentials-container">
          <div class="col-md-4 col-12" id="type-container">
            <div class="row my-4 col-12">
              <div class="col-md-12 col-6">
                <label>Type:</label>
                <p>{{syscoData.vendor.charAt(0).toUpperCase() + syscoData.vendor.slice(1)}}</p>
              </div>
              <div class="col-md-12 col-6">
                <label>Status:</label>
                <p>{{syscoData.status}}</p>
              </div>
            </div>
          </div>
          <div class="row col-md-8 col-12" id="credentials-container">
            <div class="col-lg-10 col-12" id="nested-input-1">
              <div class="row">
                <div class="col-6">
                  <p for="sysco_integration_host">Host:</p>
                    <input
                      type="text"
                      name="sysco_integration_host"
                      id="sysco_integration_host"
                      class="form-control"
                      placeholder="Integration Host"
                      [(ngModel)]="syscoData.host"/>

                </div>
                <div class="col-6">
                  <p for="sysco_integration_port">Port:</p>
                    <input
                      type="text"
                      name="sysco_integration_port"
                      id="sysco_integration_port"
                      class="form-control"
                      placeholder="Integration Port"
                      [(ngModel)]="syscoData.port"/>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <p for="sysco_integration_key">Username:</p>
                    <input
                      type="text"
                      name="sysco_integration_key"
                      id="sysco_integration_key"
                      class="form-control"
                      placeholder="Integration Username"
                      [(ngModel)]="syscoData.username" />
                </div>
                <div class="col-6">
                  <p for="sysco_integration_key_2">Password:</p>
                    <input
                      type="text"
                      name="sysco_integration_key_2"
                      id="sysco_integration_key_2"
                      class="form-control"
                      placeholder="Integration Password"
                      [(ngModel)]="syscoData.password"/>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-12 d-flex flex-column justify-content-center align-items-center mt-2" id="nested-input-2">
              <div class="align-self-center">
                <button
                  type="button"
                  class="btn btn-primary w-100 m-2"
                  id="save-sysco-btn"
                  (click)="updateFoodVendorIntegrationKeys(FoodVendorIntegrationType.Sysco)">
                  Update
                </button>
                <button class="btn btn-danger w-100 m-2" type="button" (click)="resetFoodVendor(FoodVendorIntegrationType.Sysco)">Reset</button>
              </div>
              <local-error [error]="vendorIntegrationResult[FoodVendorIntegrationType.Sysco]"></local-error>
            </div>
          </div>
        </div>
      </div>
    </div>    

    <div class="row mt-4 mb-4">
      <div class="col-12">
        <button
          *ngIf="!shamrockData?.vendor"
          class="btn btn-primary mb-2 mr-2"
          type="button"
          (click)="addFoodVendor(FoodVendorIntegrationType.Shamrock)">
          <icons class="mr-2" name="Vendor"></icons>Add Shamrock Integration
        </button>
        <div class="row col-12" *ngIf="shamrockData?.vendor" id="outer-credentials-container">
          <div class="col-md-4 col-12" id="type-container">
            <div class="row my-4 col-12">
              <div class="col-md-12 col-6">
                <label>Type:</label>
                <p>{{shamrockData.vendor.charAt(0).toUpperCase() + shamrockData.vendor.slice(1)}}</p>
              </div>
              <div class="col-md-12 col-6">
                <label>Status:</label>
                <p>{{shamrockData.status}}</p>
              </div>
            </div>
          </div>
          <div class="row col-md-8 col-12" id="credentials-container">
            <div class="col-lg-10 col-12" id="nested-input-1">
              <div class="row">
                <div class="col-6">
                  <p for="shamrock_integration_host">Host:</p>
                  <input
                    type="text"
                    name="shamrock_integration_host"
                    id="shamrock_integration_host"
                    class="form-control"
                    placeholder="Integration Host"
                    [(ngModel)]="shamrockData.host"/>
                </div>
                <div class="col-6">
                  <p for="shamrock_integration_port">Port:</p>
                  <input
                    type="text"
                    name="shamrock_integration_port"
                    id="shamrock_integration_port"
                    class="form-control"
                    placeholder="Integration Port"
                    [(ngModel)]="shamrockData.port"/>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-6">
                  <p for="shamrock_integration_key">Username:</p>
                  <input
                    type="text"
                    name="shamrock_integration_key"
                    id="shamrock_integration_key"
                    class="form-control"
                    placeholder="Integration Username"
                    [(ngModel)]="shamrockData.username" />
                </div>
                <div class="col-6">
                  <p for="shamrock_integration_key_2">Password:</p>
                  <input
                    type="text"
                    name="shamrock_integration_key_2"
                    id="shamrock_integration_key_2"
                    class="form-control"
                    placeholder="Integration Password"
                    [(ngModel)]="shamrockData.password"/>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-12 d-flex flex-column justify-content-center align-items-center mt-2" id="nested-input-2">
              <div class="align-self-center">
                <button
                  type="button"
                  class="btn btn-primary w-100 m-2"
                  id="save-shamrock-btn"
                  (click)="updateFoodVendorIntegrationKeys(FoodVendorIntegrationType.Shamrock)">
                  Update
                </button>
                <button class="btn btn-danger w-100 m-2" type="button" (click)="resetFoodVendor(FoodVendorIntegrationType.Shamrock)">Reset</button>
              </div>
              <local-error [error]="vendorIntegrationResult[FoodVendorIntegrationType.Shamrock]"></local-error>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="row mt-2">
      <div class="col-12">
        <button
          *ngIf="data.stripe_customer_id"
          class="btn btn-danger float-right mr-2 ml-2"
          type="button"
          (click)="confirmResetBillingModal.show()">
          Reset billing
        </button>
        <cancel-button class="float-right" routerLink="../.."></cancel-button>
        <save-button class="float-right"></save-button>
      </div>
    </div>
  </div>
</form>

<div class="row mt-2" *ngIf="saveMessage">
  <div class="col-12">
    <alert type="success">{{saveMessage}} </alert>
  </div>
</div>
<div class="card" *ngIf="paymentIntents && paymentIntents.data.length>0">
  <div class="card-body">
    <div class="row row-table row-header">
      <div class="col-12">Payments</div>
    </div>
    <div class="row row-table">
      <div class="col-1">Amount</div>
      <div class="col-1">Currency</div>
      <div class="col-2">Description</div>
      <div class="col-2">Status</div>
      <div class="col-2">Date</div>
    </div>
    <div class="row-table row-striped">
      <a
        [href]="stripeUrl+'payments/'+paymentIntent.id"
        target="_blank"
        class="row p-1 link"
        *ngFor="let paymentIntent of paymentIntents.data | orderBy: ['invoice.created']">
        <div class="col-1">{{ paymentIntent.amount | bliPrice }}</div>
        <div class="col-1">{{ paymentIntent.currency.toUpperCase() }}</div>
        <div class="col-2">
          <span *ngIf="paymentIntent.description"
            >{{ paymentIntent.description}}</span
          >
          <span *ngIf="!paymentIntent.description"> — </span>
        </div>
        <div class="col-2">
          {{ paymentIntent.status.charAt(0).toUpperCase() +
          paymentIntent.status.slice(1) }}
        </div>
        <div class="col-2">{{ paymentIntent.created * 1000 | bliDate}}</div>
      </a>
      <button
        class="btn btn-primary float-right mt-2"
        type="button"
        *ngIf="paymentIntents.has_more"
        (click)="getMorePaymentIntents()">
        Show more
      </button>
    </div>
  </div>
</div>
<div class="card" *ngIf="invoices && invoices.data.length>0">
  <div class="card-body">
    <div class="row row-table row-header">
      <div class="col-12">Invoices</div>
    </div>
    <div class="row row-table">
      <div class="col-1">Amount</div>
      <div class="col-1">Currency</div>
      <div class="col-2">Invoice Number</div>
      <div class="col-2">Status</div>
      <div class="col-2">Due Date</div>
      <div class="col-2">Reciept</div>
      <div class="col-2">Date</div>
    </div>
    <div class="row-table row-striped">
      <a
        [href]="stripeUrl+'invoices/'+invoice.id"
        target="_blank"
        class="row p-1 link"
        *ngFor="let invoice of invoices.data | orderBy: ['invoice.created']">
        <div class="col-1">{{ invoice.total | bliPrice }}</div>
        <div class="col-1">{{ invoice.currency.toUpperCase() }}</div>
        <div class="col-2">
          <span *ngIf="invoice.number">{{ invoice.number}}</span>
          <span *ngIf="!invoice.number"> — </span>
        </div>
        <div class="col-2">
          {{ invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1) }}
        </div>
        <div class="col-2">
          <span *ngIf="invoice.due_date">
            {{ invoice.due_date * 1000 | bliDate}}
          </span>
          <span *ngIf="!invoice.due_date"> — </span>
        </div>
        <div class="col-2">
          <a [href]="invoice.hosted_invoice_url" target="_blank">Download</a>
        </div>
        <div class="col-2">{{ invoice.created * 1000 | bliDate}}</div>
      </a>
      <button
        class="btn btn-primary float-right mt-2"
        type="button"
        *ngIf="invoices.has_more"
        (click)="getMoreInvoice()">
        Show more
      </button>
    </div>
  </div>
</div>
<div class="card" *ngIf="users">
  <div class="card-body">
    <div class="row row-table row-header">
      <div class="col-12">Staff</div>
    </div>
    <div class="row row-table">
      <div class="col-2">Name</div>
      <div class="col-2">Email</div>
      <div class="col-2">Phone</div>
      <div class="col-6">
        <span class="float-right w-25">Actions</span>
      </div>
    </div>
    <div class="row-table row-striped">
      <div
        class="row py-1"
        *ngFor="let u of users | orderBy: ['-user_type','lastname']">
        <div class="col-2">{{ u.firstname}} {{u.lastname }}</div>
        <div class="col-2">
          <a [href]="'mailto:' + u.email">{{ u.email}}</a>
        </div>
        <div class="col-2">
          <a *ngIf="u.cell" [href]="'tel:' + u.cell">{{ u.cell | phone}}</a>
          <span *ngIf="!u.cell"> — </span>
        </div>
        <div class="col-6">
          <div *ngIf="!u.token">
            <button
              class="btn btn-secondary float-right w-25"
              type="button"
              (click)="signinAs(u)">
              Get Signin Link
            </button>
          </div>
          <div *ngIf="u.token">
            <a
              *ngIf="u.token"
              class="btn btn-primary float-right w-25"
              [href]="u.token | safeUrl">
              Open in incognito
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #confirmResetBillingModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Confirm Reset Billing"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Reset Billing
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="confirmResetBillingModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body align-self-center">
        Are you sure you want to reset billing for
        <em>{{data?.name}}</em>? Make sure to delete any subscription from
        Stripe first. This will only remove the Stripe information from the
        database, and it will not delete any customer or subscription from
        Stripe itself.

        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="confirmResetBillingModal.hide()">
            </cancel-button>
            <button
              type="button"
              class="btn btn-primary float-right mr-2"
              (click)="resetBillingPlan()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #confirmCancelSubscriptionModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Confirm Cancel Subscription"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Cancel Subscription
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="confirmResetBillingModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body align-self-center">
        Are you sure you want to cancel subscription for
        <em>{{data?.name}}</em>?

        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="confirmCancelSubscriptionModal.hide()">
            </cancel-button>
            <button
              type="button"
              class="btn btn-primary float-right mr-2"
              (click)="cancelSubscription()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
