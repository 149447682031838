<div class="card m-4" *ngIf="loc">
  <div class="card-body">
    <div class="title text-center">Packages</div>
    <div *ngIf="loc.free_until && loc.free_until>now" class="text-center w-100">
      Your free trial is active until {{ loc.free_until | bliDate}}
    </div>
    <p class="text-center" *ngIf="!loc.stripe_subscription_id">
      Please select a package to continue.
    </p>
    <p class="text-center" *ngIf="loc.cancel_at">
      This account is scheduled for deactivation on the {{loc.cancel_at * 1000 |
      bliDateLong}}. To renew, please go to the 'Manage Location Billing'
      section and renew your plan.
    </p>
    <p class="text-center" *ngIf="paymentStatus==='incomplete'">
      Your payment method is incomplete. Please go to the 'Manage Location
      Billing' section and update your payment method or pay your invoice.
    </p>
    <div class="row text-center">
      <div class="col col-12">
        <div class="card-deck">
          <div
            class="card mx-4 py-4"
            [class.package-unselected]="loc.package_type!=PackageType.Freemium">
            <div class="card-body">
              <h3 class="card-title display-4 text-warning">Basic</h3>
              <p class="card-text">
                <b>Get a handle on employee scheduling.</b> Manage shifts and
                trades and streamline your workflow.
                <b>Huge savings for the small shop.</b>
              </p>
            </div>
            <div class="border-0 pb-4">
              <button
                type="button"
                [disabled]="loc.package_type==PackageType.Freemium || packageError || packageMessage || paymentStatus==='incomplete' || loc.cancel_at"
                (click)="checkout(PackageType.Freemium)"
                class="btn btn-primary btn-lg">
                <span *ngIf="loc.package_type==PackageType.Freemium"
                  >Current Package</span
                >
                <span *ngIf="!(loc.package_type==PackageType.Freemium)"
                  >Select</span
                >
              </button>
            </div>
          </div>

          <div
            class="card mx-4 py-4"
            [class.package-unselected]="loc.package_type!=PackageType.Normal">
            <div class="card-body">
              <h3 class="card-title display-4 text-warning">Plus</h3>
              <p class="card-text">
                Everything from Basic, plus inventory, document and vendor
                management, and other
                <b>advanced restaurant management tools.</b>
              </p>
            </div>

            <div class="border-0 pb-4">
              <button
                type="button"
                [disabled]="loc.package_type==PackageType.Normal || packageError || packageMessage || paymentStatus==='incomplete' || loc.cancel_at"
                (click)="checkout(PackageType.Normal)"
                class="btn btn-primary btn-lg">
                <span *ngIf="loc.package_type==PackageType.Normal"
                  >Current Package</span
                >
                <span *ngIf="!(loc.package_type==PackageType.Normal)"
                  >Select</span
                >
              </button>
            </div>
          </div>

          <div
            class="card mx-4 py-4"
            [class.package-unselected]="loc.package_type!=PackageType.Pro">
            <div class="card-body">
              <h3 class="card-title display-4 text-warning">Pro</h3>
              <p class="card-text">
                All the "Plus" features, along with POS integration.
                <b>The end-to-end restaurant management solution.</b>
              </p>
            </div>

            <div class="border-0 pb-4">
              <button
                type="button"
                [disabled]="(loc.package_type==PackageType.Pro) || packageError || packageMessage || paymentStatus==='incomplete' || loc.cancel_at"
                (click)="checkout(PackageType.Pro)"
                class="btn btn-primary btn-lg">
                <span *ngIf="(loc.package_type==PackageType.Pro)"
                  >Current Package</span
                >
                <span *ngIf="!(loc.package_type==PackageType.Pro)">Select</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 mx-1" *ngIf="packageMessage && newType===null">
      <div class="col-12">
        <alert type="success">
          <strong>{{packageMessage}}</strong>
        </alert>
      </div>
    </div>

    <div class="row mt-2 mx-1" *ngIf="packageError">
      <div class="col-12">
        <alert type="danger">
          <strong>{{packageError}}</strong>
        </alert>
      </div>
    </div>

    <div class="row mt-4" *ngIf="loc.stripe_customer_id!==null">
      <div class="col-12">
        <button
          type="button"
          class="btn btn-secondary mx-3 float-right"
          (click)="createStripePortalSession()">
          Manage location billing
        </button>
      </div>
    </div>
  </div>
</div>
