import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  NgZone,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { UserService } from '../services/user.service';
import { EventService, EventType } from '../services/event.service';
import { baseRole } from '../shared/baseRole';
import { ServiceTimeService } from '../services/serviceTime.service';
import { LogService } from '../services/log.service';
import { ShiftService } from '../services/shift.service';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { ModalEventComponent } from '../shared/modalEvent.component';
import { ModalTaskComponent } from '../shared/modalTask.component';
import { getStartEndDays, moment, textColor } from '../shared/api';
import { DeleteModalComponent } from '../shared/shared.component';
import * as momentHoliday_ from 'moment-holiday-us';

@Component({
  templateUrl: './home.html',
})
export class HomeComponent extends baseRole implements OnInit, OnDestroy {
  protected forcedUpdateSub;
  public calendarOptions: CalendarOptions;
  public data: any[];
  //public birthdayData: any[];

  public deleteName: string;
  public deleteObj: any = null;
  public collapse = true;

  @ViewChild('modalEvent')
  public modalEvent: ModalEventComponent;

  @ViewChild('modalTask')
  public modalTask: ModalTaskComponent;

  @ViewChild('deleteModal')
  public deleteModal: DeleteModalComponent;

  @ViewChild('ucCalendar')
  public fullCalendar: FullCalendarComponent;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected zone: NgZone,
    protected userService: UserService,
    protected eventService: EventService,
    protected shiftService: ShiftService,
    protected serviceTimeService: ServiceTimeService,
    protected logService: LogService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.forcedUpdateSub = this.accountService
      .getCalendarUpdateObservable()
      .subscribe(data => {
        this.refetch();
      });

    let mobileMode = window.innerWidth < 756;

    this.calendarOptions = {
      editable: false,
      timeZone: 'local',
      slotEventOverlap: false,
      eventClick: this.eventClick.bind(this),
      eventContent: this.eventRender.bind(this),
      //defaultView: (mobileMode?'listWeek':'dayGridMonth'),
      headerToolbar: {
        start: !mobileMode ? 'prev,today,next' : '',
        center: 'title',
        end: !mobileMode
          ? 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          : '',
      },
      events: this.load.bind(this),
    };
  }

  ngOnDestroy() {
    if (this.forcedUpdateSub) this.forcedUpdateSub.unsubscribe();
  }

  refetch() {
    if (this.fullCalendar && this.fullCalendar.getApi()) {
      this.fullCalendar.getApi().refetchEvents();
    }
  }

  deleteEvent() {
    if (!this.deleteObj) return;
    this.eventService.delete(this.deleteObj.id).subscribe(() => {
      this.refetch();
    });
  }

  load(
    arg: {
      start: Date;
      end: Date;
      startStr: string;
      endStr: string;
      timeZone: string;
    },
    successCallback,
    failureCallback
  ) {
    let holidays = this.addHolidayEvents(arg.start, arg.end);
    /*	console.log(arg.start);
		console.log(arg.end);*/
    let p = [
      this.eventService.shortList({ start: arg.start, end: arg.end }),
      this.logService.getDailyTotals({ start: arg.start, end: arg.end }),
    ];

    forkJoin(p).subscribe(results => {
      let backgroundMap: any = {};
      //console.log('Event Loading');
      //console.log(start.toDate());
      //console.log(end.toDate());
      this.data = [].concat(holidays);
      //this.data = this.data.concat(bdays);

      for (let e of results[0]) {
        // Events
        //console.log(e);
        let dates = getStartEndDays(
          e.start_date,
          e.end_date,
          e.repeat_option,
          e.repeat_until,
          arg.start,
          arg.end
        );
        //console.log(dates);
        for (let date of dates) {
          this.data.push({
            id: e.id,
            title: e.name,
            //event_type: 'event',
            forceEventDuration: true,
            start: date.start.toDate(),
            end: date.end.toDate(),
            display: 'block',
            allDay: e.event_type == EventType.Task,
            editable: false,
            extendedProps: { type: 'event', data: e },
            backgroundColor: e.color,
            textColor: textColor(e.color),
          });
        }
      }
      if (this.isAllowed(this.Permission.ViewSales)) {
        for (let s of results[1]) {
          backgroundMap[s.date] = { total: s.sales };
        }
      }

      for (let date in backgroundMap) {
        let d = backgroundMap[date];
        this.data.push({
          title:
            '<span class="fc-sales">' +
            (d.total ? '$' + Math.round(d.total) : '') +
            '</span>',
          forceEventDuration: true,
          start: date,
          allDay: true,
          className: 'calendar-background-event',
          display: 'background',
          editable: false,
          extendedProps: {
            type: 'background',
          },
        });
      }

      this.data.sort((a, b) => {
        return a.start - b.start;
      });
      successCallback(this.data);
    });
  }

  addHolidayEvents(start, end) {
    let dates = moment(start).holidaysBetween(end);
    //	console.log('Holidays');
    //console.log(dates);
    let holidays: any[] = [];
    for (let d of dates) {
      holidays.push({
        title: d.isHoliday(),
        forceEventDuration: true,
        start: d.toDate(),
        allDay: true,
        editable: false,
        className: 'no-click',
        backgroundColor: '#b3ecff',
        textColor: '#3d454d',
        extendedProps: {
          type: 'holiday',
        },
      });
    }
    /*console.log('Holidays');
		console.log(holidays);*/
    return holidays;
  }

  getTrashClick(data: any): boolean {
    let cName = data.jsEvent.srcElement.className;
    // Take a look at the classes on the object originally clicked on.
    //console.log('Trash?: '+(cName.indexOf("calendar-delete")>=0));
    return cName.indexOf('calendar-delete') >= 0;
  }

  eventClick(data) {
    // Jam all non events.
    if (data.event._def.extendedProps.type != 'event') {
      data.jsEvent.preventDefault();
      return;
    }
    //console.log(data);
    let evt = data.event._def.extendedProps.data;
    let trash = this.getTrashClick(data);
    if (!evt) return;
    if (trash) {
      this.deleteObj = evt;
      this.deleteName = evt.name;
      this.deleteModal.show();
      return;
    }

    if (
      evt.event_type == EventType.Event &&
      this.isAllowed(this.Permission.EditEvent)
    ) {
      this.modalEvent.show(evt);
    } else if (
      evt.event_type == EventType.Task &&
      this.isAllowed(this.Permission.EditTask)
    ) {
      this.modalTask.show(evt);
    }
  }

  eventRender(data) {
    if (data.event._def.extendedProps.type != 'event') {
      return { html: data.event.title };
    }
    //console.log(data);
    // Events here
    let myEvent = data.event._def.extendedProps.data;
    let title = myEvent.name;
    if (myEvent.repeat_option > 0) {
      title += '<span class="cdi-s-refresh pointer"></span>';
    }
    title +=
      '<span class="float-right cdi-s-trash calendar-delete pointer" data-id="' +
      myEvent.id +
      '"></span>';
    return { html: title };
  }
  print() {
    const start = moment(
      this.fullCalendar.getApi().getCurrentData().viewTitle,
      'MMMM YYYY'
    ).toDate();
    const end = moment(start).endOf('month').toDate();

    let link = this.eventService.getPdfEventUrl({
      location_id: this.getLocationId(),
      start: start,
      end: end,
    });
    window.open(link, '_blank');
  }
}
