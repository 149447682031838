<div
  bsModal
  #modalDocSign="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Sign This Document"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" *ngIf="data">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Sign This Document
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
          <br />
        </div>
        <h4 class="text-center">
          By filling out the form below, you are legally signing your name to
          this document. By signing you are acknowledging that you have read
          this doc and understand it's contents.
          <a
            href="http://uniformlaws.org/Act.aspx?title=Electronic%20Transactions%20Act"
            target="_blank"
            ><u>Tell me more about the Electronic Signatures Act.</u></a
          >
        </h4>

        <div class="modal-body" *ngIf="doc && data">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12"></div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <label>Type your Name</label>
                  <input
                    [(ngModel)]="data.name"
                    type="text"
                    name="name"
                    class="form-control"
                    required />
                  <input-toggle name="checked" [(ngModel)]="checked">
                    I have read this document
                  </input-toggle>
                </div>
                <div class="col-md-6 col-12">
                  <label
                    >Draw your Signature
                    <u
                      class="float-right"
                      (click)="hasDrawn = false;signature.clear()">
                      Clear</u
                    >
                  </label>
                  <signature
                    #signature
                    (hasDrawn)="hasDrawn = true"></signature>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <save-button
                *ngIf="checked && hasDrawn && data.name && data.name.length>0"
                class="float-right"
                (click)="save()"></save-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
