import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { ShiftService, IShift } from '../services/shift.service';
import { ShiftTradeService, IShiftTrade } from '../services/shiftTrade.service';
import { UserService } from '../services/user.service';
import { LogService } from '../services/log.service';
import { baseRole } from '../shared/baseRole';
import {
  moment,
  TradeType,
  TradeTypeNames,
  dateToBeginningOfPayroll,
} from '../shared/api';

@Component({
  selector: 'modal-trade-board-approve',
  templateUrl: 'modalTradeBoardApproval.html',
})
export class ModalTradeBoardApprovalComponent
  extends baseRole
  implements OnInit
{
  @ViewChild('modal')
  public modal: ModalDirective;
  @Input() myPositionMaps;
  @Output() done = new EventEmitter<boolean>();
  public data;
  public TradeType = TradeType;
  public TradeTypeNames = TradeTypeNames;

  constructor(
    public accountService: AccountService,
    public shiftTradeService: ShiftTradeService,
    public logService: LogService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  dateFormat(date: Date): string {
    return moment(date).format('ddd Do h:mm a');
  }

  load() {
    this.shiftTradeService
      .list({ first_approval: true })
      .subscribe((data: any[]) => {
        let start = new Date();
        let end = moment();
        let payrollStart = dateToBeginningOfPayroll(start, this.getLocation());
        for (let d of data) {
          if (moment(d.end).isAfter(end)) {
            end = moment(d.end);
          }
          if (moment(d.trade_shift_end).isAfter(end)) {
            end = moment(d.trade_shift_end);
          }
        }
        // Pick up the rest of the payroll week.
        end = dateToBeginningOfPayroll(end.toDate(), this.getLocation()).add(
          7,
          'days'
        );
        this.logService
          .getReport({
            start: payrollStart.toDate(),
            end: end.toDate(),
            include_user_data: true,
          })
          .subscribe(logs => {
            let dMap: any = {};

            for (let l of logs) {
              dMap[l.date] = l;
            }

            //console.log('Date Map');
            //console.log(dMap);

            for (let d of data) {
              console.log(d);
              let user = [];
              let key: string;

              d.user = {
                minutes: 0,
                salary: 0,
                cost: 0,
              };

              d.trade_user = {
                minutes: 0,
                salary: 0,
                cost: 0,
              };

              if (d.trade_shift_start) {
                let key = moment(d.trade_shift_start).format('YYYY-MM-DD');
                //console.log('Key: '+key);
                if (key in dMap) {
                  user = dMap[key].users.filter(e => {
                    return e.id == d.trade_user_id;
                  });
                } else {
                  console.log('Missing key: ' + key);
                  console.log(dMap);
                }
              } else {
                let key = moment(d.start).format('YYYY-MM-DD');
                //console.log('Key: '+key);
                if (key in dMap) {
                  user = dMap[key].users.filter(e => {
                    return e.id == d.trade_user_id;
                  });
                } else {
                  console.log('Missing key: ' + key);
                  console.log(dMap);
                }
              }

              if (user.length == 1) {
                console.log(user[0]);
                let s = user[0].scheduled;
                d.trade_user = {
                  minutes:
                    s.foh_minutes +
                    s.boh_minutes +
                    s.ot_foh_minutes +
                    s.ot_boh_minutes,
                  cost: s.foh_cost + s.boh_cost + s.ot_foh_cost + s.ot_boh_cost,
                };
                if (user[0].salary) {
                  d.trade_user.salary =
                    user[0].salary.foh_cost + user[0].salary.boh_cost;
                }
              } else {
                console.log('No trade match: ' + key + ' ' + d.trade_user_id);
              }

              key = moment(d.start).format('YYYY-MM-DD');
              //console.log('Key: '+key);
              user = [];
              if (key in dMap) {
                user = dMap[key].users.filter(e => {
                  return e.id == d.user_id;
                });
              } else {
                console.log('Missing key: ' + key);
                console.log(dMap);
              }
              if (user.length == 1) {
                console.log(user[0]);
                let s = user[0].scheduled;
                d.user = {
                  minutes:
                    s.foh_minutes +
                    s.boh_minutes +
                    s.ot_foh_minutes +
                    s.ot_boh_minutes,
                  cost: s.foh_cost + s.boh_cost + s.ot_foh_cost + s.ot_boh_cost,
                };
                if (user[0].salary) {
                  d.user.salary =
                    user[0].salary.foh_cost + user[0].salary.boh_cost;
                }
              } else {
                console.log('No match: ' + key + ' ' + d.user_id);
              }
            }
            this.data = data;

            //console.log(this.data);
            this.modal.show();
          });
      });
  }

  show() {
    this.load();
  }

  cancel() {
    this.modal.hide();
    this.done.emit(true);
  }

  approveTrade(trade) {
    let updateObj = {
      id: trade.id,
      final_approval: new Date(),
      final_user_id: this.getUserId(),
    };
    this.shiftTradeService.update(updateObj).subscribe(() => {
      this.load();
    });
  }

  rejectTrade(trade) {
    let updateObj = {
      id: trade.id,
      shift_id: trade.shift_id,
      reject: new Date(),
      final_user_id: this.getUserId(),
    };
    this.shiftTradeService.update(updateObj).subscribe(() => {
      this.load();
    });
  }
}
