<date-menu (dateChange)="updateDate($event)"></date-menu>
<div class="datenav-padding"></div>
<div class="container-fluid nav-padding">
  <modal-inventory-order #modalInventoryOrder></modal-inventory-order>
  <div class="row">
    <div class="col-6">
      <label>Vendor</label>
      <select
        class="form-control"
        name="contact_id"
        [ngModel]="contactFilterId ||''"
        (ngModelChange)="contactFilterId =$event; updateFilter()">
        <option *ngFor="let v of vendors" [value]="v.id">{{v.company}}</option>
      </select>
    </div>
    <div class="col-6">
      <label>Category</label>
      <select
        class="form-control"
        name="expense_account_id"
        [ngModel]="expenseAccountFilterId ||''"
        (ngModelChange)="expenseAccountFilterId=$event; updateFilter()">
        <option *ngFor="let cat of categories" [value]="cat.id">
          {{cat.name}}
        </option>
      </select>
    </div>
  </div>
  <carousel [interval]="0">
    <slide *ngFor="let location of locations | orderBy: 'order_by'">
      <div class="card">
        <div class="card-header">
          <div class="row d-flex justify-content-center align-items-center">
            <span class="pr-2">{{location.name}}</span>
            <div
              class="icons"
              (click)="modalInventoryOrder.show(location, getSkusForLocation(location))">
              <icons name="SortAlphaDown"></icons>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row mx-4">
            <div class="col-md-12 my-1">
              <div class="row flex justify-content-end">
                <div class="btn-group mx-2" role="group">
                  <button
                    *ngIf="error"
                    class="btn btn-danger"
                    type="button"
                    disabled>
                    Unable to save
                  </button>
                </div>
                <div class="btn-group mx-2 d-none d-md-block" role="group">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="gridListToggle('list')">
                    <icons name="List"></icons>
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="gridListToggle('grid')">
                    <icons name="Grid"></icons>
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngFor="let s of location.items | orderBy: 's_order_by'"
              class="my-2"
              [class.col-md-4]="gridListView === 'grid'"
              [class.col-md-12]="gridListView === 'list'">
              <div class="item-container">
                <label class="item-label">{{s.name}}</label>
                <div class="input-group">
                  <input
                    inputmode="decimal"
                    min="0"
                    maxlength="7"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    [(ngModel)]="s.count"
                    (blur)="saveItem(s, s.inventory_count_id, s.count, location.id)"
                    [name]="'sku_count_'+s.id"
                    style="border-width: 1px"
                    [class.form-control]="true"
                    placeholder="0.00" />
                  <div class="input-group-append">
                    <span class="input-group-text">{{s.sub_unit}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 my-1">
              <div class="row flex justify-content-end">
                <p class="mx-2">
                  Last modified:
                  <span *ngIf="location.last_modified"
                    >{{location.last_modified}}</span
                  >
                  <span *ngIf="!location.last_modified">None</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide>
  </carousel>

  <span class="text-secondary">
    Only locations with items to count using the current filter will be shown
    here.
  </span>

  <export-to-csv
    class="float-right mr-2"
    [data]="filterTakeInventoryLocation()"
    [filename]="'take_inventory_'+formatDate+'.csv'">
  </export-to-csv>
</div>
