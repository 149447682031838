import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { BillingPlanService } from '../services/billingPlan.service';
import { LocationService, LocationStatus } from '../services/location.service';
import { StringSort, PackageType, BillingPlanType } from '../shared/api';

@Component({
  templateUrl: './billingPlan.list.html',
})
export class BillingPlanListComponent implements OnInit {
  public data: any[];
  public orderByField = 'code';
  public BillingPlanType = BillingPlanType;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected billingPlanService: BillingPlanService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.billingPlanService.list().subscribe(data => {
      StringSort(data, 'code');
      this.data = data;
    });
  }
}

@Component({
  templateUrl: './billingPlan.edit.html',
})
export class BillingPlanEditComponent implements OnInit {
  public data;
  public LocationStatus = LocationStatus;
  public PackageType = PackageType;
  public locations: any[];
  public BillingPlanType = BillingPlanType;
  public BillingPlanTypeKeys = Object.keys(BillingPlanType).filter(Number);

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected billingPlanService: BillingPlanService,
    protected locationService: LocationService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['id'];

      if (id) {
        this.billingPlanService.get(id).subscribe(data => {
          this.data = data;
        });
        this.locationService.list({ billing_plan_id: id }).subscribe(data => {
          this.locations = data;
        });
      } else {
        this.data = {
          billing_plan_type: BillingPlanType.Monthly,
          is_active: true,
          package_1: 20,
          package_2: 49,
          package_3: 79,
        };
      }
    });
  }

  save() {
    let id = this.data.id;
    this.billingPlanService.update(this.data).subscribe(data => {
      this.router.navigate(['/admin/billingPlan']);
    });
  }
}
