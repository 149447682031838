import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './forgotPassword.html',
})
export class ForgotPasswordComponent implements OnInit {
  public username: string;
  public password: string;
  public error: string;
  public forgotPasswordMessage: string;

  constructor(
    protected router: Router,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Forgot password');
  }

  ngOnInit() {
    if (this.accountService.getUser() != null) {
      console.log('User signed in, redirecting.');
      this.router.navigate(['/']);
    }
  }

  forgotPassword() {
    this.forgotPasswordMessage = null;
    this.error = null;

    this.accountService.forgotPassword(this.username).subscribe(
      data => {
        if (data) {
          this.forgotPasswordMessage =
            'You will receive an Email with instructions within the next 5 minutes (usually instant). Please make sure you check your bulk and spam email folders, as our mail sometimes gets filtered.';
        } else {
          this.error =
            'That email was not found. Please check the email and try again.';
        }
        this.removeError();
      },
      err => {
        this.error =
          'That email was not found. Please check the email and try again.';
        this.removeError();
      }
    );
  }

  removeError() {
    setTimeout(() => {
      if (!this.error) {
        this.router.navigate(['/']);
      }
      this.error = null;
      this.forgotPasswordMessage = null;
    }, 7000);
  }
}
