import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AccountService } from '../services/account.service';
import { ContactService, IContact } from '../services/contact.service';
import { ExpenseAccountService } from '../services/expenseAccount.service';
import { SkuService, SkuCostMethod } from '../services/sku.service';
import { baseRole } from '../shared/baseRole';
import { skuUnitToGrams } from '../shared/api';
import {
  InventoryLocationService,
  IInventoryLocation,
} from '../services/inventoryLocation.service';

import * as XLSX from 'xlsx';

@Component({
  templateUrl: './import.html',
})
export class ImportComponent extends baseRole implements OnInit {
  public locationId: number;
  public locMap: any = {};
  public contactMap: any = {};
  public expenseMap: any = {};
  public filename: string;
  public error: any = {};
  public data: any[];
  public dataError: string[][];
  public xData: any[];
  public headers: any[];
  public success = false;
  public jsonData: any[];

  public requiredHeaders = {
    sku_num: 'string',
    name: 'string',
    price: 'number',
    expense_account: 'expense_account', // Expense Account Id
    contact: 'contact', // Contact id
    buying_unit: 'string',
  };

  public availableHeaders = {
    subdivided: 'boolean',
    sub_unit: 'string',
    qty_inner_units: 'number',
    cost_method: 'cost_method', // Cost Method
    reorder_qty: 'number',
    par_qty: 'number',
    par_qty_monday: 'number',
    reorder_qty_monday: 'number',
    par_qty_tuesday: 'number',
    reorder_qty_tuesday: 'number',
    par_qty_wednesday: 'number',
    reorder_qty_wednesday: 'number',
    par_qty_thursday: 'number',
    reorder_qty_thursday: 'number',
    par_qty_friday: 'number',
    reorder_qty_friday: 'number',
    par_qty_saturday: 'number',
    reorder_qty_saturday: 'number',
    par_qty_sunday: 'number',
    reorder_qty_sunday: 'number',
    par_type: 'par_type',
    master_weight: 'number',
    master_weight_unit: 'number',
    total_individual_units: 'number',
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactService: ContactService,
    protected expenseAccountService: ExpenseAccountService,
    protected inventoryLocationService: InventoryLocationService,
    protected skuService: SkuService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.locationId = params['id'];
      forkJoin([
        this.inventoryLocationService.list({ location_id: this.locationId }),
        this.contactService.list({ location_id: this.locationId }),
        this.expenseAccountService.list({ location_id: this.locationId }),
      ]).subscribe(results => {
        for (let d of results[0]) {
          this.locMap['location_' + d.name.toLowerCase()] = d;
        }
        for (let d of results[1]) {
          this.contactMap[d.company.toLowerCase()] = d;
        }
        for (let d of results[2]) {
          this.expenseMap[d.name.toLowerCase()] = d;
        }
      });
    });
  }

  onFileUpload(evt: any) {
    this.error = {};
    this.filename = null;

    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length == 0) {
      return;
    }
    if (target.files.length !== 1) {
      this.error.global = 'Cannot user multiple files';
      return;
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          cellDates: true,
          raw: true,
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.xData = fixData(
          <any[][]>XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 })
        );
        this.validateUpload();
        this.filename = evt.target.files[0].name;
        evt.target.value = '';
      } catch (e) {
        console.log(e);
        this.error.global =
          'This file type is not supported or there is an error in your file.';
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  validateUpload() {
    this.error = {};
    this.dataError = null;
    this.data = null;
    this.jsonData = null;

    let out = checkData(
      this.xData,
      (header: string) => {
        return this.findHeader(header);
      },
      (headerInfo: any, value: any, obj: any) => {
        return this.findData(headerInfo, value, obj);
      },
      (fieldName: string) => {
        return fieldName in this.requiredHeaders;
      }
    );
    if (out.error) {
      this.error.global = out.error;
    }
    this.dataError = out.dataError;
    this.data = out.data;
    this.jsonData = out.objs;
    this.headers = out.headers;
  }

  findData(
    headerInfo: { type: string; name: string },
    value: any,
    obj: any
  ): boolean {
    switch (headerInfo.type) {
      case 'contact':
        if (!value) return false;
        value = value.toLowerCase();
        if (value in this.contactMap) {
          obj[headerInfo.name + '_id'] = this.contactMap[value].id;
          return true;
        }
        return false;
      case 'expense_account':
        if (!value) return false;
        value = value.toLowerCase();
        if (value in this.expenseMap) {
          obj[headerInfo.name + '_id'] = this.expenseMap[value].id;
          return true;
        }
        return false;
      case 'location':
        if (!value) return true;
        let locName = headerInfo.name.toLowerCase();
        if (locName in this.locMap) {
          if (!('locations' in obj)) obj.locations = [];
          obj.locations.push({
            inventory_location_id: this.locMap[locName].id,
            count: value || 0,
          });
          return true;
        }
        return false;
      case 'cost_method':
        {
          let val = SkuCostMethod.Neither;
          if (value == 'Quantity') {
            val = SkuCostMethod.Quantity;
          } else if (value == 'Weight') {
            val = SkuCostMethod.Weight;
          }
          obj[headerInfo.name] = val;
        }
        return true;
      case 'par_type':
        {
          let val = 'std';
          if (value && value.toLowerCase() == 'daily') val = 'daily';
          obj[headerInfo.name] = val;
        }
        return true;
      default:
        return false;
    }
  }

  findHeader(name: string): string {
    if (name in this.locMap) return 'location';
    if (name in this.requiredHeaders) return this.requiredHeaders[name];
    if (name in this.availableHeaders) return this.availableHeaders[name];
    return null;
  }

  doImport() {
    this.data = null;
    this.filename = null;
    this.headers = null;
    this.skuService
      .adminBulkImport(this.locationId, this.jsonData)
      .subscribe(data => {
        this.success = true;
      });
  }
}

@Component({
  templateUrl: './importContact.html',
})
export class ImportContactComponent extends baseRole implements OnInit {
  public locationId: number;
  public requiredHeaders = {
    company: 'string',
  };
  public availableHeaders = {
    name: 'string',
    title: 'string',
    address: 'string',
    phone: 'string',
    cell: 'string',
    fax: 'string',
    email: 'string',
    notes: 'string',
    order_notes: 'string',
  };
  public filename: string;
  public error: any = {};
  public data: any[];
  public jsonData: any[];
  public xData: any[];
  public headers: any[];
  public success = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactService: ContactService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.locationId = params['id'];
    });
  }

  onFileUpload(evt: any) {
    this.error = {};
    this.filename = null;

    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length == 0) {
      return;
    }
    if (target.files.length !== 1) {
      this.error.global = 'Cannot user multiple files';
      return;
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          cellDates: true,
          raw: true,
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        console.log(ws);
        console.log(XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 }));
        /* save data */
        this.xData = fixData(
          <any[][]>XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 })
        );
        this.validateUpload();
        this.filename = evt.target.files[0].name;
        evt.target.value = '';
      } catch (e) {
        console.log(e);
        this.error.global =
          'This file type is not supported or there is an error in your file.';
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  // Need to check required fields.
  validateUpload() {
    this.error = {};

    let out = checkData(
      this.xData,
      (header: string) => {
        return this.findHeader(header);
      },
      (fieldName: string, value: string, obj: any): boolean => {
        return true;
      },
      (fieldName: string) => {
        return fieldName in this.requiredHeaders;
      }
    );
    if (out.error) {
      this.error.global = out.error;
    }
    this.data = out.data;
    this.jsonData = out.objs;
    this.headers = out.headers;
  }

  findHeader(name: string): string {
    name = name.toLowerCase();
    if (name in this.requiredHeaders) return this.requiredHeaders[name];
    if (name in this.availableHeaders) return this.availableHeaders[name];
    return null;
  }

  doImport() {
    this.data = null;
    this.filename = null;
    this.headers = null;
    this.contactService
      .adminBulkImport(this.locationId, this.jsonData)
      .subscribe(data => {
        this.success = true;
      });
  }
}

// Error must be blanked before doing this.
function checkData(
  xData: any[][],
  findHeader: (header: string) => string,
  findData: (headerInfo: any, value: string, obj: any) => boolean,
  isRequired: (fieldname: string) => boolean
): {
  error: any;
  dataError: string[][];
  objs: any[];
  data: any[][];
  headers: any[];
} {
  let out = {
    error: <string>null,
    dataError: new Array(),
    data: xData.slice(1),
    objs: new Array(),
    headers: new Array(),
  };

  let head = xData[0];
  let headerMatched = true;
  // Check everything.
  for (let i = 0; i < head.length; i++) {
    out.headers[i] = {
      name: head[i],
      type: findHeader(head[i]),
    };
    if (!out.headers[i].type) headerMatched = false;
  }
  if (!headerMatched) {
    out.error = 'Unable to match column headers.';
    return out;
  }

  let dataError = false;
  for (let i = 1, x = 0; i < xData.length; i++, x++) {
    let obj: any = {};
    for (let j = 0; j < out.headers.length; j++) {
      let data = xData[i][j];
      let good = false;
      if (typeof data != 'undefined' && data != null) {
        obj[out.headers[j].name.toLowerCase()] = data.trim();
      }
      //console.log(out.headers[j].type);
      if (
        isRequired(out.headers[j].name.toLowerCase()) &&
        (typeof data == 'undefined' || data == null)
      ) {
        if (!out.dataError[x]) out.dataError[x] = [];
        out.dataError[x][j] = 'Missing Data';
        dataError = true;
      }

      switch (out.headers[j].type) {
        case 'string':
          if (data && !(typeof data === 'string' || data instanceof String)) {
            if (!out.dataError[x]) out.dataError[x] = [];
            out.dataError[x][j] = 'Bad string';
            dataError = true;
          }
          break;
        case 'number':
          if (data && isNaN(data)) {
            if (!out.dataError[x]) out.dataError[x] = [];
            out.dataError[x][j] = 'Bad number';
            dataError = true;
          }
          break;
        case 'boolean':
          break; // Anything works here.
        default:
          console.log('Finding data');
          let id = findData(out.headers[j], data, obj);
          if (!id) {
            console.log('Data Error: ' + x + ':' + j);
            if (!out.dataError[x]) out.dataError[x] = [];
            out.dataError[x][j] = true;
            dataError = true;
          }
          break;
      }
    }
    if (
      'master_weight' in obj &&
      'master_weight_unit' in obj &&
      obj.master_weight > 0
    ) {
      obj['master_weight'] = skuUnitToGrams(
        obj['master_weight_unit'],
        obj['master_weight']
      );
    }
    out.objs.push(obj);
  }
  if (dataError) {
    out.error = 'There are 1 or more data errors.';
  }
  console.log(out);
  return out;
}

function fixData(xData: any[][]): any[][] {
  xData = xData.filter(e => {
    return e.length > 0;
  });

  for (let i = 0; i < xData.length; i++) {
    for (let j = 0; j < xData[i].length; j++) {
      if (typeof xData[i][j] != 'string') continue;
      // Check to see if for some reason it's quoted.
      if (xData[i][j][0] == '"' && xData[i][j][xData[i][j].length - 1] == '"') {
        xData[i][j] = xData[i][j].substring(1, xData[i][j].length - 1);
      }
    }
  }
  return xData;
}
