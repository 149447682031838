import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardFreemium, AuthGuardJail } from '../app/auth-guard';

import { InvoicesComponent } from './invoices.component';
import { LogComponent } from './log.component';
import { LogAddComponent } from './logAdd.component';
import { SummaryComponent } from './summary.component';
import { TimecardsComponent } from './timecards.component';

const appRoutes: Routes = [
  {
    path: 'log',
    canActivate: [AuthGuard, AuthGuardJail],
    component: LogComponent,
    children: [
      { path: '', component: SummaryComponent },
      {
        path: 'invoices',
        canActivate: [AuthGuardFreemium],
        component: InvoicesComponent,
      },
      { path: 'timecards', component: TimecardsComponent },
      {
        path: 'add/:timespan',
        canActivate: [AuthGuardFreemium],
        component: LogAddComponent,
      },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
