<div *ngIf="fixedCosts">
  <div class="card">
    <div class="card-body row-striped">
      <label
        >Fixed Costs
        <button
          class="btn float-right btn-primary"
          type="button"
          (click)="modalFixedCost.show()">
          Add a Fixed Cost
        </button>
      </label>
      <div class="d-none d-sm-block">
        <div class="row row-header body-bg my-2 mx-0 border">
          <div class="col-md-3 col-xs-12">Description</div>
          <div class="col-md-2 col-xs-12">Amount</div>
          <div class="col-md-2 col-xs-12">Frequency</div>
          <div class="col-md-2 col-xs-12">Expense Category</div>
          <div class="col-md-1 col-xs-12">Start</div>
          <div class="col-md-2 col-xs-12">End</div>
        </div>
      </div>

      <div class="row mx-0" *ngFor="let cost of fixedCosts | orderBy:'period'">
        <div class="col-md-3 col-xs-12">
          <span class="d-block-inline d-md-none text-primary">
            Description:
          </span>
          {{ cost.name }}
        </div>
        <div class="col-md-2 col-xs-12">
          <span class="d-block-inline d-md-none text-primary"> Amount: </span>
          {{ cost.amt | currency: 'USD' }}
        </div>
        <div class="col-md-2 col-xs-12">
          <span class="d-block-inline d-md-none text-primary">
            Frequency:
          </span>
          {{FixedCostPeriod[cost.period]}}
        </div>
        <div class="col-md-2 col-xs-12">
          <span class="d-block-inline d-md-none text-primary">
            Expense Category:
          </span>
          {{ cost.expense_account_name }}
        </div>
        <div class="col-md-1 col-xs-12">
          <span class="d-block-inline d-md-none text-primary"> Start: </span>
          {{(cost.start?(cost.start|bliShortDate):'--')}}
        </div>
        <div class="col-md-2 col-xs-12">
          <span class="d-block-inline d-md-none text-primary"> End: </span>
          {{(cost.end?(cost.end|bliShortDate):'--')}}
          <span
            class="cdi-s-trash float-right pointer"
            (click)="deleteFixedCost(cost)"></span>
          <edit-button
            class="float-right"
            (click)="modalFixedCost.show(cost)"></edit-button>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Employee Salaries </label>
      <ul class="list-group">
        <li
          class="list-group-item body-bg border my-1 p-2"
          *ngFor="let u of salaryUsers | orderBy: 'lastname'">
          {{ u.firstname }} {{ u.lastname }} - {{ u. rate | currency: 'USD' }}
        </li>
      </ul>
      Salaries are adjusted on the staff page
    </div>
  </div>

  <div class="card" *ngIf="loc">
    <div class="card-body">
      <label>Hourly Labor Costing Methods</label>
      <div class="row no-gutter">
        <div class="col-md-4 body-bg my-2">
          <div class="card">
            <div class="card-header">
              <div class="btn-group-toggle">
                <label
                  class="btn btn-radio"
                  [class.active]="loc.labor_type==LocationLaborType.Scheduled">
                  <input
                    type="radio"
                    name="options"
                    [value]="LocationLaborType.Scheduled"
                    [(ngModel)]="loc.labor_type"
                    (ngModelChange)="saveLocationField('labor_type')" />Use
                  Scheduled Hours/Cost
                </label>
              </div>
            </div>
            <div class="card-body">
              Use the hours and cost from the schedule.
            </div>
          </div>
        </div>

        <div class="col-md-4 body-bg my-2">
          <div class="card">
            <div class="card-header">
              <div class="btn-group-toggle">
                <label
                  class="btn btn-radio"
                  [class.active]="loc.labor_type==LocationLaborType.Timeclock">
                  <input
                    type="radio"
                    name="options"
                    [value]="LocationLaborType.Timeclock"
                    [(ngModel)]="loc.labor_type"
                    (ngModelChange)="saveLocationField('labor_type')" />Use
                  Timeclock Data
                </label>
              </div>
            </div>
            <div class="card-body">
              Most Accurate. Use timecards from either CheddrSuite or your POS
              to calculate actual hours work data.
            </div>
          </div>
        </div>

        <div class="col-md-4 body-bg my-2">
          <div class="card">
            <div class="card-header">
              <div class="btn-group-toggle">
                <label
                  class="btn btn-radio"
                  [class.active]="loc.labor_type==LocationLaborType.None">
                  <input
                    type="radio"
                    name="options"
                    [value]="LocationLaborType.None"
                    [(ngModel)]="loc.labor_type"
                    (ngModelChange)="saveLocationField('labor_type')" />None
                </label>
              </div>
            </div>
            <div class="card-body">
              Do not use any shift or timeclock data. I will add 100% of my
              labor through daily logs, fixed costs, and salaries.
            </div>
          </div>
        </div>
      </div>

      <div>
        Labor Calculation for both methods is Employee Salaries + Other Fixed
        Labor Costs + Daily Log Totals + TOTALS FROM EITHER METHOD ABOVE
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Expense Categories</label>
      <form ngNativeValidate>
        <div class="row pb-2">
          <div class="col-12 body-bg">
            <div class="input-group p-1">
              <input
                [(ngModel)]="newExpense.name"
                type="text"
                maxlength="25"
                name="new_name"
                class="form-control override-invalid"
                required
                placeholder="Add new category" />
              <div class="input-group-btn">
                <button
                  class="btn btn-secondary bg-white"
                  type="submit"
                  (click)="saveExpense(newExpense)">
                  <span class="cdi-s-add"> </span>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col body-bg">
                <div class="btn-group-toggle p-1">
                  <label
                    class="btn btn-radio"
                    [class.active]="newExpense.expense_type==ExpenseType.COGS">
                    <input
                      type="radio"
                      name="options2"
                      [value]="ExpenseType.COGS"
                      [(ngModel)]="newExpense.expense_type" />Treat as COGS
                  </label>
                </div>
              </div>
              <div class="col body-bg">
                <div class="btn-group-toggle p-1">
                  <label
                    class="btn btn-radio"
                    [class.active]="newExpense.expense_type==ExpenseType.FOH">
                    <input
                      type="radio"
                      name="options2"
                      [value]="ExpenseType.FOH"
                      [(ngModel)]="newExpense.expense_type" />Treat as Fixed
                    <span *ngIf="loc.has_houses">FOH</span> Labor
                  </label>
                </div>
              </div>
              <div class="col body-bg" *ngIf="loc.has_houses">
                <div class="btn-group-toggle p-1">
                  <label
                    class="btn btn-radio"
                    [class.active]="newExpense.expense_type==ExpenseType.BOH">
                    <input
                      type="radio"
                      name="options2"
                      [value]="ExpenseType.BOH"
                      [(ngModel)]="newExpense.expense_type" />Treat as Fixed BOH
                    Labor
                  </label>
                </div>
              </div>
              <div class="col body-bg">
                <div class="btn-group-toggle p-1">
                  <label
                    class="btn btn-radio"
                    [class.active]="newExpense.expense_type==ExpenseType.Fixed">
                    <input
                      type="radio"
                      name="options2"
                      [value]="ExpenseType.Fixed"
                      [(ngModel)]="newExpense.expense_type" />Fixed Cost
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="my-2" *ngFor="let data of expenseAccounts |orderBy:'name'">
        <div class="row">
          <div class="col-12 body-bg">
            <div class="input-group p-1">
              <input
                type="text"
                readonly
                class="form-control form-control-plaintext"
                [value]="data.name"
                *ngIf="!data._edit" />
              <input
                type="text"
                maxlength="25"
                class="form-control"
                [(ngModel)]="data._edit.name"
                *ngIf="data._edit" />

              <div class="input-group-append">
                <span
                  class="cdi-s-edit pointer"
                  *ngIf="!data._edit"
                  (click)="editExpense(data)"></span>
                <span
                  class="cdi-s-save pointer"
                  *ngIf="data._edit"
                  (click)="saveExpense(data)"></span>
              </div>
              <div class="input-group-append">
                <delete-modal
                  *ngIf="!data._edit && data._can_delete"
                  [deleteObject]="data"
                  [name]="data.name"
                  (onDelete)="deleteExpense(data)">
                  <span class="cdi-s-trash pointer"></span>
                </delete-modal>
                <span
                  *ngIf="!data._edit && !data._can_delete"
                  class="cdi-s-no-trash"></span>
                <span
                  class="cdi-s-cancel pointer"
                  *ngIf="data._edit"
                  (click)="cancelExpense(data)"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col body-bg">
            <div class="btn-group-toggle p-1">
              <label
                class="btn btn-radio"
                [class.active]="data.expense_type==ExpenseType.COGS"
                *ngIf="!data._edit">
                <input
                  type="radio"
                  name="options2"
                  [disabled]="true"
                  [value]="ExpenseType.COGS"
                  [(ngModel)]="data.expense_type" />Treat as COGS
              </label>
              <label
                class="btn btn-radio"
                [class.active]="data._edit.expense_type==ExpenseType.COGS"
                *ngIf="data._edit">
                <input
                  type="radio"
                  name="options2"
                  [value]="ExpenseType.COGS"
                  [(ngModel)]="data._edit.expense_type" />Treat as COGS
              </label>
            </div>
          </div>
          <div class="col body-bg" *ngIf="!loc.has_houses">
            <div class="btn-group-toggle p-1">
              <label
                class="btn btn-radio"
                [class.active]="data.expense_type==ExpenseType.FOH"
                *ngIf="!data._edit">
                <input
                  type="radio"
                  name="options2"
                  [disabled]="true"
                  [value]="ExpenseType.FOH"
                  [(ngModel)]="data.expense_type" />Treat as Fixed Labor
              </label>
              <label
                class="btn btn-radio"
                *ngIf="data._edit"
                [class.active]="data._edit.expense_type==ExpenseType.FOH">
                <input
                  type="radio"
                  name="options2"
                  [value]="ExpenseType.FOH"
                  [(ngModel)]="data._edit.expense_type" />Treat as Fixed Labor
              </label>
            </div>
          </div>
          <div class="col body-bg" *ngIf="loc.has_houses">
            <div class="btn-group-toggle p-1">
              <label
                class="btn btn-radio"
                *ngIf="!data._edit"
                [class.active]="data.expense_type==ExpenseType.FOH">
                <input
                  type="radio"
                  name="options2"
                  [disabled]="true"
                  [value]="ExpenseType.FOH"
                  [(ngModel)]="data.expense_type" />Treat as Fixed FOH Labor
              </label>
              <label
                class="btn btn-radio"
                *ngIf="data._edit"
                [class.active]="data._edit.expense_type==ExpenseType.FOH">
                <input
                  type="radio"
                  name="options2"
                  [value]="ExpenseType.FOH"
                  [(ngModel)]="data._edit.expense_type" />Treat as Fixed FOH
                Labor
              </label>
            </div>
          </div>

          <div class="col body-bg" *ngIf="loc.has_houses">
            <div class="btn-group-toggle p-1">
              <label
                class="btn btn-radio"
                *ngIf="!data._edit"
                [class.active]="data.expense_type==ExpenseType.BOH">
                <input
                  type="radio"
                  name="options2"
                  [disabled]="true"
                  [value]="ExpenseType.BOH"
                  [(ngModel)]="data.expense_type" />Treat as Fixed BOH Labor
              </label>
              <label
                class="btn btn-radio"
                *ngIf="data._edit"
                [class.active]="data._edit.expense_type==ExpenseType.BOH">
                <input
                  type="radio"
                  name="options2"
                  [value]="ExpenseType.BOH"
                  [(ngModel)]="data._edit.expense_type" />Treat as Fixed BOH
                Labor
              </label>
            </div>
          </div>

          <div class="col body-bg">
            <div class="btn-group-toggle p-1">
              <label
                class="btn btn-radio"
                *ngIf="!data._edit"
                [class.active]="data.expense_type==ExpenseType.Fixed">
                <input
                  type="radio"
                  name="options2"
                  [disabled]="true"
                  [value]="ExpenseType.Fixed"
                  [(ngModel)]="data.expense_type" />Fixed Cost
              </label>
              <label
                class="btn btn-radio"
                *ngIf="data._edit"
                [class.active]="data._edit.expense_type==ExpenseType.Fixed">
                <input
                  type="radio"
                  name="options2"
                  [value]="ExpenseType.Fixed"
                  [(ngModel)]="data._edit.expense_type" />Fixed Cost
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3">Only unused expense categories can be deleted.</div>
    </div>
  </div>
</div>
<modal-fixed-cost #modalFixedCost></modal-fixed-cost>
