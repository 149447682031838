<ng-content></ng-content>
<ng-template #popTemplate>
  <div
    *ngFor="let color of colors"
    class="color-picker-single"
    [class.color-picker-single-picked]="color==_value"
    [style.background-color]="color"
    (click)="selectColor(color)"></div>
</ng-template>

<div class="color-picker-outer">
  <div
    class="color-picker-selector"
    [popover]="popTemplate"
    [outsideClick]="true"
    [placement]="placement"
    #pop="bs-popover"
    [style.background-color]="_value"></div>
</div>
