<div *ngIf="loc">
  <div class="card">
    <div class="card-body">
      <label>Edit Staff Preferences</label>
      <input-toggle
        style="width: 50%"
        [(ngModel)]="loc.allow_preferences"
        (ngModelChange)="saveLocationField('allow_preferences')"
        >Allow Staff To Edit Preferences</input-toggle
      >
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <label>Schedule Colors</label>
      <div class="row">
        <div class="col">
          <div class="btn-group-toggle">
            <label class="btn btn-radio" [class.active]="loc.sched_color">
              <input
                type="radio"
                name="options1"
                [value]="true"
                [(ngModel)]="loc.sched_color"
                (ngModelChange)="saveLocationField('sched_color')" />Use Staff
              Colors
            </label>
          </div>
        </div>
        <div class="col">
          <div class="btn-group-toggle">
            <label class="btn btn-radio" [class.active]="!loc.sched_color">
              <input
                type="radio"
                name="options1"
                [value]="false"
                [(ngModel)]="loc.sched_color"
                (ngModelChange)="saveLocationField('sched_color')" />Use
              Position Colors
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <label>Schedule Sort Order</label>
      <div class="row">
        <div class="col">
          <div class="btn-group-toggle">
            <label
              class="btn btn-radio"
              [class.active]="loc.sched_sort_order==ScheduleSortOrder.Time">
              <input
                type="radio"
                name="options2"
                [value]="ScheduleSortOrder.Time"
                [(ngModel)]="loc.sched_sort_order"
                (ngModelChange)="saveLocationField('sched_sort_order')" />Sort
              By Start Time
            </label>
          </div>
        </div>
        <div class="col">
          <div class="btn-group-toggle">
            <label
              class="btn btn-radio"
              [class.active]="loc.sched_sort_order==ScheduleSortOrder.Position">
              <input
                type="radio"
                name="options2"
                [value]="ScheduleSortOrder.Position"
                [(ngModel)]="loc.sched_sort_order"
                (ngModelChange)="saveLocationField('sched_sort_order')" />Sort
              By Position
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <label>Min and Max Weekly Labor</label>
      <div class="d-flex flex-wrap flex-row justify-content-between">
        <ng-container *ngFor="let day of DaysOfWeekKeys">
          <div *ngIf="!loc.dark_days[day]">
            <ul>
              <li>{{DaysOfWeek[day]}}</li>
              <li>
                <input
                  [(ngModel)]="loc.min_labor[day]"
                  [name]="'name_min_'+day"
                  type="number"
                  min="0"
                  step="0.01"
                  class="form-control"
                  style="font-size: 0.875rem"
                  placeholder="Min Labor hours" />
              </li>
              <li>
                <input
                  [(ngModel)]="loc.max_labor[day]"
                  [name]="'name_max_'+day"
                  type="number"
                  min="0"
                  step="0.01"
                  class="form-control"
                  style="font-size: 0.875rem"
                  placeholder="Max Labor hours" />
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
      <div>
        When scheduling shifts these settings are hints to eliminate
        over-scheduling or ensure that minimum staffing requirements are met.
        <button
          class="btn btn-primary float-right"
          style="font-size: 0.875rem"
          type="button"
          (click)="saveMinMax()">
          Save Min Max Labor
        </button>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label>Time Off Request Days In Advance</label>
      <input
        [(ngModel)]="loc.to_days"
        debounce
        (result)="saveLocationField('to_days')"
        type="number"
        name="time_off_days"
        max="90"
        min="0"
        class="form-control"
        placeholder="Number of Days" />
    </div>
  </div>
  <div class="col-12 col-lg-6 card">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <label>Allow Phone Number in Printed Schedule</label>
        <div class="custom-control custom-switch custom-switch-lg">
          <input
            [(ngModel)]="loc.print_schedule_allow_phone"
            debounce
            (change)="saveLocationField('print_schedule_allow_phone')"
            type="checkbox"
            class="custom-control-input"
            id="allowPhoneSwitch" />
          <label class="custom-control-label" for="allowPhoneSwitch"> </label>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="blackOuts">
    <div class="card-body">
      <label>Black Out Days</label>
      <form ngNativeValidate>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <input
              type="text"
              class="form-control input-calendar override-invalid"
              placeholder="Start Date (yyyy-mm-dd)"
              bsDatepicker
              [(ngModel)]="blackOutStart"
              name="blackout_start_date"
              required />
          </div>
          <div class="col-md-6 col-sm-12">
            <input
              type="text"
              class="form-control input-calendar override-invalid"
              placeholder="End Date (yyyy-mm-dd)"
              bsDatepicker
              [(ngModel)]="blackOutEnd"
              name="blackout_end_date"
              required />
          </div>
        </div>
        <div class="my-2">
          <span
            >Black Out Days are days that employees are not allowed Time Off.
            <button
              class="btn btn-primary float-right"
              style="font-size: 0.875rem"
              type="submit"
              (click)="saveBlackout()">
              Add Black Out Days
            </button>
          </span>
        </div>
      </form>
      <div *ngFor="let d of blackOuts | orderBy: ['start']">
        <label class="mt=3">Black Out Days</label>
        <div class="row">
          <div class="col-10">
            {{d.start | date:"EEEE, MMM d, y"}} - {{d.end | date:"EEEE, MMM d,
            y"}}
          </div>
          <div class="col-2">
            <span
              class="cdi-s-trash float-right pointer"
              (click)="deleteBlackout(d)"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
