import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { ITimeClock, ITimeClockUser } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class TimeClockService extends BaseService<ITimeClock> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'timeClock');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'start');
          this.fixDate(d, 'end');
        }
      } else {
        this.fixDate(data, 'start');
        this.fixDate(data, 'end');
      }
      return data;
    };
  }

  list(filter?: any): Observable<ITimeClock[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      if ('start' in filter) {
        this.formatDateOnly(filter, 'start');
      }
      if ('end' in filter) {
        this.formatDateOnly(filter, 'end');
      }
      for (let key in filter) {
        if (filter[key]) {
          queryParameters = queryParameters.set(key, filter[key]);
        }
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl, { params: queryParameters })
      .pipe(map(this.postProcess()), catchError(BaseService.handleError));
  }

  listClockedIn(filter?: any): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        if (filter[key]) {
          queryParameters = queryParameters.set(key, filter[key]);
        }
      }
    }

    return this.http
      .get(this.baseUrl + 'in', { params: queryParameters })
      .pipe(map(this.postProcess()), catchError(BaseService.handleError));
  }

  getLocation(): Observable<{ location_id: number; name: string }> {
    return this.http.get<{ location_id: number; name: string }>(
      this.baseUrl + 'location'
    );
  }

  tryClockOut(
    data: any
  ): Observable<{ clocked_out: number; needs_tips: boolean }> {
    return this.http.post<{ clocked_out: number; needs_tips: boolean }>(
      this.baseUrl + 'out',
      data,
      this.defaultOptions
    );
  }

  reportTips(data: {
    id: number;
    perms: any;
    tips: number;
  }): Observable<number> {
    return this.http.post<number>(
      this.baseUrl + 'tips',
      data,
      this.defaultOptions
    );
  }

  markRead(data: any): Observable<number> {
    return this.http.post<number>(
      this.baseUrl + 'markRead',
      data,
      this.defaultOptions
    );
  }

  getUserInfo(data: any): Observable<{
    shift: { id: number; employee_position_id: number; ot_hours: number };
    employee_positions: { id: number; name }[];
    ot_hours: number;
    user_id: number;
    location_id: number;
  }> {
    // Shift Id
    return this.http.post<any>(
      this.baseUrl + 'userInfo',
      data,
      this.defaultOptions
    );
  }
}

export { ITimeClock } from '../shared/api';
