import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { BaseService, fixShortDate } from './base.service';
import { AccountService } from './account.service';
import { IUserCreditCard, IResult, getBaseUrl } from '../shared/api';

@Injectable()
export class UserCreditCardService extends BaseService<IUserCreditCard> {
  constructor(
    protected accountService: AccountService,
    protected http: HttpClient
  ) {
    super(accountService, http, 'userCreditCard');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          fixShortDate(d, 'exp');
        }
      } else {
        fixShortDate(data, 'exp');
      }
      return data;
    };
  }
}

export { IUserCreditCard } from '../shared/api';
