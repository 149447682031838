import * as Icons from './svg';

export type IconName = keyof typeof Icons;

export interface Icon {
  name: IconName;
  data: string;
}

export const IconObjects: { [key in IconName]: Icon } = Object.keys(
  Icons
).reduce<{
  [key in IconName]: Icon;
}>(
  (icons, name) => ({
    ...icons,
    [name]: {
      name: name as IconName,
      data: Icons[name as IconName],
    },
  }),
  {} as { [key in IconName]: Icon }
);
