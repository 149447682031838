import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from './shared/baseRole';
import { AccountService, IUser } from './services/account.service';
import { EventService, EventType } from './services/event.service';
import { InvoiceService } from './services/invoice.service';
import { ContactService } from './services/contact.service';
import { LogService } from './services/log.service';
import { LogNoteType, LogNoteNames, ServiceTimeType } from './shared/api';

@Component({
  templateUrl: './search.html',
})
export class SearchComponent extends baseRole implements OnInit {
  public searchString: string;
  public events: any[];
  public tasks: any[];
  public invoices: any[];
  public contacts: any[];
  public logs: any[][];
  public matchCount: number;
  public LogNoteType = LogNoteType;
  public LogNoteTypeKeys = Object.keys(LogNoteType).filter(Number);
  public LogNoteNames = LogNoteNames;
  public ServiceTimeType = ServiceTimeType;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected eventService: EventService,
    protected invoiceService: InvoiceService,
    protected logService: LogService,
    protected contactService: ContactService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.searchString = params['query'];
      this.search();
    });
  }

  search() {
    forkJoin([
      this.eventService.search(this.searchString),
      this.contactService.search(this.searchString),
      this.invoiceService.search(this.searchString),
      this.logService.search(this.searchString),
    ]).subscribe(results => {
      //console.log(results);
      this.events = results[0].filter(e => {
        return e.event_type == EventType.Event;
      });
      this.tasks = results[0].filter(e => {
        return e.event_type == EventType.Task;
      });
      if (this.isAllowed(this.Permission.ViewVendor)) {
        this.contacts = results[1];
      }
      this.invoices = results[2];
      this.filterLogs(results[3]); // Might need to handle these better.
      this.matchCount +=
        this.events.length +
        this.tasks.length +
        this.contacts.length +
        this.invoices.length;
    });
  }

  // Filter out the type of thingy..
  filterLogs(data: any[]) {
    this.logs = [];
    this.matchCount = 0;
    for (let l of this.LogNoteTypeKeys) {
      this.logs[l] = data.filter(e => {
        return e.log_note_type == l;
      });
      this.matchCount += this.logs[l].length;
    }
  }
}
