import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUsdaListItem, IUsdaItem, IResult, getBaseUrl } from '../shared/api';

@Injectable()
export class UsdaService {
  protected baseUrl: string;
  protected defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  protected defaultOptions = { headers: this.defaultHeaders };

  constructor(protected http: HttpClient) {
    this.baseUrl = getBaseUrl() + 'usda/';
  }

  search(searchString: string): Observable<IUsdaListItem[]> {
    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('search', searchString);

    return this.http.get<IUsdaListItem[]>(this.baseUrl + 'search', {
      params: queryParameters,
    });
  }

  get(id: string): Observable<IUsdaItem> {
    return this.http.get<IUsdaItem>(this.baseUrl + id);
  }
}

export { IUsdaListItem, IUsdaItem } from '../shared/api';
