import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IDocGroup } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class DocGroupService extends BaseService<IDocGroup> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'docGroup');
  }
}

export { IDocGroup } from '../shared/api';
