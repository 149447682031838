import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ILocation, LocationService } from '../services/location.service';
import {
  LocationType,
  SalesIntegrationType,
  SalesIntegrationFeatures,
  SalesIntegrationShiftSyncType,
} from '../shared/api';
import { baseRole } from '../shared/baseRole';

const fields = [
  'id',
  'sales_integration_type',
  'sales_integration_sync_employee_data',
  'sales_integration_sync_sales_data',
  'sales_integration_sync_shift',
  'sales_integration_sync_timeclock',
  'sales_integration_send_employee_email',
];

@Component({
  selector: 'integration-options',
  templateUrl: './integrationOptions.html',
})
export class IntegrationOptionsComponent extends baseRole {
  public _location_id: number;
  public data: any;
  public _orig_data: any;
  public SalesIntegrationType = SalesIntegrationType;
  public SalesIntegrationFeatures = SalesIntegrationFeatures;
  public SalesIntegrationShiftSyncType = SalesIntegrationShiftSyncType;

  @Output() onComplete = new EventEmitter<boolean>();

  @Input() initialSetup = false;
  @Input() set location_id(value: number) {
    if (value && value != this._location_id) {
      this._location_id = value;
      this.locationService.get(value).subscribe(data => {
        this.data = {};
        this._orig_data = {};
        for (let f of fields) {
          this.data[f] = this._orig_data[f] = data[f];
        }
      });
    }
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    public accountService: AccountService
  ) {
    super(accountService);
  }

  save() {
    this.locationService.update(this.data).subscribe(() => {
      for (let f of fields) {
        this._orig_data[f] = this.data[f];
      }
      this.onComplete.next(true);
    });
  }

  canSave() {
    if (this.initialSetup) return true;
    for (let f of fields) {
      if (this.data[f] != this._orig_data[f]) return true;
    }
    return false;
  }

  cancel() {
    console.log('Canceling');
    this.onComplete.next(false);
  }
}
