import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IPublishLog } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class PublishLogService extends BaseService<IPublishLog> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'publishLog');
  }

  preProcess(data: any): any {
    this.dateToLocal(data, 'week');
    //	console.log('Pre Process');
    //	console.log(data);
    return data;
  }

  list(filter?: any): Observable<any[]> {
    if ('week' in filter) {
      this.formatDateOnly(filter, 'week');
    }
    return super.list(filter);
  }

  listForDateMenu(
    start: string,
    end: string
  ): Observable<{ date: string; is_published: boolean; is_budget: boolean }[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    queryParameters = queryParameters.set('start', start.toString());
    queryParameters = queryParameters.set('end', end.toString());

    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get<{ date: string; is_published: boolean; is_budget: boolean }[]>(
        this.baseUrl + 'dateMenu',
        { params: queryParameters }
      )
      .pipe(catchError(BaseService.handleError));
  }
}

export { IPublishLog } from '../shared/api';
