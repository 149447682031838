import { Injectable } from '@angular/core';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IShiftTrade } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ShiftTradeService extends BaseService<IShiftTrade> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'shiftTrade');
  }

  listAll(filter?: any): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }

    if (!filter || !this.accountService.isAdmin() || !filter.location_id) {
      queryParameters = userFilter.updateQuery(queryParameters);
    }

    return this.http
      .get(this.baseUrl + 'all', { params: queryParameters })
      .pipe(map(this.postProcess()), catchError(BaseService.handleError));
  }
}

export { IShiftTrade } from '../shared/api';
