import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IUser,
  SelectAddAll,
} from '../services/account.service';
import { ContactService, IContact } from '../services/contact.service';
import {
  InventoryLocationService,
  IInventoryLocation,
} from '../services/inventoryLocation.service';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
import { SkuService, ISku } from '../services/sku.service';
import {
  ItemCategoryService,
  IItemCategory,
} from '../services/itemCategory.service';
import { ExpenseType, StringSort } from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './sku.html',
})
export class SkuComponent extends baseRole implements OnInit {
  public vendors: IContact[];
  //public vendorFilterId: string = '';
  public inventoryLocations: IInventoryLocation[];
  //public inventoryLocationFilterId: string ='';
  public expenseAccounts: IExpenseAccount[];
  //public expenseCategoryFilterId: string = '';
  //public skuNumberFilter: string;
  public filterKey = 'inventory_sku_filter_key';

  public filterData: {
    sku: string;
    expense_account_id: string;
    inventory_location_id: string;
    contact_id: string;
  };

  public _data: any[];
  public data: any[];
  public hideSku: ISku;

  @ViewChild('hideModal')
  public modal: ModalDirective;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactService: ContactService,
    protected inventoryLocationService: InventoryLocationService,
    protected expenseAccountService: ExpenseAccountService,
    protected skuService: SkuService
  ) {
    super(accountService);
  }

  getDefaultFilter() {
    return {
      contact_id: '',
      expense_account_id: '',
      inventory_location_id: '',
      sku: null,
    };
  }
  ngOnInit() {
    if (!this.isAllowed(this.Permission.ManageInventory)) {
      this.router.navigate(['/']);
      return;
    }
    this.filterData = this.accountService.getTempState(
      this.filterKey,
      this.getDefaultFilter()
    );

    this.contactService
      .list({ hide: false, is_vendor: true })
      .subscribe(data => {
        data.sort((a, b) => {
          return a.company.localeCompare(b.company);
        });
        this.vendors = SelectAddAll(data, 'All Vendors', 'company');
      });

    this.inventoryLocationService.list().subscribe(data => {
      data.sort((a, b) => {
        return a.order_by - b.order_by;
      });
      this.inventoryLocations = SelectAddAll(data, 'All Locations');
    });
    this.expenseAccountService
      .list({ expense_type: ExpenseType.COGS })
      .subscribe(data => {
        this.expenseAccounts = SelectAddAll(data, 'All COG Categories');
      });
    /*	this one returns all, not just cogs. So  above copied from skuEdit- it did work now fails
			this.expenseAccountService.list({cogs: true}).subscribe((data)=> {
			data.sort((a,b)=> { return a.name.localeCompare(b.name); });
			this.expenseAccounts = SelectAddAll(data,'All COG Categories');
		});
  */

    this.skuService.list({ hidden: true }).subscribe(data => {
      // I'm mapping inventory location ids to an object for fast lookup.
      for (let d of data) {
        let i: any = {};
        for (let il of (<any>d).inventory_location) {
          i[il.id] = il.id;
        }
        (<any>d).inventory_location = i;
      }
      this._data = data;
      this.data = this._data;
      this.filter();
    });
  }

  clearFilter() {
    this.filterData = this.getDefaultFilter();
    this.filter();
  }

  deleteSku(obj) {
    this.skuService.delete(obj.id).subscribe(data => {
      let idx = this._data.indexOf(obj);
      this._data.splice(idx, 1);
      this.filter();
    });
  }

  filter() {
    this.accountService.setTempState(this.filterKey, this.filterData);
    let skuName = null;
    if (this.filterData.sku) {
      skuName = this.filterData.sku.toLowerCase();
    }
    this.data = this._data.filter(e => {
      if (this.filterData.sku) {
        if (
          (!e.sku_num || e.sku_num.toLowerCase().indexOf(skuName) < 0) &&
          e.name.toLowerCase().indexOf(skuName) < 0
        )
          return false;
      }

      if (
        this.filterData.expense_account_id.length > 0 &&
        +this.filterData.expense_account_id != e.expense_account_id
      )
        return false;

      if (
        this.filterData.contact_id.length > 0 &&
        +this.filterData.contact_id != e.contact_id
      )
        return false;

      if (
        this.filterData.inventory_location_id.length > 0 &&
        !(+this.filterData.inventory_location_id in e.inventory_location)
      )
        return false;
      return true;
    });
  }
  toggleSku(sku) {
    console.log('toggleSku', sku);
    this.skuService
      .update({ id: sku.id, location_id: sku.location_id, hide: !sku.hide })
      .subscribe(() => {
        sku.hide = !sku.hide;
      });
  }
  doHideSku() {
    this.toggleSku(this.hideSku);
    this.modal.hide();
  }
  showHideModal(sku) {
    this.hideSku = sku;
    this.modal.show();
  }
  cancelModal() {
    this.modal.hide();
    this.hideSku = null;
  }
}
