<div *ngIf="user">
  <div class="card">
    <div class="card-body row-striped">
      <label> Positions</label>
      <ng-container *ngFor="let p of myPositions | orderBy: 'name'">
        <div class="row row-table" *ngIf="!p._edit">
          <div class="col-5">
            <b>{{p.name}}</b>
            <span *ngIf="p.is_primary">&nbsp;- Primary Position</span>
            <span *ngIf="!p.exists" class="text-danger"> Position Deleted</span>
          </div>
          <div class="col">
            <span
              *ngIf="(isAllowed(Permission.EditSalaries) && p.rate_type == RateType.Salary) || (isAllowed(Permission.EditHourlyWages) && !isSalary)">
              {{ p.rate | currency: 'USD' }} {{ RateType[p.rate_type] }}
            </span>
            <span *ngIf="p.rate_type != RateType.Salary && isSalary">
              Salaried
            </span>
          </div>
          <div class="col d-flex justify-content-end">
            <span
              *ngIf="(isSalary && isAllowed(Permission.EditSalaries)) || (!isSalary && isAllowed(Permission.EditHourlyWages))"
              class="pointer cdi-s-edit"
              (click)="editPosition(p)">
            </span>
            <span
              *ngIf="((isSalary && isAllowed(Permission.EditSalaries)) || (!isSalary && isAllowed(Permission.EditHourlyWages))) && !p.is_primary"
              class="pointer cdi-s-trash"
              (click)="deletePosition(p)">
            </span>
            <span class="cdi-s-blank" *ngIf="p.is_primary"> </span>
          </div>
        </div>

        <form *ngIf="p._edit">
          <div class="row d-flex">
            <div class="col-md-5">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"> {{p.name}}</span>
                </div>
                <input-toggle
                  name="is_primary"
                  [(ngModel)]="p.is_primary"
                  [disabled]="p._edit.is_primary">
                  Primary Position</input-toggle
                >
              </div>
            </div>
            <div class="col">
              <div class="input-group mb-3">
                <div
                  class="input-group-prepend"
                  *ngIf="!isSalary || p.rate_type == RateType.Salary">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  *ngIf="!isSalary || p._edit.rate_type == RateType.Salary"
                  [(ngModel)]="p.rate"
                  type="number"
                  name="rate"
                  step="0.01"
                  required
                  class="form-control form-control-lg" />
                <select
                  *ngIf="!isSalary || p._edit.rate_type == RateType.Salary"
                  class="form-control-select d-inline-block"
                  [(ngModel)]="p.rate_type"
                  name="rate_type">
                  <option [value]="RateType.Salary">Salary (Yearly)</option>
                  <option [value]="RateType.Hourly">Hourly</option>
                </select>
                <div class="input-group-append">
                  <span
                    class="cdi-s-save float-right pointer"
                    (click)="savePosition(p)">
                  </span>
                  <span
                    class="cdi-s-cancel float-right pointer"
                    (click)="cancelPosition(p)"></span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <small class="ml-3"
      >You cannot delete the Primary Position. Move Primary to another position
      if needed.</small
    >
  </div>

  <div class="card" *ngIf="availablePositions && availablePositions.length!=0">
    <div class="card-body">
      <form ngNativeValidate *ngIf="newPosition">
        <div class="row d-flex">
          <div class="col-md-5">
            <div class="input-group mb-3">
              <select
                [ngModel]="newPosition.position_id ||''"
                (ngModelChange)="newPosition.position_id =$event"
                class="form-control-select d-inline-block override-invalid r"
                name="position_id"
                required>
                <option *ngFor="let p of availablePositions" [value]="p.id">
                  {{p.name}}
                </option>
              </select>
              <div class="input-group-append py-1">
                <input-toggle
                  name="is_primary"
                  [(ngModel)]="newPosition.is_primary"
                  [disabled]="myPositions && myPositions.length==0">
                  Primary Position</input-toggle
                >
              </div>
            </div>
          </div>
          <div class="col">
            <div class="input-group mb-3" *ngIf="!isSalary">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                [(ngModel)]="newPosition.rate"
                step="0.01"
                type="number"
                name="rate"
                required
                class="form-control form-control-lg override-invalid"
                placeholder="Pay Rate" />
              <select
                class="form-control-select"
                [(ngModel)]="newPosition.rate_type"
                name="rate_type"
                required>
                <option [value]="RateType.Salary">Salary (Yearly)</option>
                <option [value]="RateType.Hourly">Hourly</option>
              </select>
              <div class="input-group-append">
                <button
                class="btn btn-primary override-invalid"
                type="submit"
                (click)="addPosition(newPosition)">
                  <icons name="Plus"></icons>
                </button>
              </div>
            </div>
            <div *ngIf="isSalary" class="mt-2">
              <button
                class="btn btn-primary override-invalid"
                type="submit"
                (click)="addPosition(newPosition)">
                <icons name="Plus"></icons>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <global-error [error]="newPositionError"></global-error>

  <div class="card" *ngIf="historyPositions && historyPositions.length>0">
    <div class="card-body">
      <label>History</label>
      <div *ngFor="let p of historyPositions | orderBy: ['name','end','id']">
        <div class="row" *ngIf="!p._edit">
          <div class="col-md-4">{{p.name}}</div>
          <div class="col-md-3">
            {{p.rate | currency: 'USD' }} {{RateType[p.rate_type]}}
          </div>
          <div class="col-md-5">
            {{p.start | date}} - {{p.end | date}}
            <span
              class="cdi-s-edit float-right pointer"
              (click)="editPosition(p)">
            </span>
          </div>
        </div>
        <form *ngIf="p._edit" ngNativeValidate>
          <div class="row d-flex">
            <div class="col-md-6">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{p.name}}</span>
                  <span class="input-group-text">$</span>
                </div>
                <input
                  type="number"
                  [(ngModel)]="p.rate"
                  name="rate"
                  class="form-control form-control-lg"
                  value="15" />
                <select
                  [(ngModel)]="p.rate_type"
                  name="rate_type"
                  class="form-control-select d-inline-block px-0">
                  <option [value]="RateType.Salary">Salary (Yearly)</option>
                  <option [value]="RateType.Hourly">Hourly</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder=" 2016-09-01"
                  bsDatepicker
                  [(ngModel)]="p.start"
                  name="start_date"
                  [disabled]="p._blackEdit" />
                <input
                  type="text"
                  class="form-control form-control-lg"
                  value=" 2017-08-14"
                  bsDatepicker
                  [(ngModel)]="p.end"
                  name="end_date" />
                <div class="input-group-append">
                  <span class="cdi-s-save pointer" (click)="savePosition(p)">
                  </span>
                  <span
                    class="cdi-s-cancel pointer"
                    (click)="cancelPosition(p)"></span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <done-button
        class="float-right"
        (click)="addPosition(true)"
        routerLink="/users"></done-button>
      <!--<save-button class='float-right' (click)="save()"></save-button>-->
    </div>
  </div>
</div>
