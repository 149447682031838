import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';
import { BillingService } from '../services/billing.service';
import { IntegrationService } from '../services/integration.service';
import { IntegrationSalesService } from '../services/integrationSales.service';
import { NotificationService } from '../services/notification.service';

import { AdminComponent } from './admin.component';
import {
  BillingPlanListComponent,
  BillingPlanEditComponent,
} from './billingPlan.component';
import { BillingComponent } from './billing.component';
import { BillingChartComponent } from './billingChart.component';
import { BillingReportComponent } from './billingReport.component';
import { HomeComponent } from './home.component';
import { ImportComponent, ImportContactComponent } from './import.component';
import { LocationEditComponent } from './location.component';
import { LocationActiveComponent } from './locationActive.component';
import { IntegrationSalesListComponent } from './integration.component';
import {
  NotificationListComponent,
  NotificationEditComponent,
} from './notification.component';
import { TestComponent } from './test.component';
import { TestHomeComponent } from './testHome.component';
import { TestIconComponent } from './testIcon.component';
import { TestUIComponent } from './testUI.component';

import { IconsModule } from '../app/icons/icons.module';
import { routing } from './admin.routing';
import { FoodVendorService } from 'source/services/foodVendor.service';

@NgModule({
  declarations: [
    AdminComponent,
    BillingPlanListComponent,
    BillingPlanEditComponent,
    BillingComponent,
    BillingChartComponent,
    BillingReportComponent,
    HomeComponent,
    ImportComponent,
    ImportContactComponent,
    LocationEditComponent,
    LocationActiveComponent,
    IntegrationSalesListComponent,
    NotificationEditComponent,
    NotificationListComponent,
    TestComponent,
    TestHomeComponent,
    TestIconComponent,
    TestUIComponent,
  ],
  imports: [SharedModule, IconsModule, routing],
  providers: [
    BillingService,
    IntegrationService,
    IntegrationSalesService,
    NotificationService,
    FoodVendorService,
  ],
})
export class AdminModule {}
