<div class="row authentication">
  <div class="col-md-4 col-10 text-center card">
    <div class="card-body">
      <div style="width: 100%" class="my-4">
        <span class="cdi-l-logo cdi-biggest mx-auto"></span>
      </div>
      <div class="text-center">
        <strong>
          Can't login? Forgot your password?<br />
          We can send help. Please enter your CheddrSuite email below.
        </strong>
        <div>
          <form ngNativeValidate *ngIf="!forgotPasswordMessage">
            <input
              type="text"
              class="form-control"
              name="username"
              placeholder="Email Address"
              [(ngModel)]="username"
              required />
            <button
              class="btn btn-primary w-100"
              type="submit"
              (click)="forgotPassword()">
              Send
            </button>
            <local-error [error]="error" class="mt-1"></local-error>
          </form>
          <div *ngIf="forgotPasswordMessage" class="mt-4 text-center">
            <strong>{{forgotPasswordMessage}}</strong>
          </div>
          <br />
          <a routerLink="/login">Main Cheddr Login</a>
        </div>
      </div>
    </div>
  </div>
</div>
