<div class="container-fluid subnav-padding">
  <div class="row nav-padding">
    <div class="col-12">
      <select
        [ngModel]="currentItemCategoryId || ''"
        (ngModelChange)="currentItemCategoryId =$event; filter()"
        name="item_category_selector"
        class="form-control d-inline-block mr-2"
        style="width: 10rem">
        <option *ngFor="let ic of itemCategories" [value]="ic.id">
          {{ic.name}}
        </option>
      </select>
      <button class="btn" (click)="modalItemCategory.show()">
        Edit Categories
      </button>
      <button class="btn float-right btn-primary" (click)="modalItem.show()">
        Add Menu Item
      </button>
    </div>
  </div>

  <div class="nav-padding"></div>
  <div class="row col-12">
    <div
      class="col-sm-12 col-md-4 col-lg-3 col-12"
      *ngFor="let d of data | orderBy:'name'; let indexOfElement = index;">
      <div class="card mx-1">
        <!--  FRONT OF CARD -->
        <div class="card-body column" style="padding-bottom: 30px">
          <div
            class="d-flex row"
            style="
              margin-top: -10px;
              margin-right: -5px;
              margin-left: -12px;
              justify-content: space-between;
            ">
            <icons
              class="icons pointer"
              name="InfoCircle"
              (click)="selectedItem=d; moreInfoModal.show(); setSelectedItemVariation(indexOfElement)">
            </icons>
            <div class="d-flex row">
              <icons (click)="deleteItem(d)" class="icons pointer" name="Trash">
              </icons>
              <icons
                (click)="modalItem.show(d)"
                class="icons pointer"
                name="Edit">
              </icons>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <img
              class="rounded float-left mr-3 mb-2"
              style="object-fit: cover"
              [src]="getItemImage(d)"
              height="80"
              width="80" />
            <span style="font-size: 1.2rem; font-weight: bold"
              >{{d.name}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #moreInfoModalSelector="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Doc View Modal"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content" *ngIf="selectedItem">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          {{selectedItem.name}}'s' Information
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="moreInfoModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body mx-2">
        <div *ngFor="let v of selectedItem.variations">
          <div class="row">
            <div class="col-6">
              <label
                >{{v.name}}
                <icons
                  (click)="modalRecipe.show(selectedItem,v)"
                  class="icons pointer float-right"
                  style="margin-top: -10px"
                  name="Edit">
                </icons>
              </label>
            </div>
            <div class="col-6">
              <label>Nutrition</label>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="card-text py-1 grey-stripe-bg">
                <strong>Price</strong>
                <span class="float-right">{{v.price | currency: 'USD'}}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="card-text py-1 grey-stripe-bg">
                <strong>Kcal</strong>
                <span class="float-right"> {{v.kcal | number: '1.0-2'}} </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="card-text py-1">
                <strong>Cost</strong>
                <span class="float-right"
                  >{{v.cost | currency: 'USD'}} /
                  <small>{{(v.cost/v.price) |percent}}</small></span
                >
              </div>
            </div>
            <div class="col-6">
              <div class="card-text py-1">
                <strong>Fat</strong>
                <span class="float-right"> {{ v.fat | number: '1.0-2'}} </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="card-text py-1 grey-stripe-bg">
                <strong>Protein</strong>
                <span class="float-right">
                  {{ v.protein | number: '1.0-2'}}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <strong>Carbs</strong>
              <span class="card-text py-1 float-right">
                {{ v.carbs | number: '1.0-2'}}
              </span>
            </div>
          </div>
        </div>
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="moreInfoModal.hide()"></cancel-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<modal-item #modalItem (onChange)="onItemChange($event)"></modal-item>
<modal-item-category
  #modalItemCategory
  (onDone)="loadCategories()"></modal-item-category>
<modal-recipe #modalRecipe (onDone)="onItemChange($event)"></modal-recipe>
