import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ISubNavItem } from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './reports.html',
})
export class ReportsComponent extends baseRole implements OnInit {
  public menu: ISubNavItem[] = [
    {
      label: 'Sales Reports',
      routerLink: '/reports',
      exact: true,
    },
    {
      label: 'Payroll Reports',
      routerLink: '/reports/payroll',
    },
  ];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Reports');
  }

  ngOnInit() {
    if (!this.isFreemium() && !this.isAllowed(this.Permission.ViewSales)) {
      this.menu = this.menu.filter(e => {
        return e.routerLink != '/reports';
      });
    }
  }
}
