import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';
import { ServiceTimeService } from '../services/serviceTime.service';
import { ShiftService } from '../services/shift.service';

import { CalendarComponent } from './calendar.component';
import { HomeComponent } from './home.component';

import { routing } from './calendar.routing';
import { IconsModule } from 'source/app/icons/icons.module';

@NgModule({
  declarations: [CalendarComponent, HomeComponent],
  imports: [SharedModule, routing, IconsModule],
  providers: [
    ServiceTimeService,
    ShiftService,
    //LogService
  ],
})
export class CalendarModule {}
