import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { moment, ServiceTimeType } from '../shared/api';
import { IntegrationSalesService } from '../services/integrationSales.service';
import { LocationService } from '../services/location.service';

const logFields = [
  'net_sales',
  'tax',
  'guest_count',
  'comps',
  'discounts',
  'employee_discounts',
  'pay_outs',
  'cash',
  'expected_cash',
  'gift_redeemed',
  'gift_sold',
  'cc_tips_collected',
];

@Component({
  templateUrl: './integrationSales.list.html',
})
export class IntegrationSalesListComponent implements OnInit {
  public data;
  public loc;
  public search;
  public startDate: Date;
  public endDate: Date;
  public totals: any = {};
  public ServiceTimeType = ServiceTimeType;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected integrationSalesService: IntegrationSalesService,
    protected locationService: LocationService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.startDate = moment().subtract(1, 'day').toDate();
      this.endDate = new Date();
      this.search = {
        location_id: params['id'],
        start: moment().startOf('day').subtract(1, 'day').toDate(),
        end: moment().startOf('day').toDate(),
      };
      if (!this.search.location_id) return;
      this.locationService.get(this.search.location_id).subscribe(data => {
        this.loc = data;
      });
      this.load();
    });
  }

  load() {
    this.integrationSalesService.list(this.search).subscribe(data => {
      this.totals = {};

      for (let f of logFields) {
        this.totals[f] = 0;
      }

      this.data = data;
      for (let d of data) {
        for (let f of logFields) {
          if (d[f]) this.totals[f] += d[f];
        }
      }
    });
  }
}
