<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Trade Board"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Trade Request
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div class="card">
          <div class="card-body" *ngIf="shift">
            For shift:
            <b
              >{{shift.start | date:'EEEE MMMM d, y, h:mm a'}} - {{shift.end|
              date:'EEEE MMMM d, y, h:mm a' }}</b
            ><br />
            Please choose what kind of shift trade you would like to put onto
            the trade board?<br />
            <div class="row">
              <div class="col-md-4 col-12">
                <div class="btn-group-toggle ml-3">
                  <label
                    class="btn btn-radio"
                    [class.active]="trade_type==TradeType.GiveUp">
                    <input
                      type="radio"
                      name="options1"
                      [value]="TradeType.GiveUp"
                      [(ngModel)]="trade_type" />
                    Give Up This Shift
                  </label>
                </div>
              </div>

              <div class="col-md-4 col-12">
                <div class="btn-group-toggle ml-3">
                  <label
                    class="btn btn-radio"
                    [class.active]="trade_type==TradeType.Pickup">
                    <input
                      type="radio"
                      name="options1"
                      [value]="TradeType.Pickup"
                      [(ngModel)]="trade_type" />
                    Offer To Trade This Shift For ANY Shift
                  </label>
                </div>
              </div>

              <div class="col-md-4 col-12">
                <div class="btn-group-toggle ml-3">
                  <label
                    class="btn btn-radio"
                    [class.active]="trade_type==TradeType.Trade">
                    <input
                      type="radio"
                      name="options1"
                      [value]="TradeType.Trade"
                      [(ngModel)]="trade_type" />
                    Trade This Shift For A SPECIFIC SHIFT
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" *ngIf="trade_type==TradeType.Trade">
          <div class="card-body">
            <div class="row" *ngIf="availableShifts.length==0">
              <div class="col-12">No shifts available</div>
            </div>
            <div
              class="row"
              *ngFor="let shift of availableShifts | orderBy: 'start'">
              <div class="col-12 btn-group-toggle mb-1">
                <label
                  class="btn btn-radio"
                  [class.active]="selectedShift==shift">
                  <input
                    type="radio"
                    name="shifts"
                    [value]="shift"
                    [(ngModel)]="selectedShift" />
                  <span *ngIf="shift.user_id">
                    {{shift.start | date:'EEEE MMMM d, y, h:mm a'}} -
                    {{shift.end| date:'EEEE MMMM d, y, h:mm a' }}
                    {{shift.firstname}} {{shift.lastname}}</span
                  >
                </label>
              </div>
            </div>
          </div>
        </div>
        <!--
				<div class='row'>
					<div class='col-12'>
					<label>Comments</label>
					<input type='text' name='comments' [(ngModel)]="comment" class="form-control" maxlength="254">
					</div>
				</div>
-->
        <div class="row my-2">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="cancel()"></cancel-button>
            <ok-button
              class="float-right mr-2"
              [disabled]="trade_type==TradeType.Trade && !selectedShift"
              (click)="save()"></ok-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
