import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { BillingService } from '../services/billing.service';
import { moment, BillingPlanType, BillingPaymentOption } from '../shared/api';

@Component({
  templateUrl: './billingChart.html',
})
export class BillingChartComponent implements OnInit {
  public chart;
  public data;
  public keys;
  public billing;
  public billing_csv;
  public BillingPlanType = BillingPlanType;
  public BillingPaymentOption = BillingPaymentOption;
  public grandTotalByType: number[] = [0, 0, 0, 0];
  public monthlyTotalByType: number[] = [0, 0, 0, 0];
  public dateKey: string;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected billingService: BillingService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.billingService.list({ success: true }).subscribe(data => {
      let cBins: any = {};
      this.data = data;
      this.keys = [];

      for (let d of data) {
        let date = moment(d.created_at);
        let k = date.format('YYYY-MM');
        if (!(k in cBins)) {
          cBins[k] = {
            d: date.startOf('month'),
            success: 0,
            failed: 0,
          };
        }
        if (d.success) {
          cBins[k].success += d.amt;
          this.grandTotalByType[d.billing_plan_type] += d.amt;
        } else {
          cBins[k].failed += d.amt;
          this.grandTotalByType[0] += d.amt;
        }
      }

      this.chart = [
        {
          name: 'Success',
          data: [],
        },
        {
          name: 'Failed',
          data: [],
        },
      ];

      for (let k in cBins) {
        this.keys.push(k);
        this.dateKey = k; // Grab the last key.
        let ticks = cBins[k].d.unix() * 1000;
        this.chart[0].data.push([ticks, cBins[k].success]);
        this.chart[1].data.push([ticks, cBins[k].failed]);
      }
      this.filter();
    });
  }

  filter() {
    let expDate = moment().add(1, 'month');
    this.monthlyTotalByType = [0, 0, 0, 0];
    this.billing = this.data.filter(e => {
      if (!e.amt) return false;
      return this.dateKey == moment(e.created_at).format('YYYY-MM');
    });
    for (let d of this.billing) {
      if (d.success) {
        this.monthlyTotalByType[d.billing_plan_type] += d.amt;
      } else {
        this.monthlyTotalByType[0] += d.amt;
      }
      d.expiring =
        d.billing_plan_exp && moment(d.billing_plan_exp).isBefore(expDate);
    }
    this.billing_csv = this.billing.map(e => {
      let billing_payment_options = '';
      if (e.billing_payment_options) {
        let opts = [];
        for (let i = 0; i < e.billing_payment_options.length; i++) {
          if (e.billing_payment_options[i]) opts.push(BillingPaymentOption[i]);
        }
        billing_payment_options = opts.join(',');
      }

      return {
        id: e.id,
        created_at: e.created_at,
        amt: e.amt,
        name: e.name,
        location_id: e.location_id,
        billing_plan_exp: e.billing_plan_exp,
        package_type: e.package_type,
        billing_plan_type: BillingPlanType[e.billing_plan_type],
        billing_payment_options: billing_payment_options,
        cc_name: e.cc_name,
        success: e.success,
        billing_paymnet_comped:
          e.billing_plan_options && e.billing_plan_options[0],
        billing_paymnet_prepay:
          e.billing_plan_options && e.billing_plan_options[1],
        billing_paymnet_ach:
          e.billing_plan_options && e.billing_plan_options[2],
        billing_paymnet_check:
          e.billing_plan_options && e.billing_plan_options[3],
      };
    });
  }
}
