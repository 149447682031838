<div class="container-fluid subnav-padding">
  <div class="title mt-2 text-center">
    {{ data?.id?'edit':'add' }} recipe block
  </div>
  <form ngNativeValidate (submit)="save()">
    <div class="row" *ngIf="data">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <label>
              Name
              <span class="float-right pr-5 mr-5">Servings</span>
            </label>
            <div class="input-group">
              <input
                [(ngModel)]="data.name"
                type="text"
                name="block_name"
                class="form-control"
                placeholder="Recipe Block Name"
                required />
              <div class="input-group-append">
                <input
                  type="number"
                  min="0"
                  [(ngModel)]="data.qty"
                  name="servings"
                  class="input-group-text mb-0 py-1 bg-white"
                  placeholder="Qty-Optional" />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <label>Details</label>
              </div>
              <div class="col-6">
                <label>Nutrition</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="card-text py-1 grey-stripe-bg">
                  <strong>Cost</strong>
                  <span class="float-right">
                    {{data.price | currency: 'USD'}}
                  </span>
                </div>
              </div>
              <div class="col-6">
                <div class="card-text py-1 grey-stripe-bg">
                  <strong>Kcal</strong>
                  <span class="float-right">
                    {{data.kcal | number: '1.0-2' }}
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="card-text py-1">
                  <strong># items</strong>
                  <span class="float-right" *ngIf="itemSkus && itemBlocks">
                    {{ (itemSkus.length + itemBlocks.length) }}
                  </span>
                </div>
              </div>
              <div class="col-6">
                <div class="card-text py-1">
                  <strong>Fat</strong>
                  <span class="card-text py-1 float-right">
                    {{data.fat | number: '1.0-2' }} g
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6">
                <div class="card-text py-1 grey-stripe-bg">
                  <strong>Protein</strong>
                  <span class="card-text py-1 float-right">
                    {{data.protein | number: '1.0-2'}} g
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6"></div>
              <div class="col-6">
                <div class="card-text py-1">
                  <strong>Carbs</strong>
                  <span class="card-text py-1 float-right">
                    {{data.carbs | number: '1.0-2'}} g
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--			<div class='card' *ngIf="(itemBlocks && itemBlocks.length>0) || (addBlockList && addBlockList.length>1)"> -->
        <div *ngIf="addBlockList && addBlockList.length>1">
          <label>Recipe Blocks</label>
          <div class="row">
            <div class="col input-group mb-0">
              <select
                class="form-control"
                style="width: 40%"
                name="rb_new"
                [(ngModel)]="newBlock.child_recipe_block_id">
                <option *ngFor="let s of addBlockList" [value]="s.id">
                  {{s.name}}
                </option>
              </select>
              <div class="input-group-append">
                <input
                  [(ngModel)]="newBlock.qty"
                  type="number"
                  name="Block_new_qty"
                  class="form-control"
                  style="width: 60%"
                  placeholder="Quantity"
                  min="0" />
                <span class="input-group-text" style="height: 85%"
                  >Servings</span
                >
                <button
                  class="btn border-0 btn-icon"
                  [disabled]="!(newBlock.qty && newBlock.child_recipe_block_id )"
                  (click)="addBlock()">
                  <span class="cdi-s-add"> </span>
                </button>
              </div>
            </div>
          </div>
          <div class="card mt-1" *ngIf="itemBlocks && itemBlocks.length>0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div
                    class="input-group"
                    *ngFor="let b of itemBlocks;let i = index">
                    <select
                      class="form-control"
                      style="width: 40%"
                      [name]="'rb_'+i"
                      (ngModelChange)="updateBlock()"
                      [(ngModel)]="b.child_recipe_block_id">
                      <option *ngFor="let o of blockList" [value]="o.id">
                        {{o.name}}
                      </option>
                    </select>
                    <div class="input-group-append">
                      <input
                        [(ngModel)]="b.qty"
                        [name]="'rb_qty_'+i"
                        type="number"
                        min="0"
                        style="width: 60%; height: 80%"
                        class="form-control mb-0"
                        (ngModelChange)="updateBlock()"
                        placeholder="Quantity"
                        required />
                      <span class="input-group-text" style="height: 80%"
                        >Servings</span
                      >
                      <button
                        class="btn bg-white"
                        type="button"
                        (click)="deleteObj(itemBlocks,b)">
                        <span class="cdi-s-trash"> </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <label>SKUs</label>
        <div class="row" *ngIf="addSkuList && addSkuList.length>0">
          <div class="col input-group">
            <select
              class="form-control"
              style="width: 40%"
              name="sku_new"
              [(ngModel)]="newSku.sku_id"
              (ngModelChange)="newUnits()">
              <option *ngFor="let s of addSkuList" [value]="s.id">
                {{s.name}}
              </option>
            </select>
            <div class="input-group-append">
              <input
                [(ngModel)]="newSku.qty_visual"
                type="number"
                name="sku_new_qty"
                (ngModelChange)="updateBlock()"
                class="form-control"
                style="width: 40%"
                placeholder="Quantity"
                min="0"
                step=".01" />
              <ng-container *ngIf="newSku.method != 1">
                <select
                  [(ngModel)]="newSku.unit"
                  style="width: 50%"
                  class="form-control"
                  name="sku_new_unit">
                  <option
                    *ngFor="let k of SkuUnitKeys"
                    (ngModelChange)="updateBlock()"
                    [value]="k">
                    {{SkuUnitNames[k]}}
                  </option>
                </select>
              </ng-container>
              <ng-container *ngIf="newSku.method == 1">
                <span class="input-group-text" style="height: 80%; width: 60%"
                  >Units</span
                >
              </ng-container>
              <button
                class="btn border-0 btn-icon"
                type="button"
                [disabled]="!(newSku.qty_visual && newSku.sku_id && newSku.unit)"
                (click)="addSku()">
                <span class="cdi-s-add"> </span>
              </button>
            </div>
          </div>
        </div>
        <div class="card mt-1" *ngIf="itemSkus && itemSkus.length>0">
          <div class="card-body">
            <div class="row">
              <!-- Listing Row -->
              <div class="col">
                <div
                  class="input-group"
                  *ngFor="let item of itemSkus;let i = index">
                  <select
                    class="form-control"
                    style="width: 40%"
                    name="sku_{{i}}"
                    [(ngModel)]="item.sku_id"
                    (ngModelChange)="setSkuItem(item)">
                    <option *ngFor="let s of skuList" [value]="s.id">
                      {{s.name}}
                    </option>
                  </select>
                  <div class="input-group-append">
                    <input
                      [(ngModel)]="item.qty_visual"
                      (ngModelChange)="updateBlock()"
                      name="sku_qty_{{i}}"
                      type="number"
                      step="any"
                      class="form-control"
                      style="width: 40%"
                      required
                      min="0" />
                    <ng-container *ngIf="item.method == 1">
                      <span
                        class="input-group-text"
                        style="height: 80%; width: 60%">
                        Units</span
                      >
                    </ng-container>
                    <ng-container *ngIf="item.method!=1">
                      <select
                        [(ngModel)]="item.unit"
                        (ngModelChange)="updateBlock()"
                        class="form-control"
                        style="width: 50%"
                        name="sku_unit_{{i}}">
                        <option *ngFor="let k of SkuUnitKeys" [value]="k">
                          {{SkuUnitNames[k]}}
                        </option>
                      </select>
                    </ng-container>
                    <button
                      class="btn bg-white"
                      type="button"
                      (click)="deleteObj(itemSkus,item)">
                      <span class="cdi-s-trash"> </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="cancel()"></cancel-button>
            <save-button class="float-right"></save-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
