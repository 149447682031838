<modal-task #modalTask [repair]="true"></modal-task>
<modal-view #modalView></modal-view>
<form ngNativeValidate *ngIf="log && serviceTime" (submit)="save()">
  <div class="row">
    <div class="col-12">
      <div class="title text-center">{{serviceTimeName}}</div>
      <div
        class="card"
        *ngIf="serviceTime.notes_general || serviceTime.notes_feedback || serviceTime.notes_employee || serviceTime.notes_repair">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-6" *ngIf="serviceTime.notes_general">
              <label>General Notes</label>
              <div
                *ngFor="let note of logNotes| filterBy: [ 'log_note_type', LogNoteType.General ] | orderBy: ['id']"
                class="body-bg mb-2 px-3">
                <delete-modal
                  [deleteObject]="note"
                  name="this note"
                  (onDelete)="deleteNote(note)"
                  ><span class="cdi-s-trash float-right pointer"></span
                ></delete-modal>
                {{ note.firstname }} {{ note.lastname }} - {{ note.created_at |
                bliDate }}<br />
                {{ note.note }}
                <div *ngIf="note.images && note.images.length>0">
                  <img
                    style="height: 5rem"
                    *ngFor="let img of note.images"
                    [src]="imageUrl(img) | safeUrl"
                    class="img-responsive m-1"
                    (click)="modalView.show(getImageView(img))" />
                </div>
              </div>
              <textarea
                [(ngModel)]="notes[LogNoteType.General]"
                name="general_notes"
                class="form-control"></textarea>
              <div class="form-group">
                <input
                  type="file"
                  name="general_file"
                  #generalFile
                  [hidden]="!notes[LogNoteType.General]"
                  (change)="handleFileChange(LogNoteType.General,$event.target.files)"
                  class="form-control-file"
                  multiple />
                <local-error
                  [error]="fileError[LogNoteType.General]"></local-error>
              </div>
            </div>
            <div class="col-md-6" *ngIf="serviceTime.notes_feedback">
              <label>Feedback, Complaints &amp; Customer Issues</label>
              <div
                *ngFor="let note of logNotes| filterBy: [ 'log_note_type', LogNoteType.Feedback] | orderBy: ['id']"
                class="body-bg mb-2 px-3">
                <delete-modal
                  [deleteObject]="note"
                  name="this note"
                  (onDelete)="deleteNote(note)"
                  ><span class="cdi-s-trash float-right pointer"></span
                ></delete-modal>
                {{ note.firstname }} {{ note.lastname }} - {{ note.created_at |
                bliDate }}<br />
                {{ note.note }}
                <div *ngIf="note.images && note.images.length>0">
                  <img
                    style="height: 5rem"
                    *ngFor="let img of note.images"
                    [src]="imageUrl(img) | safeUrl"
                    class="img-responsive m-1"
                    (click)="modalView.show(getImageView(img))" />
                </div>
              </div>
              <textarea
                [(ngModel)]="notes[LogNoteType.Feedback]"
                class="form-control"
                name="feedback"></textarea>
              <div class="form-group">
                <input
                  type="file"
                  name="feedback_file"
                  #feedbackFile
                  [hidden]="!notes[LogNoteType.Feedback]"
                  (change)="handleFileChange(LogNoteType.Feedback,$event.target.files)"
                  class="form-control-file"
                  multiple />
                <local-error
                  [error]="fileError[LogNoteType.Feedback]"></local-error>
              </div>
            </div>
            <div class="col-md-6" *ngIf="serviceTime.notes_employee">
              <label>Employee Issues</label>
              <div
                *ngFor="let note of logNotes| filterBy: [ 'log_note_type', LogNoteType.Issues] | orderBy: ['id']"
                class="body-bg mb-2 px-3">
                <delete-modal
                  [deleteObject]="note"
                  name="this note"
                  (onDelete)="deleteNote(note)"
                  ><span class="cdi-s-trash float-right pointer"></span
                ></delete-modal>
                {{ note.firstname }} {{ note.lastname }} - {{ note.created_at |
                bliDate }}<br />
                {{ note.note }}
                <div *ngIf="note.images && note.images.length>0">
                  <img
                    style="height: 5rem"
                    *ngFor="let img of note.images"
                    [src]="imageUrl(img) | safeUrl"
                    class="img-responsive m-1"
                    (click)="modalView.show(getImageView(img))" />
                </div>
              </div>
              <textarea
                [(ngModel)]="notes[LogNoteType.Issues]"
                class="form-control"
                name="issues"></textarea>
              <div class="form-group">
                <input
                  type="file"
                  #issueFile
                  [hidden]="!notes[LogNoteType.Issues]"
                  (change)="handleFileChange(LogNoteType.Issues,$event.target.files)"
                  name="employee_file"
                  class="form-control-file"
                  multiple />
                <local-error
                  [error]="fileError[LogNoteType.Issues]"></local-error>
              </div>
            </div>
            <div class="col-md-6" *ngIf="serviceTime.notes_repair">
              <label>Repair &amp; Maintenance</label>
              <div
                *ngFor="let note of logNotes| filterBy: [ 'log_note_type', LogNoteType.Repair] | orderBy: ['id']"
                class="body-bg mb-2 px-3">
                <delete-modal
                  [deleteObject]="note"
                  name="this note"
                  (onDelete)="deleteNote(note)"
                  ><span class="cdi-s-trash float-right pointer"></span
                ></delete-modal>
                {{ note.firstname }} {{ note.lastname }} - {{ note.created_at |
                bliDate}}<br />
                {{ note.note }}
                <div *ngIf="note.images && note.images.length>0">
                  <img
                    style="height: 5rem"
                    *ngFor="let img of note.images"
                    [src]="imageUrl(img) | safeUrl"
                    class="img-responsive m-1"
                    (click)="modalView.show(getImageView(img))" />
                </div>
              </div>
              <textarea
                [(ngModel)]="notes[LogNoteType.Repair]"
                class="form-control"
                name="repair"></textarea>
              <div class="form-group">
                <input
                  type="file"
                  #repairFile
                  [hidden]="!notes[LogNoteType.Repair]"
                  (change)="handleFileChange(LogNoteType.Repair,$event.target.files)"
                  name="repair_file"
                  class="form-control-file"
                  multiple />
                <local-error
                  [error]="fileError[LogNoteType.Repair]"></local-error>
                <button
                  *ngIf="!isFreemium() && isAllowed(Permission.EditTask)"
                  type="button"
                  class="btn btn-sm btn-info mt-2"
                  (click)="modalTask.show()">
                  Create a Repair Task
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-3 col-6">
              <label class="mt-2 mb-0">Sales</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.net_sales"
                  type="number"
                  min="0"
                  step=".01"
                  class="form-control override-invalid"
                  name="sales" />
              </div>
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.tax">
              <label class="mt-2 mb-0">Tax</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.tax"
                  type="number"
                  min="0"
                  step=".01"
                  class="form-control"
                  name="tax" />
              </div>
            </div>
            <!--				<div class="col-md-3  col-6" *ngIf='serviceTime.foh_labor_hours'>
						<label class='mt-2 mb-0'><span *ngIf="loc.has_houses">FOH</span>Labor Hours</label>
						<input [(ngModel)]="log.foh_labor_hours" type="number" min='0' class="form-control" name="foh_labor_hours">
					</div>
					<div class="col-md-3  col-6" *ngIf='serviceTime.foh_labor_cost'>
						<label class='mt-2 mb-0'><span *ngIf="loc.has_houses">FOH</span> Labor Cost</label>
						<div class='input-group'>
							<div class='input-group-prepend'>
								<span class="input-group-text">$</span>
							</div>
							<input [(ngModel)]="log.foh_labor_cost" type="number" min='0' step='.01' class="form-control" name="foh_labor_cost">
						</div>
					</div>
					<div class="col-md-3 col-6" *ngIf='serviceTime.boh_labor_hours'>
						<label class='mt-2 mb-0'>BOH Labor Hours</label>
						<input [(ngModel)]="log.boh_labor_hours" type="number" min='0' class="form-control" name="boh_labor_hours">
					</div>
					<div class="col-md-3 col-6" *ngIf='serviceTime.boh_labor_cost'>
						<label class='mt-2 mb-0'>BOH Labor Cost</label>
						<div class='input-group'>
							<div class='input-group-prepend'>
								<span class="input-group-text">$</span>
							</div>
							<input [(ngModel)]="log.boh_labor_cost" type="number" min='0' step='.01' class="form-control" name="boh_labor_cost">
						</div>
					</div>	-->
            <div class="col-md-3 col-6" *ngIf="serviceTime.guest_count">
              <label class="mt-2 mb-0">Guest Count</label>
              <input
                [(ngModel)]="log.guest_count"
                type="number"
                min="0"
                step="0.01"
                class="form-control"
                name="guest_count" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.comps">
              <label class="mt-2 mb-0">Comps/House Account</label>
              <input
                [(ngModel)]="log.comps"
                type="number"
                min="0"
                class="form-control"
                step="0.01"
                name="comps" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.discounts">
              <label class="mt-2 mb-0">Discounts</label>
              <input
                [(ngModel)]="log.discounts"
                type="number"
                min="0"
                class="form-control"
                step="0.01"
                name="discounts" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.employee_discounts">
              <label class="mt-2 mb-0">Employee Discounts</label>
              <input
                [(ngModel)]="log.employee_discounts"
                type="number"
                min="0"
                step="0.01"
                class="form-control"
                name="employee_discounts" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.cc_batch_no">
              <label class="mt-2 mb-0">Credit Card Batch #</label>
              <input
                [(ngModel)]="log.cc_batch_no"
                type="number"
                min="0"
                step="0.01"
                class="form-control"
                name="cc_batch_no" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.pay_outs">
              <label class="mt-2 mb-0">Payouts</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.pay_outs"
                  type="number"
                  min="0"
                  step=".01"
                  class="form-control"
                  name="pay_outs" />
              </div>
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.cash">
              <label class="mt-2 mb-0">Cash Collected</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.cash"
                  type="number"
                  min="0"
                  step=".01"
                  class="form-control"
                  name="cash" />
              </div>
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.expected_cash">
              <label class="mt-2 mb-0">Expected Cash Drop</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.expected_cash"
                  type="number"
                  min="0"
                  step=".01"
                  class="form-control"
                  name="expected_cash" />
              </div>
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.gift_redeemed">
              <label class="mt-2 mb-0">Gift Cards Redeemed</label>
              <input
                [(ngModel)]="log.gift_redeemed"
                type="number"
                min="0"
                step="0.01"
                class="form-control"
                name="gift_redeemed" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.gift_sold">
              <label class="mt-2 mb-0">Gift Cards Sold</label>
              <input
                [(ngModel)]="log.gift_sold"
                type="number"
                min="0"
                step="0.01"
                class="form-control"
                name="gift_sold" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.cc_tips_collected">
              <label class="mt-2 mb-0">CC Tips Collected</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.cc_tips_collected"
                  type="number"
                  min="0"
                  step="0.01"
                  class="form-control"
                  name="cc_tips" />
              </div>
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.actual_bank">
              <label class="mt-2 mb-0">Actual Bank Deposit</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.actual_bank"
                  type="number"
                  min="0"
                  step=".01"
                  class="form-control"
                  name="actual_bank" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--	<div *ngIf="log.id">Last Log Update: {{ log.updated_at | bliDate }}</div>-->

      <div
        class="card"
        *ngIf="(serviceTime.foh_labor_hours || serviceTime.boh_labor_hours || serviceTime.foh_labor_cost || serviceTime.boh_labor_cost)">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-3 col-6" *ngIf="serviceTime.foh_labor_hours">
              <label class="mt-2 mb-0"
                ><span *ngIf="loc.has_houses">FOH</span> Labor Hours</label
              >
              <input
                [(ngModel)]="log.foh_labor_hours"
                type="text"
                class="form-control"
                name="foh_labor_hours" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.foh_labor_cost">
              <label class="mt-2 mb-0"
                ><span *ngIf="loc.has_houses">FOH</span> Labor Cost</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.foh_labor_cost"
                  type="number"
                  min="0"
                  step=".01"
                  class="form-control"
                  name="foh_labor_cost" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3 col-6" *ngIf="serviceTime.boh_labor_hours">
              <label class="mt-2 mb-0">BOH Labor Hours</label>
              <input
                [(ngModel)]="log.boh_labor_hours"
                type="text"
                class="form-control"
                name="boh_labor_hours" />
            </div>
            <div class="col-md-3 col-6" *ngIf="serviceTime.boh_labor_cost">
              <label class="mt-2 mb-0">BOH Labor Cost</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="log.boh_labor_cost"
                  type="number"
                  min="0"
                  step=".01"
                  class="form-control"
                  name="boh_labor_cost" />
              </div>
            </div>
          </div>
          <div *ngIf="loc.labor_type==LocationLaborType.Timeclock">
            Your labor reporting method was used to calculate the following
            labor automatically. Anything entered into this log will be added to
            the figures below.
            <div *ngIf="timeCards && loc.has_houses">
              Time Cards FOH: {{ timeCards.foh_cost | currency: 'USD' }}<br />
              Time Cards BOH: {{ timeCards.boh_cost | currency: 'USD' }}
            </div>
            <div *ngIf="timeCards && !loc.has_houses">
              Time Cards: {{ timeCards.foh_cost | currency: 'USD' }}
            </div>
          </div>
        </div>
      </div>
      <!--		<div class='card' *ngIf='!loc.has_houses && (serviceTime.foh_labor_hours || serviceTime.foh_labor_cost)'>
			<div class='card-body'>
				<div class='form-row'>
					<div class="col-md-3 col-6" *ngIf='serviceTime.foh_labor_hours' >
						<label class='mt-2 mb-0'>Labor Hours</label>
						<input [(ngModel)]="log.foh_labor_hours" type="text" class="form-control" name="foh_labor_hours">
					</div>
					<div class="col-md-3 col-6"  *ngIf='serviceTime.foh_labor_cost'>
						<label class='mt-2 mb-0'>Labor Cost</label>
						<input [(ngModel)]="log.foh_labor_cost" type="text" class="form-control" name="foh_labor_cost">
					</div>
				</div>
			</div>
		</div> -->
      <!--  This is interesting, one because I am not using BOH/FOH, and there are different ways of reporting
		so we will have to fill that in correctly -->
      <div
        class="card"
        *ngIf="customs && customs.length>0 && log.custom_fields">
        <div class="card-body">
          <div class="form-row">
            <div class="col-md-3 col-6" *ngFor="let c of customs">
              <label class="mt-2 mb-0">{{c.name}}</label>
              <input
                [(ngModel)]="log.custom_fields[c.name]"
                type="number"
                min="0"
                step="any"
                class="form-control"
                [name]="'custom_'+c.id" />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="log.id" class="p-1 d-inline">
        Last Log Update: {{ log.updated_at | bliDate }}
      </div>
    </div>
  </div>
  <div class="row mt-2" *ngIf="saveMessage">
    <div class="col-12">
      <alert type="success" *ngIf="saveMessage">
        <strong>Save</strong> - {{saveMessage}}
      </alert>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12">
      <!--		<cancel-button class='float-right' (click)="cancel()"></cancel-button> -->
      <save-button *ngIf="!saveMessage" class="float-right"></save-button>
    </div>
  </div>
</form>
