import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from '../services/account.service';
import { baseRole, IApiLocation } from '../shared/baseRole';
import { UserService, IUser } from '../services/user.service';
import { DocService, IDoc } from '../services/doc.service';
import { DocUserMapService, IDocUserMap } from '../services/docUserMap.service';
import { youTubeUrlToId, DocMimeTypes } from '../shared/api';

@Component({
  templateUrl: './upload.html',
})
export class UploadComponent extends baseRole implements OnInit {
  public data: any;
  public users: any[]; // all users
  public docUsers: IDocUserMap[]; //the assigned maps
  public loc: IApiLocation;
  public file = true;
  public fileData;
  public uploading = false;
  public fileError: string = null;
  public youTubeError: string = null;

  @ViewChild('fileInput')
  fileInput: ElementRef;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected docService: DocService,
    protected docGroupService: DocService,
    protected docUserMapService: DocUserMapService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['id'];
      let groupId = params['groupId'];
      this.loc = this.getLocation();
      if (id) {
        this.docService.get(id).subscribe(data => {
          this.data = data;
          //				console.log(this.data);
          if (this.data.video) this.file = false;
        });
      } else {
        this.data = {
          customStaff: false,
          doc_group_id: groupId,
          source_name: '',
        };
      }
      this.userService.list().subscribe(data => {
        this.users = data;
        if (id) {
          this.docUserMapService.list({ doc_id: id }).subscribe(data => {
            this.docUsers = data;
            let map: any = {};
            for (let d of this.docUsers) {
              map[d.user_id] = d.user_id;
            }
            for (let u of this.users) {
              u._selected = u.id in map;
            }
          });
        }
      });
    });
  }

  handleFileChange(files) {
    if (!files || files.length == 0) this.fileData = null;
    else this.fileData = files[0];
    let mimeType = this.fileData.type;

    //console.log(mimeType);
    if (DocMimeTypes.indexOf(mimeType) < 0) {
      this.fileError = 'Upload must be an image or pdf file';
      this.fileData = null;
      this.fileInput.nativeElement.value = '';
      setTimeout(() => {
        this.fileError = null;
      }, 5000);
    }
  }
  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  save() {
    this.uploading = true;
    this.data.user_ids = [];
    let users = this.users.filter(e => {
      return e._selected;
    });
    for (let u of users) {
      this.data.user_ids.push(u.id);
    }
    this.docService.update(this.data).subscribe(data => {
      this.data.id = data.id;
      if (this.fileData) {
        this.docService.upload(this.data, this.fileData).subscribe(data => {
          this.router.navigate(['/docs', this.data.doc_group_id]);
        });
      } else {
        this.router.navigate(['/docs', this.data.doc_group_id]);
      }
    });
  }

  youTubeUrlToId(): string {
    this.youTubeError = null;
    if (this.data.video) {
      let res = youTubeUrlToId(this.data.video);
      if (!res) {
        this.youTubeError =
          'Unable to find youtube video id in url. Please check your url.';
      }
    }
    return null;
  }
}
