<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Message Reply"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" *ngIf="message">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Message
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <form ngNativeValidate (submit)="save()">
        <div class="modal-body">
          <div class="card">
            <div class="card-body">
              <div class="my-3">
                <b> From: </b>{{origMessage.firstname}} {{origMessage.lastname}}
                - {{origMessage.created_at | bliDate }}
              </div>
              <div class="my-3">
                <b> Title: </b>
                <span>{{message.title}}</span>
              </div>
              <div class="my-3">
                <b>To: </b>
                <span *ngFor="let r of origMessage.recipients; let l = last">
                  {{ r.firstname }}&nbsp;{{ r.lastname}}<span *ngIf="!l"
                    >,
                  </span>
                </span>
              </div>
              <div class="my-3">
                <b> Message:</b>
                {{origMessage.body}}
              </div>

              <div *ngIf="origMessage.images && origMessage.images.length>0">
                <span
                  *ngFor="let i of origMessage.images"
                  (click)="modalView.show(getImageView(i))">
                  <img
                    class="img-responsive m-1"
                    style="height: 5rem"
                    *ngIf="!i.mime_type.startsWith('image')"
                    src="/images/default/default-pdf.svg" />
                  <img
                    [src]="imageUrl(i) | safeUrl"
                    class="img-responsive m-1"
                    style="height: 5rem"
                    *ngIf="i.mime_type.startsWith('image')" />
                </span>
              </div>
            </div>

            <div
              class="card-body"
              *ngIf="origMessage.comments && origMessage.comments.length>0">
              <div *ngFor="let c of origMessage.comments">
                {{ c.firstname }} {{ c.lastname }} @ {{ c.created_at | bliDate}}
                - {{ c.comment }}
              </div>
            </div>
          </div>

          <div *ngIf="reply">
            <div class="card">
              <div class="card-body">
                <textarea
                  [(ngModel)]="message.body"
                  class="form-control"
                  name="message"
                  placeholder="Response"
                  required></textarea>
                <input
                  type="file"
                  #fileInput
                  class="form-control-file"
                  name="message_file"
                  multiple
                  (change)="handleFileChange($event.target.files)" />
                <local-error [error]="fileError"></local-error>
                <small>Attachments will not be included in text messages</small>
              </div>
            </div>

            <div class="card" *ngIf="message">
              <div class="card-body">
                <b>Select additional message types:</b>
                <div class="flex-200 p-1">
                  <input-toggle name="send_email" [(ngModel)]="message.is_email"
                    >Send Email</input-toggle
                  >
                </div>
                <div class="flex-200 p-1">
                  <input-toggle name="send_text" [(ngModel)]="message.is_text"
                    >Send Text</input-toggle
                  >
                </div>

                <div class="mt-1">
                  <b>Select Recipients:</b>
                  <div class="flex-200 p-1">
                    <div class="btn-group-toggle">
                      <label class="btn btn-radio" [class.active]="!replyAll">
                        <input
                          type="radio"
                          name="to"
                          [value]="false"
                          [(ngModel)]="replyAll"
                          (ngModelChange)="replyAll" />Reply to Sender
                      </label>
                    </div>
                  </div>
                  <div class="flex-200 p-1">
                    <div class="btn-group-toggle">
                      <label class="btn btn-radio" [class.active]="replyAll">
                        <input
                          type="radio"
                          name="to"
                          [value]="true"
                          [(ngModel)]="replyAll"
                          (ngModelChange)="replyAll" />Reply to All
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <global-error [error]="error"></global-error>
          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <button
                *ngIf="reply"
                type="submit"
                class="btn btn-primary float-right mr-2">
                Send
              </button>
              <button
                *ngIf="!reply"
                type="button"
                (click)="reply=true"
                class="btn btn-primary float-right mr-2">
                Reply
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<modal-view #modalView></modal-view>
