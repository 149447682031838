<div class="card" *ngIf="loc && isIntegrationEnabled()">
  <div class="card-body">
    <div class="row" *ngIf="step==1">
      <div class="col-12">
        <div class="title text-center">POS Integration</div>
        <div class="py-2 text-center">
          CheddrSuite connects to most major POS systems.
        </div>

        <div class="row mb-2 justify-content-md-center">
          <div class="col-md-4 col-4">
            <select
              name="sales_integration_type"
              [(ngModel)]="loc.sales_integration_type"
              class="form-control">
              <option
                *ngFor="let t of SalesIntegrationKeys| orderBy: 'name'"
                [value]="t.id">
                {{t.name}}
              </option>
            </select>

            <!-- Square -->
            <div
              class="w-100 py-2 text-center"
              *ngIf="loc.sales_integration_type==SalesIntegrationType.Square">
              <div
                class="py-2"
                *ngIf="loc.currentIntegrationType==SalesIntegrationType.Square">
                <div class="row" *ngIf="loc.square_locations">
                  <div class="col-12">
                    <p>
                      Select which location from your Square POS you'd like to
                      integrate with
                    </p>
                    <local-error [error]="saveError"></local-error>
                    <select
                      [(ngModel)]="loc.sales_integration_location_id"
                      name="loc_square_locations"
                      class="form-control"
                      required>
                      <option [disabled]="true" [selected]="true">
                        Select a Square location
                      </option>
                      <option
                        *ngFor="let location of loc.square_locations"
                        [value]="location.id">
                        {{location.name}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                class="py-2"
                *ngIf="loc.currentIntegrationType==SalesIntegrationType.Square">
                Connected
                <strong *ngIf="loc.sales_integration_location_name">
                  to {{loc.sales_integration_location_name}}
                </strong>
              </div>
              <div
                class="py-2"
                *ngIf="loc.currentIntegrationType!=SalesIntegrationType.Square">
                <a class="mx-auto btn btn-primary" [href]="squareUrl|safeUrl"
                  >Connect to Square</a
                >
              </div>
            </div>

            <!-- Lightspeed -->
            <div
              class="w-100 py-2 text-center"
              *ngIf="loc.sales_integration_type==SalesIntegrationType.Lightspeed">
              <a class="mx-auto btn btn-primary" [href]="lightspeedUrl|safeUrl"
                >Connect to Lightspeed</a
              >
              <div
                class="py-2"
                *ngIf="loc.currentIntegrationType==SalesIntegrationType.Lightspeed">
                Connected
              </div>
              <div
                class="py-2"
                *ngIf="loc.currentIntegrationType!=SalesIntegrationType.Lightspeed">
                Not Connected
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <cancel-button class="float-right" routerLink="/settings/account">
        </cancel-button>
        <save-button class="float-right" (click)="saveIntegrationType()">
        </save-button>
      </div>
    </div>

    <integration-options
      *ngIf="step==2"
      [location_id]="loc.id"
      [initialSetup]="true"
      (onComplete)="integrationDone($event)">
    </integration-options>

    <div
      *ngIf="step==3"
      [innerHTML]="SalesIntegrationFeatures[loc.sales_integration_type].confirmation_data | safeHTML"></div>
  </div>
</div>
