<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          {{ data?.id?'Edit':'Add' }} Menu Item
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" *ngIf="data">
        <form ngNativeValidate (submit)="save()">
          <div class="card">
            <div class="card-body">
              <label> Item Name</label>
              <input
                [(ngModel)]="data.name"
                name="item_name"
                type="text"
                maxlength="50"
                class="form-control override-invalid"
                placeholder="Item Name"
                required />
              <label> Item Picture</label>
              <input
                type="file"
                #fileInput
                (change)="handleFileChange($event.target.files)"
                class="form-control-file mb-2"
                id="menuItemlFile" />
              Your picture may not show immediately, the file upload is not
              instantaneous.
              <label class="mt-3"> Category</label>
              <select
                [ngModel]="data.item_category_id ||''"
                (ngModelChange)="data.item_category_id =$event"
                name="item_category"
                class="form-control-select override-invalid mb-2"
                required>
                <option *ngFor="let c of categories" [ngValue]="c.id">
                  {{c.name}}
                </option>
              </select>

              <label> Variation Names &amp; Prices</label>
              <div class="row" *ngFor="let v of variations">
                <div class="col-12">
                  <div class="input-group mb-3">
                    <input
                      [(ngModel)]="v.name"
                      [ngModelOptions]="{standalone: true}"
                      type="text"
                      maxlength="50"
                      class="form-control-select d-inline-block"
                      placeholder="Variation Name(Eg.12oz, Regular)"
                      required />
                    <div class="input-group-append">
                      <span class="input-group-text">$</span>
                      <input
                        [(ngModel)]="v.price"
                        [ngModelOptions]="{standalone: true}"
                        type="number"
                        class="form-control-select mb-0"
                        placeholder="Price"
                        step="0.01"
                        required />
                    </div>
                    <div class="input-group-append">
                      <span
                        class="cdi cdi-m-trash pointer"
                        (click)="deleteVariation(v)">
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="newVariation">
                <div class="col-12">
                  <div class="input-group mb-3">
                    <input
                      [(ngModel)]="newVariation.name"
                      name="new_name"
                      maxlength="50"
                      type="text"
                      class="form-control-select"
                      placeholder="Variation (Eg.12oz)" />
                    <div class="input-group-append">
                      <span class="input-group-text">$</span>
                      <input
                        [(ngModel)]="newVariation.price"
                        name="new_price"
                        type="number"
                        class="form-control-select"
                        step="0.01"
                        placeholder="Price" />
                    </div>
                    <div class="input-group-append">
                      <span
                        class="cdi cdi-m-add pointer"
                        (click)="addVariation()">
                      </span>
                      <!--<span class='cdi cdi-m-trash' (click)="deleteVariation(v)"> </span>-->
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <local-error [error]="newError"></local-error>
                </div>
              </div>

              <div class="row mt-2" *ngIf="saveMessage">
                <div class="col-12">
                  <alert type="success">
                    <strong>{{saveMessage}}</strong>
                  </alert>
                </div>
              </div>

              <div class="row mt-2" *ngIf="!saveMessage">
                <div class="col-12">
                  <cancel-button
                    class="float-right"
                    (click)="cancel()"></cancel-button>
                  <save-button class="float-right"></save-button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
