import {
  Component,
  Input,
  Output,
  ElementRef,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { AccountService } from '../services/account.service';
import { BillingPlanService } from '../services/billingPlan.service';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'freemium',
  templateUrl: './freemium.html',
})
export class FreemiumComponent
  extends baseRole
  implements OnInit, AfterViewInit
{
  public section: string;
  public upgradeText = 'Upgrade Now!';
  public plan: any;

  constructor(
    protected accountService: AccountService,
    protected billingPlanService: BillingPlanService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.billingPlanService.getForLocation().subscribe(data => {
      this.plan = data;
    });
  }

  ngAfterViewInit() {
    if (!this.section) return;
    const element = document.getElementById(this.section);
    if (element) {
      window.scroll({
        top: element.scrollTop,
        behavior: 'smooth',
      });
    }
  }
}
