import {
  Directive,
  HostListener,
  Renderer2,
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import {
  NgModel,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe, Location } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { MediaService } from '../services/media.service';
import { SalesGoalService } from '../services/salesGoal.service';
import { PublishLogService } from '../services/publishLog.service';
import { formatDateOnly } from '../services/base.service';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { noop, IMedia, DateMenuType, moment } from './api';

@Component({
  selector: 'date-menu',
  templateUrl: 'date-menu.html',
})
export class DateMenuComponent implements OnInit, OnDestroy {
  public date: Date;
  public dates: Date[] = [];
  public goals: string[];
  public width = 108;
  public itemWidth: number;
  public sub: any;
  public marginLeft = '0px';
  public DateMenuType = DateMenuType;
  public pubSub;
  public pubSkipFirst = true;
  public goalSub;
  public goalSkipFirst = true;
  public goalStartDate;
  public disabledArray: number[];
  @ViewChild('dp')
  public dateMenu;

  @Output() dateChange: EventEmitter<Date>;
  @Input() repeat: DateMenuType = DateMenuType.Daily;
  @Input() needsGoals: boolean = false;

  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.resizeContainer();
  }

  constructor(
    private accountService: AccountService,
    private salesGoalService: SalesGoalService,
    private publishLogService: PublishLogService,
    private elementRef: ElementRef
  ) {
    this.dateChange = new EventEmitter();
  }

  toggle() {
    this.dateMenu.show();
  }
  ngOnInit() {
    // This will help block a possible infinite loop.
    let firstFire = false;
    this.sub = this.accountService
      .getCurrentDateUpdateObservable(this.repeat)
      .subscribe(data => {
        if (!data) {
          firstFire = true;
          data = new Date();
          data.setHours(0);
          data.setMinutes(0);
          data.setSeconds(0);
          data.setMilliseconds(0);
        }
        if (!this.date) {
          this.date = data;
        }
        if (this.repeat == DateMenuType.Weekly) {
          let dayOfWeek = this.accountService.getLocation().week_start || 0;
          this.disabledArray = [];
          for (let i = 0; i < 7; i++) {
            if (i == dayOfWeek) continue;
            this.disabledArray.push(i);
          }
          let offset = this.date.getDay();
          if (firstFire) {
            this.date.setDate(this.date.getDate() - (offset - dayOfWeek));
            firstFire = false;
            this.accountService.notifyCurrentDateUpdate(
              this.repeat,
              new Date(this.date.getTime())
            );
            return;
          }
        } else if (firstFire) {
          firstFire = false;
          this.accountService.notifyCurrentDateUpdate(
            this.repeat,
            new Date(this.date.getTime())
          );
          return;
        }
        this.updateDateArray();
      });
    this.resizeContainer();
    this.pubSub = this.publishLogService.getUpdateObservable().subscribe(() => {
      if (this.needsGoals && !this.pubSkipFirst) {
        this.goalStartDate = null;
        this.updateDateArray();
      }
      this.pubSkipFirst = false;
    });
    this.goalSub = this.salesGoalService.getUpdateObservable().subscribe(() => {
      if (this.needsGoals && !this.goalSkipFirst) {
        this.goalStartDate = null;
        this.updateDateArray();
      }
      this.goalSkipFirst = false;
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.goalSub) this.goalSub.unsubscribe();
    if (this.pubSub) this.pubSub.unsubscribe();
  }
  resizeContainer() {
    this.itemWidth = this.elementRef.nativeElement.clientWidth;
    this.updateDateArray();
  }

  updateDateArray() {
    this.goals = null;
    let size = Math.floor(this.itemWidth / (2 * this.width));
    // The -13.33 is for the padding on the row.
    this.marginLeft =
      -((1 + 2 * size) * this.width - this.itemWidth) / 2 + 'px';
    /*console.log('Size: '+size);
		console.log(this.repeat);*/
    let startDate = new Date(this.date);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    switch (this.repeat) {
      /*case RepeatOptions.Monthly:
				startDate.setDate(0);
				startDate.setMonth(startDate.getMonth()-size*7);
				break;*/
      case DateMenuType.Weekly:
        startDate.setDate(startDate.getDate() - size * 7);
        break;
      default:
        startDate.setDate(startDate.getDate() - size);
    }
    this.dates = [];
    let origStartDate = new Date(startDate);
    for (let i = -size; i <= size; i++) {
      this.dates.push(startDate);
      startDate = new Date(startDate);
      switch (this.repeat) {
        case DateMenuType.Weekly:
          startDate.setDate(startDate.getDate() + 7);
          break;
        default:
          startDate.setDate(startDate.getDate() + 1);
      }
    }
    //console.log(this.dates);
    if (
      this.needsGoals &&
      this.dates.length > 0 &&
      (!this.goalStartDate || origStartDate != this.goalStartDate)
    ) {
      this.goalStartDate = origStartDate;
      this.publishLogService
        .listForDateMenu(
          formatDateOnly(origStartDate),
          formatDateOnly(startDate)
        )
        .subscribe(data => {
          /*console.log('Publish Data');
				console.log(data);*/
          this.goals = [];
          let now = new Date();
          let map: any = {};
          for (let d of data) {
            map[d.date] = d;
          }
          //console.log(map);
          let s = new Date(origStartDate);
          for (let i = -size; i <= size; i++) {
            let key = moment(s).format('YYYY-MM-DD');
            //console.log(key);
            if (key in map) {
              if (map[key].is_published) this.goals.push('success');
              else if (map[key].is_budget) this.goals.push('warning');
              else this.goals.push('danger');
            }
            //else if(s<now) {
            //this.goals.push('danger');
            /*}
					else {
						this.goals.push('warning');
					}*/
            s = new Date(s);
            s.setDate(s.getDate() + 7);
          }
          //console.log(this.goals);
        });
    }
    /*console.log(this.date);
		console.log(this.dates);*/
  }
  changeDate(date: Date) {
    // Block a possible endless loop.
    if (date.getTime() == this.date.getTime()) return;

    this.date = date;
    this.accountService.notifyCurrentDateUpdate(
      this.repeat,
      new Date(date.getTime())
    );
    this.dateChange.emit(this.date);
    this.updateDateArray();
  }
}

// BUTTON COMPONENTS
@Component({
  selector: 'add-button',
  template: '<i class="far fa-plus text-primary"></i><ng-content></ng-content>',
})
export class AddButtonComponent {}

@Component({
  selector: 'back-button',
  template: '<a class="text-primary pointer">Back</a>',
})
export class BackButtonComponent {}

@Component({
  selector: 'clear-button',
  template:
    '<span class="btn btn-secondary btn-sm mx-2" style="width: auto">Clear All</span>',
})
export class ClearButtonComponent {}

@Component({
  selector: 'done-button',
  template: '<button class="btn btn-primary">Done</button>',
})
export class DoneButtonComponent {}

@Component({
  selector: 'accordion-button',
  template:
    '<div class="col-12 height-transition" [class.height-transition-hidden]="isCollapsed"><ng-content></ng-content></div><div class="col-12 text-center accordion-button"><a class="border-round cdi-m-soft-arrow" [class.open]="!isCollapsed" (click)="toggleCollapsed()"></a></div>',
})
export class AccordionButtonComponent {
  public isCollapsed = true;
  @Output() onChange = new EventEmitter<boolean>();

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    this.onChange.emit(!this.isCollapsed);
  }
}

@Component({
  selector: 'auto-back-button',
  template: '<span (click)="back()" class="text-primary pointer">Back</span>',
})
export class AutoBackButtonComponent {
  constructor(protected _location: Location) {}

  back() {
    this._location.back();
  }
}

@Component({
  selector: 'cancel-button',
  template:
    '<button type="button" class="btn btn-secondary float-right mx-2">Cancel</button>',
})
export class CancelButtonComponent {}

@Component({
  selector: 'card-sub-header',
  host: {
    '[class.card-subtitle]': 'true',
    '[class.text-muted]': 'true',
  },
  template: '<ng-content></ng-content>',
})
export class CardSubHeaderComponent {}

@Component({
  selector: 'input-datetime',
  template: `<input
    type="datetime-local"
    [required]="required"
    class="form-control"
    [value]="_date"
    (change)="onDateChange($event.target.value)" />`,
})
export class DatetimeComponent {
  public _date: string;
  @Input() set date(d: Date) {
    /*console.log('Updating date');
		console.log(d);*/
    if (!d) {
      this._date = null;
      return;
    }
    this._date = this.toDateString(d);
  }
  @Input() required: boolean;

  @Output() dateChange: EventEmitter<Date>;
  constructor() {
    this.date = new Date();
    this.dateChange = new EventEmitter();
  }

  public toDateString(date: any): string {
    if (!date) return null;
    if (!(date instanceof Date)) {
      if (typeof date == 'string') {
        //date = date.replace(/Z/,'');
      }
      try {
        date = new Date(date);
      } catch (err) {
        return null;
      } // Bail out. Something went bad.
    }
    return (
      date.getFullYear().toString() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      'T' +
      date.toTimeString().slice(0, 5)
    );
  }

  public parseDateString(date: string): Date {
    if (!date || date.length == 0) return null;
    date = date.replace('T', '-');
    var parts = date.split('-');
    var timeParts = parts[3].split(':');

    // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(
      +parts[0],
      +parts[1] - 1,
      +parts[2],
      +timeParts[0],
      +timeParts[1]
    );
  }

  public onDateChange(value: string): void {
    if (value != this._date) {
      var parsedDate = this.parseDateString(value);

      if (parsedDate == null) {
        this._date = null;
        this.dateChange.emit(parsedDate);
      }
      // check if date is valid first
      else if (parsedDate.getTime() != NaN) {
        this._date = value;
        this.dateChange.emit(parsedDate);
      }
    }
  }
}

export const CUSTOM_INPUTTOGGLE_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputToggleComponent),
  multi: true,
};
@Component({
  selector: 'input-toggle',
  template: `<button
    [disabled]="disabled"
    type="button"
    class="toggle-btn align-middle"
    [class.active]="_value"
    (click)="value = !value">
    <div
      [class.cdi-s-checkbox]="!disabled"
      [class.cdi-s-check]="disabled"
      class="float-left"
      [class.active]="_value"
      [class.nostate]="disabled"></div>
    <div class="input-toggle-text"><ng-content></ng-content></div>
  </button>`,
  providers: [CUSTOM_INPUTTOGGLE_CONTROL_VALUE_ACCESSOR],
})
export class InputToggleComponent implements ControlValueAccessor {
  public _value: boolean = false;

  @Output() onDone: EventEmitter<any> = new EventEmitter();
  @Input() disabled = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): boolean {
    return this._value;
  }

  //set accessor including call the onchange callback
  set value(v: boolean) {
    let needsUpdate = false;
    if (<any>v == null || <any>v == 0 || typeof v == 'undefined') {
      v = false;
      needsUpdate = true;
    }
    if (v !== this._value) {
      this._value = v;
      needsUpdate = true;
    }
    if (needsUpdate) this.onChangeCallback(this._value);
  }

  //Set touched on blur
  onBlur() {
    this.onDone.emit();
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(v: any) {
    if (v == null || v == 0) {
      v = false;
    }
    if (v !== this._value) {
      this._value = v;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}

export const CUSTOM_YESNOSELECT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => YesNoSelectComponent),
  multi: true,
};

// Problem with the click detection sometimes. using clicked instead.
export const CUSTOM_YESNOBUTTONS_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => YesNoButtonComponent),
  multi: true,
};
@Component({
  selector: 'input-yes-no-button',
  template: `
    <div class="row">
      <div class="col-6 btn-group-toggle">
        <label
          class="btn btn-radio"
          [class.disabled]="disabled"
          (click)="clicked(true)"
          [class.active]="_value">
          <input
            type="radio"
            [disabled]="disabled"
            name="btn_group"
            [value]="true"
            [(ngModel)]="value" />
          Yes
        </label>
      </div>
      <div class="col-6 btn-group-toggle">
        <label
          class="btn btn-radio"
          [class.disabled]="disabled"
          (click)="clicked(false)"
          [class.active]="!_value">
          <input
            type="radio"
            [disabled]="disabled"
            name="btn_group"
            [value]="false"
            [(ngModel)]="value" />
          No
        </label>
      </div>
    </div>
  `,
  providers: [CUSTOM_YESNOBUTTONS_CONTROL_VALUE_ACCESSOR],
})
export class YesNoButtonComponent implements ControlValueAccessor {
  public _value: boolean = false;

  @Output() onDone: EventEmitter<any> = new EventEmitter();
  @Input() disabled = false;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): boolean {
    return this._value;
  }

  clicked(v) {
    if (!this.disabled) {
      this.value = v;
    }
  }

  //set accessor including call the onchange callback
  set value(v: boolean) {
    let needsUpdate = false;
    if (<any>v == null || <any>v == 0 || typeof v == 'undefined') {
      v = false;
      needsUpdate = true;
    }
    if (v !== this._value) {
      this._value = v;
      needsUpdate = true;
    }
    if (needsUpdate) this.onChangeCallback(this._value);
  }

  //Set touched on blur
  onBlur() {
    this.onDone.emit();
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(v: any) {
    if (v == null || v == 0) {
      v = false;
    }
    if (v !== this._value) {
      this._value = v;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}

@Component({
  selector: 'input-yes-no',
  template: `<select
    name="yes_no"
    class="form-control"
    [(ngModel)]="value"
    (blur)="onBlur()"
    [required]="required">
    <option [ngValue]="true" [attr.selected]="value">
      {{ yesNoArray[1] }}
    </option>
    <option [ngValue]="false" [attr.selected]="value === false">
      {{ yesNoArray[0] }}
    </option>
  </select>`,
  providers: [CUSTOM_YESNOSELECT_CONTROL_VALUE_ACCESSOR],
})
export class YesNoSelectComponent implements ControlValueAccessor {
  public _value: boolean = false;

  @Output() onDone: EventEmitter<any> = new EventEmitter();
  @Input() yesNoArray = ['No', 'Yes'];
  @Input() required: boolean;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): boolean {
    return this._value;
  }

  //set accessor including call the onchange callback
  set value(v: boolean) {
    let needsUpdate = false;
    if (<any>v == null || <any>v == 0) {
      v = false;
      needsUpdate = true;
    }
    if (v !== this._value) {
      this._value = v;
      needsUpdate = true;
    }
    if (needsUpdate) this.onChangeCallback(this._value);
  }

  //Set touched on blur
  onBlur() {
    this.onDone.emit();
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(v: any) {
    if (v == null || v == 0) {
      v = false;
      this.onChangeCallback(this._value);
    }
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(this._value);
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}

/**
 * The accessor for writing a value and listening to changes on a date input element
 *
 *  ### Example
 *  `<input type="date" name="myBirthday" ngModel useValueAsDate>`
 */
@Directive({ selector: '[debounce]' })
export class DebounceDirective implements OnInit {
  @Input() delay: number = 700;
  @Output() result: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef, private model: NgModel) {}

  ngOnInit(): void {
    const eventStream = fromEvent(this.elementRef.nativeElement, 'keyup')
      .pipe(map(() => this.model.value))
      .pipe(debounceTime(this.delay));

    eventStream.subscribe(input => {
      this.result.emit(input);
    });
  }
}

@Directive({
  selector: '[sort]',
})
export class ThSortDirective implements OnInit {
  public _value: string;
  public _origData;
  @Input() fieldname: string;
  @Input() get orderBy() {
    return this._value;
  }
  @Output() orderByChange = new EventEmitter();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    //		console.log('Init');
    this._origData = this.elementRef.nativeElement.parentNode.innerHTML;
    let arrows = this.renderer.createElement('i');
    this.renderer.appendChild(this.elementRef.nativeElement, arrows);
    this.renderer.addClass(
      this.elementRef.nativeElement.children[0],
      'th-sort'
    );
    this.renderer.addClass(
      this.elementRef.nativeElement.children[0],
      'cdi-s-reorder'
    );
  }
  @HostListener('click', ['$event']) onClick($event) {
    if (this.orderBy == this.fieldname) this.orderBy = '-' + this.fieldname;
    else if (this.orderBy == '-' + this.fieldname)
      this.orderBy = this.fieldname;
    else this.orderBy = this.fieldname;
  }
  set orderBy(val) {
    this._value = val;
    this.orderByChange.emit(this._value);
    let elem = this.elementRef.nativeElement.children[0];
    if (!elem) return;
    this.renderer.removeClass(elem, 'cdi-s-reorder');
    this.renderer.removeClass(elem, 'cdi-s-reorder-up');
    this.renderer.removeClass(elem, 'cdi-s-reorder-down');
    this.renderer.addClass(elem, this.getSortClass());
  }

  getSortClass(): string {
    if (this.orderBy == this.fieldname) return 'cdi-s-reorder-down';
    if (this.orderBy == '-' + this.fieldname) return 'cdi-s-reorder-up';
    return 'cdi-s-reorder';
  }
}

export const DATE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateValueAccessor),
  multi: true,
};

@Directive({
  //selector: '[useValueAsDate]',
  selector:
    'input[type=date][formControlName],input[type=date][formControl],input[type=date][ngModel]',
  providers: [DATE_VALUE_ACCESSOR],
})
export class DateValueAccessor implements ControlValueAccessor {
  @HostListener('input', ['$event.target.valueAsDate']) onChange = (
    _: any
  ) => {};
  @HostListener('blur', []) onTouched = () => {};

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  writeValue(value: Date): void {
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'valueAsDate',
      value
    );
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }
}

@Component({
  selector: 'delete-button',
  template:
    '<span class="pointer"><i class="far fa-times text-primary"></i> <ng-content></ng-content></span>',
})
export class DeleteButtonComponent {}

@Component({
  selector: 'add-comment',
  templateUrl: 'addCommentModal.html',
})
export class CommentModalComponent {
  @ViewChild('addCommentModal')
  public modal: ModalDirective;

  @ViewChild('textarea')
  public textarea: ElementRef;

  public comment: string;
  @Output() onAdd: EventEmitter<string> = new EventEmitter();

  show() {
    this.comment = null;
    this.modal.show();
    if (this.textarea && this.textarea.nativeElement) {
      setTimeout(() => {
        this.textarea.nativeElement.focus();
      }, 500);
    }
  }
  save() {
    this.onAdd.emit(this.comment);
    this.modal.hide();
  }
}

@Component({
  selector: 'delete-modal',
  templateUrl: 'deleteModal.html',
})
export class DeleteModalComponent {
  //public modalRef: BsModalRef;
  @ViewChild('deleteModal')
  public modal: ModalDirective;

  //constructor(protected modalService: BsModalService) {}

  @Input() name: string;
  @Input() deleteObject: any;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  public isVisible: boolean;

  show(evt = null) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.isVisible = true;
    this.modal.show();
    // Force me to the top.
  }
  cancel() {
    this.modal.hide();
    this.isVisible = false;
  }
  delete() {
    this.onDelete.emit(this.deleteObject);
    this.modal.hide();
    this.isVisible = false;
  }
}

@Component({
  selector: 'confirm-modal',
  templateUrl: 'confirmModal.html',
})
export class ConfirmModalComponent {
  @ViewChild('confirmModal')
  public modal: ModalDirective;

  @Input() action: string;
  @Input() title: string = 'Confirm';
  @Input() confirmObject: any;
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  show(evt = null) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    this.modal.show();
  }
  cancel() {
    this.modal.hide();
  }
  success() {
    this.onConfirm.emit(this.confirmObject);
    this.modal.hide();
  }
}

@Component({
  selector: 'edit-button',
  template:
    '<span class="pointer"><span class="cdi-s-edit"></span><ng-content></ng-content></span>',
})
export class EditButtonComponent {}

@Component({
  selector: 'loading-row',
  template:
    '<div class="row"><div class="col-12 text-center"><loading-small></loading-small></div></div>',
})
export class LoadingRowComponent {}

@Component({
  selector: 'loading-small',
  template: '<i class="far fa-cog fa-3x fa-spin fa-fw"></i>',
})
export class LoadingSmallComponent {}

@Component({
  selector: 'global-error',
  template:
    '<div *ngIf="error" class="alert alert-danger mx-2">{{error}}</div>',
})
export class GlobalErrorComponent {
  @Input() error: string;
}

@Component({
  selector: 'local-error',
  template:
    '<div class="alert alert-danger" style="margin-bottom: 5px" *ngIf="error">{{error}}</div>',
})
export class LocalErrorComponent {
  @Input() error: string;
}
@Component({
  selector: 'local-error-array',
  template:
    '<div class="alert alert-danger" style="margin-bottom: 5px" *ngFor="let error of errors">{{error}}</div>',
})
export class LocalErrorArrayComponent {
  @Input() errors: string[];
}

@Component({
  selector: 'message-button',
  template: '<a class="text-primary pointer">Message</a>',
})
export class MessageButtonComponent {}

@Component({
  selector: 'ok-button',
  template:
    '<button type="button" [disabled]="disabled" class="btn btn-primary mx-2">Save</button>',
})
export class OkButtonComponent {
  @Input() disabled: boolean;
}

@Component({
  selector: 'yes-button',
  template: '<button class="btn btn-primary">Yes</button>',
})
export class YesButtonComponent {}

@Component({
  selector: 'print-button',
  template:
    '<span class="pointer"><i class="far fa-print text-primary"></i> <ng-content></ng-content></span>',
})
export class PrintButtonComponent {}

@Component({
  selector: 'save-button',
  template:
    '<button [disabled]="disabled" class="btn btn-primary mx-2" type="submit">Save</button>',
})
export class SaveButtonComponent {
  @Input() disabled: boolean = false;
}

@Component({
  selector: 'search-button',
  template: '<button class="btn btn-primary" type="submit">Search</button>',
})
export class SearchButtonComponent {}

@Component({
  selector: 'view-button',
  template:
    '<span class="pointer"><i class="far fa-eye text-primary"></i> <ng-content></ng-content></span>',
})
export class ViewButtonComponent {}

@Component({
  selector: 'work-pref-graph',
  template:
    '<progressbar *ngIf="data" [value]="data" class="bob" [max]="96"></progressbar>',
})
export class WorkPrefGraphComponent {
  public data;
  public _day = 0;
  public _prefs: string;
  @Input() set prefs(value: string) {
    if (!value || value.length == 0) {
      value = '';
      for (let i = 0; i < 24 * 4 * 7; i++) {
        value += '0';
      }
    }
    this._prefs = value;
    this.recalc();
  }
  @Input() set day(value: number) {
    this._day = value;
    if (!this._prefs) return;
    this.recalc();
  }

  typeConvert(value: string): string {
    switch (value) {
      case '0':
        return 'info';
      case '1':
        return 'success';
      case '2':
        return 'warning';
      case '3':
        return 'danger';
      default:
        return 'info';
    }
  }

  recalc() {
    let offset = this._day * 24 * 4;
    let startIndex = offset;
    let startValue = this._prefs[offset];
    this.data = [];
    //	console.log(this._prefs);
    for (let i = 1; i < 24 * 4; i++) {
      if (this._prefs[i + offset] != startValue) {
        this.data.push({
          value: i + offset - startIndex,
          type: this.typeConvert(startValue),
          label: '',
        });
        startValue = this._prefs[offset + i];
        startIndex = i + offset;
      }
    }
    this.data.push({
      value: 24 * 4 + offset - startIndex,
      type: this.typeConvert(startValue),
      label: '',
    });
    //console.log(this.data);
  }
}

// Formatting Components

@Pipe({ name: 'bliShortDate' })
export class DateShortFormattingPipe implements PipeTransform {
  protected datePipe = new DatePipe('en-US');
  transform(value: string): string {
    return this.datePipe.transform(value, 'MM/dd/yy');
  }
}

@Pipe({ name: 'bliHours' })
export class HoursFormattingPipe implements PipeTransform {
  transform(hours: number): string {
    const hour = Math.floor(hours);
    let minutes = Math.round(hours - hour * 60).toString();
    if (minutes.length < 2) minutes = '0' + minutes;
    return hour + ':' + minutes;
  }
}

@Pipe({ name: 'bliMinutes' })
export class MinutesFormattingPipe implements PipeTransform {
  transform(minutes: number): string {
    const hour = Math.floor(minutes / 60);
    let min = Math.round(minutes % 60).toString();
    if (min.length < 2) min = '0' + min;
    return hour + ':' + min;
  }
}

@Pipe({ name: 'bliMinutesDecimal' })
export class MinutesDecimalFormattingPipe implements PipeTransform {
  transform(minutes: number): string {
    return (minutes / 60).toFixed(2);
  }
}

@Pipe({ name: 'bliMenuDate' })
export class DateMenuFormattingPipe implements PipeTransform {
  protected datePipe = new DatePipe('en-US');
  transform(value: string, argType: DateMenuType): string {
    switch (argType) {
      case DateMenuType.Weekly:
        return this.datePipe.transform(value, 'MMM d');
      /*case RepeatOptions.Monthly:
				return this.datePipe.transform(value,"dd-YY");*/
      default:
        return this.datePipe.transform(value, 'MMM d');
    }
  }
}

@Pipe({ name: 'bliDate' })
export class DateFormattingPipe implements PipeTransform {
  protected datePipe = new DatePipe('en-US');
  transform(value: any): string {
    return this.datePipe.transform(value, 'MM/dd/yy hh:mm a');
  }
}
@Pipe({ name: 'bliDateLong' })
export class DateLongFormattingPipe implements PipeTransform {
  protected datePipe = new DatePipe('en-US');
  transform(value: any): string {
    return moment(value).format('Do of MMMM, YYYY, [at] hh:mm A');
  }
}

@Pipe({ name: 'bliTime' })
export class TimeFormattingPipe implements PipeTransform {
  transform(value: any): string {
    if (!value || value.length == 0) return '';
    if (value instanceof Date) {
      let d = moment(value);
      return d.format('h:mm A');
    }
    let list = value.split(':');
    let hours = Number.parseInt(list[0]);
    let ampm = hours == 0 || hours > 12 ? ' PM' : ' AM';
    if (hours > 12) hours -= 12;
    return hours + ':' + list[1] + ampm;
  }
}

@Pipe({ name: 'bliPrice' })
export class PriceFormattingPipe implements PipeTransform {
  transform(value: number): string {
    // if value is negative, add a parenthesis around the value
    if (value < 0) {
      return '($' + (value / 100).toFixed(2).replace('-', '') + ')';
    }
    return '$' + (value / 100).toFixed(2);
  }
}

// PIPES
@Pipe({ name: 'phone' })
export class PhoneFormattingPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || typeof value != 'string' || value.length == 0) return null;
    var cleaned = ('' + value).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  }
}

@Component({
  selector: 'phone',
  template: `<a *ngIf="phone" class="text-secondary" [href]="'tel:' + phone">{{
    phone | phone
  }}</a>`,
})
export class PhoneLinkComponent {
  @Input() phone: string;
}
@Directive({
  selector: '[ngModel][phone]',
})
export class PhoneFormattingDirective implements OnInit {
  private phonePipe = new PhoneFormattingPipe();

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {}

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.phonePipe.transform(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.phonePipe.transform(value);
  }
}
// PIPES
@Pipe({ name: 'email' })
export class EmailFormattingPipe implements PipeTransform {
  transform(value: string): string {
    return value.toLowerCase();
  }
}
@Component({
  selector: 'email',
  template: `<a
    *ngIf="email"
    class="text-secondary"
    [href]="'mailto:' + email"
    >{{ email | email }}</a
  >`,
})
export class EmailLinkComponent {
  @Input() email: string;
}
@Directive({
  selector: '[ngModel][email]',
})
export class EmailFormattingDirective implements OnInit {
  private emailPipe = new EmailFormattingPipe();

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {}

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.emailPipe.transform(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.emailPipe.transform(value);
  }
}

@Pipe({
  name: 'filterBy',
  pure: false,
})
export class FilterByPipe {
  transform(value: any[], args: any[]): any {
    if (!args[0] || !args[1] || !value || value.length == 0)
      return value ? value : [];
    let fieldName = args[0];
    let fieldValue = '/' + args[1] + '/';
    //console.log(fieldValue);
    return value.filter(e => {
      if (typeof e[fieldName] == 'number') {
        return e[fieldName] == +args[1];
      } else if (!(fieldName in e) || e[fieldName] === null) return false;
      else return e[fieldName].match(fieldValue) != null;
    });
  }
}

@Pipe({
  name: 'filterSoftBy',
  pure: false,
})
export class FilterSoftByPipe {
  transform(value: any[], args: string[]): any {
    if (!args[0] || !args[1] || !value || value.length == 0)
      return value ? value : [];
    let fieldName = args[0];
    let fieldValue = args[1].toString().trim();
    if (fieldValue && fieldValue.length > 0) {
      fieldValue = fieldValue.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    } else {
      return value;
    }
    let regExp = new RegExp(fieldValue, 'i');
    return value.filter(e => {
      //return e[fieldName].toString().indexOf(fieldValue)>=0;
      if (!(fieldName in e) || e[fieldName] === null) return false;
      return e[fieldName].toString().search(regExp) >= 0;
    });
  }
}

@Pipe({
  name: 'filterByPage',
  pure: false,
})
export class FilterByPagePipe {
  transform(value: any[], args: number[]): any {
    if (!args[0] || !args[1] || !value || value.length == 0)
      return value ? value : [];
    let pageNumber = +args[0] - 1;
    let pageLength = +args[1];
    let start = pageNumber * pageLength;
    if (start >= value.length) return [];
    return value.slice(start, start + pageLength);
  }
}

@Pipe({
  name: 'filterNotBy',
  pure: false,
})
export class FilterNotByPipe {
  transform(value: any[], args: string[]): any {
    if (!args[0] || !args[1] || !value || value.length == 0)
      return value ? value : [];
    let fieldName = args[0];
    let fieldValue = args[1];
    return value.filter(e => {
      return e[fieldName] != fieldValue;
    });
  }
}

@Pipe({
  name: 'orderBy',
  pure: false,
})
export class OrderByPipe implements PipeTransform {
  static _orderByComparator(a: any, b: any): number {
    if (typeof a == 'undefined' || typeof b == 'undefined') return 0;
    if (a instanceof Date && b instanceof Date) {
      return (<Date>a).getTime() - (<Date>b).getTime();
    }
    if (
      a instanceof Date ||
      b instanceof Date ||
      moment.isMoment(a) ||
      moment.isMoment(b)
    ) {
      return moment(a) - moment(b);
    }
    if (a == null || b == null) {
      if (a == null && b == null) return 0;
      if (a == null) return -1;
      return 1;
    } else if (typeof a === 'boolean') {
      return a === b ? 0 : a ? -1 : 1;
    } else if (
      isNaN(parseFloat(a)) ||
      !isFinite(a) ||
      isNaN(parseFloat(b)) ||
      !isFinite(b)
    ) {
      //Isn't a number so lowercase the string to properly compare
      //if(a.toLowerCase() < b.toLowerCase()) return -1;
      //if(a.toLowerCase() > b.toLowerCase()) return 1;
      return a.localeCompare(b, 'en', { sensitivity: 'base' });
    } else {
      //Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }

    return 0; //equal each other
  }

  transform(input: any, config: string[] | string = '+'): any {
    if (!Array.isArray(input)) return input;

    if (
      !Array.isArray(config) ||
      (Array.isArray(config) && config.length == 1)
    ) {
      var propertyToCheck: string = !Array.isArray(config) ? config : config[0];
      var desc = propertyToCheck.substr(0, 1) == '-';

      //Basic array
      if (
        !propertyToCheck ||
        propertyToCheck == '-' ||
        propertyToCheck == '+'
      ) {
        return !desc ? input.sort() : input.sort().reverse();
      } else {
        var property: string =
          propertyToCheck.substr(0, 1) == '+' ||
          propertyToCheck.substr(0, 1) == '-'
            ? propertyToCheck.substr(1)
            : propertyToCheck;

        return input.sort(function (a: any, b: any) {
          return !desc
            ? OrderByPipe._orderByComparator(a[property], b[property])
            : -OrderByPipe._orderByComparator(a[property], b[property]);
        });
      }
    } else {
      //Loop over property of the array in order and sort
      return input.sort(function (a: any, b: any) {
        for (var i: number = 0; i < config.length; i++) {
          var desc = config[i].substr(0, 1) == '-';
          var property =
            config[i].substr(0, 1) == '+' || config[i].substr(0, 1) == '-'
              ? config[i].substr(1)
              : config[i];

          var comparison = !desc
            ? OrderByPipe._orderByComparator(a[property], b[property])
            : -OrderByPipe._orderByComparator(a[property], b[property]);

          //Don't return 0 yet in case of needing to sort by next property
          if (comparison != 0) return comparison;
        }

        return 0; //equal each other
      });
    }
  }
}

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({
  name: 'safeHTML',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
