import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { ShiftTradeService, IShiftTrade } from '../services/shiftTrade.service';
import { ShiftService } from '../services/shift.service';
import { baseRole } from '../shared/baseRole';
import { moment, TradeType } from '../shared/api';

@Component({
  selector: 'modal-trade-request',
  templateUrl: 'modalTradeRequest.html',
})
export class ModalTradeRequestComponent extends baseRole implements OnInit {
  @Input() shifts: any[];
  @Input() myPositions: any;

  @ViewChild('modal')
  public modal: ModalDirective;
  public availableShifts: any[];
  public shift;
  public selectedShift: any = null;
  public trade_type = TradeType.GiveUp;
  public TradeType = TradeType;
  public comment: string;

  constructor(
    public accountService: AccountService,
    public shiftService: ShiftService,
    public shiftTradeService: ShiftTradeService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  show(shift) {
    this.comment = null;
    this.trade_type = TradeType.GiveUp;
    this.shift = shift;
    this.shiftFilter();
    this.modal.show();
  }

  shiftFilter() {
    if (!this.shift) return;
    let now = new Date();
    this.availableShifts = this.shifts.filter(e => {
      if (
        e.user_id &&
        e.user_id != this.getUserId() &&
        e.position_id == this.shift.position_id &&
        e.start > now
      )
        return true;
      return false;
    });
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    if (this.trade_type != TradeType.Trade) {
      this.selectedShift = null;
    }
    let pickup: IShiftTrade = {
      shift_id: this.shift.id,
      user_id: this.getUserId(),
      trade_type: this.trade_type,
      trade_shift_id: this.selectedShift ? this.selectedShift.id : null,
      trade_user_id: this.selectedShift ? this.selectedShift.user_id : null,
      //comment: this.comment,
    };
    this.shiftTradeService.update(pickup).subscribe(data => {
      this.modal.hide();
    });
  }
}
