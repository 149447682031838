<div class="row">
  <div class="col-12">
    <button class="btn btn-primary float-right" type="button" routerLink="add">
      Add Billing Plan
    </button>
  </div>
</div>
<div class="row row-table row-header" *ngIf="data">
  <div class="col-2" sort fieldname="name" [(orderBy)]="orderByField">Name</div>
  <div class="col" sort fieldname="is_active" [(orderBy)]="orderByField">
    Active
  </div>
  <div class="col" sort fieldname="date_end" [(orderBy)]="orderByField">
    Date End
  </div>
  <div class="col" sort fieldname="code" [(orderBy)]="orderByField">Code</div>
  <div class="col" sort fieldname="package_1" [(orderBy)]="orderByField">
    Basic
  </div>
  <div class="col" sort fieldname="package_2" [(orderBy)]="orderByField">
    Plus
  </div>
  <div class="col" sort fieldname="package_3" [(orderBy)]="orderByField">
    Pro
  </div>
  <div
    class="col"
    sort
    fieldname="billing_plan_type"
    [(orderBy)]="orderByField">
    Type
  </div>
  <div class="col cdi-s"></div>
</div>

<div class="row-table row-striped" *ngIf="data">
  <div class="row" *ngFor="let d of data | orderBy: orderByField">
    <div class="col-2">{{d.name}}</div>
    <div class="col">{{d.is_active?'Yes':'No'}}</div>
    <div class="col">{{d.date_end | date: 'short' }}</div>
    <div class="col">{{d.code}}</div>
    <div class="col">{{ d.package_1 | currency }}</div>
    <div class="col">{{ d.package_2 | currency }}</div>
    <div class="col">{{ d.package_3 | currency }}</div>
    <div class="col">{{ BillingPlanType[d.billing_plan_type] }}</div>
    <div class="col cdi-s">
      <span class="cdi-s-edit ml-auto pointer" routerLink="{{d.id}}"></span>
    </div>
  </div>
</div>
