import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ILocation, LocationService } from '../services/location.service';
import {
  LocationType,
  LocationTypeName,
  LocationCategoryName,
  LocationTypeGoalDefaults,
} from '../shared/api';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './goals.html',
})
export class GoalsComponent extends baseRole implements OnInit {
  public data: ILocation;
  public edit = false;
  public LocationTypeKeys = Object.keys(LocationType).filter(Number);
  public LocationTypeName = LocationTypeName;
  public LocationCategoryName;
  public profitGoal = 0;
  public primeCostGoal = 0;
  public current_location_type: number;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    public accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.accountService.getLocation()) {
      this.router.navigate(['/settings/account']);
      return;
    }
    this.locationService
      .get(this.accountService.getLocation().id)
      .subscribe(data => {
        this.data = data;
        this.LocationCategoryName =
          LocationCategoryName[this.data.location_type];
        this.current_location_type = this.data.location_type;
        this.recalc(false);
      });
  }

  recalc(save = true) {
    this.primeCostGoal =
      this.data.foh_goal + this.data.boh_goal + this.data.cogs_goal;
    this.profitGoal =
      100 -
      (this.data.foh_goal +
        this.data.boh_goal +
        this.data.cogs_goal +
        this.data.fixed_goal +
        this.data.exp_goal +
        (this.data.payroll_goal / 100.0) *
          (this.data.foh_goal + this.data.boh_goal));
    //console.log('ReCalc');
    if (save) this.save();
  }

  save() {
    let updateObj: any = {
      id: this.data.id,
      location_type: this.data.location_type,
      category: this.data.category,
      foh_goal: this.data.foh_goal,
      boh_goal: this.data.boh_goal,
      cogs_goal: this.data.cogs_goal,
      fixed_goal: this.data.fixed_goal,
      exp_goal: this.data.exp_goal,
      payroll_goal: this.data.payroll_goal,
    };

    // Subscribe and chuck the results.
    this.locationService.update(updateObj).subscribe(data => {});
  }

  changeBusinessType() {
    this.data = Object.assign(
      this.data,
      LocationTypeGoalDefaults[this.current_location_type]
    );
    this.data.location_type = this.current_location_type;
    this.LocationCategoryName = LocationCategoryName[this.data.location_type];
    this.data.category = this.LocationCategoryName[0];
    this.recalc();
  }
}
