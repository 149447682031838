import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { MessageService, IMessage } from '../services/message.service';
import { UserService, IUser } from '../services/user.service';
import { PositionService, IPosition } from '../services/position.service';
import { MediaService } from '../services/media.service';
import { baseRole } from '../shared/baseRole';
import { DocMimeTypes } from '../shared/api';

@Component({
  selector: 'modal-message',
  templateUrl: 'modalMessage.html',
})
export class ModalMessageComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;
  public message: any;
  public users: any[];
  public positions: any[];
  public messageUsers: IUser[];
  public error: string = null;
  public fileError: string = null;
  public fileData;

  @ViewChild('fileInput')
  fileInput: ElementRef;

  constructor(
    public accountService: AccountService,
    public messageService: MessageService,
    public positionService: PositionService,
    public userService: UserService,
    public mediaService: MediaService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  initMessage() {
    this.message = {
      title: null,
      body: null,
      is_email: false,
      is_text: false,
      wall: false,
      signed: false,
      comments: false,
    };
  }

  load() {
    this.initMessage();
    this.userService
      .listWithPositions(new Date(), new Date())
      .subscribe(data => {
        this.users = data;
        this.positionService.list().subscribe(data => {
          this.positions = data;
          this.modal.show();
        });
      });
  }

  show() {
    this.load();
    //this.clearUsers();
  }

  cancel() {
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
    this.modal.hide();
  }

  clearUsers() {
    for (let u of this.users) {
      u._selected = false;
    }
    for (let p of this.positions) {
      p._selected = false;
    }
  }

  selectAllUsers() {
    for (let u of this.users) {
      u._selected = true;
    }
  }

  userChange(selected) {
    if (!selected) {
      // clear only if turn off, not add on
      for (let p of this.positions) {
        p._selected = false;
      }
    }
  }

  positionChange(pos) {
    if (pos._selected) {
      for (let u of this.users) {
        if (!u._selected) {
          for (let up of u.positions) {
            if (pos.id == up.id) {
              u._selected = true;
              break;
            }
          }
        }
      }
    } else {
      let rerun = false;
      for (let u of this.users) {
        if (u.positions.length > 0)
          //if no positions but selected..
          u._selected = false;
      }
      for (let sp of this.positions) {
        if (sp._selected) this.positionChange(sp);
      }
    }
  }

  buildRecipients(): boolean {
    let userMap: any = {};
    let positionMap: any = {};
    this.message.recipients = [];
    for (let u of this.users) {
      if (u._selected) {
        userMap[u.id] = true;
      }
      //		console.log(u.positions);
      for (let p of u.positions) {
        if (!(p.id in positionMap)) {
          positionMap[p.id] = [];
        }
        positionMap[p.id].push(u.id);
      }
    }
    for (let p of this.positions) {
      if (!p._selected) continue;
      if (p.id in positionMap) {
        for (let u of positionMap[p.id]) {
          userMap[u] = true;
        }
      }
    }
    for (let id in userMap) {
      this.message.recipients.push({ user_id: id });
    }
    if (this.message.recipients.length == 0) {
      this.error = 'You must select at least one recipient for a message.';
      return false;
    }
    return true;
  }

  handleFileChange(files) {
    if (!files || files.length == 0) this.fileData = null;
    else this.fileData = files;
    this.checkFiles();
  }

  checkFiles() {
    this.fileError = null;
    if (!this.fileData || this.fileData.length == 0) return true;
    for (let f of this.fileData) {
      if (DocMimeTypes.indexOf(f.type) < 0) {
        this.fileError = 'Upload must be an image or pdf file';
        this.fileData = null;
        this.fileInput.nativeElement.value = '';
        setTimeout(() => {
          this.fileError = null;
        }, 5000);
        return false;
      }
    }
    return true;
  }

  save() {
    this.error = null;
    if (!this.checkFiles()) return;
    if (this.buildRecipients()) {
      this.messageService._blockUpdate(true);
      this.message.has_attachment = this.fileData && this.fileData.length > 0;
      this.messageService.update(this.message).subscribe(data => {
        if (this.fileData && this.fileData.length > 0) {
          let p = [];
          //					console.log('Uploading');
          for (let f of this.fileData) {
            //						console.log(f);
            p.push(this.mediaService.upload(f, 'message', data.id));
          }
          forkJoin(p).subscribe(() => {
            this.fileInput.nativeElement.value = '';
            this.messageService._blockUpdate(false);
            this.messageService.notifyUpdate(true);
            this.modal.hide();
          });
        } else {
          this.messageService._blockUpdate(false);
          this.messageService.notifyUpdate(true);
          this.modal.hide();
        }
      });
    }
  }
}
