import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { ShiftService, IShift } from '../services/shift.service';
import { baseRole } from '../shared/baseRole';
import { UserService, IUser } from '../services/user.service';
import { PositionService, IPosition } from '../services/position.service';
import {
  ShiftHistoryService,
  IShiftHistory,
} from '../services/shiftHistory.service';
import { TimeOffService } from '../services/timeOff.service';
import {
  EmployeePositionService,
  IEmployeePosition,
} from '../services/employeePosition.service';
import {
  RateType,
  TradeType,
  TradeTypeNames,
  moment,
  dateToBeginningOfPayroll,
} from '../shared/api';

import {
  createShiftBreakTime,
  IShiftBreak,
  shiftBreakValidation,
} from 'source/shared/helpers';
import { Router } from '@angular/router';
@Component({
  selector: 'modal-edit-shift',
  templateUrl: 'modalEditShift.html',
})
export class ModalEditShiftComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() dayList: any[];
  @Input() timeOffs: any[];

  public day: any;
  public startIndex = 0;
  public days: Date[] = [];
  public data: IShift;
  public positions: IPosition[];
  public shiftHistory: any[];
  public _allUsers: any[];
  public users: any[];
  public unassigned = { _selected: false, num: 0 };
  public TradeType = TradeType;
  public TradeTypeNames = TradeTypeNames;
  public numbers: number[];

  public firstBreak: IShiftBreak;
  public secondBreak: IShiftBreak;
  public thirdBreak: IShiftBreak;
  public breakTimeOffset: number;
  public errors: string[] = null;

  constructor(
    public router: Router,
    public accountService: AccountService,
    public shiftService: ShiftService,
    public shiftHistoryService: ShiftHistoryService,
    public positionService: PositionService,
    protected timeOffService: TimeOffService,
    protected userService: UserService,
    protected employeePositionService: EmployeePositionService
  ) {
    super(accountService);
    this.numbers = Array(13)
      .fill(0)
      .map((x, i) => i * 5);
  }

  ngOnInit() {}

  load() {
    this.positionService.list().subscribe(data => {
      this.positions = data;
    });
    if (this.data.id) {
      this.shiftHistoryService
        .list({ shift_id: this.data.id })
        .subscribe(data => {
          this.shiftHistory = data;
        });
    }
    this.userService
      .listWithPositions(this.data.start, this.data.end)
      .subscribe(data => {
        this._allUsers = data;
        this.filterUsersByPosition();
        this.calcUserStats();
        this.calcConflicts();
        this.selectUser(this.data.user_id);

        this.modal.show();
      });
  }

  show(day, startIndex, data) {
    const hour = 60 * 60 * 1000;
    if (!day || !data) return;
    this.positions = null;
    this.shiftHistory = null;
    this._allUsers = null;
    this.users = null;
    this.startIndex = startIndex;
    this.day = day;
    this.data = Object.assign({}, data); // Copy this on the way in..
    this.data.start = (<any>this.data.start).toDate();
    this.data.end = (<any>this.data.end).toDate();
    this.days = [];
    let d = new Date(this.startDate);
    while (d.getTime() <= this.endDate.getTime()) {
      this.days.push(new Date(d));
      d.setDate(d.getDate() + 1);
    }

    this.firstBreak = {
      toggle: this.data.first_break_length > 0,
    };
    this.secondBreak = {
      toggle: this.data.second_break_length > 0,
    };
    this.thirdBreak = {
      toggle: this.data.third_break_length > 0,
    };

    if (!this.data.first_break_time) {
      this.data.first_break_time = new Date(this.data.start.getTime());
      this.data.first_break_length = 10;
    }
    if (!this.data.second_break_time) {
      this.data.second_break_time = new Date(this.data.start.getTime());
      this.data.second_break_length = 10;
    }
    if (!this.data.third_break_time) {
      this.data.third_break_time = new Date(this.data.start.getTime());
      this.data.third_break_length = 10;
    }
    this.load();
  }

  filterUsersByPosition() {
    this.users = this._allUsers.filter(e => {
      if (!e.positions) return false;
      for (let p of e.positions) {
        if (p.id == this.data.position_id) return true;
      }
      return false;
    });
    this.calcUserStats();
    this.calcConflicts();
  }

  calcUserStats() {
    if (!this.dayList) return;
    let userMap: any = {};

    for (let u of this.users) {
      userMap[u.id] = u;

      //u.conflicts = [];
      //u.ot_hours = u.ot_hours;
      //u.max_hours = u.max_hours;
      u.minutes = 0;
      u.cost = 0;
      u.shift_count = 0;
      u.shifts = [];
      u.shiftMap = {};
      u.timeOffs = [];
    }
    for (let d of this.dayList) {
      for (let s of d._shifts) {
        let shift = s.shift;
        if (!(shift.user_id in userMap)) continue; // Not in current user list
        if (!(shift.id in userMap[shift.user_id].shiftMap)) {
          userMap[shift.user_id].shiftMap[shift.id] = true;
          userMap[shift.user_id].minutes += shift.minutes + shift.ot_minutes;
          userMap[shift.user_id].shift_count++;
          userMap[shift.user_id].shifts.push(shift);

          if (shift.is_salary) continue;
          userMap[shift.user_id].cost += (shift.rate * shift.minutes) / 60;
          userMap[shift.user_id].cost +=
            (shift.rate * 1.5 * shift.ot_minutes) / 60;
        }
      }
    }
    for (let t of this.timeOffs) {
      if (!(t.user_id in userMap)) continue;
      userMap[t.user_id].timeOffs.push(t);
    }
  }

  updateIndex() {
    this.fixDate();
    this.calcUserStats();
    this.calcConflicts();
  }

  calcConflicts() {
    if (!this.users || !this.timeOffs) return;
    let payrollStart = dateToBeginningOfPayroll(
      this.startDate,
      this.getLocation()
    );

    let thisWeekKey = Math.floor(
      moment(this.days[this.startIndex]).diff(payrollStart, 'weeks')
    );
    for (let u of this.users) {
      u.weekMinutes = 0;
      if (!u.shifts) continue;
      u.conflicts = [];
      u.notifications = [];
      for (let s of u.shifts) {
        let weekKey = Math.floor(moment(s.start).diff(payrollStart, 'weeks'));
        if (weekKey == thisWeekKey) {
          u.weekMinutes += moment(s.end).diff(s.start, 'minutes');
        }
        if (s.id == this.data.id) continue;
        let shiftRange = moment.range(s.start, s.end);
        let tRange = moment.range(this.data.start, this.data.end);
        if (shiftRange.overlaps(tRange)) {
          u.conflicts.push({
            start: s.start,
            end: s.end,
            name: 'Overlap: ' + s.position_name,
          });
        } else if (moment(this.data.start).isSame(s.start, 'day')) {
          u.notifications.push({
            start: s.start,
            end: s.end,
            name: 'Working: ' + s.position_name,
          });
        }
      }

      for (let t of u.timeOffs) {
        let timeRange = moment.range(t.start_date, t.end_date);
        let tRange = moment.range(this.data.start, this.data.end);
        if (timeRange.overlaps(tRange)) {
          u.conflicts.push({
            start: t.start_date,
            end: t.end_date,
            name: 'Time Off',
          });
          break; // show this only once, not once for each day
        }
      }
    }

    for (let u of this.users) {
      u.minutes = u.weekMinutes;
      if (u.is_salary) continue;
      let weekKey = Math.floor(
        moment(this.days[this.startIndex]).diff(payrollStart, 'weeks')
      );
      if (u.weekMinutes > u.max_hours * 60) {
        u.conflicts.push({
          minutes: u.weekMinutes - u.max_hours * 60,
          name: 'Overtime',
        });
      }
    }
  }

  selectUser(userId) {
    this.data.user_id = userId;
    for (let u of this._allUsers) {
      u._selected = userId == u.id;
    }
    this.unassigned._selected = !userId;
  }

  deleteShift() {
    this.shiftService.delete(this.data.id).subscribe(() => {
      this.modal.hide();
      localStorage.setItem('scrollPosition', window.scrollY.toString());
      this.router.navigate(['/schedule']);
    });
  }

  cancel() {
    this.positions = null;
    this.shiftHistory = null;
    this._allUsers = null;
    this.users = null;
    this.startIndex = null;
    this.data = null;
    this.modal.hide();
  }

  fixDate() {
    this.breakTimeOffset = 0;
    this.data.start.setDate(this.days[this.startIndex].getDate());
    this.data.start.setMonth(this.days[this.startIndex].getMonth());
    this.data.start.setFullYear(this.days[this.startIndex].getFullYear());
    this.data.start.setSeconds(0);
    this.data.end.setSeconds(0);
    this.data.end.setFullYear(this.data.start.getFullYear());
    this.data.end.setMonth(this.data.start.getMonth());
    this.data.end.setDate(this.data.start.getDate());

    // Is end time before the start time? It's in the next day.
    if (this.data.end.getTime() <= this.data.start.getTime()) {
      this.data.end.setDate(this.data.end.getDate() + 1);
      this.breakTimeOffset = 1;
    }
  }

  save() {
    this.errors = [];
    this.fixDate();

    if (!this.firstBreak.toggle) {
      this.data.first_break_time = null;
      this.data.first_break_length = 0;
    } else {
      this.data.first_break_time = createShiftBreakTime(
        this.data.start,
        this.data.first_break_time,
        this.breakTimeOffset
      );
    }

    if (!this.secondBreak.toggle) {
      this.data.second_break_time = null;
      this.data.second_break_length = 0;
    } else {
      this.data.second_break_time = createShiftBreakTime(
        this.data.start,
        this.data.second_break_time,
        this.breakTimeOffset
      );
    }

    if (!this.thirdBreak.toggle) {
      this.data.third_break_time = null;
      this.data.third_break_length = 0;
    } else {
      this.data.third_break_time = createShiftBreakTime(
        this.data.start,
        this.data.third_break_time,
        this.breakTimeOffset
      );
    }

    this.errors.push(...shiftBreakValidation([this.data]));

    // Filter duplicate errors from array
    this.errors = [...new Set(this.errors)];

    const cleanErrors = this.errors.filter(function (item) {
      return item !== null;
    });

    // Set date Index
    if ('user' in <any>this.data) delete (<any>this.data).user;
    this.data.location_id = this.getLocationId();

    if (cleanErrors.length > 0) return;
    this.shiftService.update(this.data).subscribe(data => {
      this.modal.hide();
      localStorage.setItem('scrollPosition', window.scrollY.toString());
      this.router.navigate(['/schedule']);
    });
  }
}
