import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { BaseService, fixDate } from './base.service';
import { AccountService } from './account.service';
import { INotification, NotificationType, getBaseUrl } from '../shared/api';

@Injectable()
export class NotificationService extends BaseService<INotification> {
  constructor(
    protected accountService: AccountService,
    protected http: HttpClient
  ) {
    super(accountService, http, 'notification');
  }

  sendTest(
    notification_id: number,
    notification_type: NotificationType,
    data: any
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.baseUrl + 'test',
        {
          notification_id: notification_id,
          notification_type: notification_type,
          data: data,
        },
        this.defaultOptions
      )
      .pipe(
        tap(data => {
          this.notifyUpdate();
        }),
        catchError(BaseService.handleError)
      );
  }
}

export {
  INotification,
  NotificationType,
  NotificationTypeDataHints,
} from '../shared/api';
