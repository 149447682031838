<span class="pointer" (click)="show($event)"><ng-content></ng-content></span>
<div
  bsModal
  #confirmModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Confirm"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-center" style="width: 100%">{{title}}</div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to {{action}}
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <button type="button" class="btn float-right" (click)="cancel()">
              No
            </button>
            <button
              type="button"
              class="btn btn-primary float-right mr-2"
              (click)="success()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
