<div class="row">
  <div class="col-md-12 d-none d-md-block" style="min-height: 100px">
    &nbsp;
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-md-4 col-10 text-center card">
    <div class="card-body">
      <div style="width: 100%" class="my-4">
        <span class="cdi-l-logo cdi-biggest mx-auto"></span>
      </div>
      <h4>Cheddrsuite Password Reset</h4>
      <form (ngSubmit)="resetPassword()" ngNativeValidate>
        <label style="text-transform: none">{{email}}</label>
        <local-error [error]="error.token"></local-error>
        <input
          class="form-control"
          type="password"
          placeholder="Password"
          name="password"
          [(ngModel)]="password"
          debounce
          [delay]="500"
          (result)="checkPassword()"
          required />
        <input
          class="form-control"
          type="password"
          placeholder="Confirm Password"
          name="cpassword"
          debounce
          [delay]="500"
          (result)="matchPassword()"
          [(ngModel)]="cpassword"
          required />
        <local-error [error]="error.matchPassword"></local-error>
        <local-error [error]="warning"></local-error>
        <local-error-array [errors]="suggestions"></local-error-array>
        <button class="btn btn-primary" [disabled]="!(password && cpassword)">
          Continue
        </button>
        <local-error class="mt-2" [error]="error.global"></local-error>
      </form>
    </div>
  </div>
</div>
