/* Version 2.0 */
import * as moment_ from 'moment';
import * as momentHoliday_ from 'moment-holiday-us';
import * as momentRange_ from 'moment-range';
export const momentRange: any = (<any>momentRange_).default || momentRange_;
const moment__: any = (<any>moment_).default || moment_;
export const moment = momentRange.extendMoment(moment__);
export const momentHoliday: any =
  (<any>momentHoliday_).default || momentHoliday_;

import {
  IApiLocation,
  ActionType,
  UserStatus,
  UserType,
  PackageType,
  ResultType,
  RepeatOptions,
  SkuUnit,
  IResult,
} from './types';

export const noop = () => {};

export function getBaseUrl() {
  return '/data/v1/';
}

export interface ISubNavItem {
  label: string;
  routerLink?: string;
  exact?: boolean;
  icon?: string;
  click?: () => void;
  children?: ISubNavItem[];
}

export enum DateMenuType {
  Daily = 0,
  Weekly = 1,
}

export const ImageMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

export const DocMimeTypes = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/gif',
];

export {
  IIdentifiable,
  IApiLocation,
  IApiUser,
  ITimeClockUser,
  ICCInfo,
  BillingPlanType,
  BillingPaymentOption,
  ChecklistItemType,
  ChecklistAssignmentType,
  DaysOfWeek,
  DaysOfWeekKeys,
  EventType,
  ExpenseType,
  FrequencyOfReview,
  FrequencyOfReviewKeys,
  FrequencyOfReviewName,
  FixedCostPeriod,
  FixedCostType,
  InventoryUnits,
  IntegrationUserStatus,
  LocationType,
  LocationStatus,
  LocationLaborType,
  LocationTypeGoalDefaults,
  LocationTypeName,
  LocationCategoryName,
  LogNoteType,
  LogNoteNames,
  MediaType,
  NotificationType,
  NotificationTypeDataHints,
  Permission,
  PermissionCategory,
  AllowPermission,
  PermissionNames,
  PackageType,
  PackageTypeNames,
  PositionType,
  PublishLogType,
  PurchaseOrderStatus,
  RateType,
  RepeatOptions,
  RepeatOptionsKeys,
  ResultType,
  SalesIntegrationAuthType,
  SalesIntegrationShiftSyncType,
  SalesIntegrationType,
  SalesIntegrationFeatures,
  ScheduleSortOrder,
  ServiceTimeType,
  SkuCostMethod,
  SkuUnit,
  SkuUnitNames,
  TradeType,
  TradeTypeNames,
  UserStatus,
  UserType,
  WidgetType,
  WidgetNames,
  ActionType,
  LaborStatType,
  BasePricePerMonth,
  IntegrationPricePerMonth,
  IResult,
  IBillingPlan,
  IBlackOut,
  IChecklist,
  IChecklistItem,
  IChecklistCompleted,
  IChecklistItemCompleted,
  IChecklistUserMap,
  IChecklistPositionMap,
  IClearedEvent,
  IContact,
  IContactGroup,
  ICustomLogField,
  ICustomer,
  ICustomerCheckIn,
  IDailySalesGoal,
  IDoc,
  IDocGroupPermission,
  IDocGroup,
  IDocUserMap,
  IEmailLog,
  IEmailQueue,
  IEmployeePosition,
  IErrorLog,
  IEvent,
  IEventAssignment,
  IExpenseAccount,
  IFixedCost,
  IInventoryCount,
  IInventoryLocation,
  IInvoice,
  IInvoiceItem,
  IIntegrationSales,
  IIntegrationUser,
  IItem,
  IItemCategory,
  IItemRecipe,
  IItemVariation,
  ILocation,
  ILocationCreate,
  IFreeTrialConfig,
  ILocationUserMap,
  ILog,
  ILogComment,
  ILogImage,
  ILogNote,
  ILogQuery,
  ILogReport,
  ILogSegmentedTotals,
  ILoginAttempt,
  IMedia,
  IMessage,
  IMessageComment,
  IMessageImage,
  IMessageRecipient,
  INoteResponse,
  INoteDate,
  INotification,
  IPermissionLocation,
  IPermissionUser,
  IPersistentLogin,
  IPurchaseOrder,
  IPurchaseOrderCreate,
  IPurchaseOrderItem,
  IPosition,
  IPublishLog,
  IPurchaseOrderChangeStatus,
  IQuizQuestion,
  IQuizPassed,
  IRecipeBlock,
  IRecipeBlockItem,
  IReview,
  ISalesGoal,
  IServiceTime,
  IShift,
  IShiftHistory,
  IShiftTemplate,
  IShiftTemplateItem,
  IShiftTrade,
  ISignature,
  ISku,
  ISkuLocation,
  ISkuCategory,
  ITaskAssignment,
  ITextLog,
  ITextQueue,
  ITimeClock,
  ITimeOff,
  ITimezone,
  IUser,
  IUserCreate,
  IUserCreditCard,
  IUsdaListItem,
  IUsdaItem,
  IWriteUp,
  IReportBreakdown,
  ReportBreakdown,
} from './types';

export function SelectAddAll(
  data: any[],
  label: string = 'All',
  fieldName = 'name'
) {
  StringSort(data, fieldName);
  let obj: any = { id: '' };
  obj[fieldName] = label;
  data.unshift(obj);
  return data;
}

export function UserAddAll(data: any[]) {
  StringSort(data, 'lastname');
  let obj: any = {
    id: '',
    firstname: 'Please select an employee',
    lastname: '',
  };
  data.unshift(obj);
  return data;
}

export function StringSort(data: any[], field: string = 'name') {
  if (!data || data.length == 0) return;
  data.sort((a, b) => {
    if (!a[field] && !b[field]) return 0;
    if (!a[field]) return -1;
    if (!b[field]) return 1;
    return a[field].localeCompare(b[field], 'en', { sensitivity: 'base' });
  });
}

export interface IChartSales {
  titleBar: boolean;
  popupFormat: string;
  tickInterval: number; // 31 days is 2678400000
  data: {
    name: string;
    color?: string;
    lineWidth?: number;
    type?: string;
    data: [number, number][];
  }[];
  //color?: string,
  //lineWidth?: number,
  labelFormat?: string;
}

export const Countries = {
  CA: 'Canada',
  US: 'United States',
};

export const Provinces = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const States = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'Dist of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export interface IPaginator {
  currentPage: number;
  maxPerPage: number;
  numPages: number;
}

export const DaysBefore = {
  0: 'Same Day',
  1: '1 Day Before',
  2: '2 Days Before',
  3: '3 Days Before',
  7: '1 Week Before',
};

export const DaysBeforeKeys = new Array(0, 1, 2, 3, 7);

export function getScheduledDays(
  eStart: Date,
  eEnd: Date,
  repeatOption: RepeatOptions,
  rEnd: Date,
  periodStart: any,
  periodEnd: any
): any[] {
  let startDate = moment(periodStart);
  let endDate = moment(periodEnd);
  let eventStart = moment(eStart);
  let eventEnd = moment(eEnd);
  let repeatEnd = rEnd ? moment(rEnd) : null;
  let incValue = 1;
  let incOffset = 'days';

  /*console.log('getScheduleDays');
	console.log('Event Start: '+eStart);
	console.log('Event End: '+ eEnd);
	console.log('Repeat Until: '+repeatEnd);
	console.log('Period Start: '+startDate.toDate());
	console.log('Period End: '+endDate.toDate());
	console.log('Repeat: '+RepeatOptions[repeatOption]);*/

  if (eventEnd < startDate && repeatEnd && repeatEnd.isBefore(startDate)) {
    //		console.log('Event has ended before things started');
    return [];
  }

  if (eventStart > endDate) {
    //		console.log('Event start after the end');
    return [];
  }

  let duration = Math.ceil(moment(eEnd).diff(eStart, 'days', true));
  // Did it wrap into the next day?
  if ((eventEnd.diff(eStart, 'hours') % 24) + eventStart.hours() > 24) {
    duration++;
  }
  if (duration < 1) duration = 1; // This is caused by a bad end date
  //	console.log('Duration: '+duration);

  if (repeatEnd && repeatEnd.isBefore(endDate)) endDate = moment(repeatEnd);

  let results = [];

  switch (+repeatOption) {
    case RepeatOptions.None:
      let date = moment(eventStart);
      for (let i = 0; i < duration; i++) {
        results.push(moment(date));
        date.add('+1', 'days');
      }
      return results;
    case RepeatOptions.Daily:
      incValue = 1;
      incOffset = 'days';
      if (eventStart < startDate) eventStart = startDate;
      break;
    case RepeatOptions.Weekly:
      incValue = 7;
      incOffset = 'days';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let weekOffset = moment.duration(startDate.diff(eventStart)).asWeeks();
        weekOffset = Math.floor(weekOffset);
        eventStart.add(weekOffset, 'weeks');
      }
      break;
    case RepeatOptions.BiWeekly:
      incValue = 14;
      incOffset = 'days';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let weekOffset = moment.duration(startDate.diff(eventStart)).asWeeks();
        weekOffset = 2 * Math.floor(weekOffset / 2);
        eventStart.add(weekOffset, 'weeks');
      }
      break;
    case RepeatOptions.Monthly:
      incValue = 1;
      incOffset = 'months';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let offset = moment.duration(startDate.diff(eventStart)).asMonths();
        offset = Math.floor(offset);
        eventStart.add(offset, 'months');
      }
      break;
    case RepeatOptions.Quarterly:
      incValue = 3;
      incOffset = 'months';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let offset = moment.duration(startDate.diff(eventStart)).asMonths();
        offset = 3 * Math.floor(offset / 3);
        eventStart.add(offset, 'months');
      }
      break;
    case RepeatOptions.Annually:
      incValue = 1;
      incOffset = 'years';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let offset = moment.duration(startDate.diff(eventStart)).asYears();
        offset = Math.floor(offset);
        eventStart.add(offset, 'years');
      }
      break;
  }
  let range = moment.range(startDate, endDate);

  for (
    let m = moment(eventStart);
    m.isBefore(endDate);
    m.add(incValue, incOffset)
  ) {
    /*console.log('Date at:')
		console.log(m.toDate());*/
    let date = moment(m);
    for (let i = 0; i < duration; i++) {
      if (range.contains(date)) {
        results.push(moment(date));
      }
      date.add('+1', 'days');
    }
  }
  //console.log(results);
  return results;
}

export function getStartEndDays(
  eStart: Date,
  eEnd: Date,
  repeatOption: RepeatOptions,
  rEnd: Date,
  periodStart: any,
  periodEnd: any,
  firstOnly = false
): { start: any; end: any }[] {
  let eventDuration = moment(eEnd).diff(eStart, 'minutes');
  let startDate = moment(periodStart);
  let endDate = moment(periodEnd);
  let eventStart = moment(eStart);
  let eventEnd = moment(eEnd);
  let repeatEnd = rEnd ? moment(rEnd) : null;
  let incValue = 1;
  let incOffset = 'days';

  // Is the repeat really short for some reason?
  /*if(eventEnd.isBefore(repeatEnd)) {
		repeatEnd = moment(eventEnd);
	}*/

  if (eventEnd < startDate && repeatEnd && repeatEnd.isBefore(startDate)) {
    //		console.log('Event has ended before things started');
    return [];
  }

  if (eventStart > endDate) {
    //		console.log('Event start after the end');
    return [];
  }

  //let duration = Math.ceil(moment(eEnd).diff(eStart,'days'));
  //if(duration<1) duration = 1;	// This is caused by a bad end date

  // Shorten the time period up to the last possible repeat.
  //if(!repeatEnd) { endDate = moment(eventEnd); }
  if (repeatEnd && repeatEnd.isBefore(endDate)) endDate = moment(repeatEnd);

  /*
	console.log('getStartEndDays');
	console.log(eStart);
	console.log(eEnd);
	console.log(repeatEnd);
	console.log(periodStart.toDate());
	console.log(periodEnd.toDate());
	console.log(RepeatOptions[repeatOption]);
	console.log('Event Duration: '+eventDuration);*/

  let results: { start: any; end: any }[] = [];

  switch (repeatOption) {
    case RepeatOptions.None:
      /*let date = moment(eventStart);
			date.add(duration,'days');
			results.push({ start: moment(eventStart), end: date});*/
      return [{ start: moment(eventStart), end: moment(eventEnd) }];
    case RepeatOptions.Daily:
      incValue = 1;
      incOffset = 'days';
      if (eventStart < startDate) eventStart = startDate;
      break;
    case RepeatOptions.Weekly:
      incValue = 7;
      incOffset = 'days';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let weekOffset = moment.duration(startDate.diff(eventStart)).asWeeks();
        weekOffset = Math.ceil(weekOffset);
        eventStart.add(weekOffset, 'weeks');
      }
      break;
    case RepeatOptions.BiWeekly:
      incValue = 14;
      incOffset = 'days';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let weekOffset = moment.duration(startDate.diff(eventStart)).asWeeks();
        weekOffset = 2 * Math.ceil(weekOffset / 2);
        eventStart.add(weekOffset, 'weeks');
      }
      break;
    case RepeatOptions.Monthly:
      incValue = 1;
      incOffset = 'months';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let offset = moment.duration(startDate.diff(eventStart)).asMonths();
        offset = Math.ceil(offset);
        eventStart.add(offset, 'months');
      }
      break;
    case RepeatOptions.Quarterly:
      incValue = 3;
      incOffset = 'months';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let offset = moment.duration(startDate.diff(eventStart)).asMonths();
        offset = 3 * Math.ceil(offset / 3);
        eventStart.add(offset, 'months');
      }
      break;
    case RepeatOptions.Annually:
      incValue = 1;
      incOffset = 'years';
      if (eventStart < startDate) {
        // Offset it to the first possible
        let offset = moment.duration(startDate.diff(eventStart)).asYears();
        offset = Math.ceil(offset);
        eventStart.add(offset, 'years');
      }
      break;
  }

  for (
    let m = moment(eventStart);
    m.isBefore(endDate);
    m.add(incValue, incOffset)
  ) {
    let endDate = moment(m);
    endDate.add(eventDuration, 'minutes');
    //date.add(duration,'days');
    /*	console.log('Duration: '+duration);
		console.log('Event Duration: '+eventDuration);
		console.log(m.toDate()+ " - "+date.toDate());*/
    results.push({ start: moment(m), end: endDate });
    if (firstOnly) break;
  }
  //console.log(results);
  return results;
}

export function dateDoOffset(date: Date, refDate: Date, days: number): Date {
  let n = new Date(date);
  n.setFullYear(refDate.getFullYear());
  n.setMonth(refDate.getMonth());
  n.setDate(refDate.getDate() - days);
  n.setSeconds(0);
  return n;
}

const gramsToOz = 28.3495;

export function skuUnitToGrams(type: SkuUnit, value: number): number {
  switch (+type) {
    case SkuUnit.Quantity:
      return value;
    case SkuUnit.Grams:
      return value;
    case SkuUnit.Oz:
    case SkuUnit.FluidOz:
      return value * gramsToOz;
    case SkuUnit.Lbs:
      return value * 16 * gramsToOz;
    default:
      //		console.error('Unknown SkuUnit: '+type);
      return value;
  }
}

export function gramsToSkuUnit(type: SkuUnit, value: number): number {
  switch (+type) {
    case SkuUnit.Quantity:
      return value;
    case SkuUnit.Grams:
      return value;
    case SkuUnit.Oz:
    case SkuUnit.FluidOz:
      return +(value / gramsToOz).toFixed(4);
    case SkuUnit.Lbs:
      return +(value / (16 * gramsToOz)).toFixed(4);
    default:
      //		console.error('Unknown SkuUnit: '+type);
      return value;
  }
}

export function textColor(hex: string): string {
  if (!hex) return '';
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return '#000000';
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
}

export function youTubeUrlToId(url: string): string {
  const rx =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  let matches = url.match(rx);
  if (matches.length > 0) return matches[1];
  return null;
}

export function getStartOfCurrentWeek(loc: IApiLocation): Date {
  let dayOfWeek = loc.week_start;
  let date = new Date();
  let offset = date.getDay();

  // Flatten the date.
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setDate(date.getDate() - (offset - dayOfWeek));
  return date;
}

export function getEndOfWeek(startDate: Date): Date {
  let date = new Date(startDate);
  date.setMilliseconds(date.getMilliseconds() + 7 * 24 * 60 * 60 * 1000 - 1000);
  return date;
}

const imgTokens: any = {};

export function resetImgToken(type: string, key: string) {
  if (type in imgTokens && key in imgTokens[type]) {
    delete imgTokens[type][key];
  }
}

function getImgToken(type: string, key: string) {
  if (!(type in imgTokens) || !(key in imgTokens[type])) {
    return imgTokens[type][key];
  }

  return imgTokens[type][key];
}

export function getUserProfileImage(
  has_image: string,
  firstName?: string,
  lastname?: string,
  color?: string
): string {
  if (has_image) {
    return getBaseUrl() + 'user/image/' + has_image;
  } else {
    if (!firstName || !lastname || !color)
      return '/images/default/default-profile.svg';
    return (
      'https://ui-avatars.com/api/?name=' +
      firstName +
      '+' +
      lastname +
      '&background=' +
      color.slice(1, color.length) +
      '&color=fff&size=256'
    );
  }
}

export function getItemImage(has_image: string): string {
  if (has_image) {
    return getBaseUrl() + 'item/image/' + has_image;
  } else {
    return '/images/default/default-item.svg';
  }
}

export function getLocationImage(has_image: string): string {
  if (has_image) {
    return getBaseUrl() + 'location/image/' + has_image;
  } else {
    return '/images/default/default-location.png';
  }
}

export function getMessageImage(
  locationId: number,
  messageId: number,
  messageImageId: number
): string {
  return getBaseUrl() + 'message/image/' + messageId + '/' + messageImageId;
}

export function getLogImage(
  locationId: number,
  logId: number,
  logImageId: number
): string {
  return getBaseUrl() + 'log/image/' + logId + '/' + logImageId;
}

export function dateToBeginningOfPayroll(
  startDate: Date,
  loc: IApiLocation
): any {
  let start = moment(startDate);
  let payrollStart = loc.payroll_day;

  if (start.day() != payrollStart) {
    let offset = payrollStart - start.day();
    if (offset > 0) offset -= 7;
    start.add(offset, 'days');
  }
  return start;
}

export function getSignInUrl(token: string): string {
  return (
    window.location.origin + getBaseUrl() + 'user/adminTokenJump?token=' + token
  );
}

export function getPasswordResetUrl(token: string, email: string): string {
  let e = encodeURIComponent(email);
  return (
    window.location.origin + '/manage/reset?email=' + e + '&token=' + token
  );
}

export function isIntegrationEnabled(loc: IApiLocation): boolean {
  return (
    loc.package_type == PackageType.Pro || moment().isBefore(loc.free_until)
  );
}

export function isIntegrationActive(loc: IApiLocation): boolean {
  return (
    (loc.package_type == PackageType.Pro ||
      moment().isBefore(loc.free_until)) &&
    loc.sales_integration_type > 0
  );
}
