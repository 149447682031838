<form ngNativeValidate *ngIf="checklist" (submit)="save()">
  <div class="card">
    <div class="card-body">
      <label>{{ (checklist.id?'Edit':'Add')}} Checklist</label>
      <div class="d-flex flex-wrap flex-row justify-content-between">
        <div>
          <input
            [(ngModel)]="checklist.name"
            type="text"
            maxlength="40"
            style="min-width: 25rem"
            class="form-control override-invalid"
            name="checklist_name"
            placeholder="Add a checklist name"
            required />
        </div>
        <div>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text border-0 bg-white p-0"
                ><b>From&nbsp;</b></span
              >
            </div>
            <span>
              <timepicker
                [(ngModel)]="checklist.start"
                name="checklist_start"
                required></timepicker
            ></span>
          </div>
        </div>
        <div>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text border-0 bg-white p-0"
                ><b>To&nbsp;</b></span
              >
            </div>
            <span>
              <timepicker
                [(ngModel)]="checklist.end"
                name="checklist_end"
                required></timepicker
            ></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="flex-7 px-1">
          <input-toggle [(ngModel)]="checklist.days[0]" name="checklist_0"
            ><small>Sun</small></input-toggle
          >
        </div>
        <div class="flex-7 px-1">
          <input-toggle [(ngModel)]="checklist.days[1]" name="checklist_1"
            ><small>Mon</small></input-toggle
          >
        </div>
        <div class="flex-7 px-1">
          <input-toggle [(ngModel)]="checklist.days[2]" name="checklist_2"
            ><small>Tue</small></input-toggle
          >
        </div>
        <div class="flex-7 px-1">
          <input-toggle [(ngModel)]="checklist.days[3]" name="checklist_3"
            ><small>Wed</small></input-toggle
          >
        </div>
        <div class="flex-7 px-1">
          <input-toggle [(ngModel)]="checklist.days[4]" name="checklist_4"
            ><small>Thu</small></input-toggle
          >
        </div>
        <div class="flex-7 px-1">
          <input-toggle [(ngModel)]="checklist.days[5]" name="checklist_5"
            ><small>Fri</small></input-toggle
          >
        </div>
        <div class="flex-7 px-1">
          <input-toggle [(ngModel)]="checklist.days[6]" name="checklist_6"
            ><small>Sat</small></input-toggle
          >
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <input-toggle
            [(ngModel)]="checklist.has_notification"
            name="has_notification"
            style="max-width: 300px"
            >Send Notifications</input-toggle
          >
        </div>
        <div class="col text-center">
          <input-toggle
            [(ngModel)]="checklist.has_manager_notification"
            name="has_manager_notification"
            style="max-width: 300px"
            >Send Manager Notifications</input-toggle
          >
        </div>
        <div class="col">
          <input-toggle
            [(ngModel)]="checklist.has_assignments"
            (click)="onChangeAssignee(ChecklistAssignmentType.User)"
            name="has_assignments"
            style="max-width: 300px"
            class="float-right"
            >Assign to Specific Users</input-toggle
          >
        </div>
        <div class="col">
          <input-toggle
            [(ngModel)]="checklist.has_position_assignments"
            (click)="onChangeAssignee(ChecklistAssignmentType.Position)"
            name="has_position_assignments"
            style="max-width: 300px"
            class="float-right"
            >Assign to Specific Position</input-toggle
          >
        </div>
      </div>

      <div class="row mt-2" *ngIf="checklist.has_assignments && users">
        <div class="col-md-2 col-12" *ngFor="let u of users">
          <input-toggle [(ngModel)]="u._checked" [name]="'users_'+u.id"
            >{{ u.firstname }} {{ u.lastname }}</input-toggle
          >
        </div>
      </div>
      <div
        class="row mt-2"
        *ngIf="checklist.has_position_assignments && positions">
        <div class="col-md-2 col-12" *ngFor="let p of positions">
          <input-toggle [(ngModel)]="p._checked" [name]="'position_'+p.id"
            ><span [style.color]="p.color">{{ p.name }}</span></input-toggle
          >
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="checklistItems">
    <div class="card-body">
      <label>Add a Checklist Item</label>
      <div class="row">
        <form (submit)="saveObj(newItem,true)" ngNativeValidate class="col">
          <div class="input-group mb-0">
            <input
              [(ngModel)]="newItem.name"
              type="text"
              name="new_name"
              class="form-control"
              placeholder="Add a checklist Item"
              required />
            <select
              [(ngModel)]="newItem.checklist_item_type"
              style="max-width: 20rem"
              type="text"
              name="new_item_type"
              class="form-control"
              required>
              <option *ngFor="let k of ChecklistItemTypeKeys" [value]="k">
                {{ChecklistItemTypeName[k]}}
              </option>
            </select>
            <input
              type="number"
              *ngIf="newItem.checklist_item_type==ChecklistItemType.Number"
              [(ngModel)]="newItem.min"
              step="0.01"
              required
              class="form-control"
              name="new_min"
              placeholder="Min"
              style="max-width: 5rem" />
            <input
              type="number"
              *ngIf="newItem.checklist_item_type==ChecklistItemType.Number"
              [(ngModel)]="newItem.max"
              step="0.01"
              required
              class="form-control"
              name="new_max"
              placeholder="Max"
              style="max-width: 5rem" />
            <div class="input-group-append">
              <button class="btn btn-icon bg-white" type="submit">
                <span class="cdi-s-add"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="row">
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="newItem.days[0]" name="new_sun"
            ><small>Sun</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="newItem.days[1]" name="new_mon"
            ><small>Mon</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="newItem.days[2]" name="new_tue"
            ><small>Tue</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="newItem.days[3]" name="new_wed"
            ><small>Wed</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="newItem.days[4]" name="new_thu"
            ><small>Thu</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="newItem.days[5]" name="new_fri"
            ><small>Fri</small></input-toggle
          >
        </div>
        <div class="flex-7 p-1">
          <input-toggle [(ngModel)]="newItem.days[6]" name="new_sat"
            ><small>Sat</small></input-toggle
          >
        </div>
      </div>
      Checklist items are active only on days the checklist is active. All on
      assures the item is included if the checklist days change.
    </div>
  </div>

  <div class="card" *ngIf="checklistItems && checklistItems.length>0">
    <label class="ml-2 mt-3">Checklist Items</label>
    <div class="card-body" [dragula]="'checklist_item'">
      <div
        *ngFor="let d of checklistItems | orderBy: 'order_by'"
        [attr.data-id]="d.id">
        <div class="row no-gutters body-bg" *ngIf="!d._edit">
          <div class="col-1">
            <span class="cdi-s-reorder pointer"></span>
          </div>
          <div class="col-11 mt-2">
            {{d.name}}
            <span
              class="cdi-s-trash float-right pointer"
              (click)="deleteObj(d)"></span>
            <span
              class="cdi-s-edit float-right pointer"
              (click)="editObj(d)"></span>
          </div>
        </div>

        <form
          ngNativeValidate
          (submit)="saveObj(d,false)"
          class="row no-gutters body-bg"
          *ngIf="d._edit">
          <div class="col-9 input-group">
            <input
              [(ngModel)]="d.name"
              [name]="'name_'+d.id"
              type="text"
              class="form-control"
              required />
            <select
              [(ngModel)]="d.checklist_item_type"
              style="max-width: 20rem"
              type="text"
              [name]="'item_type_'+d.id"
              class="form-control"
              required>
              <option *ngFor="let k of ChecklistItemTypeKeys" [value]="k">
                {{ChecklistItemTypeName[k]}}
              </option>
            </select>
            <input
              type="number"
              *ngIf="d.checklist_item_type==ChecklistItemType.Number"
              [(ngModel)]="d.min"
              step="0.01"
              required
              class="form-control"
              [name]="'min_'+d.id"
              placeholder="Min"
              style="max-width: 5rem" />
            <input
              type="number"
              *ngIf="d.checklist_item_type==ChecklistItemType.Number"
              [(ngModel)]="d.max"
              step="0.01"
              required
              class="form-control"
              [name]="'max_'+d.id"
              placeholder="Max"
              style="max-width: 5rem" />
          </div>
          <div class="col-2">
            <select
              [(ngModel)]="d.checklist_id"
              [name]="'new_cl_'+d.id"
              class="form-control">
              <option *ngFor="let c of checklists" [value]="c.id">
                {{c.name}}
              </option>
            </select>
          </div>
          <div class="col-1">
            <span
              *ngIf="d._edit"
              class="cdi-s-cancel float-right pointer"
              (click)="cancelEdit(d)"></span>
            <button type="submit" class="float-right" style="all: unset">
              <span
                *ngIf="d._edit"
                class="cdi-s-save pointer"
                (click)="saveObj(d,false)"></span>
            </button>
          </div>
        </form>

        <div class="row">
          <div class="flex-7 p-1">
            <input-toggle
              [(ngModel)]="d.days[0]"
              [disabled]="!d._edit"
              [name]="'sun_'+d.id"
              ><small>Sun</small></input-toggle
            >
          </div>
          <div class="flex-7 p-1">
            <input-toggle
              [(ngModel)]="d.days[1]"
              [disabled]="!d._edit"
              [name]="'mon_'+d.id"
              ><small>Mon</small></input-toggle
            >
          </div>
          <div class="flex-7 p-1">
            <input-toggle
              [(ngModel)]="d.days[2]"
              [disabled]="!d._edit"
              [name]="'tue_'+d.id"
              ><small>Tue</small></input-toggle
            >
          </div>
          <div class="flex-7 p-1">
            <input-toggle
              [(ngModel)]="d.days[3]"
              [disabled]="!d._edit"
              [name]="'wed_'+d.id"
              ><small>Wed</small></input-toggle
            >
          </div>
          <div class="flex-7 p-1">
            <input-toggle
              [(ngModel)]="d.days[4]"
              [disabled]="!d._edit"
              [name]="'thu_'+d.id"
              ><small>Thu</small></input-toggle
            >
          </div>
          <div class="flex-7 p-1">
            <input-toggle
              [(ngModel)]="d.days[5]"
              [disabled]="!d._edit"
              [name]="'fri_'+d.id"
              ><small>Fri</small></input-toggle
            >
          </div>
          <div class="flex-7 p-1">
            <input-toggle
              [(ngModel)]="d.days[6]"
              [disabled]="!d._edit"
              [name]="'sat_'+d.id"
              ><small>Sat</small></input-toggle
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <cancel-button class="float-right" (click)="cancel()"></cancel-button>
      <save-button class="float-right mr-2"></save-button>
    </div>
  </div>
</form>
