import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IUser,
  SelectAddAll,
} from '../services/account.service';
import {
  ItemCategoryService,
  IItemCategory,
} from '../services/itemCategory.service';
import { ItemService, IItem } from '../services/item.service';
import {
  ItemVariationService,
  IItemVariation,
} from '../services/itemVariation.service';
import { getItemImage } from '../shared/api';
import { baseRole } from '../shared/baseRole';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './menu.html',
})
export class MenuComponent extends baseRole implements OnInit {
  private _data: IItem[];
  public data;
  public itemCategories: IItemCategory[];
  public currentItemCategoryId: number = null;
  public selectedItem: any;

  @ViewChild('moreInfoModalSelector')
  public moreInfoModal: ModalDirective;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected itemService: ItemService,
    protected itemCategoryService: ItemCategoryService,
    protected itemVariationService: ItemVariationService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.ManageInventory)) {
      if (!this.isAllowed(this.Permission.CountInventory)) {
        this.router.navigate(['/']);
      } else this.router.navigate(['/inventory/take']);
      return;
    }
    this.itemService.list().subscribe(data => {
      this._data = data;
      this.filter();
    });
    this.loadCategories();
  }

  onItemChange(obj) {
    let match = this.data.filter(e => {
      return e.id == obj.id;
    });
    if (match.length == 0) {
      this._data.push(obj);
    } else {
      this.open(match[0], true);
    }
    this.filter();
  }

  public getItemImage(item: any): string {
    return getItemImage(item.has_image);
  }
  deleteItem(obj) {
    this.itemService.delete(obj.id).subscribe(data => {
      let idx = this._data.indexOf(obj);
      this._data.splice(idx, 1);
      this.filter();
    });
  }

  loadCategories() {
    this.itemCategoryService.list().subscribe(data => {
      this.itemCategories = data;
      SelectAddAll(this.itemCategories, 'All Categories', 'name');
    });
  }

  open(item, load) {
    if (!load) return;
    this.itemVariationService.list({ item_id: item.id }).subscribe(data => {
      item.variations = data;
    });
  }

  setSelectedItemVariation(id: number) {
    this.open(this.data[id], true);
  }

  filter() {
    //console.log(this.currentItemCategoryId);
    if (!this.currentItemCategoryId) {
      this.data = this._data;
      //	console.log('All');
      return;
    }
    //console.log('Filtering');
    this.data = this._data.filter(e => {
      return this.currentItemCategoryId == e.item_category_id;
    });
  }
}
