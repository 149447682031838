<div *ngIf="userMap">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <label>Work Preferences</label>
          <shift-preferences [(ngModel)]="userMap.pref_dna"></shift-preferences>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2" *ngIf="saveMessage">
    <div class="col-12">
      <alert type="success"> <strong>Save</strong> - {{saveMessage}} </alert>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <save-button class="float-right" (click)="save()"></save-button>
    </div>
  </div>
</div>
