<div
  bsModal
  #adjustModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Adjust Estimated Sales
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancelAdjust()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <ul class="list-group text-center text-white">
          <li class="list-group-item blue-bg">
            <div style="font-size: 40px">
              {{ weekTotal | currency:'USD' }}
              <span style="font-size: 0.8rem"
                >{{percent| percent: '1.2-2'}}</span
              >
            </div>
            <span class="mb-0"
              >LY:{{LY[7]|currency:'USD'}}<br />LW:{{LW[7]|currency:'USD'}}
            </span>
          </li>
        </ul>
        <ul class="list-group text-center">
          <li class="list-group-item">
            <label>ESTIMATED SALES</label>
          </li>
        </ul>

        <div
          class="row my-2"
          *ngFor="let d of goals | orderBy: ['date']; let i = index">
          <div class="col-md-8 align-self-center">
            <div class="row">
              <div class="col align-self-center">
                <b> {{ d.date | date: 'EEEE, MMM d'}} </b>
                <!--  			<b class='text-dark'>{{DaysOfWeek[d]}} {{dayDate | date}}</b> -->
              </div>
              <div class="col align-self-center">
                <span class="text-muted">LY {{LY[i] | currency:'USD'}}</span>
                <span class="text-muted">
                  &nbsp; &nbsp; LW {{LW[i] | currency:'USD'}}</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                type="number"
                class="form-control text-right"
                min=".01"
                step=".01"
                [(ngModel)]="d.goal"
                (ngModelChange)="amtChange()" />
              <div class="input-group-append">
                <span
                  class="cdi-m-arrow-up pointer"
                  (click)="upOnePC(d)"></span>
                <span class="input-group-text body-bg border-0">1%</span>
                <span
                  class="cdi-m-arrow-down pointer"
                  (click)="downOnePC(d)"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="row my-2">
          <div class="col-md-8 align-self-center">
            <div class="row">
              <div class="col align-self-center">
                <b class="text-dark">Whole Week </b>
              </div>
              <div class="col align-self-center">
                <span class="text-muted">LY {{LY[7] | currency:'USD'}}</span>
                <span class="text-muted">
                  &nbsp; &nbsp; LW {{LW[7] | currency:'USD'}}</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                type="number"
                disabled
                class="form-control text-right"
                min=".01"
                step=".01"
                [(ngModel)]="weekTotal" />
              <div class="input-group-append">
                <span
                  class="cdi-m-arrow-up pointer"
                  (click)="upOnePC(7)"></span>
                <span class="input-group-text body-bg border-0">1%</span>
                <span
                  class="cdi-m-arrow-down pointer"
                  (click)="downOnePC(7)"></span>
              </div>
            </div>
          </div>
        </div>
        <p>
          Estimated sales use the last 4 weeks sales, as compared to your sales
          from the same time period from last year, to come with up with a
          suggested sales amount. It is strongly suggested you review these
          suggestions carefully and adjust your estimated sales to fit your
          business.
        </p>
        <div class="row mt-2">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="cancelAdjust()"></cancel-button>
            <save-button
              class="float-right"
              (click)="saveAdjust()"></save-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #reviewModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Review Sales"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">Review Estimated Sales</div>
      </div>

      <div class="modal-body" *ngIf="LW">
        <ul class="list-group text-center text-white">
          <li class="list-group-item blue-bg">
            <div style="font-size: 40px">
              {{ weekTotal | currency: 'USD'}}
              <span style="font-size: 0.8rem" *ngIf="LW[7]>0.01"
                >{{ (weekTotal/LW[7])*100 | number}} %</span
              >
            </div>
            <span class="mb-0">
              LY: {{ LY[7] | currency: 'USD' }}<br />
              LW: {{ LW[7] | currency: 'USD' }}
            </span>
          </li>
        </ul>
        <ul class="list-group text-center">
          <li class="list-group-item">
            <label>ESTIMATED SALES</label>
          </li>
        </ul>

        <div class="alert alert-warning" role="alert">
          Before publishing a schedule you must review the estimated sales for
          the week.
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <div class="d-flex justify-content-center">
              <button class="btn btn-primary mx-2" (click)="showAdjust()">
                Adjust
              </button>
              <button class="btn btn-primary mx-2" (click)="saveReview()">
                Accept
              </button>
              <button class="btn btn-secondary mx-2" (click)="cancelReview()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
