import { Component, ViewChild, OnInit, Input, Output } from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { ReviewService, IReview } from '../services/review.service';
import { WriteUpService, IWriteUp } from '../services/writeUp.service';
import { getBaseUrl } from '../shared/api';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-review-writeup',
  templateUrl: 'modalReWriteup.html',
})
export class ModalReWriteupComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;

  public userId: number;
  public isReview = true;
  public text: string;
  public reviews: any[];
  public writeups: any[];
  public data: any[];
  public baseUrl = getBaseUrl();

  constructor(
    public accountService: AccountService,
    public writeUpService: WriteUpService,
    public reviewService: ReviewService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  changeTab(isReview: boolean) {
    this.text = null;
    this.isReview = isReview;
    this.data = this.isReview ? this.reviews : this.writeups;
  }

  load() {
    this.reviewService.list({ user_id: this.userId }).subscribe(data => {
      this.reviews = data;
      if (this.isReview) {
        this.data = data;
        this.modal.show();
      }
    });
    this.writeUpService.list({ user_id: this.userId }).subscribe(data => {
      this.writeups = data;
      if (!this.isReview) {
        this.data = data;
        this.modal.show();
      }
    });
  }
  show(userId: number) {
    this.userId = userId;
    this.text = null;
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  deleteObj(obj) {
    if (this.isReview) {
      this.reviewService.delete(obj.id).subscribe(data => {
        let idx = this.reviews.indexOf(obj);
        this.reviews.splice(idx, 1);
      });
    } else {
      this.writeUpService.delete(obj.id).subscribe(data => {
        let idx = this.writeups.indexOf(obj);
        this.writeups.splice(idx, 1);
      });
    }
  }

  save() {
    if (this.isReview) {
      this.reviewService
        .update({ user_id: this.userId, body: this.text })
        .subscribe(data => {
          //	this.modal.hide();
          this.show(this.userId);
        });
    } else {
      this.writeUpService
        .update({ user_id: this.userId, body: this.text })
        .subscribe(data => {
          //	this.modal.hide();
          this.show(this.userId);
        });
    }
  }
}
