import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-preferences',
  templateUrl: 'modalPreferences.html',
})
export class ModalPreferencesComponent extends baseRole {
  @ViewChild('modal')
  public modal: ModalDirective;
  public map: ILocationUserMap;

  constructor(
    public accountService: AccountService,
    public locationUserMapService: LocationUserMapService
  ) {
    super(accountService);
  }

  load() {
    let userId = this.accountService.getUserId();

    this.locationUserMapService
      .list({
        user_id: userId,
        location_id: this.accountService.getLocationId(),
      })
      .subscribe(data => {
        if (data.length == 1) {
          this.map = data[0];
          this.modal.show();
        }
      });
  }

  show() {
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    this.locationUserMapService
      .update({ id: this.map.id, pref_dna: this.map.pref_dna })
      .subscribe(data => {
        this.modal.hide();
      });
  }
}
