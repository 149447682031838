import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard } from '../app/auth-guard';

import { AccountComponent } from './account.component';
import { DashboardComponent } from './dashboard.component';
import { DocComponent } from './doc.component';
import { ProfileComponent } from './profile.component';
import { ProfileEditComponent } from './profileEdit.component';
import { ReWriteupComponent } from './rewriteup.component';
import { WorkPrefsComponent } from './workPrefs.component';

const appRoutes: Routes = [
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
    children: [
      { path: '', component: AccountComponent },
      { path: 'profile', component: ProfileEditComponent },
      { path: 'scheduling', component: WorkPrefsComponent },
      { path: 'docs', component: DocComponent },
      { path: 'reviews', component: ReWriteupComponent },
      { path: 'dashboard', component: DashboardComponent },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
