import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IPermissionLocation } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class PermissionLocationService extends BaseService<IPermissionLocation> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'permissionLocation');
  }

  reset(locationId: number): Observable<IPermissionLocation[]> {
    return this.http.post<IPermissionLocation[]>(
      this.baseUrl + 'reset',
      { location_id: locationId },
      this.defaultOptions
    );
  }
}

export {
  IPermissionLocation,
  Permission,
  PermissionCategory,
  PermissionNames,
} from '../shared/api';
