import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { AccountService } from './account.service';
import { IBillingPlan } from '../shared/api';

@Injectable()
export class BillingPlanService extends BaseService<IBillingPlan> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'billingPlan');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'date_end');
        }
      } else {
        this.fixDate(data, 'date_end');
      }
      return data;
    };
  }

  getByCode(code: string): Observable<IBillingPlan> {
    return this.http.get<IBillingPlan>(
      this.baseUrl + 'code/' + encodeURIComponent(code)
    );
  }

  getDefault(): Observable<IBillingPlan> {
    return this.http.get<IBillingPlan>(this.baseUrl + 'default');
  }

  getForLocation(): Observable<IBillingPlan> {
    return this.http.get<IBillingPlan>(this.baseUrl + 'location');
  }
}

export { IBillingPlan } from '../shared/api';
