<form class="row" (submit)="save()" ngNativeValidate *ngIf="data">
  <div class="col-12">
    <h2 class="m-4">Add Location</h2>

    <div class="card-body">
      <div class="row">
        <div class="col-md-4 col-xs-12">
          <div class="card mb-0">
            <div class="card-body">
              <label>Business Name</label>
              <input
                type="text"
                name="business_name"
                maxlength="50"
                placeholder="Business Name"
                [(ngModel)]="data.name"
                class="form-control"
                required />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12">
          <div class="card mb-0">
            <div class="card-body">
              <label>Business Type</label>
              <select
                name="location_type"
                [(ngModel)]="data.location_type"
                class="form-control"
                required>
                <option *ngFor="let t of LocationTypeKeys" [value]="t">
                  {{LocationTypeName[t]}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12">
          <div class="card mb-0">
            <div class="card-body">
              <label>Category</label>
              <select
                name="category"
                [(ngModel)]="data.location_category"
                class="form-control"
                required>
                <option
                  *ngFor="let c of LocationCategoryName[data.location_type]"
                  [value]="c">
                  {{c}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-xs-12">
          <div class="card mb-0">
            <div class="card-body">
              <label>Country</label>
              <select
                name="country"
                class="form-control"
                [(ngModel)]="data.country"
                required>
                <option *ngFor="let c of CountryKeys" [value]="c">
                  {{Countries[c]}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12">
          <div class="card mb-0">
            <div class="card-body">
              <label *ngIf="data.country=='US' || data.country==''"
                >Zip Code</label
              >
              <input
                *ngIf="data.country=='US' || data.country==''"
                type="text"
                name="zipcode"
                placeholder="Zip Code"
                [(ngModel)]="data.zip"
                class="form-control"
                required
                pattern="[0-9]{5}"
                maxlength="5" />
              <label *ngIf="data.country=='CA'">Postal Code</label>
              <input
                *ngIf="data.country=='CA'"
                type="text"
                name="zipcode"
                placeholder="Postal Code"
                [(ngModel)]="data.zip"
                class="form-control"
                required
                maxlength="7" />
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12">
          <div class="card mb-0">
            <div class="card-body">
              <label>Timezone</label>
              <select
                name="timezone_id"
                class="form-control"
                [(ngModel)]="data.timezone_id"
                required>
                <option *ngFor="let t of timezones" [value]="t.id">
                  {{t.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label>Customize your setup</label>
          <div class="row m-3">
            <div class="card-subtitle pt-3 pb-1">
              How would you like to track your sales?
            </div>
            <div class="row">
              <div class="col">
                <div class="btn-group-toggle ml-3">
                  <label
                    class="btn btn-radio text-nowrap"
                    [class.active]="data.is_service_time_daily">
                    <input
                      type="radio"
                      name="options1"
                      [value]="true"
                      [(ngModel)]="data.is_service_time_daily" />By Entire Day
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio text-nowrap"
                    [class.active]="!data.is_service_time_daily">
                    <input
                      type="radio"
                      name="options1"
                      [value]="false"
                      [(ngModel)]="data.is_service_time_daily" />By Service Time
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-3">
            <div class="card-subtitle pt-3 pb-0">
              What day of the week does your schedule start?
            </div>
            <div class="row">
              <select
                class="form-control d-inline-block ml-3"
                name="day_start"
                [(ngModel)]="data.week_start">
                <option value="0" selected>Sunday</option>
                <option value="1">Monday</option>
                <option value="2">Tuesday</option>
                <option value="3">Wednesday</option>
                <option value="4">Thursday</option>
                <option value="5">Friday</option>
                <option value="6">Saturday</option>
              </select>
            </div>
          </div>
          <div class="row m-3">
            <div class="card-subtitle pt-3 pb-0">
              What day of the week is your payroll calculated from?
            </div>
            <div class="row">
              <select
                class="form-control d-inline-block ml-3"
                name="payroll_day"
                [(ngModel)]="data.payroll_day">
                <option value="0" selected>Sunday</option>
                <option value="1">Monday</option>
                <option value="2">Tuesday</option>
                <option value="3">Wednesday</option>
                <option value="4">Thursday</option>
                <option value="5">Friday</option>
                <option value="6">Saturday</option>
              </select>
            </div>
          </div>
          <div class="row m-3">
            <div class="card-subtitle pt-2 pb-1">
              Does your establishment use Front of House and Back of House? (not
              common for small quick service types)
            </div>
            <div class="row">
              <div class="col">
                <div class="btn-group-toggle ml-3">
                  <label class="btn btn-radio" [class.active]="data.has_houses">
                    <input
                      type="radio"
                      name="has_houses"
                      [value]="true"
                      [(ngModel)]="data.has_houses" />Yes
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="!data.has_houses">
                    <input
                      type="radio"
                      name="options1"
                      [value]="false"
                      [(ngModel)]="data.has_houses" />No
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!--<div class= 'row m-3'>
						<div class='card-subtitle pt-3 pb-1'>Should your staff complete and sign W4 and I9 tax documents online prior to using CheddrSuite? </div>
						<div class="row">
							<div class="col">
								<div class="btn-group-toggle  ml-3">
									<label class="btn btn-radio" [class.active]="needsDocs">
										<input type="radio" name="needsDocs" [value]='true' [(ngModel)]="taxbutton">Yes
									</label>
								</div>
							</div>
							<div class="col">
								<div class="btn-group-toggle">
									<label class="btn btn-radio" [class.active]="!needsDocs">
										<input type="radio" name="needsDocs" [value]='false' [(ngModel)]="taxbutton">No
									</label>
								</div>
							</div>
						</div>
					</div>-->

          <div class="row m-3">
            <div class="card-subtitle pt-3 pb-1">
              Would you like to have your staff report their tips?
            </div>
            <div class="row">
              <div class="col">
                <div class="btn-group-toggle ml-3">
                  <label
                    class="btn btn-radio"
                    [class.active]="data.has_tip_reporting">
                    <input
                      type="radio"
                      name="tip_reporting"
                      [value]="true"
                      [(ngModel)]="data.has_tip_reporting" />Yes
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="!data.has_tip_reporting">
                    <input
                      type="radio"
                      name="tip_reporting"
                      [value]="false"
                      [(ngModel)]="data.has_tip_reporting" />No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--<input-toggle name="toc" [(ngModel)]="toc" *ngIf="data.user_id">I Agree To Terms &amp; Conditions</input-toggle>-->
      <hr />
      <label
        >Would you like to be contacted by an onboarding specialist to help you
        get set up? &nbsp; &nbsp;It's free!
      </label>
      <select
        class="form-control d-inline-block"
        name="contact_method"
        [(ngModel)]="data.contact_method">
        <option value="0" selected>Yes! I would like phone support.</option>
        <option value="1">Yes! I would like email support.</option>
        <option value="2">
          Yes! I'm interested in having CheddrSuite set up my entire account for
          me (Fees apply)
        </option>
        <option value="3">No. I'll get set up on my own.</option>
      </select>

      <global-error [error]="error"></global-error>

      <div class="row mt-3" *ngIf="!saving">
        <div class="col-sm-12">
          <cancel-button class="float-right" (click)="cancel()"></cancel-button>
          <button
            [disabled]="!billingPlan"
            class="btn btn-primary float-right mr-3">
            Create
          </button>
        </div>
      </div>

      <alert type="success" *ngIf="saving">
        Creating your business. This may take a few seconds.
      </alert>
    </div>
  </div>
</form>
<div class="mx-4">
  <h4>Frequently asked questions</h4>

  <h5>Do you have a refund policy?</h5>
  <p>
    Yes. You may cancel your subscription within 14 days after purchase for a
    full refund, less the cost of any services provided prior to cancellation.
    Requests must be submitted in writing. View our Terms of Service.
  </p>

  <h5>Will you ever look at my data or share it with anyone?</h5>
  <p>
    Cheddrsuite will only access your data with your express permission: e.g.,
    to assist with tech support questions. For analytical purposes, we will
    occasionally compile data from all of our subscribers. However, that data
    cannot be traced back to any individual subscriber's account.
  </p>

  <h5>Can I import data from another software?</h5>
  <p>
    Yes, we provide data conversion from your previous system if you are
    converting data from an established software product or POS. Custom
    installations and data conversion packages usually cost about $500. Please
    contact us for a custom quote.
  </p>

  <h5>Is my data safe and secure?</h5>
  <p>
    Yes, data security is our highest priority. We employ numerous, redundant
    data safety systems and follow strict PCI compliance procedures to ensure
    that all of our subscribers' data receives the highest level of protection
    possible.
  </p>

  <h5>What are your technical support hours?</h5>
  <p>
    Cheddrsuite provides unlimited tech support for the life of your
    subscription. Our support staff is available Weekdays from 8:00 am to 5:00
    pm PST to answer your questions. Also, our Support Desk provides contact
    information and is updated daily with online support resources.
  </p>
</div>
