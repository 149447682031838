import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras,
  CanLoad,
  Route,
} from '@angular/router';
import { AccountService, UserType } from '../services/account.service';

const anonymousUrls = ['/login', '/register'];

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  isLoggedIn() {
    return this.accountService.isLoggedIn();
  }
  canLoad(route: Route): boolean {
    //console.log('Can load: '+route.path);
    let url = route.path;
    return this.checkLogin(url);
  }

  redirect() {
    //console.log('redirecting');
    if (this.isLoggedIn()) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    //console.log('Can activate: '+route.routeConfig.path);
    //console.log(route.routeConfig);
    var res = this.checkLogin('/' + route.routeConfig.path);
    if (!res) {
      this.redirect();
    }
    return res;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    //console.log(route.routeConfig);
    //console.log('Child: '+route.routeConfig.path);
    var res = this.canActivate(route, state);
    /*if(!res) {
			this.redirect(route.routeConfig.path);
		}*/
    return res;
  }

  checkLogin(url: string): boolean {
    let result = anonymousUrls.filter(e => {
      return url.startsWith(e);
    });
    let hitAnonymousUrl = result.length > 0;

    //console.log('Check Login: '+this.isLoggedIn()+" "+hitAnonymousUrl);
    if (this.isLoggedIn()) {
      return !hitAnonymousUrl;
    } else {
      return hitAnonymousUrl;
    }
  }
}

@Injectable()
export class AuthGuardAdmin implements CanLoad, CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  isAdmin() {
    return this.accountService.getUser().is_super;
  }

  canLoad(route: Route): boolean {
    return this.isAdmin();
  }

  redirect(url: any[]) {
    this.router.navigate(url);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.isAdmin()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
}

@Injectable()
export class AuthGuardFreemium implements CanLoad, CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  isFreemium() {
    if (!this.accountService.isLoggedIn()) return true;
    return this.accountService.isFreemium();
  }

  canLoad(route: Route): boolean {
    //console.log('Can Load: '+this.isFreemium());
    if (this.isFreemium()) {
      this.redirect();
      return false;
    }
    return true;
  }

  redirect() {
    //console.log('Redirecting');
    this.router.navigate(['/freemium']);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    //console.log('Can Activate: '+this.isFreemium());
    if (this.isFreemium()) {
      this.redirect();
      return false;
    }
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
}

@Injectable()
export class AuthGuardJail implements CanLoad, CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  inJail(url: string): boolean {
    //console.log('Checking: '+url);
    if (url.startsWith('/settings/account')) {
      return false;
    }
    return this.accountService.inJail();
  }

  canLoad(route: Route): boolean {
    //console.log('Jail Load Check: '+this.inJail());
    if (this.inJail('/' + route.path)) {
      this.redirect();
      return false;
    }
    return true;
  }

  redirect() {
    this.router.navigate(['/profile']);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    //console.log('Jail Activate Check: /'+route.routeConfig.path+' => '+this.inJail('/'+route.routeConfig.path));
    if (this.inJail('/' + route.routeConfig.path)) {
      this.redirect();
      return false;
    }
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
}
