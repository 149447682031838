import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardJail } from './auth-guard';

import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgotPassword.component';
import { RegisterComponent } from './register.component';
import { VerifyComponent } from './verify.component';
import { PasswordResetComponent } from './passwordReset.component';
import { FreemiumPageComponent } from './freemiumPage.component';

import { HomeComponent } from '../home.component';
import { TimeclockComponent } from '../timeclock.component';
import { SearchComponent } from '../search.component';

const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        canActivate: [AuthGuard, AuthGuardJail],
        component: HomeComponent,
      },
      {
        path: 'search/:query',
        canActivate: [AuthGuard, AuthGuardJail],
        component: SearchComponent,
      },
      { path: 'freemium', component: FreemiumPageComponent },

      { path: 'login', component: LoginComponent },
      { path: 'timeclock', component: TimeclockComponent },
      { path: 'signup', component: RegisterComponent },
      { path: 'verify', component: VerifyComponent },
      { path: 'reset', component: PasswordResetComponent },
      { path: 'forgot', component: ForgotPasswordComponent },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  appRoutes,
  {
    scrollPositionRestoration: 'enabled',
  }
);
