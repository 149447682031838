import { IconsModule } from './../app/icons/icons.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';
import { PublishLogService } from '../services/publishLog.service';
import { SalesGoalService } from '../services/salesGoal.service';
import { ShiftService } from '../services/shift.service';
import { ShiftHistoryService } from '../services/shiftHistory.service';
import { ShiftTemplateService } from '../services/shiftTemplate.service';
import { ShiftTemplateItemService } from '../services/shiftTemplateItem.service';

import { HomeComponent } from './home.component';
import { ModalAddShiftComponent } from './modalAddShift.component';
import { ModalEditShiftComponent } from './modalEditShift.component';
import { ModalAdjustSalesComponent } from './modalAdjustSales.component';
import { ModalFilterShiftComponent } from './modalFilterShift.component';
import { ModalLaborStatComponent } from './modalLaborStat.component';
import { ModalTemplateLoadComponent } from './modalTemplateLoad.component';
import { ModalTemplateManagerComponent } from './modalTemplateManager.component';
import { ScheduleComponent } from './schedule.component';

import { routing } from './schedule.routing';

@NgModule({
  declarations: [
    HomeComponent,
    ModalAddShiftComponent,
    ModalEditShiftComponent,
    ModalAdjustSalesComponent,
    ModalFilterShiftComponent,
    ModalLaborStatComponent,
    ModalTemplateLoadComponent,
    ModalTemplateManagerComponent,
    ScheduleComponent,
  ],
  imports: [SharedModule, routing, IconsModule],
  providers: [
    PublishLogService,
    SalesGoalService,
    ShiftService,
    ShiftHistoryService,
    ShiftTemplateService,
    ShiftTemplateItemService,
  ],
})
export class ScheduleModule {}
