import { Component, ViewChild, OnInit, Input, Output } from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-send-unverified',
  templateUrl: 'modalSendUnverified.html',
})
export class ModalSendUnverifiedComponent extends baseRole implements OnInit {
  public data: IUser[];
  public saving = false;

  @ViewChild('modal')
  public modal: ModalDirective;

  constructor(
    public accountService: AccountService,
    public userService: UserService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  show() {
    this.load();
  }

  load() {
    this.userService.listUnverified().subscribe(data => {
      this.data = data;
    });
    this.modal.show();
  }

  save() {
    let userList: number[] = [];
    for (let d of this.data) {
      userList.push(d.id);
    }
    this.saving = true;
    this.userService.sendUnverified(userList).subscribe(data => {
      setTimeout(() => {
        this.saving = false;
        this.modal.hide();
      }, 3000);
    });
  }

  cancel() {
    this.modal.hide();
  }
}
