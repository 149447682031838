import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import {
  AccountService,
  IUser,
  IApiLocation,
} from '../services/account.service';
import {
  LogService,
  ILog,
  ServiceTimeType,
  DateMenuType,
} from '../services/log.service';
import {
  LogNoteService,
  ILogNote,
  LogNoteType,
} from '../services/logNote.service';
import { LogImageService, ILogImage } from '../services/logImage.service';
import { MediaService } from '../services/media.service';
import { TimeClockService } from '../services/timeClock.service';
import { CustomLogFieldService } from '../services/customLogField.service';
import {
  ServiceTimeService,
  IServiceTime,
} from '../services/serviceTime.service';
import {
  moment,
  PositionType,
  DocMimeTypes,
  LocationLaborType,
  getLogImage,
} from '../shared/api';

@Component({
  templateUrl: './logAdd.html',
})
export class LogAddComponent extends baseRole implements OnInit, OnDestroy {
  public saveMessage: string = null;
  public LogNoteType = LogNoteType;
  public LocationLaborType = LocationLaborType;
  public service_time_type: ServiceTimeType;
  public serviceTimeName: string;
  public serviceTime: IServiceTime;
  public sub: any;
  public date: string;
  public customs: any[];
  public createRepair = false;
  public loc: IApiLocation;
  public logNotes: ILogNote[];
  public log: any;
  public notes: string[] = [];
  public files: any[] = [[], [], [], [], []];
  public fileError = [null, null, null, null, null];
  public timeCards: any;
  public PositionType = PositionType;

  @ViewChild('generalFile')
  public generalFile: ElementRef;
  @ViewChild('feedbackFile')
  public feedbackFile: ElementRef;
  @ViewChild('issueFile')
  public issueFile: ElementRef;
  @ViewChild('repairFile')
  public repairFile: ElementRef;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected serviceTimeService: ServiceTimeService,
    protected logService: LogService,
    protected logNoteService: LogNoteService,
    protected logImageService: LogImageService,
    protected mediaService: MediaService,
    protected customLogFieldService: CustomLogFieldService,
    protected timeClockService: TimeClockService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.LogShiftData)) {
      this.router.navigate(['/log']);
      return;
    }

    this.route.params.subscribe(params => {
      const timespan = params['timespan'];
      this.loc = this.accountService.getLocation();
      switch (timespan) {
        case 'breakfast':
          this.service_time_type = ServiceTimeType.Breakfast;
          this.serviceTimeName = 'Breakfast Log';
          break;
        case 'lunch':
          this.service_time_type = ServiceTimeType.Lunch;
          this.serviceTimeName = 'Lunch Log';
          break;
        case 'dinner':
          this.service_time_type = ServiceTimeType.Dinner;
          this.serviceTimeName = 'Dinner Log';
          break;
        case 'daily':
          this.service_time_type = ServiceTimeType.Daily;
          this.serviceTimeName = 'Day Log';
          break;
      }
      this.sub = this.accountService
        .getCurrentDateUpdateObservable(DateMenuType.Daily)
        .subscribe(date => {
          if (!date) return;
          this.saveMessage = null;
          this.date = moment(date).format('YYYY-MM-DD');
          this.serviceTimeService
            .list({ service_time_type: this.service_time_type })
            .subscribe(data => {
              this.serviceTime = data[0];
              this.load();
            });
          this.loadLaborCosts();
        });
    });
  }

  handleFileChange(which: number, files) {
    this.files[which] = files;
    this.checkFiles(which, this.files[which]);
  }

  imageUrl(c): string {
    return getLogImage(this.getLocationId(), c.log_note_id, c.id);
  }

  getImageView(c) {
    return {
      mime_type: c.mime_type,
      url: this.imageUrl(c),
    };
  }

  checkFiles(which, files) {
    this.fileError[which] = null;
    if (!files || files.length == 0) return true;
    for (let f of files) {
      if (DocMimeTypes.indexOf(f.type) < 0) {
        this.fileError[which] = 'Upload must be an image or pdf file';
        this.files[which] = null;
        switch (which) {
          case LogNoteType.General:
            this.generalFile.nativeElement.value = '';
            break;
          case LogNoteType.Feedback:
            this.feedbackFile.nativeElement.value = '';
            break;
          case LogNoteType.Issues:
            this.issueFile.nativeElement.value = '';
            break;
          case LogNoteType.Repair:
            this.repairFile.nativeElement.value = '';
            break;
        }
        setTimeout(() => {
          this.fileError[which] = null;
        }, 5000);
        return false;
      }
    }
    return true;
  }

  resetFiles() {
    if (this.generalFile && this.generalFile.nativeElement)
      this.generalFile.nativeElement.value = '';
    if (this.feedbackFile && this.feedbackFile.nativeElement)
      this.feedbackFile.nativeElement.value = '';
    if (this.issueFile && this.issueFile.nativeElement)
      this.issueFile.nativeElement.value = '';
    if (this.repairFile && this.repairFile.nativeElement)
      this.repairFile.nativeElement.value = '';
    this.files = [[], [], [], [], []];
  }

  loadLaborCosts() {
    this.logService
      .getReport({ start: this.date, end: this.date })
      .subscribe(data => {
        /*		console.log('Time Cards');
			console.log(data); */
        if (data.length == 0) {
          this.timeCards = { foh_cost: 0, boh_cost: 0 };
        } else {
          this.timeCards = data[0].timeclock;
        }
      });
  }

  load() {
    this.notes = [];
    this.logNoteService
      .list({ service_time_id: this.serviceTime.id, date: this.date })
      .subscribe(data => {
        this.logNotes = data;
      });
    this.customLogFieldService
      .list({ service_time_id: this.serviceTime.id })
      .subscribe(data => {
        let d = new Date(this.date);
        // Fix this to current day...
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        this.customs = data.filter(e => {
          return e.days[d.getDay()];
        });
        this.logService
          .list({ service_time_id: this.serviceTime.id, date: this.date })
          .subscribe(data => {
            /*console.log('Got Data');
				console.log(data);*/
            if (data && data.length > 0) this.log = data[0];
            else {
              this.log = {
                date: this.date,
                service_time_id: this.serviceTime.id,
              };
            }
            if (this.customs.length > 0 && !this.log.custom_fields) {
              //console.log('Adding custom');
              this.log.custom_fields = {};
            }
          });
      });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  deleteNote(note) {
    this.logNoteService.delete(note.id).subscribe(data => {
      let idx = this.logNotes.indexOf(note);
      this.logNotes.splice(idx, 1);
    });
  }

  save() {
    this.saveMessage = 'Saving now...';
    this.log.date = this.date;
    this.log.updated_at = new Date();
    this.logService.update(this.log).subscribe(data => {
      this.log.id = data.id;
      let p = [];
      //console.log(this.notes);
      for (let i = 1; i <= 4; i++) {
        let note = this.notes[i];
        //console.log(note);
        if (note) {
          p.push(
            this.logNoteService.update({
              log_id: data.id,
              service_time_id: this.serviceTime.id,
              log_note_type: i,
              user_id: this.accountService.getUserId(),
              date: this.date,
              note: note,
            })
          );
        } else {
          this.files[i] = [];
        }
      }
      if (p.length == 0) {
        this.closeSave();
      } else {
        forkJoin(p).subscribe((results: any[]) => {
          let q = [];
          /*					console.log('Results');
					console.log(results);*/
          for (let r of results) {
            let i = r.log_note_type;
            if (this.files[i] && this.files[i].length > 0) {
              for (let f of this.files[i]) {
                q.push(this.mediaService.upload(f, 'log', r.id));
              }
            }
          }
          if (q.length == 0) {
            this.closeSave();
            this.load();
          } else {
            forkJoin(q).subscribe(results => {
              this.closeSave();
              this.load();
            });
          }
        });
      }
    });
  }

  closeSave() {
    this.resetFiles();
    this.saveMessage = 'Done';
    setTimeout(() => {
      this.saveMessage = null;
    }, 3000);
  }
}
