import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from './services/account.service';
import { UserService } from './services/user.service';
import { IMessage, MessageService } from './services/message.service';
import { TimeClockService, ITimeClock } from './services/timeClock.service';
import { SelectAddAll, StringSort, ITimeClockUser } from './shared/api';

@Component({
  templateUrl: './timeclock.html',
})
export class TimeclockComponent implements OnInit {
  public perms: any;
  //public password: string;
  public users: any[] = null;
  //public pin: string;
  public error: string;
  public timeclock: any = {};
  // Step 1 = Login
  // Step 2 = Manager Approval
  // Step 3 = Success Message
  // Step 4 = Error Message
  // Step 5 = Clocked Out Tip Reporting.
  // Step 6 = Quiz Stuff
  // Step 7 = Wall Posts.
  public step = 1;
  public positions: any[];
  public finalMessage: string;
  public info;
  public tips = 0;
  public tip_id: number;
  public manager_error: string;

  @ViewChild('docQuizView') docQuizViewModal;

  @ViewChild('writeupSign') writeupSignModal;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected userService: UserService,
    protected timeClockService: TimeClockService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.reset();
    this.error = null;
    this.userService.list().subscribe(data => {
      StringSort(data, 'lastname');
      this.users = SelectAddAll(
        data.map(e => {
          return {
            id: e.id,
            name:
              (e.firstname ? e.firstname : '') +
              ' ' +
              (e.lastname ? e.lastname : ''),
          };
        }),
        'Please select a user'
      );
      //		console.log(this.users);
    });
    this.timeClockService.getLocation().subscribe(data => {
      this.timeclock = data;
    });
  }

  reset() {
    this.step = 1;
    this.perms = {
      user_id: '',
      pin: '',
      password: null,
    };
    this.positions = null;
    this.info = null;
    this.tips = 0;
    this.tip_id = null;
  }

  pinBack() {
    if (!this.perms.pin || this.perms.pin.length == 0) return;
    this.perms.pin = this.perms.pin.substring(0, this.perms.pin.length - 1);
  }

  pinReset() {
    this.perms.pin = '';
  }

  pinInput(e) {
    if (!this.perms.pin) this.perms.pin = e.toString();
    else this.perms.pin += e.toString();
  }

  save() {
    this.timeClockService.tryClockOut({ perms: this.perms }).subscribe(
      clockedOut => {
        //			console.log('Got clock out:');
        //		console.log(clockedOut);
        if (clockedOut.clocked_out) {
          if (!clockedOut.needs_tips) {
            this.setFinalMessage('You have been clocked out');
          } else {
            this.tip_id = clockedOut.clocked_out;
            this.step = 5;
          }
          return;
        }
        this.timeClockService
          .getUserInfo({ perms: this.perms })
          .subscribe(info => {
            this.info = info;
            if (
              !info.employee_positions ||
              info.employee_positions.length == 0
            ) {
              this.setErrorMessage(
                'User has no defined positions and cannot clock in'
              );
              return;
            }
            this.info.employee_position_id = this.info.employee_positions[0].id;

            if (
              !info.shift &&
              (!this.info.approver_email || !this.info.approver_password)
            ) {
              this.step = 2;
              return;
            }
            this.clockIn().subscribe(() => {
              this.postClockIn();
            });
          });
      },
      err => {
        this.setErrorMessage('Invalid password or pin.');
      }
    );
  }

  cancelApproval() {
    this.reset();
  }

  reportTips() {
    this.timeClockService
      .reportTips({ id: this.tip_id, tips: this.tips, perms: this.perms })
      .subscribe(() => {
        this.setFinalMessage('You have been clocked out');
      });
  }

  postClockIn() {
    //console.log('Checking');
    //console.log(this.info);
    if (this.info.quizes && this.info.quizes.length > 0) {
      console.log('Going to a quiz');
      this.step = 6;
      return;
    } else if (this.info.writeups && this.info.writeups.length > 0) {
      //			console.log('Going to a writeup');
      this.writeupSignModal.show(this.info.writeups[0]);
      this.step = 0;
      return;
    } else if (this.info.messages && this.info.messages.length > 0) {
      this.step = 7;
      return;
    } else {
      this.setFinalMessage('You have been clocked in.');
    }
  }

  // Remove it from array, redo checks.
  doQuizClose(doc) {
    if (
      (!doc.quiz || doc.quiz_passed_id) &&
      (!doc.signature || doc.signature_id)
    ) {
      let idx = this.info.quizes.indexOf(doc);
      if (idx >= 0) {
        this.info.quizes.splice(idx, 1);
      }
      this.postClockIn();
    }
  }

  doMarkRead(m) {
    this.timeClockService
      .markRead({ id: m.message_recipient_id, perms: this.perms })
      .subscribe(data => {
        let idx = this.info.messages.indexOf(m);
        if (idx >= 0) {
          this.info.messages.splice(idx, 1);
        }
        this.postClockIn();
      });
  }

  // Remove it from array, redo checks.
  doWriteupSign() {
    this.info.writeups.splice(0, 1);
    this.postClockIn();
  }

  managerSave() {
    this.manager_error = null;
    this.clockIn().subscribe(
      () => {
        this.postClockIn();
      },
      err => {
        this.manager_error =
          'Unable to verify admin or admin does not have permission.';
      }
    );
  }

  clockIn(): Observable<ITimeClock> {
    if (!this.info.shift) {
      let match = this.info.employee_positions.filter(e => {
        return e.id == this.info.employee_position_id;
      });
      if (match.length == 1) {
        this.info.ot_hours = match[0].ot_hours;
      }
    }
    // Create one!
    return this.timeClockService.update({
      perms: this.perms,
      shift_id: this.info.shift ? this.info.shift.id : null,
      employee_position_id: this.info.shift
        ? this.info.shift.employee_position_id
        : this.info.employee_position_id,
      ot_hours: this.info.shift ? this.info.shift.ot_hours : this.info.ot_hours,
      user_id: this.info.user_id,
      location_id: this.info.location_id,
      approver_email: this.info.approver_email,
      approver_password: this.info.approver_password,
    });
  }

  deAuthorize() {
    this.accountService.deAuthDevice().subscribe(data => {
      this.router.navigate(['/login']);
    });
  }

  setFinalMessage(msg: string) {
    this.reset();
    this.step = 3;
    this.finalMessage = msg;
    setTimeout(() => {
      this.step = 1;
      this.finalMessage = null;
    }, 5000);
  }

  setErrorMessage(msg: string) {
    this.reset();
    this.step = 4;
    this.finalMessage = msg;
    setTimeout(() => {
      this.step = 1;
      this.finalMessage = null;
    }, 5000);
  }
}
