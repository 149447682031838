<span class="pointer" (click)="show()"><ng-content></ng-content></span>
<div
  bsModal
  #addCommentModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Add Comment"
  aria-hidden="true">
  <form class="modal-dialog modal-sm" ngNativeValidate (submit)="save()">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Add Comment
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="addCommentModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <textarea
          #textarea
          [(ngModel)]="comment"
          name="comment"
          class="form-control"
          required></textarea>
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="addCommentModal.hide()"></cancel-button>
            <button class="btn btn-primary float-right mr-2">
              Add Comment
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
