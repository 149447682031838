<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Trade Board"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Trade Approval Board
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" *ngIf="data">
        <div class="card" *ngIf="data.length==0">
          There are no trades to approve. Please check back later.
        </div>

        <div class="card" *ngFor="let trade of data">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-12">
                <b>Trade From:</b><br />
                <b>
                  {{trade.start | date:'EEEE MMMM d, y, h:mm a'}} until
                  {{trade.end | date:'EEEE MMMM d, y, h:mm a'}}</b
                ><br />
              </div>
              <div class="col-md-6 col-12">
                <b>Trade To:</b><br />
                <span *ngIf="trade.trade_shift_start">
                  <b
                    >{{trade.trade_shift_start | date:'EEEE MMMM d, y, h:mm a'}}
                    until {{trade.trade_shift_end | date:'EEEE MMMM d, y, h:mm
                    a'}}</b
                  ><br />
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <p>
                  <img
                    [src]="getUserProfileImage(trade.has_image) | safeUrl"
                    class="pr-2"
                    style="height: 40px"
                    align="left" />
                  <b *ngIf="trade.user_id"
                    >{{trade.firstname}} {{trade.lastname}}</b
                  >
                  <b *ngIf="trade.user_id">Unassigned</b>
                  <br />
                  {{trade.created_at| date: 'longDate'}} <br />
                </p>
                <b>Position:</b> {{trade.position_name}} <br />
                <b>Trade Type:</b> {{TradeTypeNames[trade.trade_type]}}<br />
              </div>
              <div class="col-md-6 col-12">
                <!-- Approval type bits -->
                <p>
                  <img
                    [src]="getUserProfileImage(trade.shift_trade_user_has_image) | safeUrl"
                    class="pr-2"
                    style="height: 40px"
                    align="left" />
                  <b
                    >{{trade.shift_trade_user_firstname}}
                    {{trade.shift_trade_user_lastname}}</b
                  ><br />
                </p>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-md-3 col-6">
                Current Hours: {{trade.user.minutes | bliMinutes}}
              </div>
              <div class="col-md-3 col-6">
                Current Cost:
                <span *ngIf="!trade.user.salary"
                  >{{trade.user.cost | currency: 'USD'}}</span
                >
                <span *ngIf="trade.user.salary">
                  Salary {{trade.user.salary | currency: 'USD'}} per day</span
                >
              </div>
              <div class="col-md-3 col-6">
                Current Hours: {{trade.trade_user.minutes | bliMinutes}}
              </div>
              <div class="col-md-3 col-6">
                Current Cost:
                <span *ngIf="!trade.trade_user.salary"
                  >{{trade.trade_user.cost | currency: 'USD'}}</span
                >
                <span *ngIf="trade.trade_user.salary">
                  Salary {{trade.trade_user.salary | currency: 'USD'}} per
                  day</span
                >
              </div>
            </div>

            <div class="row my-2">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-primary float-right"
                  (click)="approveTrade(trade)">
                  Approve
                </button>
                <button
                  type="button"
                  class="btn btn-warning"
                  (click)="rejectTrade(trade)">
                  Deny
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-2">
        <div class="col-sm-12">
          <done-button
            class="float-right mr-2"
            (click)="cancel()"></done-button>
        </div>
      </div>
    </div>
  </div>
</div>
