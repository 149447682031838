<div *ngIf="vendor" class="nav-padding">
  <h3>View Vendor</h3>
  <div class="card">
    <div class="card-body">
      <dl class="row mb-0">
        <dt class="col-sm-3 col-md-2">Company</dt>
        <dd class="col-sm-9 col-md-4">{{vendor.company}}</dd>

        <dt class="col-sm-3 col-md-2">Group</dt>
        <dd class="col-sm-9 col-md-4" *ngIf="vendor.contact_group_id">
          {{ vendorGroups[vendor.contact_group_id].name}}
        </dd>
        <dd class="col-sm-9 col-md-4" *ngIf="!vendor.contact_group_id"></dd>

        <dt class="col-sm-3 col-md-2">Invoice Vendor?</dt>
        <dd class="col-sm-9 col-md-4">
          <span *ngIf="vendor.is_vendor">Is an Invoice Vendor</span>
          <span *ngIf="!vendor.is_vendor">Is not an Invoice Vendor</span>
        </dd>

        <dt class="col-sm-3 col-md-2">Order Notes</dt>
        <dd class="col-sm-9 col-md-4">{{vendor.order_notes}}</dd>
      </dl>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <dl class="row mb-0">
        <dt class="col-sm-3 col-md-2">Title</dt>
        <dd class="col-sm-9 col-md-4">{{vendor.title}}</dd>

        <dt class="col-sm-3 col-md-2">Name</dt>
        <dd class="col-sm-9 col-md-4">{{vendor.name}}</dd>

        <dt class="col-sm-3 col-md-2">Address</dt>
        <dd class="col-sm-9 col-md-4">{{vendor.address}}</dd>

        <dt class="col-sm-3 col-md-2">Office Phone</dt>
        <dd class="col-sm-9 col-md-4">
          <phone [phone]="vendor.phone"></phone>
        </dd>

        <dt class="col-sm-3 col-md-2">Cell Phone</dt>
        <dd class="col-sm-9 col-md-4"><phone [phone]="vendor.cell"></phone></dd>

        <dt class="col-sm-3 col-md-2">Fax</dt>
        <dd class="col-sm-9 col-md-4">{{vendor.fax}}</dd>

        <dt class="col-sm-3 col-md-2">Email</dt>
        <dd class="col-sm-9 col-md-4">{{vendor.email}}</dd>
      </dl>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <dl class="row mb-0">
        <dt class="col-2">Tags</dt>
        <dd class="col-10">{{vendor.tags}}</dd>
        <dt class="col-2">Notes</dt>
        <dd class="col-10">{{vendor.notes}}</dd>
      </dl>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <cancel-button class="float-right" (click)="cancel()"></cancel-button>
      <button
        type="button"
        class="float-right btn btn-primary mx-2"
        [routerLink]="['/contacts/vendor/edit/',vendor.id]">
        Edit
      </button>
    </div>
  </div>
</div>
