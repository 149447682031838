<form ngNativeValidate *ngIf="data" (submit)="save()">
  <!--	<h3 class="text-center">{{(data.id?'EDIT':'UPLOAD')}} DOCUMENT</h3> -->
  <div class="title text-center mt-3">
    {{ data?.name?'Edit Document':'Upload To This Folder' }}
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <label>Document Name</label>
          <input
            type="text"
            name="name"
            [(ngModel)]="data.name"
            class="form-control"
            placeholder="Name the document"
            required />
          <!--				<strong class='mt-3'>Current Source File Name</strong> -->
          <label> Select one, a file or a video</label>
          <div class="row mb-2">
            <div class="col">
              <div class="btn-group-toggle">
                <label class="btn btn-radio" [class.active]="file">
                  <input
                    type="radio"
                    name="Ioptions"
                    [value]="true"
                    [(ngModel)]="file"
                    [required]="!data.id" />File
                </label>
              </div>
            </div>
            <div class="col">
              <div class="btn-group-toggle">
                <label class="btn btn-radio" [class.active]="!file">
                  <input
                    type="radio"
                    name="Ioptions"
                    [value]="false"
                    [(ngModel)]="file" />Video
                </label>
              </div>
            </div>
          </div>
          <input
            type="file"
            #fileInput
            [hidden]="!file"
            class="form-control-file mb-3"
            id="formControlFile"
            (change)="handleFileChange($event.target.files)"
            accept="application/pdf,image/*" />
          <local-error [error]="fileError"></local-error>
          <div class="input-group" *ngIf="!file">
            <div class="input-group-prepend">
              <span class="input-group-text">YouTube URL </span>
            </div>
            <input
              type="url"
              class="form-control"
              [(ngModel)]="data.video"
              name="video"
              placeholder="Enter URL for a Video"
              required />
          </div>
          <img
            *ngIf="!file && youTubeUrlToId()"
            [src]="'https://i1.ytimg.com/vi/'+youTubeUrlToId()+'/mqdefault.jpg'" />
          <local-error [error]="youTubeError"></local-error>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label>Document Folder</label>

          <div class="row">
            <div class="col-md-4 col-12 px-1">
              <input-toggle [(ngModel)]="data.signature" name="signature"
                >Requires Signature</input-toggle
              >
            </div>
            <div class="col-md-4 col-12 px-1">
              <input-toggle [(ngModel)]="data.quiz" name="quiz"
                >Requires Passed Quiz</input-toggle
              >
            </div>
            <div class="col-md-4 col-12 px-1">
              <input-toggle [(ngModel)]="data.mandatory" name="mandatory"
                >Mandatory Before Sign On</input-toggle
              >
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label>Document Assignments</label>
          <div class="row">
            <div class="col">
              <div class="btn-group-toggle">
                <label
                  class="btn btn-radio"
                  [class.active]="!data.has_user_permissions">
                  <input
                    type="radio"
                    name="options"
                    [value]="false"
                    [(ngModel)]="data.has_user_permissions" />Employees with
                  viewing access to this folder
                </label>
              </div>
            </div>
            <div class="col">
              <div class="btn-group-toggle mx-1">
                <label
                  class="btn btn-radio"
                  [class.active]="data.has_user_permissions">
                  <input
                    type="radio"
                    name="options"
                    [value]="true"
                    [(ngModel)]="data.has_user_permissions" />Custom
                </label>
              </div>
            </div>
          </div>
          <div class="my-3" *ngIf="!data.has_user_permissions"></div>
          <div class="row" *ngIf="data.has_user_permissions">
            <div class="col-12" style="font-size: 90%; padding: 2px">
              <div
                class="flex-200"
                *ngFor="let u of users | orderBy:'lastname'">
                <input-toggle
                  [(ngModel)]="u._selected"
                  class="mx-1"
                  [name]="'user_'+u.id">
                  <!--<img class="rounded" style='margin-right: 0.5rem;' src="/images/test/hawk.png" height="22px">-->
                  {{u.firstname}} {{u.lastname}}</input-toggle
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <cancel-button
        class="float-right"
        [routerLink]="['/docs',data.doc_group_id]"></cancel-button>
      <save-button
        class="float-right"
        *ngIf="!uploading && !youTubeError"></save-button>
      <button
        class="float-right btn btn-primary"
        *ngIf="uploading"
        type="button"
        disabled="true">
        Uploading
      </button>
    </div>
  </div>
</form>
