<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="View File"
  aria-hidden="true">
  <div class="modal-dialog" *ngIf="data && isImage()">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          <span>View File</span>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div *ngIf="isImage()">
          <img [src]="data.url " class="img-fluid" />
        </div>

        <div class="row my-2">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-primary float-right"
              (click)="cancel()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- PDF Version -->
  <div
    class="modal-dialog modal-lg"
    style="height: 100%"
    *ngIf="data && isPdf()">
    <div class="modal-content" style="height: 95%">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          View File
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" style="height: 90%">
        <div *ngIf="isPdf()" style="height: 100%" class="w-100">
          <object
            [data]="data.url|safeUrl"
            type="application/pdf"
            class="w-100 h-100">
            <embed
              [src]="data.url|safeUrl"
              type="application/pdf"
              class="w-100 h-100" />
          </object>
        </div>
      </div>
    </div>
  </div>
</div>
