<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Modal Labor Stats"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          <span *ngIf="!daily">Weekly</span>
          <span *ngIf="daily">Daily</span>
          Labor Stats
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body" *ngIf="ready">
        <div class="row text-white">
          <div class="col-md-6 col-xs-12 align-middle">
            <ul class="list-group">
              <li class="list-group-item blue-bg">
                <div class="d-flex flex-row">
                  <h4 class="p-0 m-0">Total Budget</h4>
                  <div class="ml-auto">
                    <h3 class="p-0 m-0">
                      {{ (foh_budget+boh_budget) | currency: 'USD' }}
                    </h3>
                  </div>
                </div>
              </li>
              <div *ngIf="has_houses">
                <li class="list-group-item grey-dark-bg">
                  <div class="d-flex flex-row">
                    <h4 class="p-0 m-0">FOH Budget</h4>
                    <div class="ml-auto">
                      <h3 class="p-0 m-0">
                        {{ foh_budget | currency: 'USD' }}
                      </h3>
                    </div>
                  </div>
                </li>
                <li *ngIf="has_houses" class="list-group-item grey-bg">
                  <div class="d-flex flex-row">
                    <h4 class="p-0 m-0">BOH Budget</h4>
                    <div class="ml-auto">
                      <h3 class="p-0 m-0">
                        {{ boh_budget | currency: 'USD' }}
                      </h3>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>

          <div
            class="col-md-6 col-12 mb-3"
            style="height: 300px"
            *ngIf="fohBohChartData">
            <chart-labor-payroll [data]="fohBohChartData"></chart-labor-payroll>
          </div>
        </div>

        <table
          class="table table-striped table-hover table-small"
          style="width: 100%">
          <thead style="background-color: #c6c8ca">
            <tr class="text-center">
              <th style="width: 20%"></th>
              <th>Shifts</th>
              <th>Hours</th>
              <th>Planned</th>
              <th>Actual</th>
              <th>Diff</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngIf="getLocation().has_houses">
              <td class="text-left">FOH Salaried</td>
              <td>{{shifts[LaborStatType.SalariedFOH]}}</td>
              <td>{{hours[LaborStatType.SalariedFOH] | bliMinutesDecimal}}</td>
              <td>{{planned[LaborStatType.SalariedFOH] | currency: 'USD'}}</td>
              <td>{{actual[LaborStatType.SalariedFOH] | currency: 'USD'}}</td>
              <td>{{diff[LaborStatType.SalariedFOH] | currency: 'USD'}}</td>
            </tr>
            <tr class="text-center" *ngIf="getLocation().has_houses">
              <td class="text-left">BOH Salaried</td>
              <td>{{shifts[LaborStatType.SalariedBOH]}}</td>
              <td>{{hours[LaborStatType.SalariedBOH] | bliMinutesDecimal}}</td>
              <td>{{planned[LaborStatType.SalariedBOH] | currency: 'USD'}}</td>
              <td>{{actual[LaborStatType.SalariedBOH] | currency: 'USD'}}</td>
              <td>{{diff[LaborStatType.SalariedBOH] | currency: 'USD'}}</td>
            </tr>
            <tr class="text-center" *ngIf="!getLocation().has_houses">
              <td class="text-left">Salaried</td>
              <td>
                {{shifts[LaborStatType.SalariedFOH]+shifts[LaborStatType.SalariedBOH]}}
              </td>
              <td>
                {{hours[LaborStatType.SalariedFOH]+hours[LaborStatType.SalariedBOH]
                | bliMinutesDecimal}}
              </td>
              <td>
                {{planned[LaborStatType.SalariedFOH]+planned[LaborStatType.SalariedBOH]
                | currency: 'USD'}}
              </td>
              <td>
                {{actual[LaborStatType.SalariedFOH]+actual[LaborStatType.SalariedBOH]
                | currency: 'USD'}}
              </td>
              <td>
                {{diff[LaborStatType.SalariedFOH]+diff[LaborStatType.SalariedBOH]
                | currency: 'USD'}}
              </td>
            </tr>

            <tr class="text-center" *ngIf="getLocation().has_houses">
              <td class="text-left">FOH Hourly</td>
              <td>{{shifts[LaborStatType.HourlyFOH]}}</td>
              <td>{{hours[LaborStatType.HourlyFOH] | bliMinutesDecimal}}</td>
              <td>{{planned[LaborStatType.HourlyFOH] | currency: 'USD'}}</td>
              <td>{{actual[LaborStatType.HourlyFOH] | currency: 'USD'}}</td>
              <td>{{diff[LaborStatType.HourlyFOH] | currency: 'USD'}}</td>
            </tr>
            <tr class="text-center" *ngIf="getLocation().has_houses">
              <td class="text-left">BOH Hourly</td>
              <td>{{shifts[LaborStatType.HourlyBOH]}}</td>
              <td>{{hours[LaborStatType.HourlyBOH] | bliMinutesDecimal}}</td>
              <td>{{planned[LaborStatType.HourlyBOH] | currency: 'USD'}}</td>
              <td>{{actual[LaborStatType.HourlyBOH] | currency: 'USD'}}</td>
              <td>{{diff[LaborStatType.HourlyBOH] | currency: 'USD'}}</td>
            </tr>
            <tr class="text-center" *ngIf="!getLocation().has_houses">
              <td class="text-left">Hourly</td>
              <td>
                {{shifts[LaborStatType.HourlyFOH]+shifts[LaborStatType.HourlyBOH]}}
              </td>
              <td>
                {{hours[LaborStatType.HourlyFOH]+hours[LaborStatType.HourlyBOH]
                | bliMinutesDecimal}}
              </td>
              <td>
                {{planned[LaborStatType.HourlyFOH]+planned[LaborStatType.HourlyBOH]
                | currency: 'USD'}}
              </td>
              <td>
                {{actual[LaborStatType.HourlyFOH]+actual[LaborStatType.HourlyBOH]
                | currency: 'USD'}}
              </td>
              <td>
                {{diff[LaborStatType.HourlyFOH]+diff[LaborStatType.HourlyBOH] |
                currency: 'USD'}}
              </td>
            </tr>

            <!-- <tr class='text-center'  *ngIf="shifts[LaborStatType.UnassignedFOH]>0">
							<td  class='text-left'>	Unassigned</td>
							<td>{{shifts[LaborStatType.UnassignedFOH]}} </td>
							<td>{{hours[LaborStatType.UnassignedFOH] | bliMinutesDecimal}} </td>
							<td>{{planned[LaborStatType.UnassignedFOH] | currency: 'USD'}}</td>
							<td>{{actual[LaborStatType.UnassignedFOH] | currency: 'USD'}} </td>
							<td>{{diff[LaborStatType.UnassignedFOH] | currency: 'USD'}} </td>
						</tr> -->
            <!-- <tr class='text-center'  *ngIf="shifts[LaborStatType.UnassignedBOH]>0">
							<td  class='text-left'>	Unassigned</td>
							<td>{{shifts[LaborStatType.UnassignedBOH]}} </td>
							<td>{{hours[LaborStatType.UnassignedBOH] | bliMinutesDecimal}} </td>
							<td>{{planned[LaborStatType.UnassignedBOH] | currency: 'USD'}}</td>
							<td>{{actual[LaborStatType.UnassignedBOH] | currency: 'USD'}} </td>
							<td>{{diff[LaborStatType.UnassignedBOH] | currency: 'USD'}} </td>
						</tr> -->
            <tr class="text-center">
              <td class="text-left">Totals</td>
              <td>{{shifts[6]}}</td>
              <td>{{hours[6]|bliMinutesDecimal}}</td>
              <td>{{planned[6] | currency: 'USD'}}</td>
              <td>{{actual[6] | currency: 'USD'}}</td>
              <td>{{(planned[6]-actual[6])|currency: 'USD'}}</td>
            </tr>
          </tbody>

          <tbody></tbody>
        </table>

        <table
          class="table table-striped table-hover table-small"
          style="width: 100%">
          <thead style="background-color: #c6c8ca">
            <!--			<tr class='text-center'>
              <th  style="width:20%;">Employee <span class='cdi-s-reorder '></span></th>
              <th>Shifts <span class='cdi-s-reorder '></span></th>
							<th>Hours <span class='cdi-s-reorder '></span></th>
              <th>Planned <span class='cdi-s-reorder '></span></th>
              <th>Actual <span class='cdi-s-reorder '></span></th>
              <th>Diff<span class='cdi-s-reorder '></span></th>
						</tr> -->
            <tr class="text-center">
              <th style="width: 20%">Employee</th>
              <th>Shifts</th>
              <th>Hours</th>
              <th>Planned</th>
              <th>Actual</th>
              <th>Diff</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              *ngFor="let u of users  | orderBy:'lastname'">
              <td class="text-left">
                <!--							<img class="rounded mr-1" src="/images/test/gannet.jpg" height="22px" width="22px"> -->
                {{u.firstname}} {{ u.lastname}}
              </td>
              <td>{{u.shifts}}</td>
              <td>{{u.minutes |bliMinutesDecimal}}</td>
              <td>{{u.planned | currency: 'USD'}}</td>
              <td>{{u.actual| currency: 'USD'}}</td>
              <td>{{( u.planned - u.actual ) | currency: 'USD'}}</td>
            </tr>
            <!--<tr class='text-center' *ngIf="unassignedShifts>0">
              <td  class='text-left'>
								 UNASSIGNED</td>
              <td>{{unassignedShifts}}</td>
              <td>{{unassignedHours |bliMinutesDecimal}} </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>-->
          </tbody>

          <tbody></tbody>
        </table>

        <div class="row mt-3">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-primary float-right"
              (click)="cancel()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
