<div class="container-fluid subnav-padding">
  <div class="mt-2 title text-center">Generate PO from Inventory Counts</div>

  <form ngNativeValidate (submit)="save()">
    <div class="row">
      <div class="col-12 text-white font-headers nav-padding">
        <ul class="list-group">
          <li class="list-group-item blue-bg">
            <h4 class="float-left my-0 pt-2 pb-0">Budget</h4>
            <h3 class="float-right my-0 pt-2 pb-0">
              {{ budget | currency: 'USD' }}
            </h3>
          </li>
          <li class="list-group-item grey-dark-bg">
            <h4 class="float-left my-0 pt-2 pb-0">This Order</h4>
            <h3 class="float-right my-0 pt-2 pb-0">
              {{ total | currency:'USD' }} - {{(100*total/budget) | number:
              '1.0-0'}}%
            </h3>
          </li>
          <li class="list-group-item grey-bg">
            <h4 class="float-left my-0 pt-2 pb-0">Pending</h4>
            <h3 class="float-right my-0 pt-2 pb-0">
              {{pendingPrice | currency:'USD'}} - {{100*pendingPercent |number:
              '1.0-0'}}%
            </h3>
          </li>
          <li class="list-group-item grey-light-bg">
            <h4 class="float-left my-0 pt-2 pb-0">Approved</h4>
            <h3 class="float-right my-0 pt-2 pb-0">
              {{approvedPrice | currency:'USD'}} - {{100*approvedPercent
              |number: '1.0-0'}}%
            </h3>
          </li>
        </ul>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row" *ngIf="accounts && contacts">
          <div class="col-md-3 col-xs-12">
            <label class="pl-3"> Vendor </label>
            <select
              [ngModel]="contacts[0]?.id"
              (ngModelChange)="contactId=$event;filter()"
              class="form-control d-inline-block"
              name="contact_id">
              <option *ngFor="let c of contacts" [value]="c.id">
                {{c.company}}
              </option>
            </select>
          </div>
          <div class="col-md-3 col-xs-12">
            <label class="pl-3">Expense Category </label>
            <select
              [ngModel]="accounts[0]?.id"
              (ngModelChange)="expenseAccountId=$event;filter()"
              class="form-control d-inline-block"
              name="expense_Account_id">
              <option *ngFor="let a of accounts" [value]="a.id">
                {{a.name}}
              </option>
            </select>
          </div>
          <div class="col-md-3 col-xs-12">
            <label class="pl-3"> Inventory Date </label>
            <input
              [(ngModel)]="inventoryDate"
              type="text"
              class="form-control input-calendar"
              bsDatepicker
              (ngModelChange)="loadInventoryInfo()"
              name="Inv_start_date"
              required />
          </div>
          <div class="col-md-3 col-xs-12">
            <label class="pl-3"> PO Date </label>
            <input
              [(ngModel)]="poDate"
              type="text"
              class="form-control input-calendar"
              bsDatepicker
              name="PO_start_date"
              required />
          </div>
        </div>
      </div>
    </div>
    <div
      class="card"
      *ngIf="currentContact && currentContact.order_notes && skus && skus.length>0">
      <div class="card-body">
        <label>Order Notes</label>
        {{currentContact.order_notes}}
      </div>
    </div>
    <div style="font-size: 12px">
      <div class="d-none d-md-block">
        <div
          class="row font-weight-bold p-1 mb-2 mx-0"
          style="background-color: #c6c8ca">
          <div class="col-2">SKU</div>
          <div class="col-2">Vendor</div>
          <div class="col-2">QOH</div>
          <div class="col-2">PAR</div>
          <div class="col-2">Suggest</div>
          <div class="col-2">Cost</div>
        </div>
      </div>
      <div class="row" *ngIf="skus && skus.length==0">
        <div class="col-12">No Matches</div>
      </div>

      <div
        class="row mx-0 border-bottom"
        *ngFor="let s of skus | orderBy: 'name'">
        <div class="col-md-2">
          <span class="d-inline-block d-md-none text-primary">SKU: </span
          >{{s.name}}
        </div>
        <div class="col-md-2">
          <span class="d-inline-block d-md-none text-primary">Vendor: </span
          >{{s.contact_company}}
        </div>
        <div class="col-md-2">
          <span class="d-inline-block d-md-none text-primary">QOH: </span>
          <span class="align-top"
            >{{s.count | number: '1.0-2'}} {{s.sub_unit}}(s)
          </span>
          <span
            *ngIf="s.no_inventory flag"
            class="cdi-s-warning cdi-smallest align-top"></span>
        </div>
        <div class="col-md-2">
          <span class="d-inline-block d-md-none text-primary">PAR: </span
          >{{s.parqty}} {{s.sub_unit}}(s)
        </div>
        <div class="col-md-2">
          <span class="d-inline-block d-md-none text-primary">Suggest: </span>
          <input
            type="number"
            [(ngModel)]="s.qty"
            [name]="'sku_'+s.id"
            class="inline-form-control"
            (ngModelChange)="recalc()"
            style="max-width: 70px; margin-top: 0rem; margin-bottom: 0rem"
            min="0"
            placeholder="0" />&nbsp;{{s.buying_unit}}(s)
        </div>
        <div class="col-md-2">
          <span class="d-inline-block d-md-none text-primary">Cost: </span>{{
          (s.qty*s.price) | currency: 'USD' }}
        </div>
      </div>
    </div>

    <p class="mt-3">
      <span class="cdi-s-warning cdi-smaller"></span> indicates no inventory
      count was recorded for the selected date.<br />
      Note: SKU that use a daily par will have the value based on today.
    </p>

    <div class="row mt-3">
      <div class="col-sm-12">
        <cancel-button class="float-right" (click)="cancel()"></cancel-button>
        <button class="btn float-right btn-primary mx-2">Generate</button>
        <button
          class="btn btn-secondary float-right"
          type="button"
          (click)="clearSuggested()">
          Clear Suggestions
        </button>
      </div>
    </div>
  </form>
</div>
