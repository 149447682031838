import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from '../services/account.service';
import { DocGroupService, IDocGroup } from '../services/docGroup.service';
import { PositionService, IPosition } from '../services/position.service';
import { UserService, IUser } from '../services/user.service';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './manage.html',
})
export class ManageComponent extends baseRole implements OnInit {
  public data: IDocGroup;
  public users: IUser[];
  public positions: IPosition[];
  public loc;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected _location: Location,
    protected docGroupService: DocGroupService,
    protected userService: UserService,
    protected positionService: PositionService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.loc = this.accountService.getLocation();
    this.route.params.subscribe(params => {
      let id = params['groupId'];
      console.log('Manage: ' + id);
      if (id) {
        this.docGroupService.get(id).subscribe(data => {
          this.data = data;
          //				console.log(this.data);
          this.setFlags();
        });
      } else {
        this.data = {
          is_everyone: true,
        } as IDocGroup;
      }
    });
    this.userService.list().subscribe(data => {
      this.users = data;
      this.setFlags();
    });
    this.positionService.list().subscribe(data => {
      this.positions = data;
      this.setFlags();
    });
  }

  setFlags() {
    if (this.data && this.users && this.positions) {
      if (this.data.user_ids && this.data.user_ids.length > 0) {
        let map: any = {};
        for (let u of this.data.user_ids) {
          map[u] = u;
        }
        for (let u of this.users) {
          u._selected = u.id in map;
        }
      }
      if (this.data.position_ids && this.data.position_ids.length > 0) {
        let map: any = {};
        for (let u of this.data.position_ids) {
          map[u] = u;
        }
        for (let p of this.positions) {
          p._selected = p.id in map;
        }
      }
    }
  }

  save() {
    let users = this.users.filter(e => {
      return e._selected;
    });
    let positions = this.positions.filter(e => {
      return e._selected;
    });

    //	console.log(users);
    //	console.log(positions);
    this.data.user_ids = null;
    if (users.length > 0) {
      this.data.user_ids = [];
      for (let u of users) this.data.user_ids.push(u.id);
    }

    this.data.position_ids = null;
    if (positions.length > 0) {
      this.data.position_ids = [];
      for (let u of positions) this.data.position_ids.push(u.id);
    }

    //	console.log(this.data);
    this.docGroupService.update(this.data).subscribe(result => {
      this.router.navigate(['/docs/', result.id]);
    });
  }

  cancel() {
    this._location.back();
  }
}
