import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService, fixDate } from './base.service';
import { AccountService } from './account.service';
import { IUser, IUserCreate, getBaseUrl } from '../shared/api';

@Injectable()
export class UserService extends BaseService<IUser> {
  constructor(
    protected accountService: AccountService,
    protected http: HttpClient
  ) {
    super(accountService, http, 'user');
  }

  /*preProcess(data: any):any {
		if(Array.isArray(data)) { 
			for(let d of data) { 
				this.dateToLocal(d,'dob');
			}
		}
		else {
			this.dateToLocal(data,'dob');
		}
		return data; 
	}*/

  postProcess(): (data: any) => any {
    return data => {
      if (!data) return data;
      if (Array.isArray(data)) {
        for (let d of data) {
          //this.fixDate(d,'dob');
          if ('positions' in d) {
            for (let p of d.positions) {
              this.fixDate(p, 'start');
              this.fixDate(p, 'end');
            }
          } else {
            d.positions = [];
          }
        }
      } else {
        //this.fixDate(data,'dob');
        if ('positions' in data) {
          for (let p of data.positions) {
            this.fixDate(p, 'start');
            this.fixDate(p, 'end');
          }
        } else {
          data.positions = [];
        }
      }
      return data;
    };
  }

  /*
	getBirthdays() : Observable<{id: number, firstname: string, lastname: string, dob: Date }[]> {
	 	let queryParameters = new HttpParams();
		let userFilter = this.accountService.getUserFilter();

		queryParameters = userFilter.updateQuery(queryParameters);
		return this.http.get<{id: number, firstname: string, lastname: string, dob: Date }[]>(this.baseUrl+'birthdays',{ params: queryParameters })
			.pipe(map((data)=> {
				for(let d of data) { 
					this.fixDate(d,'birthday');
				}
				return data;
			}));
	}*/

  search(data: any): Observable<any[]> {
    return this.http
      .post<any[]>(this.baseUrl + 'search', data, this.defaultOptions)
      .pipe(
        map(data => {
          for (let d of data) {
            this.fixDate(d, 'poassword_token_expire');
          }
          return data;
        })
      );
  }

  getNewPin(): Observable<string> {
    let locationId = this.accountService.getLocationId();

    return this.http.post<string>(
      this.baseUrl + 'getNewPin',
      { location_id: locationId },
      this.defaultOptions
    );
  }

  getAdminToken(user_id: number): Observable<string> {
    return this.http.post<string>(
      this.baseUrl + 'adminToken',
      { user_id: user_id },
      this.defaultOptions
    );
  }

  listForChecklist(checklist_id?: number): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    queryParameters = userFilter.updateQuery(queryParameters);
    if (checklist_id) {
      queryParameters = queryParameters.set(
        'checklist_id',
        checklist_id.toString()
      );
    }
    return this.http
      .get(this.baseUrl + 'forChecklist', { params: queryParameters })
      .pipe(map(this.postProcess()));
  }

  listUnverified(): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl + 'unverified', { params: queryParameters })
      .pipe(map(this.postProcess()));
  }

  listWithPositions(
    startDate?: Date,
    endDate?: Date,
    filter?: any
  ): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (startDate)
      queryParameters = queryParameters.set('start', startDate.toString());
    if (endDate)
      queryParameters = queryParameters.set('end', endDate.toString());
    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl + 'withPositions', { params: queryParameters })
      .pipe(map(this.postProcess()));
  }

  updateLoginTime(locationId: number) {
    return this.http.post<boolean>(
      this.baseUrl + '/loginTime',
      { location_id: locationId },
      this.defaultOptions
    );
  }

  addUser(data: IUserCreate) {
    data = this.preProcess(data);
    return this.http.post<IUser>(
      this.baseUrl + 'add',
      data,
      this.defaultOptions
    );
  }

  sendUnverified(userIds: number[]) {
    let data: any = {
      users: userIds,
    };
    let userFilter = this.accountService.getUserFilter();
    data = userFilter.updateObject(data);
    return this.http.post<IUser>(
      this.baseUrl + 'unverified',
      data,
      this.defaultOptions
    );
  }

  getPdfStaffUrl(data: { location_id: number }): string {
    return this.baseUrl + 'pdf?location_id=' + data.location_id;
  }

  getUsernameInLocation(data: {
    username: string;
    location_id: number;
  }): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + 'userExistsInLocation',
      data,
      this.defaultOptions
    );
  }
}

export { IUser, UserType } from '../shared/api';
