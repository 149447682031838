import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, HttpClient } from './base.service';
import { IChecklistPositionMap } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ChecklistPositionMapService extends BaseService<IChecklistPositionMap> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'checklistPositionMap');
  }

  bulkUpdate(
    data: {
      checklist_id: number;
      location_id: number;
      position_id: number;
      _checked: boolean;
    }[]
  ): Observable<any> {
    return this.http.post<any>(
      this.baseUrl + 'massUpdate',
      data,
      this.defaultOptions
    );
  }
}

export { IChecklistPositionMap } from '../shared/api';
