import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import { AccountService } from '../services/account.service';
import { LocationService, ILocation } from '../services/location.service';
import { MediaService } from '../services/media.service';
import {
  moment,
  States,
  getUserProfileImage,
  IApiUser,
  IApiLocation,
  BillingPlanType,
} from '../shared/api';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';

@Component({
  templateUrl: './account.html',
})
export class AccountComponent extends baseRole implements OnInit, OnDestroy {
  public user: IApiUser;
  public loc: IApiLocation;
  public jailed = false;
  public newLocation = false;
  public freeDaysLeft = 0;
  public hasPicked = true;
  public sub;
  public BillingPlanType = BillingPlanType;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.user = this.getUser();
    this.sub = this.accountService.getUserUpdateObservable().subscribe(data => {
      this.loc = this.getLocation();
      this.jailed = this.accountService.checkJail();
      // Flag to picked if there is no location.
      this.hasPicked = this.accountService.getLocation()
        ? this.loc.has_picked_package || this.loc.block_freemium_warning
        : true;
    });

    console.log('Jailed: ' + this.jailed);
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  save() {
    this.router.navigate(['/settings/account']);
  }

  selectPackage() {
    this.router.navigate(['/settings/account', this.loc.id, 'package']);
  }
}
