import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { LocationService } from '../services/location.service';
import { UserService } from '../services/user.service';
import { BillingService } from '../services/billing.service';
import {
  getSignInUrl,
  StringSort,
  SelectAddAll,
  PackageType,
  PackageTypeNames,
  SalesIntegrationType,
  LocationStatus,
} from '../shared/api';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FoodVendorService } from 'source/services/foodVendor.service';
import { of } from 'rxjs';

@Component({
  templateUrl: './locationActive.html',
})
export class LocationActiveComponent implements OnInit {
  public data;
  public orderByField = '';
  public PackageType = PackageType;
  public PackageTypeNames = PackageTypeNames;
  public PackageTypeKeys = [1, 2, 3];
  public packageCount: number[];
  public SalesIntegrationType = SalesIntegrationType;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    protected accountService: AccountService,
    protected foodVendorService: FoodVendorService
  ) {}

  ngOnInit() {
    this.locationService.activeReport().pipe(
      switchMap(data => {
        this.packageCount = [0, 0, 0, 0];
        this.data = data;
        for (let d of data) {
          this.packageCount[d.package_type]++;
          if (!d.amount) d.amount = 0;
        }

        const ids = data.map(item => item.id).join(',');

        return this.foodVendorService.getCredentialsById(ids).pipe(
          map(credentialsResponse => {
            const idVendorMap = credentialsResponse.reduce((map, credentials) => {
              const vendorIntegration = credentials.credentials
                .map(cred => cred.vendor.charAt(0).toUpperCase() + cred.vendor.slice(1))
                .join(', ');
              map[credentials.id] = vendorIntegration;
              return map;
            }, {});

            data.forEach(item => {
              item.food_vendor_integration = idVendorMap[item.id] || '';
            });

            return data;
          }),
          catchError(error => {
            console.error(`Error in getCredentialsById: ${error}`);
            return of(data);
          })
        );
      })
    ).subscribe(dataWithIntegration => {
      this.data = dataWithIntegration;
    });
  }
}
