import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AccountService, IApiUser } from '../services/account.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  PurchaseOrderService,
  IPurchaseOrder,
  PurchaseOrderStatus,
} from '../services/purchaseOrder.service';
import { PurchaseOrderItemService } from '../services/purchaseOrderItem.service';
import { SalesGoalService } from '../services/salesGoal.service';
import { baseRole } from '../shared/baseRole';
import { DateMenuType, getEndOfWeek } from '../shared/api';

@Component({
  templateUrl: 'purchaseOrderEdit.html',
})
export class PurchaseOrderEditComponent
  extends baseRole
  implements OnInit, OnDestroy
{
  public data: any;
  public items: any[];
  public total = 0;
  public budget = 0;
  public approvedPrice = 0;
  public approvedPercent = 0;
  public pendingPrice = 0;
  public pendingPercent = 0;
  public sub;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected poService: PurchaseOrderService,
    protected poItemService: PurchaseOrderItemService,
    protected salesGoalService: SalesGoalService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.EditPO)) {
      this.router.navigate(['/inventory']);
      return;
    }
    let stat = this.route.snapshot.data['status'];
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.poService.get(id).subscribe(data => {
        this.data = data;
      });
      this.poItemService.list({ purchase_order_id: id }).subscribe(data => {
        this.items = data;
        this.calcTotal();
      });
      if (this.sub) return;
      this.sub = this.accountService
        .getCurrentDateUpdateObservable(DateMenuType.Weekly)
        .subscribe(date => {
          if (!date) return;
          let startDate = date;
          let endDate = getEndOfWeek(startDate);
          this.salesGoalService
            .list({ start: startDate, end: endDate })
            .subscribe(goals => {
              this.budget = 0;
              for (let g of goals) {
                this.budget += g.goal;
              }

              this.poService.sumByType(startDate, endDate).subscribe(prices => {
                for (let p of prices) {
                  if (p.status == PurchaseOrderStatus.Approved) {
                    this.approvedPrice += p.price;
                    if (this.budget) {
                      this.approvedPercent = this.approvedPrice / this.budget;
                    }
                  }
                  if (p.status == PurchaseOrderStatus.Pending) {
                    this.pendingPrice += p.price;
                    if (this.budget) {
                      this.pendingPercent = this.pendingPrice / this.budget;
                    }
                  }
                }
              });
            });
        });
    });
  }

  calcTotal() {
    this.total = 0;
    for (let i of this.items) {
      this.total += i.qty * i.price_per_unit;
    }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  save() {
    this.poService.update(this.data).subscribe(data => {
      if (this.items.length == 0) {
        this.router.navigate(['/inventory/orders/purchase']);
      } else {
        let items = [];

        for (let i of this.items) {
          items.push({ id: i.id, qty: i.qty });
        }
        this.poItemService.bulkUpdate(items).subscribe(data => {
          this.router.navigate(['/inventory/orders/purchase']);
        });
      }
    });
  }

  cancel() {
    this.router.navigate(['/inventory/orders/purchase']);
  }
}
