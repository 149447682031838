<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" *ngIf="data && !isAllowed(Permission.EditEvent)">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          View Event
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="card">
              <div class="card-body">
                <label>Event Name</label>
                {{data.name}}
              </div>
            </div>
          </div>
          <div class="col-md-3 col-xs-6">
            <div class="card">
              <div class="card-body">
                <label>Start Date</label>
                {{ data.start_date | bliDate }}
              </div>
            </div>
          </div>
          <div class="col-md-3 col-xs-6">
            <div class="card">
              <div class="card-body">
                <label>End Date</label>
                {{ data.end_date | bliDate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <label style="width: auto">Description</label>
                {{ data.notes }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <label>Repeats</label>
                {{ RepeatOptions[data.repeat_option] }}<br />
                <div *ngIf="data.repeat_until">
                  <label>Repeats Until</label>
                  {{data.repeat_until | bliDate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <label>Assigned to</label>
            <div class="row">
              <div class="flex-200 p-1" *ngFor="let user of users">
                <span *ngIf="user._selected">
                  {{user.firstname}} {{user.lastname}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="data && isAllowed(Permission.EditEvent)">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          {{(data.id?'Edit Event':'Add Event for schedule &amp; calendar')}}
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <form ngNativeValidate (submit)="save()">
          <div class="card-columns">
            <div class="card">
              <div class="card-body">
                <label> Event Name </label>
                <div class="form-group">
                  <input
                    [(ngModel)]="data.name"
                    type="text"
                    maxlength="50"
                    class="form-control override-invalid"
                    placeholder="Name"
                    required
                    name="name" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <label>Event Day Start </label>
                <input
                  type="text"
                  class="form-control input-calendar"
                  bsDatepicker
                  [(ngModel)]="data.start_date"
                  (ngModelChange)="updateMaxDate()"
                  name="event_start_date"
                  required />
                <timepicker
                  name="start_time"
                  [(ngModel)]="data.start_date"></timepicker>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <label>Event Day End <small> . Multiday event?</small></label>
                <input
                  type="text"
                  class="form-control input-calendar"
                  bsDatepicker
                  [(ngModel)]="data.end_date"
                  [minDate]="data.start_date"
                  [maxDate]="maxDate"
                  name="event_end_date" />
                <timepicker
                  name="end_time"
                  [(ngModel)]="data.end_date"></timepicker>
              </div>
            </div>
          </div>

          <div class="card my-2">
            <div class="card-body">
              <label style="width: auto">Event Description and Color </label>
              <input-color
                name="color"
                [(ngModel)]="data.color"
                class="float-right"></input-color>
              <div class="form-group">
                <textarea
                  [(ngModel)]="data.notes"
                  name="description"
                  class="form-control"
                  id="event_description"
                  placeholder="Event Description"
                  required></textarea>
              </div>
            </div>
          </div>

          <div class="card my-2">
            <div class="card-body">
              <label>Recurring Event </label>
              <select
                name="repeat_type"
                [(ngModel)]="data.repeat_option"
                class="form-control d-inline-block"
                (ngModelChange)="updateMaxDate()">
                <option *ngFor="let k of RepeatOptionsKeys" [value]="k">
                  {{RepeatOptions[k]}}
                </option>
              </select>
              <div *ngIf="data.repeat_option!=RepeatOptions.None">
                <label>Recurs Until this end date</label>
                <input
                  type="text"
                  class="form-control input-calendar"
                  bsDatepicker
                  [(ngModel)]="data.repeat_until"
                  name="repeat_until" />
              </div>
            </div>
          </div>

          <div class="card my-2">
            <div class="card-body" *ngIf="positions">
              <label
                >Select who this event should be assigned to
                <button
                  class="btn btn-secondary float-right"
                  type="button"
                  (click)="selectEveryone()">
                  Everybody
                </button>
              </label>
              <div class="row">
                <div class="flex-200 p-1" *ngFor="let p of positions">
                  <input-toggle
                    [name]="'t_user_'+p.id"
                    [(ngModel)]="p._selected"
                    >{{p.name}}</input-toggle
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="card my-2">
            <div class="card-body">
              <div>
                <label style="width: auto"
                  >View and Edit selected recipients here
                </label>
                <clear-button
                  class="float-right"
                  (click)="clearUsers()"></clear-button>
              </div>
              <div class="row">
                <div class="flex-200 p-1" *ngFor="let u of users">
                  <input-toggle
                    [(ngModel)]="u._selected"
                    [name]="'userToggle_'+u.id"
                    >{{ u.firstname }} {{ u.lastname}}</input-toggle
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-xs-12">
              <div class="card my-2">
                <div class="card-body">
                  <input-toggle
                    [(ngModel)]="data.text_alert"
                    class="mb-2"
                    name="text_alert">
                    Send Reminder Text</input-toggle
                  >
                  <div class="row">
                    <div class="col">
                      <select
                        [(ngModel)]="data.text_alert_days"
                        class="form-control d-inline-block"
                        name="text_alert_days">
                        <option *ngFor="let k of DaysBeforeKeys" [value]="k">
                          {{DaysBefore[k]}}
                        </option>
                      </select>
                    </div>
                    <div class="col">
                      <timepicker
                        name="text_time"
                        minuteStep="15"
                        [(ngModel)]="data.text_time"></timepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-xs-12">
              <div class="card my-2">
                <div class="card-body">
                  <input-toggle
                    [(ngModel)]="data.email_alert"
                    class="mb-2"
                    name="email_alert"
                    >Send Reminder Email</input-toggle
                  >
                  <div class="row">
                    <div class="col">
                      <select
                        [(ngModel)]="data.email_alert_days"
                        name="email_alert_days"
                        class="form-control d-inline-block">
                        <option *ngFor="let k of DaysBeforeKeys" [value]="k">
                          {{DaysBefore[k]}}
                        </option>
                      </select>
                    </div>
                    <div class="col">
                      <timepicker
                        name="email_time"
                        [(ngModel)]="data.email_time"></timepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <global-error [error]="error"></global-error>

          <div class="row mt-3">
            <div class="col-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <save-button class="float-right"></save-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
