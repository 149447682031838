<div *ngIf="data" class="nav-padding">
  <div class="title text-center">
    {{(data.id?'Edit '+data.name:'Add Folder')}}
  </div>
  <form ngNativeValidate *ngIf="data" (submit)="save()">
    <div class="card">
      <div class="card-body">
        <label>Document Folder Name</label>
        <input
          type="text"
          name="name"
          [(ngModel)]="data.name"
          class="form-control"
          required />
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <label>Who Sees the Documents in This Folder</label>
        <!--				<clear-button class='float-right'></clear-button>  -->
        <div class="col-12">
          <div class="row d-flex">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
              <input-toggle [(ngModel)]="data.is_everyone" name="everyone">
                Everybody
              </input-toggle>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
              <input-toggle [(ngModel)]="data.level_1" name="level_1">
                {{loc.level_1}}
              </input-toggle>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
              <input-toggle [(ngModel)]="data.level_2" name="level_2">
                {{loc.level_2}}
              </input-toggle>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
              <input-toggle [(ngModel)]="data.level_3" name="level_3">
                {{loc.level_3}}
              </input-toggle>
            </div>

            <div
              class="col-lg-3 col-md-4 col-sm-6 col-12"
              *ngIf="getLocation().has_houses">
              <input-toggle [(ngModel)]="data.is_foh" name="is_foh">
                All FOH
              </input-toggle>
            </div>
            <div
              class="col-lg-3 col-md-4 col-sm-6 col-12"
              *ngIf="getLocation().has_houses">
              <input-toggle [(ngModel)]="data.is_boh" name="is_boh">
                All BOH
              </input-toggle>
            </div>

            <div
              class="col-lg-3 col-md-4 col-sm-6 col-12"
              *ngFor="let p of positions">
              <input-toggle [(ngModel)]="p._selected" [name]="'p_'+p.id">
                {{p.name}}
              </input-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <label>Add Document Viewers</label>
        <div class="col-12">
          <div class="row d-flex" *ngIf="users">
            <div
              class="col-lg-3 col-md-4 col-sm-6 col-12"
              *ngFor="let user of users">
              <input-toggle
                [(ngModel)]="user._selected"
                class="px-1"
                [name]="'user_'+user.id">
                <!--<img class="rounded" style='margin-right: 0.5rem;' src="/images/test/hawk.png" height="22px">-->
                {{user.firstname}} {{user.lastname}}</input-toggle
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <cancel-button class="float-right" (click)="cancel()"></cancel-button>
    <save-button class="float-right"></save-button>
  </form>
</div>
