import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IFixedCost } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class FixedCostService extends BaseService<IFixedCost> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'fixedCost');
  }

  getReport(data: any): Observable<IFixedCost[]> {
    let userFilter = this.accountService.getUserFilter();
    this.formatDateOnly(data, 'start');
    this.formatDateOnly(data, 'end');
    data = userFilter.updateObject(data);
    return this.http.post<any[]>(
      this.baseUrl + 'report',
      data,
      this.defaultOptions
    );
  }
}

export { IFixedCost, FixedCostPeriod } from '../shared/api';
