import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
import {
  PurchaseOrderService,
  IPurchaseOrder,
  PurchaseOrderStatus,
} from '../services/purchaseOrder.service';
import {
  PurchaseOrderItemService,
  IPurchaseOrderItem,
} from '../services/purchaseOrderItem.service';
import { InvoiceService } from '../services/invoice.service';
import { InvoiceItemService } from '../services/invoiceItem.service';
import { baseRole } from '../shared/baseRole';

const defaultItem = {
  name: '',
  expense_account_id: null,
  amount: null,
};

@Component({
  selector: 'modal-po-receive',
  templateUrl: 'modalPOReceive.html',
})
export class ModalPOReceiveComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;
  public add_invoice = true;
  public data;
  public skus;
  public total = 0;
  public expenseAccounts: IExpenseAccount[];
  public errorPONumber: string = null;
  public invoice: any;
  public newItem: any;
  public items: any[];

  constructor(
    public accountService: AccountService,
    public expenseAccountService: ExpenseAccountService,
    public poService: PurchaseOrderService,
    public poItemService: PurchaseOrderItemService,
    public invoiceService: InvoiceService,
    public invoiceItemService: InvoiceItemService
  ) {
    super(accountService);
  }

  ngOnInit() {
    this.initInvoice();
  }

  load() {
    this.poItemService
      .list({ purchase_order_id: this.data.id })
      .subscribe(data => {
        this.skus = data;
        for (let s of this.skus) {
          s.qty_received = 0;
          if (!s.price_received) s.price_received = s.price_per_unit;
        }
        this.recalc();
        this.modal.show();
      });
    this.expenseAccountService.list().subscribe(data => {
      this.expenseAccounts = data;
      defaultItem.expense_account_id = data[0].id;
      this.newItem = Object.assign({}, defaultItem);
    });
  }
  show(data) {
    this.initInvoice();
    this.items = [];
    this.data = data;
    //	console.log(this.data);
    this.load();
  }

  recalc() {
    this.total = 0;
    for (let s of this.skus) {
      this.total += s.qty_received * s.price_received;
    }
    for (let i of this.items) {
      this.total += i.amount;
    }
  }

  checkPONumber() {
    this.invoiceService
      .list({
        contact_id: this.data.contact_id,
        ponumber: this.invoice.ponumber,
      })
      .subscribe(data => {
        this.errorPONumber = null;
        if (data.length > 0 && this.add_invoice) {
          this.errorPONumber =
            'This invoice already exists. Please select another to add.';
        } else if (data.length == 0 && !this.add_invoice) {
          this.errorPONumber = 'Unknown invoice. Please try again.';
        } else if (data.length == 1 && !this.add_invoice) {
          this.invoice = data[0];
        }
      });
  }

  initInvoice() {
    this.invoice = {
      terms: 0,
      date: new Date(),
    };
  }

  addInvoiceChange() {
    if (this.add_invoice) {
      this.initInvoice();
    } else {
      this.checkPONumber();
    }
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    let updateObj: any = {
      id: this.data.id,
      status: PurchaseOrderStatus.Received,
    };
    if (!this.add_invoice) {
      updateObj.invoice_id = this.invoice.id;
    }
    this.poService.update(updateObj).subscribe(() => {
      this.data.status = PurchaseOrderStatus.Received;
    });
    let items = [];
    for (let i of this.skus) {
      items.push({
        id: i.id,
        qty_received: i.qty_received,
        price_received: i.price_received,
      });
    }
    //	console.log('Doing items');
    //	console.log(items);
    this.poItemService.bulkUpdate(items).subscribe(data => {});

    if (this.add_invoice) {
      this.invoiceService
        .update({
          contact_id: this.data.contact_id,
          date: this.invoice.date,
          terms: this.invoice.terms,
          ponumber: this.invoice.ponumber,
        })
        .subscribe(invoice => {
          this.invoice.id = invoice.id;
          this.poService
            .update({ id: this.data.id, invoice_id: this.invoice.id })
            .subscribe(() => {});
          this.saveItems();
        });
    } else {
      this.invoiceService
        .update({
          id: this.invoice.id,
          terms: this.invoice.terms,
        })
        .subscribe(data => {
          this.saveItems();
        });
    }
    //	this.modal.hide();
  }

  saveItems() {
    let p = [];
    for (let s of this.skus) {
      if (s.qty_received > 0) {
        p.push({
          invoice_id: this.invoice.id,
          expense_account_id: s.expense_account_id,
          name: s.name,
          amount: s.price_per_unit * s.qty_received,
        });
      }
    }
    for (let i of this.items) {
      if (i.amount > 0 && i.expense_account_id > 0) {
        p.push({
          invoice_id: this.invoice.id,
          expense_account_id: i.expense_account_id,
          name: i.name,
          amount: i.amount,
        });
      }
    }
    this.invoiceItemService.bulkUpdate(p).subscribe(data => {
      this.modal.hide();
    });
  }

  // Clean up the bottom items;
  deleteItem(obj) {
    let idx = this.items.indexOf(obj);
    this.items.splice(idx, 1);
    this.recalc();
  }

  addItem() {
    this.items.push(this.newItem);
    this.newItem = Object.assign({}, defaultItem);
    this.recalc();
  }
}
