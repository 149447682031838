<modal-trade-board #modalTradeBoard></modal-trade-board>
<modal-trade-board-approve #modalTradeBoardApprove></modal-trade-board-approve>
<div class="row" *ngIf="data">
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body">
        <label>Schedule requests</label>
        <div class="row">
          <div
            class="col text-center"
            style="min-width: 90px; max-width: 110px">
            <div class="schedule-request-button" style="border: 0">
              <div class="card-img pointer" (click)="modalTradeBoard.show()">
                <icons name="CalendarRequest"></icons>
                <p class="card-text m-0">Posted</p>
                <p class="card-text m-0">Trades</p>
                <div
                  class="card-img-overlay"
                  *ngIf="postedTrades"
                  style="top: -15%; left: 45%">
                  <span class="notification-badge">{{postedTrades}}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col text-center"
            style="min-width: 90px; max-width: 110px">
            <div class="schedule-request-button" style="border: 0">
              <div class="card-img pointer" (click)="modalTradeBoard.show()">
                <icons name="CalendarRequest"></icons>
                <p class="card-text m-0">Pickups on my Trades</p>
                <div
                  class="card-img-overlay"
                  *ngIf="pickupsOnMyTrades"
                  style="top: -15%; left: 45%">
                  <span class="notification-badge">{{pickupsOnMyTrades}}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col text-center"
            style="min-width: 90px; max-width: 110px">
            <div class="schedule-request-button" style="border: 0">
              <div class="card-img pointer" (click)="modalTradeBoard.show()">
                <icons name="CalendarRequest"></icons>
                <p class="card-text m-0">Trades I'm Eligible for</p>
                <div
                  class="card-img-overlay"
                  *ngIf="eligableTrades"
                  style="top: -15%; left: 45%">
                  <span class="notification-badge">{{eligableTrades}}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col text-center"
            style="min-width: 90px; max-width: 110px">
            <div class="schedule-request-button" style="border: 0">
              <div class="card-img pointer" (click)="modalTradeBoard.show()">
                <icons name="CalendarRequest"></icons>
                <p class="card-text m-0">Pickups I've Requested</p>
                <div
                  class="card-img-overlay"
                  *ngIf="pickupsRequested"
                  style="top: -15%; left: 45%">
                  <span class="notification-badge">{{pickupsRequested}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row"
  *ngIf="isAllowed(Permission.ApproveTrade)||isAllowed(Permission.ApproveTimeOff)">
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body">
        <label>Pending schedule requests</label>
        <div class="row">
          <div
            class="col text-center"
            style="min-width: 90px; max-width: 110px"
            *ngIf="isAllowed(Permission.ApproveTrade)">
            <div class="schedule-request-button" style="border: 0">
              <div
                class="card-img pointer"
                (click)="modalTradeBoard.show(true)">
                <icons name="CalendarPlus"></icons>
                <p class="card-text m-0">Pending</p>
                <p class="card-text m-0">Trades</p>
                <div
                  class="card-img-overlay"
                  *ngIf="allTrades"
                  style="top: -15%; left: 45%">
                  <span class="notification-badge">{{allTrades}}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col text-center"
            style="min-width: 90px; max-width: 110px"
            *ngIf="isAllowed(Permission.ApproveTrade)">
            <div class="schedule-request-button" style="border: 0">
              <div
                class="card-img pointer"
                (click)="modalTradeBoardApprove.show()">
                <icons name="CalendarCheck"></icons>
                <p class="card-text m-0">Trades to Approve</p>
                <div
                  class="card-img-overlay"
                  *ngIf="needsApproval"
                  style="top: -15%; left: 45%">
                  <span class="notification-badge">{{needsApproval}}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="isAllowed(Permission.ApproveTimeOff)"
            class="col text-center"
            style="min-width: 90px; max-width: 110px">
            <modal-timeoff-approval></modal-timeoff-approval>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
