import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { map } from 'rxjs/operators';
import { IInventoryCount } from '../shared/api';
import { AccountService } from './account.service';
import { IInventoryReport } from 'source/shared/types';

@Injectable()
export class InventoryCountService extends BaseService<IInventoryCount> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'inventoryCount');
  }

  updateInventoryCount(data: IInventoryCount[]): Observable<IInventoryCount[]> {
    if (!data || data.length == 0) return of([]);

    return this.http
      .post<IInventoryCount[]>(
        this.baseUrl + 'update',
        data,
        this.defaultOptions
      )
      .pipe(
        map(data => {
          this.notifyUpdate();
          return data;
        })
      );
  }

  reportQuantity(): Observable<IInventoryReport[]> {
    return this.http.post<IInventoryReport[]>(
      this.baseUrl + 'reportQuantity',
      null,
      this.defaultOptions
    );
  }
}

export { IInventoryCount } from '../shared/api';
