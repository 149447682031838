import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardJail, AuthGuardFreemium } from '../app/auth-guard';
import { DocsComponent } from './docs.component';
import { FolderComponent } from './folder.component';
import { ManageComponent } from './manage.component';
import { QuizEditComponent } from './quiz.component';
import { UploadComponent } from './upload.component';

const appRoutes: Routes = [
  {
    path: 'docs',
    canActivate: [AuthGuard, AuthGuardFreemium, AuthGuardJail],
    component: DocsComponent,
    children: [
      { path: '', component: DocsComponent },
      { path: 'add', component: ManageComponent },
      {
        path: ':groupId',
        children: [
          { path: '', component: FolderComponent },
          { path: 'upload', component: UploadComponent },
          { path: 'edit', component: ManageComponent },
          {
            path: ':id',
            children: [
              { path: '', component: UploadComponent },
              { path: 'quiz', component: QuizEditComponent },
            ],
          },
        ],
      },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
