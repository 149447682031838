import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { baseRole } from '../shared/baseRole';
import { ISubNavItem } from '../shared/api';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './inventory.html',
})
export class InventoryComponent extends baseRole implements OnInit {
  public menu: ISubNavItem[];
  public _menu: ISubNavItem[] = [
    {
      label: "SKU's",
      routerLink: '/inventory',
      exact: true,
    },
    {
      label: 'Inventory Locations',
      routerLink: '/inventory/locations',
    },
    {
      label: 'Recipe Blocks',
      routerLink: '/inventory/recipe',
    },
    {
      label: 'Menu Items',
      routerLink: '/inventory/menu',
    },

    {
      label: 'Take Inventory',
      routerLink: '/inventory/take',
    },

    {
      label: 'Purchase Orders',
      routerLink: '/inventory/orders/purchase',
    },
    {
      label: 'Standing Orders',
      routerLink: '/inventory/orders/standing',
    },
    {
      label: 'Report',
      routerLink: '/inventory/report',
    },
  ];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    super(accountService);
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Inventory');
  }

  ngOnInit() {
    if (
      !(
        this.isAllowed(this.Permission.ManageInventory) ||
        this.isAllowed(this.Permission.CountInventory)
      )
    ) {
      this.router.navigate(['/']);
      return;
    }
    this.menu = this._menu.slice();
    if (!this.isAllowed(this.Permission.EditPO)) {
      this.menu = this.menu.filter(e => {
        return !(e.routerLink.indexOf('/inventory/orders') >= 0);
      });
    }
    if (!this.isAllowed(this.Permission.CountInventory)) {
      this.menu = this.menu.filter(e => {
        return (
          e.routerLink.indexOf('/inventory/take') < 0 &&
          e.routerLink.indexOf('/inventory/report') < 0
        );
      });
    }
  }
}
