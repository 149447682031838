<div *ngIf="user">
  <shift-preferences [(ngModel)]="userMap.pref_dna"></shift-preferences>

  <div class="row mt-3">
    <div class="col-sm-12">
      <button class="btn float-right" type="button" routerLink="/users">
        Cancel
      </button>
      <save-button class="float-right" (click)="save()"></save-button>
    </div>
  </div>
</div>
