import { Component } from '@angular/core';
import { AccountService } from '../services/account.service';
import {
  IApiUser,
  IApiLocation,
  UserType,
  UserStatus,
  Permission,
  getLocationImage,
  getUserProfileImage,
} from './api';

export class baseRole {
  public UserType = UserType;
  public Permission = Permission;
  public yesNoArray = [{ 0: 'No' }, { 1: 'Yes' }];

  constructor(protected accountService: AccountService) {}

  getUser(): IApiUser {
    return this.accountService.getUser();
  }
  getUserId(): number {
    return this.accountService.getUserId();
  }
  isLoggedIn(): boolean {
    return this.getUser() != null;
  }

  inRole(account: UserType[] | UserType): boolean {
    return this.accountService.inRole(account);
  }

  inJail(): boolean {
    return this.accountService.inJail();
  }

  isAllowed(perm: Permission): boolean {
    return this.accountService.isAllowed(perm);
  }

  isFreemium(): boolean {
    return this.accountService.isFreemium();
  }

  getLocation(): IApiLocation {
    return this.accountService.getLocation();
  }
  getLocationId(): number {
    if (this.getLocation()) return this.getLocation().id;
    return null;
  }

  isAdmin(): boolean {
    return this.accountService.isAdmin();
  }

  isOwner(): boolean {
    return this.accountService.isOwner();
  }

  getLocationImage(has_image: string): string {
    return getLocationImage(has_image);
  }

  getUserProfileImage(
    has_image: string,
    firstName?: string,
    lastame?: string,
    color?: string
  ): string {
    return getUserProfileImage(has_image, firstName, lastame, color);
  }
}

export { IApiUser, IApiLocation, UserStatus } from './api';
