<div class="row">
  <div class="col-12">
    <button
      class="btn btn-primary float-right mb-2"
      type="button"
      routerLink="add">
      Add Notification
    </button>
  </div>
</div>

<div class="row row-table row-header" *ngIf="data">
  <div class="col-2">Type</div>
  <div class="col-1">Active</div>
  <div class="col-2">To</div>
  <div class="col-6">Subject</div>
  <div class="col-1"></div>
</div>

<div class="row-table row-striped" *ngIf="data">
  <div
    class="row"
    *ngFor="let d of data | orderBy: ['notification_type','is_active']">
    <div class="col-2">{{ NotificationType[d.notification_type]}}</div>
    <div class="col-1">{{ (d.is_active?'Yes':'No') }}</div>
    <div class="col-2">{{ d.to }}</div>
    <div class="col-6">{{d.subject}}</div>
    <div class="col-1">
      <span class="cdi-s-edit ml-auto pointer" routerLink="{{d.id}}"></span>
    </div>
  </div>
</div>
