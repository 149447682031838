<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Load schedule from template
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <form ngNativeValidate (submit)="save()">
        <div class="modal-body" *ngIf="data && data.length==0">
          <div class="card">
            <div class="card-body"><h4>There are no saved templates.</h4></div>
          </div>
        </div>
        <div class="modal-body" *ngIf="data && data.length>0">
          <div class="card">
            <div class="card-body">
              <label text-center>Choose Template</label>
              <select
                [(ngModel)]="selectedTemplate"
                placeholder="Please select a template"
                name="template"
                class="form-control d-inline-block"
                required>
                <option *ngFor="let d of data" [value]="d.id">
                  {{d.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <label>Template Load Action</label>
              <div class="row">
                <div class="col">
                  <input-toggle [(ngModel)]="loadUnassigned" name="unassigned"
                    >Load All Shifts Unassigned</input-toggle
                  >
                </div>
                <div class="col">
                  <input-toggle [(ngModel)]="clearShifts" name="clear_shifts"
                    >Clear Shifts First</input-toggle
                  >
                </div>
              </div>
            </div>
          </div>
          By default loaded shifts are ASSIGNED and ADDED to existing shifts.
          Shifts in the template assigned to inactive employees will be
          un-assigned when loaded.
          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right mx-2"
                (click)="cancel()"></cancel-button>
              <button class="btn btn-primary float-right" type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
