<div class="row justify-content-center">
  <div class="col-md-8 col-12 card">
    <div class="row mt-2" *ngIf="error">
      <div class="col-12">
        <alert type="danger">{{error}} </alert>
      </div>
    </div>
    <div class="jumbotron bg-secondary text-white mt-2">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="mx-4">
              <h1 class="display-4">
                Simplified, Fast Funding for Your Growing Business
              </h1>
              <p class="lead">
                Take your business to the next level with our easy and fast loan
                process.
              </p>
              <hr class="my-4" />
              <div *ngIf="location && location.user_type == UserType.Owner">
                <button id="kanmon-launch-button" class="btn btn-primary">
                  Need financing?
                </button>
              </div>
              <div *ngIf="location && location.user_type != UserType.Owner">
                <button [disabled]="true" class="btn btn-primary">
                  If you're thinking about financing, please reach out to the
                  location account owner for more information.
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <img
              src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
              class="img-fluid"
              alt="Responsive image" />
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row my-4">
        <div class="col-md-6">
          <h4>Where can I learn more?</h4>
          <p>
            Please
            <a href="https://app.cheddrsuite.com/financing">click here</a> to
            access more information on CCap powered by Kanmon.
          </p>

          <h4>How do you determine my business's loan offer?</h4>
          <p>
            Loan eligibility is based on a variety of factors related to your
            business, including your outstanding debt balance, business expenses
            and income, account history, payment frequency, etc. To get started
            on your loan offer,
            <a href="https://app.cheddrsuite.com/financing">click here</a>.
          </p>

          <h4>Why do I need to provide my bank connection details?</h4>
          <p>
            By agreeing and connecting your business bank account, we will be
            able to analyze your cashflow and determine what would be the best
            loan offers for your business.
          </p>

          <h4>Does enrolling in the program affect my credit score?</h4>
          <p>
            No, during the onboarding process, we will only conduct a soft pull
            on your credit report, which will not affect your personal credit
            score.
          </p>

          <h4>When should I expect to receive a loan offer?</h4>
          <p>
            Once you are enrolled into the program, we will actively analyzing
            your financials and creating pre-approved offers that work best for
            your business needs. We do this to help you avoid debt trap.
          </p>

          <h4>Can I request a different loan amount?</h4>
          <p>
            Yes, you can either choose one of the offers we pre-approved for
            you, or submit the amount you prefer. Please note that at this
            moment the loan amount you request cannot exceed the highest amount
            from the offers.
          </p>
        </div>

        <div class="col-md-6">
          <h4>How do I repay the loan?</h4>
          <p>
            At the moment, we support monthly loan repayment once you agree to
            the loan payment schedule. A fixed amount will be automatically
            deducted from the bank you linked during the onboarding. In the near
            future, you also will have the ability to choose to pay back the
            loan as a fixed percentage of your daily sales until the loan is
            fully repaid.
          </p>

          <h4>Can I prepay my loan?</h4>
          <p>
            Yes! There are no penalties if you want to repay your loan early.
          </p>

          <h4>How do I track my outstanding balance and payment history?</h4>
          <p>
            Once you received the funding, you can come back to the admin portal
            located on this page to check your balance, payment history and
            other useful information we created for you.
          </p>

          <h4>Are there any late fees?</h4>
          <p>Currently we charge a $25 flat for late payments.</p>

          <h4>Can I change my payment schedule?</h4>
          <p>
            Currently we do not support changing repayment schedule. We will add
            this ability soon in the future.
          </p>

          <h4>What is Kanmon?</h4>
          <p>
            Kanmon is a licensed business lender. CheddrSuite has partnered with
            Kanmon to offer funding to CheddrSuite customers. All loans are
            subject to credit approval and financing terms may vary. Kanmon
            loans are issued by Kanmon Inc., and California loans are made
            pursuant to a Department of Financial Protection and Innovation
            California Lenders Law License. Read more about Kanmon at:
            <a href="http://www.kanmon.com">www.kanmon.com</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center" *ngIf="!actionRequired">
  <div class="col-md-8 col-12">
    <button
      type="button"
      class="btn btn-primary float-right my-2"
      (click)="editInformationModal.show()">
      Edit Information
    </button>
  </div>
</div>

<!-- <div
  bsModal
  #editInformationModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Edit Information"
  aria-hidden="true">
  <form class="modal-dialog modal-md" ngNativeValidate (submit)="save()">
    <div class="modal-content" *ngIf="!actionRequired">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Edit Information
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="editInformationModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="m-4" *ngIf="businessInfo">
          <h3>Business Information</h3>
          <div class="mx-2">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="searchText">Business name</label>
                <input
                  type="text"
                  class="form-control"
                  id="searchText"
                  [(ngModel)]="businessInfo.name"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group col-md-6">
                <label for="inputAddress">Address</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress"
                  [(ngModel)]="businessInfo.address.addressLineOne"
                  [(ngModel)]="businessInfo.address.addressLineOne"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="form-group">
              <label for="inputAddress2">Address 2</label>
              <input
                type="text"
                class="form-control"
                id="inputAddress2"
                [(ngModel)]="businessInfo.address.addressLineTwo"
                [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputCity">City</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputCity"
                  [(ngModel)]="businessInfo.address.city"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group col-md-4">
                <label for="inputState">State</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputState"
                  [(ngModel)]="businessInfo.address.state"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group col-md-2">
                <label for="inputZip">Zip</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputZip"
                  [(ngModel)]="businessInfo.address.zipcode"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="form-group">
              <label for="inputCountry">Country</label>
              <input
                type="text"
                class="form-control"
                id="inputCountry"
                [(ngModel)]="businessInfo.address.country"
                [ngModelOptions]="{standalone: true}" />
            </div>
          </div>
        </div>
        <div class="m-4" *ngIf="businessOwner">
          <h3>Business Owner</h3>
          <div class="mx-2">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="firstName">First name</label>
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  [(ngModel)]="businessOwner.firstName"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group col-md-4">
                <label for="middleName">Middle name</label>
                <input
                  type="text"
                  class="form-control"
                  id="middleName"
                  [(ngModel)]="businessOwner.middleName"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group col-md-4">
                <label for="lastName">Last name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  [(ngModel)]="businessOwner.lastName"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="form-group">
              <label for="inputOwnerEmail">Email</label>
              <input
                type="email"
                class="form-control"
                id="inputOwnerEmail"
                [(ngModel)]="businessOwner.email"
                [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="form-group">
              <label for="inputOwnerAddress1">Address</label>
              <input
                type="text"
                class="form-control"
                id="inputOwnerAddress1"
                [(ngModel)]="businessOwner.address.addressLineOne"
                [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="form-group">
              <label for="inputOwnerAddress2">Address 2</label>
              <input
                type="text"
                class="form-control"
                id="inputOwnerAddress2"
                [(ngModel)]="businessOwner.address.addressLineTwo"
                [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputOwnerCity">City</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputOwnerCity"
                  [(ngModel)]="businessOwner.address.city"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group col-md-4">
                <label for="inputOwnerState">State</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputOwnerState"
                  [(ngModel)]="businessOwner.address.state"
                  [ngModelOptions]="{standalone: true}" />
              </div>
              <div class="form-group col-md-2">
                <label for="inputOwnerZip">Zip</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputOwnerZip"
                  [(ngModel)]="businessOwner.address.zipcode"
                  [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="form-group">
              <label for="inputOwnerCountry">Country</label>
              <input
                type="text"
                class="form-control"
                id="inputOwnerCountry"
                [(ngModel)]="businessOwner.address.country"
                [ngModelOptions]="{standalone: true}" />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="editInformationModal.hide()"></cancel-button>
            <button type="submit" class="btn btn-primary float-right mr-2">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div> -->
