import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import {
  AccountService,
  IApiUser,
  IApiLocation,
} from '../services/account.service';
import { TimeOffService, ITimeOff } from '../services/timeOff.service';
import { baseRole } from '../shared/baseRole';
import { UserService, IUser } from '../services/user.service';
import { BlackOutService } from 'source/services/blackOut.service';
import * as moment from 'moment';

@Component({
  selector: 'modal-timeoff',
  templateUrl: 'modalTimeOff.html',
})
export class ModalTimeOffComponent extends baseRole implements OnInit {
  public data: ITimeOff;
  public timeOffs: ITimeOff[];
  public baseUser: any;
  public users: any[];
  public to_days: any;
  public daysOff = null;
  public error: any = {};
  public minDate: Date;
  public blackOuts: any[];
  public blackoutError: any;

  @ViewChild('modal')
  public modal: ModalDirective;

  constructor(
    public accountService: AccountService,
    public timeOffService: TimeOffService,
    public blockOutService: BlackOutService,
    public userService: UserService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    this.to_days = this.getLocation().to_days;
    this.minDate = new Date();
    this.roundHours(this.minDate);
    this.minDate.setHours(0);
    this.minDate.setDate(this.minDate.getDate() + this.to_days);
    this.daysOff = null;

    this.data = {
      user_id: this.baseUser ? this.baseUser.id : this.getUserId(),
      location_id: this.getLocationId(),
      whole_day: true,
      start_date: new Date(this.minDate),
      end_date: new Date(this.minDate),
    };
    this.roundHours(this.data.start_date);
    this.roundHours(this.data.end_date);

    let filterObj: any = {};

    if (!this.baseUser) {
      this.userService.list().subscribe(data => {
        this.users = data;
      });
      this.modal.show();
    } else {
      filterObj.user_id = this.baseUser.id;
      this.timeOffService
        .list({ user_id: this.baseUser.id })
        .subscribe(data => {
          this.timeOffs = data;
          this.modal.show();
        });
    }
    this.blockOutService.listCurrent().subscribe(blackOuts => {
      this.blackOuts = blackOuts;
    });
  }

  changeUser() {
    this.timeOffService.list({ user_id: this.data.user_id }).subscribe(data => {
      this.timeOffs = data;
      /*this.pendingTimeOffs = data.filter((e)=> { return e.approved===null; });
			this.approvedTimeOffs = data.filter((e)=> { return e.approved!==null; });*/
    });
  }

  save() {
    this.error = {};
    this.blackoutError = null;
    if (this.data.whole_day) {
      this.roundHours(this.data.start_date);
      this.data.start_date.setHours(0);
      this.data.end_date = new Date(this.data.start_date);
      this.data.end_date.setDate(this.data.end_date.getDate() + this.daysOff);
    } else {
      this.data.end_date.setFullYear(this.data.start_date.getFullYear());
      this.data.end_date.setMonth(this.data.start_date.getMonth());
      this.data.end_date.setDate(this.data.start_date.getDate());
    }

    if (this.data.end_date < this.data.start_date) {
      this.error.end_date = 'The end date must be after the start date';
      return;
    }

    if (
      !this.blackOuts ||
      !this.data ||
      !this.data.start_date ||
      !this.data.end_date
    ) {
      this.blackoutError = 'Required data or dates are missing';
      return;
    }

    for (let blackout of this.blackOuts) {
      // Properly check if dates are overlapping
      if (
        (blackout.start <= this.data.end_date &&
          blackout.end >= this.data.start_date) ||
        (blackout.end >= this.data.start_date &&
          blackout.start <= this.data.end_date)
      ) {
        this.blackoutError = `Conflict with Blackout date from ${moment(
          blackout.start
        ).format('ddd MMMM DD, YYYY')} to ${moment(blackout.end).format(
          'ddd MMMM DD, YYYY'
        )}`;
        return;
      }
    }
    this.timeOffService.update(this.data).subscribe(data => {
      this.blackoutError = null;
      this.timeOffs = [data, ...this.timeOffs];
    });
  }

  roundHours(date: Date) {
    date.setMinutes(0);
    date.setSeconds(0);
  }

  show(user?: any) {
    this.baseUser = user;
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  delete(obj) {
    this.timeOffService.delete(obj.id).subscribe(data => {
      let idx = this.timeOffs.indexOf(obj);
      this.timeOffs.splice(idx, 1);
    });
  }
}
