import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { of } from 'rxjs';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { ItemService, IItem } from '../services/item.service';
import {
  ItemCategoryService,
  IItemCategory,
} from '../services/itemCategory.service';
import {
  ItemVariationService,
  IItemVariation,
} from '../services/itemVariation.service';
import { MediaService } from '../services/media.service';
import { baseRole } from '../shared/baseRole';
import { SelectAddAll, ImageMimeTypes } from '../shared/api';

@Component({
  selector: 'modal-item',
  templateUrl: 'modalItem.html',
})
export class ModalItemComponent extends baseRole implements OnInit {
  public data: IItem;
  public variations: any[];
  public newVariation: IItemVariation;
  public newError: string = null;
  public deletedVariations: any[];
  public categories: IItemCategory[];
  public fileData;
  public fileError: string = null;
  public saveMessage: string = null;
  @ViewChild('fileInput')
  public fileInput;

  @ViewChild('modal')
  public modal: ModalDirective;

  @Output() onChange: EventEmitter<IItem> = new EventEmitter<IItem>();

  constructor(
    public accountService: AccountService,
    public itemService: ItemService,
    public mediaService: MediaService,
    public itemCategoryService: ItemCategoryService,
    public itemVariationService: ItemVariationService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    this.deletedVariations = [];
    this.newVariation = {} as IItemVariation;
    this.itemCategoryService.list().subscribe(data => {
      this.categories = this.data.item_category_id
        ? data
        : SelectAddAll(data, 'Select a Category', 'name');
    });
    if (this.data && this.data.id) {
      this.itemVariationService
        .list({ item_id: this.data.id })
        .subscribe(data => {
          this.variations = data;
          this.modal.show();
        });
    } else {
      this.variations = [];
      this.modal.show();
    }
  }

  show(data: IItem = null) {
    this.saveMessage = null;
    this.data = data || ({} as IItem);
    this.load();
  }

  handleFileChange(files) {
    if (!files || files.length == 0) this.fileData = null;
    else this.fileData = files[0];
    let mimeType = this.fileData.type;

    if (ImageMimeTypes.indexOf(mimeType) < 0) {
      this.fileError = 'Upload must be an image file';
      this.fileData = null;
      this.fileInput.nativeElement.value = '';
      setTimeout(() => {
        this.fileError = null;
      }, 5000);
    }
  }

  deleteVariation(v) {
    if (v.id) {
      this.deletedVariations.push(v);
    }
    let idx = this.variations.indexOf(v);
    this.variations.splice(idx, 1);
  }

  addVariation() {
    this.newError = null;
    if (!this.newVariation.name || this.newVariation.name.length == 0) {
      this.newError = 'Variation name is a required field';
      return;
    }
    if (this.newVariation.price == null) {
      this.newError = 'Price is a required field';
      return;
    }
    this.variations.push(this.newVariation);
    this.newVariation = {} as IItemVariation;
  }

  cancel() {
    this.fileInput.nativeElement.value = '';
    this.modal.hide();
  }

  save() {
    if (this.newVariation.name && this.newVariation.price) this.addVariation();
    if (this.variations.length == 0) {
      //		console.log('variation length==0');
      this.newError = 'At least one variation name and price is needed';
      return;
    }
    this.saveMessage = 'Saving';

    this.fileInput.nativeElement.value = '';
    // Might need a bulk delete on this..
    let ids = [];
    for (let t of this.deletedVariations) {
      ids.push(t.id);
    }
    if (ids.length > 0) {
      this.itemVariationService.bulkDelete(ids).subscribe(() => {});
    }

    this.itemService.update(this.data).subscribe(data => {
      this.data.id = data.id;
      let p = of(null);
      if (this.fileData) {
        //			console.log('Updating on id: '+this.data.id);
        p = this.mediaService.upload(this.fileData, 'item', this.data.id);
      }
      p.subscribe(res => {
        this.fileData = null;
        if (res) {
          this.data.has_image = res;
        }
        if (this.variations.length > 0) {
          for (let v of this.variations) {
            v.item_id = data.id;
          }
          this.itemVariationService
            .bulkUpdate(this.variations)
            .subscribe(data => {
              this.doClose();
            });
        } else {
          this.doClose();
        }
      });
    });
  }

  doClose() {
    this.onChange.emit(this.data);
    this.modal.hide();
  }
}
