<div class="card">
  <div class="d-inline">
    <div class="title text-center mt-3">Invoices</div>
  </div>
  <div class="card-body">
    <div class="table-responsive-md">
      <table class="table table-striped">
        <thead class="thead-light">
          <tr>
            <th>Company</th>
            <th>Invoice</th>
            <th>Amount</th>
            <th>Date</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody *ngIf="data">
          <tr *ngFor="let i of data | orderBy:'-date'">
            <td>{{ i.company}}</td>
            <td>{{ i.ponumber}}</td>
            <td>{{ i.amount | currency: 'USD' }}</td>
            <td>{{ i.date | date:'EEEE MMM d, y'}}</td>
            <td>
              <span
                class="cdi-s-view pointer"
                [routerLink]="['/contacts/vendor/invoices',i.contact_id,i.id]"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <label>Total Invoices For This Vendor: {{total | currency: 'USD' }}</label>
  </div>
</div>
<!-- <cancel-button class="float-right" routerLink="/contacts"></cancel-button> -->
