import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';

import { ModalProfitLossComponent } from './modalProfitLoss.component';
import { PayrollComponent } from './payroll.component';
import { ReportsComponent } from './reports.component';
import { SalesComponent } from './sales.component';

import { routing } from './reports.routing';

@NgModule({
  declarations: [
    ModalProfitLossComponent,
    PayrollComponent,
    ReportsComponent,
    SalesComponent,
  ],
  imports: [SharedModule, routing],
  providers: [],
})
export class ReportsModule {}
