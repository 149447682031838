<div class="container-fluid subnav-padding">
  <form ngNativeValidate (submit)="save(null)">
    <div class="title text-center mt-3">Locations</div>
    <div class="input-group">
      <input
        type="text"
        maxlength="50"
        class="form-control form-control-lg"
        name="newLocation"
        placeholder="Add Inventory Location"
        [(ngModel)]="name"
        required />
      <div class="input-group-append">
        <button class="btn btn-primary" type="submit">
          <icons name="Plus"></icons>
        </button>
      </div>
    </div>
  </form>

  <div class="card">
    <table class="table table-hover mb-0">
      <tbody [dragula]="'locations'">
        <tr
          *ngFor="let location of data | orderBy: 'order_by'"
          [attr.data-location-id]="location.id">
          <td class="cdi-s">
            <icons class="icons pointer" name="GripVertical"></icons>
          </td>
          <td style="vertical-align: middle">
            <span *ngIf="!location._edit" style="cursor: move"
              >{{location.name}}</span
            >
            <input
              type="text"
              maxlength="50"
              class="form-control form-control-lg"
              style="cursor: move"
              [(ngModel)]="location._edit.name"
              *ngIf="location._edit"
              [name]="'location_name_'+location.id" />
          </td>
          <td class="cdi-s">
            <span *ngIf="!location._edit" (click)="edit(location)">
              <icons class="icons pointer" name="Edit"></icons>
            </span>
            <span *ngIf="location._edit" (click)="save(location)">
              <icons class="icons pointer" name="Save2"></icons>
            </span>
          </td>
          <td class="cdi-s">
            <delete-modal
              *ngIf="!location._edit && location._can_delete"
              [deleteObject]="location"
              [name]="location.name"
              (onDelete)="delete($event)">
              <icons class="icons pointer" name="Trash"></icons>
            </delete-modal>
            <span *ngIf="!location._edit && !location._can_delete">
              <div class="icon-stack">
                <icons class="pointer" name="CircleSlash"> </icons>
                <icons class="pointer" name="Trash"> </icons>
              </div>
            </span>
            <span
              *ngIf="location._edit"
              (click)="location._edit = !location._edit">
              <icons class="icons pointer" name="Close"></icons>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
