<div class="text-center" style="width: auto">
  <span class="title">Manage Locations</span>
  <button
    class="btn btn-primary float-right"
    type="button"
    routerLink="/settings/account/new">
    Add Location
  </button>
</div>

<div *ngIf="locations">
  <div class="card" *ngFor="let loc of locations | orderBy: ['id']">
    <div class="card-body">
      <div class="row d-flex h-100">
        <div
          class="col-md-3 col-12 mb-3 d-flex flex-column justify-content-between">
          <div>
            <b style="font-size: 1.2rem">Location Information</b><br />
            <div class="h-auto d-flex">
              <img
                class="rounded"
                style="margin-right: 1.5rem; height: 82px; width: 82px"
                [src]="getLocationImage(loc.has_image)" />
              <div class="d-flex flex-column">
                <div><b>{{loc.name}}</b></div>
                <div>{{loc.address_1}}</div>
                <div *ngIf="loc.address_2">{{loc.address_2}}</div>
                <div>{{loc.city}}, {{loc.state}} {{loc.zip}}</div>
              </div>
            </div>
          </div>
          <div>
            <a
              class="btn btn-secondary"
              *ngIf="loc.billing_plan_type==BillingPlanType.Monthly"
              [routerLink]="['/settings/account',loc.id]">
              Edit Location Details</a
            >
          </div>
        </div>
        <div
          class="col-md-3 col-12 mb-3 d-flex flex-column justify-content-between">
          <div>
            <b style="font-size: 1.2rem">Package and Payment Information</b
            ><br />
            Package: <strong>{{ PackageTypeNames[loc.package_type] }}</strong>
          </div>
          <br />
          <div>
            <a
              class="btn btn-secondary"
              *ngIf="loc.billing_plan_type==BillingPlanType.Monthly"
              [routerLink]="['/settings/account',loc.id,'package']">
              Edit Package</a
            >
            <span *ngIf="loc.billing_plan_type!=BillingPlanType.Monthly">
              Please contact CheddrSuite to make changes to your plan.
            </span>
          </div>
        </div>
        <div
          class="col-md-3 col-12 mb-3 d-flex flex-column justify-content-between">
          <b style="font-size: 1.2rem">Payment Information</b><br />
          <div>
            <a
              class="btn btn-secondary"
              *ngIf="loc.billing_plan_type==BillingPlanType.Monthly"
              (click)="createStripePortalSession(loc.id)">
              Manage Billing
            </a>
            <span *ngIf="loc.billing_plan_type!=BillingPlanType.Monthly">
              Please contact CheddrSuite to make changes to your plan.
            </span>
          </div>
        </div>
        <div
          class="col-md-3 col-12 mb-3 d-flex flex-column justify-content-between">
          <strong style="font-size: 1.2rem">Sales Integration</strong><br />
          <span
            *ngIf="loc.package_type!=PackageType.Pro || !loc.sales_integration_type"
            >None</span
          >
          <span
            *ngIf="loc.package_type==PackageType.Pro && loc.sales_integration_type">
            <img
              [src]="'/images/vendor/'+SalesIntegrationFeatures[loc.sales_integration_type].image | safeUrl" />
          </span>
          <br />
          <div>
            <a
              class="btn btn-secondary"
              *ngIf="loc.package_type==PackageType.Pro"
              [routerLink]="['/settings/account',loc.id,'integration']">
              Edit Integration
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
