import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  Params,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { ISubNavItem } from './api';

@Component({
  selector: 'sub-nav',
  templateUrl: './subnav.html',
})
export class SubNavComponent implements OnInit, OnDestroy {
  public isCollapsed = false;
  public collapse = true;
  public routeSub;
  public title: string;
  public currentUrl: string;
  public activeMenu: ISubNavItem;

  @Input() canCollapse = true;
  @Input() menu: ISubNavItem[];

  constructor(private router: Router) {
    this.routeSub = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        //console.log('URL: '+this.currentUrl);
        this.setTitle();
      }
    });
  }

  setTitle() {
    if (this.menu && this.currentUrl) {
      this.title = null;
      for (let m of this.menu) {
        if (
          (m.exact && m.routerLink == this.currentUrl) ||
          (!m.exact && this.currentUrl.startsWith(m.routerLink))
        ) {
          this.activeMenu = m;
          this.title = m.label;
          //console.log('Set Title: '+this.title);
          break;
        }
      }
    }
  }

  ngOnInit() {
    this.setTitle();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
