<div class="container-fluid subnav-padding">
  <!--might want to do this as a list? -->
  <div class="row">
    <div class="col-md-3 col-12">
      <label class="pl-3"> Search SKU </label>
      <input
        [(ngModel)]="filterData.sku"
        debounce
        delay="300"
        (result)="filter()"
        type="text"
        name="sku_search"
        class="form-control"
        placeholder=" Search SKU" />
    </div>
    <div class="col-md-3 col-12">
      <label class="pl-3"> Vendor</label>
      <select
        class="form-control"
        name="data.contact_id"
        [(ngModel)]="filterData.contact_id"
        (ngModelChange)="filter()">
        <option *ngFor="let v of vendors" [value]="v.id">{{v.company}}</option>
      </select>
    </div>
    <div class="col-md-3 col-12">
      <label class="pl-3"> Expense Category </label>
      <select
        [(ngModel)]="filterData.expense_account_id"
        (ngModelChange)="filter()"
        name="expense_account_id"
        class="form-control">
        <option *ngFor="let ic of expenseAccounts" [value]="ic.id">
          {{ic.name}}
        </option>
      </select>
    </div>
    <div class="col-md-3 col-12">
      <label class="pl-3"> Location </label>
      <select
        [(ngModel)]="filterData.inventory_location_id"
        (ngModelChange)="filter()"
        name="inventory_location"
        class="form-control">
        <option *ngFor="let i of inventoryLocations" [value]="i.id">
          {{i.name}}
        </option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col my-2">
      <button
        type="button"
        class="btn btn-primary m-2"
        routerLink="/inventory/sku/add">
        Add SKU
      </button>

      <button
        type="button"
        class="btn btn-secondary m-2"
        (click)="clearFilter()">
        Clear All
      </button>
      <button
        type="button"
        class="btn btn-secondary m-2"
        routerLink="/inventory/sku/import/price">
        Import SKU Price
      </button>
    </div>
  </div>

  <div class="card my-2" *ngFor="let d of data | orderBy: 'name'">
    <div class="card-body row col-12">
      <div class="d-flex flex-column col-10">
        <label class="mb-0">{{d.name}} </label>
        <div>
          <b>Vendor:</b> {{ d.company}} - {{d.price | currency: 'USD'}} /
          {{d.buying_unit}}
        </div>
      </div>
      <div class="d-flex row col-2 justify-content-end">
        <div class="d-flex row" style="margin-right: -5px; margin-top: -10px">
          <icons
            [routerLink]="['/inventory/sku',d.id]"
            class="icons pointer"
            name="Edit">
          </icons>
          <icons
            (click)="showHideModal(d)"
            *ngIf="d.hide"
            class="icons pointer"
            name="EyeSlash"
            [color]="'#FF0000'">
          </icons>
          <icons
            (click)="showHideModal(d)"
            *ngIf="!d.hide"
            class="icons pointer"
            name="EyeVisible">
          </icons>
          <delete-modal
            [name]="d.name"
            *ngIf="d._can_delete"
            [deleteObject]="d"
            (onDelete)="deleteSku(d)">
            <icons class="icons pointer" name="Trash"></icons>
          </delete-modal>
          <span *ngIf="!d._can_delete">
            <div class="icon-stack">
              <icons class="pointer" name="CircleSlash"> </icons>
              <icons class="pointer" name="Trash"> </icons>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <p>Expense Categories can be changed in Settings &gt; Cost Options</p>
  <p *ngIf="data && data.length>0">
    SKUs used in a recipe block, item recipe, or on a purchase order cannot be
    deleted.
  </p>
</div>

<div
  bsModal
  #hideModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Hide Sku"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content" *ngIf="hideSku">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          {{hideSku.hide ? 'Unhide' : 'Hide'}} SKU
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="hideModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        SKU cannot be deleted, but we can {{hideSku.hide ? 'unhide' : 'hide'}}
        them. Are you sure you want to {{hideSku.hide ? 'unhide' : 'hide'}}
        {{hideSku.name}}?
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="hideModal.hide()"></cancel-button>
            <button
              class="btn btn-primary float-right mr-2"
              (click)="doHideSku()">
              {{hideSku.hide ? 'Unhide' : 'Hide'}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
