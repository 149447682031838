import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { ILocation, PackageType } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class LocationService extends BaseService<ILocation> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'location');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'free_until');
          this.fixDate(d, 'unlimited_texts_update');
          this.fixDate(d, 'sales_integration_update');
          this.fixDate(d, 'pref_auto_off');
          this.fixDate(d, 'credit_card_exp');
        }
      } else {
        this.fixDate(data, 'free_until');
        this.fixDate(data, 'unlimited_texts_update');
        this.fixDate(data, 'sales_integration_update');
        this.fixDate(data, 'pref_auto_off');
        this.fixDate(data, 'credit_card_exp');
      }
      return data;
    };
  }

  get(id?: string | number): Observable<ILocation> {
    if (!id) {
      id = this.accountService.getLocation().id;
    }
    return super.get(id);
  }

  search(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseUrl + 'search',
      data,
      this.defaultOptions
    );
  }

  activeReport(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'active');
  }

  owedByLocation(id: number, package_type = null): Observable<number> {
    let queryParameters = new HttpParams();
    if (!id) {
      id = this.accountService.getLocation().id;
    }

    if (package_type !== null) {
      // Package Swap
      queryParameters = queryParameters.set(
        'package_type',
        package_type.toString()
      );
    }
    return this.http.get<number>(this.baseUrl + id + '/owedByLocation', {
      params: queryParameters,
    });
  }

  payDue(): Observable<boolean> {
    let loc = this.accountService.getLocation();
    const id = this.accountService.getLocation().id;

    return this.http.get<boolean>(this.baseUrl + id + '/payDue').pipe(
      tap(result => {
        if (result) {
          loc.payment_status = true;
        }
      })
    );
  }

  // This is for a replace on a card
  owedByCard(
    user_credit_card_id: number
  ): Observable<
    { id: number; name: string; amount: number; package_type: PackageType }[]
  > {
    return this.http.get<
      { id: number; name: string; amount: number; package_type: PackageType }[]
    >(this.baseUrl + user_credit_card_id + '/owedByCard');
  }

  changePlan(data: {
    location_id: number;
    package_type?: number;
  }): Observable<boolean> {
    if (!data.location_id) {
      data.location_id = this.accountService.getLocation().id;
    }

    return this.http.post<boolean>(
      this.baseUrl + 'changePlan',
      data,
      this.defaultOptions
    );
  }

  create(data: any): Observable<ILocation> {
    data = this.preProcess(data);
    return this.http.post<ILocation>(this.baseUrl, data, this.defaultOptions);
  }

  update(data: any): Observable<ILocation> {
    return super.update(data).pipe(
      map(data => {
        let locs = this.accountService.getLocations();
        if (!locs || locs.length == 0) return data;

        let locList = locs.filter(e => {
          return e.id == data.id;
        });
        if (locList.length != 1) return data;
        let loc = locList[0];

        for (let key in data) {
          if (!(key in loc) || key == 'id') continue;
          //		console.log('Location setting key: '+key);
          loc[key] = data[key];
        }
        return data;
      })
    );
  }

  /*	updateLavu(data: any) : Observable<boolean> {
			return this.http.put<boolean>(this.baseUrl+'lavu/'+data.id,data,this.defaultOptions).pipe(map((data)=> { this.notifyUpdate(); return data; }));
	}*/

  updateOmnivore(data: any): Observable<boolean> {
    return this.http
      .put<boolean>(
        this.baseUrl + 'omnivore/' + data.id,
        null,
        this.defaultOptions
      )
      .pipe(
        map(data => {
          this.notifyUpdate();
          return data;
        })
      );
  }
}

export {
  ILocation,
  IApiLocation,
  LocationStatus,
  SalesIntegrationType,
} from '../shared/api';
