import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { baseRole } from '../shared/baseRole';
import {
  AccountService,
  IUser,
  IApiLocation,
} from '../services/account.service';
import {
  LocationUserMapService,
  ILocationUserMap,
} from '../services/locationUserMap.service';
import { WidgetType, WidgetNames } from '../shared/api';

@Component({
  templateUrl: './dashboard.html',
})
export class DashboardComponent extends baseRole implements OnInit {
  public userMap: ILocationUserMap;
  public WidgetType = WidgetType;
  public WidgetTypeKeys: number[]; // = Object.keys(WidgetType).filter(Number);
  public WidgetNames = WidgetNames;
  public widgetArray: boolean[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected locationUserMapService: LocationUserMapService
  ) {
    super(accountService);
    let tKeys = Object.keys(WidgetType).filter(Number);
    this.WidgetTypeKeys = [];
    for (let i = 0; i < tKeys.length; i++) {
      this.WidgetTypeKeys[i] = parseInt(tKeys[i]);
    }
  }

  ngOnInit() {
    this.locationUserMapService
      .list({ user_id: this.getUserId(), location_id: this.getLocationId() })
      .subscribe(data => {
        if (data && data.length == 1) {
          this.userMap = data[0];
          this.widgetArray = [];
          for (let k of this.WidgetTypeKeys) {
            this.widgetArray[k] = this.userMap.widgets.indexOf(k) >= 0;
          }
        }
      });
  }

  toggleWidget(widgetType: number) {
    let idx = this.userMap.widgets.indexOf(widgetType);
    if (idx < 0) {
      this.userMap.widgets.push(widgetType);
    } else {
      this.userMap.widgets.splice(idx, 1);
    }
    this.locationUserMapService
      .update({
        id: this.userMap.id,
        user_id: this.getUserId(),
        location_id: this.getLocationId(),
        widgets: this.userMap.widgets,
      })
      .subscribe(data => {
        this.getLocation().widgets = this.userMap.widgets.slice();
      });
  }
}
