import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { ISubNavItem } from '../shared/api';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './schedule.html',
})
export class ScheduleComponent implements OnInit {
  public menu: ISubNavItem[] = [
    {
      label: 'Add Shift',
      routerLink: '/schedule',
    },
    {
      label: 'Paste Prev Week',
      routerLink: '/schedule',
    },
    {
      label: 'Copy',
      routerLink: '/schedule',
    },
    {
      label: 'Filter',
      routerLink: '/schedule',
    },
    {
      label: 'Load Template',
      routerLink: '/schedule',
    },

    {
      label: 'Clear',
      routerLink: '/schedule',
    },
    {
      label: 'Trade Board',
      routerLink: '/schedule',
    },
    {
      label: 'Time Off',
      routerLink: '/schedule',
    },
    {
      label: 'Print Schedule',
      routerLink: '/schedule',
    },
  ];
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    private meta: Meta,
    private title: Title
  ) {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Discover CheddrSuite - the leading restaurant management software. Simplify operations, enhance staff scheduling, and elevate guest experiences. Trusted by top restaurateurs for peak efficiency.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Restaurant Management, Restaurant Operatio, POS Integration, Inventory Management, Staff Scheduling, Reporting and Analytics',
      },
    ]);
    this.title.setTitle('CheddrSuite | Schedule');
  }

  ngOnInit() {}
}
