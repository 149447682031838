import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import {
  ICapital,
  IKanmonBusiness,
  IKanmonBusinessOwner,
  IKanmonError,
  UserType,
} from 'source/shared/types';
import { AccountService, IApiLocation } from '../services/account.service';
import { CapitalService } from '../services/capital.service';
import { LocationService } from '../services/location.service';
import { baseRole } from '../shared/baseRole';

type UserState =
  | 'START_FLOW'
  | 'USER_INPUT_REQUIRED'
  | 'WAITING_FOR_OFFERS'
  | 'NO_OFFERS_EXTENDED'
  | 'VIEW_OFFERS'
  | 'OFFER_ACCEPTED'
  | 'SERVICING';

@Component({
  templateUrl: './home.html',
})
export class HomeComponent extends baseRole implements OnInit {
  private connectToken: string;
  private window: Window;
  public launchButton: any;
  protected location: IApiLocation;
  protected business: ICapital;
  public businessInfo: IKanmonBusiness;
  public businessOwner: IKanmonBusinessOwner;
  public userState: UserState;
  public actionRequired: boolean;
  public error: string;

  public searchText: any;
  @ViewChild('editInformationModal')
  public editInformationModal: ModalDirective;
  @Output() onEditBusinessInfo: EventEmitter<IKanmonBusiness> =
    new EventEmitter();
  @Output() onEditBusinessOwner: EventEmitter<IKanmonBusinessOwner> =
    new EventEmitter();
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected zone: NgZone,
    protected accountService: AccountService,
    protected locationService: LocationService,
    protected capitalService: CapitalService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(accountService);
    this.window = this.document.defaultView;
  }

  ngOnInit() {
    this.error = null;
    this.location = this.accountService.getLocation();
    // use search function if you want to pass in location_id in the request,
    // currently we only pass the user_id since kanmon cannot process multiple loan applications for the same business owner

    if (this.location && this.location.user_type == UserType.Owner) {
      this.capitalService
        ._search(this.accountService.getUserId())
        .subscribe((search: any) => {
          if (!search) {
            return this.capitalService
              .createBusiness({
                name: this.location.name,
              })
              .subscribe((business: ICapital) => {
                if (business.kanmon_business_id) {
                  return this.capitalService
                    .createBusinessOwner({
                      email: this.accountService.getUser().email,
                      businessId: business.kanmon_business_id,
                      locationId: this.location.id,
                      capitalId: business.id,
                    })
                    .subscribe(
                      ({
                        businessOwner,
                        error,
                      }: {
                        businessOwner: ICapital;
                        error: IKanmonError;
                      }) => {
                        if (error?.code) {
                          this.error = error.message;
                          // setTimeout(() => {
                          //   this.error = null;
                          // }, 5000);
                        }
                        this.business = {
                          ...businessOwner,
                          kanmon_business_id: business.kanmon_business_id,
                        };
                        this.load();
                      }
                    );
                }
              });
          } else {
            this.business = search;
            this.load();
          }
        });
    }
  }

  load() {
    const p = [
      this.capitalService.getBusinessOwnerInfo(
        this.business.kanmon_business_owner_id
      ),
      this.capitalService.getBusinessInfo(this.business.kanmon_business_id),
    ];

    forkJoin(p).subscribe(
      ([businessOwner, businessInfo]: [
        IKanmonBusinessOwner,
        IKanmonBusiness
      ]) => {
        this.businessOwner = businessOwner;
        this.businessInfo = businessInfo;
        if (this.businessOwner && this.businessInfo) {
          this.launchButton = document.getElementById('kanmon-launch-button');
          this.capitalService
            .connectToken(this.business.kanmon_business_owner_id)
            .subscribe((result: any) => {
              this.connectToken = result.connectToken;
              if (this.window) {
                // @ts-ignore
                this.window.KANMON_CONNECT.start({
                  launchMode: 'callback',
                  connectToken: this.connectToken,
                  callbackConfig: {
                    onUpdate: ({
                      actionMessage,
                      userState,
                      actionRequired,
                    }) => {
                      // This will set the launch buttons text whenever the users state changes
                      // See these messages here: https://kanmon.dev/docs/getting-started#userstate-type
                      this.userState = userState;
                      this.actionRequired = actionRequired;
                      this.launchButton.textContent = actionMessage;

                      console.log('actionMessage', actionMessage);
                      console.log('userState', userState);
                      console.log('actionRequired', actionRequired);
                    },
                  },
                });
              }
            });
          this.launchButton.addEventListener('click', () => {
            // @ts-ignore
            window.KANMON_CONNECT.show();
          });
        }
        // if (this.businessInfo) {
        //   this.capitalService
        //     .getAllIssuedProducts(this.businessInfo.id)
        //     .subscribe((products: any) => {
        //       console.log('products', products);
        //     });
        // }
      }
    );
  }

  save() {
    this.onEditBusinessInfo.emit(this.businessInfo);
    this.onEditBusinessOwner.emit(this.businessOwner);
    const p = [
      this.capitalService.updateBusinessOwnerInfo(this.businessOwner),
      this.capitalService.updateBusinessInfo(this.businessInfo),
    ];
    forkJoin(p).subscribe(result => {
      this.load();
    });

    this.editInformationModal.hide();
  }
}
