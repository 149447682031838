<div class="row" *ngIf="shifts">
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-body" style="line-height: 1.1">
        <label>Who's working right now</label>
        <span *ngIf="shifts.length==0">No Scheduled Shifts for Right Now </span>
        <div *ngFor="let s of shifts | orderBy:'start'">
          <div class="py-2 border-bottom">
            <img
              class="rounded float-left"
              style="margin-right: 1rem"
              [src]="getUserProfileImage(s.has_image, s.firstname, s.lastname, s.color) | safeUrl"
              height="28px" />
            {{s.firstname}} {{s.lastname}}
            <br />
            <span *ngIf="!s.end"
              >Clocked in {{ s.start | date: 'EEE h:mm a' }}</span
            >
            <span *ngIf="s.end"
              >{{ s.start | date: 'shortTime' }} - {{s.end | date: 'shortTime'
              }}</span
            >
            <div
              *ngIf="s._notClocked"
              class="text-danger"
              style="margin-left: 3.5rem">
              <small> Not clocked in</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
