import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  ItemCategoryService,
  IItemCategory,
} from '../services/itemCategory.service';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-item-category',
  templateUrl: 'modalItemCategory.html',
})
export class ModalItemCategoryComponent extends baseRole implements OnInit {
  public data: IItemCategory[];
  public newItem: any = { _edit: {} };
  public hasChanged = false;
  @Output() onDone: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modal')
  public modal: ModalDirective;

  constructor(
    public accountService: AccountService,
    public itemCategoryService: ItemCategoryService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  show() {
    this.itemCategoryService.list().subscribe(data => {
      this.data = data;
      this.newItem = { _edit: {} };
      this.modal.show();
    });
  }

  cancel() {
    this.newItem = null;
    if (this.hasChanged) {
      //			console.log('Emitting');
      this.onDone.emit();
    }
    this.modal.hide();
  }

  deleteObj(obj) {
    //		console.log('Deleteing');
    this.itemCategoryService.delete(obj.id).subscribe(data => {
      let idx = this.data.indexOf(obj);
      this.data.splice(idx, 1);
      this.hasChanged = true;
    });
  }

  cancelEdit(obj) {
    delete obj._edit;
  }

  editObj(obj) {
    obj._edit = Object.assign({}, obj);
  }
  saveObj(obj) {
    this.hasChanged = true;
    this.itemCategoryService.update(obj._edit).subscribe(data => {
      if (!obj.id) {
        data._can_delete = true;
        this.data.push(data);
        this.newItem._edit = {};
      } else {
        obj = Object.assign(obj, data);
        delete obj._edit;
      }
    });
  }
}
