import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICapital,
  IKanmonBusinessCreationRequest,
  IKanmonBusinessOwnerCreationRequest,
} from 'source/shared/types';
import { AccountService } from './account.service';
import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root',
})
export class CapitalService extends BaseService<ICapital> {
  constructor(
    protected accountService: AccountService,
    protected http: HttpClient
  ) {
    super(accountService, http, 'capital');
  }
  _search(user_id): Observable<any> {
    return this.http.get(this.baseUrl + 'search/' + user_id);
  }

  connectToken(userId): Observable<any> {
    return this.http.post(this.baseUrl + 'connect/token', {
      userId: userId,
    });
  }

  createBusiness({
    name,
    address,
  }: IKanmonBusinessCreationRequest): Observable<any> {
    return this.http.post(this.baseUrl + 'business/create', {
      name,
      address,
    });
  }
  createBusinessOwner({
    email,
    businessId,
    locationId,
    capitalId,
  }: IKanmonBusinessOwnerCreationRequest & {
    locationId: number;
    capitalId: number;
  }): Observable<any> {
    return this.http.post(this.baseUrl + 'business/create-owner', {
      email,
      businessId,
      locationId,
      capitalId,
    });
  }

  getBusinessInfo(businessId: string): Observable<any> {
    return this.http.get(this.baseUrl + 'business/info/' + businessId);
  }

  getBusinessOwnerInfo(primaryBusinessOwnerId: string): Observable<any> {
    return this.http.get(
      this.baseUrl + 'business/owner/' + primaryBusinessOwnerId
    );
  }

  updateBusinessInfo(data: any): Observable<any> {
    return this.http.patch(this.baseUrl + 'business/update', data);
  }

  updateBusinessOwnerInfo(data: any): Observable<any> {
    return this.http.patch(this.baseUrl + 'business/owner/update', data);
  }

  getAllIssuedProducts(businessId: string): Observable<any> {
    return this.http.get(this.baseUrl + 'products/' + businessId);
  }

  getIssuedProduct(issuedProductId: string): Observable<any> {
    return this.http.get(this.baseUrl + 'issued-products/' + issuedProductId);
  }
}
