import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
  timeToString,
  dateToLocal,
} from './base.service';
import { IChecklistUserMap, moment } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class ChecklistUserMapService extends BaseService<IChecklistUserMap> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'checklistUserMap');
  }

  bulkUpdate(
    data: {
      id: number;
      location_id: number;
      checklist_id: number;
      user_id: number;
      _checked: boolean;
    }[]
  ): Observable<any> {
    let userFilter = this.accountService.getUserFilter();
    data = userFilter.updateObject(data);
    return this.http.post<any>(
      this.baseUrl + 'massUpdate',
      data,
      this.defaultOptions
    );
  }
}

export { IChecklistUserMap } from '../shared/api';
