import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  PurchaseOrderService,
  PurchaseOrderStatus,
} from '../services/purchaseOrder.service';
import { RepeatOptions } from '../shared/api';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './standingOrder.html',
})
export class StandingOrderComponent extends baseRole implements OnInit {
  public standingList: any[];
  public pausedList: any[];
  public RepeatOptions = RepeatOptions;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected purchaseOrderService: PurchaseOrderService,
    protected accountService: AccountService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.ManageInventory)) {
      this.router.navigate(['/']);
      return;
    }
    if (!this.isAllowed(this.Permission.EditPO)) {
      this.router.navigate(['/inventory']);
      return;
    }
    this.load();
  }

  load() {
    this.purchaseOrderService
      .list({ status: PurchaseOrderStatus.Standing })
      .subscribe(data => {
        this.standingList = data;
      });
    this.purchaseOrderService
      .list({ status: PurchaseOrderStatus.Paused })
      .subscribe(data => {
        this.pausedList = data;
      });
  }

  delete(obj, isStandingList) {
    this.purchaseOrderService.delete(obj.id).subscribe(data => {
      if (isStandingList) {
        let idx = this.standingList.indexOf(obj);
        this.standingList.splice(idx, 1);
      } else {
        let idx = this.pausedList.indexOf(obj);
        this.pausedList.splice(idx, 1);
      }
    });
  }

  togglePause(obj) {
    let updateObj: any = {
      id: obj.id,
    };
    if (obj.status == PurchaseOrderStatus.Standing) {
      updateObj.status = PurchaseOrderStatus.Paused;
    } else {
      updateObj.status = PurchaseOrderStatus.Standing;
    }
    this.purchaseOrderService.update(updateObj).subscribe(data => {
      obj.status = updateObj.status;
      if (updateObj.status == PurchaseOrderStatus.Standing) {
        let idx = this.pausedList.indexOf(obj);
        this.pausedList.splice(idx, 1);
        this.standingList.push(obj);
      } else {
        let idx = this.standingList.indexOf(obj);
        this.standingList.splice(idx, 1);
        this.pausedList.push(obj);
      }
    });
  }
}
