<freemium *ngIf="isFreemium()"></freemium>

<div *ngIf="data && !isFreemium()">
  <div class="card" *ngIf="initialSetup">
    <div class="card-body text-center">
      <h3>
        Configure Your {{
        SalesIntegrationFeatures[data.sales_integration_type].name }} Connection
      </h3>
      Select the types of data you want to sync with your POS system.
    </div>
  </div>

  <div class="card" *ngIf="!initialSetup">
    <div class="card-body text-center">
      <h3>
        {{ SalesIntegrationFeatures[data.sales_integration_type].name }}
        Connection
        <button
          class="btn btn-primary"
          [routerLink]="'/settings/account/'+getLocationId()+'/integration'">
          Change
        </button>
      </h3>
    </div>
  </div>

  <div
    class="card mb-3"
    *ngIf="SalesIntegrationFeatures[data.sales_integration_type].employees">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="row">
            <div style="flex: 0 0 42px" class="pl-2">
              <div class="cdi-m-schedule nostate d-inline-block"></div>
            </div>
            <div class="col">
              <label class="pt-2">Employee Data</label>
            </div>
          </div>
          <hr class="my-0 mb-1" />
          Do you want to import employee data?<br />
          <input-yes-no-button
            [(ngModel)]="data.sales_integration_sync_employee_data"></input-yes-no-button>
          <br />
          Do you want us to send a welcome email or text to employees
          automatically once they've been imported? You may also send the
          welcome email/text manually at a later date from the Staff module.<br />
          <input-yes-no-button
            [(ngModel)]="data.sales_integration_send_employee_email"></input-yes-no-button>
        </div>
        <div class="col-md-6 col-12">
          <div class="card mt-0">
            <div class="card-body body-bg">
              <div
                [innerHTML]="SalesIntegrationFeatures[data.sales_integration_type].employee_data | safeHTML"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <!-- Outer Col -->
          <div
            class="card mb-3"
            [class.body-bg]="!data.sales_integration_sync_employee_data"
            *ngIf="SalesIntegrationFeatures[data.sales_integration_type].write_schedule || SalesIntegrationFeatures[data.sales_integration_type].read_schedule || SalesIntegrationFeatures[data.sales_integration_type].timeclock">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="row">
                    <div style="flex: 0 0 42px" class="pl-2">
                      <div
                        class="cdi-m-schedule cdi-smaller nostate d-inline-block"></div>
                    </div>
                    <div class="col">
                      <label class="pt-2">Shift &amp; Schedule Data</label>
                    </div>
                  </div>
                  <hr class="my-0 mb-1" />
                  <div
                    class="mb-3"
                    *ngIf="SalesIntegrationFeatures[data.sales_integration_type].write_schedule || SalesIntegrationFeatures[data.sales_integration_type].read_schedule">
                    Import or export shifts?<br />
                    <div class="row">
                      <div
                        class="col btn-group-toggle"
                        *ngIf="SalesIntegrationFeatures[data.sales_integration_type].read_schedule">
                        <label
                          class="btn btn-radio"
                          [class.disabled]="!data.sales_integration_sync_employee_data"
                          [class.active]="data.sales_integration_sync_shift==SalesIntegrationShiftSyncType.Import">
                          <input
                            [disabled]="!data.sales_integration_sync_employee_data"
                            type="radio"
                            name="sales_integration_sync_shift"
                            [value]="SalesIntegrationShiftSyncType.Import"
                            [(ngModel)]="data.sales_integration_sync_shift" />
                          Import from {{
                          SalesIntegrationFeatures[data.sales_integration_type].name
                          }}
                        </label>
                      </div>
                      <!--<div class='col btn-group-toggle' *ngIf="SalesIntegrationFeatures[data.sales_integration_type].write_schedule">
												<label class="btn btn-radio" [class.disabled]="!data.sales_integration_sync_employee_data" [class.active]="data.sales_integration_sync_shift==SalesIntegrationShiftSyncType.Export">
													<input type="radio" [disabled]="!data.sales_integration_sync_employee_data" name="sales_integration_sync_shift" [value]='SalesIntegrationShiftSyncType.Export' [(ngModel)]="data.sales_integration_sync_shift"> Export to {{ SalesIntegrationFeatures[data.sales_integration_type].name }}
												</label>
											</div>-->
                      <div class="col btn-group-toggle">
                        <label
                          class="btn btn-radio"
                          [class.disabled]="!data.sales_integration_sync_employee_data"
                          [class.active]="data.sales_integration_sync_shift==SalesIntegrationShiftSyncType.None">
                          <input
                            type="radio"
                            [disabled]="!data.sales_integration_sync_employee_data"
                            name="sales_integration_sync_shift"
                            [value]="SalesIntegrationShiftSyncType.None"
                            [(ngModel)]="data.sales_integration_sync_shift" />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="SalesIntegrationFeatures[data.sales_integration_type].timeclock">
                    Do you want to import employee timeclock data?
                    <input-yes-no-button
                      [disabled]="!data.sales_integration_sync_employee_data"
                      [(ngModel)]="data.sales_integration_sync_timeclock"></input-yes-no-button>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="card mt-0">
                    <div class="card-body body-bg">
                      <div
                        [innerHTML]="SalesIntegrationFeatures[data.sales_integration_type].shift_data | safeHTML"></div>
                      <!--<div *ngIf="SalesIntegrationFeatures[data.sales_integration_type].read_schedule">
												Cheddrsuite will perform an initial import of existing shift data to jumpstart shift building in CheddrSuite.
											</div>
											
											<div *ngIf="SalesIntegrationFeatures[data.sales_integration_type].write_schedule"> 
												When posting a schedule, CheddrSuite will write the data to your POS. Your employees will be able to use your POS to clock in and out. Cheddrsuite will pull in actual times into the system. <br>
					Updated or deleted CheddrSuite shifts will be posted to the POS when you publish (some POS types will have extra conditions).
											</div>

											<div *ngIf="SalesIntegrationFeatures[data.sales_integration_type].timeclock">
												Timeclocks will be  imported hourly|daily|etc (based on POS)
											</div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card mb-3"
            *ngIf="!(SalesIntegrationFeatures[data.sales_integration_type].write_schedule || SalesIntegrationFeatures[data.sales_integration_type].read_schedule || SalesIntegrationFeatures[data.sales_integration_type].timeclock)">
            <div class="card-body">
              <div class="row">
                <div style="flex: 0 0 42px" class="pl-2">
                  <div
                    class="cdi-m-schedule cdi-smaller nostate d-inline-block"></div>
                </div>
                <div class="col">
                  <label class="pt-2">Shift &amp; Schedule Data</label>
                </div>
              </div>
              <hr class="my-0 mb-1" />
              {{ SalesIntegrationFeatures[data.sales_integration_type].name }}
              does not support Shift &amp; Schedule data sync at this time.
            </div>
          </div>
        </div>
        <!-- Outer col -->
      </div>
    </div>
  </div>
  <div
    class="card mb-3"
    *ngIf="!SalesIntegrationFeatures[data.sales_integration_type].employees">
    <div class="card-body">
      <div class="row">
        <div style="flex: 0 0 42px" class="pl-2">
          <div class="cdi-m-schedule cdi-smaller nostate d-inline-block"></div>
        </div>
        <div class="col">
          <label class="pt-2">Employee Data</label>
        </div>
      </div>
      <hr class="my-0 mb-1" />
      {{ SalesIntegrationFeatures[data.sales_integration_type].name }} does not
      support employee data sync at this time.
    </div>
  </div>

  <div
    class="card mb-3"
    *ngIf="SalesIntegrationFeatures[data.sales_integration_type].sales">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="row">
            <div style="flex: 0 0 42px" class="pl-2">
              <div class="cdi-m-dash cdi-smaller nostate d-inline-block"></div>
            </div>
            <div class="col">
              <label class="pt-2">Sales Data</label>
            </div>
          </div>
          <hr class="my-0 mb-1" />
          Do you want to import sales data?<br />
          <input-yes-no-button
            [(ngModel)]="data.sales_integration_sync_sales_data"></input-yes-no-button>
        </div>
        <div class="col-md-6 col-12">
          <div class="card mt-0">
            <div class="card-body body-bg">
              <div
                [innerHTML]="SalesIntegrationFeatures[data.sales_integration_type].sales_data | safeHTML"></div>
              <!--Cheddrsuite will import your daily sales data.-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="card mb-3"
    *ngIf="!SalesIntegrationFeatures[data.sales_integration_type].sales">
    <div class="card-body">
      <div class="row">
        <div style="flex: 0 0 42px" class="pl-2">
          <div class="cdi-m-dash cdi-smaller nostate d-inline-block"></div>
        </div>
        <div class="col">
          <label class="pt-2">Sales Data</label>
        </div>
      </div>
      <hr class="my-0 mb-1" />
      {{ SalesIntegrationFeatures[data.sales_integration_type].name }} does not
      support sales data sync at this time.
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <cancel-button
        *ngIf="initialSetup"
        (click)="cancel()"
        class="float-right"></cancel-button>
      <save-button
        *ngIf="canSave()"
        (click)="save()"
        class="float-right"></save-button>
    </div>
  </div>
</div>
