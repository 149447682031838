import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { baseRole } from '../shared/baseRole';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { InvoiceService, IInvoice } from '../services/invoice.service';
import { DateMenuType } from '../shared/api';
import { formatDateOnly } from '../services/base.service';

@Component({
  templateUrl: './invoices.html',
})
export class InvoicesComponent extends baseRole implements OnInit, OnDestroy {
  public invoices: any[];
  public total: number;
  public sub: any;
  public invoiceSub: any;
  public currentDate: Date;
  public orderBy = 'company';
  public error: any = {};

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected invoiceService: InvoiceService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.EditInvoices)) {
      this.router.navigate(['/log']);
    }
    this.sub = this.accountService
      .getCurrentDateUpdateObservable(DateMenuType.Daily)
      .subscribe(date => {
        if (!date) return;
        this.currentDate = date;
        this.invoiceSub = this.invoiceService
          .getUpdateObservable()
          .subscribe(data => {
            this.load();
          });
      });
  }

  ngOnDestroy() {
    if (this.invoiceSub) this.invoiceSub.unsubscribe();
    if (this.sub) this.sub.unsubscribe();
  }

  load() {
    this.invoiceService
      .list({ date: formatDateOnly(this.currentDate) })
      .subscribe((data: any[]) => {
        this.total = 0;
        this.invoices = data;
        for (let i of data) {
          this.total += i.amount;
        }
      });
  }

  deleteInvoice(obj) {
    this.invoiceService.delete(obj.id).subscribe(data => {
      let idx = this.invoices.indexOf(obj);
      this.invoices.splice(idx, 1);
    });
  }
}
