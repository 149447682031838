import {
  Component,
  Input,
  Output,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { AccountService } from '../services/account.service';
import { DocService, IDoc } from '../services/doc.service';
import { IQuizPassed, QuizPassedService } from '../services/quizPassed.service';
import {
  IQuizQuestion,
  QuizQuestionService,
} from '../services/quizQuestion.service';
import { youTubeUrlToId, getBaseUrl } from '../shared/api';

// This is the dashboard component to list the current stuff that needs to get taken care of.
@Component({
  selector: 'doc-quiz-sign',
  templateUrl: './doc.quizSign.html',
})
export class DocQuizSignComponent implements OnInit, OnDestroy {
  public data;
  public sub;
  public sub2;
  public inLoad = false;
  @Output() count = new EventEmitter<number>();

  constructor(
    protected accountService: AccountService,
    protected docService: DocService,
    protected quizPassedService: QuizPassedService
  ) {}

  ngOnInit() {
    this.sub = this.docService.getUpdateObservable().subscribe(() => {
      this.load();
    });
    this.sub2 = this.quizPassedService.getUpdateObservable().subscribe(() => {
      this.load();
    });
  }

  load() {
    if (!this.inLoad) {
      this.inLoad = true;
      this.docService
        .listForUser(this.accountService.getUserId())
        .subscribe(data => {
          this.data = data;
          this.count.emit(data.length);
          this.inLoad = false;
        });
    }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.sub2) this.sub2.unsubscribe();
  }
}

@Component({
  selector: 'doc-quiz-sign-all',
  templateUrl: './doc.quizSignAll.html',
})
export class DocQuizSignAllComponent implements OnInit, OnDestroy {
  public sub;
  public accountSub;
  public data;
  public users: any[];
  public totalQuiz: number;
  public totalDocs: number;
  constructor(
    protected accountService: AccountService,
    protected docService: DocService
  ) {}

  ngOnInit() {
    this.accountService.getUserUpdateObservable().subscribe(() => {
      if (!this.sub) {
        this.sub = this.docService.getUpdateObservable().subscribe(() => {
          this.load();
        });
      } else {
        this.load();
      }
    });
  }

  load() {
    this.docService.listForUser(null).subscribe(data => {
      this.totalQuiz = 0;
      this.totalDocs = 0;
      this.users = [];
      this.data = data;
      let map: any = {};
      let user: any;
      for (let d of data) {
        if (!(d.user_id in map)) {
          map[d.user_id] = {
            user_id: d.user_id,
            firstname: d.firstname,
            lastname: d.lastname,
            docs: [],
          };
        }
        let match = false;
        if (d.signature && !d.signature_id) {
          this.totalDocs++;
          match = true;
        }
        if (d.quiz && d.quizReady && !d.quiz_passed_id) {
          this.totalQuiz++;
          match = true;
        }
        if (match) map[d.user_id].docs.push(d);
      }
      for (let k of Object.keys(map)) {
        this.users.push(map[k]);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.accountSub) this.accountSub.unsubscribe();
  }
}

@Component({
  selector: 'doc-view',
  templateUrl: './doc.view.html',
})
export class DocViewComponent {
  @Input() canEdit = false;
  @Input() viewOnly = false;
  @Input() doc;
  @Input() perms;
  @Output() onDelete = new EventEmitter<IDoc>();
  @Output() onComplete = new EventEmitter<boolean>();

  constructor(
    protected accountService: AccountService,
    protected docService: DocService
  ) {}

  deleteDoc(obj) {
    this.docService.delete(this.doc.id).subscribe(data => {
      this.onDelete.emit(this.doc);
    });
  }
  youTubeUrlToId(): string {
    if (this.doc && this.doc.video) return youTubeUrlToId(this.doc.video);
    return null;
  }
  isEmpty() {
    return this.docService.isEmpty(this.doc);
  }
  isPdf() {
    return this.docService.isPdf(this.doc);
  }
  isVideo() {
    return this.docService.isVideo(this.doc);
  }
  isImage() {
    return this.docService.isImage(this.doc);
  }
  getBaseUrl() {
    return getBaseUrl();
  }

  done() {
    this.onComplete.emit(true);
  }
}

@Component({
  selector: 'doc-quiz-view',
  templateUrl: 'doc.quiz.html',
})
export class DocQuizComponent {
  //	public questions: IQuizQuestion[];
  public questions: any[];
  public passed = null;
  public doc: IDoc;
  public answerKeys = ['a', 'b', 'c', 'd'];
  public saveMessage: string = null;

  @Input() perms: any;
  @Input() showClose = true;

  @Output() onClose = new EventEmitter<boolean>();

  @ViewChild('quizViewModal')
  public modal;

  public TQButton: any;

  constructor(
    protected accountService: AccountService,
    protected quizQuestionService: QuizQuestionService,
    protected quizPassedService: QuizPassedService
  ) {}

  show(doc) {
    this.doc = doc;
    this.load();
  }

  load() {
    this.passed = false;
    this.saveMessage = null;
    this.questions = null;
    this.quizQuestionService.list({ doc_id: this.doc.id }).subscribe(data => {
      this.questions = data;

      if (!this.perms) {
        this.quizPassedService
          .list({
            doc_id: this.doc.id,
            user_id: this.accountService.getUserId(),
          })
          .subscribe(data => {
            if (data && data.length == 0) {
              for (let q of this.questions) {
                q.color = 'black';
                q.selected_answer = null;
              }
            } else {
              this.passed = data[0];
              for (let q of this.questions) {
                q.color = 'green';
                q.selected_answer = q.right_answer;
              }
            }
            this.modal.show();
          });
      } else {
        this.modal.show();
      }
    });
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    let allCorrect = true;
    for (let q of this.questions) {
      if (q.selected_answer == q.right_answer) q.color = 'green';
      else {
        allCorrect = false;
        q.color = 'red';
      }
    }
    if (allCorrect) {
      this.passed = this.doc.id;
      let user_id = this.perms
        ? this.perms.user_id
        : this.accountService.getUserId();
      this.quizPassedService
        .update({ perms: this.perms, doc_id: this.doc.id, user_id: user_id })
        .subscribe(passed => {
          (<any>this.doc).quiz_passed_id = passed.id;
          if (!this.perms) {
            this.saveMessage = 'You have completed the quiz successfully.';
            this.accountService.setLocation().subscribe(() => {});
            setTimeout(() => {
              this.modal.hide();
            }, 3000);
          } else {
            this.onClose.emit(true);
            this.modal.hide();
          }
        });
    }
  }
}
