import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { DocService } from '../services/doc.service';
import { baseRole } from '../shared/baseRole';
import { youTubeUrlToId, getBaseUrl } from '../shared/api';

@Component({
  selector: 'modal-view-file',
  templateUrl: 'modalViewFile.html',
})
export class ModalViewFileComponent extends baseRole {
  @ViewChild('modal')
  public modal: ModalDirective;
  public doc;

  constructor(
    public accountService: AccountService,
    public docService: DocService
  ) {
    super(accountService);
  }

  show(doc) {
    this.doc = doc;
    if (doc) {
      if (this.isPdf()) {
        window.open(this.getDocData(), '_blank');
      } else {
        this.modal.show();
      }
    }
  }

  isPdf() {
    return this.docService.isPdf(this.doc);
  }
  isVideo() {
    return this.docService.isVideo(this.doc);
  }
  isImage() {
    return this.docService.isImage(this.doc);
  }

  youTubeUrlToId(): string {
    if (this.doc && this.doc.video) return youTubeUrlToId(this.doc.video);
    return null;
  }

  cancel() {
    this.doc = null;
    this.modal.hide();
  }

  getDocData() {
    if (!this.doc) return null;
    return getBaseUrl() + 'doc/data/' + this.doc.id;
  }
}
