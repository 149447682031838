import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import {
  NgModel,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DocService } from '../services/doc.service';
import { QuizPassedService } from '../services/quizPassed.service';
import { SignatureService } from '../services/signature.service';

@Component({
  selector: 'modal-doc-stats',
  templateUrl: 'modalDocStats.html',
})
export class ModalDocStatsComponent {
  public data: any[];
  public doc;

  @Output() onChange = new EventEmitter<boolean>();

  @ViewChild('modalDocStats')
  public modal;

  @ViewChild('resetModal')
  public resetModal;

  constructor(
    protected docService: DocService,
    protected quizPassedService: QuizPassedService,
    protected signatureService: SignatureService
  ) {}

  show(data) {
    this.doc = data;
    this.load();
  }

  load() {
    this.docService.listStats(this.doc.id).subscribe(data => {
      this.data = data;
      this.modal.show();
    });
  }

  cancel() {
    this.modal.hide();
  }

  resetSingle(obj) {
    this.quizPassedService.delete(obj.quiz_passed_id).subscribe(data => {
      obj.quiz_passed_id = null;
      obj.quiz_passed_date = null;
    });
  }

  resetAll() {
    this.quizPassedService.reset(this.doc.id).subscribe(data => {
      this.docService.notifyUpdate();
      this.onChange.emit(true);
      this.resetModal.hide();
      this.modal.hide();
    });
  }
}
