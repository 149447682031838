import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService } from '../services/account.service';
import { DocService } from '../services/doc.service';

@Component({
  templateUrl: './folder.html',
})
export class FolderComponent implements OnInit, OnDestroy {
  public data: any[];
  public sub;
  public doc_group_id;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected docService: DocService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.doc_group_id = params['groupId'];
      //console.log('Group Id: '+this.doc_group_id);
      if (!this.sub) {
        this.sub = this.docService.getUpdateObservable().subscribe(() => {
          this.load();
        });
      } else {
        this.load();
      }
    });
  }

  load() {
    this.docService
      .list({ doc_group_id: this.doc_group_id })
      .subscribe(data => {
        this.data = data;
      });
  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  deleteDoc(obj) {
    let idx = this.data.indexOf(obj);
    this.data.splice(idx, 1);
  }
}
