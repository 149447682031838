import { Injectable } from '@angular/core';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { ITimeOff } from '../shared/api';
import { AccountService } from './account.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class TimeOffService extends BaseService<ITimeOff> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'timeOff');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'start_date');
          this.fixDate(d, 'end_date');
        }
      } else {
        this.fixDate(data, 'start_date');
        this.fixDate(data, 'end_date');
      }
      return data;
    };
  }

  approved(id: number, approved: boolean): Observable<ITimeOff> {
    return this.http
      .put<ITimeOff>(
        this.baseUrl + 'approval/' + id,
        { approved: approved, action_at: new Date() },
        this.defaultOptions
      )
      .pipe(
        map(data => {
          this.notifyUpdate();
          return data;
        })
      );
  }
}

export { ITimeOff } from '../shared/api';
