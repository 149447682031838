import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  ExpenseAccountService,
  IExpenseAccount,
} from '../services/expenseAccount.service';
import { ContactService, IContact } from '../services/contact.service';
import { SkuService } from '../services/sku.service';
import {
  PurchaseOrderService,
  IPurchaseOrder,
} from '../services/purchaseOrder.service';
import { PurchaseOrderItemService } from '../services/purchaseOrderItem.service';
import { baseRole } from '../shared/baseRole';
import {
  SelectAddAll,
  DaysOfWeek,
  ExpenseType,
  RepeatOptions,
  PurchaseOrderStatus,
  StringSort,
} from '../shared/api';

@Component({
  selector: 'modal-standing-order',
  templateUrl: 'modalStandingOrder.html',
})
export class ModalStandingOrderComponent extends baseRole implements OnInit {
  public data: IPurchaseOrder;
  public contacts: IContact[];
  public accounts: IExpenseAccount[];
  public _allSkus: any[];
  public skus: any[] = [];
  public total = 0;
  public expenseAccountId = null;
  public DaysOfWeek = DaysOfWeek;
  public RepeatOptions = RepeatOptions;
  public RepeatKeys = [
    RepeatOptions.Daily,
    RepeatOptions.Weekly,
    RepeatOptions.BiWeekly,
    RepeatOptions.Monthly,
  ];
  public RepeatText = [null, 'Daily', 'Weekly', 'Every other Week', 'Monthly'];

  @Output() onChange = new EventEmitter<boolean>();

  @ViewChild('modal')
  public modal: ModalDirective;

  constructor(
    public accountService: AccountService,
    public contactService: ContactService,
    public expenseAccountService: ExpenseAccountService,
    public purchaseOrderService: PurchaseOrderService,
    public purchaseOrderItemService: PurchaseOrderItemService,
    public skuService: SkuService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    this.contactService.list().subscribe(data => {
      StringSort(data, 'company');
      this.contacts = data;
      //console.log(this.contacts);
      if (!this.data.id) {
        this.data.contact_id = this.contacts[0].id;
      }
    });
    this.expenseAccountService
      .list({ expense_type: ExpenseType.COGS })
      .subscribe(data => {
        this.accounts = SelectAddAll(data, 'All Categories');
      });
    if (!this.data.id) {
      this.skuService.list().subscribe(data => {
        this._allSkus = data;
        this.filter();
      });
    } else {
      this.purchaseOrderItemService
        .list({ purchase_order_id: this.data.id })
        .subscribe(data => {
          this.skus = data;
          this.recalc();
        });
    }
    this.modal.show();
  }

  recalc() {
    this.total = 0;
    for (let s of this.skus) {
      if (s.qty) {
        s.cost = s.qty * s.price;
        this.total += s.cost;
      } else {
        s.qty = 0;
      }
    }
  }
  show(data: IPurchaseOrder = null) {
    if (data) {
      this.data = data;
    } else {
      this.data = {
        location_id: this.accountService.getLocationId(),
        name: null,
        number: 0,
        contact_id: null,
        date: new Date(),
        days: [true, true, true, true, true, true, true],
        status: PurchaseOrderStatus.Standing,
        repeat_option: RepeatOptions.Weekly,
      };
    }
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    this.purchaseOrderService.update(this.data).subscribe(data => {
      if (!this.data.id) {
        for (let s of this.skus) {
          s.sku_id = s.id;
          s.purchase_order_id = data.id;
          s.price_per_unit = s.price;
          delete s.id;
        }
      }
      this.purchaseOrderItemService.bulkUpdate(this.skus).subscribe(data => {
        this.onChange.emit(true);
        this.modal.hide();
      });
    });
  }

  filter() {
    if (!this.expenseAccountId && !this.data.contact_id) {
      this.skus = [];
      this.recalc();
      return;
    }
    this.skus = this._allSkus.filter(e => {
      if (
        this.expenseAccountId &&
        e.expense_account_id != this.expenseAccountId
      )
        return false;
      if (this.data.contact_id && e.contact_id != this.data.contact_id)
        return false;
      return true;
    });
    this.recalc();
  }
}
