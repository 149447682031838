import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';

import { DocsComponent } from './docs.component';
import { FolderComponent } from './folder.component';
import { ManageComponent } from './manage.component';
import { QuizEditComponent } from './quiz.component';
import { UploadComponent } from './upload.component';

import { DocGroupService } from '../services/docGroup.service';
import { DocUserMapService } from '../services/docUserMap.service';
//import { DocGroupPermissionService } from '../services/docGroupPermission.service';
import { QuizPassedService } from '../services/quizPassed.service';
import { QuizQuestionService } from '../services/quizQuestion.service';
import { SignatureService } from '../services/signature.service';

import { routing } from './docs.routing';

@NgModule({
  declarations: [
    DocsComponent,
    FolderComponent,
    ManageComponent,
    QuizEditComponent,
    UploadComponent,
  ],
  imports: [SharedModule, routing],
  providers: [
    DocGroupService,
    //DocGroupPermissionService,
    DocUserMapService,
    QuizQuestionService,
    QuizPassedService,
    SignatureService,
  ],
})
export class DocsModule {}
