import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import { LocationService } from '../services/location.service';
import { UserService } from '../services/user.service';
import { BillingService } from '../services/billing.service';
import { BillingPlanService } from '../services/billingPlan.service';
import { IntegrationService } from '../services/integration.service';
import { LocationUserMapService } from '../services/locationUserMap.service';
import {
  moment,
  getSignInUrl,
  StringSort,
  SelectAddAll,
  PackageType,
  PackageTypeNames,
  SalesIntegrationType,
  SalesIntegrationFeatures,
  SalesIntegrationAuthType,
  LocationStatus,
  BillingPlanType,
  BillingPaymentOption,
} from '../shared/api';
import {
  IFoodVendorIntegrationRequest,
  IFoodVendorIntegrationCreds,
  FoodVendorIntegrationType
} from '../shared/types'
import { PaymentService } from 'source/services/payment.service';
import { environment } from 'environments/environment';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FoodVendorService } from 'source/services/foodVendor.service';

@Component({
  templateUrl: './location.edit.html',
})
export class LocationEditComponent implements OnInit {
  public data;
  public users;
  public billing;
  public billingPlans: any[];
  public isFree = false;
  public LocationStatus = LocationStatus;
  public LocationStatusKeys = Object.keys(LocationStatus).filter(Number);
  public PackageType = PackageType;
  public PackageTypeNames = PackageTypeNames;
  public PackageTypeKeys = [0, 1, 2, 3];
  public BillingPlanType = BillingPlanType;
  public BillingPlanTypeKeys = Object.keys(BillingPlanType).filter(Number);
  public BillingPaymentOption = BillingPaymentOption;
  public BillingPaymentOptionKeys =
    Object.keys(BillingPaymentOption).filter(Number);
  public SalesIntegrationType = SalesIntegrationType;
  public SalesIntegrationFeatures = SalesIntegrationFeatures;
  public SalesIntegrationAuthType = SalesIntegrationAuthType;
  public salesIntegrationResult: string;
  public loc: any;
  public billingPlanMap: any = {};
  public billingPlan: string;
  @ViewChild('confirmResetBillingModal')
  public confirmResetBillingModal;
  @ViewChild('confirmCancelSubscriptionModal')
  public confirmCancelSubscriptionModal;
  public error: string;
  public stripeUrl: string = environment.stripeBaseUrl;
  public customer;
  public location_id;
  public success: string;
  public saveMessage: string;
  public locationWithOwner: any;
  public invoices: any;
  public paymentIntents: any;
  public FoodVendorIntegrationType = FoodVendorIntegrationType;
  public vendorIntegrationResult: {
    sysco?: string,
    shamrock?: string
  } = {};
  public foodVendorResponse: IFoodVendorIntegrationRequest;
  public syscoData: IFoodVendorIntegrationCreds;
  public shamrockData: IFoodVendorIntegrationCreds;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    protected userService: UserService,
    protected integrationService: IntegrationService,
    protected billingPlanService: BillingPlanService,
    protected billingService: BillingService,
    protected accountService: AccountService,
    protected paymentService: PaymentService,
    protected locationUserMapService: LocationUserMapService,
    protected foodVendorService: FoodVendorService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.location_id = params['id'];
      this.load();
    });
  }

  load() {
    let p = [
      this.locationService.get(this.location_id).pipe(
        catchError(error => {
          console.error(`Failed to load location: ${error}`);
          return of(null);
        })
      ),
      this.paymentService.findCustomer(this.location_id).pipe(
        catchError(error => {
          console.error(`Failed to find or create customer: ${error}`);
          return of(null);
        })
      ),
      this.paymentService.getPaymentStatus(this.location_id).pipe(
        catchError(error => {
          console.error(`Failed to load payment status: ${error}`);
          return of(null);
        })
      ),
      this.userService
        .list({ location_id: this.location_id, hide: false })
        .pipe(
          catchError(error => {
            console.error(`Failed to list users: ${error}`);
            return of(null);
          })
        ),
      this.billingService.list({ location_id: this.location_id }).pipe(
        catchError(error => {
          console.error(`Failed to list billing information: ${error}`);
          return of(null);
        })
      ),
      this.locationService.search({ search: this.location_id }).pipe(
        catchError(error => {
          console.error(`Failed to get location search information: ${error}`);
          return of(null);
        })
      ),
      this.foodVendorService.getCredentialsById(this.location_id).pipe(
        catchError(error => {
          console.error(`Failed to load vendors integration info: ${error}`);
          return of(null);
        })
      ),
    ];
    forkJoin(p).subscribe(
      result => {
        if (!result.includes(null)) {
          this.data = result[0];
          this.customer = result[1];
          const subscription: any = {
            ...result[2],
          };
          if (subscription.plan && subscription.plan.interval) {
            this.billingPlan = subscription.plan.interval;
          }
          this.users = result[3];
          this.billing = result[4];
          this.locationWithOwner = result[5][0];
          if (this.data.stripe_customer_id) {
            this.paymentService
              .getCustomerInvoices(this.location_id)
              .subscribe(invoices => {
                this.invoices = invoices;
              });
            this.paymentService
              .getCustomerPaymentIntents(this.location_id)
              .subscribe(paymentIntents => {
                this.paymentIntents = paymentIntents;
              });
          }
          const foodVendorRes: any = result[6];
          if (foodVendorRes.length > 0) {
            this.foodVendorResponse = foodVendorRes[0];
            this.syscoData = this.foodVendorResponse.credentials.find((creds) => creds.vendor === FoodVendorIntegrationType.Sysco);
            this.shamrockData = this.foodVendorResponse.credentials.find((creds) => creds.vendor === FoodVendorIntegrationType.Shamrock);
          }
        } else {
          // Handle situation when some of the data failed to load
          console.error('Some data failed to load.');
        }
      },
      error => {
        // Handle any errors here
        console.error(`An error occurred: ${error}`);
      }
    );
  }

  save() {
    this.saveMessage = null;
    const updateData = this.createUpdateData();
    this.updateLocationService(updateData)
      .then(() => this.navigateToLocation())
      .then(() => {
        this.saveMessage = 'Location saved.';
        setTimeout(() => {
          this.saveMessage = null;
        }, 3000);
      });
  }

  getMorePaymentIntents() {
    this.paymentService
      .getCustomerPaymentIntents(
        this.location_id,
        this.paymentIntents.data[this.paymentIntents.data.length - 1].id
      )
      .subscribe(newPaymentIntents => {
        this.paymentIntents.data = [
          ...this.paymentIntents.data,
          ...newPaymentIntents['data'],
        ];
        this.paymentIntents.has_more = newPaymentIntents['has_more'];
      });
  }

  getMoreInvoice() {
    this.paymentService
      .getCustomerInvoices(
        this.location_id,
        this.invoices.data[this.invoices.data.length - 1].id
      )
      .subscribe(newInvoices => {
        this.invoices.data = [...this.invoices.data, ...newInvoices['data']];
        this.invoices.has_more = newInvoices['has_more'];
      });
  }

  createUpdateData() {
    const {
      id,
      package_type,
      new_package_type,
      status,
      billing_payment_options,
      billing_plan_exp,
      payment_status,
      omni_key,
      free_until,
      block_freemium_warning,
      block_credit_card_warning,
      billing_plan_id,
    } = this.data;

    return {
      id,
      package_type,
      new_package_type,
      status,
      billing_payment_options,
      billing_plan_exp,
      payment_status,
      omni_key,
      free_until,
      block_freemium_warning,
      block_credit_card_warning,
      billing_plan_id,
    };
  }

  updateLocationService(updateData) {
    return new Promise((resolve, reject) => {
      this.locationService.update(updateData).subscribe({
        next: data => resolve(data),
        error: err => reject(err),
      });
    });
  }

  navigateToLocation() {
    return this.router.navigate(['/admin/location/' + this.location_id]);
  }

  updateSalesIntegrationKey() {
    let updateObj: any = {
      location_id: this.location_id,
      sales_integration_token: this.data.sales_integration_token,
    };
    if (this.data.sales_integration_type == SalesIntegrationType.WineDirect) {
      updateObj.sales_integration_refresh_token =
        this.data.sales_integration_refresh_token;
    }
    this.integrationService
      .setSalesIntegrationKey(updateObj)
      .subscribe(result => {
        this.salesIntegrationResult = result ? 'Success' : 'Failure';
        setTimeout(() => {
          this.salesIntegrationResult = null;
        }, 5000);
      });
  }
  resetBillingPlan() {
    this.locationService
      .update({
        id: this.location_id,
        payment_failed_at: null,
        payment_status: false,
        package_type: 0,
        has_picked_plan: false,
        billing_plan_id: this.data.billing_plan_id,
        stripe_customer_id: null,
        stripe_subscription_id: null,
        cancel_at: null,
        canceled_at: null,
      })
      .subscribe(() => {
        this.confirmResetBillingModal.hide();
        this.saveMessage = 'Location saved.';
        setTimeout(() => {
          this.router.navigate(['/admin/location/']);
          this.saveMessage = null;
        }, 3000);
      });
  }

  signinAs(user) {
    this.userService.getAdminToken(user.id).subscribe(token => {
      user.token = getSignInUrl(token);
      setTimeout(() => {
        user.token = null;
      }, 5000);
    });
  }
  signinAsOwner() {
    this.userService
      .getAdminToken(this.locationWithOwner.user[0].id)
      .subscribe(token => {
        this.locationWithOwner.user[0].token = getSignInUrl(token);
        setTimeout(() => {
          this.locationWithOwner.user[0].token = null;
        }, 5000);
      });
  }

  createStripeCustomer(location_id) {
    this.paymentService.findOrCreateCustomer(location_id).subscribe(() => {
      console.log('Created stripe customer for location: ' + location_id);
      this.load();
    });
  }

  async createPaymentMethod(location_id) {
    this.error = null;
    await this.paymentService
      .createPaymentMethod(location_id)
      .subscribe(data => {
        if (data['result'] === 'error') {
          this.error = data['message'];
        } else {
          this.success = 'Payment method created successfully.';
        }
        this.load();
        setTimeout(() => {
          this.success = null;
          this.error = null;
        }, 5000);
      });
  }

  cancelSubscription() {
    this.paymentService
      .cancelSubscription(this.data.stripe_subscription_id, true)
      .subscribe(() => {
        console.log(
          'Cancelled stripe subscription for location: ' + this.data.id
        );
        this.confirmCancelSubscriptionModal.hide();
        this.saveMessage = 'Location saved.';
        setTimeout(() => {
          this.load();
          this.saveMessage = null;
        }, 3000);
      });
  }
  renewSubscription() {
    this.paymentService
      .cancelSubscription(this.data.stripe_subscription_id, false)
      .subscribe(() => {
        console.log(
          'Renewed stripe subscription for location: ' + this.data.id
        );
        this.saveMessage = 'Location saved.';
        setTimeout(() => {
          this.load();
          this.saveMessage = null;
        }, 3000);
      });
  }

  addFoodVendor(foodVendor: FoodVendorIntegrationType): void {
    const vendorsObj: IFoodVendorIntegrationCreds = {
      vendor: foodVendor,
      host: "",
      port: 22,
      username: "",
      password: "",
      status: "Inactive"
    };

    if (vendorsObj.vendor === FoodVendorIntegrationType.Sysco) {
      this.syscoData = vendorsObj;
    } else {
      this.shamrockData = vendorsObj;
    }
  }

  updateFoodVendorIntegrationKeys(foodVendor: FoodVendorIntegrationType): void {
    const updateFoodVendorObj: any = {
      id: this.location_id,
      credentials: [this.syscoData, this.shamrockData].filter(Boolean),
    };

    this.foodVendorService.addCredentials(updateFoodVendorObj)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.vendorIntegrationResult[foodVendor] = null;
          }, 5000);
        })
      )
      .subscribe(
        (data) => {
          this.vendorIntegrationResult[foodVendor] = data['message'];
        },
        (error) => {
          this.vendorIntegrationResult[foodVendor] = error;
        }
      );
  }

  resetFoodVendor(foodVendor: FoodVendorIntegrationType) {
    if(foodVendor === FoodVendorIntegrationType.Sysco) {
     this.syscoData = null;
    } else {
     this.shamrockData = null;
    }
    this.foodVendorService.deleteCredentialsByIdAndVendor(this.location_id, foodVendor)
    .subscribe(
      (data) => {
        console.log("Vendor reset: " + data["message"]);
      },
      (error) => {
        console.log("Vendor reset error: " + error);
      }
    );
   }
}
