import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  NgModel,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AccountService, IUser } from '../services/account.service';
import { IDoc, DocService } from '../services/doc.service';
import {
  IQuizQuestion,
  QuizQuestionService,
} from '../services/quizQuestion.service';

@Component({
  templateUrl: 'quiz.edit.html',
})
export class QuizEditComponent implements OnInit {
  public doc: IDoc;
  public questions: IQuizQuestion[];
  public newQuestion: IQuizQuestion;
  public answerKeys = ['a', 'b', 'c', 'd'];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected docService: DocService,
    protected quizQuestionService: QuizQuestionService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = params['id'];
      let groupId = params['groupId'];
      this.docService.get(id).subscribe(data => {
        this.doc = data;
        this.initNewQuestion();
      });
      this.quizQuestionService.list({ doc_id: id }).subscribe(data => {
        this.questions = data;
      });
    });
  }

  initNewQuestion() {
    this.newQuestion = {
      doc_id: this.doc.id,
      right_answer: 'a',
    } as IQuizQuestion;
  }

  editQuestion(q) {
    q._edit = Object.assign({}, q);
  }

  cancelEdit(q) {
    delete q._edit;
  }

  saveQuestion(q) {
    this.quizQuestionService.update(q._edit || q).subscribe(data => {
      if (!q.id) {
        this.questions.push(data);
        this.initNewQuestion();
      } else {
        Object.assign(q, q._edit);
        delete q._edit;
      }
    });
  }

  deleteQuestion(q) {
    this.quizQuestionService.delete(q.id).subscribe(() => {
      let idx = this.questions.indexOf(q);
      this.questions.splice(idx, 1);
    });
  }

  save() {
    this.docService
      .update({ id: this.doc.id, quizReady: this.doc.quizReady })
      .subscribe(data => {
        this.quizQuestionService.bulkUpdate(this.questions).subscribe(data => {
          //			this.router.navigate(['..'],{ relativeTo:this.route });
        });
      });
  }
}
