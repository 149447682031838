import { Injectable } from '@angular/core';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IMessageComment } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class MessageCommentService extends BaseService<IMessageComment> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'messageComment');
  }
}

export { IMessageComment } from '../shared/api';
