<div class="row nav-padding">
  <div class="col-12">
    <div>
      <div
        *ngFor="let l of letters"
        class="letters mb-2"
        [class.active]="l.hasVendor"
        [class.selected]="l.selected"
        (click)="selectChar(l)">
        {{l.name}}
      </div>
      <div class="letters letters-show-all mb-2" (click)="unsetChar()">
        Show All
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body card-header card-table d-none d-sm-block">
    <div class="col-12 row card-sub-header">
      <div class="col-2">Company</div>
      <div class="col-2">Contact</div>
      <div class="col-3">Email</div>
      <div class="col-2">Cell</div>
      <div class="col-2">Office</div>
      <div class="col-1"></div>
    </div>
  </div>
  <div class="card-body card-table" *ngFor="let d of filteredVendors">
    <div class="col-12 row card-text">
      <div class="col-sm-2 col-12 p-0">
        <div class="d-flex d-sm-none row">
          <div class="d-flex col-12">
            <div class="col-4">Company</div>
            <div class="col-8">{{ d.company }}</div>
          </div>
        </div>
        <div class="d-none d-sm-block">{{d.company }}</div>
      </div>
      <div class="col-sm-2 col-12 p-0">
        <div class="d-flex d-sm-none row">
          <div class="d-flex col-12">
            <div class="col-4">Contact</div>
            <div class="col-8">{{ d.name }}</div>
          </div>
        </div>
        <div class="d-none d-sm-block">{{ d.name }}</div>
      </div>
      <div class="col-sm-3 col-12 p-0">
        <div class="d-flex d-sm-none row" *ngIf="d.email">
          <div class="d-flex col-12">
            <div class="col-4">Email</div>
            <div class="col-8"><email [email]="d.email"></email></div>
          </div>
        </div>
        <div class="d-none d-sm-block"><email [email]="d.email"></email></div>
      </div>
      <div class="col-sm-2 col-12 p-0">
        <div class="d-flex d-sm-none row" *ngIf="d.cell">
          <div class="d-flex col-12">
            <div class="col-4">Cell</div>
            <div class="col-8"><phone [phone]="d.cell"></phone></div>
          </div>
        </div>
        <div class="d-none d-sm-block"><phone [phone]="d.cell"></phone></div>
      </div>
      <div class="col-sm-2 col-12 p-0">
        <div class="d-flex d-sm-none row" *ngIf="d.phone">
          <div class="d-flex col-12">
            <div class="col-4">Office</div>
            <div class="col-8"><phone [phone]="d.phone"></phone></div>
          </div>
        </div>
        <div class="d-none d-sm-block"><phone [phone]="d.phone"></phone></div>
      </div>
      <div class="col-sm-1 col-12 p-0">
        <div class="d-flex d-sm-none row">
          <div class="d-flex col-12">
            <div class="col-4"></div>
            <div class="col-8">
              <div class="row">
                <div
                  class="cdi-s-refresh pointer"
                  *ngIf="d.hide"
                  (click)="toggleView(d)"></div>
                <div
                  class="cdi-s-trash pointer"
                  *ngIf="isAllowed(Permission.EditVendor) && !d.hide && d._can_delete"
                  (click)="toggleView(d)"></div>
                <div
                  class="cdi-s-no-trash"
                  *ngIf="isAllowed(Permission.EditVendor) && !d.hide && !d._can_delete"></div>
                <div
                  class="cdi-s-view-file pointer"
                  *ngIf="isAllowed(Permission.EditInvoices)"
                  [routerLink]="['/contacts/vendor/invoices',d.id]"></div>
                <div
                  *ngIf="isAllowed(Permission.EditVendor)"
                  class="cdi-s-edit pointer"
                  [routerLink]="['/contacts/vendor/edit/',d.id]"></div>
                <div
                  class="cdi-s-view pointer"
                  [routerLink]="['/contacts/vendor/view/',d.id]"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-sm-block">
          <div class="row">
            <div
              class="cdi-s-refresh pointer"
              *ngIf="d.hide"
              (click)="toggleView(d)"></div>
            <div
              class="cdi-s-trash pointer"
              *ngIf="isAllowed(Permission.EditVendor) && !d.hide && d._can_delete"
              (click)="toggleView(d)"></div>
            <div
              class="cdi-s-no-trash"
              *ngIf="isAllowed(Permission.EditVendor) && !d.hide && !d._can_delete"></div>
            <div
              class="cdi-s-view-file pointer"
              *ngIf="isAllowed(Permission.EditInvoices)"
              [routerLink]="['/contacts/vendor/invoices',d.id]"></div>
            <div
              *ngIf="isAllowed(Permission.EditVendor)"
              class="cdi-s-edit pointer"
              [routerLink]="['/contacts/vendor/edit/',d.id]"></div>
            <div
              class="cdi-s-view pointer"
              [routerLink]="['/contacts/vendor/view/',d.id]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="m-4">
    A vendor cannot be deleted if they have been used on an invoice or SKU.
  </span>
</div>
