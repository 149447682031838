import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { ISku } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class SkuService extends BaseService<ISku> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'sku');
  }

  listForInventory(date: Date): Observable<any[]> {
    let tmpObj = { date: date };
    this.dateToLocal(tmpObj, 'date');
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    queryParameters = queryParameters.set('date', tmpObj.date.toJSON());

    /*if(filter) {
			for(let key in filter) {
				queryParameters = queryParameters.set(key,filter[key]);
			}
		}*/
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl + 'inventory', { params: queryParameters })
      .pipe(map(this.postProcess()));
  }

  listForGenPO(date: Date): Observable<any[]> {
    let tmpObj = { date: date };
    this.dateToLocal(tmpObj, 'date');
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    queryParameters = queryParameters.set('date', tmpObj.date.toJSON());

    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get(this.baseUrl + 'genPO', { params: queryParameters })
      .pipe(
        map(this.postProcess()),
        map(data => {
          for (let d of data) {
            if (d.count === null) {
              d.count = 0;
              d.no_inventory = true;
            }
          }
          return data;
        })
      );
  }

  adminBulkImport(location_id: number, data: any[][]): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + location_id + '/bulkImport',
      data,
      this.defaultOptions
    );
  }

  priceImport(
    contact_id: number,
    data: { sku: string; price: number }[]
  ): Observable<{ success: string[]; error: string[] }> {
    return this.http.post<{ success: string[]; error: string[] }>(
      this.baseUrl + 'priceImport',
      { contact_id: contact_id, data: data },
      this.defaultOptions
    );
  }
}

export { ISku, SkuCostMethod, SkuUnit, SkuUnitNames } from '../shared/api';
