<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" *ngIf="data && !isAllowed(Permission.EditTask)">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          View Task
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <div class="card">
              <div class="card-body">
                <label>Task Name</label>
                {{data.name}}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="card">
              <div class="card-body">
                <label>Due Date</label>
                {{ data.start_date | bliDate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <label style="width: auto">Description</label>
                {{ data.notes }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <label>Repeats</label>
                {{ RepeatOptions[data.repeat_option] }}<br />
                <div *ngIf="data.repeat_until">
                  <label>Repeats Until</label>
                  {{data.repeat_until | bliDate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <label>Assigned to</label>
            <div class="row">
              <div class="flex-200 p-1" *ngFor="let user of users">
                <span *ngIf="user._selected">
                  {{user.firstname}} {{user.lastname}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-content" *ngIf="data && isAllowed(Permission.EditTask)">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          {{(data.id?'Edit':'Add')}} Task
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <form ngNativeValidate (submit)="save()">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <label>Task Name</label>
                  <input
                    [(ngModel)]="data.name"
                    type="text"
                    maxlength="50"
                    class="form-control override-invalid"
                    name="task_name"
                    placeholder="Name"
                    required />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <label>Due Date</label>
                  <input
                    type="text"
                    class="form-control input-calendar"
                    placeholder="yyyy-mm-dd"
                    bsDatepicker
                    [(ngModel)]="data.start_date"
                    name="start_date"
                    required />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <label style="width: auto"> Description and Color</label>
                  <input-color
                    [(ngModel)]="data.color"
                    class="float-right"
                    name="color"></input-color>

                  <div class="form-group">
                    <textarea
                      [(ngModel)]="data.notes"
                      class="form-control"
                      name="task_description"
                      placeholder="Task Description"
                      required></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <label>Repair List Item</label>
                  <input-toggle
                    name="repair"
                    [(ngModel)]="data.is_repair"
                    class="mr-1"
                    >Make it a Repair List Item</input-toggle
                  >
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <label>Repeats</label>
                  <select
                    name="repeat_type"
                    [(ngModel)]="data.repeat_option"
                    class="form-control d-inline-block">
                    <option *ngFor="let k of RepeatOptionsKeys" [value]="k">
                      {{RepeatOptions[k]}}
                    </option>
                  </select>
                  <div *ngIf="data.repeat_option!=RepeatOptions.None">
                    <label>Repeats Until</label>
                    <input
                      type="text"
                      class="form-control input-calendar"
                      placeholder="0000-00-00"
                      bsDatepicker
                      [(ngModel)]="data.repeat_until"
                      name="repeat_until" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <label>Select who this task should be assigned to</label>
              <div class="row">
                <div class="flex-200 p-1" *ngFor="let user of users">
                  <input-toggle
                    [name]="'user_'+user.id"
                    [(ngModel)]="user._selected">
                    {{user.firstname}} {{user.lastname}}
                  </input-toggle>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <input-toggle
                    [(ngModel)]="data.text_alert"
                    name="text_alert"
                    class="mr-1 mb-2"
                    >Send Reminder Text</input-toggle
                  >
                  <div class="row">
                    <div class="col">
                      <select
                        [(ngModel)]="data.text_alert_days"
                        class="form-control d-inline-block"
                        name="text_alert_days">
                        <option *ngFor="let k of DaysBeforeKeys" [value]="k">
                          {{DaysBefore[k]}}
                        </option>
                      </select>
                    </div>
                    <div class="col">
                      <small
                        ><timepicker
                          name="text_time"
                          [(ngModel)]="data.text_time"></timepicker
                      ></small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xs-12">
              <div class="card">
                <div class="card-body">
                  <input-toggle
                    [(ngModel)]="data.email_alert"
                    name="email_alert"
                    class="mr-1 mb-2"
                    >Send Reminder Email</input-toggle
                  >
                  <div class="row">
                    <div class="col">
                      <select
                        [(ngModel)]="data.email_alert_days"
                        class="form-control d-inline-block"
                        name="alert_email_days">
                        <option *ngFor="let k of DaysBeforeKeys" [value]="k">
                          {{DaysBefore[k]}}
                        </option>
                      </select>
                    </div>
                    <div class="col">
                      <small
                        ><timepicker
                          name="email_time"
                          [(ngModel)]="data.email_time"></timepicker
                      ></small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-12">
              <cancel-button
                class="float-right"
                (click)="cancel()"></cancel-button>
              <save-button class="float-right"></save-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
