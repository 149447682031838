<!-- sales graph here -->
<div class="row" *ngIf="_salesThisMonth && _salesThisMonth.length>0">
  <div class="col-12">
    <div class="card">
      <widget-sales-this-month
        *ngIf="_salesThisMonth"
        [reportData]="_salesThisMonth"></widget-sales-this-month>
    </div>
  </div>
</div>

<div class="row" *ngIf="widgetList && widgetList.length>0 && doneData">
  <div class="col-md-4 col-sm-6 col-12" *ngFor="let w of widgetList">
    <div class="card mt-0">
      <!--<widget [reportData]="reportData" [widgetType]="w"></widget>-->
      <div [ngSwitch]="w" class="widget-container">
        <widget-sales-last-week
          [reportData]="_salesLastWeek"
          *ngSwitchCase="WidgetType.SalesLastWeek"></widget-sales-last-week>
        <widget-sales-this-week
          [reportData]="_salesThisWeek"
          *ngSwitchCase="WidgetType.SalesThisWeek"></widget-sales-this-week>
        <widget-ytd-quarters
          [reportData]="_ytdQuarter"
          *ngSwitchCase="WidgetType.YTDQuarters"></widget-ytd-quarters>
        <widget-year-to-date
          [reportData]="_ytdMonth"
          *ngSwitchCase="WidgetType.YearToDate"></widget-year-to-date>
        <widget-sales-per-week
          [reportData]="_salesThisMonthByWeek"
          *ngSwitchCase="WidgetType.SalesPerWeek"></widget-sales-per-week>
        <widget-avg-sales-by-weekday
          [reportData]="_avgSalesPerDay"
          *ngSwitchCase="WidgetType.AvgSalesPerDayofWeek"></widget-avg-sales-by-weekday>
      </div>
    </div>
  </div>
</div>
