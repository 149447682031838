import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { PositionService, IPosition } from '../services/position.service';
import { EventService, IEvent } from '../services/event.service';
import {
  EventAssignmentService,
  IEventAssignment,
} from '../services/eventAssignment.service';
import { UserService, IUser } from '../services/user.service';
import { baseRole } from '../shared/baseRole';
import {
  RepeatOptions,
  RepeatOptionsKeys,
  EventType,
  DaysBefore,
  DaysBeforeKeys,
  dateDoOffset,
} from '../shared/api';

@Component({
  selector: 'modal-event',
  templateUrl: 'modalEvent.html',
})
export class ModalEventComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;
  public positions: any[];
  public users: any[];
  public data: IEvent;
  public RepeatOptions = RepeatOptions;
  public RepeatOptionsKeys = RepeatOptionsKeys;
  public DaysBefore = DaysBefore;
  public DaysBeforeKeys = DaysBeforeKeys;
  public positionMap: any = {};
  public userMap: any = {};
  public maxDate: Date = null;
  public error: string;

  constructor(
    public accountService: AccountService,
    public positionService: PositionService,
    public userService: UserService,
    public eventService: EventService,
    public eventAssignmentService: EventAssignmentService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    this.positionService.list().subscribe(data => {
      this.positions = data;
      for (let d of data) {
        this.positionMap[d.id] = d;
      }
      this.userService.list().subscribe(data => {
        this.users = data;
        for (let d of data) {
          this.userMap[d.id] = d;
        }
        if (this.data.id) {
          this.eventAssignmentService
            .list({ event_id: this.data.id })
            .subscribe(data => {
              for (let d of data) {
                if (d.position_id) {
                  if (d.position_id in this.positionMap) {
                    this.positionMap[d.position_id]._selected = true;
                  }
                } else if (d.user_id in this.userMap) {
                  this.userMap[d.user_id]._selected = true;
                }
              }
              this.modal.show();
            });
        } else this.modal.show();
      });
    });
  }

  show(obj = null) {
    if (obj) {
      this.data = obj;
      if (!this.data.text_time)
        this.data.text_time = new Date(this.data.start_date);
      if (!this.data.email_time)
        this.data.email_time = new Date(this.data.start_date);
    } else {
      let now = new Date();
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      this.data = {
        name: null,
        event_type: EventType.Event,
        location_id: this.getLocationId(),
        repeat_option: RepeatOptions.None,
        repeat_until: new Date(now),
        start_date: new Date(now),
        end_date: new Date(now),
        email_time: new Date(now),
        text_time: new Date(now),
        text_alert: false,
        text_alert_days: 0,
        email_alert: false,
        email_alert_days: 0,
        color: '#A0A0A0',
      } as IEvent;
    }
    this.load();
  }

  updateMaxDate() {
    //		console.log('Updating max date');
    switch (+this.data.repeat_option) {
      case RepeatOptions.None:
        this.maxDate = null;
        break;
      case RepeatOptions.Daily:
        this.maxDate = new Date(this.data.start_date);
        this.maxDate.setDate(this.maxDate.getDate() + 1);
        break;
      case RepeatOptions.Weekly:
        this.maxDate = new Date(this.data.start_date);
        this.maxDate.setDate(this.maxDate.getDate() + 7);
        break;
      case RepeatOptions.BiWeekly:
        this.maxDate = new Date(this.data.start_date);
        this.maxDate.setDate(this.maxDate.getDate() + 14);
        break;
      case RepeatOptions.Monthly:
        this.maxDate = new Date(this.data.start_date);
        this.maxDate.setMonth(this.maxDate.getMonth() + 1);
        break;
      case RepeatOptions.Quarterly:
        this.maxDate = new Date(this.data.start_date);
        this.maxDate.setMonth(this.maxDate.getMonth() + 3);
        break;
      case RepeatOptions.Annually:
        this.maxDate = new Date(this.data.start_date);
        this.maxDate.setFullYear(this.maxDate.getFullYear() + 1);
        break;
      default:
        console.log('Unknown repeat option: ' + this.data.repeat_option);
    }
    if (this.data.end_date < this.data.start_date) {
      this.data.end_date.setDate(this.data.start_date.getDate());
      this.data.end_date.setMonth(this.data.start_date.getMonth());
      this.data.end_date.setFullYear(this.data.start_date.getFullYear());
    }
    //	console.log('Max Date: '+this.maxDate);
  }

  clearUsers() {
    for (let u of this.users) {
      u._selected = false;
    }
    for (let p of this.positions) {
      p._selected = false;
    }
  }

  selectEveryone() {
    for (let p of this.positions) {
      p._selected = true;
    }
    for (let u of this.users) {
      u._selected = true;
    }
  }
  cancel() {
    this.modal.hide();
  }

  save() {
    //	console.log(this.data);
    if (this.data.text_alert) {
      this.data.text_time = dateDoOffset(
        this.data.text_time,
        this.data.start_date,
        this.data.text_alert_days
      );
      this.data.text_time.setMinutes(
        15 * Math.floor(this.data.text_time.getMinutes() / 15)
      );
    } else {
      this.data.text_time = null;
    }
    if (this.data.email_alert) {
      this.data.email_time = dateDoOffset(
        this.data.email_time,
        this.data.start_date,
        this.data.email_alert_days
      );
      this.data.email_time.setMinutes(
        15 * Math.floor(this.data.email_time.getMinutes() / 15)
      );
    } else {
      this.data.email_time = null;
    }

    // I'm casting to get around the problems.
    (<any>this.data).positions = this.positions
      .filter(e => {
        return e._selected;
      })
      .map(e => {
        return e.id;
      });
    (<any>this.data).users = this.users
      .filter(e => {
        return e._selected;
      })
      .map(e => {
        return e.id;
      });
    this.eventService.update(this.data).subscribe(data => {
      this.accountService.notifyCalendarUpdate();
      this.modal.hide();
    });
  }
}
