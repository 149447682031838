0
<div class="container-fluid subnav-padding">
  <button
    type="button"
    class="btn btn-primary my-2 float-right"
    routerLink="/inventory/recipe/add">
    Add Recipe Block
  </button>
  <div class="mt-2 text-center">
    <span class="title">Recipe blocks</span>
  </div>

  <div *ngIf="data" class="mt-3">
    <div class="card nav-padding mb-4" *ngFor="let d of data | orderBy:'name'">
      <div class="card-body">
        <div class="d-flex row col-12">
          <div class="card-text d-flex flex-column col-10">
            <label>{{d.name}} </label>
          </div>
          <div class="d-flex row col-2 justify-content-end">
            <span [routerLink]="['/inventory/recipe/',d.id]">
              <icons class="icons pointer" name="Edit"></icons>
            </span>
            <delete-modal
              [name]="d.name"
              *ngIf="d._can_delete"
              [deleteObject]="d"
              (onDelete)="delete($event)"
              style="font-size: 1rem">
              <icons class="icons pointer" name="Trash"></icons>
            </delete-modal>
            <span *ngIf="!d._can_delete">
              <div class="icon-stack">
                <icons class="pointer" name="CircleSlash"> </icons>
                <icons class="pointer" name="Trash"> </icons>
              </div>
            </span>
          </div>
        </div>

        <accordion-button class="row">
          <div class="row pt-2">
            <div class="col-6">
              <label>Details</label>
            </div>
            <div class="col-6">
              <label>Nutrition</label>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card-text py-1 grey-stripe-bg">
                <strong>Cost</strong>
                <span class="float-right">
                  {{ d.price | currency: 'USD' }}
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="card-text py-1 grey-stripe-bg">
                <strong>Kcal</strong>
                <span class="float-right"> {{ d.kcal }} </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card-text py-1">
                <strong># items</strong>
                <span class="float-right"> {{ d.num_child }} </span>
              </div>
            </div>
            <div class="col-6">
              <div class="card-text py-1">
                <strong>Fat</strong>
                <span class="card-text py-1 float-right"> {{ d.fat }} g </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card-text py-1 grey-stripe-bg">
                <strong>Servings</strong>
                <span class="float-right"> {{ d.qty }} </span>
              </div>
            </div>
            <div class="col-6">
              <div class="card-text py-1 grey-stripe-bg">
                <strong>Protein</strong>
                <span class="card-text py-1 float-right">
                  {{ d.protein }} g
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="card-text py-1">
                <strong>Carbs</strong>
                <span class="card-text py-1 float-right">
                  {{ d.carbs }} g
                </span>
              </div>
            </div>
          </div>
        </accordion-button>
      </div>
    </div>

    <label> What are recipe blocks?</label>
    <p>
      Recipe Blocks are for grouping ingredients that are used together. Use
      them to save time when assigning ingredients to menu items. <br />Recipe
      blocks can be nested inside each other. Example: You could build a salad
      dressing block, then nest that block inside a salad block, then nest that
      block inside a menu item that includes a burger and salad.
    </p>
    <p>
      Use the servings option to specify how many servings your recipe makes. If
      your recipe makes 1 gallon (128oz) of sauce, and your serving size is 1oz,
      then fill in 128 servings and your cost per serving is calculated
      accordingly.
    </p>
    <p>
      The nutritional data uses the SKU nutritional data. If you want accurate
      information make sure the SKU has accurate data.
    </p>
  </div>
  <div class="float-bottom-buffer"></div>
</div>
