<!-- Nav Bar -->

<div class="navbar navbar-expand-md subnav">
  <ul class="navbar-nav d-flex d-md-none">
    <li class="nav-item fake-active">
      <a>Menu</a>
    </li>
  </ul>
  <span
    class="navbar-toggler cdi-m-soft-arrow border-circle pointer"
    [class.active]="!collapse"
    (click)="collapse=!collapse">
  </span>

  <div
    class="navbar-collapse collapse"
    [collapse]="collapse"
    (click)="$event.preventDefault()">
    <ul class="navbar-nav schedule-subnav" (click)="collapse=true">
      <li
        class="nav-item"
        (click)="addShiftWithConfirm()"
        *ngIf="shifts && isAllowed(Permission.EditSchedule) && isAllowed(Permission.ViewUnpublishedSchedule) && !published">
        <a class="nav-link">
          <icons class="mx-2" name="Plus"></icons>
          Add Shift
        </a>
      </li>

      <li class="nav-item" (click)="modalFilterShift.show()">
        <a class="nav-link">
          <icons class="mx-2" name="Filter"></icons>
          <span class="d-block d-md-none d-lg-block">Filter</span>
        </a>
      </li>
      <li
        class="nav-item"
        (click)="clearShiftModal.show()"
        *ngIf="isAllowed(Permission.EditSchedule) && shifts && shifts.length>0 && !published">
        <a class="nav-link">
          <icons class="mx-2" name="Clear"></icons>
          <span class="d-block d-md-none d-lg-block">Clear</span>
        </a>
      </li>
      <li
        class="nav-item"
        *ngIf="isAllowed(Permission.EditSchedule) && shifts && shifts.length>0">
        <a class="nav-link" (click)="copy()" *ngIf="!copyShifts">
          <icons class="mx-2" name="Copy"></icons>
          <span class="d-block d-md-none d-lg-block">Copy</span>
        </a>
      </li>
      <li
        class="nav-item"
        *ngIf="copyShifts && isAllowed(Permission.EditSchedule) && isAllowed(Permission.ViewUnpublishedSchedule) && !published">
        <a (click)="paste()" class="nav-link">
          <icons class="mx-2" name="Paste"></icons>
          <span class="d-block d-md-none d-lg-block">Paste</span>
        </a>
        <!-- <a (click)="clearCopy()" class="nav-link">X</a> -->
      </li>
      <!-- <li
        class="nav-item"
        *ngIf="isAllowed(Permission.ViewUnpublishedSchedule) && isAllowed(Permission.EditSchedule) && !published">
        <a class="nav-link" (click)="copyLastWeekModal.show()"
          >Paste Prev Week</a
        >
      </li> -->
      <li
        class="nav-item"
        *ngIf="isAllowed(Permission.EditSchedule) && shifts && shifts.length>0">
        <a class="nav-link" (click)="modalTemplateManager.show()">
          <icons class="mx-2" name="Save"></icons>
          <span class="d-block d-md-none d-lg-block">Save</span>
        </a>
      </li>
      <li
        class="nav-item"
        *ngIf="isAllowed(Permission.ViewUnpublishedSchedule) && isAllowed(Permission.EditSchedule) && !published">
        <a class="nav-link" (click)="modalTemplateLoad.show()">
          <icons class="mx-2" name="Load"></icons>
          <span class="d-block d-md-none d-lg-block">Load</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="modalTradeBoard.show()">
          <icons class="mx-2" name="CalendarCheck"></icons>
          <span class="d-block d-md-none d-lg-block">Trade Board</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="isAllowed(Permission.ApproveTimeOff)">
        <a class="nav-link" (click)="modalTimeOff.show()">
          <icons class="mx-2" name="Calendarplus"></icons>

          <span class="d-block d-md-none d-lg-block">Time Off</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="print()">
          <icons class="mx-2" name="Printer"></icons>
          <span class="d-block d-md-none d-lg-block">Print</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- modals -->
<div
  bsModal
  #copyLastWeekModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-center">Copy From Last Week</div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="copyLastWeekModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to import the previous weeks' schedule? All
        existing shifts will be deleted.
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="copyLastWeekModal.hide()"></cancel-button>
            <button
              type="button"
              class="btn btn-primary float-right mr-2"
              (click)="copyLastWeekModal.hide();copyPrevWeek()">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<confirm-modal #confirmModal (onConfirm)="modalAddShift.show()"></confirm-modal>
<delete-modal
  #clearShiftModal
  name="all shifts"
  (onDelete)="clearShifts()"></delete-modal>
<date-menu [needsGoals]="true" [repeat]="DateMenuType.Weekly"></date-menu>
<modal-event #modalEvent></modal-event>
<modal-add-shift
  [startDate]="date"
  [endDate]="endDate"
  [dayList]="days"
  [timeOffs]="timeOffs"
  [stats]="stats"
  #modalAddShift></modal-add-shift>
<modal-edit-shift
  [startDate]="date"
  [endDate]="endDate"
  [dayList]="days"
  [timeOffs]="timeOffs"
  #modalEditShift></modal-edit-shift>
<modal-adjust-sales
  #modalAdjustSales
  [startDate]="date"
  [endDate]="endDate"></modal-adjust-sales>
<modal-filter-shift
  #modalFilterShift
  (filterChange)="updateFilter($event)"></modal-filter-shift>
<modal-template-manager
  #modalTemplateManager
  [shifts]="shifts"></modal-template-manager>
<modal-template-load
  #modalTemplateLoad
  (onChange)="templateLoader($event)"></modal-template-load>
<modal-trade-board #modalTradeBoard></modal-trade-board>
<modal-trade-request
  [myPositions]="myPositionMap"
  [shifts]="shifts"
  #modalTradeRequest></modal-trade-request>
<modal-labor-stat #modalLaborStat></modal-labor-stat>
<modal-timeoff-approval
  [hideDisplay]="true"
  #modalTimeOff></modal-timeoff-approval>
<div class="datenav-padding"></div>

<!-- Actual page -->
<div class="container-fluid nav-padding">
  <div class="row text-white" *ngIf="isAllowed(Permission.EditSchedule)">
    <div class="col-md-6 col-12">
      <ul class="list-group panel-lg">
        <li class="list-group-item align-middle">
          <div class="p-0 text-dark">
            <h4>
              <strong>TOTAL SCHEDULED COST</strong>
              <span
                class="pointer cdi-m-resize float-right"
                (click)="isAllowed(Permission.ViewSales) && modalLaborStat.show(statMap,null,dayMap)"></span>
            </h4>
          </div>
        </li>
        <li class="list-group-item blue-bg">
          <h4 class="float-left">
            Estimated Sales
            <span
              class="pointer cdi-s-edit"
              *ngIf="isAllowed(Permission.ViewSales) && isAllowed(Permission.CreateBudget)"
              (click)="showAdjust()"></span>
          </h4>
          <h3 class="float-right mb-0">{{ estSales | currency: 'USD' }}</h3>
        </li>
        <li class="list-group-item grey-darker-bg">
          <h4 class="float-left pt-1">Budget</h4>
          <h3 class="float-right mb-0">{{ budgetCost | currency: 'USD' }}</h3>
        </li>
        <li class="list-group-item grey-dark-bg">
          <h4 class="float-left pt-1">Schedules &amp; Salaries</h4>
          <h3 class="float-right mb-0">{{ scheduleCost | currency: 'USD' }}</h3>
        </li>
        <li class="list-group-item grey-bg">
          <h4 class="float-left pt-1">Actual</h4>
          <h3 class="float-right mb-0">{{ actualCost | currency: 'USD' }}</h3>
        </li>
      </ul>
    </div>

    <div class="col-md-6 col-12">
      <ul class="list-group panel-lg">
        <li class="list-group-item">
          <div class="flex-row align-middle">
            <div class="p-0 text-dark">
              <h4>
                <strong>TOTAL SCHEDULED HOURS</strong>
                <span
                  class="pointer cdi-m-resize float-right"
                  (click)="isAllowed(Permission.ViewSales) && modalLaborStat.show(statMap,null,dayMap)"></span>
              </h4>
            </div>
          </div>
        </li>
        <li class="list-group-item blue-bg x3">
          <div style="height: 4rem"></div>
          <h1 class="display-4 mx-auto my-auto text-center">
            {{totalMinutes| bliMinutes}}
          </h1>
        </li>
      </ul>
      <div class="d-flex justify-content-around my-3">
        <button
          class="btn btn-secondary mx-2"
          type="button"
          *ngIf="isAllowed(Permission.ViewSales) && isAllowed(Permission.CreateBudget)"
          (click)="showReview()">
          Review Estimated Sales
        </button>
        <button
          class="btn btn-secondary mx-2"
          type="button"
          (click)="toggleView()">
          Toggle View
        </button>
        <button
          class="btn btn-primary mx-2"
          type="button"
          [disabled]="publishSaving"
          *ngIf="isAllowed(Permission.PublishSchedule) && !published"
          (click)="publishModal.show()">
          Publish
        </button>
        <button
          class="btn btn-primary mx-2"
          type="button"
          [disabled]="publishSaving"
          *ngIf="isAllowed(Permission.PublishSchedule) && published"
          (click)="doPublish()">
          Unpublish
        </button>
      </div>
    </div>
  </div>

  <!-- Toogle View for everyone else. -->
  <div class="row" *ngIf="!isAllowed(Permission.EditSchedule)">
    <div class="col-12">
      <button class="btn float-right" type="button" (click)="toggleView()">
        Toggle View
      </button>
    </div>
  </div>

  <div class="card" *ngIf="currentPublished && currentPublished.note">
    <div class="card-body">
      <label>Schedule Notes</label>
      {{ currentPublished.note }}
    </div>
  </div>

  <div
    class="alert alert-danger text-center"
    *ngIf="conflicts && conflicts.length>0"
    role="alert">
    <b> Shifts with Conflicts</b>
    <div *ngFor="let c of conflicts">
      {{c.shift.user.firstname}} {{c.shift.user.lastname}} {{c.shift.start
      |date:'EEEE h:mm a'}} - {{c.shift.end|date:'h:mm a'}} {{ c.name }}
    </div>
  </div>

  <div
    class="alert alert-warning text-center"
    *ngIf="otwarnings && otwarnings.length>0"
    role="alert">
    <b> Users with Overtime </b>
    <div *ngFor="let ot of otwarnings | orderBy:'day'">
      On {{ot.day | date: 'EEE, MMM d'}} {{ot.user.firstname}}
      {{ot.user.lastname}} is in over time by {{ ot.minutes | bliMinutes }}
    </div>
  </div>

  <div
    class="alert alert-info text-center"
    *ngIf="filter.isFiltered"
    role="alert">
    <b>Staff in the schedule is filtered</b>
    <span
      class="d-flex align-self-center float-right pointer"
      (click)="modalFilterShift.clearFilter()">
      <span class="cdi-s-cancel mt-0"></span>
      <span>Clear filter</span>
    </span>
  </div>
  <ng-template #toolTipIcon let-shift="shift" let-day="day" let-index="index">
    <span
      *ngIf="shift"
      class="schedule-shift"
      (click)="doShiftClick(day,index,shift)"
      placement="top"
      container="body"
      [tooltip]="toolTipContent">
      <div class="shift-image d-block d-md-none d-xl-block" *ngIf="shift.user">
        <img
          [src]="getUserProfileImage(shift.user.has_image, shift.user.firstname, shift.user.lastname, shift.color )" />
      </div>

      <div class="d-flex" style="flex-direction: column">
        <div class="shift-name-time">
          <span>
            {{shift.start | date: 'shortTime'}} - {{shift.end | date:
            'shortTime' }}
          </span>
        </div>
        <div class="shift-name-time">
          <span *ngIf="shift.user">
            {{shift.user.firstname}} {{shift.user.lastname}}
          </span>
          <span *ngIf="!shift.user_id">UNASSIGNED</span>
        </div>
      </div>
    </span>

    <ng-template #toolTipContent>
      <p>Breaks</p>
      <span
        *ngIf="!shift.first_break_length && !shift.second_break_length && !shift.third_break_length"
        >No breaks are scheduled.
      </span>

      <span *ngIf="shift.first_break_length">
        {{ shift.first_break_length }}min @ {{ shift.first_break_time |
        date:'shortTime' }}</span
      ><br />
      <span *ngIf="shift.second_break_length"
        >{{ shift.second_break_length }}min @ {{ shift.second_break_time | date:
        'shortTime' }} </span
      ><br />
      <span *ngIf="shift.third_break_length"
        >{{ shift.third_break_length }}min @ {{ shift.third_break_time | date:
        'shortTime' }}
      </span>
    </ng-template>
  </ng-template>

  <div class="row" *ngIf="displayLong && !mainLoading">
    <div class="col-12" *ngFor="let d of days; let i=index; ">
      <div class="card">
        <div class="card-body">
          <h5 class="text-primary">
            <span
              class="cdi-m-resize align-middle float-right pointer"
              *ngIf="isAllowed(Permission.EditSchedule)"
              (click)="isAllowed(Permission.ViewSales) && modalLaborStat.show(statMap,d,dayMap)"></span>
            <h3 class="text-secondary">{{ d.date | date: 'EEEE - M/d/yy'}}</h3>
            <div class="scheduleHoliday" *ngIf="d.holiday">
              {{d.holiday.name}}
            </div>
            <div class="scheduleBirthday" *ngFor="let u of d.birthdays">
              {{u.firstname}} {{u.lastname}} - Birthday
            </div>
            <!--		<div class='eventHoliday ' *ngFor="let e of d.events" [style.color]="e.color" (click)="modalEvent.show(e)">{{e.name}}</div> -->
            <button
              class="btn px-2 mx-1"
              type="button"
              *ngFor="let e of d.events"
              [style.background-color]="e.color"
              [style.color]="textColor(e.color)"
              (click)="modalEvent.show(e)">
              {{e.name}}
            </button>
            <!--					<button class='btn btn-light' type='button'  *ngFor="let e of d.events" [style.background]="e.color" style='opacity:0.80' (click)="modalEvent.show(e)">{{e.name}} </button> -->
          </h5>
          <label
            *ngIf="isAllowed(Permission.ViewSales) && isAllowed(Permission.EditSchedule)"
            >Sales LY: {{ d.last_year_sales | currency: 'USD'}}</label
          >

          <table
            class="table table-striped"
            *ngIf="isAllowed(Permission.ViewSales) && isAllowed(Permission.EditSchedule)">
            <tr>
              <td>
                Hours
                <span
                  *ngIf="minLabor[d.dayOfWeek] && d.hours >= minLabor[d.dayOfWeek] "
                  class="text-success small pl-2"
                  >Min {{minLabor[d.dayOfWeek]}}</span
                >
                <span
                  *ngIf="minLabor[d.dayOfWeek] && d.hours < minLabor[d.dayOfWeek]"
                  class="text-danger small pl-2"
                  >Min {{minLabor[d.dayOfWeek]}}</span
                >
                <span
                  *ngIf="maxLabor[d.dayOfWeek] && d.hours <= maxLabor[d.dayOfWeek]"
                  class="text-success small pl-2"
                  >Max {{maxLabor[d.dayOfWeek]}}</span
                >
                <span
                  *ngIf="maxLabor[d.dayOfWeek] && d.hours > maxLabor[d.dayOfWeek]"
                  class="text-danger small pl-2"
                  >Max {{maxLabor[d.dayOfWeek]}}</span
                >
              </td>
              <td class="text-right">{{d.hours | number: '1.2-2'}}</td>
            </tr>
            <tr>
              <td>Sales Goal</td>
              <td class="text-right">{{d.goal | currency: 'USD'}}</td>
            </tr>
            <tr>
              <td>Budget</td>
              <td class="text-right">{{d.budget | currency: 'USD'}}</td>
            </tr>
            <tr>
              <td>Scheduled &amp; Salaries</td>
              <td class="text-right">{{d.cost| currency: 'USD'}}</td>
            </tr>
            <tr>
              <td>Actual</td>
              <td class="text-right" *ngIf="d.date < now">
                {{d.actual | currency: 'USD'}}
              </td>
              <td class="text-right" *ngIf="d.date > now">Pending</td>
            </tr>
          </table>
          <div class="gantt-wrapper">
            <div class="gantt__header gantt__header--hours">
              <span></span>
              <span>12</span><span>1</span><span>2</span><span>3</span>
              <span>4</span><span>5</span> <span>6</span><span>7</span>
              <span>8</span><span>9</span><span>10</span><span>11</span>
              <span>12</span><span>1</span><span>2</span><span>3</span>
              <span>4</span><span>5</span> <span>6</span><span>7</span>
              <span>8</span><span>9</span><span>10</span><span>11</span>
            </div>
            <div class="gantt">
              <div class="gantt__row gantt__row--lines">
                <span></span>
                <span
                  *ngFor="let serviceTimeType of serviceTimeArray"
                  [class.breakfast]="serviceTimeType==1"
                  [class.lunch]="serviceTimeType==2"
                  [class.dinner]="serviceTimeType==3"
                  [class.daily]="serviceTimeType==4"></span>
              </div>
              <div class="gantt__row" *ngFor="let shift of d.shifts">
                <div class="gantt__row-first">
                  {{shift.shift.position_name}}
                </div>
                <ul class="gantt__row-bars">
                  <li
                    class="d-none d-md-block"
                    [style.background-color]="shift.shift.color"
                    [style.grid-column-start]="shift.shift.uiStart"
                    [style.grid-column-end]="shift.shift.uiEnd"
                    [style.border-top-right-radius]="(shift.shift.uiOffset > 0) ? '0px' : '20px'"
                    [style.border-bottom-right-radius]="(shift.shift.uiOffset > 0) ? '0px' : '20px'">
                    <ng-container
                      *ngIf="shift.shift.uiStart <= 22 * 4"
                      [ngTemplateOutlet]="toolTipIcon"
                      [ngTemplateOutletContext]="{ shift: shift.shift, day: d, index: i }">
                    </ng-container>
                  </li>

                  <li
                    class="d-none d-md-block"
                    *ngIf="shift.shift.uiOffset > 0"
                    [style.background-color]="shift.shift.color"
                    [style.grid-column-start]="1"
                    [style.grid-column-end]="shift.shift.uiOffset"
                    [style.border-top-left-radius]="(shift.shift.uiOffset > 0) ? '0px' : '20px'"
                    [style.border-bottom-left-radius]="(shift.shift.uiOffset > 0) ? '0px' : '20px'">
                    <ng-container
                      *ngIf="shift.shift.uiStart > 22 * 4"
                      [ngTemplateOutlet]="toolTipIcon"
                      [ngTemplateOutletContext]="{ shift: shift.shift, day: d, index: i }">
                    </ng-container>
                  </li>
                  <li
                    class="d-block d-md-none"
                    [style.background-color]="shift.shift.color"
                    [style.grid-column-start]="1"
                    [style.grid-column-end]="97">
                    <ng-container
                      [ngTemplateOutlet]="toolTipIcon"
                      [ngTemplateOutletContext]="{ shift: shift.shift, day: d, index: i }">
                    </ng-container>
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!displayLong && shortView && users && !mainLoading">
    <div class="d-none d-sm-block">
      <div class="row my-2 border border-dark text-center">
        <div class="col">
          <strong>Employee</strong>
        </div>
        <div class="col" *ngFor="let d of days">
          <strong
            >{{ d.date | date: 'EEEE' }}<br />{{ d.date | date: 'MMM d'
            }}</strong
          >
        </div>
      </div>
    </div>

    <div class="row m-2 border" *ngFor="let user of shortViewKeys()">
      <div class="col">
        <!--					<img class="rounded" style='margin-right: 1.0rem;' src="/images/test/brian.jpg" height="40px"> -->
        {{ shortView[user].firstname }} {{ shortView[user].lastname }}
      </div>
      <div
        class="col-md col-sm-12 text-center"
        *ngFor="let dates of shortView[user].days;let i=index">
        <span class="d-md-none">
          <!-- sm view -->
          <div *ngFor="let shift of dates" class="my-1">
            <span
              [class.pointer]="shift._isClickable"
              (click)="doShortShiftClick(dates,i,shift)">
              <b>{{days[i].date | date:"EEE MMM d" }}</b> {{ shift.position_name
              }} {{shift.start | date: 'h:mm a' }} - {{ shift.end | date: 'h:mm
              a' }}
            </span>
          </div>
        </span>
        <span class="d-none d-md-block">
          <!-- all larger than sm -->
          <div *ngFor="let shift of shortViewDayKeys(dates)">
            <span
              [class.pointer]="shift._isClickable"
              (click)="doShortShiftClick(dates,i,shift)">
              {{ shift.position_name }} <br />
              {{shift.start | date: 'h:mm a' }} - {{ shift.end | date: 'h:mm a'
              }}
            </span>
          </div>
        </span>
      </div>
    </div>
  </ng-container>

  <div *ngIf="publishLogs && publishLogs.length>0" class="mb-3">
    <div class="text-center">
      Publication History<br />
      <div *ngFor="let p of publishLogs">
        {{ PublishLogType[p.publish_log_type]}}ed by by {{p.firstname}}
        {{p.lastname}} on {{ p.created_at | date: 'medium'}}
      </div>
    </div>
  </div>

  <div
    bsModal
    #publishModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="Publish"
    aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header d-inline-block">
          <div class="modal-title text-center">
            Publish Schedule
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="publishModal.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <alert
            type="danger"
            *ngIf="!isFreemium() && salesGoalData && salesGoalData.length==0">
            To correctly schedule you need to
            <strong>review estimated sales</strong> in order to plan your
            budget.
          </alert>
          <div class="row">
            <div class="col-12">
              <label class="control-label">Add a publishing note</label>
              <textarea
                style="width: 100%"
                name="publish_note"
                [(ngModel)]="publishNotes"></textarea>
              Notes are sent in email and text alerts.
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <b>Are you sure you want to publish this schedule?</b>
              <cancel-button
                class="float-right"
                (click)="publishModal.hide()"></cancel-button>
              <button
                class="btn btn-primary float-right mr-2"
                type="button"
                (click)="doPublish();publishModal.hide()">
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
