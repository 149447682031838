import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
//import 'rxjs/add/observable/of';
import { BaseService } from './base.service';
import { AccountService } from './account.service';
import { IMedia, MediaType } from '../shared/api';

@Injectable()
export class MediaService extends BaseService<IMedia> {
  constructor(
    protected accountService: AccountService,
    protected http: HttpClient
  ) {
    super(accountService, http, 'media');
  }

  // Returns uuid;
  upload(
    file: any,
    type: string,
    id: number,
    fields?: any
  ): Observable<string> {
    let formData: FormData = new FormData();
    //	console.log(file);
    formData.append('file', file);
    //	console.log('Fields');
    //	console.log(fields);
    if (fields && Object.keys(fields).length > 0) {
      for (let key of Object.keys(fields)) {
        if (typeof fields[key] == 'undefined' || fields[key] === null) continue;
        //				console.log('Appending: '+key);
        formData.append(key, fields[key].toString());
      }
    }
    formData.append('content_type', file.type);
    formData.append('content_length', file.size);
    let headers = new HttpHeaders();
    //headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.http.post<string>(
      this.baseUrl + '?object_type=' + type + '&id=' + id,
      formData,
      options
    );
  }
}

export { IMedia, MediaType } from '../shared/api';
