import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IUser,
  IApiLocation,
} from '../services/account.service';
import {
  PositionService,
  IPosition,
  PositionType,
} from '../services/position.service';
import { LocationService, ILocation } from '../services/location.service';

const defaultPosition = {
  name: null,
  location_id: null,
  color: '#444',
  position_type: PositionType.FrontOfHouse,
};

@Component({
  templateUrl: './positions.html',
})
export class PositionsComponent implements OnInit {
  public loc: IApiLocation;
  public newObj: IPosition;
  public PositionType = PositionType;
  public PositionTypeKeys = Object.keys(PositionType).filter(e => {
    return e.toString().length < 3;
  });
  public data: IPosition[]; /* = [
	{
		name: 'Cook',
    color: '#769de5',
  }];// as IPosition[] ;*/

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected locationService: LocationService,
    protected positionService: PositionService,
    protected accountService: AccountService
  ) {}

  ngOnInit() {
    this.newObj = Object.assign({}, defaultPosition);
    this.positionService.list({ hide: false }).subscribe(data => {
      this.data = data;
    });
    this.loc = this.accountService.getLocation();
  }

  edit(obj) {
    obj._edit = Object.assign({}, obj);
  }

  cancel(obj) {
    delete obj._edit;
  }

  save(obj) {
    this.positionService.update(obj._edit || obj).subscribe(result => {
      if (!obj.id) {
        result._can_delete = true;
        this.data.push(result);
        this.newObj = Object.assign({}, defaultPosition);
      } else {
        obj = Object.assign(obj, obj._edit);
        delete obj._edit;
      }
    });
  }

  delete(obj) {
    this.positionService.delete(obj.id).subscribe(result => {
      let idx = this.data.indexOf(obj);
      this.data.splice(idx, 1);
    });
  }
}
