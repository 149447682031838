import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import { UserService, IUser } from '../services/user.service';
import { TimeClockService, ITimeClock } from '../services/timeClock.service';
import { baseRole } from '../shared/baseRole';

@Component({
  selector: 'modal-timeclock-edit',
  templateUrl: 'modalTimeClockEdit.html',
})
export class ModalTimeClockEditComponent extends baseRole {
  @ViewChild('modal')
  public modal: ModalDirective;
  public data: any;
  public user;

  @Output() onComplete = new EventEmitter<boolean>();

  constructor(
    public accountService: AccountService,
    public userService: UserService,
    public timeClockService: TimeClockService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    this.userService
      .listWithPositions(this.data.start, this.data.end, {
        id: this.data.user_id,
      })
      .subscribe(data => {
        if (data.length == 0) return;
        this.user = data[0];
        this.modal.show();
      });
  }

  show(data) {
    this.data = Object.assign({}, data);
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    this.timeClockService.update(this.data).subscribe(data => {
      this.modal.hide();
      this.onComplete.emit(true);
    });
  }
}
