import { TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

export function getTimepickerConfig(): TimepickerConfig {
  return Object.assign(new TimepickerConfig(), {
    hourStep: 1,
    minuteStep: 1,
    readonlyInput: false,
    mousewheel: true,
    showSpinners: false,
    showMinutes: true,
    showSeconds: false,
  });
}

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false,
  });
}
