import { Injectable } from '@angular/core';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { ILogComment } from '../shared/api';
import { AccountService } from './account.service';

@Injectable()
export class LogCommentService extends BaseService<ILogComment> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'logComment');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'date');
        }
      } else {
        this.fixDate(data, 'date');
      }
      return data;
    };
  }
}

export { ILogComment } from '../shared/api';
