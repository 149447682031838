<div class="container-fluid subnav-padding">
  <div class="row">
    <form
      ngNativeValidate
      *ngIf="vendors && expenseAccounts && data"
      class="col"
      (submit)="save()">
      <div class="card">
        <div class="card-header text-center">
          {{ data?.id?'Edit':'Add' }} SKU
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <label>SKU Description</label>
              <input
                [(ngModel)]="data.name"
                type="text"
                maxlength="100"
                class="form-control"
                name="name"
                placeholder="SKU Description"
                required />
            </div>
            <div class="col-md-6">
              <label>Vendor SKU Number</label>
              <input
                [(ngModel)]="data.sku_num"
                type="text"
                maxlength="25"
                name="sku_num"
                class="form-control"
                placeholder="Optional but recommended for PO accuracy" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Expense Category</label>
              <select
                [ngModel]="data.expense_account_id || ''"
                (ngModelChange)="data.expense_account_id = $event"
                name="sku_category"
                class="form-control d-inline-block"
                required>
                <option *ngFor="let i of expenseAccounts" [value]="i.id">
                  {{i.name}}
                </option>
              </select>
              <div class="input-group" *ngIf="data.expense_account_id==-1">
                <input
                  [(ngModel)]="expenseName"
                  name="expense_name"
                  maxlength="25"
                  type="text"
                  class="form-control"
                  placeholder="New COG Expense Category Name"
                  required />
                <div class="input-group-append">
                  <button
                    class="btn bg-white btn-icon"
                    type="button"
                    (click)="addExpenseAcct()">
                    <span class="cdi-s-add"> </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label> Vendor</label>
              <select
                [ngModel]="data.contact_id || ''"
                (ngModelChange)="data.contact_id = $event"
                class="form-control d-inline-block"
                name="contact_id"
                required>
                <option *ngFor="let v of vendors" [value]="v.id">
                  {{v.company}}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label> How do you buy this?</label>
              <select
                [(ngModel)]="data.buying_unit"
                class="form-control d-inline-block"
                name="buying_unit"
                (ngModelChange)="onChange()">
                <option *ngFor="let unit of InventoryUnits" [value]="unit">
                  {{unit}}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label> Price per {{data.buying_unit}}</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  [(ngModel)]="data.price"
                  type="number"
                  name="price"
                  min="0"
                  max="99999999.99"
                  step="0.01"
                  class="form-control"
                  placeholder="Vendor Price"
                  name="price"
                  required />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label> How do you want to count this item?</label>
          <div class="row">
            <div class="col-md-3 col-6">
              <div class="btn-group-toggle mb-2">
                <label class="btn btn-radio" [class.active]="!data.subdivided">
                  <input
                    [(ngModel)]="data.subdivided"
                    type="radio"
                    name="subdivided"
                    [value]="false" />By {{data.buying_unit}}
                </label>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="btn-group-toggle mb-2">
                <label class="btn btn-radio" [class.active]="data.subdivided">
                  <input
                    [(ngModel)]="data.subdivided"
                    type="radio"
                    name="subdivided"
                    [value]="true" />By some other unit
                </label>
              </div>
            </div>
          </div>
          <span class="text-danger">
            Warning: Once created, this unit cannot be modified.
          </span>
          <br />
          <span>
            Using a different unit is to make counting some inventory easier. A
            different unit affects taking inventory only, it has nothing to do
            with cost.
          </span>
          <br />
          <small>
            For Example a case may contain cartons that are easy to count. Or a
            box could come by weight, so to avoid weighting it you could eyeball
            it and say 1/4 box.
          </small>
          <div *ngIf="data.subdivided">
            <!--subUnitToggle-->
            <label class="mt-3">What are the other units called?</label>
            <select
              [(ngModel)]="data.sub_unit"
              class="form-control d-inline-block"
              style="width: 200px"
              name="sub_unit">
              <option *ngFor="let unit of InventoryUnits" [value]="unit">
                {{unit}}
              </option>
            </select>
            <label
              >How many {{data.sub_unit}}(s) in a {{data.buying_unit}}</label
            >
            <input
              [(ngModel)]="data.qty_inner_units"
              type="number"
              name="price"
              min="0"
              step="1"
              style="width: 200px"
              class="form-control"
              placeholder="Sub Units"
              name="qty_sub_unit"
              required />
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label> ReOrder Point</label>
          <div class="row">
            <div class="col-md-3 col-6 mb-3">
              <div class="btn-group-toggle">
                <label
                  class="btn btn-radio"
                  [class.active]="data.par_type=='std'">
                  <input
                    [(ngModel)]="data.par_type"
                    type="radio"
                    name="par"
                    value="std" />Standard PAR
                </label>
              </div>
            </div>
            <div class="col-md-3 col-6 mb-3">
              <div class="btn-group-toggle">
                <label
                  class="btn btn-radio"
                  [class.active]="data.par_type=='daily'">
                  <input
                    [(ngModel)]="data.par_type"
                    type="radio"
                    name="par"
                    value="daily" />Daily PAR
                </label>
              </div>
            </div>
          </div>
          <div *ngIf="data.par_type=='std'">
            <div class="row">
              <div class="col form-inline">
                Reorder
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.reorder_qty"
                  name="reorder_qty"
                  step="1"
                  min="0"
                  style="max-width: 3rem"
                  required />
                {{data.buying_unit}}(s) at ROP
                <input
                  type="number"
                  class="form-inline my-0 mx-3"
                  [(ngModel)]="data.par_qty"
                  name="par_qty"
                  step=".25"
                  min="0"
                  style="max-width: 3.5rem"
                  required />
                {{data.sub_unit}}(s).
              </div>
            </div>
          </div>
          <div *ngIf="data.par_type=='daily'">
            <div class="row">
              <div class="col-md-2">
                <strong> Sunday</strong>
              </div>
              <div class="col form-inline">
                Reorder
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.reorder_qty_sunday"
                  name="reorder_qty_sunday"
                  step="1"
                  min="0"
                  style="max-width: 3rem" />
                {{data.buying_unit}}(s) at ROP
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.par_qty_sunday"
                  name="par_qty_sunday"
                  step=".25"
                  min="0"
                  style="max-width: 3.5rem" />
                {{data.sub_unit}}(s).
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <strong> Monday</strong>
              </div>
              <div class="col form-inline">
                Reorder
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.reorder_qty_monday"
                  name="reorder_qty_monday"
                  step="1"
                  min="0"
                  style="max-width: 3rem" />
                {{data.buying_unit}}(s) at ROP
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.par_qty_monday"
                  name="par_qty_monday"
                  step=".25"
                  min="0"
                  style="max-width: 3.5rem" />
                {{data.sub_unit}}(s).
              </div>
            </div>
            <div class="row my-1">
              <div class="col-md-2">
                <strong> Tuesday</strong>
              </div>
              <div class="col form-inline">
                Reorder
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.reorder_qty_tuesday"
                  name="reorder_qty_tuesday"
                  step="1"
                  value="1"
                  min="0"
                  style="max-width: 3rem" />
                {{data.buying_unit}}(s) at ROP
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.par_qty_tuesday"
                  name="par_qty_tuesday"
                  step=".25"
                  value="1.00"
                  min="0"
                  style="max-width: 3.5rem" />
                {{data.sub_unit}}(s).
              </div>
            </div>
            <div class="row my-1">
              <div class="col-md-2">
                <strong> Wednesday</strong>
              </div>
              <div class="col form-inline">
                Reorder
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.reorder_qty_wednesday"
                  name="reorder_qty_wednesday"
                  step="1"
                  value="1"
                  min="0"
                  style="max-width: 3rem" />
                {{data.buying_unit}}(s) at ROP
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.par_qty_wednesday"
                  name="par_qty_wednesday"
                  step=".25"
                  value="1.00"
                  min="0"
                  style="max-width: 3.5rem" />
                {{data.sub_unit}}(s).
              </div>
            </div>
            <div class="row my-1">
              <div class="col-md-2">
                <strong> Thursday</strong>
              </div>
              <div class="col form-inline">
                Reorder
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.reorder_qty_thursday"
                  name="reorder_qty_thursday"
                  step="1"
                  value="1"
                  min="0"
                  style="max-width: 3rem" />
                {{data.buying_unit}}(s) at ROP
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.par_qty_thursday"
                  name="par_qty_thursday"
                  step=".25"
                  value="1.00"
                  min="0"
                  style="max-width: 3.5rem" />
                {{data.sub_unit}}(s).
              </div>
            </div>
            <div class="row my-1">
              <div class="col-md-2">
                <strong> Friday</strong>
              </div>
              <div class="col form-inline">
                Reorder
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.reorder_qty_friday"
                  name="reorder_qty_friday"
                  step="1"
                  value="1"
                  min="0"
                  style="max-width: 3rem" />
                {{data.buying_unit}}(s) at ROP
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.par_qty_friday"
                  name="par_qty_friday"
                  step=".25"
                  value="1.00"
                  min="0"
                  style="max-width: 3.5rem" />
                {{data.sub_unit}}(s).
              </div>
            </div>
            <div class="row my-1">
              <div class="col-md-2">
                <strong> Saturday</strong>
              </div>
              <div class="col form-inline">
                Reorder
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.reorder_qty_saturday"
                  name="reorder_qty_saturday"
                  step="1"
                  value="1"
                  min="0"
                  style="max-width: 3rem" />
                {{data.buying_unit}}(s) at ROP
                <input
                  type="number"
                  class="form-inline my-1 mx-3"
                  [(ngModel)]="data.par_qty_saturday"
                  name="par_qty_saturday"
                  step=".25"
                  value="1.00"
                  min="0"
                  style="max-width: 3.5rem" />
                {{data.sub_unit}}(s).
              </div>
            </div>
          </div>
          <p style="margin-bottom: 0.5rem; margin-top: 1rem">
            <b>PAR:</b> Periodic Automatic Replenishment. Safety Stock level
          </p>
          <p><b>ROP</b>: ReOrder Point = Lead Time Demand + Safety Stock</p>
          <p style="margin-bottom: 0.5rem">
            <strong>Standard PAR:</strong> Use for items you regularly order.
            <br /><small
              >Example: You need more straws whenever you dip below one
              case.</small
            >
          </p>
          <p style="margin-bottom: 0.5rem">
            <strong> Daily PAR:</strong> Use for items ordered daily or that
            need different PARs for some days, usually perishables. <br /><small
              >Example: More fish for the weekend and none for your closed day.
            </small>
          </p>
          <p style="margin-bottom: 0.5rem">
            <strong>For both</strong> Consider usage until delivery (<small>
              Lead Time Demand </small
            >).
          </p>
          <p>
            If you have taken inventory in the previous day, the system will
            automatically generate and send a Purchase Order to your vendor if
            the inventory taken was below the reorder point.<br />
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label
            >How do you want to calculate cost for this SKU in your menu
            items?</label
          >
          <div class="row">
            <div class="col-md-3 col-4">
              <div class="btn-group-toggle mb-2">
                <label
                  class="btn btn-radio"
                  [class.active]="data.cost_method===SkuCostMethod.Quantity">
                  <input
                    type="radio"
                    name="co"
                    [value]="SkuCostMethod.Quantity"
                    [(ngModel)]="data.cost_method" />Quantity</label
                >
              </div>
            </div>
            <div class="col-md-3 col-4">
              <div class="btn-group-toggle mb-2">
                <label
                  class="btn btn-radio"
                  [class.active]="data.cost_method==SkuCostMethod.Weight">
                  <input
                    type="radio"
                    name="co"
                    [value]="SkuCostMethod.Weight"
                    [(ngModel)]="data.cost_method" />Weight</label
                >
              </div>
            </div>
            <div class="col-md-3 col-4">
              <div class="btn-group-toggle mb-2">
                <label
                  class="btn btn-radio px-1"
                  [class.active]="data.cost_method==SkuCostMethod.Neither">
                  <input
                    type="radio"
                    name="co"
                    [value]="SkuCostMethod.Neither"
                    [(ngModel)]="data.cost_method" />Not a menu item</label
                >
              </div>
            </div>
          </div>
          <p style="margin-bottom: 0.5rem">
            <strong>Quantity:</strong> Use for items that come only in defined
            units, where weight or volume do not apply. <br /><small
              >Example: Paper Goods/Supplies/Single Use Food Items.</small
            >
          </p>
          <p style="margin-bottom: 0.5rem">
            <strong> Weight:</strong> Use for items that have weight or volume.
            Enter total weight or volume of the buying unit. <br /><small
              >Example: For a case of twelve 32 fl oz bottles, the case totals
              384 fl oz.
            </small>
          </p>
          <p>
            <strong>Not in a Menu Item:</strong> Use for items like trash bags.
          </p>
          <div class="row">
            <div class="col-md-6">
              <div *ngIf="data.cost_method==SkuCostMethod.Quantity">
                <label>Quantity per {{data.buying_unit}}</label>
                <input
                  [(ngModel)]="data.total_individual_units"
                  type="number"
                  class="form-control"
                  placeholder="How many come in this unit?"
                  name="total_individual_units"
                  required />
              </div>
              <div *ngIf="data.cost_method==SkuCostMethod.Weight">
                <label>Total Weight </label>
                <div class="input-group">
                  <input
                    [(ngModel)]="data.master_weight"
                    type="number"
                    min="0"
                    step=".01"
                    class="form-control"
                    style="line-height: 1"
                    placeholder="Enter total weight here and select unit"
                    name="master_weight"
                    required />
                  <div class="input-group-append">
                    <select
                      [(ngModel)]="data.master_weight_unit"
                      class="form-control mb-0"
                      name="master_weight_unit">
                      <option *ngFor="let k of SkuUnitKeys" [value]="k">
                        {{SkuUnitNames[k]}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <label>Where is this item kept?</label>
          <div class="row">
            <div *ngFor="let i of inventoryLocations | orderBy:'name'">
              <div class="flex-200" style="padding: 2px">
                <input-toggle
                  [(ngModel)]="i._selected"
                  [name]="'inventoryLoc_'+i.id"
                  >{{i.name}}</input-toggle
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="data.cost_method!=SkuCostMethod.Neither">
        <div class="card">
          <div class="card-body">
            <label> Add nutritional data? (optional)</label>
            <div class="row">
              <div class="col-auto mb-2">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="data.has_nutrition">
                    <input
                      [(ngModel)]="data.has_nutrition"
                      type="radio"
                      name="usda"
                      [value]="true"
                      (ngModelChange)="nutrition()" />Yes
                  </label>
                </div>
              </div>
              <div class="col-auto">
                <div class="btn-group-toggle mb-2">
                  <label
                    class="btn btn-radio"
                    [class.active]="!data.has_nutrition">
                    <input
                      [(ngModel)]="data.has_nutrition"
                      type="radio"
                      name="usda"
                      [value]="false" />No
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="data.has_nutrition">
              <label>Search USDA Nutritional Data</label>
              <div class="input-group">
                <input
                  [(ngModel)]="usdaSearch"
                  name="usda_search"
                  type="text"
                  class="form-control" />
                <div class="input-group-append">
                  <button
                    class="btn btn-light body-bg border"
                    type="button"
                    (click)="doUsdaSearch()">
                    Search
                  </button>
                </div>
              </div>
              <p class="pt-1">
                USDA Nutritional values are per 100g or 100ml and may not be
                specific to your item. For accurate data, enter the nutritional
                data from the packaging you get from your suppliers.
              </p>
              <div *ngIf="usdaList">
                <b> Select one below to load the data.</b>
                <clear-button
                  class="float-right"
                  (click)="clearusda()"></clear-button>
                <div *ngIf="usdaList.length==0">None Found</div>
                <table
                  class="table table-striped table-hover table-small"
                  style="width: 100%">
                  <tbody>
                    <tr
                      *ngFor="let i of usdaList"
                      class="pointer"
                      (click)="doUsdaItem(i)">
                      <td title="Click to load the data">
                        {{i.name}} - {{i.ndbno}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="data.cost_method==SkuCostMethod.Weight">
                <label>Portion Size</label>
                <div class="input-group mb-2">
                  <input
                    [(ngModel)]="data.nutritional_portion_weight"
                    name="portion_size"
                    type="text"
                    class="form-control mb-2"
                    required
                    placeholder="Portion Size" />
                  <div class="input-group-append">
                    <select
                      [(ngModel)]="data.nutritional_portion_weight_unit"
                      class="form-control"
                      name="portionUnit"
                      required>
                      <option *ngFor="let k of SkuUnitKeys" [value]="k">
                        {{SkuUnitNames[k]}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="data.cost_method==SkuCostMethod.Quantity">
                <label>Portion Size</label>
                <div class="input-group mb-2">
                  <input
                    [(ngModel)]="data.nutritional_portion_weight"
                    name="portion_size"
                    type="text"
                    class="form-control mb-2"
                    required
                    placeholder="Portion Size" />
                  <div class="input-group-append">
                    <span class="input-group-text" style="height: 80%">
                      Unit(s)</span
                    >
                  </div>
                </div>
              </div>
              <!--<label>Water</label> - water does not seem to show up anywhere on menu items.  where would it show or be used?
				<input [(ngModel)]="data.water" type="text" class="form-control" placeholder='Water'>-->
              <div class="row">
                <div class="col">
                  <label>Energy (Kcal)</label>
                  <input
                    [(ngModel)]="data.kcal"
                    name="kcal"
                    type="text"
                    class="form-control"
                    placeholder="Total Calories" />
                </div>
                <div class="col">
                  <label>Fat (g)</label>
                  <input
                    [(ngModel)]="data.fat"
                    name="fat"
                    type="text"
                    class="form-control"
                    placeholder="Fat" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>Carbs (g)</label>
                  <input
                    [(ngModel)]="data.carbs"
                    name="carbs"
                    type="text"
                    class="form-control"
                    placeholder="Carbohydrates" />
                </div>
                <div class="col">
                  <label>Protein (g)</label>
                  <input
                    [(ngModel)]="data.protein"
                    name="protein"
                    type="text"
                    class="form-control"
                    placeholder="Protein" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-sm-12">
          <cancel-button class="float-right" (click)="cancel()"></cancel-button>
          <save-button class="float-right"></save-button>
        </div>
      </div>
    </form>
  </div>
</div>
