<div class="row nav-padding">
  <div class="col-md-4 col-xs-12 mb-3">
    <button type="button" class="btn btn-primary">Primary</button>
    <button type="button" class="btn btn-secondary">Secondary</button>
    <button type="button" class="btn btn-default">Default</button>
    <button type="button" class="btn btn-outline-dark">Outline</button>
  </div>
  <div class="col-md-4 mb-3 col-xs-12">
    <input-toggle class="mb-2" [(ngModel)]="cbox">Checkbox</input-toggle>
    Checkbox Value: {{cbox}}
  </div>
  <div class="col-md-4 col-xs-12 mb-3">
    <div class="btn-group-toggle">
      <label class="btn btn-radio mb-2" [class.active]="rbutton==1">
        <input type="radio" name="options" [value]="1" [(ngModel)]="rbutton" />
        Radio Option 1
      </label>
      <label class="btn btn-radio mb-2" [class.active]="rbutton==2">
        <input type="radio" name="options" [value]="2" [(ngModel)]="rbutton" />
        Radio Option 2
      </label>
    </div>
    Radio Button Value: {{rbutton}}
  </div>
</div>

<div class="row nav-padding">
  <div class="col-4">
    <label>Select</label>
    <select name="select" class="form-control" [(ngModel)]="select">
      <option value="1">Test Value</option>
      <option value="2">Other</option>
    </select>
  </div>
  <div class="col-4">
    <label>Dropdown menu</label>
    <span class="bgn-group dropdown" dropdown>
      <div class="dropdown-toggle" dropdownToggle>
        Stuff <span class="caret"></span>
      </div>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <li class="dropdown-item">Thing 1</li>
        <li class="dropdown-item">Thing 2</li>
      </div>
    </span>
  </div>
  <div class="col-4">
    <label>Modal</label>
    <button type="button" class="btn btn-primary" (click)="testModal.show()">
      Open
    </button>
    <br />
  </div>
</div>

<div class="row nav-padding">
  <div class="col-4">
    <label>Date</label>
    <input
      type="text"
      bsDatepicker
      [(ngModel)]="date"
      class="form-control input-calendar" />
    Date: {{date}}
  </div>
  <div class="col-4">
    <label>Time</label>
    <timepicker [(ngModel)]="date"></timepicker>
    Time: {{date}}
  </div>
  <div class="col-4">
    <label>Color </label>
    <input-color [(ngModel)]="color"></input-color><br />
    Color: {{color}}
  </div>
</div>

<div class="card">
  <div class="card-header">Inline Editor</div>
  <table class="table table-hover mb-0">
    <tbody>
      <tr *ngFor="let d of data">
        <td class="cdi-s">
          <span class="cdi-s-reorder"></span>
        </td>
        <td>
          <span *ngIf="!d._edit">{{d.data}}</span>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="d.data"
            *ngIf="d._edit" />
        </td>
        <td class="cdi-s">
          <span
            class="cdi-s-edit"
            *ngIf="!d._edit"
            (click)="d._edit = !d._edit"></span>
          <span
            class="cdi-s-save"
            *ngIf="d._edit"
            (click)="d._edit=!d._edit"></span>
        </td>
        <td class="cdi-s">
          <delete-modal *ngIf="!d._edit" [deleteObject]="d" [name]="d.data">
            <span class="cdi-s-trash"></span>
          </delete-modal>
          <span
            class="cdi-s-cross"
            *ngIf="d._edit"
            (click)="d._edit = !d._edit"></span>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-2 col-6">1</div>
      <div class="col-md-2 col-6">2</div>
      <div class="col-md-2 col-6">3</div>
      <div class="col-md-2 col-6">4</div>
      <div class="col-md-2 col-6">5</div>
    </div>
  </div>
</div>

<div
  bsModal
  #testModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Test</div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="testModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Test Modal
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <button class="btn btn-primary" (click)="testModal.hide()">
              Save?
            </button>
            <cancel-button
              class="float-right"
              (click)="testModal.hide()"></cancel-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
