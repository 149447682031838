import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { AccountService } from './account.service';
import { IIntegrationUser } from '../shared/api';

@Injectable()
export class IntegrationUserService extends BaseService<IIntegrationUser> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'integrationUser');
  }

  userUpdate(data: IIntegrationUser[]): Observable<number> {
    return this.http.put<number>(this.baseUrl, data, this.defaultOptions);
  }
}

export { IIntegrationUser, IntegrationUserStatus } from '../shared/api';
