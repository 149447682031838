<div
  bsModal
  #quizViewModal="bs-modal"
  [config]="{backdrop: 'static', ignoreBackdropClick: true, keyboard: false }"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="View Quiz"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Take Quiz
          <button
            *ngIf="showClose"
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <label *ngIf="passed">You have passed this quiz</label>
        <div class="card mt-0">
          <div class="card-body" *ngFor="let q of questions">
            <label [style.color]="q.color">{{q.question}}</label>
            <div *ngFor="let k of answerKeys">
              <div class="btn-group-toggle">
                <label
                  class="btn btn-radio"
                  style="text-align: left; max-width: 40rem"
                  [class.active]="q.selected_answer==k">
                  <input
                    type="radio"
                    [name]="'a_'+q.id+'_'+k"
                    [value]="k"
                    [(ngModel)]="q.selected_answer" />
                  {{q['answer_'+k]}}
                </label>
              </div>
            </div>
          </div>
        </div>
        By taking and passing this quiz you are acknowledging that you've read
        the document this quiz is attached to and understand the policies
        therein.
        <div class="row mt-3" *ngIf="saveMessage">
          <div class="col-sm-12">
            <alert type="success"> {{saveMessage}} </alert>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <done-button
              *ngIf="showClose"
              class="float-right"
              (click)="cancel()"></done-button>
            <button
              class="btn btn-primary float-right mr-2"
              type="button"
              *ngIf="!passed && !saveMessage"
              (click)="save()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
