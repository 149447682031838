import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  forwardRef,
  EventEmitter,
} from '@angular/core';
import {
  NgModel,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AccountService, IApiUser } from '../services/account.service';
import { baseRole } from '../shared/baseRole';
import { noop, DaysOfWeekKeys } from './api';

enum WorkState {
  None = 0,
  Prefer = 1,
  Dislike = 2,
  Cannot = 3,
}

export const CUSTOM_SHIFT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ShiftPreferencesComponent),
  multi: true,
};

@Component({
  selector: 'shift-preferences',
  templateUrl: 'shiftPreferences.html',
  providers: [CUSTOM_SHIFT_CONTROL_VALUE_ACCESSOR],
})
export class ShiftPreferencesComponent implements ControlValueAccessor {
  public _value: string;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input() disabled = false;

  protected isMouseDown = false;

  public WorkState = WorkState;
  public curWorkState = WorkState.Prefer;
  public workStateColor = [null, 'green', 'pink', 'red'];

  public DaysOfWeekKeys = DaysOfWeekKeys;
  public DayList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  public HourHeader: number[] = [];
  public TimeSlots: number[] = [];

  constructor() {
    for (let i = 0; i < 24; i++) {
      this.HourHeader.push(i);
    }
    for (let i = 0; i < 24 * 4; i++) {
      this.TimeSlots.push(i);
    }
  }

  clearAll() {
    this._value = new Array(24 * 7 * 4).join('0');
  }

  setWeekday(day: number) {
    for (let i = 0; i < 24 * 4; i++) {
      this.setState(day, i);
    }
  }

  mouseDown(day, time) {
    if (this.disabled) return;
    this.isMouseDown = true;
    this.setState(day, time);
  }

  mouseUp(event) {
    this.isMouseDown = false;
  }

  mouseEnter(day: number, time: number) {
    if (!this.isMouseDown) return;
    this.setState(day, time);
  }
  mouseLeave(day: number, time: number) {
    if (!this.isMouseDown) return;
    this.setState(day, time);
  }

  mouseMasterLeave() {
    this.isMouseDown = false;
  }

  getState(day: number, time: number) {
    if (!this._value) this.clearAll();
    return this._value[day * (24 * 4) + time];
  }

  setState(day: number, time: number) {
    let idx = day * (24 * 4) + time;
    this._value =
      this._value.substr(0, idx) +
      this.curWorkState +
      this._value.substr(idx + 1);
    this.onChangeCallback(this._value);
  }

  //get accessor
  get value(): string {
    return this._value;
  }

  //set accessor including call the onchange callback
  set value(v: string) {
    let needsUpdate = false;
    if (<any>v == null) {
      this.clearAll();
      needsUpdate = true;
    }
    if (v !== this._value) {
      this._value = v;
      needsUpdate = true;
    }
    if (needsUpdate) this.onChangeCallback(this._value);
  }

  //From ControlValueAccessor interface
  writeValue(v: any) {
    if (v == null) {
      this.clearAll();
      this.onChangeCallback(this._value);
    }
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(this._value);
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
