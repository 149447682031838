import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  ShiftTemplateService,
  IShiftTemplate,
} from '../services/shiftTemplate.service';
import { baseRole } from '../shared/baseRole';
import { SelectAddAll } from '../shared/api';

@Component({
  selector: 'modal-template-load',
  templateUrl: 'modalTemplateLoad.html',
})
export class ModalTemplateLoadComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;

  @Output() onChange = new EventEmitter<{
    clear: boolean;
    unassign: boolean;
    shift_template_id: number;
  }>();

  public data: IShiftTemplate[];
  public loadUnassigned = false;
  public clearShifts = false;
  public selectedTemplate: number;

  constructor(
    public accountService: AccountService,
    public shiftTemplateService: ShiftTemplateService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    // Load the data you need, then

    this.shiftTemplateService.list().subscribe(data => {
      this.data = data;
      if (data.length > 0) {
        this.selectedTemplate = data[0].id;
      }
    });
    this.modal.show();
  }
  show() {
    this.data = null;
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  save() {
    if (this.selectedTemplate) {
      this.onChange.emit({
        clear: this.clearShifts,
        unassign: this.loadUnassigned,
        shift_template_id: this.selectedTemplate,
      });
      this.modal.hide();
    } else {
      this.modal.hide();
    }
  }
}
