<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Delete"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" *ngIf="loc">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">Filter Staff</div>
      </div>

      <div class="modal-body">
        <label
          >Multiple Select
          <button
            class="btn btn-secondary btn-sm float-right"
            style="width: auto"
            (click)="noFilter()">
            No Filter
          </button></label
        >
        <div class="row">
          <div class="col">
            <div *ngIf="getLocation().has_houses">
              <div class="card mt-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col py-0">
                      <strong>Group Filter</strong>
                      <button
                        class="btn btn-secondary btn-sm float-right"
                        style="width: auto"
                        (click)="clearHouses()">
                        <small>Clear FOH &amp; BOH </small>
                      </button>
                    </div>
                  </div>
                  <div class="row no-gutter">
                    <div class="col-md-6 my-2">
                      <div class="btn-group-toggle">
                        <label
                          class="btn btn-radio"
                          [class.active]="filter.foh">
                          <input
                            type="radio"
                            name="options"
                            [value]="true"
                            [(ngModel)]="filter.foh"
                            (click)="switchPositions(true)" />FOH
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6 my-2">
                      <div class="btn-group-toggle">
                        <label
                          class="btn btn-radio"
                          [class.active]="filter.foh===false">
                          <input
                            type="radio"
                            name="options"
                            [value]="false"
                            [(ngModel)]="filter.foh"
                            (click)="switchPositions(false)" />BOH
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-0">
              <div class="card-body">
                <div class="py-1"><strong> Permission Type Filter</strong></div>
                <div class="row">
                  <div class="col-md-4 my-2">
                    <input-toggle
                      [(ngModel)]="filter.user_type[UserType.Regular]"
                      >{{loc.level_1}}</input-toggle
                    >
                  </div>
                  <div class="col-md-4 my-2">
                    <input-toggle
                      [(ngModel)]="filter.user_type[UserType.ShiftLead]"
                      >{{loc.level_2}}</input-toggle
                    >
                  </div>
                  <div class="col-md-4 my-2">
                    <input-toggle
                      [(ngModel)]="filter.user_type[UserType.Manager]"
                      >{{loc.level_3}}</input-toggle
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card mt-0">
              <div class="card-body">
                <div class="py-1"><strong>Position Filter</strong></div>
                <div class="row">
                  <ng-container *ngFor='let p of positions | orderBy: "name"'>
                    <div
                      class="flex-200 p-1"
                      *ngIf="filter.foh==null ||  (p.position_type==1 && filter.foh) || (p.position_type==2 && !filter.foh)">
                      <input-toggle name="p.id" [(ngModel)]="p._selected">
                        {{p.name}}
                      </input-toggle>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-0">
          <div class="card-body">
            <label class="mb-0"> Sort Staff By </label>
            <div class="row no-gutter">
              <div class="col-md-4 my-2">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="filter.order_by=='firstname'">
                    <input
                      type="radio"
                      name="options"
                      value="firstname"
                      [(ngModel)]="filter.order_by" />First Name
                  </label>
                </div>
              </div>
              <div class="col-md-4 my-2">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="filter.order_by=='lastname'">
                    <input
                      type="radio"
                      name="options"
                      value="lastname"
                      [(ngModel)]="filter.order_by" />Last Name
                  </label>
                </div>
              </div>
              <div class="col-md-4 my-2">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="filter.order_by=='date_of_hire'">
                    <input
                      type="radio"
                      name="options"
                      value="date_of_hire"
                      [(ngModel)]="filter.order_by" />Date of Hire
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <ok-button class="float-right" (click)="save()"></ok-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
