import { Injectable, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanDeactivate } from '@angular/router';

import { AuthGuard, AuthGuardJail, AuthGuardFreemium } from '../app/auth-guard';
import { ContactsComponent } from './contacts.component';
import { HomeComponent } from './home.component';
import { GroupListComponent } from './group.component';
import {
  InvoiceListComponent,
  InvoiceViewComponent,
} from './invoice.component';
import { VendorEditComponent, VendorViewComponent } from './vendor.component';

const appRoutes: Routes = [
  {
    path: 'contacts',
    canActivate: [AuthGuard, AuthGuardFreemium, AuthGuardJail],
    component: ContactsComponent,
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'vendor',
        children: [
          { path: 'add', component: VendorEditComponent },
          { path: 'edit/:id', component: VendorEditComponent },
          { path: 'view/:id', component: VendorViewComponent },
          {
            path: 'invoices/:id',
            children: [
              { path: '', component: InvoiceListComponent },
              { path: ':invoice_id', component: InvoiceViewComponent },
            ],
          },
        ],
      },
      {
        path: 'group',
        children: [
          { path: ':option', component: GroupListComponent },
          { path: '', component: GroupListComponent },
        ],
      },
      { path: ':group_id', component: HomeComponent },
    ],
  },
];

export const routing: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(appRoutes);
