import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  PasswordStrength,
  MinimumPasswordStrength,
} from '../shared/passwordStrength';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'environments/environment';
import { Title, Meta } from '@angular/platform-browser';

declare var growsumo: any;

@Component({
  templateUrl: './register.html',
})
export class RegisterComponent implements OnInit {
  public error: any = {};
  public warning: string;
  public suggestions: string[];
  public tnc = 0;
  public user: any = {};
  public step = 1;
  public pTest = new PasswordStrength();
  protected readonly freeTrialConfig = environment.freeTrial;

  public passwordSaved: string;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected accountService: AccountService,
    private gtmService: GoogleTagManagerService,
    private meta: Meta,
    private title: Title
  ) {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Signup for CheddrSuite to streamline your business operations with our comprehensive management tools.',
      },
      { name: 'author', content: 'CheddrSuite' },
      {
        name: 'keywords',
        content:
          'CheddrSuite, Signup, Business Management, Operations, Registration',
      },
      {
        name: 'robots',
        content: 'noindex, nofollow',
      },
    ]);
    this.title.setTitle('CheddrSuite | Signup');
  }

  ngOnInit() {
    if (this.accountService.getUser()) {
      this.router.navigate(['/']);
      return;
    }
    this.route.params.subscribe(params => {
      this.user.email = params['email'];
      this.user.firstname = params['firstname'];
      this.user.lastname = params['lastname'];
      this.user.token = params['token'];
    });
  }

  checkUsername(data) {
    this.user.email = data;

    delete this.error.email;
    if (!data || data.length == 0) {
      return;
    }
    this.accountService.usernameExists(this.user.email).subscribe(result => {
      if (result) {
        this.error.email = 'This email exists. Please login or use another.';
      }
    });
  }

  matchPassword() {
    delete this.error.matchPassword;
    if (this.user.password !== this.user.cpassword) {
      this.error.matchPassword = 'Passwords do not match';
      return false;
    }
    return true;
  }

  checkPassword() {
    delete this.warning;
    delete this.suggestions;
    let [score, warning, suggestions] = this.pTest.measure(this.user.password);
    if (score < MinimumPasswordStrength) {
      this.warning = warning;
      this.suggestions = suggestions;
      return false;
    }
    return true;
  }

  register() {
    if (environment.maintenance) return;
    this.error = {};
    if (!this.matchPassword()) return;
    if (!this.checkPassword()) return;
    this.passwordSaved = this.user.password;
    this.user.username = this.user.email;
    this.accountService.register(this.user).subscribe(
      user => {
        if (user) {
          this.user = user;
          this.step = 2;
          this.partnerStackSignUp();
          const gtmTag = {
            event: 'Signup',
            lead_id: user.id,
          };
          this.gtmService.pushTag(gtmTag);
        } else {
          this.error.global =
            'There was a problem with creating your account. Please try again later, or contact customer support.';
        }
        //this.router.navigate(['/verify',{ username: this.user.email}]);
      },
      err => {
        this.error.global =
          'There was a problem with creating your account. Please try again later, or contact customer support.';
        return;
      }
    );
  }

  cancel() {
    this.router.navigate(['/login']);
  }

  payment() {
    this.accountService
      .login(this.user.email, this.passwordSaved, false)
      .subscribe(user => {
        this.accountService.tagNewOwner(this.user.email);
        // this.accountService
        //   .setLocation(user.locations[0].id)
        //   .subscribe(() => {});
        this.step = 3;
      });
  }
  partnerStackSignUp() {
    growsumo.data.name = this.user.firstname + ' ' + this.user.lastname;
    growsumo.data.email = this.user.email;
    growsumo.data.customer_key = this.user.email;
    growsumo.createSignup();
  }
}
