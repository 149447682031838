import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from '../services/account.service';
import { InventoryLocationService } from '../services/inventoryLocation.service';
import { InventoryCountService } from '../services/inventoryCount.service';
import { ItemService } from '../services/item.service';
import { ItemCategoryService } from '../services/itemCategory.service';
import { ItemRecipeService } from '../services/itemRecipe.service';
import { ItemVariationService } from '../services/itemVariation.service';
import { PurchaseOrderService } from '../services/purchaseOrder.service';
import { PurchaseOrderItemService } from '../services/purchaseOrderItem.service';
import { RecipeBlockService } from '../services/recipeBlock.service';
import { RecipeBlockItemService } from '../services/recipeBlockItem.service';
import { SkuService } from '../services/sku.service';
import { SkuLocationService } from '../services/skuLocation.service';
import { SkuCategoryService } from '../services/skuCategory.service';
import { UsdaService } from '../services/usda.service';

import { InventoryComponent } from './inventory.component';
import { InventoryCountComponent } from './inventoryCount.component';
import { LocationsComponent } from './locations.component';
import { MenuComponent } from './menu.component';
import { ModalItemCategoryComponent } from './modalItemCategory.component';
import { PurchaseOrderEditComponent } from './purchaseOrderEdit.component';
import { PurchaseOrderGenerateComponent } from './purchaseOrderGenerate.component';
import { ModalRecipeComponent } from './modalRecipe.component';
import { ModalPOReceiveComponent } from './modalPOReceive.component';
import { ModalItemComponent } from './modalItem.component';
import { ModalInventoryOrderComponent } from './modalInventoryOrder.component';
import { ModalStandingOrderComponent } from './modalStandingOrder.component';
import { PurchaseOrderComponent } from './purchaseOrder.component';
import { RecipeComponent } from './recipe.component';
import { RecipeBlockEditComponent } from './recipeBlockEdit.component';
import { SkuComponent } from './sku.component';
import { SkuEditComponent } from './skuEdit.component';
import { SkuPriceComponent } from './skuPrice.component';
import { StandingOrderComponent } from './standingOrder.component';
import { TakeComponent } from './take.component';

import { routing } from './inventory.routing';

import { IconsModule } from './../app/icons/icons.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    InventoryComponent,
    InventoryCountComponent,
    LocationsComponent,
    MenuComponent,
    ModalItemCategoryComponent,
    PurchaseOrderEditComponent,
    PurchaseOrderGenerateComponent,
    ModalStandingOrderComponent,
    ModalRecipeComponent,
    ModalItemComponent,
    ModalPOReceiveComponent,
    ModalInventoryOrderComponent,
    PurchaseOrderComponent,
    RecipeComponent,
    RecipeBlockEditComponent,
    SkuComponent,
    SkuEditComponent,
    SkuPriceComponent,
    StandingOrderComponent,
    TakeComponent,
  ],
  imports: [SharedModule, routing, IconsModule, BrowserAnimationsModule],
  exports: [BrowserAnimationsModule],
  providers: [
    InventoryLocationService,
    InventoryCountService,
    ItemService,
    ItemRecipeService,
    ItemCategoryService,
    ItemVariationService,
    PurchaseOrderService,
    PurchaseOrderItemService,
    RecipeBlockService,
    RecipeBlockItemService,
    SkuService,
    SkuCategoryService,
    SkuLocationService,
    UsdaService,
  ],
})
export class InventoryModule {}
