<date-menu [repeat]="DateMenuType.Weekly"></date-menu>
<div class="datenav-padding"></div>
<modal-po-receive #modalPOReceive></modal-po-receive>
<confirm-modal
  action="resend this PO to the vendor via email?"
  title="Resend Purchase Order"
  (onConfirm)="resend($event)"
  #confirmSendModal></confirm-modal>
<confirm-modal
  action="undo receiving this PO? All items will be marked as not received and if there is an invoice linked to this PO, it will be deleted."
  title="Undo Receive PO?"
  (onConfirm)="changeStatus($event,PurchaseOrderStatus.Approved)"
  #confirmUndoModal></confirm-modal>
<div class="container-fluid nav-padding">
  <div class="row nav-padding">
    <div class="col-12 text-white font-headers">
      <ul class="list-group">
        <li class="list-group-item blue-bg">
          <h5 class="float-left my-0 pt-2 pb-0">Budget</h5>
          <h3 class="float-right my-0 pt-2 pb-0">
            {{budget | currency: 'USD' }}
          </h3>
        </li>
        <li class="list-group-item grey-dark-bg">
          <h5 class="float-left my-0 pt-2 pb-0">Pending</h5>
          <h3 class="float-right my-0 pt-2 pb-0">
            {{pendingPrice | currency: 'USD' }} - {{ pendingPercent*100 |
            number: '1.0-0' }}%
          </h3>
        </li>
        <li class="list-group-item grey-bg">
          <h5 class="float-left my-0 pt-2 pb-0">Approved</h5>
          <h3 class="float-right my-0 pt-2 pb-0">
            {{ approvedPrice | currency: 'USD' }} - {{ approvedPercent*100 |
            number: '1.0-0' }}%
          </h3>
        </li>
      </ul>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <label class="my-4">Pending Purchase Orders</label>
      <div style="font-size: 12px">
        <div class="d-none d-md-block">
          <div
            class="row font-weight-bold p-1 mb-2 mx-0"
            style="background-color: #c6c8ca">
            <div class="col-2">
              Vendor
              <!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
            </div>
            <div class="col-2">
              Number
              <!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
            </div>
            <div class="col-2">
              Date<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
            </div>
            <div class="col-2">
              Total<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
            </div>
            <div class="col-2"></div>
            <div class="col-2"></div>
          </div>
        </div>

        <div
          class="row mx-0"
          *ngFor="let p of data | filterBy: ['status',PurchaseOrderStatus.Pending]">
          <div class="col-md-2">
            <span class="d-block-inline d-md-none text-primary">Vendor: </span
            >{{p.contact_company}}
          </div>
          <div class="col-md-2">
            <span class="d-block-inline d-md-none text-primary">Number: </span
            >{{p.number}}
          </div>
          <div class="col-md-2">
            <span class="d-block-inline d-md-none text-primary">Date: </span
            >{{p.date |date}}
          </div>
          <div class="col-md-2">
            <span class="d-block-inline d-md-none text-primary">Total: </span
            >{{p.price|currency: 'USD' }}
          </div>
          <div class="col-md-2 float-right">
            <span class="cdi-s-file-pdf" (click)="print(p)"></span>
            <span
              class="cdi-s-edit pointer"
              [routerLink]="['/inventory/orders/purchase',p.id]"></span>
            <delete-modal
              [name]="'Purchase Order #' +p.number"
              [deleteObject]="p"
              (onDelete)="deleteObj($event)"
              ><span class="cdi-s-trash pointer"></span
            ></delete-modal>
          </div>
          <div class="col-md-2 float-right">
            <button
              type="button"
              class="btn btn-primary py-1"
              style="font-size: 12px"
              (click)="showApproval(p);changeStatus(p,PurchaseOrderStatus.Approved)">
              Approve &amp; Send
            </button>
          </div>
          <hr class="my-1" />
        </div>

        <label class="my-4">Approved Purchase Orders</label>
        <div style="font-size: 12px">
          <div class="d-none d-md-block">
            <div
              class="row font-weight-bold p-1 mx-0"
              style="background-color: #c6c8ca">
              <div class="col-2">
                Vendor<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-2">
                Number<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-2">
                Date<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-2">
                Total<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-2"></div>
              <div class="col-2"></div>
            </div>
          </div>

          <div
            class="row mx-0 border-bottom"
            *ngFor="let p of data | filterBy: ['status',PurchaseOrderStatus.Approved]">
            <div class="col-md-2">
              <span class="d-block-inline d-md-none text-primary">Vendor: </span
              >{{p.contact_company}}
            </div>
            <div class="col-md-2">
              <span class="d-block-inline d-md-none text-primary">Number: </span
              >{{p.number}}
            </div>
            <div class="col-md-2">
              <span class="d-block-inline d-md-none text-primary">Date: </span
              >{{p.date | date}}
            </div>
            <div class="col-md-2">
              <span class="d-block-inline d-md-none text-primary">Total: </span
              >{{p.price|currency: 'USD' }}
            </div>

            <div class="col-md-2 float-right">
              <!--<span class='cdi-s-file-pdf'></span>-->
              <span
                class="cdi-s-edit pointer"
                [routerLink]="['/inventory/orders/purchase',p.id]"></span>
              <delete-modal
                [name]="'Purchase Order #' +p.number"
                [deleteObject]="p"
                (onDelete)="deleteObj($event)"
                ><span class="cdi-s-trash pointer"></span
              ></delete-modal>
            </div>
            <div class="col-md-2 float-right">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary py-1"
                  style="font-size: 12px"
                  (click)="modalPOReceive.show(p)">
                  Receive
                </button>
                <button
                  type="button"
                  class="btn py-1"
                  style="font-size: 12px"
                  (click)="confirmSendModal.confirmObject=p;confirmSendModal.show()">
                  Resend
                </button>
              </div>
            </div>
          </div>
        </div>

        <label class="my-4">Received Purchase Orders</label>
        <div style="font-size: 12px">
          <div class="d-none d-md-block">
            <div
              class="row font-weight-bold p-1 mb-2 mx-0"
              style="background-color: #c6c8ca">
              <div class="col-2">
                Vendor
                <!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-1">
                Number<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-1">
                Date<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-2">
                Ordered<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-2">
                Received<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="col-1">
                Invoice<!--<span class='cdi-s-reorder' style='vertical-align:bottom'></span>-->
              </div>
              <div class="d-none d-sm-block">
                <div class="col-1"></div>
                <div class="col-2"></div>
              </div>
            </div>
          </div>

          <div
            class="row mx-0 border-bottom"
            *ngFor="let p of data | filterBy: ['status',PurchaseOrderStatus.Received]">
            <div class="col-md-2">
              <span class="d-block-inline d-md-none text-primary">Vendor: </span
              >{{p.contact_company}}
            </div>
            <div class="col-md-1">
              <span class="d-block-inline d-md-none text-primary">Number: </span
              >{{p.number}}
            </div>
            <div class="col-md-1">
              <span class="d-block-inline d-md-none text-primary">Date: </span
              >{{p.date|date}}
            </div>
            <div class="col-md-2">
              <span class="d-block-inline d-md-none text-primary"
                >Ordered: </span
              >{{p.price | currency: 'USD' }}
            </div>
            <div class="col-md-2">
              <span class="d-block-inline d-md-none text-primary"
                >Received: </span
              >{{p.price_received | currency: 'USD' }}
            </div>
            <div class="col-md-1">
              <span class="d-block-inline d-md-none text-primary"
                >Invoice: </span
              >{{p.ponumber}}
            </div>
            <div class="col-md-1 float-right">
              <!--<span class='cdi-s-file-pdf'></span>-->
              <span
                class="cdi-s-edit pointer"
                [routerLink]="['/inventory/orders/purchase',p.id]"></span>
              <delete-modal
                [name]="'Purchase Order #' +p.number"
                [deleteObject]="p"
                (onDelete)="deleteObj($event)"
                ><span class="cdi-s-trash pointer"></span
              ></delete-modal>
            </div>

            <div class="col-md-2 float-right">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn py-1"
                  style="font-size: 12px"
                  (click)="confirmUndoModal.confirmObject=p;confirmUndoModal.show()">
                  Undo
                </button>
                <button
                  type="button"
                  class="btn py-1"
                  style="font-size: 12px"
                  (click)="confirmSendModal.confirmObject=p;confirmSendModal.show()">
                  Resend
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary float-right mt-3"
        routerLink="add">
        Generate Purchase Order
      </button>
    </div>
  </div>
</div>
<!--
DETCH
Are you sure you want to approve this invoice?
This vendor has no auto email on file. Remember to call or send this PO manually.
-->
<div
  bsModal
  #approveModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Approve"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-center">Approve Purchase Order</div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="approveModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to approve this purchase order?
        <hr />
        <div *ngIf="hasEmail">
          <input-toggle [(ngModel)]="sendEmail"
            >Send email to vendor</input-toggle
          >
        </div>
        <div *ngIf="!hasEmail">
          This vendor has no auto email on file. Remember to call or send this
          PO manually.
        </div>
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="approveModal.hide()"></cancel-button>
            <button
              type="button"
              class="btn btn-primary float-right mr-2"
              (click)="changeStatus(approvalOrder,PurchaseOrderStatus.Approved)">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
