import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AccountService, IUser } from '../services/account.service';
import {
  IInventoryLocation,
  InventoryLocationService,
} from '../services/inventoryLocation.service';
import { DragulaService } from 'ng2-dragula';
import { baseRole } from '../shared/baseRole';

@Component({
  templateUrl: './locations.html',
})
export class LocationsComponent extends baseRole implements OnInit, OnDestroy {
  public sub;
  public name: string;
  public data: IInventoryLocation[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected inventoryLocationService: InventoryLocationService,
    protected dragulaService: DragulaService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.ManageInventory)) {
      this.router.navigate(['/']);
      return;
    }
    this.setupDragNDrop();
    this.inventoryLocationService.list().subscribe(data => {
      this.data = data;
      this.data.sort((a, b) => {
        return a.order_by - b.order_by;
      });
      let i = 0;
      for (let d of this.data) {
        d.order_by = i++;
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    this.dragulaService.destroy('locations');
  }

  setupDragNDrop() {
    this.dragulaService.createGroup('locations', {
      direction: 'vertical',
    });

    if (!this.sub) {
      this.sub = this.dragulaService.drop('locations').subscribe(value => {
        var myId = value.el.getAttribute('data-location-id');

        let i = 0;
        for (i = 0; i < this.data.length; i++) {
          if (this.data[i].id === parseInt(myId)) break;
        }
        if (i == this.data.length) {
          return; // No idea what happened..
        }

        let me = this.data[i];

        if (value.sibling) {
          // Was inserted before someone...
          var targetId = value.sibling.getAttribute('data-location-id');
          let j = 0;
          for (j = 0; j < this.data.length; j++) {
            if (this.data[j].id === parseInt(targetId)) break;
          }
          if (j == this.data.length) return; // No idea what happened..
          // Carefull when splicing the array you don't delete before
          // the index you want the second insert into.
          if (i < j) {
            this.data.splice(j, 0, me);
            this.data.splice(i, 1);
          } else {
            this.data.splice(i, 1);
            this.data.splice(j, 0, me);
          }
        } else {
          this.data.splice(i, 1);
          this.data.push(me);
        }

        let updateObjs: any[] = [];
        for (i = 0; i < this.data.length; i++) {
          if (this.data[i].order_by != i) {
            updateObjs.push({ id: this.data[i].id, order_by: i });
          }
          this.data[i].order_by = i;
        }
        this.inventoryLocationService.reOrder(updateObjs).subscribe(data => {});
      });
    }
  }

  edit(obj) {
    obj._edit = Object.assign({}, obj);
  }

  cancel(obj) {
    delete obj._edit;
  }

  save(obj) {
    if (!obj) {
      obj = {
        name: this.name,
        order_by: this.data.length,
        location_id: null,
      };
    }
    this.inventoryLocationService.update(obj._edit || obj).subscribe(result => {
      if (!obj.id) {
        result._can_delete = true;
        this.data.push(result);
        this.name = null;
      } else {
        obj = Object.assign(obj, obj._edit);
        delete obj._edit;
      }
    });
  }

  delete(obj) {
    this.inventoryLocationService.delete(obj.id).subscribe(result => {
      let idx = this.data.indexOf(obj);
      this.data.splice(idx, 1);
    });
  }
}
