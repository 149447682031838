<modal-review-writeup #modalReWriteup></modal-review-writeup>
<modal-timeoff #modalTimeOff></modal-timeoff>
<modal-send-unverified #modalSendUnverified></modal-send-unverified>
<modal-filter-users
  #modalFilterUsers
  (filterChange)="filter($event)"></modal-filter-users>
<div class="row">
  <div class="col-12">
    <div class="d-flex justify-content-end" style="margin-right: 25px">
      <div class="pointer" (click)="modalFilterUsers.show()">
        <icons
          class="mr-1 pointer align-self-center"
          style="color: gray"
          name="FilterFilled">
        </icons>
        <span class="pointer align-self-center mr-3"> Filter Staff </span>
      </div>
      <div class="pointer" (click)="toggleHidden()">
        <icons
          *ngIf="showHidden"
          class="mr-2 mb-1 pointer align-self-center"
          [class.active]="showHidden"
          style="color: gray"
          name="EyeVisible">
        </icons>
        <icons
          *ngIf="!showHidden"
          class="mr-2 mb-1 pointer align-self-center"
          style="color: gray"
          name="EyeSlash">
        </icons>
        <span class="pointer align-self-center mr-3">Hidden Staff</span>
      </div>
      <div class="pointer" (click)="print()">
        <icons
          class="mr-1 mb-1 pointer align-self-center"
          style="color: gray"
          name="Print">
        </icons>
        <span class="pointer align-self-center ml-1">Print</span>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-info text-center" *ngIf="isFiltered" role="alert">
  <b>Staff view is filtered</b>
  <span class="d-flex align-self-center float-right pointer">
    <span
      class="cdi-s-cancel mt-0"
      (click)="modalFilterUsers.clearFilter()"></span>
    <span (click)="modalFilterUsers.clearFilter()">Clear filter</span>
  </span>
</div>

<div *ngIf="data && loc" class="row col-12">
  <div
    class="col-sm-12 col-md-4 col-lg-3 col-12"
    *ngFor="let d of data | orderBy: filterObject.order_by; let indexOfElement = index;"
    [class.hidden-red]="d.hide">
    <div class="flip-animation__wrapper">
      <div class="card mx-1 flip-animation__card" [@flipState]="d.flipState">
        <!--  FRONT OF CARD -->
        <div
          class="card-body column flip-animation__front flip-animation__side h-200">
          <div class="d-flex row justify-content-end">
            <div
              class="d-flex row"
              style="margin-right: -5px; margin-top: -10px">
              <icons
                *ngIf="d.hide"
                (click)="toggleUser(d)"
                class="icons pointer"
                name="Refresh">
              </icons>
              <icons
                *ngIf="!d.hide && d.user_type!=4"
                (click)="showHideModal(d)"
                class="icons pointer"
                name="Trash">
              </icons>
              <icons
                [routerLink]="'/users/user/'+d.id"
                class="icons pointer"
                name="Edit">
              </icons>
            </div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <img
              class="rounded float-left mb-2"
              style="object-fit: cover"
              [src]="getUserProfileImage(d.has_image, d.firstname, d.lastname, d.color) | safeUrl"
              height="82px"
              width="82px" />
            <span style="font-size: 1.2rem; font-weight: bold">
              {{d.firstname}} {{d.lastname}}
            </span>
            <div style="font-size: 1rem">
              <span *ngIf="d.user_type==4">Owner</span>
              <span *ngIf="d.user_type!=4">{{loc['level_'+d.user_type]}}</span>
              <!--<br>Age {{d.age}}-->
            </div>
          </div>
          <div class="mt-2 mb-5">
            <div
              class="mb-1 d-flex row justify-content-center align-items-center align-self-center"
              style="gap: 5px">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                (click)="viewDocUser=d;docViewModal.show()">
                Docs {{ d.doc_signed }}/{{d.doc_count}} Quizzes
                {{d.quiz_complete}}/{{d.quiz_count}}
              </button>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                (click)="modalReWriteup.show(d.id)">
                Reviews & Write Ups
              </button>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                (click)="modalTimeOff.show(d)">
                Time Off
              </button>
              <button
                type="button"
                (click)="sendResetPassword(d)"
                class="btn btn-sm btn-secondary"
                *ngIf="!d.emailSent && d.live_status">
                Reset Password
              </button>
              <button
                (click)="sendVerification(d)"
                type="button"
                class="btn btn-sm btn-warning text-white"
                *ngIf="!d.emailSent && !d.live_status">
                Resend Invite
              </button>
              <button
                *ngIf="d.emailSent"
                disabled
                type="button"
                class="btn btn-sm btn-success text-white">
                Email Sent
              </button>
            </div>
          </div>
          <div
            class="d-flex justify-content-center align-items-center row float-right float-bottom"
            style="
              cursor: pointer;
              position: absolute;
              bottom: 15px;
              right: 15px;
            "
            (click)="toggleFlipCard(indexOfElement)">
            <div>More details</div>
            <icons
              style="margin-top: 5px; margin-left: 2px"
              name="ChevronArrowRight"></icons>
          </div>
        </div>
        <!-- BACK OF CARD -->
        <div
          class="card-body column flip-animation__side flip-animation__back flip-absolute">
          <div class="column" style="padding-top: 2px">
            <table class="table table-sm table-striped">
              <tbody>
                <tr>
                  <td><b>Cell</b></td>
                  <td class="text-right">
                    <phone [phone]="d.cell"></phone>
                  </td>
                </tr>
                <tr>
                  <td><b>Email</b></td>
                  <td class="text-right">
                    <a href="mailto:{{d.email}}">{{d.email}}</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      Primary
                      <span class="d-none d-md-inline-block">Position</span></b
                    >
                  </td>
                  <td class="text-right">
                    <div *ngIf="d.primaryPosition">
                      {{d.primaryPosition.name}}&nbsp;-&nbsp;
                      <span
                        *ngIf="(isAllowed(Permission.EditSalaries) && d.primaryPosition.rate_type == RateType.Salary) || (isAllowed(Permission.EditHourlyWages) && !d.hasSalary)">
                        {{ d.primaryPosition.rate | currency: 'USD' }} {{
                        RateType[d.primaryPosition.rate_type] }}
                      </span>
                      <span
                        *ngIf="d.primaryPosition.rate_type != RateType.Salary && d.hasSalary">
                        Salaried
                      </span>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="d.additionalPosition.length > 0">
                  <td>
                    <b
                      >Additional<span class="d-none d-md-inline-block"
                        >Positions</span
                      ></b
                    >
                  </td>
                  <td class="text-right">
                    <div
                      *ngFor="let p of d.additionalPosition | orderBy:'name'; let i = index">
                      <div *ngIf="i === 0">
                        {{p.name}} &nbsp;- &nbsp;
                        <span
                          *ngIf="p.rate_type == RateType.Salary || !d.hasSalary">
                          {{ p.rate | currency: 'USD' }} {{
                          RateType[p.rate_type] }}
                        </span>
                        <span
                          *ngIf="p.rate_type != RateType.Salary && d.hasSalary">
                          Salaried
                        </span>
                      </div>
                    </div>
                    <a
                      *ngIf="d.additionalPosition.length > 1"
                      (click)="additionalPositionsUser=d; additionalPositionsViewModal.show()"
                      >+ {{d.additionalPosition.length - 1}} more</a
                    >
                  </td>
                </tr>
                <tr>
                  <td><b>Hire Date</b></td>
                  <td class="text-right">{{d.date_of_hire | date}}</td>
                </tr>
                <tr>
                  <td><b>Last Login</b></td>
                  <td class="text-right">{{d.last_login | date}}</td>
                </tr>
                <tr>
                  <td><b>PIN</b></td>
                  <td class="text-right">{{d.pin}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--			<div class="btn-group float-right d-md-none mb-2" role="group" >
    <button type="button" class="btn btn-outline-dark body-bg pr-3" style='font-size: 10px; line-height: 11px;'  > Docs 0/4 <br>Quizzes 0/2 </button>
    <button type="button" class="btn btn-outline-dark body-bg px-3" style='font-size: 10px; line-height: 11px;' (click)="modalReWriteup.show(d.id)">Reviews <br>&nbsp; Write Ups </button>
    <button type="button" class="btn btn-outline-dark body-bg px-3" style='font-size: 10px; line-height: 11px;' (click)="modalTimeOff.show(d.id)"> Time <br>Off </button>
    <button type="button" class="btn btn-outline-dark body-bg pl-3" style='font-size: 10px; line-height: 11px;'> Reset <br>Password </button>
  </div> -->
          <div
            class="d-flex justify-content-center align-items-center row float-left float-bottom"
            style="cursor: pointer; position: absolute; bottom: 15px"
            (click)="toggleFlipCard(indexOfElement)">
            <icons
              style="margin-top: 5px; margin-left: 11px; margin-right: -5px"
              name="ChevronArrowLeft"></icons>
            <div>View profile</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #hideModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Hide User"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Hide Staff
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancelModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body" *ngIf="hideUser">
        Staff members cannot be deleted, but we can hide them. Are you sure you
        want to hide {{hideUser.firstname}} {{hideUser.lastname}}?
        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="cancelModal()"></cancel-button>
            <button
              class="btn btn-primary float-right mr-2"
              (click)="doHideUser()">
              Hide
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #docViewModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Doc View Modal"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Docs &amp; Quizzes
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="docViewModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body" *ngIf="viewDocUser">
        <div class="row">
          <div
            class="d-flex justify-content-around"
            *ngFor="let d of viewDocUser.docs">
            <div class="card my-1" style="width: 12.8rem">
              <div class="card-body">
                <doc-view [viewOnly]="true" [doc]="d"></doc-view>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="docViewModal.hide()"></cancel-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #additionalPositionsViewModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Doc View Modal"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title text-center">
          Additional Positions
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="additionalPositionsViewModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body mx-2">
        <div
          *ngFor="let p of additionalPositionsUser?.additionalPosition  | orderBy:'name'; let i = index"
          class="d-flex column justify-content-center">
          {{p.name}} &nbsp;- &nbsp;
          <span
            *ngIf="p.rate_type == RateType.Salary || !additionalPositionsUser.hasSalary">
            {{ p.rate | currency: 'USD' }} {{ RateType[p.rate_type] }}
          </span>
          <span
            *ngIf="p.rate_type != RateType.Salary && additionalPositionsUser.hasSalary">
            Salaried
          </span>
        </div>
        <div class="row"></div>

        <br />
        <div class="row mt-3">
          <div class="col-sm-12">
            <cancel-button
              class="float-right"
              (click)="additionalPositionsViewModal.hide()"></cancel-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
