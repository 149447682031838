<div class="navbar date-menu" *ngIf="dates">
  <ul class="navbar-nav">
    <li
      class="navbar-item pointer"
      (click)="changeDate(d)"
      *ngFor="let d of dates;let i = index"
      [class.selected]="d.getTime()==date.getTime()"
      [class.border-goal-success]="goals && goals[i]=='success'"
      [class.border-goal-danger]="goals && goals[i]=='danger'"
      [class.border-goal-warning]="goals && goals[i]=='warning'">
      <button
        class="btn btn-primary p-1 mx-2"
        (click)="toggle()"
        *ngIf="d.getTime()==date.getTime()">
        <icons name="CalendarRequest">
          <input
            [bsValue]="date"
            [bsConfig]="{ isAnimated: true,  containerClass:'theme-orange'}"
            #dp="bsDatepicker"
            (bsValueChange)="changeDate($event)"
            [daysDisabled]="disabledArray"
            type="text"
            bsDatepicker
            style="
              width: 1px;
              height: 1px;
              opacity: 0;
              display: inline;
              padding: 0px;
              margin: 0px;
            " />
        </icons>
      </button>
      {{ d | bliMenuDate: repeat}}
    </li>
  </ul>
</div>
