<div *ngIf="doc" class="title text-center mt-3">Quiz for {{doc.name}}</div>
<form ngNativeValidate (submit)="save()">
  <div class="row" *ngIf="doc">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <label>Quiz Status</label>
          <input-toggle
            [(ngModel)]="doc.quizReady"
            name="is_published"
            (ngModelChange)="save()"
            >Published</input-toggle
          >
          Click here to publish this quiz when it's ready for your staff to
          take.
        </div>
      </div>

      <div class="card">
        <form
          ngNativeValidate
          class="card-body"
          (submit)="saveQuestion(newQuestion)">
          <input
            [(ngModel)]="newQuestion.question"
            type="text"
            class="override-invalid form-control"
            name="question"
            placeholder="Question"
            required />
          Please select the correct answer.
          <div class="input-group" *ngFor="let k of answerKeys">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <div class="btn-group-toggle">
                  <label
                    class="btn btn-radio"
                    [class.active]="newQuestion.right_answer==k">
                    <input
                      type="radio"
                      [name]="'new_'+k"
                      [value]="k"
                      [(ngModel)]="newQuestion.right_answer" />
                  </label>
                </div>
              </span>
            </div>
            <input
              [(ngModel)]="newQuestion['answer_'+k]"
              type="text"
              class="override-invalid form-control"
              [name]="'newAnswer'+k"
              placeholder="Answer"
              required />
          </div>
          <div class="mt-3">
            <button class="btn">Add Question</button>
          </div>
        </form>
      </div>

      <div class="row mt-3">
        <div class="col-sm-12">
          <done-button class="float-right" routerLink="../.."></done-button>
        </div>
      </div>

      <hr />

      <div class="card" *ngIf="questions">
        <div class="card-body">
          <label>Quiz Questions</label>
        </div>
        <form
          ngNativeValidate
          class="card-body py-1"
          *ngFor="let q of questions"
          (submit)="saveQuestion(q)">
          <div class="body-bg input-group">
            <input
              type="text"
              [readonly]="!q._edit"
              class="form-control"
              [name]="'q_'+q.id"
              [value]="q.question" />
            <div class="input-group-append">
              <button
                class="cdi-s-save"
                *ngIf="q._edit"
                (click)="saveQuestion(q)"></button>
              <span
                class="cdi-s-cancel pointer"
                *ngIf="q._edit"
                (click)="cancelEdit(q)"></span>
              <span
                class="cdi-s-edit pointer"
                *ngIf="!q._edit"
                (click)="editQuestion(q)"></span>
              <delete-modal
                *ngIf="!q._edit"
                [deleteObject]="q"
                [name]="q.question"
                (onDelete)="deleteQuestion(q)">
                <span class="cdi-s-trash pointer"></span>
              </delete-modal>
            </div>
          </div>

          <div *ngIf="q._edit">
            <div class="input-group" *ngFor="let k of answerKeys">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <div class="btn-group-toggle">
                    <label
                      class="btn btn-radio"
                      [class.active]="q._edit.right_answer==k">
                      <input
                        type="radio"
                        name="options1"
                        [value]="k"
                        [name]="'a_'+q.id+'_'+k"
                        [(ngModel)]="q._edit.right_answer" />
                    </label>
                  </div>
                </span>
              </div>
              <input
                [(ngModel)]="q._edit['answer_'+k]"
                type="text"
                class="form-control"
                [name]="'answer_'+q.id+'_'+k"
                placeholder="Answer"
                required />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</form>
