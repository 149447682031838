<div
  class="schedule-request-button"
  style="border: 0"
  (click)="show()"
  *ngIf="!hideDisplay">
  <div class="card-img pointer">
    <icons name="CalendarX"></icons>
    <p class="card-text m-0">Time off to Approve</p>
    <div
      class="card-img-overlay"
      *ngIf="pendingTimeOffs && pendingTimeOffs.length>0"
      style="top: -15%; left: 45%">
      <span class="notification-badge">{{pendingTimeOffs.length}}</span>
    </div>
  </div>
</div>

<div
  bsModal
  #modal="bs-modal"
  class="modal fade text-center"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Modal Time Off Approve"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" *ngIf="pendingTimeOffs">
    <div class="modal-content">
      <div class="modal-header d-inline-block">
        <div class="modal-title">
          Pending Time Offs
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="cancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <button
          class="btn btn-primary float-right"
          type="button"
          (click)="modalTimeOff.show()">
          Add New Time Off
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="card my-0" *ngIf="pendingTimeOffs">
              <div *ngIf="pendingTimeOffs && pendingTimeOffs.length==0">
                <label>There are no pending requests</label>
              </div>

              <ng-container
                *ngFor="let d of pendingTimeOffs | orderBy: 'start_date'">
                <div class="card-body my-0 text-left">
                  <span class="ml-3">
                    <h5>{{d.firstname}} {{d.lastname }}</h5>
                  </span>
                  {{d.start_date | date:"EE MMM d y h:mm a"}} - {{d.end_date |
                  date:"EE MMM d y h:mm a"}}
                  <span class="btn-group float-right">
                    <button
                      type="button"
                      class="btn btn-success mx-2"
                      (click)="approve(d)">
                      Approve
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning mx-2"
                      (click)="deny(d)">
                      Deny
                    </button>
                    <!--<delete-modal [deleteObject]="d" name="this time off" (onDelete)="delete($event)">-->
                    <button
                      type="button"
                      class="btn btn-danger mx-2"
                      (click)="deleteModal.deleteObject=d;deleteModal.show()">
                      Delete
                    </button>
                    <!--</delete-modal>-->
                  </span>
                  <br />Note: {{ d.notes }} <br />Requested {{d.created_at
                  |date: "EEE, MMM d, y, h:mm a" }}
                  <alert type="warning" *ngIf="d._too_close">
                    Time off was requested in too short of a time period.
                  </alert>
                  <ng-container *ngIf="d.conflicts && d.conflicts.length>0">
                    <alert type="danger" *ngFor="let c of d.conflicts">
                      <span *ngIf="c.is_blackout">
                        Conflict with Blackout from {{ c.start | date:
                        'longDate' }} to {{ c.end | date: 'longDate'}}
                      </span>
                      <span *ngIf="c.is_event">
                        Conflict with event: {{ c.name }} at {{ c.start | date:
                        'medium' }} to {{ c.end | date: 'medium' }}
                      </span>
                    </alert>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="ml-2 mt-2">All Requests with Status</label>
            <div class="card my-0" *ngIf="timeOffs">
              <ng-container *ngFor="let d of timeOffs | orderBy: '-start_date'">
                <div class="card-body my-0 text-left">
                  <div class="ml-3">
                    <h5>{{d.firstname}} {{d.lastname }}</h5>
                    {{d.start_date | date:"EEE, MMM d, y, h:mm a"}} -
                    {{d.end_date | date:"EEE, MMM d, y, h:mm a"}}
                    <span *ngIf="d.approved" class="alert-success"
                      >Approved
                      <span class="font-weight-light">
                        by {{ d.action_firstname }} {{d.action_lastname}}
                        {{d.action_at | date:"EEE, MMM d, y, h:mm a"}}</span
                      ></span
                    >
                    <span
                      *ngIf="!d.approved && d.action_at"
                      class="alert-danger"
                      >Denied
                      <span class="font-weight-light"
                        >by {{ d.action_firstname }} {{d.action_lastname}}
                        {{d.action_at | date:"EEE, MMM d, y, h:mm a"}}</span
                      ></span
                    >
                    <br />Note: {{ d.notes }}
                    <br />
                    Requested {{d.created_at |date: "EEE, MMM d, y, h:mm a" }}
                    <!--<delete-modal [deleteObject]="d" name="this time off" (onDelete)="delete($event)">-->
                    <button
                      type="button"
                      (click)="deleteModal.deleteObject=d;deleteModal.show()"
                      class="btn btn-dark btn-sm float-right">
                      Delete
                    </button>
                    <!--</delete-modal>-->
                  </div>
                </div>
              </ng-container>
              <div class="card-body" *ngIf="timeOffs.length==0">
                No time off requests.
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12">
            <done-button class="float-right" (click)="cancel()"></done-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete-modal
    #deleteModal
    name="this time off"
    (onDelete)="delete($event)"></delete-modal>
  <modal-timeoff #modalTimeOff></modal-timeoff>
</div>
