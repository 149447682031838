import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';

type IPasswordStrength = [number, string, string[]];

export class PasswordStrength {
  public measure(password: string): IPasswordStrength {
    const options = {
      translations: zxcvbnEnPackage.translations,
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
    };

    zxcvbnOptions.setOptions(options);
    const result = zxcvbn(password);
    return [result.score, result.feedback.warning, result.feedback.suggestions];
  }
}

export const MinimumPasswordStrength = 3;
