import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  AccountService,
  IUser,
  SelectAddAll,
} from '../services/account.service';
import { SkuService, ISku } from '../services/sku.service';
import { ContactService, IContact } from '../services/contact.service';
import { baseRole } from '../shared/baseRole';

import * as XLSX from 'xlsx';

@Component({
  templateUrl: './skuPrice.html',
})
export class SkuPriceComponent extends baseRole implements OnInit {
  public vendors: IContact[];
  public contact_id: number;
  public xData: any[];
  public data: { sku: string; price: number }[];
  public filename: string;
  public error: any = {};
  public badPriceSkus: string[];
  public missedSkus: string[];
  public importedSkus: string[];
  public success = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected accountService: AccountService,
    protected contactService: ContactService,
    protected skuService: SkuService
  ) {
    super(accountService);
  }

  ngOnInit() {
    if (!this.isAllowed(this.Permission.ManageInventory)) {
      this.router.navigate(['/']);
      return;
    }

    this.contactService
      .list({ hide: false, is_vendor: true })
      .subscribe(data => {
        data.sort((a, b) => {
          return a.company.localeCompare(b.company);
        });
        this.vendors = data;
      });
  }

  onFileUpload(evt: any) {
    this.error = {};
    this.filename = null;

    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length == 0) {
      return;
    }
    if (target.files.length !== 1) {
      this.error.global = 'Cannot user multiple files';
      return;
    }

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          cellDates: true,
          raw: true,
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        //console.log(ws);
        //console.log(XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 }));
        /* save data */
        this.xData = fixData(
          <any[][]>XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 })
        );
        this.validateUpload();
        this.filename = evt.target.files[0].name;
        evt.target.value = '';
      } catch (e) {
        console.log(e);
        this.error.global =
          'This file type is not supported or there is an error in your file.';
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

  submitFile() {
    this.skuService
      .priceImport(this.contact_id, this.data)
      .subscribe(result => {
        this.success = true;
        this.missedSkus = result.error;
        this.importedSkus = result.success;
      });
  }

  validateUpload() {
    this.error = {};
    this.importedSkus = null;
    this.missedSkus = null;
    this.badPriceSkus = [];
    this.data = [];

    for (let i = 0; i < this.xData.length; i++) {
      const sku = this.xData[i][0].toString().trim();
      const sPrice = this.xData[i][1].toString().trim();

      if (sku.length == 0) continue;
      if (sPrice.length == 0 || isNaN(sPrice)) {
        this.badPriceSkus.push(sku);
        continue;
      }
      this.data.push({
        sku: sku,
        price: parseFloat(sPrice),
      });
    }
  }
}

function fixData(xData: any[][]): any[][] {
  xData = xData.filter(e => {
    return e.length > 0;
  });

  for (let i = 0; i < xData.length; i++) {
    for (let j = 0; j < xData[i].length; j++) {
      if (typeof xData[i][j] != 'string') continue;
      // Check to see if for some reason it's quoted.
      if (xData[i][j][0] == '"' && xData[i][j][xData[i][j].length - 1] == '"') {
        xData[i][j] = xData[i][j].substring(1, xData[i][j].length - 1);
      }
    }
  }
  return xData;
}
