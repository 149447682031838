import {
  Component,
  ViewChild,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ModalDirective,
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { AccountService, IApiUser } from '../services/account.service';
import {
  ShiftTemplateService,
  IShiftTemplate,
} from '../services/shiftTemplate.service';
import {
  ShiftTemplateItemService,
  IShiftTemplateItem,
} from '../services/shiftTemplateItem.service';
import { baseRole } from '../shared/baseRole';
import { StringSort } from '../shared/api';

@Component({
  selector: 'modal-template-manager',
  templateUrl: 'modalTemplateManager.html',
})
export class ModalTemplateManagerComponent extends baseRole implements OnInit {
  @ViewChild('modal')
  public modal: ModalDirective;
  public data: IShiftTemplate[];
  public selectedTemplate: IShiftTemplate;
  public templateName: string;

  @Input() shifts;

  constructor(
    public accountService: AccountService,
    public shiftTemplateService: ShiftTemplateService,
    public shiftTemplateItemService: ShiftTemplateItemService
  ) {
    super(accountService);
  }

  ngOnInit() {}

  load() {
    // Load the data you need, then
    this.shiftTemplateService.list().subscribe(data => {
      StringSort(data);
      this.data = data;
    });

    this.modal.show();
  }
  show() {
    this.load();
  }

  cancel() {
    this.modal.hide();
  }

  addNew() {
    this.shiftTemplateService
      .update({ name: this.templateName })
      .subscribe(data => {
        let p = [];
        for (let s of this.shifts) {
          p.push({
            shift_template_id: data.id,
            start: s.start,
            end: s.end,
            position_id: s.position_id,
            user_id: s.user_id,
          });
        }
        this.shiftTemplateItemService.bulkUpdate(p).subscribe(() => {
          this.modal.hide();
        });
      });
    this.templateName = '';
  }

  replace() {
    this.shiftTemplateService
      .delete(this.selectedTemplate.id)
      .subscribe(data => {
        let idx = this.data.indexOf(this.selectedTemplate);
        this.data.splice(idx, 1);
        this.templateName = this.selectedTemplate.name;
        this.selectedTemplate = null;
        //	this.addNew();  shouldn't they have to select save at this point?
      });
  }

  deleteTemplate(obj) {
    this.shiftTemplateService.delete(obj.id).subscribe(data => {
      this.selectedTemplate = null;
      let idx = this.data.indexOf(obj);
      this.data.splice(idx, 1);
    });
  }
}
