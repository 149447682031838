<modal-timeoff #modalTimeOff></modal-timeoff>
<modal-trade-board #modalTradeBoard></modal-trade-board>
<alert *ngIf="locations.cancel_at && isOwner()" type="dark" class="nag-header">
  <div class="row">
    <div class="col-12 text-center">
      <p>
        This account is scheduled for deactivation on the {{locations.cancel_at
        * 1000 | bliDateLong}}. To renew, please go to the 'Manage Location
        Billing' section and renew your plan.
      </p>
      <button
        class="btn btn-primary"
        type="button"
        [routerLink]="['/settings/account',getLocation().id,'package']">
        Manage billing
      </button>
    </div>
  </div>
</alert>
<div class="container-fluid nav-padding">
  <div class="row">
    <div class="col-sm-12 d-md-none">
      <form class="header-search-form" (submit)="search()">
        <icons name="Search"></icons>
        <input
          type="search"
          name="headerSearch"
          [(ngModel)]="searchText"
          placeholder="Search..."
          aria-label="Search"
          autofocus />
      </form>
    </div>
    <!-- 2/3 Column Area (left and middle third) -->
    <div class="col-md-8 col-sm-12">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
          <home-messages></home-messages>
        </div>
      </div>
      <widgets
        *ngIf="!isFreemium() && isAllowed(Permission.ViewSales) && getLocation() && getLocation().has_finances"
        [reportData]="reportData">
      </widgets>
      <!-- 2/3 Column Area split into two halves -->
      <div class="row">
        <!-- Left Half -->
        <div class="col-12 col-md-6 col-lg-6">
          <home-repair-todo></home-repair-todo>
          <!-- If "SALES THIS MONTH" widget is not shown, then show the Schedule requests and Pending schedule requests cards in this area -->
          <home-schedule-requests
            *ngIf="!isAllowed(Permission.ViewSales)"></home-schedule-requests>
        </div>
        <!-- Right Half -->
        <div class="col-12 col-md-6 col-lg-6">
          <checklist-list [date]="startDate"></checklist-list>
          <!-- "Who's working right now" card (Only render here if no graph widget component shown)-->
          <home-shift-list
            *ngIf="!(!isFreemium() && isAllowed(Permission.ViewSales) && getLocation() && getLocation().has_finances)"></home-shift-list>
        </div>
      </div>
    </div>
    <!-- 1/3 Column Area (right third) -->
    <div class="col-md-4 col-sm-12">
      <!-- If "SALES THIS MONTH" widget is shown, then show the Schedule requests and Pending schedule requests cards in this area -->
      <home-schedule-requests
        *ngIf="isAllowed(Permission.ViewSales)"></home-schedule-requests>
      <doc-quiz-sign-all *ngIf="isAllowed(Permission.ManageDoc)">
      </doc-quiz-sign-all>

      <div class="row" *ngIf="isAllowed(Permission.LogShiftData)">
        <div class="col-12">
          <div class="card mb-0">
            <div class="card-body">
              <label>Today</label>
              <div *ngIf="getLocation() && getLocation().has_finances">
                <div class="text-white">
                  <ul class="list-group">
                    <li class="list-group-item blue-bg" *ngIf="today">
                      <div class="d-flex flex-row">
                        <h4 class="p-0 m-0">SALES</h4>
                        <div class="ml-auto">
                          <h4 class="p-0 m-0">
                            <b
                              >{{today.service_time[0].sales | currency: 'USD'}}
                            </b>
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item grey-dark-bg" *ngIf="today">
                      <div class="d-flex flex-row">
                        <h4 class="p-0 m-0">LABOR</h4>
                        <div class="ml-auto">
                          <h4 class="p-0 m-0">
                            <b
                              >{{ (today.boh_cost+today.foh_cost)|currency:
                              'USD' }}</b
                            >
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item grey-bg" *ngIf="today">
                      <div class="d-flex flex-row">
                        <h4 class="p-0 m-0">COGS</h4>
                        <div class="ml-auto">
                          <h4 class="p-0 m-0">
                            <b>{{ today.cogs_cost | currency: 'USD' }}</b>
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <div *ngIf="logNotesToday" style="line-height: 1">
                <div class="d-inline mt-3" *ngIf="logNotesToday.length==0">
                  <span style="text-align: center">No Log Notes</span>
                </div>

                <div class="d-inline mt-3">
                  <div
                    class="my-2"
                    *ngFor="let l of logNotesToday | orderBy:['service_time_id','log_note_type','id']">
                    {{ l.firstname }} {{ l.lastname }} at {{ l.created_at |
                    date:'short' }} <br />
                    &nbsp;{{ ServiceTimeType[l.service_time_id] }}
                    {{LogNoteNames[l.log_note_type]}}
                    <span *ngIf="l.images[0]"><b> + </b></span>
                    <div class="text-black mx-2">{{ l.note}}</div>
                  </div>
                </div>

                <add-comment
                  class="d-flex justify-content-end"
                  *ngIf="logNotesToday.length!=0"
                  (onAdd)="addLogComment($event,true)">
                  <span class="cdi-m-send-message"></span>
                  <span class="align-self-center" style="font-size: 12px"
                    >Add Comment</span
                  >
                </add-comment>

                <div class="d-inline mt-3">
                  <div
                    class="body-bg border my-1"
                    style="font-size: 12px"
                    *ngFor="let l of logCommentsToday">
                    &nbsp;{{ l.firstname }} {{ l.lastname }} at {{ l.created_at
                    | date:'short'}}
                    <span
                      class="cdi-s-cancel pointer float-right"
                      (click)="deleteLogComment(l)"></span>
                    <div class="text-black mx-2">{{ l.response }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card mb-0">
            <div class="card-body">
              <label>Yesterday</label>
              <div *ngIf="getLocation() && getLocation().has_finances">
                <div class="text-white">
                  <ul class="list-group">
                    <li class="list-group-item blue-bg" *ngIf="yesterday">
                      <div class="d-flex flex-row">
                        <h4 class="p-0 m-0">SALES</h4>
                        <div class="ml-auto">
                          <h4 class="p-0 m-0">
                            <b
                              >{{yesterday.service_time[0].sales | currency:
                              'USD'}}
                            </b>
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item grey-dark-bg" *ngIf="yesterday">
                      <div class="d-flex flex-row">
                        <h4 class="p-0 m-0">LABOR</h4>
                        <div class="ml-auto">
                          <h4 class="p-0 m-0">
                            <b
                              >{{ (yesterday.boh_cost+yesterday.foh_cost) |
                              currency: 'USD' }}</b
                            >
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item grey-bg" *ngIf="yesterday">
                      <div class="d-flex flex-row">
                        <h4 class="p-0 m-0">COGS</h4>
                        <div class="ml-auto">
                          <h4 class="p-0 m-0">
                            <b>{{ yesterday.cogs_cost | currency: 'USD' }}</b>
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <div
                class="d-inline mt-3"
                *ngIf="!logNotesYesterday || (logNotesYesterday && logNotesYesterday.length==0)">
                <span style="text-align: center">No Log Notes</span>
              </div>
              <div style="line-height: 1" *ngIf="logNotesYesterday">
                <div class="d-inline mt-3">
                  <div
                    class="my-2"
                    *ngFor="let l of logNotesYesterday | orderBy:['service_time_id','log_note_type','id']">
                    {{ l.firstname }} {{ l.lastname }} at {{ l.created_at |
                    date:'short' }} <br />
                    &nbsp;{{ ServiceTimeType[l.service_time_id] }}
                    {{LogNoteNames[l.log_note_type]}}
                    <div class="text-black mx-2">{{ l.note}}</div>
                  </div>
                </div>

                <add-comment
                  class="d-flex justify-content-end"
                  *ngIf="logNotesYesterday.length!=0"
                  (onAdd)="addLogComment($event,false)">
                  <span class="cdi-m-send-message"></span>
                  <span class="align-self-center" style="font-size: 12px"
                    >Add Comment</span
                  >
                </add-comment>

                <div class="d-inline mt-3" *ngIf="logCommentsYesterday">
                  <div
                    class="body-bg border my-1"
                    style="font-size: 12px"
                    *ngFor="let l of logCommentsYesterday">
                    &nbsp;{{ l.firstname }} {{ l.lastname }} at {{ l.created_at
                    | date:'short'}}
                    <span
                      class="cdi-s-cancel pointer float-right"
                      (click)="deleteLogComment(l)"></span>
                    <div class="text-black mx-2">{{ l.response }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  <home-calendar></home-calendar> -->
      <!-- "Who's working right now" card (Only render here if graph widget component is shown)-->
      <log-skipped *ngIf="isAllowed(Permission.LogShiftData)"></log-skipped>
      <home-shift-list
        *ngIf="!isFreemium() && isAllowed(Permission.ViewSales) && getLocation() && getLocation().has_finances"></home-shift-list>
    </div>
  </div>
</div>
