import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  BaseService,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from './base.service';
import { IEvent } from '../shared/api';
import { AccountService } from './account.service';
import * as moment from 'moment';

@Injectable()
export class EventService extends BaseService<IEvent> {
  constructor(accountService: AccountService, http: HttpClient) {
    super(accountService, http, 'event');
  }

  postProcess(): (data: any) => any {
    return data => {
      if (Array.isArray(data)) {
        for (let d of data) {
          this.fixDate(d, 'start_date');
          this.fixDate(d, 'end_date');
          this.fixDate(d, 'repeat_until');
        }
      } else {
        this.fixDate(data, 'start_date');
        this.fixDate(data, 'end_date');
        this.fixDate(data, 'repeat_until');
      }
      return data;
    };
  }

  shortList(filter?: any): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get<any[]>(this.baseUrl + 'short', { params: queryParameters })
      .pipe(map(this.postProcess()));
  }

  assignmentList(filter?: any): Observable<any[]> {
    let queryParameters = new HttpParams();
    let userFilter = this.accountService.getUserFilter();

    if (filter) {
      for (let key in filter) {
        queryParameters = queryParameters.set(key, filter[key]);
      }
    }
    queryParameters = userFilter.updateQuery(queryParameters);
    return this.http
      .get<any[]>(this.baseUrl + 'assignment', { params: queryParameters })
      .pipe(map(this.postProcess()));
  }
  getPdfEventUrl(data: {
    location_id: number;
    start: Date;
    end: Date;
  }): string {
    let url =
      this.baseUrl +
      'pdf?location_id=' +
      data.location_id +
      '&start=' +
      data.start +
      '&end=' +
      data.end;
    return url;
  }
}

export { IEvent, EventType } from '../shared/api';
